import React, { useEffect, useState } from 'react'
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap'
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import Async from 'react-select/async';
import Select from 'react-select';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { connect } from 'react-redux';
import {
    clearCreateMilestoneError,
    ClearProjectDate,
    CreateMilestone,
    createMilestoneSuccess,
    getAdminProjectNameAsyncSelectAction,
    getMilestoneOwnerAsyncSelect,
    getProjectDate,
    getProjectNameAsyncSelect
} from '../../../../store/Modules/ProjectPortal/Milestones/actions';
import { STATUS_OPTIONS } from '../../../../store/Modules/ProjectPortal/Milestones/constants';

const CreateMilestones = (props) => {
    const { projId, ProjName, MilestoneProjectDates, selectedFlexibleDueDates, filteredData } = props;
    const { userId, userAppDetails } = props.currentProfile;

    const [formFields, setFormFields] = useState({});
    const [existingProjectUser, setExistingProjectUser] = useState([]);
    const [prevMilestoneOwnerUser, setPrevMilestoneOwnerUser] = useState([]);
    const [errors, setErrors] = useState({});
    const [prevProjectName, setPrevProjectName] = useState([]);
    const [autoFocus, setAutoFocus] = useState(true);
    const [projectStartDate, setProjectStartDate] = useState(null);
    const [projectEndDate, setProjectEndDate] = useState(null);
    const [keyChange, setKeyChange] = useState(1);
    const [projectId, setProjectId] = useState();

    useEffect(() => {
        if (projId) {
            props.getProjectDate(projId);
        }
        // props.ClearProjectDate();
        props.createMilestoneSuccess();
        props.clearCreateMilestoneError();
        setPrevProjectName(projId ? { label: ProjName || 'Select project', value: projId || null } : 'Select project');
        setProjectId(projId);
        setKeyChange(keyChange + 1);
        setFormFields({ ...formFields, ProjectId: projId, AllowFlexibleDueDates: selectedFlexibleDueDates || false, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
        return (() => {
            props.ClearProjectDate();
            props.createMilestoneSuccess();
            props.clearCreateMilestoneError();
        })
    }, [])

    //--------------------------------------- Default Options ---------------------------------------------------------
    const handleAdminProject = (inputValue, callback) => {
        const { getAdminProjectNameAsyncSelectAction } = props;
        return getAdminProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleProject = (inputValue, callback) => {
        const { getProjectNameAsyncSelectAction } = props;
        return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback, userId);
    };

    const handleMilestone = (inputValue, callback) => {
        const { getMilestoneOwnerAsyncSelectAction } = props;
        if (projectId) {
            return getMilestoneOwnerAsyncSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
        }
        else {
            callback([]);
        }
    };
    //----------------------------------------Add Input Field ------------------------------------------------------
    const maxLength = 50;
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'MileStoneName') {
            if (value && value.length >= maxLength) {
                setErrors({ ...errors, MileStoneName: `MileStone name cannot exceed ${maxLength} characters.` });
            } else {
                setErrors({ ...errors, MileStoneName: '' });
            }
        }
    };
    //----------------------------------------Add Date Field --------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        const formattedDate = value;
        setFormFields({ ...formFields, [name]: formattedDate });
        const startDate = moment(MilestoneProjectDates && MilestoneProjectDates.startDate).format('YYYY-MM-DD');
        const endDate = moment(MilestoneProjectDates && MilestoneProjectDates.endDate).format('YYYY-MM-DD');
        // setErrors({ ...errors, [name]: '' });
        if (name === 'startDate') {
            const isStartValid = moment(value || formFields.startDate).isBetween(startDate, endDate, null, '[]');
            if (isStartValid || formFields.AllowFlexibleDueDates) {
                setErrors({ ...errors, startProjectDate: false, startDate: '' });
            } else {
                setErrors({ ...errors, startProjectDate: true, startDate: '' });
            }
        }
        if (name === 'endDate') {
            const isEndValid = moment(value || formFields.endDate).isBetween(startDate, endDate, null, '[]');
            if (isEndValid || formFields.AllowFlexibleDueDates) {
                setErrors({ ...errors, endProjectDate: false, endDate: '' });
            } else {
                setErrors({ ...errors, endProjectDate: true, endDate: '' });
            }
        }
    };
    //----------------------------------------Add Status Field --------------------------------------------------------
    const handleMilestoneStatus = (Value) => {
        const { value } = Value || {};
        setFormFields({ ...formFields, status: value });
        setErrors({ ...errors, status: '' });
    }   //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleCreateMilestones();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //----------------------------------------Create Milestone----------------------------------------------------------
    const handleCreateMilestones = () => {
        const newErrors = {};
        const StartDates = moment(MilestoneProjectDates && MilestoneProjectDates.startDate).format('YYYY-MM-DD');
        const EndDates = moment(MilestoneProjectDates && MilestoneProjectDates.endDate).format('YYYY-MM-DD');
        const startDate = moment(formFields.startDate);
        const endDate = moment(formFields.endDate);
        const projectStart = moment(projectStartDate);
        const projectEnd = moment(projectEndDate);

        if (!formFields.ProjectId || !formFields.ProjectId.trim()) {
            newErrors.ProjectId = 'Project name is required';
        }
        if (!formFields.MileStoneName || !formFields.MileStoneName.trim()) {
            newErrors.MileStoneName = 'Milestone name is required';
        }
        if (formFields.MileStoneName && formFields.MileStoneName.length >= maxLength) {
            newErrors.MileStoneName = `MileStone name cannot exceed ${maxLength} characters.`;
        }
        if (!formFields.startDate || !formFields.startDate.trim()) {
            newErrors.startDate = 'Start date is required';
        }
        if (!formFields.endDate || !formFields.endDate.trim()) {
            newErrors.endDate = 'End date is required';
        }
        if (!formFields.MileStoneOwnerUserId || !formFields.MileStoneOwnerUserId.trim()) {
            newErrors.MileStoneOwnerUserId = 'Milestone owner name is required';
        }
        if (!formFields.status || !formFields.status.trim()) {
            newErrors.status = 'Milestone status is required';
        }
        if (formFields && !formFields.AllowFlexibleDueDates) {
            if (startDate.isBefore(projectStart) || startDate.isAfter(projectEnd)) {
                newErrors.startDate = `Start date must be between ${projectStart.format('DD/MM/YYYY')} and ${projectEnd.format('DD/MM/YYYY')}`;
            }
            if (endDate.isBefore(startDate) || endDate.isAfter(projectEnd)) {
                newErrors.endDate = `End date must be after start date and before ${projectEnd.format('DD/MM/YYYY')}`;
            }
            const isStartValid = moment(formFields.startDate).isBetween(StartDates, EndDates, null, '[]');
            if (!isStartValid) {
                newErrors.startProjectDate = true
            }
            const isEndValid = moment(formFields.endDate).isBetween(StartDates, EndDates, null, '[]');
            if (!isEndValid) {
                newErrors.endProjectDate = true
            }
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            props.CreateMilestonesAction(formFields, projId, filteredData);
            props.handleDone(formFields && formFields.ProjectId);
        }
    }
    //----------------------------------------Add Project Name-------------------------------------------------------
    const handleAddProjectName = (value) => {
        console.log('value:', value);

        setKeyChange(keyChange + 1)
        setPrevProjectName(value);
        setFormFields({ ...formFields, ProjectId: value.value, MileStoneOwnerUserId: '', AllowFlexibleDueDates: value.AllowFlexibleDueDates || false });
        setPrevMilestoneOwnerUser('Select milestone owner');
        props.getProjectDate(value.value);
        setProjectId(value.value);
        setErrors({ ...errors, ProjectId: '', endDate: '', startDate: '' });
    }

    useEffect(() => {
        if (MilestoneProjectDates) {
            const startDate = moment(MilestoneProjectDates && MilestoneProjectDates.startDate).format('YYYY-MM-DD');
            const endDate = moment(MilestoneProjectDates && MilestoneProjectDates.endDate).format('YYYY-MM-DD');

            if (((formFields.startDate >= startDate) && (formFields.endDate <= endDate)) || (formFields && formFields.AllowFlexibleDueDates)) {
                setProjectStartDate(MilestoneProjectDates.startDate);
                setProjectEndDate(MilestoneProjectDates.endDate);
                setErrors({ startDate: '', endDate: '' });
            } else {
                setErrors({
                    ...errors,
                    endProjectDate: true,
                    startProjectDate: true,
                });
            }
        }
    }, [MilestoneProjectDates]);
    //----------------------------------------Add Milestone Owner------------------------------------------------------
    const handleAddMilestoneOwner = (selectedOptions) => {
        setFormFields({ ...formFields, MileStoneOwnerUserId: selectedOptions.value });
        setPrevMilestoneOwnerUser(selectedOptions);
        setErrors({ ...errors, MileStoneOwnerUserId: '' });
    }

    const startDateMax = formFields.endDate ? moment(formFields.endDate).format('YYYY-MM-DD') : projectEndDate ? moment(projectEndDate).format('YYYY-MM-DD') : null;
    const endDateMin = formFields.startDate ? moment(formFields.startDate).format('YYYY-MM-DD') : projectStartDate ? moment(projectStartDate).format('YYYY-MM-DD') : null;

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <FormGroup>
                <Label>
                    Project Name<span className="text-danger"> *</span>
                </Label>
                {userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "projectportal").length > 0 &&
                    userAppDetails
                        .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "projectportal")
                        .map(userApp => {
                            const adminRoles = userApp?.appRoleDetails?.filter(role => role?.roleName);
                            const isAdmin = adminRoles?.some(role => role.roleName.toLowerCase() === "administrator");

                            return (
                                <div key={userApp.appName}>
                                    {isAdmin ? (
                                        <Async
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: (errors && errors.ProjectId) ? 'red' : "silver"
                                                })
                                            }}
                                            openMenuOnFocus={true}
                                            autoFocus={true}
                                            name="ProjectId"
                                            placeholder="Select project name"
                                            isSearchable
                                            defaultOptions
                                            loadOptions={handleAdminProject}
                                            onChange={handleAddProjectName}
                                            value={prevProjectName}
                                            isDisabled={ProjName}
                                        />
                                    ) : (
                                        <div>
                                            <Async
                                                openMenuOnFocus={true}
                                                autoFocus={true}
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: (errors && errors.ProjectId) ? 'red' : "silver"
                                                    })
                                                }}
                                                name="ProjectId"
                                                placeholder="Select project name"
                                                isSearchable
                                                defaultOptions
                                                loadOptions={handleProject}
                                                onChange={handleAddProjectName}
                                                value={prevProjectName}
                                                isDisabled={ProjName}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })
                }
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.ProjectId}
                        {formFields.ProjectId && ((errors && errors.startProjectDate) || (errors && errors.endProjectDate)) && `The current date is not within the range between the project start date: ${moment(MilestoneProjectDates && MilestoneProjectDates.startDate).format('DD-MM-YYYY')} and the end date: ${moment(MilestoneProjectDates && MilestoneProjectDates.endDate).format('DD-MM-YYYY')} . Please enable flexible due date or change date.`}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    Milestone Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    onFocus={handleFocus}
                    type="text"
                    name="MileStoneName"
                    placeholder="Enter milestone name"
                    onChange={validateOnChange}
                    value={formFields && formFields.MileStoneName}
                    invalid={errors && errors.MileStoneName}
                    maxLength={maxLength}
                />
                <FormFeedback>{errors && errors.MileStoneName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label>
                    Milestone Owner <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.MileStoneOwnerUserId) ? 'red' : "silver"
                        })
                    }}
                    key={keyChange}
                    name="MileStoneOwnerUserId"
                    onChange={handleAddMilestoneOwner}
                    placeholder="Select milestone owner"
                    isSearchable
                    defaultOptions
                    // isMulti
                    loadOptions={handleMilestone}
                    value={prevMilestoneOwnerUser}
                    isDisabled={!(formFields && formFields.ProjectId)}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.MileStoneOwnerUserId}
                    </div>
                </FormText>
            </FormGroup>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Start Date <span className="text-danger"> *</span></Label>
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="startDate"
                            placeholder="Select start date"
                            onChange={validateOnChangeDate}
                            min={!(formFields && formFields.AllowFlexibleDueDates) && (projectStartDate ? moment(projectStartDate).format('YYYY-MM-DD') : null)}
                            max={!(formFields && formFields.AllowFlexibleDueDates) ? startDateMax : formFields.endDate}
                            value={moment(formFields.startDate).format("YYYY-MM-DD")}
                            invalid={errors && errors.startDate}
                        />
                        <FormFeedback>{errors && errors.startDate}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>End Date <span className="text-danger"> *</span></Label>
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="endDate"
                            placeholder="Select end date"
                            onChange={validateOnChangeDate}
                            min={!(formFields && formFields.AllowFlexibleDueDates) ? endDateMin : formFields.startDate}
                            max={!(formFields && formFields.AllowFlexibleDueDates) && (projectEndDate ? moment(projectEndDate).format('YYYY-MM-DD') : null)}
                            value={moment(formFields.endDate).format("YYYY-MM-DD")}
                            invalid={errors && errors.endDate}
                        />
                        <FormFeedback>{errors && errors.endDate}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label className="col-form-label">Milestone Status <span className="text-danger"> *</span></Label>
                <Select
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.status) ? 'red' : "silver"
                        })
                    }}
                    name="status"
                    placeholder="Select milestone status"
                    isSearchable
                    defaultOptions
                    options={STATUS_OPTIONS}
                    onChange={handleMilestoneStatus}
                    value={formFields && formFields.status ? { label: formFields && formFields.status, value: formFields && formFields.status } : 'Select milestone status'}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.status}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    Milestone Description
                </Label>
                <Input
                    onFocus={handleFocus}
                    type="textarea"
                    name="Description"
                    placeholder="Enter milestone description"
                    onChange={validateOnChange}
                    value={formFields && formFields.Description}
                />
            </FormGroup>
            <br></br>
            <FormGroup>
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateMilestones}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </FormGroup>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.Milestones.isPending,
    errorMessage: state.Milestones.errorMessage,
    MilestoneProjectDates: state.Milestones.MilestoneProjectDates,
    milestoneProjectDatesError: state.Milestones.milestoneProjectDatesError,
    isMilestoneProjectDatesPending: state.Milestones.isMilestoneProjectDatesPending,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    getAdminProjectNameAsyncSelectAction: (searchValue, callback) => dispatch(getAdminProjectNameAsyncSelectAction(searchValue, callback)),
    getProjectNameAsyncSelectAction: (searchValue, callback, userId) => dispatch(getProjectNameAsyncSelect(searchValue, callback, userId)),
    getMilestoneOwnerAsyncSelectAction: (projectId, searchValue, callback) => dispatch(getMilestoneOwnerAsyncSelect(projectId, searchValue, callback)),
    CreateMilestonesAction: (formFields, projId, filteredData) => dispatch(CreateMilestone(formFields, projId, filteredData)),
    clearCreateMilestoneError: () => dispatch(clearCreateMilestoneError()),
    createMilestoneSuccess: () => dispatch(createMilestoneSuccess()),
    getProjectDate: (id) => dispatch(getProjectDate(id)),
    ClearProjectDate: () => dispatch(ClearProjectDate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMilestones)