import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import VWButton from '../../../../components/vwButton/VWButton';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { EditAppMaster, updateAppMasterSuccess } from '../../../../store/Modules/Admin/AppMaster/actions';

const AppMasterEdit = (props) => {
    const [formFields, setFormFields] = useState({});
    const [isActive, setisActive] = useState(true);
    const [errors, setErrors] = useState({});
    const [autoFocus, setAutoFocus] = useState(true);
    useEffect(() => {
        props.updateAppMasterSuccess();
        if (props.AppMasterDetails) {
            setFormFields(props.AppMasterDetails);
            setisActive(props.AppMasterDetails && props.AppMasterDetails.isActive)
        }
    }, [])
    //---------------------------------- Input field Update -----------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'latestVersion') {
            setErrors({ ...errors, latestVersion: null });
        }

    };
    //---------------------------- Status Update -----------------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, isActive: !isActive })
    };
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleEditAppMaster();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //--------------------------------------------EDIT ---------------------------------------------------------
    const handleEditAppMaster = () => {
        const newErrors = {};
        if (!(formFields && formFields.latestVersion) || !(formFields.latestVersion && formFields.latestVersion.trim())) {
            newErrors.latestVersion = 'Latest version is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.EditAppMasterAction(formFields);
        }
    };
    //------------------------------------------------------------------------------------------------------------
    console.log("formfields", formFields);
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <form className="mb-3" name="formAddCustomer">
                <FormGroup>
                    <Label>
                        App Name <span className="text-danger"> *</span>
                    </Label>
                    <Input
                        onFocus={handleFocus}
                        autoFocus={true}
                        type="text"
                        name="appName"
                        placeholder="Enter app name"
                        onChange={validateOnChange}
                        value={formFields && formFields.appName}
                        disabled
                    />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Latest Version <span className="text-danger"> *</span>
                    </Label>
                    <Input
                        onFocus={handleFocus}
                        type="text"
                        name="latestVersion"
                        placeholder="Enter latest version"
                        onChange={validateOnChange}
                        value={formFields && formFields.latestVersion}
                        invalid={errors && errors.latestVersion}
                    />
                    {<FormFeedback>{errors && errors.latestVersion}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!isActive ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${isActive ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
            </form>
            <br></br>
            <div className="form-group">
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleEditAppMaster}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    AppMasterDetails: state.appMaster.appMasterDetails,
    isPending: state.appMaster.isUpdateAppMasterPending,
});

const mapDispatchToProps = (dispatch) => ({
    EditAppMasterAction: (formFields) => dispatch(EditAppMaster(formFields)),
    updateAppMasterSuccess: () => dispatch(updateAppMasterSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMasterEdit)