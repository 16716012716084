import { CLEAR_TIMESHEET_REPORT_LIST_ERROR, CLEAR_TIMESHEET_REPORT_USER_LIST_ERROR, CLOSE_MODAL_TIMESHEET_REPORT, OPEN_MODAL_TIMESHEET_REPORT, SET_TIMESHEET_REPORT_EXPORT_PENDING, SET_TIMESHEET_REPORT_LIST, SET_TIMESHEET_REPORT_LIST_PENDING, SET_TIMESHEET_REPORT_USER_LIST, SET_TIMESHEET_REPORT_USER_LIST_PENDING, SET_TIMESHEET_TOGGLE_VIEW_DOCS, TIMESHEET_REPORT_EXPORT_ERROR, TIMESHEET_REPORT_EXPORT_SUCCESS, TIMESHEET_REPORT_LIST_ERROR, TIMESHEET_REPORT_USER_LIST_ERROR } from "./constants";

const initialState = {
    //--------------------LIST-------------------------------------
    reportList: [],
    reportListCount: 0,
    isReportListPending: false,
    reportListErrorMessage: null,
    isReportUserListPending: false,
    reportUserList: [],
    reportUserListCount: 0,
    reportUserListErrorMessage: null,
    isTimeSheetReportModalOpen: false,
};


export default (localState = initialState, action) => {
    switch (action.type) {
        case TIMESHEET_REPORT_EXPORT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                reportExportErrorMessage: errorMessage,
                isReportExportPending: false,
            };
        }
        case TIMESHEET_REPORT_EXPORT_SUCCESS: {
            return {
                ...localState,
                isReportExportPending: false,
            };
        }
        case OPEN_MODAL_TIMESHEET_REPORT: {
            return {
                ...localState,
                isTimeSheetReportModalOpen: true,
            };
        }
        case CLOSE_MODAL_TIMESHEET_REPORT: {
            return {
                ...localState,
                isTimeSheetReportModalOpen: false,
            };
        }
        case SET_TIMESHEET_REPORT_EXPORT_PENDING: {
            return {
                ...localState,
                isReportExportPending: true,
            };
        }
        case TIMESHEET_REPORT_USER_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                reportUserListErrorMessage: errorMessage,
                reportUserList: [],
                reportUserListCount: 0,
                isReportUserListPending: false,
            };
        }
        case CLEAR_TIMESHEET_REPORT_USER_LIST_ERROR: {
            return {
                ...localState,
                reportUserListErrorMessage: null,
            };
        }
        case SET_TIMESHEET_REPORT_USER_LIST: {
            const { reportUserList } = action.payload;
            return {
                ...localState,
                reportUserList: reportUserList && reportUserList['value'],
                reportUserListCount: reportUserList && reportUserList['@odata.count'],
                isReportUserListPending: false,
                reportUserListErrorMessage: null,
            };
        }
        case SET_TIMESHEET_REPORT_USER_LIST_PENDING: {
            return {
                ...localState,
                isReportUserListPending: true,
            };
        }
        case SET_TIMESHEET_TOGGLE_VIEW_DOCS: {
            const { toggleView } = action.payload;
            return {
                ...localState,
                toggleViewBool: toggleView
            }
        }
        case SET_TIMESHEET_REPORT_LIST_PENDING: {
            return {
                ...localState,
                isReportListPending: true,
            };
        }
        case SET_TIMESHEET_REPORT_LIST: {
            const { reportList } = action.payload;
            return {
                ...localState,
                reportList: reportList && reportList['value'],
                reportListCount: reportList && reportList['@odata.count'],
                isReportListPending: false,
                reportListErrorMessage: null,
            };
        }
        case CLEAR_TIMESHEET_REPORT_LIST_ERROR: {
            return {
                ...localState,
                reportListErrorMessage: null,
            };
        }
        case TIMESHEET_REPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                reportListErrorMessage: errorMessage,
                reportList: [],
                reportListCount: 0,
                isReportListPending: false,
            };
        }
        default: {
            return localState;
        }
    }
};
