import React, { useEffect, useState } from 'react'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row, Table } from 'reactstrap';
import moment from 'moment-timezone';
import Async from 'react-select/async';
import { connect } from 'react-redux';
import { clearForm, updateForm } from '../../../../store/form/actions';
import { ClearAvailableLeaveCount, ClearLeaveCount, clearCreateLeaveError, clearLeaveCountError, clearLeaveTrackerActionError, createLeave, createLeaveError, createLeaveSuccess, getAvailableLeaveCount, getLeaveCountAction, getLeaveTypeAsyncSelect } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';
import VWButton from '../../../../components/vwButton/VWButton';
import VWMessage from '../../../../components/vwMessage/vwMessage';


const Create = (props) => {
    const { filteredData } = props;

    const [formFields, setFormFields] = useState({});
    const [reasons, setReason] = useState({});
    const [errors, setErrors] = useState({});
    const [autoFocus, setAutoFocus] = useState(true);

    const handleLoadLeaveType = (inputValue, callback) => {
        const { getLeaveTypeAsyncSelectAction } = props;
        return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        // setFormFields({ ...formFields, [input.name]: value });
        setReason({ ...reasons, [input.name]: value });
        setErrors({ ...errors, leaveReason: '' });
    };

    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        // const formattedDate = value ? moment(value).format() : null;
        const formattedDate = value;
        setFormFields({ ...formFields, [name]: formattedDate });

        if (name === 'fromDateTime') {
            setErrors({ ...errors, fromDateTime: '' });
        }
        if (name === 'toDateTime') {
            setErrors({ ...errors, toDateTime: '' });
        }
    };

    const handleAddLeaveTypeChange = (value) => {
        setFormFields({ ...formFields, leaveTypeId: value.value });
        setErrors({ ...errors, leaveTypeId: '' });

    }
     useEffect(() => {
            const handleKeyDown = (event) => {
                console.log('autoFocus:', autoFocus);
                if (event.key === 'Escape') {
                    console.log('escape key');
                    setAutoFocus(false);
                    const inputs = document.querySelectorAll('input');
                    inputs.forEach(input => input.blur());
                }
                else if (event.key === 'Tab') {
                    // Set autoFocus to true when Tab is pressed
                    console.log('tab key');
                    setAutoFocus(true);
                }
                else if (event.key === 'Enter' && autoFocus === false) {
                    console.log('enter button');
                    handleCreateLeave();
                }
            };
    
            document.addEventListener('keydown', handleKeyDown);
    
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }, [autoFocus]);
    //--------------------------------------------Create ---------------------------------------------------------
    const handleCreateLeave = () => {
        const newErrors = {};
        if (!formFields.fromDateTime) {
            newErrors.fromDateTime = 'From date is required';
        }
        if (!formFields.toDateTime) {
            newErrors.toDateTime = 'To date is required';
        }
        if (!formFields.leaveTypeId) {
            newErrors.leaveTypeId = 'Leave type is required';
        }
        if (!reasons.leaveReason) {
            newErrors.leaveReason = 'Leave reason is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.createLeaveAction(formFields, reasons, filteredData);
        }
    };

    const x = props && props.Dates.map((item) => {
        const fromDate = item.FromDateTime.split('T')[0];
        const toDate = item.ToDateTime.split('T')[0];
        return {
            FromDateTime: fromDate,
            ToDateTime: toDate
        };
    });

    console.log(x);
    console.log(props.Dates);

    useEffect(() => {
        props.ClearLeaveCount();
        // if (formFields.fromDateTime && formFields.toDateTime && formFields.leaveTypeId) {
        if (formFields.fromDateTime && formFields.toDateTime && formFields.leaveTypeId) {
            // props.ClearLeaveCount();
            props.getLeaveCount(formFields);
        }
        if (formFields.leaveTypeId) {
            //  props.ClearLeaveCount();
            props.getAvailableLeaveCount(formFields);
        }
    }, [formFields])

    useEffect(() => {
        props.createLeaveSuccess();
        props.ClearAvailableLeaveCount();
        props.ClearLeaveCount();
        props.clearCreateLeaveError();
        props.clearFormAction();
        props.createLeaveError();
        props.clearLeaveTrackerActionError();
        props.clearLeaveCountError();
        props.clearLeaveCountError()
    }, [])

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    console.log("LeaveCount:", props.LeaveCount);
    console.log("availableLeaveCount:", props.availableLeaveCount);
    console.log("formfields:", formFields);

    let current = moment().subtract(1, 'weeks').calendar();

    return (
        <React.Fragment>
            {(props.createLeaveCountErrorMessage || props.errorMessage || props.createAvailableLeaveCountErrorMessage) && (
                <VWMessage type="danger" defaultMessage={props.errorMessage || props.createLeaveCountErrorMessage || props.createAvailableLeaveCountErrorMessage} baseClassName="alert"></VWMessage>
            )}
            {(props.LeaveCount && props.LeaveCount.errMsg) ?
                <VWMessage type='danger' defaultMessage={props.LeaveCount && props.LeaveCount.errMsg} baseClassName="alert"></VWMessage>
                : ''}
            <>
                <Row className='m-0 p-0'>
                    <FormGroup>
                        <Label>
                            Leave Type <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            openMenuOnFocus={true}
                            autoFocus={true}
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.leaveTypeId) ? '#FF3D60' : "#CED4DA"
                                })
                            }}
                            name="leaveTypeId"
                            placeholder="Select Leave Type"
                            isSearchable
                            defaultOptions
                            loadOptions={handleLoadLeaveType}
                            onChange={handleAddLeaveTypeChange}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.leaveTypeId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Row>
                {/* <br></br> */}
                {props.LeaveCount && props.LeaveCount.availableLeaveCount >= 0 ?
                    <Row className='m-0 p-0'>
                        <Col>
                            <Label>Available Count: {props.LeaveCount && props.LeaveCount.availableLeaveCount || 0}</Label>
                        </Col>
                    </Row> :
                    <Row className='m-0 p-0'>
                        <Col>
                            <Label>Available Count: {props.availableLeaveCount || 0}</Label>
                        </Col>
                    </Row>}
                <Row style={{ alignItems: "center" }} className='m-0 p-0'>
                    <Col md={6}>
                        <FormGroup>
                            <Label>From Date <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="date"
                                name="fromDateTime"
                                placeholder="Select From Date"
                                onChange={validateOnChangeDate}
                                min={moment(current).format("YYYY-MM-DD")}
                                max={formFields.toDateTime}
                                invalid={errors && errors.fromDateTime}
                            />
                            {<FormFeedback>{errors && errors.fromDateTime}</FormFeedback>}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className='me-3'>
                            <Label>To Date <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="date"
                                name="toDateTime"
                                placeholder="Select To Date"
                                onChange={validateOnChangeDate}
                                min={formFields.fromDateTime || moment(current).format("YYYY-MM-DD")}
                                invalid={errors && errors.toDateTime}
                            />
                            {<FormFeedback>{errors && errors.toDateTime}</FormFeedback>}
                        </FormGroup>
                    </Col>
                </Row >
                {/* <br></br> */}
                {props.LeaveCount && props.LeaveCount.applyingLeaveDays && props.LeaveCount.applyingLeaveDays.length > 0 ? (
                    <>
                        <Row className='m-0 p-0'>
                            <Col>
                                <Label>LOP Count: {props.LeaveCount && props.LeaveCount.lopLeaveCount}</Label>
                            </Col>
                            <Col>
                                <Label>Privilege Leave: {props.LeaveCount && props.LeaveCount.privilegeLeaveCount}</Label>
                            </Col>
                            <Col>
                                <Label>Total Leave: {props.LeaveCount && props.LeaveCount.totalLeaveCount}</Label>
                            </Col>
                        </Row>
                    </>
                ) : ''}
                <Row className='m-0 p-0'>
                    <FormGroup>
                        <Label>
                            Reason <span className="text-danger">*</span>
                        </Label>
                        <Input
                            onFocus={handleFocus}
                            type="text"
                            name="leaveReason"
                            placeholder="Enter Reason"
                            onChange={validateOnChange}
                            value={reasons && reasons.leaveReason}
                            invalid={errors && errors.leaveReason}
                        />
                        {<FormFeedback>{errors && errors.leaveReason}</FormFeedback>}
                    </FormGroup>
                </Row>
                <br></br>
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateLeave}
                        disabled={props.isPending || props.createLeaveCountErrorMessage || props.LeaveCount && props.LeaveCount.errMsg || props.createAvailableLeaveCountErrorMessage}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    form: state.form,
    isPending: state.leaveTracker.isPending,
    errorMessage: state.leaveTracker.errorMessage,
    Dates: state.leaveTracker.Dates,
    createLeaveCountErrorMessage: state.leaveTracker.createLeaveCountErrorMessage,
    isLeaveCountPending: state.leaveTracker.isLeaveCountPending,
    LeaveCount: state.leaveTracker.LeaveCount,
    availableLeaveCount: state.leaveTracker.availableLeaveCount,
    createAvailableLeaveCountErrorMessage: state.leaveTracker.createAvailableLeaveCountErrorMessage,
});
const mapDispatchToProps = (dispatch) => ({
    updateFormAction: (data) => dispatch(updateForm(data)),
    clearFormAction: () => dispatch(clearForm()),
    getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
        dispatch(getLeaveTypeAsyncSelect(searchValue, callback)),
    createLeaveAction: (formFields, reasons, filteredData) => dispatch(createLeave(formFields, reasons, filteredData)),
    getLeaveCount: (formFields) => dispatch(getLeaveCountAction(formFields)),
    clearCreateLeaveError: () => dispatch(clearCreateLeaveError()),
    ClearAvailableLeaveCount: () => dispatch(ClearAvailableLeaveCount()),
    ClearLeaveCount: () => dispatch(ClearLeaveCount()),
    getAvailableLeaveCount: (formFields) => dispatch(getAvailableLeaveCount(formFields)),
    createLeaveError: () => dispatch(createLeaveError()),
    clearLeaveTrackerActionError: () => dispatch(clearLeaveTrackerActionError()),
    clearLeaveCountError: () => dispatch(clearLeaveCountError()),
    createLeaveSuccess: () => dispatch(createLeaveSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create)