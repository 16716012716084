import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar';
import { ElementToolTip } from '../../Projects/vwToolTip';
import { ElementReportToolTip } from '../../Projects/vwToolTipReport';
import moment from 'moment-timezone';
import './ProjectCard.css'
import VWModel from '../../../../components/Modal/VWModal';
import ProjectReportModal from './ProjectReportModal';
import { connect } from 'react-redux';
import { closeReportTimeSheetModalAction, openReportTimeSheetModalAction } from '../../../../store/Modules/Timesheet/Reports/actions';

const ProjectCard = (props) => {
    const { data, dates, isTimeSheetReportModalOpen, closeReportTimeSheetModalAction, openReportTimeSheetModalAction } = props;
    const getUserColor = (userName) => {
        const hash = Array.from(userName).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const hue = hash % 360; // Hue value for HSL color
        return `hsl(${hue}, 70%, 60%)`; // Return a color based on the hue
    };

    useEffect(() => {
        // Reinitialize tooltips after each render
        document.querySelectorAll('.tooltip').forEach((tooltip) => {
            // Assuming a method to reinitialize tooltips
            ElementToolTip.reinitialize(tooltip);
        });
    }, []);

    const [selectedUser, setSelectedUser] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => {
        // closeReportTimeSheetModalAction();
        setIsModalOpen(false);
    };
    // const handleOpenModal = (user) => {
    //     setSelectedUser(user);
    //     setIsModalOpen(true);
    //     // openReportTimeSheetModalAction();
    // }
    const handleOpenModal = (user, isMore = false) => {
        if (isMore) {
            setSelectedUser(data.ProjectUsers.slice(3)); // Pass remaining users for "+more"
        } else {
            setSelectedUser([user]); // Pass the selected user
        }
        setIsModalOpen(true);
    };
    console.log('isTimeSheetReportModalOpen:', isTimeSheetReportModalOpen);
    console.log('selectedUser:', selectedUser);

    return (
        <div className="card-container">
            <div className="project-card">
                <div className="projectcard-header">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <Avatar name={(data && data.ProjectName) || null} round size="40" className="avatar" />
                        <div className="projectcard-header-info">
                            <h5><span style={{ cursor: 'pointer' }} id={`projectname-${data.Id}`}>{data.ProjectName || "N/A"}</span><ElementToolTip id={`projectname-${data.Id}`} name={`Project Name: ${data.ProjectName}`} /></h5>
                            <p><span style={{ cursor: 'pointer' }} id={`projectmanagername-${data.Id}`}>{data.ProjectManagerUserName || "N/A"}</span><ElementToolTip id={`projectmanagername-${data.Id}`} name={`Project Manager: ${data.ProjectManagerUserName}`} /></p>
                        </div>
                    </div>
                </div>
                <div className="date-range">
                    <span role="img" aria-label="calendar">📅</span> <span id={`projectstartdate-${data.Id}`}>{data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'}</span> - <span id={`projectenddate-${data.Id}`}>{data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}</span>
                    <ElementToolTip id={`projectstartdate-${data.Id}`} name={`Project Start Date`} />
                    <ElementToolTip id={`projectenddate-${data.Id}`} name={`Project End Date`} />
                </div>
                <div className="date-range">
                    <div >
                        <span role="img" aria-label="clock"><strong>🕒 Project Hours : </strong>{data.TotalHrs || 0} hrs</span>
                    </div>
                    <div >
                        <span role="img" aria-label="clock"><strong>🕒 Logged Hours : </strong>{data.CustomDatesHrs || 0} hrs</span>
                    </div>
                </div>
                {/* <div className="date-range hours-container">
                    <div className="hours-item">
                        <span role="img" aria-label="clock">🕒</span>
                        <span id={`projecthours-${data.Id}`}>{data.TotalHrs || 0} hrs</span>
                        <ElementToolTip id={`projecthours-${data.Id}`} name="Project Total Hours" />
                    </div>
                    <div className="hours-item">
                        <span role="img" aria-label="clock">🕒</span>
                        <span id={`custom-date-${data.Id}`}>{data.CustomDatesHrs || 0} hrs</span>
                        <ElementToolTip id={`custom-date-${data.Id}`} name="Logged Hours" />
                    </div>
                </div> */}
                {/* <div className="timesheet-user-summary mt-2">
                    <button className="total-Hrs" style={{ cursor: 'default' }}>
                        <span className=''>{data.TotalHrs || 0}</span>
                        Total Hrs
                    </button>
                    <button className="current-month" style={{ cursor: 'default' }}>
                        <span>{data.CurrentMonthHrs || 0}</span>
                        Current Month Hrs
                    </button>
                </div>
                <div className="timesheet-user-summary mt-2">
                    <button className="current-week" style={{ cursor: 'default' }}>
                        <span>{data.CurrentWeekHrs || 0}</span>
                        Current Week Hrs
                    </button>
                    <button className="custom-date" style={{ cursor: 'default' }}>
                        <span>{data.CustomDatesHrs || 0}</span>
                        Custom Dates Hrs
                    </button>
                </div> */}
                <div className="assigned-users">
                    <div className="avatar-group" >
                        {data.ProjectUsers &&
                            data.ProjectUsers.slice(0, 3).map((user, index) => (
                                <div
                                    key={index}
                                    className="avatar-group-item"
                                    id={`user-tooltip-${data.Id}-${index}`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleOpenModal(user)}
                                >
                                    {user.ProfilePicture ? (
                                        <img
                                            src={user.ProfilePicture}
                                            alt={user.UserName}
                                            className="rounded-circle avatar-xs"
                                        />
                                    ) : (
                                        <div
                                            className="fallback-avatar"
                                            style={{ backgroundColor: getUserColor(user.UserName) }}
                                        >
                                            {user.UserName.charAt(0).toUpperCase()}
                                        </div>
                                    )}
                                    <ElementReportToolTip id={`user-tooltip-${data.Id}-${index}`} name={user.UserName} />
                                </div>
                            ))}

                        {data.ProjectUsers && data.ProjectUsers.length > 3 && (
                            <div
                                className="avatar-group-item more-users"
                                id={`more-users-tooltip-${data.Id}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleOpenModal(null, true)}
                            >
                                <div
                                    className="rounded-circle avatar"
                                    style={{
                                        backgroundColor: getUserColor('more-users'),
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    +{data.ProjectUsers.length - 3}
                                </div>
                                <ElementReportToolTip
                                    id={`more-users-tooltip-${data.Id}`}
                                    name={
                                        <div>
                                            {data.ProjectUsers.slice(3).map((user, index) => (
                                                <div key={index}>
                                                    <small>{user.UserName}</small>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                />
                            </div>
                        )}

                    </div>
                </div>

            </div>
            <VWModel
                modalSize="extra-large"
                header="TIMESHEET REPORT"
                // isOpen={props.isTimeSheetReportModalOpen}
                isOpen={isModalOpen}
                handleClose={handleClose}
            >
                <ProjectReportModal data={selectedUser} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isTimeSheetReportModalOpen: state.TimesheetReport.isTimeSheetReportModalOpen,
});
const mapDispatchToProps = (dispatch) => ({
    closeReportTimeSheetModalAction: () => dispatch(closeReportTimeSheetModalAction()),
    openReportTimeSheetModalAction: () => dispatch(openReportTimeSheetModalAction()),

});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard)