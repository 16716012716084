import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, InputGroup, Table, Tooltip } from "reactstrap";
import VWModel from "../../../../components/Modal/VWModal";
import CreateLeads from "./create";
import { getPager } from "../../../../helper/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faFont, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { clearOpportunityExportListError, closeOpportunityCreate, getActionAsyncSelect, getActionListAsyncSelect, getCurrentPhaseAsyncSelect, getCurrentPhaseListAsyncSelect, getOpportunityExportList, getOpportunityList, getTypeAsyncSelect, getTypeListAsyncSelect, openOpportunityCreate } from "../../../../store/Modules/crm/Opportunity/actions";
import moment from 'moment-timezone';
import Async from "react-select/async"
import Select from 'react-select';
import '../../../Modules/viewList.css'
import { OPPORTUNITY_EXPORT_HEADERS } from "../../../../store/Modules/crm/Opportunity/constants";
import { excelPrint } from "../../../../helper/export";
import UITooltip from "../../../../CommonData/Data/UITooltip";
import { ElementToolTip } from "../../Projects/vwToolTip";

const statusOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Closed won', value: 'closed won' },
    { label: 'Closed lost', value: 'closed lost' },
]
const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const CrmOpportunityList = (props) => {

    const path = window.location.search;
    const params = new URLSearchParams(path);
    console.log("path:", path);
    console.log("params:", params);

    const StartDate = params.get('startDate');
    const EndDate = params.get('endDate');

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { opportunityExportListErrorMessage, isOpportunityExportListPending, opportunityListErrorMessage, isOpportunityListPending, opportunityListCount, opportunityList, isCreateOpportunityOpen, opportunityExportList, currentProfile } = props;
    //----------------------------ROLE-------------------------------------------

    const { userAppDetails } = currentProfile || [];
    const appData = userAppDetails && userAppDetails.filter((app) => app.appName === "CRM");
    const { appRoleDetails } = appData.length > 0 ? appData[0] : null;
    const adminRoleData = appRoleDetails && appRoleDetails.filter((role) => role.roleName === "Administrator");
    const isAdmin = adminRoleData && adminRoleData.length > 0;

    const buildQueryContactName = () => {
        const { ContactEmail } = filter || {};
        let searchContactName = '';
        if (ContactEmail) {
            searchContactName += `${ContactEmail.replace(/\+/g, "%2B")}`;
        }
        return searchContactName
    }
    const buildQueryCompanyName = () => {
        const { CompanyName } = filter || {};
        let searchCompanyName = '';
        if (CompanyName) {
            searchCompanyName += `${CompanyName}`;
        }
        return searchCompanyName
    }
    const buildQueryExpectedValue = () => {
        const { ExpectedValue } = filter || {};
        let searchExpectedValue = '';
        if (ExpectedValue) {
            searchExpectedValue += `${ExpectedValue}`;
        }
        return searchExpectedValue
    }
    const buildQueryStage = () => {
        const { Stage } = filter || {};
        let searchStage = '';
        if (Stage) {
            searchStage += `${Stage}`;
        }
        return searchStage
    }
    const buildQuerytotalDays = () => {
        const { TotalDays } = filter || {};
        let searchtotalDays = '';
        if (TotalDays) {
            searchtotalDays += `${TotalDays}`;
        }
        return searchtotalDays
    }
    const buildQueryOpportunityName = () => {
        const { OpportunityName } = filter || {};
        let searchOpportunityName = '';
        if (OpportunityName) {
            searchOpportunityName += `${OpportunityName}`;
        }
        return searchOpportunityName
    }
    const buildQueryOpCategoryName = () => {
        const { OpCategoryName } = filter || {};
        let searchOpCategoryName = '';
        if (OpCategoryName) {
            searchOpCategoryName += `${OpCategoryName}`;
        }
        return searchOpCategoryName
    }
    const buildQueryPendingDays = () => {
        const { PendingDays } = filter || {};
        let searchOpPendingDays = '';
        if (PendingDays) {
            searchOpPendingDays += `${PendingDays}`;
        }
        return searchOpPendingDays
    }
    const buildQueryOpTypeName = () => {
        const { OpTypeName } = filter || {};
        let searchOpTypeName = '';
        if (OpTypeName) {
            searchOpTypeName += `${OpTypeName}`;
        }
        return searchOpTypeName
    }
    const buildQueryOpActionName = () => {
        const { OpActionName } = filter || {};
        let searchOpActionName = '';
        if (OpActionName) {
            searchOpActionName += `${OpActionName}`;
        }
        return searchOpActionName
    }
    const buildQuerysearchOpStatus = () => {
        const { OpStatus } = filter || {};
        let searchOpStatus = '';
        if (OpStatus) {
            searchOpStatus += `${OpStatus}`;
        }
        return searchOpStatus
    }
    const buildQuerysearchOpCurrentPhase = () => {
        const { OpCurrentPhase } = filter || {};
        let searchOpCurrentPhase = '';
        if (OpCurrentPhase) {
            searchOpCurrentPhase += `${OpCurrentPhase}`;
        }
        return searchOpCurrentPhase
    }
    const buildQuerysearchWeightedValue = () => {
        const { WeightedValue } = filter || {};
        let searchWeightedValue = '';
        if (WeightedValue) {
            searchWeightedValue += `${WeightedValue}`;
        }
        return searchWeightedValue
    }
    const buildQuerysearchStartDate = () => {
        const { StartDate } = filter || {};
        let searchStartDate = '';
        if (StartDate) {
            searchStartDate += `${StartDate}`;
        }
        return searchStartDate
    }
    const buildQuerysearchEndDate = () => {
        const { EndDate } = filter || {};
        let searchEndDate = '';
        if (EndDate) {
            searchEndDate += `${EndDate}`;
        }
        return searchEndDate
    }
    const buildQueryLeadCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }
    const buildQueryLeadCreatedDate = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedDate = '';
        if (CreatedTimeStamp) {
            searchCreatedDate += `${CreatedTimeStamp}`;
        }
        return searchCreatedDate
    }
    const searchType = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            OpTypeName: label,
        })
    }
    const searchAction = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            OpActionName: label,
        })
    }
    const searchStatus = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            OpStatus: label,
        })
    }
    const searchCurrentPhase = (value) => {
        const { label } = value || {};
        const phase = label ? label.split('-') : null;
        const name = phase && phase.length > 0 ? phase[0].trim() : null;
        setFilter({
            ...filter,
            OpCurrentPhase: name,
        })
    }
    const handleCurrentPhase = (inputValue, callback) => {
        const { getCurrentPhaseAsyncSelect } = props;
        return getCurrentPhaseAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleAction = (inputValue, callback) => {
        const { getActionAsyncSelect } = props;
        return getActionAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleType = (inputValue, callback) => {
        const { getTypeAsyncSelect } = props;
        return getTypeAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }

    const setPage = (page = 1) => {
        props.getOpportunityList(filter, page, PageSize, buildQueryContactName(), buildQueryCompanyName(), buildQueryExpectedValue(), buildQueryStage(), buildQuerytotalDays(), buildQueryOpportunityName(), buildQueryOpCategoryName(), buildQueryPendingDays(), buildQueryOpTypeName(), buildQueryOpActionName(), buildQuerysearchOpStatus(), buildQuerysearchOpCurrentPhase(), buildQuerysearchWeightedValue(), buildQuerysearchStartDate(), buildQuerysearchEndDate(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy());

        setPager(prevPager => {
            const totalPages = Math.ceil(opportunityListCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(opportunityListCount, 1, PageSize)
        setPager(currentPager)
    }, [opportunityListCount])

    useEffect(() => {
        if (StartDate || EndDate) {
            setFilter({
                ...filter,
                CreatedTimeStamp: StartDate,
                UpdatedTimeStamp: EndDate,
            })
        } else {
            const currentPageNumber = Pager && Pager.currentPage;
            setPage(currentPageNumber);
        }
        props.clearOpportunityExportListError();
    }, [])

    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    // ----------------------------------------SORTING-----------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };
    console.log("opportunityList: ", opportunityList)
    const sortedLeadsList = [...opportunityList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'TotalDays') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        if (sortOrder === 'PendingDays') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        if (sortOrder === 'IsTerminated') {
            return sortDirection === 'asc' ? b.IsTerminated - a.IsTerminated : a.IsTerminated - b.IsTerminated;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    //------------------------------------------ACCOUNT-CREATE-MODAL------------------------------------------------
    const createOpportunityOpen = () => {
        props.openOpportunityCreate();
    }
    const handleClose = () => {
        props.closeOpportunityCreate();
    }
    const [tooltipOpen, setTooltipOpen] = useState({});

    const toggleTooltip = (itemId) => {
        setTooltipOpen({
            ...tooltipOpen,
            [itemId]: !tooltipOpen[itemId]
        });
    };

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/crm/opportunity/details/${Id}`);
    };
    const handleExports = () => {
        props.getOpportunityExport(filter, buildQueryContactName(), buildQueryCompanyName(), buildQueryExpectedValue(), buildQueryStage(), buildQuerytotalDays(), buildQueryOpportunityName(), buildQueryOpCategoryName(), buildQueryPendingDays(), buildQueryOpTypeName(), buildQueryOpActionName(), buildQuerysearchOpStatus(), buildQuerysearchOpCurrentPhase(), buildQuerysearchWeightedValue(), buildQuerysearchStartDate(), buildQuerysearchEndDate(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy())
            .then((response) => {
                if (response) {
                    const exportOpportunityList = [];
                    const headers = OPPORTUNITY_EXPORT_HEADERS;
                    const headerSizes = [30, 20, 30, 50, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
                    response && response.forEach((element) => {
                        exportOpportunityList.push({
                            companyName: element.companyName || "N/A",
                            opportunityName: element.opportunityName || "N/A",
                            contactName: element.contactName || "N/A",
                            contactEmail: element.contactEmail || "N/A",
                            expectedValue: element.expectedValue || '0',
                            weightedValue: element.weightedValue || '0',
                            opCategoryName: element.opCategoryName || "N/A",
                            opSubCategory: element.opSubCategory || "N/A",
                            totalDays: element.totalDays || 0,
                            pendingDays: element.pendingDays || 0,
                            opTypeName: element.opTypeName || "N/A",
                            opActionName: element.opActionName || "N/A",
                            opStatus: element.opStatus || "N/A",
                            opCurrentPhase: element.opCurrentPhase || "N/A",
                            startDate: element.startDate ? moment(element.startDate).format('DD MMM YYYY') : "NA",
                            endDate: element.endDate ? moment(element.endDate).format('DD MMM YYYY') : "NA",
                            createdTimeStamp: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD MMM YYYY') : "NA",
                            CreatedBy: element.createdBy || "NA",
                        });
                    });
                    excelPrint(exportOpportunityList, "Opportunity-List", headers, headerSizes);
                }
            })
    }
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    console.log("filter:", filter);

    return (
        <div className="page-content">
            <Card>
                <CardTitle tag="h4"
                    className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                    <strong className='d-flex align-items-center'>OPPORTUNITY  -&nbsp;<Badge color="danger" id={'opportunityCount'}>{isOpportunityListPending ? 0 : (props && props.opportunityListCount || 0)}</Badge>
                        <ElementToolTip id={'opportunityCount'} name={
                            'Opportunity Count'
                        } />
                    </strong>
                    <div>
                        <Button color="primary" onClick={createOpportunityOpen}>
                            CREATE
                        </Button>&nbsp;
                        {isAdmin &&
                            <Button
                                className="me-4"
                                color="success"
                                onClick={handleExports}
                                disabled={opportunityList && opportunityList.length <= 0 || isOpportunityExportListPending}
                            >
                                {isOpportunityExportListPending ? 'EXPORTING...' : 'EXPORT'}
                            </Button>
                        }
                    </div>
                </CardTitle>
            </Card>
            <Card>
                <CardBody className="p-4">
                    {(opportunityListErrorMessage || opportunityExportListErrorMessage) ? <div className='w-100'>
                        {(opportunityListErrorMessage || opportunityExportListErrorMessage) && (
                            <VWMessage type="danger" defaultMessage={opportunityListErrorMessage || opportunityExportListErrorMessage} baseClassName="alert" ></VWMessage>
                        )}
                    </div> : ""}
                    <div style={{ height: "60vh", overflowX: "auto" }}>
                        <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                <tr>

                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CompanyName')} className='hand-cursor'>Account Name {sortOrder === 'CompanyName' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('OpportunityName')} className='hand-cursor' >Opportunity Name {sortOrder === 'OpportunityName' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('ContactName')} className='hand-cursor' >Contact Name {sortOrder === 'ContactName' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('ExpectedValue')} className='hand-cursor'>Opportunity Value {sortOrder === 'ExpectedValue' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('WeightedValue')} className='hand-cursor'>Weighted Value {sortOrder === 'WeightedValue' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('OpCategoryName')} className='hand-cursor'>Category {sortOrder === 'OpCategoryName' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('TotalDays')} className='hand-cursor'>Total Days {sortOrder === 'TotalDays' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('PendingDays')} className='hand-cursor'>Pending Days {sortOrder === 'PendingDays' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('OpTypeName')} className='hand-cursor'>Type {sortOrder === 'OpTypeName' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('OpActionName')} className='hand-cursor'>Action {sortOrder === 'OpActionName' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('OpStatus')} className='hand-cursor'>Status {sortOrder === 'OpStatus' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('OpCurrentPhase')} className='hand-cursor'>Current Phase {sortOrder === 'OpCurrentPhase' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th
                                        onClick={() => handleSort('StartDate')} className='hand-cursor'
                                    >
                                        Start Date &nbsp;
                                        {sortOrder === 'StartDate' && (
                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                        )}
                                    </th>
                                    <th
                                        onClick={() => handleSort('EndDate')} className='hand-cursor'
                                    >
                                        End Date &nbsp;
                                        {sortOrder === 'EndDate' && (
                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                        )}
                                    </th>

                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CreatedBy')} className='hand-cursor'>Created By {sortOrder === 'CreatedBy' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>View</th>

                                </tr>
                                <tr>
                                    <th>
                                        <Input
                                            type="text"
                                            name="CompanyName"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="OpportunityName"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="ContactEmail"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            onKeyDown={
                                                (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            type="number"
                                            name="ExpectedValue"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                            min='0'
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            onKeyDown={
                                                (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            type="number"
                                            name="WeightedValue"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                            min='0'
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="OpCategoryName"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            onKeyDown={
                                                (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            type="number"
                                            name="TotalDays"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                            min='0'
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            onKeyDown={
                                                (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            type="number"
                                            name="PendingDays"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                            min='0'
                                        />
                                    </th>
                                    <th>

                                        <Async
                                            styles={customStyles}
                                            name="OpTypeName"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleType}
                                            onChange={searchType}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="OpActionName"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleAction}
                                            onChange={searchAction}
                                        />
                                    </th>
                                    <th>

                                        <Select
                                            styles={customStyles}
                                            name="OpStatus"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            options={statusOptions}
                                            onChange={searchStatus}
                                        />
                                    </th>
                                    <th>

                                        <Async
                                            styles={customStyles}
                                            name="OpCurrentPhase"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleCurrentPhase}
                                            onChange={searchCurrentPhase}
                                        />
                                    </th>
                                    <th >
                                        <Input
                                            type='date'
                                            placeholder='Search'
                                            name='StartDate'
                                            onChange={searchInputValidation}
                                            value={filter && filter.StartDate}
                                            onKeyPress={handleKeyPress}
                                            max="9999-12-31"
                                        /></th>
                                    <th >
                                        <Input
                                            type='date'
                                            placeholder='Search'
                                            name='EndDate'
                                            onChange={searchInputValidation}
                                            value={filter && filter.EndDate}
                                            onKeyPress={handleKeyPress}
                                            max="9999-12-31"
                                        /></th>
                                    <th>
                                        <InputGroup style={{ width: "200px" }}>
                                            {filterSelectionOption === 'name' ?
                                                <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                    <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                    <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                style={{ width: "120px" }}
                                                type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                max={filterSelectionOption !== "name" && "9999-12-31"}
                                            />
                                        </InputGroup>
                                    </th>

                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isOpportunityListPending && (opportunityList && opportunityList.length > 0) ?
                                    sortedLeadsList && sortedLeadsList.map((data, index) => (
                                        <tr key={index} onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                            <td className='ps-3'>{data.CompanyName || "N/A"}</td>
                                            <td className='ps-3'>{data.OpportunityName || "N/A"}</td>
                                            <td className='ps-3'>{data.ContactName || "N/A"}<br />
                                                <small>{data.ContactEmail || ""}</small></td>
                                            <td className='ps-3'>{data.ExpectedValue || '0'}</td>
                                            <td className='ps-3'>{data.WeightedValue || '0'}</td>
                                            <td className='ps-3'>{data.OpCategoryName || "N/A"}
                                                <br />
                                                <small>{data.OpSubCategory || ""}</small>
                                            </td>
                                            <td className='ps-3'>{data.TotalDays || 0}</td>
                                            <td className='ps-3'>{data.PendingDays || 0}</td>
                                            <td className='ps-3'>{data.OpTypeName || "N/A"}</td>
                                            <td className='ps-3'>{data.OpActionName || "N/A"}</td>
                                            <td className='ps-3'>{data.OpStatus || "N/A"}</td>
                                            <td className='ps-3'>{`${data.OpCurrentPhase} - ${data.OpCurrentPhaseValue}%` || 'N/A'}</td>
                                            <td className='ps-3'>{data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'}</td>
                                            <td className='ps-3'>{data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}</td>
                                            <td className='ps-3'>{data.CreatedBy || "N/A"}<br />
                                                {data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                                            <td className='sticky-column' style={{ backgroundColor: "white" }}>
                                                <Link className="btn btn-sm btn-light" to={`/crm/opportunity/details/${data.Id}`}>
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                        </tr>)) :
                                    isOpportunityListPending ?
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <VWSpinner />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <NoRecord />
                                                </div>
                                            </div>
                                        </div>}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <CardFooter>
                    {opportunityList && opportunityList.length > 0 ?
                        <VWPagination pager={Pager} setPage={setPage} totalRecords={opportunityListCount} pageSize={PageSize} /> : null
                    }
                </CardFooter>
            </Card>
            <VWModel
                header="CREATE OPPORTUNITY"
                modalSize="extra-large"
                isOpen={isCreateOpportunityOpen}
                handleClose={handleClose}
            >
                <CreateLeads handleClose={handleClose} filteredData={filter} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isCreateOpportunityOpen: state.crmOpportunity.isCreateOpportunityOpen,
    opportunityList: state.crmOpportunity.opportunityList,
    opportunityListCount: state.crmOpportunity.opportunityListCount,
    isOpportunityListPending: state.crmOpportunity.isOpportunityListPending,
    opportunityListErrorMessage: state.crmOpportunity.opportunityListErrorMessage,
    opportunityExportList: state.crmOpportunity.opportunityExportList,
    opportunityExportListErrorMessage: state.crmOpportunity.opportunityExportListErrorMessage,
    isOpportunityExportListPending: state.crmOpportunity.isOpportunityExportListPending,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    clearOpportunityExportListError: () => dispatch(clearOpportunityExportListError()),
    openOpportunityCreate: () => dispatch(openOpportunityCreate()),
    closeOpportunityCreate: () => dispatch(closeOpportunityCreate()),
    getOpportunityExport: (filter, searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchtotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy) => dispatch(getOpportunityExportList(filter, searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchtotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy)),
    getCurrentPhaseAsyncSelect: (searchValue, callback) => dispatch(getCurrentPhaseListAsyncSelect(searchValue, callback)),
    getActionAsyncSelect: (searchValue, callback) => dispatch(getActionListAsyncSelect(searchValue, callback)),
    getTypeAsyncSelect: (searchValue, callback) => dispatch(getTypeListAsyncSelect(searchValue, callback)),
    getOpportunityList: (filter, page, PageSize, searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchtotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy) => dispatch(getOpportunityList(filter, page, PageSize, searchContactName, searchCompanyName, searchExpectedValue,
        searchStage, searchtotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy))
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmOpportunityList)