import { CLEAR_CREATE_ORGANIZATION_ERROR, CLEAR_ORG_USER_BASED_ORG_LIST_ERROR, CLEAR_ORGANIZATION_DETAILS_ERROR, CLEAR_ORGANIZATION_EXPORT_LIST_ERROR, CLEAR_ORGANIZATION_LIST_ERROR, CLEAR_UPDATE_ORGANIZATION_ERROR, CLOSE_CREATE_ORGANIZATION_MODAL, CLOSE_EDIT_ORGANIZATION_MODAL, CREATE_ORGANIZATION_ERROR, CREATE_ORGANIZATION_PENDING, CREATE_ORGANIZATION_SUCCESS, OPEN_CREATE_ORGANIZATION_MODAL, OPEN_EDIT_ORGANIZATION_MODAL, SET_ORG_USER_BASED_ORG_LIST, SET_ORGANIZATION_DETAILS, SET_ORGANIZATION_DETAILS_ERROR, SET_ORGANIZATION_DETAILS_PENDING, SET_ORGANIZATION_EXPORT_LIST, SET_ORGANIZATION_EXPORT_LIST_ERROR, SET_ORGANIZATION_EXPORT_PENDING, SET_ORGANIZATION_LIST, SET_ORGANIZATION_LIST_ERROR, SET_ORGANIZATION_PENDING, SET_ORGANIZATION_USER_BASED_ORG_LIST_PENDING, UPDATE_ORGANIZATION_ERROR, UPDATE_ORGANIZATION_PENDING, UPDATE_ORGANIZATION_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    orgListError: null,
    isCreateOrganizationModalOpen: false,
    isEditOrganizationModalOpen: false,
    OrganizationList: [],
    totalRecords: 0,
    OrganizationDetails: null,
    orgDetailsError: null,
    isUpdateOrganizationPending: false,
    updateOrganizationErrorMessage: null,
    isOrganizationListPending: false,
    isOrganizationDetailsPending: false,
    orgExportListError: null,
    isOrganizationExportListPending: false,
    OrganizationExportList: [],
    orgExportListError: null,
    isOrgUserBasedOrgListPending: false,
    orgUserBasedOrgListError: null,
    OrgUserBasedOrgList: [],
    OrgUserBasedOrgtotalRecords: 0
};
export default (localState = initialState, action) => {
    switch (action.type) {

        case OPEN_CREATE_ORGANIZATION_MODAL: {
            return {
                ...localState,
                isCreateOrganizationModalOpen: true,
            };
        }

        case CLOSE_CREATE_ORGANIZATION_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateOrganizationModalOpen: false,
            };
        }
        case OPEN_EDIT_ORGANIZATION_MODAL: {
            return {
                ...localState,
                isEditOrganizationModalOpen: true,
            };
        }

        case CLOSE_EDIT_ORGANIZATION_MODAL: {
            return {
                ...localState,
                updateOrganizationErrorMessage: null,
                isEditOrganizationModalOpen: false,
            };
        }
        case SET_ORGANIZATION_EXPORT_LIST: {
            const { organizationExportList } = action.payload;
            return {
                ...localState,
                isOrganizationExportListPending: false,
                OrganizationExportList: organizationExportList,
                // totalRecords: organizationList["@odata.count"],
            }
        }
        case SET_ORGANIZATION_LIST: {
            const { organizationList } = action.payload;
            return {
                ...localState,
                isOrganizationListPending: false,
                OrganizationList: organizationList && organizationList.value,
                totalRecords: organizationList["@odata.count"],
            }
        }

        case SET_ORGANIZATION_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isOrganizationListPending: false,
                orgListError: errorMessage,
            }
        }
        case SET_ORGANIZATION_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isOrganizationExportListPending: false,
                orgExportListError: errorMessage,
            }
        }
        case CLEAR_ORGANIZATION_LIST_ERROR: {
            return {
                ...localState,
                orgListError: null,
            }
        }
        case CLEAR_ORGANIZATION_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                orgExportListError: null,
                isOrganizationExportListPending: false,
            }
        }
        case SET_ORGANIZATION_DETAILS: {
            const { OrganizationDetails } = action.payload;
            return {
                ...localState,
                isOrganizationDetailsPending: false,
                OrganizationDetails: OrganizationDetails,
            }
        }
        case SET_ORGANIZATION_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            // console.log("errorMessage: ", errorMessage)
            return {
                ...localState,
                OrganizationDetails: {},
                isOrganizationDetailsPending: false,
                orgDetailsError: errorMessage,
            }
        }
        case CLEAR_ORGANIZATION_DETAILS_ERROR: {
            return {
                ...localState,
                orgDetailsError: null,
            }
        }
        case CREATE_ORGANIZATION_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage: errorMessage,
                isPending: false,
            };
        }
        case CREATE_ORGANIZATION_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CREATE_ORGANIZATION_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_CREATE_ORGANIZATION_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CLEAR_UPDATE_ORGANIZATION_ERROR: {
            return {
                ...localState,
                updateOrganizationErrorMessage: null,
                isUpdateOrganizationPending: false,
            };
        }
        case UPDATE_ORGANIZATION_PENDING: {
            return {
                ...localState,
                isUpdateOrganizationPending: true,
            };
        }
        case UPDATE_ORGANIZATION_SUCCESS: {
            return {
                ...localState,
                isUpdateOrganizationPending: false,
            };
        }
        case UPDATE_ORGANIZATION_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateOrganizationErrorMessage: errorMessage,
                isUpdateOrganizationPending: false,
            };
        }
        case SET_ORGANIZATION_PENDING: {
            return {
                ...localState,
                orgListError: null,
                isOrganizationListPending: true,
                // totalRecords: 0,
            }
        }
        case SET_ORGANIZATION_EXPORT_PENDING: {
            return {
                ...localState,
                orgExportListError: null,
                isOrganizationExportListPending: true
            }
        }
        case SET_ORGANIZATION_DETAILS_PENDING: {
            return {
                ...localState,
                isOrganizationDetailsPending: true,
                orgDetailsError: null,
            }
        }
        case SET_ORGANIZATION_USER_BASED_ORG_LIST_PENDING: {
            return {
                ...localState,
                isOrgUserBasedOrgListPending: true,
                orgUserBasedOrgListError: null,
            }
        }
        case CLEAR_ORG_USER_BASED_ORG_LIST_ERROR: {
            return {
                ...localState,
                orgUserBasedOrgListError: null,
                isOrgUserBasedOrgListPending: false,
            }
        }
        case SET_ORG_USER_BASED_ORG_LIST: {
            const { OrgUserList } = action.payload;
            console.log("OrgUserList: ", OrgUserList);

            return {
                ...localState,
                isOrgUserBasedOrgListPending: false,
                OrgUserBasedOrgList: OrgUserList && OrgUserList.value,
                OrgUserBasedOrgtotalRecords: OrgUserList["@odata.count"],
            }
        }
        default: {
            return localState;
        }
    }
};