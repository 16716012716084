import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import Async from "react-select/async"
import {
    closeContactsCreate,
    getAccountsAsyncSelect,
    openContactsCreate
} from "../../../../store/Modules/crm/Contacts/actions";
import {
    clearCreateOpportunityError,
    createNewOpportunity,
    createOpportunitySuccess,
    getActionAsyncSelect,
    getCategoryAsyncSelect,
    getContactAsyncSelectAction,
    getContantsAsyncSelect,
    getCrmUserAsyncSelect,
    getCurrentPhaseAsyncSelect,
    getSubCategoryAsyncSelectAction,
    getTypeAsyncSelect
} from "../../../../store/Modules/crm/Opportunity/actions";
import Select from 'react-select';
import { Link } from "react-router-dom";
import CreateContact from '../Contacts/create'
import VWModel from "../../../../components/Modal/VWModal";
import { faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { file } from "jszip";


const currencyOptions = [
    { label: 'INR', value: 'INR' },
]
const statusOptions = [
    { label: 'open', value: 'open' },
    { label: 'closed won', value: 'closed won' },
    { label: 'closed lost', value: 'closed lost' },
]
const CreateLeads = (props) => {
    const { errorMessage, isOpportunityCreatePending, filteredData } = props;
    const [formFields, setFormFields] = useState({ IndexFile: [], description: '', expectedValue: '', opCurrency: '' });
    const [errors, setErrors] = useState();
    const [isActive, setIsActive] = useState(true);
    const [existingContact, setExistingContact] = useState([]);
    const [existingSubCategory, setExistingSubCategory] = useState([]);
    const [prevContactValue, setPrevContactValue] = useState([]);
    const [prevMainCategory, setPrevMainCategory] = useState([]);
    const [prevOppStatus, setOppStatus] = useState({ label: 'open', value: 'open' });
    const [Files, setFile] = useState([]);
    const [keyChange, setKeyChange] = useState(1);
    const [subKeyChange, setSubKeyChange] = useState(1);
    const [accountId, setAccountId] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [autoFocus, setAutoFocus] = useState(true);

    useEffect(() => {
        props.clearCreateOpportunityError();
        props.createOpportunitySuccess();
        setFormFields({ ...formFields, opStatus: prevOppStatus && prevOppStatus.value });
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setFormFields({
            ...formFields,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleChangeCurrencySelect = (value) => {
        if (value === null) {
            setFormFields({
                ...formFields,
                opCurrency: null
            })
        } else {
            setFormFields({
                ...formFields,
                opCurrency: value && value.value
            })
            setErrors({
                ...errors,
                opCurrency: null
            })
        }
    }
    const handleChangeStatusSelect = (value) => {
        if (value === null) {
            setOppStatus(value);
            setFormFields({
                ...formFields,
                opStatus: null
            })
        } else {
            setOppStatus(value);
            setFormFields({
                ...formFields,
                opStatus: value && value.value
            })
            setErrors({
                ...errors,
                opStatus: null
            })
        }
    }
    const handleAddContact = (value) => {

        setFormFields({ ...formFields, contactId: value.value });
        setPrevContactValue(value);
        setErrors({ ...errors, contactId: '' });
    }

    //-----------------------------------------Add Date field---------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;

        const formattedDate = value || null;
        setFormFields({ ...formFields, [name]: formattedDate });
        if (name === 'startDate') {
            setErrors({ ...errors, startDate: '' });
        }
        if (name === 'endDate') {
            setErrors({ ...errors, endDate: '' });
        }
    };

    const handleAccountSelect = (value) => {
        console.log("value", value);

        setFormFields({
            ...formFields,
            companyName: value.label,
            companyId: value.value,
            contactId: ''

        })
        setPrevContactValue({ label: 'Select your contact name', value: '' })
        setKeyChange(keyChange + 1);
        setAccountId(value.value);
        setErrors({
            ...errors,
            companyId: null
        })
    }
    const handleMainCategorySelect = (value) => {
        setFormFields({
            ...formFields,
            categoryId: value.label,
            opCategoryId: ''
        })
        setPrevMainCategory({ label: 'Select your sub category', value: '' })
        setSubKeyChange(subKeyChange + 1);
        setSelectedCategory(value.label);
        setErrors({
            ...errors,
            categoryId: ''
        })
    }

    const handleTypeSelect = (value) => {
        setFormFields({
            ...formFields,
            opTypeId: value.value
        })
        setErrors({
            ...errors,
            opTypeId: null
        })
    }
    const handleActionSelect = (value) => {
        setFormFields({
            ...formFields,
            opActionId: value.value
        })
        setErrors({
            ...errors,
            opActionId: null
        })
    }
    const handleActionCurrentPhase = (value) => {
        setFormFields({
            ...formFields,
            OpCurrentPhaseId: value.value
        })
        setErrors({
            ...errors,
            OpCurrentPhaseId: null
        })
    }
    const handleAddSubCategory = (value) => {

        setFormFields({ ...formFields, opCategoryId: value.value });
        setPrevMainCategory(value);
        setErrors({ ...errors, opCategoryId: '', categoryId: '' });
    }

    const handleAccounts = (inputValue, callback) => {
        const { getAccountsAsyncSelect } = props;
        return getAccountsAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleMainCategory = (inputValue, callback) => {
        const { getCategoryAsyncSelect } = props;
        return getCategoryAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleSubCategory = (inputValue, callback) => {
        const { getSubCategoryAsyncSelectAction } = props;
        if (selectedCategory) {
            return getSubCategoryAsyncSelectAction(selectedCategory, inputValue.length > 0 ? inputValue : '', callback);
        }
        else {
            callback([]);
        }
    }
    const handleType = (inputValue, callback) => {
        const { getTypeAsyncSelect } = props;
        return getTypeAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleAction = (inputValue, callback) => {
        const { getActionAsyncSelect } = props;
        return getActionAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleCurrentPhase = (inputValue, callback) => {
        const { getCurrentPhaseAsyncSelect } = props;
        return getCurrentPhaseAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleContacts = (inputValue, callback) => {
        const { getContactAsyncSelectAction } = props;
        if (accountId) {
            return getContactAsyncSelectAction(accountId, inputValue.length > 0 ? inputValue : '', callback);
        }
        else {
            callback([]);
        }
    }
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleAccountCreate();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    const handleAccountCreate = (e) => {
        const { OpCurrentPhaseId, opActionId, oppourtunityName, endDate, stage, ownerUserId, companyId, contactId, startDate, opCategoryId,
            categoryId, opTypeId } = formFields || {};
        let currentErrors = {};

        if (!oppourtunityName) {
            currentErrors.oppourtunityName = "Opportunity name is required";
        }
        if (!categoryId) {
            currentErrors.categoryId = "Category is required";
        }
        if (!opCategoryId) {
            currentErrors.opCategoryId = "Sub category is required";
        }
        if (!contactId) {
            currentErrors.contactId = "Contact name is required";
        }
        if (!endDate) {
            currentErrors.endDate = "End date is required";
        }
        if (!startDate) {
            currentErrors.startDate = "Start date is required";
        }
        if (!companyId) {
            currentErrors.companyId = "Account name is required";
        }
        if (!opTypeId) {
            currentErrors.opTypeId = "Type is required";
        }
        if (!opActionId) {
            currentErrors.opActionId = "Action is required";
        }
        if (!OpCurrentPhaseId) {
            currentErrors.OpCurrentPhaseId = "Current phase is required";
        }
        setErrors(currentErrors);

        if (Object.keys(currentErrors).length === 0) {
            console.log("create:", formFields);
            props.CreateOpportunity(formFields, filteredData);
        }
        if (e) {
            e.preventDefault();
        }

    }

    //------------------------------------------ACCOUNT-CREATE-MODAL------------------------------------------------
    const createContactOpen = () => {
        console.log("open modal");

        props.openContactsCreate();
    }
    const onRefreshColor = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "grey"
    }
    const onRefresh = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "lightseagreen"
        console.log("onRefresh");

        if (formFields && formFields.companyId) {
            // console.log("working");

            // props.getContactAsyncSelectAction(formFields && formFields.companyId, '', (existingProjectUsers) => {
            //     setExistingContact(existingProjectUsers);
            // });
            // console.log("existingContact 1: ", existingContact);
            setKeyChange(keyChange + 1);
        }
        console.log("keyChange: ", keyChange);
    }
    const handleClose = () => {
        props.closeContactsCreate();
        console.log("submitted");
        console.log("companyId:", formFields && formFields.companyId);
    }

    const handleDone = (data) => {
        console.log("data:", data);
        if (formFields && formFields.companyId) {
            // props.getContactAsyncSelectAction(data, '', (existingProjectUsers) => {
            //     setExistingContact(existingProjectUsers);
            // });
            setKeyChange(keyChange + 1);
        }
    };

    console.log("error message: ", errorMessage);
    console.log("formFields: ", formFields);
    console.log("errors: ", errors);
    console.log("existingContact 2: ", existingContact);

    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        console.log('selectedFiles:', selectedFiles);
        setError('');

        const validFiles = selectedFiles.filter(file => {
            if (file.size > 5 * 1024 * 1024) {
                setError(prev => `${prev} ${file.name} exceeds 5 MB.`);
                return false; // Exclude invalid files
            }
            return true; // Include valid files
        });
        console.log("validFiles:", validFiles);

        // Update state with valid files
        setFile(prevFiles => [...prevFiles, ...validFiles]);
        setFormFields({ ...formFields, IndexFile: [...Files, ...validFiles] });
        // Reset input if no files are valid
        if (validFiles.length === 0) {
            e.target.value = ""; // Reset input
        }
    };
    const handleRemoveFile = (index) => {
        const updatedFiles = Files.filter((_, i) => i !== index);
        setFile(updatedFiles);
        setFormFields({ ...formFields, IndexFile: updatedFiles });

        // if (updatedFiles.length === 0) {
        //     document.querySelector('input[name="files"]').value = ""; // Reset input
        // }
        document.getElementById('file-input').value = "";
    };

    console.log("files:", Files);
    console.log("keyChange: ", keyChange);
    return (
        <div>
            <Form>
                {errorMessage &&
                    (<VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />)
                }
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-primary border-5 mb-3"> &nbsp;opportunity Information</div>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Opportunity Name <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                autoFocus={true}
                                type="text"
                                name="oppourtunityName"
                                placeHolder="Enter your opportunity Name"
                                onChange={validateOnchange}
                                value={formFields && formFields.oppourtunityName}
                                invalid={errors && errors.oppourtunityName}
                            />
                            <FormFeedback>{errors && errors.oppourtunityName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Account Name <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.companyId) ? 'red' : "silver"
                                    })
                                }}
                                name="companyId"
                                defaultOptions
                                placeholder="Select your account name"
                                loadOptions={handleAccounts}
                                onChange={handleAccountSelect}
                            />
                            {(errors && errors.companyId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.companyId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label className="d-flex justify-content-between"><div>Contact Name <span className="text-danger">*</span>
                                {/* <div> */} &nbsp;
                                <FontAwesomeIcon icon={faRefresh}
                                    id="icon"
                                    onClick={onRefresh}
                                    onMouseLeave={onRefreshColor}
                                    style={{ cursor: 'pointer' }}
                                    data-toggle="tooltip"
                                    title="Refresh Contact Name" /></div>
                                <Link
                                    onClick={createContactOpen}
                                >&nbsp;
                                    Create Contact
                                </Link></Label>
                            <Async
                                key={keyChange}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.contactId) ? 'red' : "silver"
                                    })
                                }}
                                // className="vm-react-select"
                                name="contactId"
                                onChange={handleAddContact}
                                placeholder="Select your contact name"
                                isSearchable
                                defaultOptions
                                loadOptions={handleContacts}
                                value={prevContactValue}
                            />
                            {(errors && errors.contactId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.contactId}
                                    </div>
                                </FormText>
                            }

                        </FormGroup>

                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Category <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.categoryId) ? 'red' : "silver"
                                    })
                                }}
                                name="categoryId"
                                defaultOptions
                                placeholder="Select your category"
                                loadOptions={handleMainCategory}
                                onChange={handleMainCategorySelect}
                            />
                            {(errors && errors.categoryId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.categoryId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Sub Category <span className="text-danger">*</span></Label>
                            <Async
                                key={subKeyChange}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opCategoryId) ? 'red' : "silver"
                                    })
                                }}
                                name="opCategoryId"
                                onChange={handleAddSubCategory}
                                placeholder="Select your sub category"
                                isSearchable
                                defaultOptions
                                loadOptions={handleSubCategory}
                                value={prevMainCategory}
                                isDisabled={!(formFields && formFields.categoryId)}
                            />
                            {(errors && errors.opCategoryId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opCategoryId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Opportunity Value</Label>
                            <Input
                                onFocus={handleFocus}
                                onKeyDown={
                                    (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                min='0'
                                type="number"
                                name="expectedValue"
                                placeHolder="Enter your opportunity value"
                                onChange={validateOnchange}
                                value={formFields && formFields.expectedValue}
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Currency</Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opCurrency) ? 'red' : "silver"
                                    })
                                }}
                                name='opCurrency'
                                placeholder='Select your currency'
                                options={currencyOptions}
                                onChange={handleChangeCurrencySelect}
                                isClearable
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Start Date <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="date"
                                name="startDate"
                                placeholder="Select your start sate"
                                onChange={validateOnChangeDate}
                                // disabled={!isEdited}
                                max={formFields && formFields.endDate}
                                invalid={errors && errors.startDate}
                            />
                            {(errors && errors.startDate) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.startDate}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>End Date <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="date"
                                name="endDate"
                                placeholder="Select your end date"
                                onChange={validateOnChangeDate}
                                min={formFields && formFields.startDate}
                                invalid={errors && errors.endDate}
                            />
                            {(errors && errors.endDate) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.endDate}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Type <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opTypeId) ? 'red' : "silver"
                                    })
                                }}
                                name="opTypeId"
                                defaultOptions
                                placeholder="Select your type"
                                loadOptions={handleType}
                                onChange={handleTypeSelect}
                            />
                            {(errors && errors.opTypeId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opTypeId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Action <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opActionId) ? 'red' : "silver"
                                    })
                                }}
                                name="opActionId"
                                defaultOptions
                                placeholder="Select your action"
                                loadOptions={handleAction}
                                onChange={handleActionSelect}
                            />
                            {(errors && errors.opActionId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opActionId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Status</Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opStatus) ? 'red' : "silver"
                                    })
                                }}
                                name='opStatus'
                                placeholder='Select your status'
                                options={statusOptions}
                                onChange={handleChangeStatusSelect}
                                value={prevOppStatus}
                                isClearable
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Current Phase <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.OpCurrentPhaseId) ? 'red' : "silver"
                                    })
                                }}
                                name="OpCurrentPhaseId"
                                defaultOptions
                                placeholder="Select your current phase"
                                loadOptions={handleCurrentPhase}
                                onChange={handleActionCurrentPhase}
                            />
                            {(errors && errors.OpCurrentPhaseId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.OpCurrentPhaseId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Description</Label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="description"
                                placeHolder="Enter your opportunity notes"
                                onChange={validateOnchange}
                                value={formFields && formFields.description}
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Attach Documents</Label>
                            <Input
                                onFocus={handleFocus}
                                id="file-input"
                                type="file"
                                name="IndexFile"
                                accept="*/*"
                                multiple
                                onChange={handleFileChange}
                            /> {error && <div className="text-danger">{error}</div>}
                            <div>
                                <Table className="mb-2">
                                    <tbody>
                                        {
                                            /* {Files && Files.length > 0 ? ( */
                                            Files && Files.map((file, i) => (
                                                // <div key={file.id} >
                                                <tr key={i} >
                                                    <td className="me-2 mt-2">Selected file: {file.name}</td>
                                                    <td>
                                                        <Button
                                                            type="button"
                                                            color="danger"
                                                            size="sm"
                                                            className="mt-0"
                                                            onClick={() => handleRemoveFile(i)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                            // ): (
                                            //         <tr>
                                            //             <td colSpan="2" className="text-center text-muted">
                                            //                 No files selected
                                            //             </td>
                                            //         </tr>
                                            //     )
                                        }

                                    </tbody>
                                </Table>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <hr className="mt-0" />
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleAccountCreate}
                        disabled={isOpportunityCreatePending}
                    >
                        {isOpportunityCreatePending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form >
            <VWModel
                header="CREATE CONTACTS"
                modalSize="extra-large"
                isOpen={props.isCreateContactsOpen}
                handleClose={handleClose}
            >
                <CreateContact
                    handleClose={handleClose}
                    AccId={formFields && formFields.companyId}
                    AccName={formFields && formFields.companyName}
                    handleDone={handleDone}
                />
            </VWModel>
        </div >
    )
}

const mapStateToProps = (state) => ({
    isCreateContactsOpen: state.crmContacts.isCreateContactsOpen,
    errorMessage: state.crmOpportunity.opportunityCreateErrorMessage,
    isOpportunityCreateSuccess: state.crmOpportunity.isOpportunityCreateSuccess,
    isOpportunityCreatePending: state.crmOpportunity.isOpportunityCreatePending,
})
const mapDispatchToProps = (dispatch) => ({
    openContactsCreate: () => dispatch(openContactsCreate()),
    closeContactsCreate: () => dispatch(closeContactsCreate()),
    createOpportunitySuccess: () => dispatch(createOpportunitySuccess()),
    CreateOpportunity: (data, filteredData) => dispatch(createNewOpportunity(data, filteredData)),
    clearCreateOpportunityError: () => dispatch(clearCreateOpportunityError()),
    getCrmUserAsyncSelect: (searchValue, callback) => dispatch(getCrmUserAsyncSelect(searchValue, callback)),
    getAccountsAsyncSelect: (searchValue, callback) => dispatch(getAccountsAsyncSelect(searchValue, callback)),
    getContantsAsyncSelect: (searchValue, callback) => dispatch(getContantsAsyncSelect(searchValue, callback)),
    getTypeAsyncSelect: (searchValue, callback) => dispatch(getTypeAsyncSelect(searchValue, callback)),
    getActionAsyncSelect: (searchValue, callback) => dispatch(getActionAsyncSelect(searchValue, callback)),
    getCurrentPhaseAsyncSelect: (searchValue, callback) => dispatch(getCurrentPhaseAsyncSelect(searchValue, callback)),
    getCategoryAsyncSelect: (searchValue, callback) => dispatch(getCategoryAsyncSelect(searchValue, callback)),
    getContactAsyncSelectAction: (id, searchValue, callback) => dispatch(getContactAsyncSelectAction(id, searchValue, callback)),
    getSubCategoryAsyncSelectAction: (oppCategory, searchValue, callback) => dispatch(getSubCategoryAsyncSelectAction(oppCategory, searchValue, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateLeads);