import React, { useEffect, useState } from 'react'
import VWButton from '../../../../components/vwButton/VWButton'
import { connect } from 'react-redux'
import { CreateTaskAction, UpdateTasks, clearCreateTaskError, closeMilestoneCreate, getAssignedUserAsyncSelect, getDependenciesAsyncSelect, getMilestoneDate, getMilestoneSelectAction, getProjectNameAsyncSelect, getTaskScopeAsyncSelectAction, getTaskStatusAsyncMilestoneSelectAction, getTaskStatusAsyncSelectAction, getTaskStatusAsyncUserSelectAction, getTaskTypeAsyncSelectAction, openMilestoneCreate, updateTaskSuccess } from '../../../../store/Modules/ProjectPortal/Task/actions'
import moment from 'moment-timezone';
import Async from 'react-select/async';
import Select from 'react-select';
import { Button, Col, FormFeedback, FormGroup, FormText, Input, InputGroup, Label, Row } from 'reactstrap';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWModel from '../../../../components/Modal/VWModal';
import CreateMilestones from '../Milestones/CreateMilestones';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "./TaskEditor.css"

const priorityOption = [
  { label: "Critical", value: "Critical" },
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
  { label: "Lowest", value: "Lowest" },
]
const effortOption = [
  { label: "XS", value: "XS" },
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "XXL", value: "XXL" },
]


const CloneTask = (props) => {

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    container: (provided) => ({
      ...provided,
      minWidth: '150px',
    }),
  };

  const [formFields, setFormFields] = useState({});
  const [PrevProjectName, setPrevProjectName] = useState([]);
  const [existingProjectUser, setExistingProjectUser] = useState([]);
  const [prevAssignedUser, setPrevAssignedUser] = useState([]);
  const [existingMilestone, setExistingMilestone] = useState([]);
  const [newMilestone, setPrevMilestone] = useState([]);
  const [taskType, setTaskType] = useState([]);
  const [priorityType, setPriorityType] = useState({});
  const [effortType, setEffortType] = useState();
  const [statusType, setStatusType] = useState([]);
  const [errors, setErrors] = useState({});
  const [autoFocus, setAutoFocus] = useState(true);
  // const [isEdited, setIsEdited] = useState(true);
  const [milestoneError, setMilestoneError] = useState('');
  const [isActive, setisActive] = useState(false);
  const [scopeType, setScopeType] = useState([]);
  const [milestoneStartDate, setMilestoneStartDate] = useState(null);
  const [milestoneEndDate, setMilestoneEndDate] = useState(null);

  const [keyChange, setKeyChange] = useState(1);
  const [projectId, setProjectId] = useState();
  const [prevReviewerUser, setPrevReviewerUser] = useState([]);

  const { TaskDetails, TaskKanbanDetails, currentProfile } = props || {};
  const [newDependencies, setNewDependencies] = useState([]);

  const [milestonekeyChange, setMilestoneKeyChange] = useState(1);
  const [milestoneId, setMilestoneId] = useState();
  const [statuskeyChange, setStatusKeyChange] = useState(1);
  const { userAppDetails } = currentProfile || [];
  useEffect(() => {
    props.clearCreateTaskError();
    props.updateTaskSuccess();
    console.log("TaskKanbanDetails:", TaskKanbanDetails);
    if (props.TaskKanbanDetails) {
      const { dependencies } = TaskKanbanDetails;
      const { dependenciesName } = TaskDetails || {};
      const queriedDependencies = dependencies.length > 0 ? dependencies[0] : null;
      setFormFields({ ...TaskKanbanDetails, dependencies: queriedDependencies });
      setNewDependencies(queriedDependencies ? { label: dependenciesName || 'Select dependencies', value: queriedDependencies || '' } : 'Select dependencies');
      if (TaskDetails && TaskDetails.mileStoneId) {
        setMilestoneKeyChange(milestonekeyChange + 1);
        setMilestoneId(TaskDetails && TaskDetails.mileStoneId);
        setStatusKeyChange(statuskeyChange + 1);
      }
      setScopeType({ label: TaskKanbanDetails && TaskKanbanDetails.taskScopeName, value: TaskKanbanDetails && TaskKanbanDetails.taskScopeId })
      setPrevMilestone({ label: TaskKanbanDetails && TaskKanbanDetails.milestoneName, value: TaskKanbanDetails && TaskKanbanDetails.mileStoneId });
      setTaskType({ label: TaskKanbanDetails && TaskKanbanDetails.taskTypeName, value: TaskKanbanDetails && TaskKanbanDetails.taskTypeId });
      setPriorityType({ label: TaskKanbanDetails && TaskKanbanDetails.priority, value: TaskKanbanDetails && TaskKanbanDetails.priority });
      setEffortType(TaskKanbanDetails.effort ? { label: TaskKanbanDetails && TaskKanbanDetails.effort, value: TaskKanbanDetails && TaskKanbanDetails.effort } : "Select effort");
      setPrevProjectName({ label: TaskKanbanDetails && TaskKanbanDetails.projectName, value: TaskKanbanDetails && TaskKanbanDetails.projectId });
      setPrevAssignedUser(TaskDetails && TaskDetails.assignedUserId ? { label: `${TaskDetails && TaskDetails.assignedUserName || ''} | ${TaskDetails && TaskDetails.assignedUserEmail || ''}`, value: TaskDetails && TaskDetails.assignedUserId } : '');
      setStatusType({ label: `${TaskKanbanDetails && TaskKanbanDetails.taskStatus} - ${TaskKanbanDetails && TaskKanbanDetails.currentStatusPercentage}`, value: TaskKanbanDetails && TaskKanbanDetails.taskStatus });
      setisActive(TaskKanbanDetails && TaskKanbanDetails.allowUserToEditStatus);
      setProjectId(TaskKanbanDetails && TaskKanbanDetails.projectId);
      setPrevReviewerUser({ label: `${TaskDetails && TaskDetails.reviewerUserName || 'N/A'} | ${TaskDetails && TaskDetails.reviewerUserEmail || 'N/A'}`, value: TaskDetails && TaskDetails.reviewerUserId });

      if (TaskKanbanDetails && TaskKanbanDetails.projectId) {
        setKeyChange(keyChange + 1)
      }
    }
    else {
      console.log("TaskDetails:", TaskDetails);

      if (TaskDetails) {
        props.getMilestoneDate(TaskDetails && TaskDetails.mileStoneId);
        const { dependencies, dependenciesName } = TaskDetails;
        const queriedDependencies = dependencies.length > 0 ? dependencies[0] : null;
        setFormFields({ ...TaskDetails, dependencies: queriedDependencies });
        setNewDependencies(queriedDependencies ? { label: dependenciesName || 'Select dependencies', value: queriedDependencies || '' } : 'Select dependencies');
        if (TaskDetails && TaskDetails.mileStoneId) {
          setMilestoneKeyChange(milestonekeyChange + 1);
          setStatusKeyChange(statuskeyChange + 1);
        }
        setScopeType({ label: TaskDetails && TaskDetails.taskScopeName || 'Select scope', value: TaskDetails && TaskDetails.taskScopeId })
        setPrevMilestone({ label: TaskDetails && TaskDetails.milestoneName, value: TaskDetails && TaskDetails.mileStoneId });
        setTaskType({ label: TaskDetails && TaskDetails.taskTypeName || 'Select task type', value: TaskDetails && TaskDetails.taskTypeId });
        setPriorityType(TaskDetails.priority ? { label: TaskDetails && TaskDetails.priority || 'Select priority', value: TaskDetails && TaskDetails.priority } : "Select priority");
        setEffortType(TaskDetails.effort ? { label: TaskDetails && TaskDetails.effort || 'Select effort', value: TaskDetails && TaskDetails.effort } : "Select effort");
        setPrevProjectName({ label: TaskDetails && TaskDetails.projectName, value: TaskDetails && TaskDetails.projectId });
        setPrevAssignedUser(TaskDetails && TaskDetails.assignedUserId ? { label: `${TaskDetails && TaskDetails.assignedUserName || 'N/A'} | ${TaskDetails && TaskDetails.assignedUserEmail || 'N/A'}`, value: TaskDetails && TaskDetails.assignedUserId } : "Select");
        setStatusType({ label: `${TaskDetails && TaskDetails.taskStatus} - ${TaskDetails && TaskDetails.currentStatusPercentage || 0}%`, value: TaskDetails && TaskDetails.taskStatus });
        setisActive(TaskDetails && TaskDetails.allowUserToEditStatus);
        setPrevReviewerUser(((TaskDetails && TaskDetails.reviewerUserName) && (TaskDetails && TaskDetails.reviewerUserEmail)) ? { label: `${TaskDetails && TaskDetails.reviewerUserName || 'N/A'} | ${TaskDetails && TaskDetails.reviewerUserEmail || 'N/A'}`, value: TaskDetails && TaskDetails.reviewerUserId } : 'Select reviewer');

        setProjectId(TaskDetails && TaskDetails.projectId);
        if (TaskDetails && TaskDetails.projectId) {
          setKeyChange(keyChange + 1)
        }
        setMilestoneId(TaskDetails && TaskDetails.mileStoneId);
        if (TaskDetails && TaskDetails.mileStoneId) {
          setMilestoneKeyChange(milestonekeyChange + 1);
          setStatusKeyChange(statuskeyChange + 1);
        }
      }
    }
  }, [TaskKanbanDetails])
  //-----------------------------------------Default Options---------------------------------------------------------
  const handleProject = (inputValue, callback) => {
    const { getProjectNameAsyncSelectAction } = props;
    return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleMilestone = (inputValue, callback) => {
    if (projectId) {
      const { getMilestoneSelectAction } = props;
      return getMilestoneSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
    }
    else {
      callback([]);
    }
  };
  const handleAssignUser = (inputValue, callback) => {
    if (projectId) {
      const { getAssignedUserAsyncSelectAction } = props;
      return getAssignedUserAsyncSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
    }
    else {
      callback([]);
    }
  };
  //-----------------------------------------Update Project Name-----------------------------------------------------
  const handleProjectName = (data) => {
    setKeyChange(keyChange + 1);
    setPrevReviewerUser({ label: 'Select reviewer', value: '' });
    setPrevAssignedUser({ label: 'Select user', value: '' })
    setPrevMilestone({ label: 'Select milestone name', value: '' })
    setPrevProjectName(data);
    setFormFields({ ...formFields, assignedUserId: '', mileStoneId: '', reviewerUserId: '' })
    if (data.value) {
      setProjectId(data.value);
    }
  }
  //----------------------------------------Update Date Field--------------------------------------------------------
  const validateOnChangeDate = (e) => {
    const { name, value } = e.target;
    // const formattedDate = value ? moment(value).format() : null;
    const formattedDate = value;
    setFormFields({ ...formFields, [name]: formattedDate });
    if (name === 'startDate') {
      setErrors({ ...errors, startDate: '' });
    }
    if (name === 'endDate') {
      setErrors({ ...errors, endDate: '' });
    }
  };
  //----------------------------------------Update Input Field-------------------------------------------------------
  const maxLength = 150;
  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
    setFormFields({ ...formFields, [input.name]: value });
    if (input.name === 'taskName') {
      if (value && value.length >= maxLength) {
        setErrors({ ...errors, taskName: `TaskName name cannot exceed ${maxLength} characters.` });
      } else {
        setErrors({ ...errors, taskName: '' });
      }
    }
  };
  //----------------------------------------Assigned User-----------------------------------------------------------
  const handleAddProjectUser = (value) => {
    if (value === null) {
      setFormFields({ ...formFields, assignedUserId: null });
      setPrevAssignedUser(value);
    } else {
      setFormFields({ ...formFields, assignedUserId: value.value });
      setPrevAssignedUser(value);
      setErrors({ ...errors, assignedUserId: '' });
    }
  }

  const handleAddReviewerUser = (value) => {
    if (value === null) {
      setFormFields({ ...formFields, reviewerUserId: null });
      setPrevReviewerUser(value);
    } else {
      setFormFields({ ...formFields, reviewerUserId: value.value });
      setPrevReviewerUser(value);
    }
  }
  const App = userAppDetails.filter((i) => {
    return (i.appName === "ProjectPortal") ? i.appId : ""
  })
  //----------------------------------------- Dependencies dropdown --------------------------------------------
  const handleDependencies = (inputValue, callback) => {
    if (milestoneId) {
      const { getDependenciesAsyncSelect } = props;
      return getDependenciesAsyncSelect(App[0] && App[0].appId, milestoneId, inputValue.length > 0 ? inputValue : '', callback);
    }
    else {
      callback([]);
    }
  }
  const handleTaskScope = (inputValue, callback) => {
    const { getTaskScopeAsyncSelectAction } = props;
    return getTaskScopeAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSelectTaskType = (inputValue, callback) => {
    const { getTaskTypeAsyncSelectAction } = props;
    return getTaskTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
  };
  //-------------------------------------------------------------------------------------------------------------------
  const handleDependenciesSelect = (value) => {
    if (value === null) {
      setNewDependencies(value);
      setFormFields({
        ...formFields,
        dependencies: null,
        dependenciesName: null
      })
    } else {
      setNewDependencies(value);
      setFormFields({
        ...formFields,
        dependencies: value.value,
        dependenciesName: value.label
      })
    }

  }
  //--------------------------------------------Form Editor Description-------------------------------------------
  // const handleEditorChange = (content) => {
  //   // setFormFields({ ...formFields, "contentText": content });
  //   setFormFields(prevFields => ({ ...prevFields, description: content }));
  // };
  const MAX_SIZE = 300 * 1024; // 300 KB

  const handleEditorChange = (content) => {
    let totalSize = new Blob([content]).size; // Calculate HTML size
    console.log('totalSize:', totalSize);

    // Extract image sources (base64 and URLs)
    const imgTags = content.match(/<img[^>]+src="([^">]+)"/g) || [];

    let imagePromises = imgTags.map(tag => {
      const srcMatch = tag.match(/src="([^">]+)"/);
      if (!srcMatch) return Promise.resolve(0);

      const src = srcMatch[1];

      // If image is base64 encoded, calculate its size
      if (src.startsWith('data:image')) {
        let base64Str = src.split(',')[1]; // Extract base64 data
        let decodedSize = (base64Str.length * (3 / 4)) - (base64Str.endsWith('==') ? 2 : base64Str.endsWith('=') ? 1 : 0);
        return Promise.resolve(decodedSize);
      }

      // If image is an external URL, fetch it to determine size
      return fetch(src, { method: 'HEAD' })
        .then(res => res.headers.get('content-length') || 0)
        .then(size => parseInt(size, 10))
        .catch(() => 0);
    });

    Promise.all(imagePromises).then(imageSizes => {
      totalSize += imageSizes.reduce((sum, size) => sum + size, 0);
      console.log('totalSize:', totalSize, 'MAX_SIZE', MAX_SIZE);
      if (totalSize > MAX_SIZE) {
        setErrors({ ...errors, description: "Content size exceeds the 300KB limit. Please reduce text or images." });
        setFormFields(prevFields => ({ ...prevFields, description: content }));
      } else {
        setFormFields(prevFields => ({ ...prevFields, description: content }));
        setErrors({ ...errors, description: "" });
      }
    });
  };
  //----------------------------------------Update Milestone--------------------------------------------------------
  const handleMilestoneId = (Value) => {
    const { MilestoneError, isCurrentUserManagerOrMilestoneOwner, label, value, IsMilestoneOwnerUpdateStatus, IsUserUpdateStatus } = Value || {};
    setFormFields({ ...formFields, mileStoneId: value, dependencies: '', dependenciesName: '', allowFlexibleDueDates: Value.AllowFlexibleDueDates, isMilestoneOwnerUpdateStatus: IsMilestoneOwnerUpdateStatus, isUserUpdateStatus: IsUserUpdateStatus, taskStatus: '', currentStatusPercentage: '' });
    setNewDependencies('Select dependencies');
    setPrevMilestone(Value);
    props.getMilestoneDate(value);
    setErrors({ ...errors, mileStoneId: '' });
    //--------------------------------------------------
    setMilestoneError(MilestoneError);
    setMilestoneKeyChange(milestonekeyChange + 1);
    setStatusKeyChange(statuskeyChange + 1);
    setMilestoneId(value);
    setStatusType({ label: 'Select status', value: '' })
  }
  //---------------------------------------Update Dropdown field-----------------------------------------------------
  const handleEffort = (value) => {
    if (value === null) {
      setFormFields({ ...formFields, effort: null });
      setEffortType(value);
    } else {
      setFormFields({ ...formFields, effort: value.value });
      setEffortType(value);
    }
  }
  const handleTaskStatus = (options) => {
    const { value, Percentage } = options;
    setFormFields({ ...formFields, taskStatus: value, currentStatusPercentage: Percentage });
    setErrors({ ...errors, taskStatus: '' });
    setStatusType(options);
  }
  const handlePriority = (value) => {
    if (value === null) {
      setFormFields({ ...formFields, priority: null })
      setPriorityType(value);
    } else {
      setFormFields({ ...formFields, priority: value.value })
      setPriorityType(value);
    }
  }
  const handleTaskType = (value) => {
    setFormFields({ ...formFields, taskTypeId: value.value });
    setTaskType(value);
    setErrors({ ...errors, taskTypeId: '' });
  }

  const handleScope = (value) => {
    setFormFields({ ...formFields, taskScopeId: value.value });
    setScopeType(value);
    setErrors({ ...errors, taskScopeId: '' });
  }
  //------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log('autoFocus:', autoFocus);
      if (event.key === 'Escape') {
        console.log('escape key');
        setAutoFocus(false);
        const inputs = document.querySelectorAll('input');
        inputs.forEach(input => input.blur());
      }
      else if (event.key === 'Tab') {
        console.log('tab key');
        setAutoFocus(true);
      }
      else if (event.key === 'Enter') {
        const activeElement = document.activeElement;

        if (activeElement.type === 'checkbox') {
          activeElement.click();
        }
        else if (autoFocus === false) {
          console.log('enter button');
          handleUpdateTask();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [autoFocus]);

  const handleFocus = (event) => {
    setAutoFocus(true);
  };
  //------------------------------------------Update Task------------------------------------------------------------
  const handleUpdateTask = () => {
    const newErrors = {};
    const startDate = moment(formFields.startDate);
    const endDate = moment(formFields.endDate);
    const projectStart = moment(milestoneStartDate);
    const projectEnd = moment(milestoneEndDate);

    if (errors && errors.description) {
      newErrors.description = errors.description;
    }
    if (!formFields.taskScopeId || !formFields.taskScopeId.trim()) {
      newErrors.taskScopeId = 'Scope is required';
    }
    if (!formFields.projectId || !formFields.projectId.trim()) {
      newErrors.projectId = 'Project name is required';
    }
    if (!formFields.mileStoneId || !formFields.mileStoneId.trim()) {
      newErrors.mileStoneId = 'Milestone name is required';
    }
    if (!formFields.taskName || !formFields.taskName.trim()) {
      newErrors.taskName = 'Task name is required';
    }
    if (formFields.taskName && formFields.taskName.length >= maxLength) {
      newErrors.taskName = `TaskName name cannot exceed ${maxLength} characters.`;
    }
    if (!formFields.taskTypeId || !formFields.taskTypeId.trim()) {
      newErrors.taskTypeId = 'Task type is required';
    }
    // if (!formFields.assignedUserId || !formFields.assignedUserId.trim()) {
    //   newErrors.assignedUserId = 'Assigned user is required';
    // }
    if (!formFields.taskStatus || !formFields.taskStatus.trim()) {
      newErrors.taskStatus = 'Task status is required';
    }
    if (!formFields.startDate || !formFields.startDate.trim()) {
      newErrors.startDate = 'Start date is required';
    }
    if (!formFields.endDate || !formFields.endDate.trim()) {
      newErrors.endDate = 'End date is required';
    }
    if (formFields && !formFields.allowFlexibleDueDates) {
      if (startDate.isBefore(projectStart) || startDate.isAfter(projectEnd)) {
        newErrors.startDate = `Start date must be between ${projectStart.format('DD/MM/YYYY')} and ${projectEnd.format('DD/MM/YYYY')}`;
      }
      if (endDate.isBefore(startDate) || endDate.isAfter(projectEnd)) {
        newErrors.endDate = `End date must be after start date and before ${projectEnd.format('DD/MM/YYYY')}`;
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      props.CloneTaskAction(App[0] && App[0].appId, formFields, props.PROJECTID);
    }
  }
  //----------------------------------------------------------------------------------------------------------------

  const createMilestoneOpen = () => {
    console.log("open modal");

    props.openMilestoneCreate();
  }

  const handleClose = () => {
    props.closeMilestoneCreate();
  };


  const handleDone = (getProjId) => {
    console.log("getProjId:", getProjId);

    if (getProjId === formFields && formFields.projectId) {
      props.getAssignedUserAsyncSelectAction(getProjId, '', (existingProjectUsers) => {
        console.log("existingProjectUsers:", existingProjectUsers);

        setExistingProjectUser(existingProjectUsers);
      });
      props.getMilestoneSelectAction(getProjId, '', (existingMilestone) => {
        setExistingMilestone(existingMilestone)
        console.log("existingMilestone:", existingMilestone);
      })
    }
  };

  const onRefresh = () => {
    let icon = document.getElementById("icon")
    icon.style.color = "lightseagreen"
    console.log("onRefresh");

    if (formFields && formFields.projectId) {
      console.log("working");

      props.getMilestoneSelectAction(formFields && formFields.projectId, '', (existingMilestone) => {
        setExistingMilestone(existingMilestone);
      });
      props.getAssignedUserAsyncSelectAction(formFields && formFields.projectId, '', (existingProjectUsers) => {
        setExistingProjectUser(existingProjectUsers);
      });
    }
    setKeyChange(keyChange + 1)
  }
  const onRefreshColor = () => {
    let icon = document.getElementById("icon")
    icon.style.color = "grey"
  }
  //-------------------------------------Status Change----------------------------------------------------------
  const handleActiveStatusChange = () => {
    setisActive(!isActive);
    setFormFields({ ...formFields, allowUserToEditStatus: !isActive })
  };

  const handleTaskStatusPercentage = (inputValue, callback) => {
    const { getTaskStatusAsyncSelectAction } = props;
    return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleTaskUserStatusPercentage = (inputValue, callback) => {
    const { getTaskStatusAsyncUserSelectAction } = props;
    return getTaskStatusAsyncUserSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
  };

  const handleTaskMilestoneStatusPercentage = (inputValue, callback) => {
    const { getTaskStatusAsyncMilestoneSelect } = props;
    return getTaskStatusAsyncMilestoneSelect(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
  };

  useEffect(() => {
    if (props.MilestoneDate) {
      setMilestoneStartDate(props.MilestoneDate && props.MilestoneDate.startDate);
      setMilestoneEndDate(props.MilestoneDate && props.MilestoneDate.endDate);
    }
  }, [props.MilestoneDate]);

  const startDateMax = formFields.endDate ? moment(formFields.endDate).format('YYYY-MM-DD') : milestoneEndDate ? moment(milestoneEndDate).format('YYYY-MM-DD') : '';
  const endDateMin = formFields.startDate ? moment(formFields.startDate).format('YYYY-MM-DD') : milestoneStartDate ? moment(milestoneStartDate).format('YYYY-MM-DD') : '';


  console.log("formFields:", formFields);
  console.log("description:", formFields.description);
  console.log("errors", errors);
  console.log("priorityType", priorityType);
  console.log("PROJECTID", props.PROJECTID);
  console.log("MilestoneDate:", props.MilestoneDate);
  return (
    <React.Fragment>
      {props.errorMessage && (
        <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
      )}
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Project Name<span className="text-danger"> *</span>
            </Label>
            <Async
              autoFocus={true}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.projectId) ? 'red' : "silver"
                })
              }}
              name="projectId"
              placeholder="Select project name"
              className="vm-react-select"
              isSearchable
              defaultOptions
              loadOptions={handleProject}
              onChange={handleProjectName}
              value={PrevProjectName}
              isDisabled
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.projectId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className="d-flex justify-content-between">
              <div>
                Milestone Name<span className="text-danger"> *</span>
              </div>
              {(formFields && formFields.allowToCreateMilestone) &&
                <div>
                  <FontAwesomeIcon
                    icon={faRefresh}
                    id="icon"
                    onClick={onRefresh}
                    onMouseLeave={onRefreshColor}
                    style={{ cursor: 'pointer' }}
                  />
                  <Link onClick={createMilestoneOpen}>&nbsp;
                    Create Milestone
                  </Link>
                </div>
              }
            </Label>
            <Async
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.mileStoneId) ? 'red' : "silver"
                })
              }}
              key={keyChange}
              name="mileStoneId"
              placeholder="Select milestone name"
              className="vm-react-select"
              isSearchable
              defaultOptions
              loadOptions={handleMilestone}
              onChange={handleMilestoneId}
              value={newMilestone}
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.mileStoneId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Task Name <span className="text-danger"> *</span>
            </Label>
            <Input
              onFocus={handleFocus}
              type="text"
              name="taskName"
              placeholder="Enter task name"
              onChange={validateOnChange}
              value={formFields && formFields.taskName}
              invalid={errors && errors.taskName}
              maxLength={maxLength}
            /><FormFeedback>{errors && errors.taskName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Task Type <span className="text-danger"> *</span>
            </Label>
            <Async
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.taskTypeId) ? 'red' : "silver"
                })
              }}
              name="taskTypeId"
              placeholder="Select task type"
              className="vm-react-select"
              isSearchable
              defaultOptions
              loadOptions={handleSelectTaskType}
              onChange={handleTaskType}
              value={taskType}
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.taskTypeId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Assigned To
            </Label>
            <Async
              key={keyChange}
              name="assignedUserId"
              placeholder="Select assigned user"
              className="vm-react-select"
              isSearchable
              defaultOptions
              loadOptions={handleAssignUser}
              onChange={handleAddProjectUser}
              value={prevAssignedUser}
              isClearable
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Planned Hours </Label>
            <Input
              onFocus={handleFocus}
              type="number"
              name="plannedHours"
              placeholder="Enter planned hours"
              onChange={validateOnChange}
              value={formFields && formFields.plannedHours}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row><Col md={6}>
        <FormGroup>
          <Label>Start Date <span className="text-danger"> *</span></Label>
          {formFields && formFields.allowFlexibleDueDates ?
            <Input
              onFocus={handleFocus}
              type="date"
              name="startDate"
              placeholder="Select start date"
              onChange={validateOnChangeDate}
              value={formFields.startDate ? moment(formFields.startDate).format("YYYY-MM-DD") : ""}
              max={moment(formFields.endDate).format("YYYY-MM-DD")}
              invalid={errors && errors.startDate}
            />
            :
            <Input
              onFocus={handleFocus}
              type="date"
              name="startDate"
              placeholder="Select start date"
              onChange={validateOnChangeDate}
              value={formFields.startDate ? moment(formFields.startDate).format("YYYY-MM-DD") : ""}
              min={milestoneStartDate ? moment(milestoneStartDate).format('YYYY-MM-DD') : ''}
              max={startDateMax}
              invalid={errors && errors.startDate}
            />
          }
          <FormFeedback>{errors && errors.startDate}</FormFeedback>
        </FormGroup>
      </Col>
        <Col md={6}>
          <FormGroup>
            <Label>End Date <span className="text-danger"> *</span></Label>
            {formFields && formFields.allowFlexibleDueDates ?
              <Input
                onFocus={handleFocus}
                type="date"
                name="endDate"
                placeholder="Select end date"
                onChange={validateOnChangeDate}
                value={formFields.endDate ? moment(formFields.endDate).format("YYYY-MM-DD") : ""}
                min={moment(formFields.startDate).format("YYYY-MM-DD")}
                invalid={errors && errors.endDate}
              /> :
              <Input
                onFocus={handleFocus}
                type="date"
                name="endDate"
                placeholder="Select end date"
                onChange={validateOnChangeDate}
                value={formFields.endDate ? moment(formFields.endDate).format("YYYY-MM-DD") : ""}
                min={endDateMin}
                max={milestoneEndDate ? moment(milestoneEndDate).format('YYYY-MM-DD') : ''}
                invalid={errors && errors.endDate}
              />}
            <FormFeedback>{errors && errors.endDate}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Completed Date</Label>
            <Input
              onFocus={handleFocus}
              type="date"
              name="CompletedDate"
              placeholder="Select completed date"
              onChange={validateOnChangeDate}
              value={formFields.CompletedDate ? moment(formFields.CompletedDate).format("YYYY-MM-DD") : ""}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Priority
            </Label>
            <Select
              styles={customStyles}
              name="priority"
              placeholder="Select priority"
              className="vm-react-select"
              isSearchable
              defaultOptions
              options={priorityOption}
              onChange={handlePriority}
              value={priorityType}
              isClearable
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Effort
            </Label>
            <Select
              styles={customStyles}
              name="effort"
              placeholder="Select effort"
              className="vm-react-select"
              isSearchable
              // defaultOptions
              options={effortOption}
              onChange={handleEffort}
              value={effortType}
              isClearable
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Task Status <span className="text-danger"> *</span>
            </Label>
            <Async
              styles={{
                ...customStyles,
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.taskStatus) ? 'red' : "silver"
                })
              }}
              key={statuskeyChange}
              name="taskStatus"
              placeholder="Select status"
              isSearchable
              defaultOptions
              loadOptions={formFields && formFields.isMilestoneOwnerUpdateStatus
                ? handleTaskMilestoneStatusPercentage
                : formFields && formFields.isUserUpdateStatus
                  ? handleTaskUserStatusPercentage
                  : handleTaskStatusPercentage}
              onChange={handleTaskStatus}
              value={statusType}
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.taskStatus}
              </div>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Scope  <span className="text-danger"> *</span>
            </Label>
            <Async
              styles={{
                ...customStyles,
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.taskScopeId) ? 'red' : "silver"
                })
              }}
              name="taskScopeId"
              placeholder="Select scope"
              className="vm-react-select"
              isSearchable
              defaultOptions
              loadOptions={handleTaskScope}
              onChange={handleScope}
              value={scopeType}
            // isDisabled={!isEdited}
            // isDisabled={(formFields && formFields.isUserEdit)}
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.taskScopeId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Reviewer</Label>
            <Async
              styles={customStyles}
              key={keyChange}
              name="reviewerUserId"
              defaultOptions
              placeholder="Select reviewer"
              loadOptions={handleAssignUser}
              onChange={handleAddReviewerUser}
              // isDisabled={(formFields && formFields.isUserEdit)}
              value={prevReviewerUser}
              isClearable
            />
          </FormGroup>
        </Col >
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Dependencies (Depends On)</Label>
            <Async
              styles={customStyles}
              key={milestonekeyChange}
              name="dependencies"
              defaultOptions
              placeholder="Select dependencies"
              loadOptions={handleDependencies}
              isOptionDisabled={(option) => option.value === formFields.id}
              onChange={handleDependenciesSelect}
              value={newDependencies}
              isClearable
            />
          </FormGroup>
        </Col >
        {/* {(formFields && formFields.isUserEdit) ? '' : */}
        <Col md={6}>
          <FormGroup>
            <Label>Allow User To Edit Status</Label>
            <ul className="form-unstyled-list form-status-list">
              <li
                onClick={handleActiveStatusChange}
                className={`form-status-item ${!isActive ? 'active' : ''}`}
              >
                No
              </li>
              <li
                onClick={handleActiveStatusChange}
                className={`form-status-item ${isActive ? 'active' : ''}`}
              >
                Yes
              </li>
            </ul>
          </FormGroup>
        </Col>
        {/* } */}
      </Row>
      <Row>
        <FormGroup>
          <Label>
            Task Description
          </Label>

          <div className="quill-container-task">
            <ReactQuill
              value={formFields && formFields.description}
              onChange={handleEditorChange}
              theme="snow"
              placeholder="Start From Here..."
              // readOnly={!isEdited}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike', 'image'],
                  ['clean']
                ],
              }}
            />
          </div>
          <FormText sx={{ marginLeft: "10px" }}>
            <div className="text-danger">
              {errors && errors.description}
            </div>
          </FormText>
        </FormGroup>

      </Row>
      <br></br>
      <div className="form-group">
        <FormGroup>
          <Button
            className="me-2"
            color="success"
            onClick={handleUpdateTask}
            disabled={props.isPending}
          >
            {(props.isPending) ? <>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              &nbsp; Submitting...
            </>
              : <>Submit</>}
          </Button>
          <Button
            color="danger"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
        </FormGroup>
        <VWModel
          header="CREATE MILESTONE"
          isOpen={props.isCreateMilestoneModalOpen}
          handleClose={handleClose}
        >
          <CreateMilestones projId={formFields && formFields.projectId} ProjName={formFields && formFields.projectName} handleDone={handleDone}
            handleClose={handleClose}
          />
        </VWModel>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  TaskDetails: state.task.TaskDetails,
  isPending: state.task.isPending,
  errorMessage: state.task.errorMessage,
  isCreateMilestoneModalOpen: state.task.isCreateMilestoneModalOpen,
  MilestoneDate: state.task.MilestoneDate,
  currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
  getTaskTypeAsyncSelectAction: (searchValue, callback) => dispatch(getTaskTypeAsyncSelectAction(searchValue, callback)),
  getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeAsyncSelectAction(appId, searchValue, callback)),
  getProjectNameAsyncSelectAction: (searchValue, callback) => dispatch(getProjectNameAsyncSelect(searchValue, callback)),
  getAssignedUserAsyncSelectAction: (projectId, searchValue, callback) => dispatch(getAssignedUserAsyncSelect(projectId, searchValue, callback)),
  getMilestoneSelectAction: (projectId, searchValue, callback) => dispatch(getMilestoneSelectAction(projectId, searchValue, callback)),
  CloneTaskAction: (appId, formFields, PROJECTID) => dispatch(CreateTaskAction(appId, formFields, PROJECTID)),
  openMilestoneCreate: () => dispatch(openMilestoneCreate()),
  closeMilestoneCreate: () => dispatch(closeMilestoneCreate()),
  updateTaskSuccess: () => dispatch(updateTaskSuccess()),
  clearCreateTaskError: () => dispatch(clearCreateTaskError()),
  getMilestoneDate: (id) => dispatch(getMilestoneDate(id)),
  getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),
  getDependenciesAsyncSelect: (appId, milestoneId, searchValue, callback) => dispatch(getDependenciesAsyncSelect(appId, milestoneId, searchValue, callback)),
  getTaskStatusAsyncUserSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncUserSelectAction(appId, searchValue, callback)),
  getTaskStatusAsyncMilestoneSelect: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncMilestoneSelectAction(appId, searchValue, callback)),

});
export default connect(mapStateToProps, mapDispatchToProps)(CloneTask)