import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import vizo_logo from "../../../../assets/images/Vizo logo (9).png";
import { connect } from "react-redux";
import { clearOrgLogoError, clearUpdateOrgLogoError, EditOrgPortalLogo, getOrganizationLogo, updateOrgLogoSuccess } from "../../../../store/Modules/Admin/PortalManagement/actions";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit } from "@fortawesome/free-solid-svg-icons";

const PortalLogo = (props) => {
    const [formFields, setFormFields] = useState({});
    const { orgId } = props.currentProfile || {};
    const [errors, setErrors] = useState({});
    const [preview, setPreview] = useState(null);
    const [isUploadMode, setIsUploadMode] = useState(false);

    useEffect(() => {
        props.clearUpdateOrgLogoError();
        props.updateOrgLogoSuccess();
        props.clearOrgLogoError();
        props.getOrganizationLogo(orgId);
        if (orgId) {
            setFormFields({ ...formFields, orgId: orgId });
        }
    }, [orgId]);

    useEffect(() => {
        if (props.OrgLogoDetails.orgLogo || props.OrgLogoDetails.orgPortalName) {
            const { OrgLogoDetails } = props || {};
            setPreview(OrgLogoDetails.orgLogo);
            setFormFields({ ...formFields, orgId: OrgLogoDetails.orgId, orgPortalName: OrgLogoDetails.orgPortalName, logo: OrgLogoDetails.orgLogo });
        }
    }, [props.OrgLogoDetails]);

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        const { name } = event.target;

        if (file) {
            const reader = new FileReader();
            const imageSize = file.size / 1024; // Size in KB

            reader.onload = (e) => {
                const base64String = e.target.result;

                // Validate Image size and dimensions
                const img = new Image();
                img.onload = () => {
                    if (imageSize > 200) {
                        setErrors({ ...errors, logo: "Image size should be below 200 KB." });
                    } else if (img.width > 900 || img.height > 350) {
                        setErrors({
                            ...errors,
                            logo: "Image dimensions should not exceed 900px in width and 350px in height.",
                        });
                    } else {
                        setFormFields({ ...formFields, [name]: base64String });
                        setPreview(base64String);
                        setErrors({ ...errors, logo: null });
                        setIsUploadMode(false);
                    }
                };

                img.src = base64String;
            };

            reader.readAsDataURL(file); // Convert file to Base64
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
        setErrors({ ...errors, [name]: value ? null : "Portal Name is required." });
    };

    const handleSubmit = () => {
        const newErrors = {};

        if (!formFields.orgPortalName || formFields.orgPortalName.trim() === "") {
            newErrors.orgPortalName = "Portal Name is required.";
        }
        if (errors && errors.logo) {
            newErrors.logo = errors && errors.logo;
        }

        console.log("newErrors:", newErrors);

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            props.EditOrgPortalLogo(formFields);
            setIsUploadMode(false);
        }
    };

    const handleCancel = () => {
        setIsUploadMode(false);
        // setFormFields({ ...formFields, logo: null });
        // setErrors({ ...errors, logo: null });
        // setPreview(null)
        if (props.OrgLogoDetails.orgLogo || props.OrgLogoDetails.orgPortalName) {
            const { OrgLogoDetails } = props || {};
            setPreview(OrgLogoDetails.orgLogo);
            setFormFields({ ...formFields, logo: OrgLogoDetails.orgLogo });
        }
    };
    const handleRevert = () => {
        props.getOrganizationLogo(orgId);
        setIsUploadMode(false);
        setErrors({});
    };

    console.log("formfields:", formFields);
    console.log("error:", errors);

    return (
        <div className="page-content">
            <Card>
                <CardTitle
                    tag="h4"
                    className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                >
                    <strong>Portal Logo</strong>
                </CardTitle>
            </Card>
            <Card>
                <CardBody>
                    {(props.errorMessage || props.updateOrgLogoErrorMessage) && (
                        <VWMessage type="danger" defaultMessage={props.errorMessage || props.updateOrgLogoErrorMessage} baseClassName="alert"></VWMessage>
                    )}
                    <Row
                    >
                        <Col md={5}>
                            <FormGroup>
                                <Label>
                                    Portal Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    autoFocus={true}
                                    type="text"
                                    name="orgPortalName"
                                    placeholder="Enter portal name"
                                    value={formFields.orgPortalName || ""}
                                    onChange={handleInputChange}
                                    invalid={errors && errors.orgPortalName}
                                />
                                <FormFeedback>{errors && errors.orgPortalName}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            {isUploadMode ? (
                                <div className="d-flex gap-3 align-items-center">
                                    <FormGroup style={{ width: "90%" }}>
                                        <Label>
                                            Portal Logo
                                        </Label>
                                        <Input
                                            type="file"
                                            name="logo"
                                            accept="image/png, image/jpeg"
                                            onChange={handleLogoChange}
                                            invalid={errors && errors.logo}
                                        />
                                        <FormFeedback>{errors && errors.logo}</FormFeedback>
                                    </FormGroup>
                                    < FontAwesomeIcon
                                        // className="mt-2"
                                        icon={faClose}
                                        style={{ color: "red", cursor: "pointer", marginTop: errors && errors.logo ? "0px" : "10px", marginBottom: errors && errors.logo ? "7px" : "0px" }}
                                        onClick={handleCancel}
                                    />
                                </div>
                            ) : null}

                            {/* Logo preview */}
                            {!isUploadMode && preview ? (
                                <img
                                    src={preview}
                                    alt="Portal Logo"
                                    style={{ height: "120px", width: "auto" }}
                                />
                            ) : !isUploadMode ? (
                                <img
                                    src={vizo_logo}
                                    alt="Default Logo"
                                    style={{ height: "120px", width: "auto" }}
                                />
                            ) : null}
                            {/* </Col> */}
                            {/* <Col md={2}> */}

                            {!isUploadMode &&
                                //  ?
                                //     < FontAwesomeIcon
                                //         // className="mt-2"
                                //         icon={faClose}
                                //         style={{ color: "red", cursor: "pointer", marginLeft: "10px", marginTop: "10px" }}
                                //         onClick={handleCancel}
                                //     /> :
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    style={{ color: "black", cursor: "pointer", marginLeft: "10px", }}
                                    onClick={() => {
                                        setIsUploadMode(true);
                                        setFormFields({ ...formFields, logo: null });
                                        setErrors({ ...errors, logo: null });
                                        setPreview(null);
                                    }
                                    }
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Button color="success" onClick={handleSubmit} disabled={props.isUpdateOrgLogoPending} className="m-2">
                                {props.isUpdateOrgLogoPending ? <>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                    &nbsp; Submitting...
                                </>
                                    : <>Submit</>}
                            </Button>
                            {/* </Col>
                        <Col md={2}> */}
                            <Button color="danger" onClick={handleRevert} className="m-2">
                                Revert
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isPending: state.OrgPortalManagement.isOrgLogoPending,
    errorMessage: state.OrgPortalManagement.orgLogoError,
    OrgLogoDetails: state.OrgPortalManagement.OrgLogoDetails,
    isUpdateOrgLogoPending: state.OrgPortalManagement.isUpdateOrgLogoPending,
    updateOrgLogoErrorMessage: state.OrgPortalManagement.updateOrgLogoErrorMessage,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    getOrganizationLogo: (orgId) => dispatch(getOrganizationLogo(orgId)),
    EditOrgPortalLogo: (formFields) => dispatch(EditOrgPortalLogo(formFields)),
    clearUpdateOrgLogoError: () => dispatch(clearUpdateOrgLogoError()),
    updateOrgLogoSuccess: () => dispatch(updateOrgLogoSuccess()),
    clearOrgLogoError: () => dispatch(clearOrgLogoError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PortalLogo);
