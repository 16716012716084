import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap';

const ProjectReportModal = ({ data }) => {
    console.log('data:', data);
    const [formFields, setFormFields] = useState(data || []);
    useEffect(() => {
        if (data && data.length > 0) {
            setFormFields(data);
        }
    }, [data]); // Only run if `data` changes

    console.log('Modal Data State:', formFields);
    return (
        <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" >
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Total Hrs</th>
                    {/* <th>Current Month Hrs</th>
                    <th>Current Week Hrs</th> */}
                    <th>Logged Hrs</th>
                </tr>
            </thead>
            <tbody>
                {/* {data && data.map((datas) => (
                    <tr >
                        <td className='ps-3'>{datas.UserName || "N/A"}<br />
                            <small>{datas.UserEmail || 'N/A'}</small></td>
                        <td className='ps-3'>{datas.TotalHrs || 0}</td>
                        <td className='ps-3'>{datas.CurrentMonthHrs || 0}</td>
                        <td className='ps-3'>{datas.CurrentWeekHrs || 0}</td>
                        <td className='ps-3'>{datas.CustomDatesHrs || 0}</td>
                    </tr>
                ))} */}
                {formFields.length > 0 ? (
                    formFields.map((user, index) => (
                        <tr key={index}>
                            <td className='ps-3'>{user.UserName || "N/A"}<br />
                                <small>{user.UserEmail || 'N/A'}</small>
                            </td>
                            <td className='ps-3'>{user.TotalHrs || 0}</td>
                            {/* <td className='ps-3'>{user.CurrentMonthHrs || 0}</td>
                            <td className='ps-3'>{user.CurrentWeekHrs || 0}</td> */}
                            <td className='ps-3'>{user.CustomDatesHrs || 0}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5" className="text-center">No data available</td>
                    </tr>
                )}
            </tbody>
        </Table>
    )
}

export default ProjectReportModal