import React, {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    Input,
    Button,
    FormFeedback,
    FormGroup,
    Label,
    InputGroup
} from "reactstrap";
import { connect } from "react-redux";
import {
    clearChangePasswordByAdminError,
    changePasswordByAdmin
} from "../../../../../store/Modules/Admin/OrgUser/actions.js";
import VWMessage from "../../../../../components/vwMessage/vwMessage.js";

const ChangePasswordByAdmin = (props) => {

    const { handleClose, errorMessage, userId } = props;
    const [newPassword, setNewPassword] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState(true);
    const [formFields, setFormFields] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearChangePasswordByAdminError();
        setFormFields({
            ...formFields,
            userId: userId
        })
    }, [])

    const validateOnChange = (event) => {
        const { name, value } = event.target;
        setFormFields({
            ...formFields,
            [name]: value
        });
        if (name === 'newPassword') {
            setErrors({ ...errors, [name]: 'Password must be eight character' });
            if (value && value.length >= 8) {
                setErrors({ ...errors, [name]: null });
            }
        }
        if (name === 'confirmPassword') {
            setErrors({ ...errors, [name]: 'Confirm password does not match' });
            if (value === (formFields && formFields.newPassword)) {
                setErrors({ ...errors, [name]: null });
            }
        }
    };

    const handleChangePassword = () => {
        const { newPassword, confirmPassword } = formFields || {};
        let currentErrors = {};

        if (!newPassword) {
            currentErrors.newPassword = "New password is required";
        }
        if (!confirmPassword) {
            currentErrors.confirmPassword = "Confirm password is required";
        }
        if ((formFields && formFields.newPassword && formFields.newPassword.length < 8)) {
            currentErrors.newPassword = 'New password must be eight character';
        }
        if (((formFields && formFields.confirmPassword) !== (formFields && formFields.newPassword))) {
            currentErrors.confirmPassword = 'Confirm password does not match';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.changePasswordByAdmin(formFields);
        }
    };

    return (
        <Fragment>
            {errorMessage && (
                <VWMessage
                    type="danger"
                    defaultMessage={errorMessage}
                    baseClassName="alert"
                />
            )}
            <form className="mb-3" name="formChangePassword">
                <FormGroup>
                    <Label >New Password<span className="text-danger">*</span></Label>
                    <InputGroup><Input
                        className={errors && errors.newPassword ? "border-end-0" : 'border-end-0 border-secondary'}
                        type={newPassword ? "password" : "text"}
                        name="newPassword"
                        placeholder="Enter new password"
                        onChange={validateOnChange}
                        invalid={errors && errors.newPassword}
                    />
                        <Button
                            className={errors && errors.newPassword ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                            color='white'
                            onClick={() => setNewPassword(!newPassword)}
                        >
                            {newPassword ?
                                <i className="fas fa-eye text-secondary"></i>
                                :
                                <i class="fas fa-eye-slash text-secondary"></i>
                            }
                        </Button>
                        <FormFeedback>{errors && errors.newPassword}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>Confirm Password<span className="text-danger">*</span></Label>
                    <InputGroup>
                        <Input
                            className={errors && errors.confirmPassword ? "border-end-0" : 'border-end-0 border-secondary'}
                            type={confirmPassword ? "password" : "text"}
                            name="confirmPassword"
                            placeholder="Enter confirm password"
                            onChange={validateOnChange}
                            invalid={errors && errors.confirmPassword}
                        />
                        <Button
                            className={errors && errors.confirmPassword ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                            color='white'
                            onClick={() => setConfirmPassword(!confirmPassword)}
                        >
                            {confirmPassword ?
                                <i className="fas fa-eye text-secondary"></i>
                                :
                                <i class="fas fa-eye-slash text-secondary"></i>
                            }
                        </Button>
                        <FormFeedback>{errors && errors.confirmPassword}</FormFeedback>
                    </InputGroup>
                </FormGroup>
            </form>
            <FormGroup>
                <Button
                    className='me-4'
                    color="success"
                    onClick={handleChangePassword}
                    disabled={props.isPending}
                >
                    {props.isPending ? <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        &nbsp; Submitting...
                    </>
                        : <>Submit</>}
                </Button>
                <Button
                    color="danger"
                    onClick={handleClose}
                >Cancel
                </Button>
            </FormGroup>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    isPending: state.OrgUser.isChangePasswordByAdminPending,
    errorMessage: state.OrgUser.isChangePasswordByAdminErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    changePasswordByAdmin: (formfields) => dispatch(changePasswordByAdmin(formfields)),
    clearChangePasswordByAdminError: () => dispatch(clearChangePasswordByAdminError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordByAdmin);
