import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Badge, Button, Card, CardBody, CardTitle, Col, Input, Row, Table, Tooltip } from 'reactstrap'
import { clearMilestoneDetails, ClearMilestoneName, closeCloneMilestoneModal, closeEditMilestoneModalAction, getMilestoneDetails, openCloneMilestoneModalAction, openEditMilestoneModalAction } from '../../../../store/Modules/ProjectPortal/Milestones/actions'
import VWModel from '../../../../components/Modal/VWModal'
import { Link, useNavigate, useParams } from 'react-router-dom'
import UpdateMilestones from './UpdateMilestones'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { CloseCreateTaskModalAction, UpdateTaskStatus, clearMilestoneTaskList, clearUpdateTaskStatusError, getTaskMilestoneOwnerStatusAsyncSelectAction, getTaskStatusAsyncSelectAction, getTaskUserStatusAsyncSelectAction, getmilestoneTaskLists, getmilestonesPieChart, isStatusTaskEditOpen, openCreateTaskModalAction } from '../../../../store/Modules/ProjectPortal/Task/actions'
import PieChart from './PieChart'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions'
import CloneMilestone from './CloneMilestone'
import { ElementToolTip } from '../vwToolTip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faEdit, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import Async from 'react-select/async'
import { getUserNameAsyncSelect } from '../../../../store/Modules/ProjectPortal/Projects/actions'
import CreateTask from '../Task/CreateTask'

const MilestonesDetails = (props) => {

    const navigate = useNavigate();

    const { userAppDetails, displayName, userEmail } = props && props.currentProfile;
    const [filter, setFilter] = useState();

    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    const OnDoubleClickAction = (Id) => {
        console.log("Id:", Id);
        navigate(`/projectportal/tasks/details/${Id}`);
    };

    const [labels, setLabels] = useState();
    const [series, setSeries] = useState();

    const params = useParams();
    const { id } = params;


    const customStyles = {
        container: (provided) => ({
            ...provided,
            fontWeight: 'normal'
        }),
        control: (provided) => ({
            ...provided,
            fontWeight: 'normal',
            minWidth: '250px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontWeight: 'normal'
        }),
    };

    useEffect(() => {
        props.getMilestoneDetails(id);
        props.getmilestoneTaskLists(id, filter, App[0] && App[0].appId);
        props.getmilestonesPieChart(id);
        props.clearUpdateTaskStatusError();
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
            props.clearMilestoneDetails();
            props.clearUpdateTaskStatusError();
            props.clearMilestoneTaskList();
        }
    }, [id]);

    useEffect(() => {
        if (props.MilestonePieChartList) {
            let milestonePiechartList = []
            let milestonePieChartCountList = []

            milestonePiechartList.push(props.MilestonePieChartList.newTask && props.MilestonePieChartList.newTask.status)
            milestonePiechartList.push(props.MilestonePieChartList.inProgressTask && props.MilestonePieChartList.inProgressTask.status)
            milestonePiechartList.push(props.MilestonePieChartList.onHoldTask && props.MilestonePieChartList.onHoldTask.status)
            milestonePiechartList.push(props.MilestonePieChartList.internalTestingTask && props.MilestonePieChartList.internalTestingTask.status)
            milestonePiechartList.push(props.MilestonePieChartList.internalTestingDoneTask && props.MilestonePieChartList.internalTestingDoneTask.status)
            milestonePiechartList.push(props.MilestonePieChartList.waitingForApprovalTask && props.MilestonePieChartList.waitingForApprovalTask.status)
            milestonePiechartList.push(props.MilestonePieChartList.doneTask && props.MilestonePieChartList.doneTask.status)

            milestonePieChartCountList.push(props.MilestonePieChartList.newTask && props.MilestonePieChartList.newTask.count)
            milestonePieChartCountList.push(props.MilestonePieChartList.inProgressTask && props.MilestonePieChartList.inProgressTask.count)
            milestonePieChartCountList.push(props.MilestonePieChartList.onHoldTask && props.MilestonePieChartList.onHoldTask.count)
            milestonePieChartCountList.push(props.MilestonePieChartList.internalTestingTask && props.MilestonePieChartList.internalTestingTask.count)
            milestonePieChartCountList.push(props.MilestonePieChartList.internalTestingDoneTask && props.MilestonePieChartList.internalTestingDoneTask.count)
            milestonePieChartCountList.push(props.MilestonePieChartList.waitingForApprovalTask && props.MilestonePieChartList.waitingForApprovalTask.count)
            milestonePieChartCountList.push(props.MilestonePieChartList.doneTask && props.MilestonePieChartList.doneTask.count)

            const labelsData = milestonePiechartList.filter((i) => i !== null)
            const seriesData = milestonePieChartCountList.filter((i) => i !== null)
            setLabels(labelsData);
            setSeries(seriesData);
        }
    }, [props.MilestonePieChartList])

    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleOpenEditleaveTypeModel = () => {
        props.openEditMilestoneModal();
    }
    const handleOpenCloneMilestoneModel = () => {
        props.openCloneMilestoneModal();
    }
    const handleClose = () => {
        props.closeEditMilestoneModal();
        props.closeCloneMilestoneModal();
    };
    const handleCloseTaskModal = () => {
        props.closeCreateTaskModal();
    };

    // --------------------------------------------SORTING------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    console.log("sortOrder:", sortOrder);

    const handleSort = (column) => {
        console.log("sortOrder:", sortOrder);
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedTaskList = [...props.MilestoneTaskList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'CurrentStatusPercentage') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });


    const projectPortalApps = userAppDetails?.filter(item =>
        item?.appName?.toLowerCase() === "projectportal"
    ) || [];

    const isProjectAdmin = projectPortalApps.some(userApp => {
        const adminRoles = userApp?.appRoleDetails?.filter(role => role.roleName) || [];
        return adminRoles.some(role => role.roleName.toLowerCase() === "administrator");
    });
    const { isGlobalReadOnlyUser } = props.currentProfile || {};
    console.log("isProjectAdmin:", isProjectAdmin);

    //---------------------------------dropdown close action for outside click------------------------------------------
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                props.isStatusTaskEditOpen(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    //-------------------------------------------Status Task Update ------------------------------------------------------------
    const [taskStatusValues, setTaskStatusValues] = useState([]);

    const handleTaskSelectChange = (selectedOption, Id, milestoneId) => {
        const updatedStatus = selectedOption ? selectedOption.value : '';
        setTaskStatusValues((prevValues) => ({
            ...prevValues,
            [Id]: updatedStatus
        }));
        props.UpdateTaskAction({ TaskStatus: updatedStatus, taskId: Id, MileStoneId: milestoneId, CurrentStatusPercentage: selectedOption.Percentage }, filter, 'Details', App[0] && App[0].appId);
    };
    const { userId } = props.currentProfile;

    const handleTaskEditClick = (itemId) => {
        props.isStatusTaskEditOpen(itemId);
    };
    //------------------------------------------Filter--------------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                props.getmilestoneTaskLists(id, filter, App[0] && App[0].appId);
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])


    const [TaskStatusFilter, setStatusFilter] = useState();

    const handleTaskStatus = (inputValue, callback) => {
        const { getTaskStatusAsyncSelectAction } = props;
        return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskMilestoneOwnerStatus = (inputValue, callback) => {
        const { getTaskMilestoneOwnerStatusAsyncSelectAction } = props;
        return getTaskMilestoneOwnerStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskUserStatus = (inputValue, callback) => {
        const { getTaskUserStatusAsyncSelectAction } = props;
        return getTaskUserStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const searchStatus = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            TaskStatus: value,
        })
        setStatusFilter({ label: label || 'Search', value: value || '' })
    }
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    //-------------------------------------------Modal Open and Close--------------------------------------------------
    const handleOpenCreateTask = () => {
        props.openCreateTaskModal();
         props.ClearMilestoneName();
    };
    console.log('props.milestonesDetails:', props.milestonesDetails);

    return (
        <React.Fragment>
            <div className="page-content">
                <div style={{ height: "80vh", overflowY: "auto" }}>
                    <Card>
                        <CardTitle
                            tag="h4"
                            className=" px-3 py-2 mb-0 d-flex align-items-center text-capitalize overflow-hidden sticky-top"
                            style={{ backgroundColor: "white", overflow: "hidden", zIndex: "30" }}
                        >
                            <strong>MILESTONE DETAILS&nbsp;&gt;&nbsp; <span id={'ProjectNames'}><Link
                                to={`/projectportal/projects/details/${props.milestonesDetails && props.milestonesDetails.projectId}`}
                            >   {props.isMilestoneDetailsPending ? <>
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                                &nbsp; loading...
                            </>
                                :
                                <span>{props.milestonesDetails && props.milestonesDetails.projectName && props.milestonesDetails.projectName.trim() || "NA"}</span>
                                }

                            </Link> </span>&nbsp;&gt;&nbsp;
                                {props.isMilestoneDetailsPending ?
                                    <>
                                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                                        &nbsp; loading...
                                    </>
                                    : <><span id={'MilestonesName'}>{props.milestonesDetails && props.milestonesDetails.milestoneName || "NA"}</span>
                                    </>
                                }
                                <ElementToolTip id={'ProjectNames'} name={'Project Name'} />
                                <ElementToolTip id={'MilestonesName'} name={'Milestone Name'} />
                            </strong>
                            <div className="ms-auto">
                                {(((isProjectAdmin) || (props.milestonesDetails && props.milestonesDetails.isUpdateMilestoneOwner)) && (!props.isMilestoneDetailsPending) && (!isGlobalReadOnlyUser)) ?
                                    <Link type="button"
                                        className="btn btn-info"
                                        onClick={handleOpenCloneMilestoneModel}
                                    >
                                        CLONE MILESTONE
                                    </Link> : ''}&nbsp;
                                {(!props.isMilestoneDetailsPending) && (!props.errorMessage) && (props.milestonesDetails && props.milestonesDetails.isEditable) && (!isGlobalReadOnlyUser) ?
                                    <Link type="button"
                                        className="btn btn-info"
                                        onClick={handleOpenEditleaveTypeModel}
                                    >
                                        EDIT
                                    </Link> : ""}
                            </div>
                        </CardTitle>
                        <CardTitle
                            className="border-bottom px-3  mb-0 d-flex justify-content-between align-items-center sticky-column"
                            style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                        </CardTitle>
                        <CardBody className="p-1 sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                            {props.errorMessage ? <div className='w-100'>
                                {props.errorMessage && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            {props.isMilestoneDetailsPending ?
                                <div className="d-flex justify-content-center mt-2">
                                    <VWSpinner />
                                </div>
                                :
                                <Row>
                                    <div>
                                        <div className="p-3 bg-light  d-flex justify-content-between align-items-center">
                                            <div className="d-flex fw-bold fs-4">
                                                <span className='text-capitalize'>{props.milestonesDetails && props.milestonesDetails.projectName || "NA"} → {props.milestonesDetails && props.milestonesDetails.milestoneName || "NA"}</span>
                                            </div>
                                            <div className="d-flex">
                                                <span id={'startDate'}>{props.milestonesDetails && props.milestonesDetails.startDate ? moment(props.milestonesDetails && props.milestonesDetails.startDate).format('DD MMM YYYY') : 'NA' || "NA"}</span>
                                                <ElementToolTip id={'startDate'} name={'Start Date'} />
                                                <span>&nbsp; → &nbsp;</span>
                                                <span id={'endDate'}>{props.milestonesDetails && props.milestonesDetails.endDate ? moment(props.milestonesDetails && props.milestonesDetails.endDate).format('DD MMM YYYY') : 'NA' || "NA"}</span>
                                                <ElementToolTip id={'endDate'} name={'End Date'} />
                                            </div>
                                        </div>
                                    </div>
                                    <Col xl="6">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <strong>Milestone Owner</strong>
                                                                </td>
                                                                <td>{props.milestonesDetails && props.milestonesDetails.mileStoneOwnerUserName || "N/A"}<br />
                                                                    {props.milestonesDetails && props.milestonesDetails.mileStoneOwnerUserEmail}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Description</strong>
                                                                </td>
                                                                <td style={{ textAlign: "justify" }}>{props.milestonesDetails && props.milestonesDetails.description || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Status</strong>
                                                                </td>
                                                                <td>{props.milestonesDetails && props.milestonesDetails.status || "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="6">
                                        <div className='fs-5 fw-bold pt-2 ps-2 text-info'>
                                            <FontAwesomeIcon icon={faChartPie} /> Pie-Chart
                                        </div>
                                        {(labels && labels.length > 0) && (series && series.length > 0) ?
                                            <PieChart labels={labels} value={series} />
                                            :
                                            <div className="d-flex justify-content-center">
                                                <div className={props.projectPieChartDetailsError ? "text-center mb-4" : "text-center my-4"}>
                                                    <div className="text-md mb-2 text-danger">Pie-Chart data not found</div>
                                                    <p className="lead m-0">Oh! It is empty :{`(`} </p>
                                                    <p className='m-0'>
                                                        You don`t have any data for this pie-chart.
                                                    </p>
                                                </div>
                                            </div>}
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>
                    <Card className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                        <CardTitle tag="h4"
                            className=" px-3 py-2 mb-0 d-flex align-items-center text-capitalize">
                            <strong className='d-flex align-items-center pt-2 pb-0 mb-0'>TASKS&nbsp;</strong>
                            <div className="ms-2">
                                <Button
                                    id={`createTask`}
                                    className="border rounded-circle"
                                    size="sm"
                                    color="primary"
                                    onClick={handleOpenCreateTask}
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{ fontWeight: 'bolder', color: "white", marginTop: '5px' }} />
                                </Button>
                                <ElementToolTip id={`createTask`} name={'Create Task'} />
                            </div>
                        </CardTitle>
                        <hr className='p-0 m-0' />
                        <CardBody className="p-1">
                            {props.ProjectMilestoneListError ? <div className='w-100'>
                                {props.ProjectMilestoneListError && (
                                    <VWMessage type="danger" defaultMessage={props.ProjectMilestoneListError} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            {(props.isProjectMilestonePending || props.isUpdateTaskStatusPending) ?
                                <div className="d-flex justify-content-center mt-2">
                                    <VWSpinner />
                                </div>
                                :
                                <Row>
                                    <Col xl="12">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div style={{ height: "500px", overflowX: "auto" }}>
                                                    <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                                        <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                                            <tr>
                                                                <th className='hand-cursor' onClick={() => handleSort('TaskName')}>Task Name &nbsp;
                                                                    {sortOrder === 'TaskName' && (
                                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                                    )}</th>
                                                                <th className='hand-cursor' onClick={() => handleSort('CurrentStatusPercentage')}>Status  &nbsp;
                                                                    {sortOrder === 'CurrentStatusPercentage' && (
                                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                                    }</th>
                                                                <th className='hand-cursor' onClick={() => handleSort('AssignedUserName')}>Assigned To &nbsp;
                                                                    {sortOrder === 'AssignedUserName' && (
                                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                                    }</th>
                                                                <th className='hand-cursor' onClick={() => handleSort('StartDate')}>Duration &nbsp;
                                                                    {sortOrder === 'StartDate' && (
                                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                                    }</th>
                                                                <th>View</th>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <Input
                                                                        // size="sm"
                                                                        type='text'
                                                                        placeholder='Search'
                                                                        name='TaskName'
                                                                        onChange={searchInputValidation}
                                                                        value={filter && filter.TaskName}
                                                                    />
                                                                </th>
                                                                <th>
                                                                    <Async
                                                                        // styles={{
                                                                        //     control: (provided) => ({
                                                                        //         ...provided,
                                                                        //         fontWeight: 'normal',
                                                                        //         height: '30px',
                                                                        //         minHeight: '30px',
                                                                        //         fontSize: '0.700rem',
                                                                        //         padding: '0 8px',
                                                                        //         minWidth: '150px',
                                                                        //     }),
                                                                        //     dropdownIndicator: (provided) => ({
                                                                        //         ...provided,
                                                                        //         padding: '0px 1px 0px 0px',
                                                                        //     }),
                                                                        //     indicatorSeparator: (provided) => ({
                                                                        //         ...provided,
                                                                        //         display: 'none',
                                                                        //     }),
                                                                        //     placeholder: (provided) => ({
                                                                        //         ...provided,
                                                                        //         fontWeight: 'normal',
                                                                        //         fontSize: '0.700rem',
                                                                        //     }),
                                                                        //     menu: (provided) => ({
                                                                        //         ...provided,
                                                                        //         fontWeight: 'normal',
                                                                        //         fontSize: '0.700rem',
                                                                        //     }),
                                                                        //     option: (provided) => ({
                                                                        //         ...provided,
                                                                        //         fontWeight: 'normal',
                                                                        //         fontSize: '0.700rem',
                                                                        //     }),
                                                                        // }}
                                                                        styles={customStyles}
                                                                        name="TaskStatus"
                                                                        placeholder="Search"
                                                                        isSearchable
                                                                        isClearable
                                                                        defaultOptions
                                                                        loadOptions={handleTaskStatus}
                                                                        onChange={searchStatus}
                                                                        value={TaskStatusFilter}
                                                                    />
                                                                </th>
                                                                <th>
                                                                    <Input
                                                                        // size="sm"
                                                                        type='text'
                                                                        placeholder='Search'
                                                                        name='AssignedUserName'
                                                                        onChange={searchInputValidation}
                                                                        value={filter && filter.AssignedUserName}
                                                                    />
                                                                </th>
                                                                <th>
                                                                    <Input
                                                                        // size="sm"
                                                                        type='date'
                                                                        placeholder='Search'
                                                                        name='TaskStartDate'
                                                                        onChange={searchInputValidation}
                                                                        value={filter && filter.startDate}
                                                                        max="9999-12-31"
                                                                    />
                                                                </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                sortedTaskList && sortedTaskList.length > 0 ?
                                                                    sortedTaskList && sortedTaskList.map(item =>
                                                                        <tr key={item.Id}
                                                                            className="clickable-row" style={{ cursor: 'pointer' }}
                                                                            onDoubleClick={() => OnDoubleClickAction(item.Id)}>
                                                                            <td>{item.TaskName || 'N/A'}</td>
                                                                            <td>
                                                                                {item.IsEditable ? (
                                                                                    <>
                                                                                        {item.IsMilestoneOwnerUpdateStatus ? (
                                                                                            props.isStatusTaskEditID === item.Id ? (
                                                                                                <div ref={dropdownRef}>
                                                                                                    <Async
                                                                                                        styles={customStyles}
                                                                                                        name="TaskStatus"
                                                                                                        placeholder="Search"
                                                                                                        isSearchable
                                                                                                        isClearable
                                                                                                        defaultOptions
                                                                                                        loadOptions={handleTaskMilestoneOwnerStatus}
                                                                                                        onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId) }}
                                                                                                        value={taskStatusValues[item.Id] ?
                                                                                                            { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                                            item.TaskStatus ?
                                                                                                                { value: item.TaskStatus, label: `${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` } :
                                                                                                                null
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            ) : (
                                                                                                < div className='d-flex justify-content-between'>
                                                                                                    <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={faEdit}
                                                                                                        style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                                        onClick={() => handleTaskEditClick(item.Id)}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        ) : (
                                                                                            item.IsUserUpdateStatus && userId === item.AssignedUserId ? (
                                                                                                props.isStatusTaskEditID === item.Id ? (
                                                                                                    <div ref={dropdownRef}>
                                                                                                        <Async
                                                                                                            styles={customStyles}
                                                                                                            name="TaskStatus"
                                                                                                            placeholder="Search"
                                                                                                            isSearchable
                                                                                                            isClearable
                                                                                                            defaultOptions
                                                                                                            loadOptions={handleTaskUserStatus}
                                                                                                            onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId) }}
                                                                                                            value={taskStatusValues[item.Id] ?
                                                                                                                { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                                                item.TaskStatus ?
                                                                                                                    { value: item.TaskStatus, label: `${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` } :
                                                                                                                    null
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={faEdit}
                                                                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                                            onClick={() => handleTaskEditClick(item.Id)}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            ) : (
                                                                                                props.isStatusTaskEditID === item.Id ? (
                                                                                                    <div ref={dropdownRef}>
                                                                                                        <Async
                                                                                                            styles={customStyles}
                                                                                                            name="TaskStatus"
                                                                                                            placeholder="Search"
                                                                                                            isSearchable
                                                                                                            isClearable
                                                                                                            defaultOptions
                                                                                                            loadOptions={handleTaskStatus}
                                                                                                            onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId) }}
                                                                                                            value={taskStatusValues[item.Id] ?
                                                                                                                { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                                                item.TaskStatus ?
                                                                                                                    { value: item.TaskStatus, label: `${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` } :
                                                                                                                    null
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={faEdit}
                                                                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                                            onClick={() => handleTaskEditClick(item.Id)}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            )
                                                                                        )}
                                                                                    </>
                                                                                ) : item.IsUserEdit ? (
                                                                                    props.isStatusTaskEditID === item.Id ? (
                                                                                        <div ref={dropdownRef}>
                                                                                            <Async
                                                                                                styles={customStyles}
                                                                                                name="TaskStatus"
                                                                                                placeholder="Search"
                                                                                                isSearchable
                                                                                                isClearable
                                                                                                defaultOptions
                                                                                                loadOptions={handleTaskUserStatus}
                                                                                                onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId) }}
                                                                                                value={taskStatusValues[item.Id] ?
                                                                                                    { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                                    item.TaskStatus ?
                                                                                                        { value: item.TaskStatus, label: `${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` } :
                                                                                                        null
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <span>{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faEdit}
                                                                                                style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                                onClick={() => handleTaskEditClick(item.Id)}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                ) : (
                                                                                    <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                )}
                                                                            </td>
                                                                            <td>{item.AssignedUserName || "N/A"}<br />
                                                                                <small>{item.AssignedUserEmail || 'N/A'}</small></td>
                                                                            <td>
                                                                                <span id="taskStartDate">{item.StartDate ? moment(item.StartDate).format('DD MMM YYYY') : 'NA' || "NA"}</span>
                                                                                <ElementToolTip id={'taskStartDate'} name={
                                                                                    'Start Date'
                                                                                } />
                                                                                <span>&nbsp; → &nbsp;</span>
                                                                                <span id='taskEndDate'> {item.EndDate ? moment(item.EndDate).format('DD MMM YYYY') : 'NA' || "NA"}</span>
                                                                                <ElementToolTip id={'taskEndDate'} name={
                                                                                    'End Date'
                                                                                } />
                                                                            </td>
                                                                            <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                                                                                type="button"
                                                                                className="btn btn-sm  btn-light"
                                                                                to={`/projectportal/tasks/details/${item.Id}`}
                                                                            >
                                                                                <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                                            </Link></td>
                                                                        </tr>
                                                                    ) : (props.isMilestoneTaskPending ?
                                                                        <tr>
                                                                            <td colSpan={5}>
                                                                                <div className="d-flex justify-content-center mt-2">
                                                                                    <VWSpinner />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        <tr>
                                                                            <td colSpan={5}>
                                                                                <div className="d-flex justify-content-center">
                                                                                    <div className="text-center my-4">
                                                                                        <div className="mb-3">
                                                                                            <em className="bi bi-database error-title"></em>
                                                                                        </div>
                                                                                        <div className="text-md mb-3 text-danger">Task not found</div>
                                                                                        <p className="lead m-0">Oh! It is empty :{`(`} </p>
                                                                                        <p>
                                                                                            You don`t have any task for the selected milestone <br />or else  you do not select any milestone.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>)}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>
                </div>
                <VWModel
                    header="EDIT MILESTONE"
                    isOpen={props.isEditMilestoneModalOpen}
                    handleClose={handleClose}
                >
                    <UpdateMilestones
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="CLONE MILESTONE"
                    isOpen={props.isCloneMilestoneModalOpen}
                    handleClose={handleClose}
                >
                    <CloneMilestone
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="CREATE TASK"
                    isOpen={props.isCreateTaskModalOpen}
                    handleClose={handleCloseTaskModal}
                    modalSize="large"
                >
                    <CreateTask
                        selectedMilestoneOwnerStatus={props.milestonesDetails && props.milestonesDetails.isMilestoneOwnerUpdateStatus}
                        selectedUserStatus={props.milestonesDetails && props.milestonesDetails.isUserUpdateStatus}
                        selectedProjectName={props.milestonesDetails && props.milestonesDetails.projectName}
                        selectedProjectId={props.milestonesDetails && props.milestonesDetails.projectId}
                        selectedMilestoneId={props.milestonesDetails && props.milestonesDetails.id}
                        selectedMilestoneName={props.milestonesDetails && props.milestonesDetails.milestoneName}
                        // selectedMilestoneEditOption={assignedMilestoneTaskCreateOption}
                        selectedFlexibleDueDates={props.milestonesDetails && props.milestonesDetails.allowFlexibleDueDates}
                        selectedLoginUser={displayName}
                        selectedLoginEmail={userEmail}
                        // selectedUserStatus={UserUpdateStatus}
                        // selectedMilestoneOwnerStatus={MilestoneOwnerUpdateStatus}
                        handleClose={handleCloseTaskModal}
                    />
                </VWModel>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    isCloneMilestoneModalOpen: state.Milestones.isCloneMilestoneModalOpen,
    isEditMilestoneModalOpen: state.Milestones.isEditMilestoneModalOpen,
    milestonesDetails: state.Milestones.MilestoneDetails,
    errorMessage: state.Milestones.milestoneDetailsError,
    isMilestoneDetailsPending: state.Milestones.isMilestoneDetailsPending,
    MilestonePieChartList: state.task.MilestonePieChartList,
    MilestoneTaskList: state.task.MilestoneTaskList,
    currentProfile: state.login.currentProfile,
    isStatusTaskEditID: state.task.isStatusTaskEditID,
    isUpdateTaskStatusPending: state.task.isUpdateTaskStatusPending,
    isMilestoneTaskPending: state.task.isMilestoneTaskPending,
    isCreateTaskModalOpen: state.task.isCreateTaskModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
       ClearMilestoneName: () => dispatch(ClearMilestoneName()),
    openCreateTaskModal: () => dispatch(openCreateTaskModalAction()),
    closeCreateTaskModal: () => dispatch(CloseCreateTaskModalAction()),
    clearMilestoneTaskList: () => dispatch(clearMilestoneTaskList()),
    closeEditMilestoneModal: () => dispatch(closeEditMilestoneModalAction()),
    closeCloneMilestoneModal: () => dispatch(closeCloneMilestoneModal()),
    openEditMilestoneModal: () => dispatch(openEditMilestoneModalAction()),
    openCloneMilestoneModal: () => dispatch(openCloneMilestoneModalAction()),
    getMilestoneDetails: (Id) => dispatch(getMilestoneDetails(Id)),
    getmilestoneTaskLists: (Id, filter, appID) => dispatch(getmilestoneTaskLists(Id, filter, appID)),
    getmilestonesPieChart: (Id) => dispatch(getmilestonesPieChart(Id)),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
    ClearPath: () => dispatch(clearSetPath()),
    clearMilestoneDetails: () => dispatch(clearMilestoneDetails()),
    clearUpdateTaskStatusError: () => dispatch(clearUpdateTaskStatusError()),
    UpdateTaskAction: (formFields, filter, apiList, appId) => dispatch(UpdateTaskStatus(formFields, filter, apiList, appId)),
    isStatusTaskEditOpen: (id) => dispatch(isStatusTaskEditOpen(id)),
    getUserNameAsyncSelectAction: (searchValue, callback) => dispatch(getUserNameAsyncSelect(searchValue, callback)),
    getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),
    getTaskMilestoneOwnerStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskMilestoneOwnerStatusAsyncSelectAction(appId, searchValue, callback)),
    getTaskUserStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskUserStatusAsyncSelectAction(appId,searchValue, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MilestonesDetails)