import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Col, FormText, Input, Label, Row, Table, Tooltip } from 'reactstrap';
import Async from 'react-select/async';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getAdminProjectNameAsyncSelectAction } from '../../../../store/Modules/ProjectPortal/Milestones/actions';
import { clearGeneralTaskList, clearGeneralTaskListError, clearUpdateGeneralTaskError, EditGeneralTask, GeneralTaskImageModalClose, GeneralTaskImageModalOpen, getAssignedUserAsyncSelect, getGeneralTaskList, getMilestoneSelectAction, updateGeneralTaskSuccess } from '../../../../store/Modules/ProjectPortal/GeneralTasks/actions';
import { getPager } from '../../../../helper/common';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWPagination from '../../../../components/vwPagination/vwPagination';
import GeneralTaskImageModal from './GeneralTaskImageModal';
import VWModel from '../../../../components/Modal/VWModal';
import { getTaskStatusAsyncSelectAction, getTaskTypeAsyncSelectAction } from '../../../../store/Modules/ProjectPortal/Task/actions';
import { ElementToolTip } from '../vwToolTip';


const GeneralTasks = (props) => {
    const [formFields, setFormFields] = useState();
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const [keyChange, setKeyChange] = useState(1);
    const [projectId, setProjectId] = useState();
    const [expandedRows, setExpandedRows] = useState(new Set());

    const { userAppDetails } = props && props.currentProfile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })


    useEffect(() => {
        props.updateGeneralTaskSuccess();
        if (props.GeneralTaskLists) {
            setFormFields(props.GeneralTaskLists);
            props.clearUpdateGeneralTaskError();
            props.clearGeneralTaskListError();
        }
    }, [props.GeneralTaskLists])


    const buildTaskName = () => {
        const { TaskName } = filter || {};
        let searchTaskName = '';
        if (TaskName) {
            searchTaskName += `${TaskName}`;
        }
        return (searchTaskName) || ''
    }

    const buildDescription = () => {
        const { Description } = filter || {};
        let searchDescription = '';
        if (Description) {
            searchDescription += `${Description}`;
        }
        return (searchDescription) || ''
    }


    const setPage = (page = 1) => {
        props.getGeneralTaskList(page, PageSize, buildTaskName(), buildDescription());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        return (() => {
            props.clearGeneralTaskList();
        })
    }, [])

    const handleProject = (inputValue, callback) => {
        const { getProjectNameAsyncSelectAction } = props;
        return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])
    //---------------------------------------------------------------------------------------------------------------
    const handleMilestone = (inputValue, callback) => {
        if (projectId) {
            const { getMilestoneSelect } = props;
            return getMilestoneSelect(projectId, inputValue.length > 0 ? inputValue : '', callback);
        } else {
            callback([]);
        }
    };
    const handleAssignUser = (inputValue, callback) => {
        if (projectId) {
            const { getAssignedUserAsyncSelectAction } = props;
            return getAssignedUserAsyncSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
        } else {
            callback([]);
        }
    };

    const handleAddProjectName = (value, i) => {
        const data = [...formFields];
        data[i].ProjectId = value ? value.value : '';
        data[i].MileStoneId = null;
        data[i].MileStoneName = null;
        data[i].assignedUserId = null;
        data[i].assignedUserName = null;
        setFormFields(data);
        data[i].ProjectIdError = ""
        setKeyChange(keyChange + 1);
        if (value && value.value) {
            setProjectId(value.value)
        }
    };

    const handleAddProjectUser = (value, i) => {
        const data = [...formFields];
        data[i].assignedUserId = value ? value.value : '';
        data[i].assignedUserName = value ? value.label : '';
        setFormFields(data);
        data[i].assignedUserIdError = ""
    };

    const handleAddMilestoneOwner = (value, i) => {
        const data = [...formFields];
        data[i].MileStoneId = value ? value.value : '';
        data[i].MileStoneName = value ? value.label : '';
        setFormFields(data);
        data[i].MileStoneIdError = ""
    };

    const handleTaskStatus = (value, i) => {
        const data = [...formFields];
        data[i].taskStatus = value ? value.value : '';
        setFormFields(data);
        data[i].taskStatusError = ""
    }

    const handleTaskType = (value, i) => {
        const data = [...formFields];
        data[i].taskTypeId = value ? value.value : '';
        setFormFields(data);
        data[i].taskTypeError = ""
    }

    const handleSubmit = (e, index) => {
        const data = [...formFields];
        const UpdatedData = data[index];
        console.log("data:", data);
        let withOutError = true;

        if (!UpdatedData.ProjectId || !(UpdatedData.ProjectId && UpdatedData.ProjectId.trim())) {
            data[index].ProjectIdError = 'Project name is required';
            withOutError = false
        }
        if (!UpdatedData.MileStoneId || !UpdatedData.MileStoneId.trim()) {
            data[index].MileStoneIdError = 'Milestone name is required';
            withOutError = false
        }
        if (!UpdatedData.assignedUserId || !UpdatedData.assignedUserId.trim()) {
            data[index].assignedUserIdError = 'Assigned username is required';
            withOutError = false
        }
        if (!UpdatedData.taskStatus || !UpdatedData.taskStatus.trim()) {
            data[index].taskStatusError = 'Task status is required';
            withOutError = false
        }
        if (!UpdatedData.taskTypeId || !UpdatedData.taskTypeId.trim()) {
            data[index].taskTypeError = 'Task type is required';
            withOutError = false
        }
        setFormFields(data);
        if (withOutError) {
            console.log("api");
            props.EditGeneralTaskAction(UpdatedData);
        }
    };

    const toggleDescription = (index) => {
        setExpandedRows(prev => {
            const newExpandedRows = new Set(prev);
            if (newExpandedRows.has(index)) {
                newExpandedRows.delete(index);
            } else {
                newExpandedRows.add(index);
            }
            return newExpandedRows;
        });
    };

    const renderDescription = (description, index) => {
        const maxLineWords = 5; // Adjust this value as needed
        const maxLength = 50;

        const isLongDescription = description.split(' ').length > maxLineWords;
        const words = description.split(' ');
        const chunks = [];

        for (let i = 0; i < words.length; i += maxLineWords) {
            chunks.push(words.slice(i, i + maxLineWords).join(' '));
        }

        return (
            <div>
                {chunks.slice(0, isLongDescription && !expandedRows.has(index) ? 2 : chunks.length).map((chunk, i) => (
                    <div key={i} className='text-justify'>{chunk}</div>
                ))}
                {isLongDescription && (
                    <Row>
                        <Col>
                            <Button
                                color="link"
                                onClick={() => toggleDescription(index)}
                                style={{ padding: 0, marginLeft: '5px' }}
                            >
                                {expandedRows && expandedRows.has(index) ? 'Read less' : 'Read more'}
                            </Button>
                        </Col>
                        {(props.GeneralTaskLists && props.GeneralTaskLists[index] && props.GeneralTaskLists[index].ImageData) &&
                            <Col>
                                <Button
                                    color="link"
                                    onClick={() => props.GeneralTaskImageModalOpen(index)}
                                    style={{ padding: 0, marginLeft: '5px' }}
                                >
                                    {"images"}
                                </Button>

                            </Col>
                        }
                    </Row>


                )}
            </div>
        );
    };
    const handleClose = () => {
        props.GeneralTaskImageModalClose();
    };

    const handleTaskStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncSelectAction } = props;
        return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };

    const handleSelectTaskType = (inputValue, callback) => {
        const { getTaskTypeAsyncSelectAction } = props;
        return getTaskTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong className='d-flex align-items-center'>GENERAL TASKS -&nbsp;<Badge color="danger" id='generalTasksCount' style={{ cursor: 'pointer' }}>{props.isGeneralTaskListPending ? 0 : (props && props.totalCount || 0)}</Badge>
                            <ElementToolTip id={'generalTasksCount'} name={
                                'General Tasks Count'
                            } /></strong>
                    </CardTitle>
                </Card>
                <Card className='mb-1'>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <Label>Task Name</Label>
                                <Input type='text' placeholder='Search' name='TaskName' onChange={searchInputValidation}
                                    value={filter && filter.TaskName}
                                    onKeyPress={handleKeyPress} />
                            </Col>
                            <Col md={6}>
                                <Label>Description</Label>
                                <Input type='text' placeholder='Search' name='Description' onChange={searchInputValidation}
                                    value={filter && filter.Description}
                                    onKeyPress={handleKeyPress} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="p-4">
                        {(props.generalTaskListError || props.updateGeneralTaskErrorMessage) && (
                            <VWMessage type="danger" defaultMessage={props.generalTaskListError || props.updateGeneralTaskErrorMessage} baseClassName="alert" />
                        )}
                        <div style={{ height: "60vh", overflowX: "auto" }}>
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                    <tr>
                                        <th style={{ minWidth: '220px' }}>Task Name</th>
                                        <th style={{ minWidth: '250px' }}>Description</th>
                                        <th style={{ minWidth: '250px' }}>Project Name <span className="text-danger"> *</span></th>
                                        <th style={{ minWidth: '250px' }}>Milestone Name <span className="text-danger"> *</span></th>
                                        <th style={{ minWidth: '250px' }}>Assigned User <span className="text-danger"> *</span></th>
                                        <th style={{ minWidth: '250px' }}>Task Status <span className="text-danger"> *</span></th>
                                        <th style={{ minWidth: '250px' }}>Task Type <span className="text-danger"> *</span></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!props.isGeneralTaskListPending && (formFields && formFields.length > 0) ? formFields.map((data, i) => (
                                        <tr key={i}>
                                            <td className="ps-3">{data.TaskName || "N/A"}</td>
                                            <td className="ps-3">
                                                {renderDescription(data.Description || "N/A", i)}
                                            </td>
                                            <td className="ps-3">
                                                <Async
                                                    autoFocus={i === 0 ?? true}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: (data.ProjectIdError) ? '#FF3D60' : "#CED4DA"
                                                        })
                                                    }}
                                                    name="ProjectId"
                                                    placeholder="Select project name"
                                                    // className="vm-react-select"
                                                    isSearchable
                                                    defaultOptions
                                                    loadOptions={handleProject}
                                                    onChange={e => handleAddProjectName(e, i)}
                                                />
                                                <FormText sx={{ marginLeft: "10px" }}>
                                                    <div className="text-danger">
                                                        {data.ProjectIdError}
                                                    </div>
                                                </FormText>
                                            </td>
                                            <td className="ps-3">
                                                {console.log("data: ", data)}
                                                <Async
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: (data.MileStoneIdError) ? '#FF3D60' : "#CED4DA"
                                                        })
                                                    }}
                                                    key={keyChange}
                                                    // className="vm-react-select"
                                                    name="MileStoneId"
                                                    onChange={e => handleAddMilestoneOwner(e, i)}
                                                    placeholder="Select milestone name"
                                                    isSearchable
                                                    defaultOptions
                                                    loadOptions={handleMilestone}
                                                    value={data.MileStoneName ? { label: data.MileStoneName, value: data.MileStoneId } : 'Select milestone name'}
                                                />
                                                <FormText sx={{ marginLeft: "10px" }}>
                                                    <div className="text-danger">
                                                        {data.MileStoneIdError}
                                                    </div>
                                                </FormText>
                                            </td>
                                            <td className="ps-3">
                                                <Async
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: (data.assignedUserIdError) ? '#FF3D60' : "#CED4DA"
                                                        })
                                                    }}
                                                    key={keyChange}
                                                    name="assignedUserId"
                                                    placeholder="Select assigned user"
                                                    // className="vm-react-select"
                                                    isSearchable
                                                    defaultOptions
                                                    loadOptions={handleAssignUser}
                                                    onChange={e => handleAddProjectUser(e, i)}
                                                    value={data.assignedUserName ? { label: data.assignedUserName, value: data.assignedUserId } : 'Select assigned user'}
                                                />
                                                <FormText sx={{ marginLeft: "10px" }}>
                                                    <div className="text-danger">
                                                        {data.assignedUserIdError}
                                                    </div>
                                                </FormText>
                                            </td>
                                            <td className="ps-3">
                                                <Async
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: (data.taskStatusError) ? '#FF3D60' : "#CED4DA"
                                                        })
                                                    }}
                                                    name="taskStatus"
                                                    placeholder="Select status"
                                                    isSearchable
                                                    defaultOptions
                                                    loadOptions={handleTaskStatusPercentage}
                                                    onChange={e => handleTaskStatus(e, i)}
                                                />
                                                <FormText sx={{ marginLeft: "10px" }}>
                                                    <div className="text-danger">
                                                        {data.taskStatusError}
                                                    </div>
                                                </FormText>
                                            </td>
                                            <td className="ps-3">
                                                <Async
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: (data.taskTypeError) ? '#FF3D60' : "#CED4DA"
                                                        })
                                                    }}
                                                    name="taskTypeId"
                                                    placeholder="Select task type"
                                                    isSearchable
                                                    defaultOptions
                                                    loadOptions={handleSelectTaskType}
                                                    onChange={e => handleTaskType(e, i)}
                                                />
                                                <FormText sx={{ marginLeft: "10px" }}>
                                                    <div className="text-danger">
                                                        {data.taskTypeError}
                                                    </div>
                                                </FormText>
                                            </td>
                                            <td>
                                                <Button
                                                    color='success'
                                                    onClick={(e) => {
                                                        handleSubmit(e, i)
                                                    }} disabled={props.isUpdateGeneralTaskPending}>
                                                    {props.isPending ? <>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                        &nbsp; Updating...
                                                    </>
                                                        : <>Update</>}</Button>
                                            </td>
                                        </tr>
                                    )) : props.isGeneralTaskListPending ? <div className="sticky-spinner-row">
                                        <div className="position-relative">
                                            <div className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '60%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                    width: '100vw',
                                                    height: '100vh',
                                                    pointerEvents: 'none',
                                                }}>
                                                <VWSpinner />
                                            </div>
                                        </div>
                                    </div>
                                        :
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <NoRecord />
                                                </div>
                                            </div>
                                        </div>}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {formFields && formFields.length > 0 ? (
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} />
                        ) : null}
                    </CardFooter>
                </Card>
                {console.log("isGeneralTaskImgModalOpen:", props.isGeneralTaskImgModalOpen)}
                <VWModel
                    // header="GENERAL TASK IMAGE"
                    isOpen={props.isGeneralTaskImgModalOpen}
                    handleClose={handleClose}
                    modalSize='large'
                >
                    <GeneralTaskImageModal
                        handleClose={handleClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({

    GeneralTaskLists: state.GeneralTasks.GeneralTaskLists,
    isGeneralTaskListPending: state.GeneralTasks.isGeneralTaskListPending,
    generalTaskListError: state.GeneralTasks.generalTaskListError,
    updateGeneralTaskErrorMessage: state.GeneralTasks.updateGeneralTaskErrorMessage,
    isUpdateGeneralTaskPending: state.GeneralTasks.isUpdateGeneralTaskPending,
    isGeneralTaskImgModalOpen: state.GeneralTasks.isGeneralTaskImgModalOpen,
    totalCount: state.GeneralTasks.totalRecords,
    isGeneralTaskImageModalOpen: state.GeneralTasks.isGeneralTaskImageModalOpen,
    currentProfile: state.login.currentProfile,
})
const mapDispatchToProps = (dispatch) => ({
    getProjectNameAsyncSelectAction: (searchValue, callback) => dispatch(getAdminProjectNameAsyncSelectAction(searchValue, callback)),
    getTaskTypeAsyncSelectAction: (searchValue, callback) => dispatch(getTaskTypeAsyncSelectAction(searchValue, callback)),
    getMilestoneSelect: (projectId, searchValue, callback) => dispatch(getMilestoneSelectAction(projectId, searchValue, callback)),
    getAssignedUserAsyncSelectAction: (projectId, searchValue, callback) => dispatch(getAssignedUserAsyncSelect(projectId, searchValue, callback)),
    getGeneralTaskList: (page, PageSize, searchTaskName, searchDescription) => dispatch(getGeneralTaskList(page, PageSize, searchTaskName, searchDescription)),
    EditGeneralTaskAction: (UpdatedData) => dispatch(EditGeneralTask(UpdatedData)),
    clearUpdateGeneralTaskError: () => dispatch(clearUpdateGeneralTaskError()),
    clearGeneralTaskListError: () => dispatch(clearGeneralTaskListError()),
    GeneralTaskImageModalOpen: (index) => dispatch(GeneralTaskImageModalOpen(index)),
    GeneralTaskImageModalClose: () => dispatch(GeneralTaskImageModalClose()),
    updateGeneralTaskSuccess: () => dispatch(updateGeneralTaskSuccess()),
    clearGeneralTaskList: () => dispatch(clearGeneralTaskList()),
    getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),

});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTasks);
