import React, { useEffect } from "react";
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, Table, Tooltip } from "reactstrap";
import { connect } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import VWModel from "../../../../components/Modal/VWModal";
import CreateProjects from "./CreateProjects";
import { clearProjectList, closeCreateProjectModalAction, getManagerUserNameAsyncSelectAction, getProjectDropDownUserListAsyncSelectAction, getProjectExportListAction, getProjectList, openCreateProjectModalAction } from "../../../../store/Modules/ProjectPortal/Projects/actions";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import VWSkeleton from "../../../../components/vwSkeleton/vwSkeleton";
import Select from 'react-select/async';
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getPager } from "../../../../helper/common";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from "../../../../helper/export";
import { PROJECT_EXPORT_HEADERS } from "../../../../store/Modules/ProjectPortal/Projects/constants";
import { ElementToolTip } from "../vwToolTip";


const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};
const ProjectList = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { isProjectExportPending } = props;

    const path = window.location.search;
    const params = new URLSearchParams(path);

    const startDate = params.get('StartDate');
    const endDate = params.get('EndDate');
    const username = params.get('Username');
    const projectName = params.get('ProjectName');

    const { isGlobalReadOnlyUser } = props.currentProfile || {};
    const { userAppDetails } = props.currentProfile || {};
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })
    const buildsearchProjectName = () => {
        const { ProjectName } = filter || {};
        let searchProjectName = '';
        if (ProjectName) {
            searchProjectName += `${ProjectName}`;
        }
        return (searchProjectName) || ''
    }
    const buildsearchDescription = () => {
        const { Description } = filter || {};
        let searchDescription = '';
        if (Description) {
            searchDescription += `${Description}`;
        }
        return (searchDescription) || ''
    }
    const buildsearchComments = () => {
        const { Comments } = filter || {};
        let searchComments = '';
        if (Comments) {
            searchComments += `${Comments}`;
        }
        return (searchComments) || ''
    }
    const buildsearchStartTimeStamp = () => {
        const { StartDate } = filter || {};
        let searchStartTimeStamp = '';
        if (StartDate) {
            searchStartTimeStamp += `${StartDate}`;
        }
        return searchStartTimeStamp || ''
    }
    const buildsearchEndTimeStamp = () => {
        const { EndDate } = filter || {};
        let searchEndTimeStamp = '';
        if (EndDate) {
            searchEndTimeStamp += `${EndDate}`;
        }
        return searchEndTimeStamp || ''
    }
    const buildIsProjectActive = () => {
        const { IsProjectActive } = filter || {};
        let searchIsProjectActive = '';
        if (IsProjectActive) {
            searchIsProjectActive += `${IsProjectActive}`;
        }
        return (searchIsProjectActive) || ''
    }
    const buildIsProjectManagerUserName = () => {
        const { ProjectManagerUserName } = filter || {};
        let searchProjectManagerUserName = '';
        if (ProjectManagerUserName) {
            searchProjectManagerUserName += `${ProjectManagerUserName}`;
        }
        return (searchProjectManagerUserName) || ''
    }

    const setPage = (page = 1) => {
        props.getProjectListAction(filter, page, PageSize, buildsearchProjectName(), buildsearchDescription(), buildsearchComments(), buildsearchStartTimeStamp(), buildsearchEndTimeStamp(), buildIsProjectActive(), buildIsProjectManagerUserName());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }


    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        // navigate filter from dashboard
        if (startDate || endDate || username || projectName) {
            setFilter({
                ...filter,
                StartDate: startDate,
                EndDate: endDate,
                AssignedUserName: username,
                ProjectName: projectName
            });
        }
        else {
            const currentPageNumber = Pager && Pager.currentPage;
            setPage(currentPageNumber);
        }
        return (() => {
            props.clearProjectList();
        })
    }, [])
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])


    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedProjectList = [...props.ProjectList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'IsProjectActive') {
            return sortDirection === 'asc' ? b.IsProjectActive - a.IsProjectActive : a.IsProjectActive - b.IsProjectActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    const handlemanager = (inputValue, callback) => {
        const { getManagerUserNameAsyncSelectAction } = props;
        return getManagerUserNameAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleAddProjectManagerUser = (Value) => {
        console.log("value:", Value);
        const { label, assignedUserEmail, assignedUserName } = Value || {};
        setFilter({
            ...filter,
            ProjectManagerUserName: assignedUserEmail || assignedUserName,
        })
    }
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleOpenCreateProject = () => {
        props.openCreateProjectModal();
    }
    const handleClose = () => {
        props.closeCreateProjectModal();
    };

    const Roles = props.currentProfile && props.currentProfile.roles && props.currentProfile.roles.map((data) => (
        data.roleName
    ))


    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        console.log("Id:", Id);
        navigate(`/projectportal/projects/details/${Id}`);
    };


    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getProjectExportListAction(buildsearchProjectName(), buildsearchDescription(), buildsearchComments(), buildsearchStartTimeStamp(), buildsearchEndTimeStamp(), buildIsProjectActive(), buildIsProjectManagerUserName())
            .then((response) => {
                console.log("response:", response);
                if (response) {
                    const exportProjectListExportList = [];
                    const headerExcel = [
                        { headerName: "PROJECT NAME", key: "projectName", headerWidhth: 30 },
                        { headerName: "PROJECT MANAGER", key: "projectManagerUserName", headerWidhth: 50 },
                        { headerName: "DESCRIPTION", key: "description", headerWidhth: 30 },
                        { headerName: "START DATE", key: "startDate", headerWidhth: 30 },
                        { headerName: "END DATE", key: "endDate", headerWidhth: 30 },
                    ]
                    response && response.forEach((element) => {
                        exportProjectListExportList.push({
                            projectName: element.projectName || "N/A",
                            projectManagerUserName: element.projectManagerUserName || "N/A",
                            description: element.description || "N/A",
                            startDate: element.startDate ? moment(element.startDate).format('DD MMM YYYY') : 'N/A',
                            endDate: element.endDate ? moment(element.endDate).format('DD MMM YYYY') : 'N/A',
                        });
                    });
                    excelSheetExport(headerExcel, exportProjectListExportList, "Project-List");
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong className='d-flex align-items-center'>PROJECTS -&nbsp;<Badge id='projectCount' color="danger" style={{ cursor: 'pointer' }}>{props.isProjectPending ? 0 : (props?.totalCount || 0)}</Badge>
                            <ElementToolTip id={'projectCount'} name={
                                'Projects Count'
                            } /></strong>

                        {userAppDetails && userAppDetails.filter(item => item.appName.toLowerCase() === "projectportal").length > 0 &&
                            userAppDetails
                                .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "projectportal")
                                .map(userApp => {
                                    const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                        const roleName = role.roleName.toLowerCase();
                                        return roleName;
                                    });
                                    const isAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
                                    return (
                                        <React.Fragment key={userApp.id}>
                                            <div className="content-heading">
                                                <div className="ml-auto">
                                                    {isAdmin && (!isGlobalReadOnlyUser) ? (
                                                        <Link
                                                            type="button"
                                                            className="btn btn-info"
                                                            onClick={handleOpenCreateProject}
                                                        >
                                                            CREATE
                                                        </Link>
                                                    ) : null}&nbsp;
                                                    <Button
                                                        className="me-4"
                                                        color="success"
                                                        onClick={handleExports}
                                                        disabled={props.ProjectList && props.ProjectList.length <= 0 || isProjectExportPending}
                                                    >
                                                        {isProjectExportPending ? 'EXPORTING...' : 'EXPORT'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })
                        }
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody className="p-4">
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        <div style={{ height: "60vh", overflowX: "auto" }}>
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                    <tr>
                                        <th
                                            onClick={() => handleSort('ProjectName')} style={{ minWidth: '200px' }} className='hand-cursor'
                                        >Project Name &nbsp;
                                            {sortOrder === 'ProjectName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th
                                            onClick={() => handleSort('ProjectManagerUserName')} style={{ minWidth: '200px' }} className='hand-cursor'
                                        >Project Manager &nbsp;
                                            {sortOrder === 'ProjectManagerUserName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th
                                            onClick={() => handleSort('Description')} style={{ minWidth: '200px' }} className='hand-cursor'
                                        >
                                            Description &nbsp;
                                            {sortOrder === 'Description' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('StartDate')} style={{ minWidth: '200px' }} className='hand-cursor'
                                        >
                                            Start Date &nbsp;
                                            {sortOrder === 'StartDate' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('EndDate')} style={{ minWidth: '200px' }} className='hand-cursor'
                                        >
                                            End Date &nbsp;
                                            {sortOrder === 'EndDate' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                            View
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='ProjectName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.ProjectName}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th >
                                            <Select
                                                styles={customStyles}
                                                name="ProjectManagerUserName"
                                                placeholder="Select project manager"
                                                isSearchable
                                                defaultOptions
                                                loadOptions={handlemanager}
                                                isClearable
                                                onChange={handleAddProjectManagerUser}
                                            />
                                        </th>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='Description'
                                                onChange={searchInputValidation}
                                                value={filter && filter.Description}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >
                                            <Input
                                                type='date'
                                                placeholder='Search'
                                                name='StartDate'
                                                onChange={searchInputValidation}
                                                value={filter && filter.StartDate}
                                                onKeyPress={handleKeyPress}
                                                max="9999-12-31"
                                            /></th>
                                        <th >
                                            <Input
                                                type='date'
                                                placeholder='Search'
                                                name='EndDate'
                                                onChange={searchInputValidation}
                                                value={filter && filter.EndDate}
                                                onKeyPress={handleKeyPress}
                                                max="9999-12-31"
                                            /></th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!props.isProjectPending && (props.ProjectList && props.ProjectList.length > 0) ?
                                        sortedProjectList && sortedProjectList.map((data) => (
                                            <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                <td className='ps-3'>{data.ProjectName || "N/A"}</td>
                                                <td className='ps-3'>{data.ProjectManagerUserName || "N/A"}
                                                    <br />
                                                    <small>{data.ProjectManagerUserEmail || "N/A"}</small>
                                                </td>
                                                {/* <td className='ps-3'>{data.Description ? (data.Description && data.Description.length > 20) ? data.Description && data.Description.substring(0, 20) + '...' : data.Description : "N/A"}</td> */}
                                                <td className='ps-3'>{data.Description ? (data.Description && data.Description.length > 20) ? <><span id={`description-${data.Id}`}>{data.Description && data.Description.substring(0, 20) + '...'}</span>
                                                    <ElementToolTip
                                                        id={`description-${data.Id}`}
                                                        name={data.Description && data.Description.length > 20 ? data.Description : ''}
                                                    >
                                                        {data.Description && data.Description.length > 20
                                                            ? data.Description && data.Description.substring(0, 20) + '...'
                                                            : data.Description}
                                                    </ElementToolTip>
                                                </>
                                                    : data.Description : "N/A"}
                                                </td>
                                                <td className='ps-3'>{data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'}</td>
                                                <td className='ps-3'>{data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}</td>
                                                <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                                                    type="button"
                                                    className="btn btn-sm  btn-light"
                                                    to={`/projectportal/projects/details/${data.Id}`}
                                                >
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                            </tr>
                                        ))
                                        : props.isProjectPending ?
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <VWSpinner />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <NoRecord />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.ProjectList && props.ProjectList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="CREATE PROJECT"
                    modalSize="extra-large"
                    isOpen={props.isCreateProjectTypeModalOpen}
                    handleClose={handleClose}
                >
                    <CreateProjects
                        filterData={filter}
                        handleClose={handleClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isCreateProjectTypeModalOpen: state.ProjectPortal.isCreateProjectModalOpen,
    ProjectList: state.ProjectPortal.ProjectLists,
    isProjectPending: state.ProjectPortal.isProjectPending,
    errorMessage: state.ProjectPortal.ProjectListError,
    totalCount: state.ProjectPortal.totalRecords,
    isProjectExportPending: state.ProjectPortal.isProjectExportPending,
    currentProfile: state.login.currentProfile,
});

const mapDispatchToProps = (dispatch) => ({
    getManagerUserNameAsyncSelectAction: (appID, searchValue, callback) => dispatch(getProjectDropDownUserListAsyncSelectAction(appID, searchValue, callback)),
    clearProjectList: () => dispatch(clearProjectList()),
    closeCreateProjectModal: () => dispatch(closeCreateProjectModalAction()),
    openCreateProjectModal: () => dispatch(openCreateProjectModalAction()),
    getProjectListAction: (filter, page, PageSize, searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName) => dispatch(getProjectList(filter, page, PageSize, searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName)),
    getProjectExportListAction: (searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName) => dispatch(getProjectExportListAction(searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);

