export const SET_DOCS_LIST = "SET_DOCS_LIST"
export const CREATE_VISIONDOCS_ERROR = "CREATE_VISIONDOCS_ERROR"
export const CREATE_VISION_DOCS_PENDING = "CREATE_VISION_DOCS_PENDING"
export const CLEAR_CREATE_VISION_DOCS_ERROR = "CLEAR_CREATE_VISION_DOCS_ERROR"
export const CREATE_VISION_DOCS_SUCCESS = "CREATE_VISION_DOCS_SUCCESS"
export const VISION_DOCS_LIST_PENDING = "VISION_DOCS_LIST_PENDING"
export const SET_VISION_DOCS_LIST = "SET_VISION_DOCS_LIST"
export const SET_TOGGLE_VIEW = "SET_TOGGLE_VIEW"
export const CLEAR_VISION_DOCS_LIST_ERROR = "CLEAR_VISION_DOCS_LIST_ERROR"
export const VISION_DOCS_LIST_ERROR = "VISION_DOCS_LIST_ERROR"
export const VISION_DOCS_DETAILS_PENDING = "VISION_DOCS_DETAILS_PENDING"
export const SET_VISION_DOCS_DETAILS = "SET_VISION_DOCS_DETAILS"
export const CLEAR_VISION_DOCS_DETAILS_ERROR = "CLEAR_VISION_DOCS_DETAILS_ERROR"
export const VISION_DOCS_DETAILS_ERROR = "VISION_DOCS_DETAILS_ERROR"
export const UPDATE_VISION_DOCS_PENDING = "UPDATE_VISION_DOCS_PENDING"
export const CLEAR_UPDATE_VISION_DOCS_ERROR = "CLEAR_UPDATE_VISION_DOCS_ERROR"
export const UPDATE_VISION_DOCS_SUCCESS = "UPDATE_VISION_DOCS_SUCCESS"
export const UPDATE_VISIONDOCS_ERROR = "UPDATE_VISIONDOCS_ERROR"
export const CREATE_VISIONDOCS_CONTENT = "CREATE_VISIONDOCS_CONTENT"
export const CREATE_VISIONDOCS_TITLE = "CREATE_VISIONDOCS_TITLE"
export const OPEN_CREATE_VISION_DOCS_MODAL = "OPEN_CREATE_VISION_DOCS_MODAL"
export const CLOSE_CREATE_VISION_DOCS_MODAL = "CLOSE_CREATE_VISION_DOCS_MODAL"
export const OPEN_UPDATE_VISION_DOCS_MODAL = "OPEN_UPDATE_VISION_DOCS_MODAL"
export const CLOSE_UPDATE_VISION_DOCS_MODAL = "CLOSE_UPDATE_VISION_DOCS_MODAL"
export const IS_UPDATE_VISION_DOCS_SUCCESS = "IS_UPDATE_VISION_DOCS_SUCCESS"
export const IS_UPDATE_VISION_DOCS_PENDING = "IS_UPDATE_VISION_DOCS_PENDING"
export const CLEAR_VISION_DOCS_DETAILS = "CLEAR_VISION_DOCS_DETAILS"
export const IS_CREATE_VISION_DOCS_SUCCESS = "IS_CREATE_VISION_DOCS_SUCCESS"
export const IS_CREATE_VISION_DOCS_PENDING = "IS_CREATE_VISION_DOCS_PENDING"
export const OPEN_CREATE_VISION_DOCS_HEADER_MODAL = "OPEN_CREATE_VISION_DOCS_HEADER_MODAL"
export const CLOSE_CREATE_VISION_DOCS_HEADER_MODAL = "CLOSE_CREATE_VISION_DOCS_HEADER_MODAL"
export const VISION_DOCS_CREATE_ID = "VISION_DOCS_CREATE_ID"
export const CLEAR_CREATE_VISION_DOCS = "CLEAR_CREATE_VISION_DOCS"
export const OPEN_UPDATE_VISION_DOCS_HEADER_MODAL = "OPEN_UPDATE_VISION_DOCS_HEADER_MODAL"
export const CLOSE_UPDATE_VISION_DOCS_HEADER_MODAL = "CLOSE_UPDATE_VISION_DOCS_HEADER_MODAL"
export const OPEN_VISION_DOCS_DELETE = "OPEN_VISION_DOCS_DELETE"
export const CLOSE_VISION_DOCS_DELETE = "CLOSE_VISION_DOCS_DELETE"
export const VISION_DOCS_DELETE_LIST_ERROR = "VISION_DOCS_DELETE_LIST_ERROR"
export const CLEAR_DELETE_VISION_DOCS_ERROR = "CLEAR_DELETE_VISION_DOCS_ERROR"
export const VISION_DOCS_DELETE_PENDING = "VISION_DOCS_DELETE_PENDING"
export const CLEAR_VISION_DOCS_DELETE_ERROR = "CLEAR_VISION_DOCS_DELETE_ERROR"
export const VISION_DOCS_DETAILS_SUCCESS = "VISION_DOCS_DETAILS_SUCCESS"
export const IS_DOCUMENT_UPDATED = "IS_DOCUMENT_UPDATED"
export const CLEAR_IS_DOCUMENT_UPDATED = "CLEAR_IS_DOCUMENT_UPDATED"
