import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import Badges from '@mui/material/Badge';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Table,
  Badge,
  Col,
  Row,
  InputGroup,
  Tooltip
} from 'reactstrap'
import { getPager } from '../../../../helper/common';
import VWPagination from '../../../../components/vwPagination/vwPagination';
import {
  getAllTimeSheetExportListAction,
  getAllTimeSheetListActionModal
} from '../../../../store/Modules/Timesheet/allTimeSheet/actions';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { useSearchParams } from 'react-router-dom';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import {
  clearSetLoginPath,
  clearSetPath
} from '../../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faFont
} from "@fortawesome/free-solid-svg-icons";
import UITooltip from '../../../../CommonData/Data/UITooltip';
import { excelPrint, excelSheetExport } from '../../../../helper/export';
import { ALL_TIMESHEET_EXPORT_HEADERS } from '../../../../store/Modules/Timesheet/allTimeSheet/constants';
import {
  getTaskScopeListAsyncSelectAction,
  getTaskStatusAsyncSelectAction,
  getTaskStatusAsyncSelectTimesheet
} from '../../../../store/Modules/ProjectPortal/Task/actions';
import Async from "react-select/async";
import { ElementToolTip } from '../../Projects/vwToolTip';
import { getProjectDropDownUserListAsyncSelectAction, getUserNameAsyncSelect } from '../../../../store/Modules/ProjectPortal/Projects/actions';

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};
const ScopeOptions = [
  { label: 'Development', value: 'Development' },
  { label: 'Testing', value: 'Testing' },
  { label: 'Discussion', value: 'Discussion' },
  { label: 'Review', value: 'Review' },
  { label: 'Deployment', value: 'Deployment' },
  { label: 'Documentation', value: 'Documentation' },
]
const AllTimesheet = (props) => {

  const path = window.location.search;
  const params = new URLSearchParams(path);
  const id = params.get('filter');
  const { isAllTimesheetExportPending } = props;
  const [badgeCount, setBadgeCount] = useState(0);
  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 });
  const [filter, setFilter] = useState({});
  const [allTimeSheetStatus, setAllTimeSheetStatus] = useState();
  const [filterparams, setFilterParams] = useSearchParams();
  const [filterSelectionOption, setFilterSelectionOption] = useState('name');

  const [all, setAll] = useState(true);
  const [Submitted, setSubmitted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);

  const buildQueryuserName = () => {
    const { UserName } = filter;
    let searchuserName = '';
    if (UserName) {
      searchuserName += `${UserName}`;
    }
    return searchuserName
  }
  const buildQueryprojectName = () => {
    const { ProjectName } = filter;
    let searchprojectName = '';
    if (ProjectName) {
      searchprojectName += `${ProjectName}`;
    }
    return (searchprojectName)
  }
  const buildQuerytaskName = () => {
    const { TaskName } = filter;
    let searchtaskName = '';
    if (TaskName) {
      searchtaskName += `${TaskName}`;
    }
    return (searchtaskName)
  }
  const buildQueryCurrentTaskStatus = () => {
    const { CurrentTaskStatus } = filter;
    let searchCurrentTaskStatus = '';
    if (CurrentTaskStatus) {
      searchCurrentTaskStatus += `${CurrentTaskStatus}`;
    }
    return (searchCurrentTaskStatus)
  }
  const buildQueryType = () => {
    const { ScopeName } = filter;
    let searchType = '';
    if (ScopeName) {
      searchType += `${ScopeName}`;
    }
    return (searchType)
  }
  const buildworkHours = () => {
    const { WorkHours } = filter;
    let searchworkHours = '';
    if (WorkHours) {
      searchworkHours += `${WorkHours}`;
    }
    return (searchworkHours)
  }
  const buildworkDate = () => {
    const { WorkDate } = filter;
    let searchworkDate = '';
    if (WorkDate) {
      searchworkDate += `${WorkDate}`;
    }
    return (searchworkDate)
  }
  const buildcreatedDateTime = () => {
    const { CreatedDateTime } = filter;
    let searchcreatedDateTime = '';
    if (CreatedDateTime) {
      searchcreatedDateTime += `${CreatedDateTime}`;
    }
    return searchcreatedDateTime
  }

  const buildStatus = () => {
    const { Status } = filter;
    let searchStatus = '';
    if (Status) {
      searchStatus += `${Status}`;
    }
    return (searchStatus)
  }
  const buildUpdatedBy = () => {
    const { UpdatedBy } = filter;
    let searchUpdatedBy = '';
    if (UpdatedBy) {
      searchUpdatedBy += `${UpdatedBy}`;
    }
    return (searchUpdatedBy)
  }
  const buildUpdatedTimeStamp = () => {
    const { UpdatedTimeStamp } = filter;
    let searchUpdatedTimeStamp = '';
    if (UpdatedTimeStamp) {
      searchUpdatedTimeStamp += `${UpdatedTimeStamp}`;
    }
    return searchUpdatedTimeStamp
  }
  const searchType = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      ScopeName: label,
    })
  }
  const searchStatus = (option) => {
    const { value } = option || {};
    setFilter({
      ...filter,
      CurrentTaskStatus: value,
    })
  }

  const setPage = (page = 1) => {
    props.getAllTimeSheetListAction(page, PageSize, buildQueryuserName(), buildQueryprojectName(), buildQuerytaskName(), buildQueryCurrentTaskStatus(),
      buildworkDate(), buildworkHours(), buildQueryType(), buildcreatedDateTime(), buildStatus(), buildUpdatedBy(), buildUpdatedTimeStamp(), id);

    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }
      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    return async () => {
      await props.clearSetLoginPath();
      props.ClearPath();
    }
  }, []);

  //eslint-disable-next-line
  useEffect(() => {
    const currentPager = getPager(props.totalCount, 1, PageSize)
    setPager(currentPager)
    setBadgeCount(props.totalCount);
  }, [props.totalCount])

  useEffect(() => {
    props.ClearPath();
    if (id === "Submitted") {
      setAll(false)
      setSubmitted(true)
      setFilter({
        ...filter,
        Status: "Submitted",
      })
    }
    else if (id === "Approved") {
      setAll(false);
      setSubmitted(false);
      setRejected(false);
      setApproved(true);
      setFilter({
        ...filter,
        Status: "Approved",
      })
    }
    else if (id === "Rejected") {
      setAll(false);
      setSubmitted(false);
      setRejected(true);
      setApproved(false);
      setFilter({
        ...filter,
        Status: "Rejected",
      })
    }
    else {
      setAll(true);
      setSubmitted(false);
      setRejected(false);
      setApproved(false);
      setFilter({
        ...filter,
        Status: "All",
      })
      setFilterParams('filter=All')
    }
  }, [])



  //------------------------------------------------------------------
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }


  const handleSearchClick = () => {
    setPage();
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const searchInputValidationDate = (event) => {
    const { value, name } = event.target;
    setFilter({
      ...filter,
      [name]: value,
    })

  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
    }

  }, [filter])

  const { userAppDetails } = props && props.currentProfile;
  const App = userAppDetails.filter((i) => {
    return (i.appName === "Timesheets") ? i.appId : ""
  })

  const handleProjectUser = (inputValue, callback) => {
    const { getUserNameAsyncSelectAction } = props;
    return getUserNameAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSearchprojectUser = (Value) => {
    console.log("value:", Value);
    const { label, assignedUserEmail, assignedUserName } = Value || {};
    setFilter({
      ...filter,
      UserName: assignedUserEmail || assignedUserName,
    })
  }
  //------------------------------------Status Filter with Count ---------------------------------------------------
  const handleAllAction = () => {
    setBadgeCount(0);
    setAll(true);
    setRejected(false);
    setApproved(false);
    setSubmitted(false);
    setFilterParams({
      filter: "All"
    })
    setFilter({
      ...filter,
      Status: "All",
    })

  };
  const handleSubmittedAction = () => {
    setBadgeCount(0);
    setAll(false);
    setRejected(false);
    setApproved(false);
    setSubmitted(true);
    setFilterParams({
      filter: "Submitted"
    })
    setFilter({
      ...filter,
      Status: "Submitted",
    })
  };
  const handleRejectedAction = () => {
    setBadgeCount(0);
    setAll(false);
    setRejected(true);
    setApproved(false);
    setSubmitted(false);
    setFilterParams({
      filter: "Rejected"
    })
    setFilter({
      ...filter,
      Status: "Rejected",
    })
  };
  const handleApprovedAction = () => {
    setBadgeCount(0);
    setAll(false);
    setRejected(false);
    setApproved(true);
    setSubmitted(false);
    setFilterParams({
      filter: "Approved"
    })
    setFilter({
      ...filter,
      Status: "Approved",
    })
  };
  //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
  const handleTextSelect = (e) => {
    e.preventDefault();
    setFilterSelectionOption('date');
    setFilter({
      ...filter,
      UpdatedBy: null,
      UpdatedTimeStamp: null,
    });
  }

  const handleDateSelect = (e) => {
    e.preventDefault();
    setFilterSelectionOption('name');
    setFilter({
      ...filter,
      UpdatedBy: null,
      UpdatedTimeStamp: null,
    });
  }

  const handleTaskScope = (inputValue, callback) => {
    const { getTaskScopeAsyncSelectAction } = props;
    return getTaskScopeAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleTaskStatus = (inputValue, callback) => {
    const { getTaskStatusAsyncSelectAction } = props;
    return getTaskStatusAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  // ------------------------------------------SORTING------------------------------------------------------------//
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedAllTimesheetList = [...props.AllTimeSheetList].sort((a, b) => {
    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };


  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getAllTimeSheetExportListAction(buildQueryuserName(), buildQueryprojectName(), buildQuerytaskName(), buildQueryCurrentTaskStatus(),
      buildworkDate(), buildworkHours(), buildQueryType(), buildcreatedDateTime(), buildStatus(), buildUpdatedBy(), buildUpdatedTimeStamp(), id)
      .then((AllTaskList) => {
        const exportAllTimesheetExportList = [];
        const headerExcel = [
          { headerName: "USER NAME", key: "userName", headerWidhth: 30 },
          { headerName: "USER EMAIL", key: "userEmail", headerWidhth: 50 },
          { headerName: "PROJECT NAME", key: "projectName", headerWidhth: 30 },
          { headerName: "TASK NAME", key: "taskName", headerWidhth: 30 },
          { headerName: "TASK CURRENT STATUS", key: "currentTaskStatus", headerWidhth: 30 },
          { headerName: "WORK DATE", key: "workDate", headerWidhth: 20 },
          { headerName: "WORK HOURS", key: "workHours", headerWidhth: 20 },
          { headerName: "SCOPE", key: "scopeName", headerWidhth: 20 },
          { headerName: "CREATED DATE", key: "createdDateTime", headerWidhth: 20 },
          { headerName: "STATUS", key: "status", headerWidhth: 20 },
          { headerName: "UPDATED DATE", key: "updatedTimeStamp", headerWidhth: 20 },
        ]
        AllTaskList && AllTaskList.forEach((element) => {
          exportAllTimesheetExportList.push({
            userName: element.userName || "N/A",
            userEmail: element.userEmail || "N/A",
            projectName: element.projectName || "N/A",
            taskName: element.taskName || "N/A",
            currentTaskStatus: element.currentTaskStatus || "N/A",
            workDate: element.workDate ? moment(element.workDate).format('DD MMM YYYY') : 'N/A',
            workHours: element.workHours || "N/A",
            scopeName: element.scopeName || "N/A",
            createdDateTime: element.createdDateTime ? moment(element.createdDateTime).format('DD MMM YYYY') : 'N/A',
            status: element.status || "N/A",
            updatedTimeStamp: element.updatedTimeStamp ? moment(element.updatedTimeStamp).format('DD MMM YYYY') : 'N/A',
          });
        });
        excelSheetExport(headerExcel, exportAllTimesheetExportList, "All Timesheet-List");
      })
  }

  const [AllTimesheetCountTooltipOpen, setAllTimesheetCountTooltipOpen] = useState(false);
  const toggleAllTimesheetCountTooltipOpen = () => setAllTimesheetCountTooltipOpen(!AllTimesheetCountTooltipOpen);
  return (
    <>
      <div className="page-content">
        <Card >
          <CardTitle tag="h4"
            className="border-bottom px-4 py-3 mb-0">
            <Row>
              <Col lg='3' md='3' sm='4' className='d-flex align-items-center ps-0'>
                <strong className='d-flex align-items-center'> ALL TIMESHEET -&nbsp;<Badge color="danger" id='AllTimesheetCount' style={{ cursor: 'pointer' }}>{props.isAllTimesheetPending ? 0 : (props && props.totalCount || 0)}</Badge>
                  <ElementToolTip id='AllTimesheetCount' name={
                    'All Timesheet Count'
                  } /></strong>
              </Col>
              <Col lg='7' md='7' sm='6' className='d-flex align-items-center justify-content-around gap-4 ps-0'>
                <Row>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isAllTimesheetPending ? null : badgeCount} color='primary' invisible={all !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleAllAction}
                        className={all !== true ? "btn btn-outline-secondary" : 'btn btn-outline-secondary active'}
                        outline color="secondary"
                      ><b>All</b>
                      </Button>
                    </Badges>
                  </Col>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isAllTimesheetPending ? null : badgeCount} color="primary" invisible={Submitted !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleSubmittedAction}
                        className={Submitted !== true ? "btn btn-outline-primary " : 'btn btn-outline-primary  active'}
                        outline color="primary"
                      ><b>Submitted</b>
                      </Button>
                    </Badges>
                  </Col>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isAllTimesheetPending ? null : badgeCount} color="primary" invisible={approved !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleApprovedAction}
                        className={approved !== true ? "btn btn-outline-success" : 'btn btn-outline-success active'}
                        outline color="success"
                      ><b>Approved</b>
                      </Button>
                    </Badges>
                  </Col>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isAllTimesheetPending ? null : badgeCount} color="primary" invisible={rejected !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleRejectedAction}
                        className={rejected !== true ? "btn btn-outline-danger " : 'btn btn-outline-danger  active'}
                        outline color="danger"
                      ><b>Rejected</b>
                      </Button>
                    </Badges>
                  </Col>
                </Row>
              </Col>
              <Col lg='1' md='1' sm='1'>
              </Col>
              <Col lg='1' md='1' sm='1' className='d-flex align-items-center justify-content-end pe-0'>
                <Button
                  className="me-2 mb-2"
                  color="success"
                  onClick={handleExports}
                  disabled={isAllTimesheetExportPending || !props.totalCount}
                >
                  {isAllTimesheetExportPending ? 'EXPORTING' : 'EXPORT'}
                </Button>
              </Col>
            </Row>
          </CardTitle>
        </Card>
        <Card>
          <>
            <CardBody className="p-4">
              {props.allTimesheetErrorMessage && (
                <VWMessage type="danger" defaultMessage={props.allTimesheetErrorMessage} baseClassName="alert" />
              )}
              <div style={{ height: "60vh", overflowX: "auto" }}> {/* maxWidth: "1600px" */}
                <Table className="table table-striped table-hover table-nowrap w-100 " style={style.table}>
                  <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                    <tr>
                      <th onClick={() => handleSort('UserName')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        User&nbsp;
                        {sortOrder === 'UserName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('ProjectName')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Project Name&nbsp;
                        {sortOrder === 'ProjectName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('TaskName')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Task Name&nbsp;
                        {sortOrder === 'TaskName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('CurrentTaskStatus')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Task Current Status&nbsp;
                        {sortOrder === 'CurrentTaskStatus' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('WorkDate')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Work Date&nbsp;
                        {sortOrder === 'WorkDate' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('WorkHours')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Work Hours&nbsp;
                        {sortOrder === 'WorkHours' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('ScopeName')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Scope &nbsp;
                        {sortOrder === 'ScopeName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('CreatedDateTime')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Created Date&nbsp;
                        {sortOrder === 'CreatedDateTime' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('Status')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Status&nbsp;
                        {sortOrder === 'Status' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('UpdatedBy')} style={{ minWidth: '150px' }} className='hand-cursor'>
                        Updated&nbsp;
                        {sortOrder === 'UpdatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                    </tr>
                    <tr>
                      <th >
                        {/* <Input
                          type='text'
                          placeholder='Search'
                          name='UserName'
                          onChange={searchInputValidation}
                          value={filter && filter.UserName}
                          onKeyPress={handleKeyPress}
                        /> */}
                        <Async
                          styles={customStyles}
                          name="UserName"
                          isClearable
                          defaultOptions
                          placeholder="Search"
                          loadOptions={handleProjectUser}
                          onChange={handleSearchprojectUser}
                        />
                      </th>
                      <th>
                        <Input
                          type='text'
                          placeholder='Search'
                          name='ProjectName'
                          onChange={searchInputValidation}
                          value={filter && filter.ProjectName}
                          onKeyPress={handleKeyPress}
                        />
                      </th>
                      <th >
                        <Input
                          type='text'
                          placeholder='Search'
                          name='TaskName'
                          onChange={searchInputValidation}
                          value={filter && filter.TaskName}
                          onKeyPress={handleKeyPress}
                        />
                      </th>
                      <th>
                        <Async
                          styles={customStyles}
                          name="CurrentTaskStatus"
                          isClearable
                          defaultOptions
                          placeholder="Search"
                          loadOptions={handleTaskStatus}
                          onChange={searchStatus}
                        />
                      </th>
                      <th >
                        <Input
                          type='date'
                          placeholder='Search'
                          name='WorkDate'
                          onChange={searchInputValidation}
                          value={filter && filter.WorkDate}
                          onKeyPress={handleKeyPress}
                          max="9999-12-31"
                        />
                      </th>
                      <th >
                        <Input
                          type='text'
                          placeholder='Search'
                          name='WorkHours'
                          onChange={searchInputValidation}
                          value={filter && filter.WorkHours}
                          onKeyPress={handleKeyPress}
                        />
                      </th>
                      <th >
                        <Async
                          styles={customStyles}
                          name="ScopeName"
                          isClearable
                          defaultOptions
                          placeholder="Search"
                          loadOptions={handleTaskScope}
                          onChange={searchType}
                        />
                      </th>
                      <th >
                        <Input
                          type='date'
                          placeholder='Search'
                          name='CreatedDateTime'
                          onChange={searchInputValidationDate}
                          value={filter && filter.CreatedDateTime}
                          onKeyPress={handleKeyPress}
                          max="9999-12-31"
                        />
                      </th>
                      <th >
                        <Input
                          type='text'
                          placeholder='Search'
                          name='Status'
                          onChange={searchInputValidation}
                          value={filter && filter.Status}
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </th>
                      <th>
                        <InputGroup style={{ width: "200px" }}>
                          {filterSelectionOption === 'name' ?
                            <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                              <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                            </UITooltip>
                            : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                              <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                            </UITooltip>
                          }
                          <Input
                            style={{ width: "120px" }}
                            type={filterSelectionOption === 'name' ? 'text' : 'date'}
                            placeholder='Search'
                            name={filterSelectionOption === 'name' ? 'UpdatedBy' : 'UpdatedTimeStamp'}
                            onChange={searchInputValidation}
                            onKeyPress={handleKeyPress}
                            value={filter ? filterSelectionOption === 'name' ? filter.UpdatedBy || '' : filter.UpdatedTimeStamp || '' : ""}
                            max={filterSelectionOption !== "name" && "9999-12-31"}
                          />
                        </InputGroup>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!props.isAllTimesheetPending && (props.AllTimeSheetList && props.AllTimeSheetList.length > 0) ?
                      sortedAllTimesheetList && sortedAllTimesheetList.map((data) => (

                        <tr>
                          <td className='ps-3'>{data.UserName || "N/A"}
                            <br />
                            <small>{data.UserEmail || ""}</small>
                          </td>
                          <td className='ps-3'>{data.ProjectName || "N/A"}</td>
                          {/* <td className='ps-3'>{data.TaskName ? (data.TaskName && data.TaskName.length > 20) ? data.TaskName && data.TaskName.substring(0, 20) + '...' : data.TaskName : "N/A"}</td> */}
                          <td className='ps-3'>{data.TaskName ? (data.TaskName && data.TaskName.length > 20) ? <><span id={`taskName-${data.Id}`}>{data.TaskName && data.TaskName.substring(0, 20) + '...'}</span>
                            <ElementToolTip
                              id={`taskName-${data.Id}`}
                              name={data.TaskName && data.TaskName.length > 20 ? data.TaskName : ''}
                            >
                              {data.TaskName && data.TaskName.length > 20
                                ? data.TaskName && data.TaskName.substring(0, 20) + '...'
                                : data.TaskName}
                            </ElementToolTip>
                          </>
                            : data.TaskName : "N/A"}
                          </td>
                          <td className='ps-3'>{data.CurrentTaskStatus || "N/A"}</td>
                          <td className='ps-3'>{data.WorkDate ? moment(data.WorkDate).format('DD MMM YYYY') : 'N/A'}</td>
                          <td className='ps-3'>{`${data.WorkHours} hrs` || "N/A"}</td>
                          <td className='ps-3'>{data.ScopeName || "N/A"}</td>
                          <td className='ps-3'>{data.CreatedDateTime ? moment(data.CreatedDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                          <td className='ps-3'>{data.Status || "N/A"}</td>
                          <td>
                            {data.UpdatedBy || "N/A"}<br />
                            <small>{data.UpdatedTimeStamp ? moment(data.UpdatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                          </td>
                        </tr>

                      ))
                      : props.isAllTimesheetPending ? <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <VWSpinner />
                          </div>
                        </div>
                      </div>
                        :
                        <div className="sticky-spinner-row">
                          <div className="position-relative">
                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                position: 'fixed',
                                top: '50%',
                                left: '60%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                                width: '100vw',
                                height: '100vh',
                                pointerEvents: 'none',
                              }}>
                              <NoRecord />
                            </div>
                          </div>
                        </div>}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            <CardFooter>
              {props.AllTimeSheetList && props.AllTimeSheetList.length > 0 ?
                <VWPagination
                  pager={Pager}
                  setPage={setPage}
                  totalRecords={props.totalCount}
                  pageSize={PageSize}
                />
                : null
              }
            </CardFooter>
          </>

        </Card>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  AllTimeSheetList: state.AllTimesheet.AllTimeSheetList,
  totalCount: state.AllTimesheet.totalRecords,
  isAllTimesheetPending: state.AllTimesheet.isAllTimesheetPending,
  allTimesheetErrorMessage: state.AllTimesheet.allTimesheetErrorMessage,
  AllTimeSheetExportList: state.AllTimesheet.AllTimeSheetExportList,
  isAllTimesheetExportPending: state.AllTimesheet.isAllTimesheetExportPending,
  currentProfile: state.login.currentProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTimeSheetListAction: (page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate,
    searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id) =>
    dispatch(getAllTimeSheetListActionModal(page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus,
      searchworkDate, searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id)),
  getAllTimeSheetExportListAction: (searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate, searchworkHours,
    searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id) =>
    dispatch(getAllTimeSheetExportListAction(searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate,
      searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id)),
  ClearPath: () => dispatch(clearSetPath()),
  clearSetLoginPath: () => dispatch(clearSetLoginPath()),
  getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeListAsyncSelectAction(appId, searchValue, callback)),
  getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectTimesheet(appId, searchValue, callback)),
  getUserNameAsyncSelectAction: (appId, searchValue, callback) => dispatch(getProjectDropDownUserListAsyncSelectAction(appId, searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTimesheet)