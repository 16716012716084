import { CLEAR_APP_MASTER_DETAILS_ERROR, CLEAR_APP_MASTER_EXPORT_LIST_ERROR, CLEAR_APP_MASTER_LIST_ERROR, CLEAR_CREATE_APP_MASTER_ERROR, CLEAR_UPDATE_APP_MASTER_ERROR, CLOSE_CREATE_APP_MASTER_MODAL, CLOSE_EDIT_APP_MASTER_MODAL, CREATE_APP_MASTER_ERROR, CREATE_APP_MASTER_PENDING, CREATE_APP_MASTER_SUCCESS, OPEN_CREATE_APP_MASTER_MODAL, OPEN_EDIT_APP_MASTER_MODAL, SET_APP_DETAILS_ERROR, SET_APP_EXPORT_LIST_ERROR, SET_APP_LIST_ERROR, SET_APP_MASTER_DETAILS, SET_APP_MASTER_DETAILS_PENDING, SET_APP_MASTER_EXPORT_LIST, SET_APP_MASTER_EXPORT_PENDING, SET_APP_MASTER_EXPORT_SUCCESS, SET_APP_MASTER_LIST, SET_APP_MASTER_PENDING, UPDATE_APP_MASTER_ERROR, UPDATE_APP_MASTER_PENDING, UPDATE_APP_MASTER_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    isCreateAppMasterModalOpen: false,
    isEditAppMasterModalOpen: false,
    AppMasterList: [],
    appMasterListError: null,
    totalRecords: 0,
    appMasterDetails: null,
    appMasterDetailsError: null,
    updateAppMasterErrorMessage: null,
    isUpdateAppMasterPending: false,
    isAppMasterListPending: false,
    isAppMasterDetailsPending: false,
    appMasterExportError: null,
    isAppMasterExportPending: false,
    AppMasterExportList: [],
};
export default (localState = initialState, action) => {
    switch (action.type) {

        case OPEN_CREATE_APP_MASTER_MODAL: {
            return {
                ...localState,
                isCreateAppMasterModalOpen: true,
            };
        }
        case OPEN_EDIT_APP_MASTER_MODAL: {
            return {
                ...localState,
                isEditAppMasterModalOpen: true,
            };
        }

        case CLOSE_CREATE_APP_MASTER_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateAppMasterModalOpen: false,
            };
        }
        case CLOSE_EDIT_APP_MASTER_MODAL: {
            return {
                ...localState,
                updateAppMasterErrorMessage: null,
                isEditAppMasterModalOpen: false,
            };
        }
        case SET_APP_MASTER_LIST: {
            const { appmasterList } = action.payload;
            return {
                ...localState,
                isAppMasterListPending: false,
                AppMasterList: appmasterList && appmasterList.value,
                totalRecords: appmasterList["@odata.count"],
            }
        }
        case SET_APP_MASTER_EXPORT_LIST: {
            const { appmasterExportList } = action.payload;
            return {
                ...localState,
                isAppMasterExportPending: false,
                AppMasterExportList: appmasterExportList,
                // totalRecords: appmasterList["@odata.count"],
            }
        }
        case SET_APP_MASTER_EXPORT_SUCCESS: {
            return {
                ...localState,
                isAppMasterExportPending: false,
            }
        }
        case SET_APP_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isAppMasterListPending: false,
                appMasterListError: errorMessage,
            }
        }
        case SET_APP_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isAppMasterExportPending: false,
                appMasterExportError: errorMessage,
            }
        }
        case CLEAR_APP_MASTER_LIST_ERROR: {
            return {
                ...localState,
                appMasterListError: null,
            }
        }
        case CLEAR_APP_MASTER_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                appMasterExportError: null,
                isAppMasterExportPending: false,
            }
        }
        case SET_APP_MASTER_DETAILS: {
            const { appMasterDetails } = action.payload;
            return {
                ...localState,
                isAppMasterDetailsPending: false,
                appMasterDetails: appMasterDetails,
            }
        }
        case SET_APP_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                appMasterDetails: {},
                isAppMasterDetailsPending: false,
                appMasterDetailsError: errorMessage,
            }
        }
        case CLEAR_APP_MASTER_DETAILS_ERROR: {
            return {
                ...localState,
                appMasterDetailsError: null,
            }
        }
        case CREATE_APP_MASTER_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CLEAR_CREATE_APP_MASTER_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CREATE_APP_MASTER_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CREATE_APP_MASTER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CLEAR_UPDATE_APP_MASTER_ERROR: {
            return {
                ...localState,
                updateAppMasterErrorMessage: null,
                isUpdateAppMasterPending: false,
            };
        }
        case UPDATE_APP_MASTER_PENDING: {
            return {
                ...localState,
                isUpdateAppMasterPending: true,
            };
        }
        case UPDATE_APP_MASTER_SUCCESS: {
            return {
                ...localState,
                isUpdateAppMasterPending: false,
            };
        }
        case UPDATE_APP_MASTER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateAppMasterErrorMessage: errorMessage,
                isUpdateAppMasterPending: false,
            };
        }
        case SET_APP_MASTER_PENDING: {
            return {
                ...localState,
                appMasterListError: null,
                isAppMasterListPending: true,
                // totalRecords: 0,
            }
        }
        case SET_APP_MASTER_DETAILS_PENDING: {
            return {
                ...localState,
                appMasterDetailsError: null,
                isAppMasterDetailsPending: true
            }
        }
        case SET_APP_MASTER_EXPORT_PENDING: {
            return {
                ...localState,
                appMasterExportError: null,
                isAppMasterExportPending: true
            }
        }

        default: {
            return localState;
        }
    }
};