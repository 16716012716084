import { CLEAR_ALL_DOCS_LIST, CLEAR_CREATE_OVER_ALL_DOCS_ERROR, CLEAR_CREATE_OVERALL_DOCS, CLEAR_DELETE_OVERALL_DOCS_ERROR, CLEAR_IS_OVERALL_DOCUMENT_UPDATED, CLEAR_OVER_ALL_DOCS_DELETE_ERROR, CLEAR_OVER_ALL_DOCS_DETAILS, CLEAR_OVER_ALL_DOCS_DETAILS_ERROR, CLEAR_OVER_ALL_DOCS_LIST_ERROR, CLEAR_UPDATE_OVER_ALL_DOCS_ERROR, CLOSE_CREATE_OVER_ALL_DOCS_HEADER_MODAL, CLOSE_CREATE_OVER_ALL_DOCS_MODAL, CLOSE_DELETE_OVERALL_DOCS, CLOSE_UPDATE_OVER_ALL_DOCS_HEADER_MODAL, CLOSE_UPDATE_OVER_ALL_DOCS_MODAL, CREATE_OVER_ALL_DOCS_PENDING, CREATE_OVER_ALL_DOCS_SUCCESS, CREATE_OVER_ALL_VISIONDOCS_ERROR, CREATE_OVERALL_DOCS_TITLE, CREATE_OVERALLDOCS_CONTENT, IS_CREATE_OVER_ALL_DOCS_PENDING, IS_CREATE_OVER_ALL_DOCS_SUCCESS, IS_DELETE_OVER_ALL_DOCS_PENDING, IS_OVERALL_DOCUMENT_UPDATED, IS_UPDATE_OVER_ALL_DOCS_PENDING, IS_UPDATE_OVER_ALL_DOCS_SUCCESS, OPEN_CREATE_OVER_ALL_DOCS_HEADER_MODAL, OPEN_CREATE_OVER_ALL_DOCS_MODAL, OPEN_DELETE_OVERALL_DOCS, OPEN_UPDATE_OVER_ALL_DOCS_HEADER_MODAL, OPEN_UPDATE_OVER_ALL_DOCS_MODAL, OVER_ALL_DOCS_DELETE_ERROR, OVER_ALL_DOCS_DETAILS_ERROR, OVER_ALL_DOCS_DETAILS_PENDING, OVER_ALL_DOCS_DETAILS_SUCCESS, OVER_ALL_DOCS_LIST_ERROR, OVER_ALL_DOCS_LIST_PENDING, OVERALL_DOCS_CREATE_ID, SET_OVER_ALL_DOCS_DETAILS, SET_OVER_ALL_DOCS_LIST, SET_TOGGLE_VIEW, SET_TOGGLE_VIEW_DOCS, UPDATE_OVER_ALL_DOCS_ERROR, UPDATE_OVER_ALL_DOCS_PENDING, UPDATE_OVER_ALL_DOCS_SUCCESS } from "./constants";

const initialState = {
    OverAllDocsList: [],
    isPendingList: false,
    ListErrorMessage: null,
    isCreateOverAllModalOpen: false,
    ErrorMessageCreateDocs: null,
    isPendingDocsCreate: false,
    isCreateEditDocsSuccess: false,
    isPendingDetails: false,
    DocsDetails: null,
    detailsErrorMessage: null,
    isUpdateOverAllModalOpen: false,
    isUpdateEditDocsSuccess: false,
    updateErrorMessage: null,
    isPendingUpdate: false,
    isOpenCreateOverAllDocsModal: false,
    documentContent: null,
    CreateDocsID: null,
    documentTitle: null,
    openUpdateOverAllDocsHeader: false,
    isOpenDeleteOverAllDocsModal: false,
    DeleteErrorMessage: null,
    isPendingOverAllDocsPending: false,
    isDocsUpdated: false
};


export default (localState = initialState, action) => {
    switch (action.type) {
        case IS_OVERALL_DOCUMENT_UPDATED: {
                    return {
                        ...localState,
                        isDocsUpdated:true
                    };
                }
        case CLEAR_IS_OVERALL_DOCUMENT_UPDATED: {
                    return {
                        ...localState,
                        isDocsUpdated:false
                    };
                }
        case CLEAR_CREATE_OVERALL_DOCS: {
            console.log("trigger working");
            return {
                ...localState,
                CreateDocsID: null,
                documentContent: '',
                documentTitle: '',
            }
        }
        case IS_DELETE_OVER_ALL_DOCS_PENDING: {
            return {
                ...localState,
                isPendingOverAllDocsPending: true,
            };
        }
        case CLEAR_OVER_ALL_DOCS_DELETE_ERROR: {
            return {
                ...localState,
                isPendingOverAllDocsPending: false,
                DeleteErrorMessage: null
            };
        }
        case OVERALL_DOCS_CREATE_ID: {
            const { id } = action.payload;
            console.log("id:", id.id);
            return {
                ...localState,
                CreateDocsID: id.id,
            }
        }
        case CREATE_OVERALL_DOCS_TITLE: {
            const { title } = action.payload;
            console.log("title:", title);
            return {
                ...localState,
                documentTitle: title,
            }
        }
        case CREATE_OVERALLDOCS_CONTENT: {
            const { content } = action.payload;
            return {
                ...localState,
                documentContent: content,
            }
        }
        case OPEN_CREATE_OVER_ALL_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                isOpenCreateOverAllDocsModal: true,
            };
        }
        case OPEN_DELETE_OVERALL_DOCS: {
            return {
                ...localState,
                isOpenDeleteOverAllDocsModal: true,
            };
        }
        case CLOSE_DELETE_OVERALL_DOCS: {
            return {
                ...localState,
                isOpenDeleteOverAllDocsModal: false,
            };
        }
        case CLOSE_CREATE_OVER_ALL_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                isOpenCreateOverAllDocsModal: false,
            };
        }
        case IS_CREATE_OVER_ALL_DOCS_PENDING: {
            return {
                ...localState,
                isCreateEditDocsSuccess: false,
            };
        }
        case IS_CREATE_OVER_ALL_DOCS_SUCCESS: {
            return {
                ...localState,
                isCreateEditDocsSuccess: true,
            };
        }
        case CREATE_OVER_ALL_DOCS_SUCCESS: {
            return {
                ...localState,
                isPendingDocsCreate: false,
            };
        }
        case CLEAR_CREATE_OVER_ALL_DOCS_ERROR: {
            return {
                ...localState,
                ErrorMessageCreateDocs: null,
            };
        }
        case CREATE_OVER_ALL_DOCS_PENDING: {
            return {
                ...localState,
                isPendingDocsCreate: true,
            };
        }
        case CREATE_OVER_ALL_VISIONDOCS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                ErrorMessageCreateDocs: errorMessage,
                isPendingDocsCreate: false,
            };
        }
        case OPEN_CREATE_OVER_ALL_DOCS_MODAL: {
            return {
                ...localState,
                isCreateOverAllModalOpen: true,
            };
        }
        case OPEN_UPDATE_OVER_ALL_DOCS_MODAL: {
            return {
                ...localState,
                isUpdateOverAllModalOpen: true,
            };
        }
        case CLOSE_UPDATE_OVER_ALL_DOCS_MODAL: {
            return {
                ...localState,
                isUpdateOverAllModalOpen: false,
            };
        }
        case CLOSE_CREATE_OVER_ALL_DOCS_MODAL: {
            return {
                ...localState,
                isCreateOverAllModalOpen: false,
            };
        }
        case OVER_ALL_DOCS_LIST_PENDING: {
            return {
                ...localState,
                // totalRecords: 0,
                isPendingList: true,
            };
        }
        case CLEAR_OVER_ALL_DOCS_LIST_ERROR: {
            return {
                ...localState,
                ListErrorMessage: null,
            };
        }
        case OVER_ALL_DOCS_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                ListErrorMessage: errorMessage,
                isPendingList: false,
            };
        }
        case SET_OVER_ALL_DOCS_LIST: {
            const { list } = action.payload;
            // console.log("docsList:", docsList);
            return {
                ...localState,
                OverAllDocsList: list && list.value,
                totalRecords: list["@odata.count"],
                isPendingList: false
            }
        }
        case CLEAR_ALL_DOCS_LIST: {
            return {
                ...localState,
                OverAllDocsList: [],
                totalRecords: 0,
            }
        }
        case SET_TOGGLE_VIEW: {
            const { toggleView } = action.payload;
            // console.log("docsList:", docsList);
            return {
                ...localState,
                toggleViewBool: toggleView
            }
        }
        case SET_TOGGLE_VIEW_DOCS: {
            const { toggleView } = action.payload;
            return {
                ...localState,
                toggleViewBool: toggleView
            }
        }
        case OVER_ALL_DOCS_DETAILS_PENDING: {
            return {
                ...localState,
                isPendingDetails: true,
            };
        }
        case OVER_ALL_DOCS_DETAILS_SUCCESS: {
            return {
                ...localState,
                isPendingDetails: false,
            };
        }
        case SET_OVER_ALL_DOCS_DETAILS: {
            const { details } = action.payload;
            // console.log("docsList:", docsList);
            return {
                ...localState,
                DocsDetails: details,
            }
        }
        case CLEAR_OVER_ALL_DOCS_DETAILS_ERROR: {
            return {
                ...localState,
                detailsErrorMessage: null,
            };
        }
        case OVER_ALL_DOCS_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                detailsErrorMessage: errorMessage,
                isPendingDetails: false,
            };
        }
        case UPDATE_OVER_ALL_DOCS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateErrorMessage: errorMessage,
                isPendingUpdate: false,
            };
        }
        case OVER_ALL_DOCS_DELETE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                DeleteErrorMessage: errorMessage,
            };
        }
        case CLEAR_DELETE_OVERALL_DOCS_ERROR: {
            return {
                ...localState,
                DeleteErrorMessage: null,
            };
        }
        case UPDATE_OVER_ALL_DOCS_PENDING: {
            return {
                ...localState,
                isPendingUpdate: true,
            };
        }
        case UPDATE_OVER_ALL_DOCS_SUCCESS: {
            return {
                ...localState,
                isPendingUpdate: false,
            };
        }
        case CLEAR_UPDATE_OVER_ALL_DOCS_ERROR: {
            return {
                ...localState,
                updateErrorMessage: null,
            };
        }
        case IS_UPDATE_OVER_ALL_DOCS_SUCCESS: {
            return {
                ...localState,
                isUpdateEditDocsSuccess: true,
            };
        }
        case IS_UPDATE_OVER_ALL_DOCS_PENDING: {
            return {
                ...localState,
                isUpdateEditDocsSuccess: false,
            };
        }
        case CLEAR_OVER_ALL_DOCS_DETAILS: {
            return {
                ...localState,
                DocsDetails: null,
            };
        }
        case OPEN_UPDATE_OVER_ALL_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                openUpdateOverAllDocsHeader: true,
            };
        }
        case CLOSE_UPDATE_OVER_ALL_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                openUpdateOverAllDocsHeader: false,
            };
        }
        default: {
            return localState;
        }
    }
};
