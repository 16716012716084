import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import Async from 'react-select/async';
import Select from 'react-select';
import VWButton from "../../../../components/vwButton/VWButton";
import { clearCreateTaskError, ClearMilestoneDate, closeMilestoneCreate, CreateTaskAction, createTaskSuccess, getAssignedUserAsyncSelect, getDependenciesAsyncSelect, getMilestoneDate, getMilestoneSelectAction, getProjectIDlist, getProjectIDValidationlist, getProjectNameAsyncSelect, getTaskScopeAsyncSelectAction, getTaskStatusAsyncMilestoneSelectAction, getTaskStatusAsyncSelectAction, getTaskStatusAsyncUserSelectAction, getTaskTypeAsyncSelectAction, openMilestoneCreate } from "../../../../store/Modules/ProjectPortal/Task/actions";
import { connect } from "react-redux";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { Link } from "react-router-dom";
import VWModel from "../../../../components/Modal/VWModal";
import CreateMilestones from "../Milestones/CreateMilestones";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClearMilestoneName } from "../../../../store/Modules/ProjectPortal/Milestones/actions";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "./TaskEditor.css"

const taskTypeOption = [
    { label: "Task", value: "Task" },
    { label: "Bug", value: "Bug" },
    { label: "Discussion", value: "Discussion" },
]
const priorityOption = [
    { label: "Critical", value: "Critical" },
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
    { label: "Lowest", value: "Lowest" },
]
const effortOption = [
    { label: "XS", value: "XS" },
    { label: "S", value: "S" },
    { label: "M", value: "M" },
    { label: "L", value: "L" },
    { label: "XL", value: "XL" },
    { label: "XXL", value: "XXL" },
]
const statusOption = [
    { label: "New", value: "New" },
    { label: "InProgress", value: "InProgress" },
    { label: "OnHold", value: "OnHold" },
    { label: "Internal Testing", value: "Internal Testing" },
    { label: "Internal Testing Done", value: "Internal Testing Done" },
    { label: "Waiting for Approval", value: "Waiting for Approval" },
    { label: "Done", value: "Done" },
]

const scopeOption = [
    { label: "API", value: "API" },
    { label: "Console App", value: "Console App" },
    { label: "Desktop App", value: "Desktop App" },
    // { label: "General", value: "General" },
    { label: "Mobile App", value: "Mobile App" },
    { label: "UI", value: "UI" },
    { label: "Others", value: "Others" },
]

const CreateTask = (props) => {
    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
        container: (provided) => ({
            ...provided,
            minWidth: '150px',
        }),
    };

    const { selectedUsersIds, selectedUsername, selectedIsUserUpdateStatus, selectedIsMilestoneOwnerUpdateStatus, selectedProjectName, selectedMilestoneName, selectedProjectId, selectedMilestoneId, selectedMilestoneEditOption, currentProfile, selectedFlexibleDueDates, selectedLoginUser, selectedLoginEmail, selectedUserStatus, selectedMilestoneOwnerStatus, filteredData, appId, KanbanStartDate, KanbanEndDate, CreatedMilestoneName, kanbanFilterTaskName } = props;
    console.log('props:', props);

    const [formFields, setFormFields] = useState({ projectId: '', IsUserUpdateStatus: false, IsMilestoneOwnerUpdateStatus: false });
    const [existingProjectUser, setExistingProjectUser] = useState([]);
    const [prevAssignedUser, setPrevAssignedUser] = useState([]);
    const [prevReviewerUser, setPrevReviewerUser] = useState([]);
    const [existingMilestone, setExistingMilestone] = useState([]);
    const [newMilestone, setNewMilestone] = useState([]);
    const [newDependencies, setNewDependencies] = useState([]);
    const [errors, setErrors] = useState({});
    const [statusType, setStatusType] = useState([]);
    const [scopeType, setScopeType] = useState([]);
    const [isEdited, setIsEdited] = useState(false);
    const [milestoneError, setMilestoneError] = useState('');
    const [isActive, setisActive] = useState(true);
    const [autoFocus, setAutoFocus] = useState(true);
    const [milestoneStartDate, setMilestoneStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [milestoneEndDate, setMilestoneEndDate] = useState(moment().format("YYYY-MM-DD"));

    const [keyChange, setKeyChange] = useState(1);
    const [keyStatusChange, setKeyStatusChange] = useState(1);
    const [projectId, setProjectId] = useState();
    const [projectName, setProjectName] = useState();
    const [milestonekeyChange, setMilestoneKeyChange] = useState(1);
    const [milestoneId, setMilestoneId] = useState();

    const { displayName, userId, userEmail } = currentProfile;
    const { userAppDetails } = currentProfile || [];

    let isEditable;
    const [isProjectManager, setIsProjectmanager] = useState(false);

    // let endDateMin;
    // let startDateMax;
    const [startDateMax, setstartDateMax] = useState();
    const [endDateMin, setendDateMin] = useState();
    //----------------------------------------- Dependencies dropdown --------------------------------------------
    const handleDependencies = (inputValue, callback) => {
        if (milestoneId) {
            const { getDependenciesAsyncSelect } = props;
            return getDependenciesAsyncSelect(App[0] && App[0].appId, milestoneId, inputValue.length > 0 ? inputValue : '', callback);
        }
        else {
            callback([]);
        }
    }
    //-------------------------------------------------------------------------------------------------------------------
    const handleDependenciesSelect = (value) => {
        if (value === null) {
            setNewDependencies(value);
            setFormFields({
                ...formFields,
                dependencies: null,
            })
        } else {
            setNewDependencies(value);
            setFormFields({
                ...formFields,
                dependencies: value.value,
            })
        }
    }
    // useEffect(() => {
    //     const StartDateMax = formFields.endDate ? moment(formFields.endDate).format('YYYY-MM-DD') : milestoneEndDate && moment(milestoneEndDate).format('YYYY-MM-DD');
    //     const EndDateMin = formFields.startDate ? moment(formFields.startDate).format('YYYY-MM-DD') : milestoneStartDate && moment(milestoneStartDate).format('YYYY-MM-DD');
    //     setstartDateMax(StartDateMax);
    //     setendDateMin(EndDateMin);
    // }, [])
    //---------------------------------------- Status Type --------------------------------------------------------
    useEffect(() => {

        // setPrevAssignedUser({ label: `${displayName} | ${userEmail}`, value: userId });
        props.clearCreateTaskError();
        props.ClearMilestoneDate();
        props.createTaskSuccess();
        setFormFields({ ...formFields, allowUserToEditStatus: isActive, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
        if (props.fixedStatus) {
            setStatusType({ label: props.fixedStatus, value: props.fixedStatus });
            setFormFields({ ...formFields, taskStatus: props.fixedStatus });
        }
        return (() => {
            props.ClearMilestoneDate();
            props.ClearMilestoneName();
        })
    }, [props.fixedStatus])

    useEffect(() => {
        if (selectedProjectName && selectedProjectId && selectedMilestoneName && selectedMilestoneId) {
            setMilestoneId(selectedMilestoneId);
            setProjectId(selectedProjectId);
            setProjectName(selectedProjectName);
            setKeyChange(keyChange + 1);
            setMilestoneKeyChange(milestonekeyChange + 1);
            console.log('selectedProjectId: ', selectedProjectId)
            const mileStoneOption = { label: selectedMilestoneName, value: selectedMilestoneId };
            setFormFields({ ...formFields, projectId: selectedProjectId, projectName: selectedProjectName, mileStoneId: selectedMilestoneId, allowUserToEditStatus: isActive, AllowFlexibleDueDates: selectedFlexibleDueDates, IsUserUpdateStatus: selectedUserStatus, IsMilestoneOwnerUpdateStatus: selectedMilestoneOwnerStatus, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
            setNewMilestone(mileStoneOption)
            props.getMilestoneDate(selectedMilestoneId);
            console.log("formFields: ", formFields);
            setKeyStatusChange(keyStatusChange + 1);
        } else if (selectedProjectName || selectedProjectId || selectedUsername || selectedUsersIds) {
            console.log('Kanban data');
            setProjectId(selectedProjectId);
            setProjectName(selectedProjectName);
            setKeyChange(keyChange + 1);
            setMilestoneKeyChange(milestonekeyChange + 1);
            setStatusType({ label: props.fixedStatus || 'Select status', value: props.fixedStatus });
            setFormFields({ ...formFields, taskStatus: props.fixedStatus, projectId: selectedProjectId, projectName: selectedProjectName, allowUserToEditStatus: isActive, AllowFlexibleDueDates: selectedFlexibleDueDates, IsUserUpdateStatus: selectedUserStatus, IsMilestoneOwnerUpdateStatus: selectedMilestoneOwnerStatus, startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") });
        }
        if (selectedMilestoneEditOption) {
            setIsEdited(selectedMilestoneEditOption);
        }
    }, [selectedUsersIds || selectedUsername || selectedProjectName || selectedProjectId || selectedMilestoneName || selectedMilestoneId || selectedMilestoneEditOption || selectedIsUserUpdateStatus || selectedIsMilestoneOwnerUpdateStatus])

    useEffect(() => {
        if (formFields && formFields.IsUserUpdateStatus || formFields && formFields.IsMilestoneOwnerUpdateStatus) {
            setKeyStatusChange(keyStatusChange + 1);
        }
    }, [(formFields && formFields.IsUserUpdateStatus) || (formFields && formFields.IsMilestoneOwnerUpdateStatus)])
    //-------------------------------------------Default Options------------------------------------------------------
    const handleProject = (inputValue, callback) => {
        const { getProjectNameAsyncSelectAction } = props;
        return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskScope = (inputValue, callback) => {
        const { getTaskScopeAsyncSelectAction } = props;
        return getTaskScopeAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskStatusPercentage = (inputValue, callback) => {
        if (milestoneId || selectedMilestoneId) {
            const { getTaskStatusAsyncSelectAction } = props;
            return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
        } else {
            callback([]);
        }
    };
    const handleTaskUserStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncUserSelectAction } = props;
        return getTaskStatusAsyncUserSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskMilestoneStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncMilestoneSelect } = props;
        return getTaskStatusAsyncMilestoneSelect(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleSelectTaskType = (inputValue, callback) => {
        const { getTaskTypeAsyncSelectAction } = props;
        return getTaskTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleMilestone = (inputValue, callback) => {
        if (projectId) {
            const { getMilestoneSelectAction } = props;
            return getMilestoneSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
        }
        else {
            callback([]);
        }
    };
    const handleAssignUser = (inputValue, callback) => {
        if (projectId) {
            const { getAssignedUserAsyncSelectAction } = props;
            getAssignedUserAsyncSelectAction(projectId, inputValue.length > 0 ? inputValue : '', (options) => {
                const userIsInOptions = options.some(user => user.value === userId);
                // if (userIsInOptions) {
                //     const defaultUser = options.find(user => user.value === userId); // if login user present assign default user.
                //     setPrevAssignedUser(defaultUser);
                // } else {
                setPrevAssignedUser(null); // if login user didn't present set there is no default options.
                setFormFields({ ...formFields, assignedUserId: null });
                // }
                callback(options);
            });
        }
        else {
            callback([]);
        }
    };
    const handleAssignReviewer = (inputValue, callback) => {
        if (projectId) {
            const { getAssignedUserAsyncSelectAction } = props;
            getAssignedUserAsyncSelectAction(projectId, inputValue.length > 0 ? inputValue : '', (options) => {
                callback(options);
            });
        }
        else {
            callback([]);
        }
    };

    const handleProjectName = (data) => {
        const { displayName, userId, userEmail } = currentProfile;

        setIsProjectmanager((data && data.ProjectManagerUserId) === userId);
        console.log("data:", data);
        // setPrevAssignedUser({ label: `${displayName} | ${userEmail}`, value: userId });
        setKeyChange(keyChange + 1);
        setNewMilestone({ label: 'Select milestone name', value: '' });
        setNewDependencies({ label: 'Select dependencies', value: '' });
        setStatusType([]);
        setKeyStatusChange(keyStatusChange + 1);
        setIsEdited(false);
        setMilestoneError('');
        if (data.value) {
            setFormFields({ ...formFields, taskStatus: null, mileStoneId: '', ReviewerUserId: '', dependencies: '', projectId: data.value, projectName: data.label, AllowFlexibleDueDates: data.AllowFlexibleDueDates || false, assignedUserId: null })
            setProjectId(data.value);
            setProjectName(data.label);
        }
        setErrors({ ...errors, projectId: '', assignedUserId: '', startDate: '', endDate: '', mileStoneId: '', endMilestoneDate: false, startMilestoneDate: false });
        // startDateMax = null;
        // endDateMin = null;
        setstartDateMax(null);
        setendDateMin(null);
        props.getProjectIDValidation(data.value)
            .then(() => {
                setErrors({ ...errors, projectId: '', startDate: '', endDate: '', mileStoneId: '', endMilestoneDate: false, startMilestoneDate: false });
            })
            .catch((error) => {
                console.error("Error checking new user:", error);
                setErrors({
                    ...errors,
                    projectId: error || 'Failed to fetch user data. Please try again.',
                    startDate: '', endDate: '', mileStoneId: '', endMilestoneDate: false, startMilestoneDate: false
                });
            })
    }
    //--------------------------------------------Form Editor Description-------------------------------------------
    const handleEditorChange = (content) => {
        // setFormFields({ ...formFields, "contentText": content });
        setFormFields(prevFields => ({ ...prevFields, description: content }));
    };
    //-----------------------------------------Add Date field---------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        const formattedDate = value;
        setFormFields({ ...formFields, [name]: formattedDate });
        const startDate = moment(props.MilestoneDate && props.MilestoneDate.startDate).format('YYYY-MM-DD');
        const endDate = moment(props.MilestoneDate && props.MilestoneDate.endDate).format('YYYY-MM-DD');

        if (name === 'startDate') {
            const isStartValid = moment(value || formFields.startDate).isBetween(startDate, endDate, null, '[]');
            if (isStartValid || formFields.AllowFlexibleDueDates) {
                setErrors({ ...errors, startMilestoneDate: false, startDate: '' });
            } else {
                setErrors({ ...errors, startMilestoneDate: true, startDate: '' });
            }
            setMilestoneStartDate(value);
        }
        if (name === 'endDate') {
            const isEndValid = moment(value || formFields.endDate).isBetween(startDate, endDate, null, '[]');
            if (isEndValid || formFields.AllowFlexibleDueDates) {
                setErrors({ ...errors, endMilestoneDate: false, endDate: '' });
            } else {
                setErrors({ ...errors, endMilestoneDate: true, endDate: '' });
            }
            setMilestoneEndDate(value);
        }
    };
    //----------------------------------------Add Input field---------------------------------------------------------
    const maxLength = 150;
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'taskName') {
            if (value && value.length >= maxLength) {
                setErrors({ ...errors, taskName: `TaskName name cannot exceed ${maxLength} characters.` });
            } else {
                setErrors({ ...errors, taskName: '' });
            }
        }
    };
    //------------------------------------- Assigned User---------------------------------------------------------------
    const handleAddProjectUser = (value) => {
        if (value === null) {
            setFormFields({ ...formFields, assignedUserId: null });
            setPrevAssignedUser(value);
        } else {
            setFormFields({ ...formFields, assignedUserId: value.value });
            setPrevAssignedUser(value);
            setErrors({ ...errors, assignedUserId: '' });
        }
    }
    const handleAddReviewerUser = (value) => {
        if (value === null) {
            setPrevReviewerUser(value);
            setFormFields({ ...formFields, ReviewerUserId: null });
        } else {
            setPrevReviewerUser(value);
            setFormFields({ ...formFields, ReviewerUserId: value.value });
        }
    }
    //------------------------------------- Add Selected Dropdown fields ----------------------------------------------
    const handleMilestoneId = (Value) => {
        console.log("Value:", Value);
        const { MilestoneError, isCurrentUserManagerOrMilestoneOwner, label, value, IsUserUpdateStatus, IsMilestoneOwnerUpdateStatus } = Value || {};
        setFormFields({
            ...formFields, mileStoneId: value, dependencies: '',
            AllowFlexibleDueDates: Value.AllowFlexibleDueDates,
            IsUserUpdateStatus: IsUserUpdateStatus,
            IsMilestoneOwnerUpdateStatus: IsMilestoneOwnerUpdateStatus,
            taskStatus: null,
        });
        setNewMilestone(Value);
        setNewDependencies('Select dependencies');
        setErrors({ ...errors, mileStoneId: '', startDate: '', endDate: '' });
        props.getMilestoneDate(value);
        setIsEdited(isCurrentUserManagerOrMilestoneOwner);
        setMilestoneError(MilestoneError);
        setMilestoneKeyChange(milestonekeyChange + 1);
        setMilestoneId(value);
        setKeyStatusChange(keyStatusChange + 1);
        setStatusType([]);
    }
    const handleEffort = (value) => {
        if (value === null) {
            setFormFields({ ...formFields, effort: null })
        } else {
            setFormFields({ ...formFields, effort: value.value })
        }
    }
    const handleTaskStatus = (options) => {
        const { value, Percentage } = options;
        setFormFields({ ...formFields, taskStatus: value, currentStatusPercentage: Percentage });
        setStatusType(options);
        setErrors({ ...errors, taskStatus: '' });
    }
    const handleScope = (value) => {
        setFormFields({ ...formFields, taskScopeId: value.value });
        setScopeType(value);
        setErrors({ ...errors, taskScopeId: '' });
    }
    const handlePriority = (value) => {
        if (value === null) {
            setFormFields({ ...formFields, priority: null });
        } else {
            setFormFields({ ...formFields, priority: value.value });
        }
    }
    const handleTaskType = (value) => {
        setFormFields({ ...formFields, taskTypeId: value.value })
        setErrors({ ...errors, taskTypeId: '' });
    }

    //----------------------------------------------------------------------------------------------------------------

    const createMilestoneOpen = () => {
        props.openMilestoneCreate();
    }

    const handleClose = () => {
        props.closeMilestoneCreate();
    };

    const handleDone = (getProjId) => {
        if (getProjId === formFields && formFields.projectId) {
            props.getAssignedUserAsyncSelectAction(getProjId, '', (existingProjectUsers) => {
                console.log("existingProjectUsers:", existingProjectUsers);

                setExistingProjectUser(existingProjectUsers);
            });
            props.getMilestoneSelectAction(getProjId, '', (existingMilestone) => {
                setExistingMilestone(existingMilestone)
                console.log("existingMilestone:", existingMilestone);
            })
        }
    };

    const onRefresh = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "lightseagreen"
        if (formFields && formFields.projectId) {
            console.log("working");

            props.getMilestoneSelectAction(formFields && formFields.projectId, '', (existingMilestone) => {
                setExistingMilestone(existingMilestone);
            });
            props.getAssignedUserAsyncSelectAction(formFields && formFields.projectId, '', (existingProjectUsers) => {
                setExistingProjectUser(existingProjectUsers);
            });
        }
        setKeyChange(keyChange + 1)
    }

    const onRefreshColor = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "grey"
    }

    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleCreateTask();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //---------------------------------------Create Task------------------------------------------------------------------
    const handleCreateTask = () => {
        const StartDates = moment(props.MilestoneDate && props.MilestoneDate.startDate).format('YYYY-MM-DD');
        const EndDates = moment(props.MilestoneDate && props.MilestoneDate.endDate).format('YYYY-MM-DD');
        const startDate = moment(formFields.startDate);
        const endDate = moment(formFields.endDate);
        const projectStart = moment(milestoneStartDate);
        const projectEnd = moment(milestoneEndDate);
        const newErrors = {};
        if (errors && errors.projectId) {
            newErrors.projectId = errors && errors.projectId;
        }
        if (!formFields.projectId || !formFields.projectId.trim()) {
            newErrors.projectId = 'Project name is required';
        }
        if (!formFields.taskScopeId || !formFields.taskScopeId.trim()) {
            newErrors.taskScopeId = 'Scope is required';
        }
        if (!formFields.mileStoneId || !formFields.mileStoneId.trim()) {
            newErrors.mileStoneId = 'Milestone name is required';
        }
        if (!formFields.taskName || !formFields.taskName.trim()) {
            newErrors.taskName = 'Task name is required';
        }
        if (formFields.taskName && formFields.taskName.length >= maxLength) {
            newErrors.taskName = `TaskName name cannot exceed ${maxLength} characters.`;
        }
        if (!formFields.taskStatus || !formFields.taskStatus.trim()) {
            newErrors.taskStatus = 'Task status is required';
        }
        if (!formFields.taskTypeId || !formFields.taskTypeId.trim()) {
            newErrors.taskTypeId = 'Task type is required';
        }
        if (!formFields.startDate || !formFields.startDate.trim()) {
            newErrors.startDate = 'Start date is required';
        }
        if (!formFields.endDate || !formFields.endDate.trim()) {
            newErrors.endDate = 'End date is required';
        }
        if (formFields && !formFields.AllowFlexibleDueDates) {
            if (startDate.isBefore(projectStart) || startDate.isAfter(projectEnd)) {
                newErrors.startDate = `Start date must be between ${projectStart.format('DD/MM/YYYY')} and ${projectEnd.format('DD/MM/YYYY')}`;
            }
            if (endDate.isBefore(startDate) || endDate.isAfter(projectEnd)) {
                newErrors.endDate = `End date must be after start date and before ${projectEnd.format('DD/MM/YYYY')}`;
            }
            const isStartValid = moment(formFields.startDate).isBetween(StartDates, EndDates, null, '[]');
            if (!isStartValid) {
                newErrors.startMilestoneDate = true
            }

            const isEndValid = moment(formFields.endDate).isBetween(StartDates, EndDates, null, '[]');
            if (!isEndValid) {
                newErrors.endMilestoneDate = true
            }

            console.log('formFields.startDate:', formFields.startDate);
            console.log('startDate:', StartDates);
            console.log('endDate:', EndDates);
            console.log('isStartValid;', isStartValid);
            console.log('isEndValid:', isEndValid);
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.CreateTaskAction(App ? App[0] && App[0].appId : '' || appId, formFields, props.kanbanFilterProjectId, props.kanbanFilterUserIds, selectedMilestoneId, filteredData, KanbanStartDate, KanbanEndDate, kanbanFilterTaskName);
        }
    }

    //-------------------------------------Status Change----------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, allowUserToEditStatus: !isActive })
    };

    useEffect(() => {

        if (props.MilestoneDate) {
            // setKeyStatusChange(keyStatusChange + 1);
            const startDate = moment(props.MilestoneDate && props.MilestoneDate.startDate).format('YYYY-MM-DD');
            const endDate = moment(props.MilestoneDate && props.MilestoneDate.endDate).format('YYYY-MM-DD');

            // if (!(formFields && formFields.AllowFlexibleDueDates)) {
            const isStartValid = moment(formFields.startDate || moment().format("YYYY-MM-DD")).isBetween(startDate, endDate, null, '[]');
            const isEndValid = moment(formFields.endDate || moment().format("YYYY-MM-DD")).isBetween(startDate, endDate, null, '[]');
            console.log('isStartValid:', isStartValid);
            console.log('isEndValid:', isEndValid);
            console.log('formfields:', formFields);

            // if (((formFields.startDate >= startDate) && (formFields.endDate <= endDate))) {
            if (isStartValid && isEndValid) {
                setMilestoneStartDate(startDate);
                setMilestoneEndDate(endDate);
                setErrors({ startDate: '', endDate: '' });
            } else {
                setErrors({
                    endMilestoneDate: true,
                    startMilestoneDate: true,
                });
            }
            // }
            if (formFields && formFields.AllowFlexibleDueDates) {
                setMilestoneStartDate(formFields.startDate);
                setMilestoneEndDate(formFields.endDate);
                setErrors({
                    endMilestoneDate: false,
                    startMilestoneDate: false,
                })
            } else {
                setstartDateMax(endDate);
                setendDateMin(startDate);
            }
        }
    }, [props.MilestoneDate]);


    const projectPortalApps = userAppDetails?.filter(item =>
        item?.appName?.toLowerCase() === "projectportal"
    ) || [];

    const isProjectAdmin = projectPortalApps.some(userApp => {
        const adminRoles = userApp?.appRoleDetails?.filter(role => role.roleName) || [];
        return adminRoles.some(role => role.roleName.toLowerCase() === "administrator");
    });

    useEffect(() => {
        if (CreatedMilestoneName) {
            const mileStoneOption = { label: CreatedMilestoneName.MileStoneName, value: CreatedMilestoneName.Id };
            setNewMilestone(mileStoneOption);
            setFormFields({
                ...formFields, mileStoneId: CreatedMilestoneName.Id
            });
            props.getMilestoneDate(CreatedMilestoneName.Id);
            setKeyStatusChange(keyStatusChange + 1);
            setKeyChange(keyChange + 1);
            setMilestoneId(CreatedMilestoneName.Id);
        }
    }, [CreatedMilestoneName])

    console.log('errors:', errors);
    console.log('formfields:', formFields);
    console.log('CreatedMilestoneName:', CreatedMilestoneName);
    console.log('formFields.mileStoneId:', formFields.mileStoneId);
    console.log('keyStatusChange:', keyStatusChange);


    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <Row>
                <Col md={6}>{console.log("projectName: ", projectName)}
                    <FormGroup>
                        <Label>
                            Project Name<span className="text-danger"> *</span>
                        </Label>
                        <Async
                            openMenuOnFocus={true}
                            autoFocus={true}
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.projectId) ? 'red' : "silver"
                                })
                            }}
                            name="ProjectId"
                            placeholder="Select project name"
                            isSearchable
                            defaultOptions
                            loadOptions={handleProject}
                            onChange={handleProjectName}
                            value={projectId ? { label: projectName, value: projectId } : null}
                            isDisabled={selectedProjectId}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.projectId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="d-flex justify-content-between">
                            <div>
                                Milestone Name<span className="text-danger"> *</span>
                            </div>
                            {(isProjectManager || isProjectAdmin) && (formFields && formFields.projectId) && (!selectedMilestoneId) &&
                                <div>
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        id="icon"
                                        onClick={onRefresh}
                                        onMouseLeave={onRefreshColor}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <Link onClick={createMilestoneOpen}>&nbsp;
                                        Create Milestone
                                    </Link>
                                </div>
                            }
                        </Label>
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.mileStoneId) ? 'red' : "silver"
                                })
                            }}
                            key={keyChange}
                            name="mileStoneId"
                            placeholder="Select milestone name"
                            isSearchable
                            defaultOptions
                            loadOptions={handleMilestone}
                            onChange={handleMilestoneId}
                            value={newMilestone}
                            isDisabled={selectedMilestoneId || !(formFields && formFields.projectId)}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.mileStoneId}
                                {formFields.mileStoneId && ((errors && errors.startMilestoneDate) || (errors && errors.endMilestoneDate)) && `The current date is not within the range between the milestone start date: ${moment(props.MilestoneDate && props.MilestoneDate.startDate).format('DD-MM-YYYY')} and the end date: ${moment(props.MilestoneDate && props.MilestoneDate.endDate).format('DD-MM-YYYY')} . Please enable flexible due date or change date.`}
                            </div>
                        </FormText>
                        {!isEdited ?
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {milestoneError}
                                </div>
                            </FormText>
                            : ''}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Task Name <span className="text-danger"> *</span>
                        </Label>
                        <Input
                            onFocus={handleFocus}
                            type="text"
                            name="taskName"
                            placeholder="Enter task name"
                            onChange={validateOnChange}
                            value={formFields && formFields.taskName}
                            // disabled={!isEdited}
                            invalid={errors && errors.taskName}
                            maxLength={maxLength}
                        />
                        <FormFeedback>{errors && errors.taskName}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Task Type <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.taskTypeId) ? 'red' : "silver"
                                })
                            }}
                            name="taskTypeId"
                            placeholder="Select task type"
                            isSearchable
                            defaultOptions
                            loadOptions={handleSelectTaskType}
                            onChange={handleTaskType}
                        // isDisabled={!isEdited}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.taskTypeId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Assigned To
                        </Label>
                        <Async
                            key={keyChange}
                            name="assignedUserId"
                            placeholder="Select assigned user"
                            isSearchable
                            defaultOptions
                            loadOptions={handleAssignUser}
                            onChange={handleAddProjectUser}
                            value={prevAssignedUser}
                            isDisabled={!(formFields && formFields.projectId)}
                            isClearable
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Planned Hours </Label>
                        <Input
                            onFocus={handleFocus}
                            type="number"
                            name="plannedHours"
                            placeholder="Enter planned hours"
                            onChange={validateOnChange}
                            value={formFields && formFields.Description}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row><Col md={6}>
                <FormGroup>
                    <Label>Start Date
                        <span className="text-danger"> *</span>
                    </Label>
                    <Input
                        onFocus={handleFocus}
                        type="date"
                        name="startDate"
                        placeholder="Select start date"
                        onChange={validateOnChangeDate}
                        // min={!(formFields && formFields.AllowFlexibleDueDates) && (milestoneStartDate ? moment(milestoneStartDate).format('YYYY-MM-DD') : '')}
                        // min={startDateMax}
                        min={endDateMin}
                        max={milestoneEndDate}
                        // min={!(formFields && formFields.AllowFlexibleDueDates) && moment(milestoneStartDate).format('YYYY-MM-DD')}
                        // max={!(formFields && formFields.AllowFlexibleDueDates) ? startDateMax : formFields.endDate}
                        value={moment(formFields.startDate).format("YYYY-MM-DD")}
                        invalid={errors && errors.startDate}
                    />
                    <FormFeedback>{errors && errors.startDate}</FormFeedback>
                </FormGroup>
            </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>End Date <span className="text-danger"> *</span> </Label>
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="endDate"
                            placeholder="Select end date"
                            onChange={validateOnChangeDate}
                            // min={!(formFields && formFields.AllowFlexibleDueDates) ? endDateMin : formFields.startDate}
                            // max={!(formFields && formFields.AllowFlexibleDueDates) && (milestoneEndDate ? moment(milestoneEndDate).format('YYYY-MM-DD') : '')}
                            // min={milestoneEndDate}
                            min={milestoneStartDate}
                            // max={endDateMin}
                            max={startDateMax}
                            value={moment(formFields.endDate).format("YYYY-MM-DD")}
                            invalid={errors && errors.endDate}
                        />
                        <FormFeedback>{errors && errors.endDate}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Completed Date</Label>
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="completedDate"
                            placeholder="Select completed date"
                            onChange={validateOnChangeDate}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Priority
                        </Label>
                        <Select
                            name="priority"
                            placeholder="Select priority"
                            isSearchable
                            defaultOptions
                            options={priorityOption}
                            onChange={handlePriority}
                            isClearable
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Effort
                        </Label>
                        <Select
                            styles={customStyles}
                            name="effort"
                            placeholder="Select effort"
                            isSearchable
                            defaultOptions
                            options={effortOption}
                            onChange={handleEffort}
                            isClearable
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Task Status  <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            key={keyStatusChange}
                            styles={{
                                ...customStyles,
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.taskStatus) ? 'red' : "silver"
                                })
                            }}
                            name="taskStatus"
                            placeholder="Select status"
                            isSearchable
                            defaultOptions
                            loadOptions={formFields.mileStoneId ?
                                formFields && formFields.IsMilestoneOwnerUpdateStatus
                                    ? handleTaskMilestoneStatusPercentage
                                    : formFields && formFields.IsUserUpdateStatus
                                        ? handleTaskUserStatusPercentage
                                        : handleTaskStatusPercentage
                                : ''}
                            onChange={handleTaskStatus}
                            value={statusType}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.taskStatus}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>


            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Scope  <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            styles={{
                                ...customStyles,
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.taskScopeId) ? 'red' : "silver"
                                })
                            }}
                            name="taskScopeId"
                            placeholder="Select scope"
                            isSearchable
                            defaultOptions
                            loadOptions={handleTaskScope}
                            onChange={handleScope}
                            value={scopeType}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.taskScopeId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Reviewer</Label>
                        <Async
                            styles={customStyles}
                            key={keyChange}
                            name="ReviewerUserId"
                            defaultOptions
                            placeholder="Select reviewer"
                            loadOptions={handleAssignReviewer}
                            onChange={handleAddReviewerUser}
                            isDisabled={!(formFields && formFields.projectId)}
                            value={prevReviewerUser}
                            isClearable
                        />
                    </FormGroup>
                </Col >
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Dependencies (Depends On)</Label>
                        <Async
                            styles={customStyles}
                            key={milestonekeyChange}
                            name="dependencies"
                            defaultOptions
                            placeholder="Select dependencies"
                            loadOptions={handleDependencies}
                            onChange={handleDependenciesSelect}
                            value={newDependencies}
                            isDisabled={!(formFields && formFields.mileStoneId)}
                            isClearable
                        />
                    </FormGroup>
                </Col >
                <Col md={6}>
                    <FormGroup>
                        <Label>Allow User To Edit Status</Label>
                        <ul className="form-unstyled-list form-status-list">
                            <li
                                onClick={handleActiveStatusChange}
                                className={`form-status-item ${!isActive ? 'active' : ''}`}
                            >
                                No
                            </li>
                            <li
                                onClick={handleActiveStatusChange}
                                className={`form-status-item ${isActive ? 'active' : ''}`}
                            >
                                Yes
                            </li>
                        </ul>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col >
                    <FormGroup>
                        <Label>
                            Task Description
                        </Label>
                        <div className="quill-container-task">
                            <ReactQuill
                                value={formFields && formFields.description}
                                onChange={handleEditorChange}
                                theme="snow"
                                placeholder="Start From Here..."
                                // readOnly={!isEdited}
                                modules={{
                                    toolbar: [
                                        ['bold', 'italic', 'underline', 'strike', 'image'],        // toggled buttons
                                        ['clean']
                                    ],
                                }}
                            />
                        </div>
                    </FormGroup>
                </Col>

            </Row>
            <br></br>
            <FormGroup>
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateTask}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
                <VWModel
                    header="CREATE MILESTONE"
                    isOpen={props.isCreateMilestoneModalOpen}
                    handleClose={handleClose}
                >
                    <CreateMilestones projId={formFields && formFields.projectId} ProjName={formFields && formFields.projectName} handleDone={handleDone} selectedFlexibleDueDates={formFields && formFields.AllowFlexibleDueDates}
                        handleClose={handleClose}
                    />
                </VWModel>
            </FormGroup>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.task.isPending,
    errorMessage: state.task.errorMessage,
    MilestoneDate: state.task.MilestoneDate,
    MilestoneTaskDateError: state.task.MilestoneTaskDateError,
    isMilestoneTaskDatePending: state.task.isMilestoneTaskDatePending,
    isCreateMilestoneModalOpen: state.task.isCreateMilestoneModalOpen,
    currentProfile: state.login.currentProfile,
    CreatedMilestoneName: state.Milestones.CreatedMilestoneName
});
const mapDispatchToProps = (dispatch) => ({
    getTaskStatusAsyncMilestoneSelect: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncMilestoneSelectAction(appId, searchValue, callback)),
    getTaskStatusAsyncUserSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncUserSelectAction(appId, searchValue, callback)),
    getTaskTypeAsyncSelectAction: (searchValue, callback) => dispatch(getTaskTypeAsyncSelectAction(searchValue, callback)),
    getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeAsyncSelectAction(appId, searchValue, callback)),
    getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),
    getProjectNameAsyncSelectAction: (searchValue, callback) => dispatch(getProjectNameAsyncSelect(searchValue, callback)),
    getDependenciesAsyncSelect: (appId, milestoneId, searchValue, callback) => dispatch(getDependenciesAsyncSelect(appId, milestoneId, searchValue, callback)),
    getAssignedUserAsyncSelectAction: (projectId, searchValue, callback) => dispatch(getAssignedUserAsyncSelect(projectId, searchValue, callback)),
    getMilestoneSelectAction: (projectId, searchValue, callback) => dispatch(getMilestoneSelectAction(projectId, searchValue, callback)),
    CreateTaskAction: (appId, formFields, kanbanFilterProjectId, kanbanFilterUserIds, selectedMilestoneId, filteredData, KanbanStartDate, KanbanEndDate, kanbanFilterTaskName) => dispatch(CreateTaskAction(appId, formFields, kanbanFilterProjectId, kanbanFilterUserIds, selectedMilestoneId, filteredData, KanbanStartDate, KanbanEndDate, kanbanFilterTaskName)),
    openMilestoneCreate: () => dispatch(openMilestoneCreate()),
    closeMilestoneCreate: () => dispatch(closeMilestoneCreate()),
    createTaskSuccess: () => dispatch(createTaskSuccess()),
    getMilestoneDate: (id) => dispatch(getMilestoneDate(id)),
    ClearMilestoneDate: () => dispatch(ClearMilestoneDate()),
    clearCreateTaskError: () => dispatch(clearCreateTaskError()),
    ClearMilestoneName: () => dispatch(ClearMilestoneName()),
    getProjectIDValidation: (projectId) => dispatch(getProjectIDValidationlist(projectId)),


});
export default connect(mapStateToProps, mapDispatchToProps)(CreateTask)