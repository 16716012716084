import React, { useEffect, useState } from 'react'
import Async from 'react-select/async';
import VWMessage from '../../../../../components/vwMessage/vwMessage'
import VWButton from '../../../../../components/vwButton/VWButton'
import { connect } from 'react-redux';
import { getOrgNameAsyncSelect } from '../../../../../store/Modules/Admin/OrgUser/actions';
import { Button, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import { CreateGroups, createGroupSuccess, getAppNameAsyncSelect, getRoleNameAsyncSelect, getUserNameAsyncSelect } from '../../../../../store/Modules/Admin/Group/actions';
import FormValidator from '../../../../../helper/formValidator';

const CreateGroup = (props) => {

    const roles = props.currentProfile && props.currentProfile.roles && props.currentProfile.roles.map((data) => data.roleName)
    const orgName = props.currentProfile && props.currentProfile.orgName;

    const { orgId } = props.currentProfile;
    const [validation, setValidation] = useState({ emailValid: true });
    const [errors, setErrors] = useState({});
    const [existingAppDetails, setExistingAppDetails] = useState([]);
    const [prevAppDetails, setPrevAppDetails] = useState([]);
    const [prevUserDetails, setprevUserDetails] = useState([]);
    const [formFields, setFormFields] = useState({ OrgId: orgId });
    const [groupEmailValid, setgroupEmailValid] = useState(true);
    const [groupOwnerEmailValid, setgroupOwnerEmailValid] = useState(true);
    const [selectedRoles, setSelectedRoles] = useState();
    const [keyChange, setKeyChange] = useState(1);
    const [keyUserChange, setKeyUserChange] = useState(1);
    const [autoFocus, setAutoFocus] = useState(true);
    const { filterData, appId } = props;
    useEffect(() => {
        props.createGroupSuccess();
    }, [])

    const handleOrgName = (inputValue, callback) => {
        const { getOrgNameAsyncSelectAction } = props;
        return getOrgNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback, orgName);
    };
    const handleRole = (inputValue, callback) => {
        const { getRoleNameAsyncSelectAction } = props;
        return getRoleNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback, roles);
    };
    const handleapps = (inputValue, callback) => {
        const { getAppNameAsyncSelectAction } = props;
        if (selectedRoles) {
            return getAppNameAsyncSelectAction(selectedRoles, inputValue.length > 0 ? inputValue : '', callback);
        } else {
            callback([]);
        }
    };
    const handleUser = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        if (selectedRoles) {
            console.log("selectedRoles:", selectedRoles);
            return getUserNameAsyncSelectAction(appId, selectedRoles, inputValue.length > 0 ? inputValue : '', callback);
        } else {
            callback([]);
        }
    };

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        let result = FormValidator.validate(input);
        let emailValid = true;
        if (input.name === 'GroupEmail') {
            let emailValid = true;

            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(value)) {
                result = 'Invalid group email';
                emailValid = false;
            }
            else if (prohibitedChars.test(value)) {
                result = 'Invalid group email';
                emailValid = false;
            }
            else {
                emailValid = true;
            }
            setErrors({
                ...errors,
                GroupEmail: emailValid ? null : result,  // Update errors directly
            });
        }
        if (input.name === 'GroupOwnerEmail') {
            let emailValid = true;

            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(value)) {
                result = 'Invalid group owner email';
                emailValid = false;
            }
            else if (prohibitedChars.test(value)) {
                result = 'Invalid group owner email';
                emailValid = false;
            }
            else {
                emailValid = true;
            }
            setErrors({
                ...errors,
                GroupOwnerEmail: emailValid ? null : result,  // Update errors directly
            });
        }
        setFormFields({
            ...formFields, [input.name]: value, errors: {
                ...(formFields && formFields.errors),
                [input.name]: result,
            },
        });
        if (input.name === 'GroupName') {
            setErrors({ ...errors, GroupName: '' });
        }
    };

    const handleAddOrgName = (value) => {
        setFormFields({ ...formFields, OrgId: value.value });
        setErrors({ ...errors, OrgId: '' });
    }
    const handleAddRole = (value) => {
        setFormFields({
            ...formFields,
            RoleId: value.value,
            AppId: [],
            UserId: []
        });
        setErrors({ ...errors, RoleId: '' });
        setKeyChange(keyChange + 1);
        setKeyUserChange(keyUserChange + 1);
        setSelectedRoles(value && value.label)
        setPrevAppDetails([]);
        setprevUserDetails([]);
    }

    const handleAddApps = (value) => {
        const AppID = value.map((item) => {
            return (
                item.value
            )
        })
        setFormFields({ ...formFields, AppId: AppID });
        setErrors({ ...errors, AppId: '' });
        setPrevAppDetails(value)
    }
    const handleAddUser = (value) => {
        const userID = value.map((item) => {
            return (
                item.value
            )
        })
        setprevUserDetails(value);
        setFormFields({ ...formFields, UserId: userID });
        setErrors({ ...errors, UserId: '' });
    }
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleCreateGroupUser();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //--------------------------------------------Create ---------------------------------------------------------
    const handleCreateGroupUser = () => {
        const newErrors = {};
        if (!(formFields && formFields.OrgId) || !(formFields && formFields.OrgId && formFields.OrgId.trim())) {
            newErrors.OrgId = 'Org name is required';
        }
        if (!(formFields && formFields.GroupName) || !(formFields && formFields.GroupName && formFields.GroupName.trim())) {
            newErrors.GroupName = 'Group name is required';
        }
        if (!(formFields && formFields.GroupEmail) || !(formFields && formFields.GroupEmail && formFields.GroupEmail.trim())) {
            newErrors.GroupEmail = 'Group email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(formFields.GroupEmail)) {
                newErrors.GroupEmail = 'Invalid group email';
            } else if (prohibitedChars.test(formFields.GroupEmail)) {
                newErrors.GroupEmail = 'Invalid group email';
            }
        }
        if (!(formFields && formFields.GroupOwnerEmail) || !(formFields && formFields.GroupOwnerEmail && formFields.GroupOwnerEmail.trim())) {
            newErrors.GroupOwnerEmail = 'Group owner email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(formFields.GroupOwnerEmail)) {
                newErrors.GroupOwnerEmail = 'Invalid group owner email';
            } else if (prohibitedChars.test(formFields.GroupOwnerEmail)) {
                newErrors.GroupOwnerEmail = 'Invalid group owner email';
            }
        }
        if (!(formFields && formFields.RoleId)) {
            newErrors.RoleId = 'Role is required';
        }
        var _ = require('lodash');
        if (!(formFields && formFields.AppId) || _.isEmpty(formFields && formFields.AppId && formFields.AppId)) {
            newErrors.AppId = 'App(s) is required';
        }
        if (!(formFields && formFields.UserId) || _.isEmpty(formFields && formFields.UserId && formFields.UserId)) {
            newErrors.UserId = 'User(s) is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0 && validation.emailValid === true) {
            props.createGroupAction(formFields, appId, filterData);
        }
    };

    console.log("formfields:", formFields);
    console.log("error:", errors);
    console.log("filterData:", filterData);


    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <FormGroup>
                <Label>
                    Group Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    onFocus={handleFocus}
                    autoFocus={true}
                    type="text"
                    name="GroupName"
                    placeholder="Enter group name"
                    onChange={validateOnChange}
                    value={formFields && formFields.GroupName}
                    invalid={errors && errors.GroupName}
                />
                {<FormFeedback>{errors && errors.GroupName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    Group Email <span className="text-danger"> *</span>
                </Label>
                <Input
                    onFocus={handleFocus}
                    type="text"
                    name="GroupEmail"
                    placeholder="Enter group email"
                    invalid={errors && errors.GroupEmail}
                    onChange={validateOnChange}
                    value={formFields && formFields.GroupEmail}
                />
                <FormFeedback>{errors && errors.GroupEmail}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label>
                    Group Owner Email <span className="text-danger"> *</span>
                </Label>
                <Input
                    onFocus={handleFocus}
                    type="text"
                    name="GroupOwnerEmail"
                    placeholder="Enter group owner email"
                    invalid={errors && errors.GroupOwnerEmail}
                    onChange={validateOnChange}
                    value={formFields && formFields.GroupOwnerEmail}
                />
                <FormFeedback>{errors && errors.GroupOwnerEmail}</FormFeedback>

            </FormGroup>
            <FormGroup>
                <Label>
                    Role<span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.RoleId) ? '#FF3D60' : "#CED4DA"
                        })
                    }}
                    name="RoleId"
                    placeholder="Select role"
                    isSearchable
                    defaultOptions
                    loadOptions={handleRole}
                    onChange={handleAddRole}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.RoleId}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    App(s) <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.AppId) ? '#FF3D60' : "#CED4DA"
                        })
                    }}
                    key={keyChange}
                    name="AppId"
                    placeholder="Select app(s)"
                    isSearchable
                    isMulti
                    defaultOptions
                    loadOptions={handleapps}
                    onChange={handleAddApps}
                    value={prevAppDetails}
                /> <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.AppId}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    User(s) <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.UserId) ? '#FF3D60' : "#CED4DA"
                        })
                    }}
                    key={keyUserChange}
                    name="UserId"
                    placeholder="Select user(s)"
                    isSearchable
                    isMulti
                    defaultOptions
                    loadOptions={handleUser}
                    onChange={handleAddUser}
                    value={prevUserDetails}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.UserId}
                    </div>
                </FormText>
            </FormGroup>
            <br></br>
            <FormGroup>
                <Button
                    className="me-2"
                    color="success"
                    onClick={handleCreateGroupUser}
                    disabled={props.isPending}
                >
                    {props.isPending ? <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        &nbsp; Submitting...
                    </>
                        : <>Submit</>}
                </Button>
                <Button
                    color="danger"
                    onClick={props.handleClose}
                >
                    Cancel
                </Button>
            </FormGroup>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isPending: state.AdminGroup.isPending,
    errorMessage: state.AdminGroup.errorMessage,
    currentProfile: state.login.currentProfile
});
const mapDispatchToProps = (dispatch) => ({
    getOrgNameAsyncSelectAction: (searchValue, callback, orgName) => dispatch(getOrgNameAsyncSelect(searchValue, callback, orgName)),
    getRoleNameAsyncSelectAction: (searchValue, callback, roles) => dispatch(getRoleNameAsyncSelect(searchValue, callback, roles)),
    getAppNameAsyncSelectAction: (Role, searchValue, callback) => dispatch(getAppNameAsyncSelect(Role, searchValue, callback)),
    getUserNameAsyncSelectAction: (appId, Role, searchValue, callback) => dispatch(getUserNameAsyncSelect(appId, Role, searchValue, callback)),
    createGroupAction: (formFields, appId, filterData) => dispatch(CreateGroups(formFields, appId, filterData)),
    createGroupSuccess: () => dispatch(createGroupSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup)