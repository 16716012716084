import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Input, InputGroup, Label, Row, Table, Tooltip } from 'reactstrap'
import { connect } from "react-redux";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBorderAll, faEdit, faFileLines, faCalendarAlt, faEye, faTrash, faFont } from '@fortawesome/free-solid-svg-icons';
import { ClearCreateVisionDocsReducer, clearVisionDocsDetails, closeDeleteDocsModalAction, deleteDocsList, getvisionDocsList, isCreateEditDocspending, isUpdateEditDocspending, openDeleteDocsModalAction, sendToggleView } from '../../../store/Modules/VisionDocs/actions';
import moment from "moment";
import { getPager } from '../../../helper/common';
import VWPagination from '../../../components/vwPagination/vwPagination';
import Avatar from 'react-avatar';
import VWSpinner from '../../../components/vwSpinner/vwSpinner';
import NoRecord from '../../../components/NoRecords/noRecords';
import VWMessage from '../../../components/vwMessage/vwMessage';
import DocsDelete from './DocsDelete';
import VWModel from '../../../components/Modal/VWModal';
import { ElementToolTip } from '../Projects/vwToolTip';
import UITooltip from '../../../CommonData/Data/UITooltip';


const DocsList = (props) => {
    const { VWDocsList } = props;
    const [toggleView, setToggleView] = useState(true);
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const [deleteID, setDeleteID] = useState({});

    const buildQueryDocumentTitle = () => {
        const { Title } = filter || {};
        let searchTitle = '';
        if (Title) {
            searchTitle += `${Title}`;
        }
        return encodeURIComponent(searchTitle)
    }
    const buildQueryAssignUserCount = () => {
        const { AssignUserCount } = filter || {};
        let searchAssignUserCount = '';
        if (AssignUserCount) {
            searchAssignUserCount += `${AssignUserCount}`;
        }
        return (searchAssignUserCount)
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return (searchCreatedBy)
    }
    const buildQueryUpdatedBy = () => {
        const { UpdatedBy } = filter || {};
        let searchUpdatedBy = '';
        if (UpdatedBy) {
            searchUpdatedBy += `${UpdatedBy}`;
        }
        return (searchUpdatedBy)
    }
    const buildQueryCreatedTimeStamp = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedTimeStamp = '';
        if (CreatedTimeStamp) {
            searchCreatedTimeStamp += `${CreatedTimeStamp}`;
        }
        return (searchCreatedTimeStamp)
    }
    const buildQueryUpdatedTimeStamp = () => {
        const { UpdatedTimeStamp } = filter || {};
        let searchUpdatedTimeStamp = '';
        if (UpdatedTimeStamp) {
            searchUpdatedTimeStamp += `${UpdatedTimeStamp}`;
        }
        return (searchUpdatedTimeStamp)
    }
    const buildQueryAccess = () => {
        const { Access } = filter || {};
        let searchAccess = '';
        if (Access) {
            searchAccess += `${Access}`;
        }
        return (searchAccess)
    }

    const setPage = (page = 1) => {
        props.getvisionDocsList(page, PageSize, buildQueryDocumentTitle(), buildQueryAssignUserCount(), buildQueryCreatedBy(), buildQueryCreatedTimeStamp(), buildQueryAccess(), buildQueryUpdatedBy(), buildQueryUpdatedTimeStamp());

        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        setToggleView(props.toggleViewBool)
    }, [props.toggleViewBool])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.isUpdateEditDocspending();
        props.isCreateEditDocspending();
        props.clearVisionDocsDetails();
        props.ClearCreateVisionDocsReducer();
    }, [])

    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }


    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])


    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };
    const sortedVWDocsList = [...props.VWDocsList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'AssignUserCount') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });



    const clearCreateID = () => {
        props.ClearCreateVisionDocsReducer();
    }

    const handleDelete = (id) => {
        console.log("delete function trigger");
        console.log("delete id", id);
        // props.deleteDocsList(id);
        setDeleteID({ id });
        props.openDeleteDocsModalAction();

    }

    console.log("profile:", props.profile);
    const { userId } = props.profile;
    // const assignedOwnerId = value && value.filter((i) => (i.UserDetails && i.UserDetails.AssignedUserId) === userId)

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const [filterUpdateSelectionOption, setFilterUpdateSelectionOption] = useState('updateName');
    const handleUpdateTextSelect = (e) => {
        e.preventDefault();
        setFilterUpdateSelectionOption('UpdateDate');
        setFilter({
            ...filter,
            UpdatedBy: null,
            UpdatedTimeStamp: null,
        });
    }

    const handleUpdateDateSelect = (e) => {
        e.preventDefault();
        setFilterUpdateSelectionOption('updateName');
        setFilter({
            ...filter,
            UpdatedBy: null,
            UpdatedTimeStamp: null,
        });
    }

    //-----------------------------------------Modal open & close-----------------------------------------------------

    const handleClose = () => {
        props.closeDeleteDocsModalAction();
    };

    const [redirectID, setRedirectID] = useState(null);
    const OnDoubleClickAction = (ID) => {
        setRedirectID({ ...redirectID, id: ID });
    };
    if (redirectID) {
        return <Navigate to={`/visiondocs/documents/ve/${redirectID.id}`} />;
    }
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Card >
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>DOCUMENT</strong>
                        <Row className='border border-info rounded-1' noGutters>
                            <Col
                                className={toggleView ? "bg-info text-white p-2" : "bg-white text-info p-2 rounded-1"}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setToggleView(true)
                                    props.sendToggleView(true)
                                }}
                                id={'GridView'}
                            >
                                <FontAwesomeIcon icon={faBorderAll} className='fs-4' />
                                <ElementToolTip id={'GridView'} position={'left'} name={
                                    'Grid View'
                                } />
                            </Col>
                            <Col
                                className={!toggleView ? "bg-info p-2 text-white" : "bg-white text-info p-2 rounded-1"}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setToggleView(false)
                                    props.sendToggleView(false)
                                }}
                                id={'TableView'}
                            >
                                <FontAwesomeIcon icon={faBars} className='fs-4' />
                                <ElementToolTip id={'TableView'} position={'right'} name={
                                    'Table View'
                                } />
                            </Col>
                        </Row>

                        <div className="content-heading">
                            <div className="ml-auto">
                                <Link
                                    type="button"
                                    className="btn btn-info"
                                    to='/visiondocs/documents/add'
                                    onChange={clearCreateID()}
                                >
                                    CREATE
                                </Link>&nbsp;
                            </div>
                        </div>
                    </CardTitle>
                </Card>
                <Card>
                    {props.ListErrorMessage ? <div className='w-100'>
                        {props.ListErrorMessage && (
                            <VWMessage type="danger" defaultMessage={props.ListErrorMessage} baseClassName="alert" ></VWMessage>
                        )}
                    </div> : ""}
                    {toggleView ?
                        <>
                            <Card className="shadow-none p-3">
                                <Row>
                                    <Col lg="4">
                                        <Label>Document Name</Label>
                                        <Input
                                            type='text'
                                            placeholder='Search'
                                            name='Title'
                                            onChange={searchInputValidation}
                                            value={filter && filter.Title}
                                            onKeyPress={handleKeyPress}
                                        /></Col>
                                    <Col lg="4">
                                        <Label>Created By</Label>
                                        <InputGroup >
                                            {filterSelectionOption === 'name' ?
                                                <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                    <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                    <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                // style={{ width: "120px" }}
                                                type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col lg="4">
                                        <Label>Updated By</Label>
                                        <InputGroup >
                                            {filterUpdateSelectionOption === 'updateName' ?
                                                <UITooltip id={`UpdateText`} key={`UpdateText`} content={`Text Filter`}>
                                                    <Button onClick={handleUpdateTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`UpdateDate`} key={`UpdateDate`} content={`Date Filter`}>
                                                    <Button onClick={handleUpdateDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                // style={{ width: "120px" }}
                                                type={filterUpdateSelectionOption === 'updateName' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterUpdateSelectionOption === 'updateName' ? 'UpdatedBy' : 'UpdatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterUpdateSelectionOption === 'updateName' ? filter.UpdatedBy || '' : filter.UpdatedTimeStamp || '' : ""}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card>
                            <div style={{ height: "50vh", overflowX: "auto" }}>
                                <CardBody className="p-4 pt-0">
                                    {!props.isPendingList && (props.VWDocsList && props.VWDocsList.length > 0) ? (
                                        <Row >
                                            {sortedVWDocsList.map((data, index) => (
                                                <Col key={index} sm="4" md='3' onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                    {/* <Link to={`/visiondocs/documents/ve/${data.Id}`}> */}
                                                    <Card className='p-0 mx-2 mt-0 shadow-none rounded-1'
                                                        style={{
                                                            minHeight: "70px",
                                                            backgroundColor: "#f2f5ea",
                                                            color: "#2c363f"
                                                        }}>
                                                        <div className='d-flex justify-content-between mt-1 mx-2'>

                                                            <div className='ms-2 font-size-14 text-truncate' style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faFileLines} style={{ height: '15px' }} />
                                                                </span>&nbsp;
                                                                <strong className='text-capitalize' id={`title-${data.Id}`}>{data.Title ? (data.Title && data.Title.length > 20) ? data.Title && data.Title.substring(0, 20) + '...' : data.Title : "N/A" || "NA"}
                                                                    {(data.Title && data.Title.length > 20) && (
                                                                        <ElementToolTip id={`title-${data.Id}`} name={data.Title} />
                                                                    )}</strong>
                                                            </div>
                                                            <div >
                                                                <span id={`avatar-${data.Id}`}>
                                                                    <Avatar round size={25} title={""} name={data.OwnerUserName} />
                                                                </span>
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={
                                                                    <div>
                                                                        <small>{data.OwnerUserName}- [Owner]</small>
                                                                        {data.assignUsers && data.assignUsers.map((user, index) => (
                                                                            <div key={index}>
                                                                                <small>{user.UserName}- [User]</small>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                } />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-item-center mt-2 mx-2'>
                                                            <div>
                                                                {!data.UpdatedTimeStamp ? (
                                                                    <>
                                                                        <span className=" badge p-1" style={{ backgroundColor: "#e75a7c" }} id={`startDate-${data.Id}`}>
                                                                            <FontAwesomeIcon icon={faCalendarAlt} className='me-1' /> {data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD/MMM/yyyy') : ''}
                                                                        </span>
                                                                        <ElementToolTip id={`startDate-${data.Id}`} name={
                                                                            'Created Date'
                                                                        } />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="badge p-1" style={{ backgroundColor: "#e75a7c" }} id={`endDate-${data.Id}`}>
                                                                            <FontAwesomeIcon icon={faCalendarAlt} className='me-1' /> {data.UpdatedTimeStamp ? moment(data.UpdatedTimeStamp).format('DD/MMM/YYYY') : ''}
                                                                        </span>
                                                                        <ElementToolTip id={`endDate-${data.Id}`} name={
                                                                            'Updated Date'
                                                                        } />
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <Link
                                                                    type="button"
                                                                    className="btn btn-sm  btn-light"
                                                                    to={`/visiondocs/documents/ve/${data.Id}`}
                                                                > {data.Access === 'Write' ?
                                                                    <><FontAwesomeIcon icon={faEdit} style={{ color: "black", }} id={`write-${data.Id}`} /><ElementToolTip id={`write-${data.Id}`} name={'Write'} /></> :
                                                                    <><FontAwesomeIcon icon={faEye} style={{ color: "black" }} id={`read-${data.Id}`} /><ElementToolTip id={`read-${data.Id}`} name={'Read only'} /></>}
                                                                </Link>
                                                                {data.AllowUserToDeleteDoc ?
                                                                    <Button type="submit" size="sm"
                                                                        onClick={() => handleDelete(data.Id)}
                                                                        style={{ backgroundColor: 'transparent', border: 'none' }}
                                                                    > <FontAwesomeIcon icon={faTrash} color='red' /></Button>
                                                                    :
                                                                    <Button type="submit" size="sm"
                                                                        onClick={() => handleDelete(data.Id)}
                                                                        disabled
                                                                        style={{ backgroundColor: 'transparent', border: 'none' }}
                                                                    > <FontAwesomeIcon icon={faTrash} color='black' /></Button>}
                                                            </div>
                                                        </div>
                                                    </Card>
                                                    {/* </Link> */}
                                                </Col>
                                            ))}
                                        </Row>
                                    ) : (
                                        props.isPendingList ?
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <VWSpinner />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <NoRecord />
                                                    </div>
                                                </div>
                                            </div>
                                    )}
                                </CardBody>
                            </div>

                        </>
                        :
                        (
                            <CardBody className="p-4">
                                <div style={{ height: "60vh", overflowX: "auto" }} className='p-1 pt-0'>
                                    <Table className="table table-striped table-hover table-nowrap w-100" size="sm" style={style.table}>
                                        <thead className='overflow-hidden sticky-top ' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                            <tr>
                                                <th onClick={() => handleSort('Title')} className='hand-cursor'
                                                >
                                                    &nbsp;  Document Name  &nbsp;
                                                    {sortOrder === 'Title' && (
                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                    )}
                                                </th>
                                                <th onClick={() => handleSort('CreatedBy')} className='hand-cursor'
                                                >
                                                    Created By  &nbsp;
                                                    {sortOrder === 'CreatedBy' && (
                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                    )}
                                                </th>
                                                <th onClick={() => handleSort('UpdatedBy')} className='hand-cursor'
                                                >
                                                    Updated By  &nbsp;
                                                    {sortOrder === 'UpdatedBy' && (
                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                    )}
                                                </th>

                                                <th className='text-center'>Action</th>
                                            </tr>
                                            <tr>
                                                <th >
                                                    <Input
                                                        type='text'
                                                        placeholder='Search'
                                                        name='Title'
                                                        onChange={searchInputValidation}
                                                        value={filter && filter.Title}
                                                        onKeyPress={handleKeyPress}
                                                    /></th>
                                                <th>
                                                    <InputGroup >
                                                        {filterSelectionOption === 'name' ?
                                                            <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                                <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                            </UITooltip>
                                                            : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                                <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                            </UITooltip>
                                                        }
                                                        <Input
                                                            // style={{ width: "120px" }}
                                                            type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                            placeholder='Search'
                                                            name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                            onChange={searchInputValidation}
                                                            onKeyPress={handleKeyPress}
                                                            value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                            max={filterSelectionOption !== "name" && "9999-12-31"}
                                                        />
                                                    </InputGroup>
                                                </th>
                                                <th>
                                                    <InputGroup >
                                                        {filterUpdateSelectionOption === 'updateName' ?
                                                            <UITooltip id={`UpdateText`} key={`UpdateText`} content={`Text Filter`}>
                                                                <Button onClick={handleUpdateTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                            </UITooltip>
                                                            : <UITooltip id={`UpdateDate`} key={`UpdateDate`} content={`Date Filter`}>
                                                                <Button onClick={handleUpdateDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                            </UITooltip>
                                                        }
                                                        <Input
                                                            // style={{ width: "120px" }}
                                                            type={filterUpdateSelectionOption === 'updateName' ? 'text' : 'date'}
                                                            placeholder='Search'
                                                            name={filterUpdateSelectionOption === 'updateName' ? 'UpdatedBy' : 'UpdatedTimeStamp'}
                                                            onChange={searchInputValidation}
                                                            onKeyPress={handleKeyPress}
                                                            value={filter ? filterUpdateSelectionOption === 'updateName' ? filter.UpdatedBy || '' : filter.UpdatedTimeStamp || '' : ""}
                                                            max={filterUpdateSelectionOption !== "updateName" && "9999-12-31"}
                                                        />
                                                    </InputGroup>
                                                </th>
                                                <th></th>
                                                {/* <th></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!props.isPendingList && (VWDocsList && VWDocsList.length > 0) ?
                                                sortedVWDocsList && sortedVWDocsList.map((data, index) => (
                                                    <tr key={index} onDoubleClick={() => OnDoubleClickAction(data.Id)}>

                                                        <td className='ps-3'>{data.Title || "NA"} &nbsp;
                                                            <span id={`avatar-${data.Id}`}>
                                                                <Badge color='success'>{data.AssignUserCount || 0}</Badge>
                                                            </span>
                                                            {data.assignUsers && data.assignUsers.length > 0 && (
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={
                                                                    <div>
                                                                        <small>{data.OwnerUserName}- [Owner]</small>
                                                                        {data.assignUsers && data.assignUsers.map((user, index) => (
                                                                            <div key={index}>
                                                                                <small>{user.UserName}- [User]</small>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                } />
                                                            )}
                                                        </td>
                                                        <td className='ps-3'>{data.CreatedBy || "NA"}<br />
                                                            {data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : 'N/A' || "NA"}</td>
                                                        <td className='ps-3'>{data.UpdatedBy || "NA"}<br />
                                                            {data.UpdatedTimeStamp ? moment(data.UpdatedTimeStamp).format('DD MMM YYYY') : 'N/A' || "NA"}</td>
                                                        <td >
                                                            <div className='d-flex justify-content-around'>
                                                                <Link
                                                                    type="button"
                                                                    className="btn btn-sm  btn-light"
                                                                    to={`/visiondocs/documents/ve/${data.Id}`}
                                                                > {data.Access === 'Write' ?
                                                                    <><FontAwesomeIcon icon={faEdit} style={{ color: "black", }} id={`write-${data.Id}`} /><ElementToolTip id={`write-${data.Id}`} name={'Write'} /></> :
                                                                    <><FontAwesomeIcon icon={faEye} style={{ color: "black" }} id={`read-${data.Id}`} /><ElementToolTip id={`read-${data.Id}`} name={'Read only'} /></>}
                                                                </Link>
                                                                {data.AllowUserToDeleteDoc ?
                                                                    <Button type="submit" color="danger" size="sm"
                                                                        onClick={() => handleDelete(data.Id)}
                                                                    > <FontAwesomeIcon icon={faTrash} /></Button>
                                                                    :
                                                                    <Button type="submit" color="dark" size="sm"
                                                                        onClick={() => handleDelete(data.Id)}
                                                                        disabled
                                                                    > <FontAwesomeIcon icon={faTrash} /></Button>}
                                                            </div></td>

                                                    </tr>
                                                )) :
                                                (
                                                    props.isPendingList ?
                                                        <div className="sticky-spinner-row">
                                                            <div className="position-relative">
                                                                <div className="d-flex justify-content-center align-items-center"
                                                                    style={{
                                                                        position: 'fixed',
                                                                        top: '50%',
                                                                        left: '60%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        zIndex: 1000,
                                                                        width: '100vw',
                                                                        height: '100vh',
                                                                        pointerEvents: 'none',
                                                                    }}>
                                                                    <VWSpinner />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="sticky-spinner-row">
                                                            <div className="position-relative">
                                                                <div className="d-flex justify-content-center align-items-center"
                                                                    style={{
                                                                        position: 'fixed',
                                                                        top: '50%',
                                                                        left: '60%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        zIndex: 1000,
                                                                        width: '100vw',
                                                                        height: '100vh',
                                                                        pointerEvents: 'none',
                                                                    }}>
                                                                    <NoRecord />
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>)

                    }
                    <CardFooter>
                        {props.VWDocsList && props.VWDocsList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    //eslint-disable-next-line
                    centered={true}
                    header="DELETE DOCS"
                    isOpen={props.isOpenVisionDocsDelete}
                    handleClose={handleClose}
                >
                    <DocsDelete data={deleteID} handleClose={handleClose} />
                </VWModel>
            </div>
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    VWDocsList: state.visionDocs.DocsList,
    totalCount: state.visionDocs.totalRecords,
    isPendingList: state.visionDocs.isPendingList,
    toggleViewBool: state.visionDocs.toggleViewBool,
    ListErrorMessage: state.visionDocs.ListErrorMessage,
    profile: state.login.currentProfile,
    isOpenVisionDocsDelete: state.visionDocs.isOpenVisionDocsDelete
});
const mapDispatchToProps = (dispatch) => ({
    getvisionDocsList: (page, PageSize, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp) => dispatch(getvisionDocsList(page, PageSize, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp)),
    isUpdateEditDocspending: () => dispatch(isUpdateEditDocspending()),
    isCreateEditDocspending: () => dispatch(isCreateEditDocspending()),
    clearVisionDocsDetails: () => dispatch(clearVisionDocsDetails()),
    sendToggleView: (toggleView) => dispatch(sendToggleView(toggleView)),
    ClearCreateVisionDocsReducer: () => dispatch(ClearCreateVisionDocsReducer()),
    deleteDocsList: (id) => dispatch(deleteDocsList(id)),
    openDeleteDocsModalAction: () => dispatch(openDeleteDocsModalAction()),
    closeDeleteDocsModalAction: () => dispatch(closeDeleteDocsModalAction()),

});
export default connect(mapStateToProps, mapDispatchToProps)(DocsList)