import React, { useEffect, useState } from 'react'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { connect } from 'react-redux';
import { clearCreateVisitTypeError, createNewVisitType, createVisitTypeSuccess } from '../../../../../../store/Modules/crm/Settings/VisitType/actions';

const AddVisit = (props) => {

    const { isVisitTypeCreatePending, errorMessage } = props;
    const [visitTypeDetails, setVisitTypeDetails] = useState();
    const [errors, setErrors] = useState();
    const [autoFocus, setAutoFocus] = useState(true);
    useEffect(() => {
        props.clearCreateVisitTypeError();
        props.createVisitTypeSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setVisitTypeDetails({
            ...visitTypeDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleSubmit();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let currentErrors = {};
        const { visitTypeName } = visitTypeDetails || {};

        if (!visitTypeName) {
            currentErrors.visitTypeName = 'Visit type is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createVisitType(visitTypeDetails)
        }
    }
    console.log("errorMessage:", errorMessage);

    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label>Visit Type  <span className='text-danger'>*</span></Label>
                <Input
                    onFocus={handleFocus}
                    autoFocus={true}
                    type="text"
                    name="visitTypeName"
                    placeHolder="Enter visit type"
                    onChange={validateOnchange}
                    value={visitTypeDetails && visitTypeDetails.visitTypeName}
                    invalid={errors && errors.visitTypeName}
                />
                <FormFeedback>{errors && errors.visitTypeName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isVisitTypeCreatePending}
                >
                    {isVisitTypeCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isVisitTypeCreatePending: state.VisitType.isVisitTypeCreatePending,
    errorMessage: state.VisitType.visitTypeCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createVisitType: (visitTypeDetails) => dispatch(createNewVisitType(visitTypeDetails)),
    clearCreateVisitTypeError: () => dispatch(clearCreateVisitTypeError()),
    createVisitTypeSuccess: () => dispatch(createVisitTypeSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddVisit)