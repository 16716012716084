import React, { useEffect } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    Table
} from "reactstrap";
import { connect } from "react-redux";
import moment from 'moment';
import VWModel from "../../../../../../components/Modal/VWModal";
import VWMessage from "../../../../../../components/vwMessage/vwMessage";
import NoRecord from "../../../../../../components/NoRecords/noRecords";
import VWPagination from "../../../../../../components/vwPagination/vwPagination";
import { useState } from "react";
import { getPager } from "../../../../../../helper/common";
import VWSpinner from "../../../../../../components/vwSpinner/vwSpinner";
import { clearUpdateTaskScopeError, closeAddTaskScopeModal, closeEditTaskScopeModal, getTaskScopeList, openAddTaskScopeModal, openEditTaskScopeModal, setTaskScopeDetails, updateTaskScope, updateTaskScopeSuccess } from "../../../../../../store/Modules/ProjectPortal/Settings/TaskScope/actions";
import AddTaskScope from "./AddTaskScope";
import EditTaskScope from "./EditTaskScope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { setIsInlineUpdate } from "../../../../../../store/Modules/crm/Settings/SettingsInlineUpdate/actions";

const TaskScope = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const { userAppDetails } = props.currentProfile || {};
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    const setPage = (page = 1) => {
        props.getTaskScopeList(App[0] && App[0].appId || '', page, PageSize);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.setIsInlineUpdate();
        props.clearUpdateTaskScopeError();
        props.updateTaskScopeSuccess();
    }, [])
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedTaskScopeList = [...props.taskScopeList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleAddOpenTaskScope = () => {
        props.openAddTaskScopeModal();
    }
    const handleClose = () => {
        props.closeAddTaskScopeModal();
    };

    const handleScopeEdit = (id) => {
        const { setTaskScopeDetails, openEditTaskScopeModal } = props;
        const selected_Scope = sortedTaskScopeList.find((category) => {
            return category.Id === id;
        });
        setTaskScopeDetails(selected_Scope);
        console.log("selected_Scope:", selected_Scope);

        openEditTaskScopeModal();
    }
    const handleEditClose = () => {
        props.closeEditTaskScopeModal();
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();
    const [editInlineList, setEditInlineList] = useState({ scopeName: '' });

    const handleEditClick = (itemId, IsActive, ScopeName) => {
        props.setIsInlineUpdate(itemId);
        setActive(IsActive);
        setEditInlineList({ ...editInlineList, scopeName: ScopeName, isActive: IsActive, Id: itemId });
        setErrors({});
    };
    const handleClearClick = () => {
        props.setIsInlineUpdate(null);
    };

    const handleSave = (e) => {
        let currentErrors = {};
        const { scopeName } = editInlineList || {};

        if (!scopeName) {
            currentErrors.scopeName = 'Scope name is required';
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: currentErrors
        }));
        if (Object.keys(currentErrors).length === 0) {
            props.updateTaskScope(editInlineList, App[0] && App[0].appId)
        }
    }


    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setEditInlineList({
            ...editInlineList,
            [name]: value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: {
                ...prevErrors[editInlineList.Id],
                [name]: value ? null : `Scope name is required`
            }
        }));
    }
    const handleActiveStatusChange = () => {
        setEditInlineList({
            ...editInlineList,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    return (
        <React.Fragment>
            <div>
                <Card >
                    <CardTitle className=" fs-5 border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center fw-bold">
                        TASK SCOPE
                        <Button
                            className='fw-bold'
                            size='sm'
                            color='info'
                            onClick={handleAddOpenTaskScope} >
                            ADD
                        </Button>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody>
                        <div style={{ height: "380px", overflowX: "auto" }}>
                            {(props.errorMessage || props.taskScopeUpdateErrorMessage) ? <div className='w-100'>
                                {(props.errorMessage || props.taskScopeUpdateErrorMessage) && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage || props.taskScopeUpdateErrorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th onClick={() => handleSort('ScopeName')} className='hand-cursor'>
                                            Task Scope Name &nbsp;
                                            {sortOrder === 'ScopeName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('IsActive')} className='hand-cursor'>
                                            Status &nbsp;
                                            {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')}>
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.taskScopeList && props.taskScopeList.length > 0 ?
                                        sortedTaskScopeList && sortedTaskScopeList.map((data) => (
                                            <tr class="middle-align">
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <Input
                                                                autoFocus={true}
                                                                size='md'
                                                                type="text"
                                                                name="scopeName"
                                                                placeHolder="Enter scope name"
                                                                onChange={validateOnchange}
                                                                value={editInlineList.scopeName}
                                                                invalid={errors && errors[editInlineList.Id]?.scopeName}
                                                            />
                                                            <FormFeedback>{errors && errors[editInlineList.Id]?.scopeName}</FormFeedback>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>{data.ScopeName || "N/A"}</td>
                                                }
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <ul className="form-settings-unstyled-list form-settings-status-list">
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${!Active ? 'active' : ''}`}
                                                                >
                                                                    Inactive
                                                                </li>
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${Active ? 'active' : ''}`}
                                                                >
                                                                    Active
                                                                </li>
                                                            </ul>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>
                                                        {data.IsActive ? (
                                                            <Badge color="success">Active</Badge>
                                                        ) : (
                                                            <Badge color="danger">Inactive</Badge>
                                                        )}
                                                    </td>
                                                }
                                                <td>
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{ color: "green", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleSave()}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faXmark}
                                                                style={{ color: "red", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleClearClick(data.Id)}
                                                            />
                                                        </>
                                                    </td>
                                                    :
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                            onClick={() => handleEditClick(data.Id, data.IsActive, data.ScopeName)}
                                                        />
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                        : props.isTaskScopeListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.taskScopeList && props.taskScopeList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="ADD TASK SCOPE"
                    modalSize="medium"
                    isOpen={props.isCreateTaskScopeOpen}
                    handleClose={handleClose}
                >
                    <AddTaskScope
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="EDIT TASK SCOPE"
                    modalSize="medium"
                    isOpen={props.isUpdateTaskScopeOpen}
                    handleClose={handleEditClose}
                >
                    <EditTaskScope
                        handleClose={handleEditClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    taskScopeList: state.TaskScope.taskScopeList,
    totalCount: state.TaskScope.taskScopeListCount,
    isTaskScopeListPending: state.TaskScope.isTaskScopeListPending,
    errorMessage: state.TaskScope.taskScopeListErrorMessage,
    isCreateTaskScopeOpen: state.TaskScope.isCreateTaskScopeOpen,
    isUpdateTaskScopeOpen: state.TaskScope.isUpdateTaskScopeOpen,
    isInlineEditID: state.SettingsInlineEdit.isInlineEditID,
    isTaskScopeUpdatePending: state.TaskScope.isTaskScopeUpdatePending,
    taskScopeUpdateErrorMessage: state.TaskScope.taskScopeUpdateErrorMessage,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    getTaskScopeList: (appId, page, PageSize) => dispatch(getTaskScopeList(appId, page, PageSize)),
    openAddTaskScopeModal: () => dispatch(openAddTaskScopeModal()),
    closeAddTaskScopeModal: () => dispatch(closeAddTaskScopeModal()),
    openEditTaskScopeModal: () => dispatch(openEditTaskScopeModal()),
    closeEditTaskScopeModal: () => dispatch(closeEditTaskScopeModal()),
    setTaskScopeDetails: (selected_Scope) => dispatch(setTaskScopeDetails(selected_Scope)),
    setIsInlineUpdate: (itemId) => dispatch(setIsInlineUpdate(itemId)),
    updateTaskScope: (scopeDetails, appId) => dispatch(updateTaskScope(scopeDetails, appId)),
    clearUpdateTaskScopeError: () => dispatch(clearUpdateTaskScopeError()),
    updateTaskScopeSuccess: () => dispatch(updateTaskScopeSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(TaskScope)