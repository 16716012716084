import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    UpdateTasks,
    closeMilestoneCreate,
    getAssignedUserAsyncSelect,
    getDependenciesAsyncSelect,
    getMilestoneDate,
    getMilestoneSelectAction,
    getProjectNameAsyncSelect,
    getTaskScopeAsyncSelectAction,
    getTaskTypeAsyncSelectAction,
    getTaskDetails,
    openMilestoneCreate,
    updateTaskSuccess,
    getTaskStatusAsyncSelectAction,
    getTaskStatusAsyncUserSelectAction,
    getTaskStatusAsyncMilestoneSelectAction
} from '../../../../store/Modules/ProjectPortal/Task/actions'
import moment from 'moment-timezone';
import Async from 'react-select/async';
import Select from 'react-select';
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Row
} from 'reactstrap';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWModel from '../../../../components/Modal/VWModal';
import CreateMilestones from '../Milestones/CreateMilestones';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
    EFFORT_OPTION,
    HOURS, MINUTES,
    PRIORITY_OPTION,
    SCOPE_OPTIONS,
    STATUS_OPTION,
    STATUS_OPTION_USER_EDIT,
    TASK_TYPE_OPTION
} from '../../../../store/Modules/ProjectPortal/Task/constants';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import "./TaskEditor.css"

const customStylesTime = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        minWidth: '50px',
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: '0px 5px 5px 0px',
    }),
};
const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        minWidth: '150px',
    }),
};
const UpdateTask = (props) => {
    const [formFields, setFormFields] = useState({});
    const [PrevProjectName, setPrevProjectName] = useState([]);
    const [existingProjectUser, setExistingProjectUser] = useState([]);
    const [prevAssignedUser, setPrevAssignedUser] = useState([]);
    const [prevReviewerUser, setPrevReviewerUser] = useState([]);
    const [existingMilestone, setExistingMilestone] = useState([]);
    const [newMilestone, setPrevMilestone] = useState([]);
    const [taskType, setTaskType] = useState([]);
    const [priorityType, setPriorityType] = useState({});
    const [effortType, setEffortType] = useState();
    const [statusType, setStatusType] = useState([]);
    const [errors, setErrors] = useState({});
    const [newDependencies, setNewDependencies] = useState([]);
    const [isEdited, setIsEdited] = useState(true);
    const [milestoneError, setMilestoneError] = useState('');
    const [isActive, setisActive] = useState(false);
    const [scopeType, setScopeType] = useState([]);
    const [milestoneStartDate, setMilestoneStartDate] = useState(null);
    const [milestoneEndDate, setMilestoneEndDate] = useState(null);
    const [spentHours, setSpentHours] = useState(null);
    const [spentMinutes, setSpentMinutes] = useState(null);
    const [keyChange, setKeyChange] = useState(1);
    const [projectId, setProjectId] = useState();
    const [milestonekeyChange, setMilestoneKeyChange] = useState(1);
    const [statuskeyChange, setStatusKeyChange] = useState(1);
    const [milestoneId, setMilestoneId] = useState();
    const [isSpendHoursUpdate, setSpentHoursUpdate] = useState(); // this bool is used for checking  assigned user and login user are same ,if same bool is true. 
    const [isEditSpentHours, setEditSpendHours] = useState(false); // this bool is used , if task status is changed ,this bool become true. 
    const [autoFocus, setAutoFocus] = useState(true);
    const { TaskDetails, TaskKanbanDetails, isGanttChartUpdate } = props || {};
    const { userId, userAppDetails } = props.currentProfile || {};
    const { appId, KanbanStartDate, KanbanEndDate, kanbanFilterProjectId, kanbanFilterUserIds, kanbanFilterTaskName } = props;
    useEffect(() => {
        setSpentHoursUpdate(props.TaskDetails && props.TaskDetails.assignedUserId === userId);

        props.updateTaskSuccess();
        if (props.TaskKanbanDetails) {
            props.getMilestoneDate(TaskKanbanDetails && TaskKanbanDetails.mileStoneId);
            const { dependencies } = TaskKanbanDetails;
            const { dependenciesName } = TaskKanbanDetails || {};
            const queriedDependencies = dependencies.length > 0 ? dependencies[0] : null;
            setFormFields({ ...TaskKanbanDetails, dependencies: queriedDependencies });
            setScopeType((TaskKanbanDetails && TaskKanbanDetails.taskScopeName) ? { label: TaskKanbanDetails && TaskKanbanDetails.taskScopeName, value: TaskKanbanDetails && TaskKanbanDetails.taskScopeId } : 'Select scope')
            setNewDependencies((dependenciesName && queriedDependencies) ? { label: dependenciesName || 'Select dependencies', value: queriedDependencies || '' } : 'Select dependencies');
            if (TaskKanbanDetails && TaskKanbanDetails.mileStoneId) {
                setMilestoneKeyChange(milestonekeyChange + 1);
                setMilestoneId(TaskKanbanDetails && TaskKanbanDetails.mileStoneId);
                setStatusKeyChange(statuskeyChange + 1);
            }
            setPrevMilestone({ label: TaskKanbanDetails && TaskKanbanDetails.milestoneName, value: TaskKanbanDetails && TaskKanbanDetails.mileStoneId });
            setTaskType({ label: TaskKanbanDetails && TaskKanbanDetails.taskTypeName, value: TaskKanbanDetails && TaskKanbanDetails.taskTypeId });
            setPriorityType((TaskKanbanDetails && TaskKanbanDetails.priority) ? { label: TaskKanbanDetails && TaskKanbanDetails.priority, value: TaskKanbanDetails && TaskKanbanDetails.priority } : 'Select priority');
            setEffortType(TaskKanbanDetails.effort ? { label: TaskKanbanDetails && TaskKanbanDetails.effort, value: TaskKanbanDetails && TaskKanbanDetails.effort } : { label: "Select effort", value: '' });
            setPrevProjectName({ label: TaskKanbanDetails && TaskKanbanDetails.projectName, value: TaskKanbanDetails && TaskKanbanDetails.projectId });
            setPrevAssignedUser(TaskKanbanDetails && TaskKanbanDetails.assignedUserId ? { label: `${TaskKanbanDetails && TaskKanbanDetails.assignedUserName || 'N/A'} | ${TaskKanbanDetails && TaskKanbanDetails.assignedUserEmail || 'N/A'}`, value: TaskKanbanDetails && TaskKanbanDetails.assignedUserId } : '');
            setPrevReviewerUser(((TaskKanbanDetails && TaskKanbanDetails.reviewerUserName) && (TaskKanbanDetails && TaskKanbanDetails.reviewerUserEmail)) ? { label: `${TaskKanbanDetails && TaskKanbanDetails.reviewerUserName || 'N/A'} | ${TaskKanbanDetails && TaskKanbanDetails.reviewerUserEmail || 'N/A'}`, value: TaskKanbanDetails && TaskKanbanDetails.reviewerUserId } : 'Select reviewer');
            setStatusType({ label: `${TaskKanbanDetails && TaskKanbanDetails.taskStatus} - ${TaskKanbanDetails && TaskKanbanDetails.currentStatusPercentage}%`, value: TaskKanbanDetails && TaskKanbanDetails.taskStatus });
            setisActive(TaskKanbanDetails && TaskKanbanDetails.allowUserToEditStatus);
            setProjectId(TaskKanbanDetails && TaskKanbanDetails.projectId);
            if (TaskKanbanDetails && TaskKanbanDetails.assignedUserId) {
                setFormFields({ ...TaskKanbanDetails, userId: TaskKanbanDetails && TaskKanbanDetails.assignedUserId, dependencies: queriedDependencies });
            }
            if (TaskKanbanDetails && TaskKanbanDetails.projectId) {
                setKeyChange(keyChange + 1)
            }
        }
        else {
            if (TaskDetails) {
                props.getMilestoneDate(TaskDetails && TaskDetails.mileStoneId);
                const { dependencies, dependenciesName } = TaskDetails || {};
                const queriedDependencies = dependencies && dependencies.length > 0 ? dependencies[0] : null;

                setFormFields({ ...TaskDetails, dependencies: queriedDependencies });
                setNewDependencies((dependenciesName && queriedDependencies) ? { label: dependenciesName || 'Select dependencies', value: queriedDependencies || '' } : 'Select dependencies');
                if (TaskDetails && TaskDetails.mileStoneId) {
                    setMilestoneKeyChange(milestonekeyChange + 1);
                    setStatusKeyChange(statuskeyChange + 1);
                }
                setScopeType((TaskDetails && TaskDetails.taskScopeName) ? { label: TaskDetails && TaskDetails.taskScopeName || 'Select scope', value: TaskDetails && TaskDetails.taskScopeId } : 'Select scope')
                setPrevMilestone({ label: TaskDetails && TaskDetails.milestoneName, value: TaskDetails && TaskDetails.mileStoneId });
                setTaskType({ label: TaskDetails && TaskDetails.taskTypeName || 'Select task type', value: TaskDetails && TaskDetails.taskTypeId });
                setPriorityType(TaskDetails.priority ? { label: TaskDetails && TaskDetails.priority || 'Select priority', value: TaskDetails && TaskDetails.priority } : "Select priority");
                setEffortType(TaskDetails.effort ? { label: TaskDetails && TaskDetails.effort || 'Select effort', value: TaskDetails && TaskDetails.effort } : "Select effort");
                setPrevProjectName({ label: TaskDetails && TaskDetails.projectName, value: TaskDetails && TaskDetails.projectId });
                setPrevAssignedUser(TaskDetails && TaskDetails.assignedUserId ? { label: `${TaskDetails && TaskDetails.assignedUserName || 'N/A'} | ${TaskDetails && TaskDetails.assignedUserEmail || 'N/A'}`, value: TaskDetails && TaskDetails.assignedUserId } : '');
                setPrevReviewerUser(((TaskDetails && TaskDetails.reviewerUserName) && (TaskDetails && TaskDetails.reviewerUserEmail)) ? { label: `${TaskDetails && TaskDetails.reviewerUserName || 'N/A'} | ${TaskDetails && TaskDetails.reviewerUserEmail || 'N/A'}`, value: TaskDetails && TaskDetails.reviewerUserId } : 'Select reviewer');
                setStatusType(TaskDetails && TaskDetails.taskStatus ? { label: `${TaskDetails && TaskDetails.taskStatus} - ${TaskDetails && TaskDetails.currentStatusPercentage || 0}%`, value: TaskDetails && TaskDetails.taskStatus } : 'Select status');
                setisActive(TaskDetails && TaskDetails.allowUserToEditStatus);
                // if (TaskDetails && TaskDetails.assignedUserId) {
                //     setFormFields({ ...TaskDetails, userId: TaskDetails && TaskDetails.assignedUserId });
                // }
                setProjectId(TaskDetails && TaskDetails.projectId);
                if (TaskDetails && TaskDetails.projectId) {
                    setKeyChange(keyChange + 1)
                }
                setMilestoneId(TaskDetails && TaskDetails.mileStoneId);
                if (TaskDetails && TaskDetails.mileStoneId) {
                    setMilestoneKeyChange(milestonekeyChange + 1);
                    setStatusKeyChange(statuskeyChange + 1);
                }
            }
        }
    }, [TaskKanbanDetails])

    //----------------------------------------- Dependencies dropdown --------------------------------------------
    const handleDependencies = (inputValue, callback) => {
        if (milestoneId) {
            const { getDependenciesAsyncSelect } = props;
            return getDependenciesAsyncSelect(App[0] && App[0].appId, milestoneId, inputValue.length > 0 ? inputValue : '', callback);
        }
    }
    //-------------------------------------------------------------------------------------------------------------------
    const handleDependenciesSelect = (value) => {
        if (value === null) {
            setNewDependencies(value);
            setFormFields({
                ...formFields,
                dependencies: null,
                dependenciesName: null
            })
        } else {
            setNewDependencies(value);
            setFormFields({
                ...formFields,
                dependencies: value.value,
                dependenciesName: value.label
            })
        }
    }
    const handleAddReviewerUser = (value) => {
        if (value === null) {
            setFormFields({ ...formFields, reviewerUserId: null });
            setPrevReviewerUser(value);
        } else {
            setFormFields({ ...formFields, reviewerUserId: value.value });
            setPrevReviewerUser(value);
        }
    }
    //-----------------------------------------Default Options---------------------------------------------------------
    const handleProject = (inputValue, callback) => {
        const { getProjectNameAsyncSelectAction } = props;
        return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleMilestone = (inputValue, callback) => {
        if (projectId) {
            const { getMilestoneSelectAction } = props;
            return getMilestoneSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
        }
    };
    const handleAssignUser = (inputValue, callback) => {
        if (projectId) {
            const { getAssignedUserAsyncSelectAction } = props;
            return getAssignedUserAsyncSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
        }
    };
    const handleTaskScope = (inputValue, callback) => {
        const { getTaskScopeAsyncSelectAction } = props;
        return getTaskScopeAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleSelectTaskType = (inputValue, callback) => {
        const { getTaskTypeAsyncSelectAction } = props;
        return getTaskTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };

    const handleTaskStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncSelectAction } = props;
        return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskUserStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncUserSelectAction } = props;
        return getTaskStatusAsyncUserSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };

    const handleTaskMilestoneStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncMilestoneSelect } = props;
        return getTaskStatusAsyncMilestoneSelect(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    //-----------------------------------------Update Project Name-----------------------------------------------------
    const handleProjectName = (data) => {
        setKeyChange(keyChange + 1)
        setPrevAssignedUser({ label: 'Select user', value: '' })
        setPrevMilestone({ label: 'Select milestone name', value: '' })
        setPrevReviewerUser({ label: 'Select reviewer', value: '' });
        setPrevProjectName(data);
        setFormFields({ ...formFields, assignedUserId: '', reviewerUserId: '', mileStoneId: '', dependenciesName: '', dependencies: '' })
        if (data.value) {
            setProjectId(data.value);
        }
    }
    //----------------------------------------Update Date Field--------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        // const formattedDate = value ? moment(value).format() : null;
        const formattedDate = value;
        setFormFields({ ...formFields, [name]: formattedDate });
        if (name === 'startDate') {
            setErrors({ ...errors, startDate: '' });
        }
        if (name === 'endDate') {
            setErrors({ ...errors, endDate: '' });
        }
    };
    //----------------------------------------Update Input Field-------------------------------------------------------
    const maxLength = 150;
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'taskName') {
            if (value && value.length >= maxLength) {
                setErrors({ ...errors, taskName: `TaskName name cannot exceed ${maxLength} characters.` });
            } else {
                setErrors({ ...errors, taskName: '' });
            }
        }
    };
    //----------------------------------------Assigned User-----------------------------------------------------------
    const handleAddProjectUser = (option) => {
        if (option === null) {
            setFormFields({ ...formFields, assignedUserId: null });
            setPrevAssignedUser(option);
        } else {
            setFormFields({ ...formFields, assignedUserId: option.value });
            setPrevAssignedUser(option);
            setErrors({ ...errors, assignedUserId: '' });
            setSpentHoursUpdate(option && option.value === userId);
        }
    }
    //----------------------------------------Update Milestone--------------------------------------------------------
    const handleMilestoneId = (Value) => {
        const { MilestoneError, isCurrentUserManagerOrMilestoneOwner, label, value, IsMilestoneOwnerUpdateStatus, IsUserUpdateStatus } = Value || {};
        console.log("isCurrentUserManagerOrMilestoneOwner:", isCurrentUserManagerOrMilestoneOwner);
        setFormFields({ ...formFields, mileStoneId: value, dependencies: '', dependenciesName: '', allowFlexibleDueDates: Value.AllowFlexibleDueDates, isMilestoneOwnerUpdateStatus: IsMilestoneOwnerUpdateStatus, isUserUpdateStatus: IsUserUpdateStatus, taskStatus: '', currentStatusPercentage: '' });
        setNewDependencies('Select dependencies');
        setPrevMilestone(Value);
        props.getMilestoneDate(value);
        setErrors({ ...errors, mileStoneId: '' });
        //--------------------------------------------------
        setIsEdited(isCurrentUserManagerOrMilestoneOwner);
        setMilestoneError(MilestoneError);
        setMilestoneKeyChange(milestonekeyChange + 1);
        setStatusKeyChange(statuskeyChange + 1);
        setMilestoneId(value);
        setStatusType({ label: 'Select status', value: '' })
    }
    //---------------------------------------Update Dropdown field-----------------------------------------------------
    const handleEffort = (value) => {
        if (value === null) {
            setFormFields({ ...formFields, effort: null });
            setEffortType(value);
        } else {
            setFormFields({ ...formFields, effort: value.value });
            setEffortType(value);
        }
    }
    const handleTaskStatus = (options) => {
        const { value, Percentage } = options;
        setFormFields({ ...formFields, taskStatus: value, currentStatusPercentage: Percentage });
        setErrors({ ...errors, taskStatus: '' });
        setStatusType(options);
        setEditSpendHours(true);
    }
    const handlePriority = (value) => {
        if (value === null) {
            setFormFields({ ...formFields, priority: null })
            setPriorityType(value);
        } else {
            setFormFields({ ...formFields, priority: value.value })
            setPriorityType(value);
        }
    }
    const handleTaskType = (value) => {
        setFormFields({ ...formFields, taskTypeId: value.value });
        setTaskType(value);
        setErrors({ ...errors, taskTypeId: '' });
    }

    const handleScope = (value) => {
        setFormFields({ ...formFields, taskScopeId: value.value });
        setScopeType(value);
        setErrors({ ...errors, taskScopeId: '' });
    }
    const handleHours = (value) => {
        setFormFields({ ...formFields, hours: value.value });
        setSpentHours(value);
    }
    const handleMinutes = (value) => {
        setFormFields({ ...formFields, mint: value.value });
        setSpentMinutes(value);
    }
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    //--------------------------------------------Form Editor Description-------------------------------------------
    // const handleEditorChange = (content) => {
    //     // setFormFields({ ...formFields, "contentText": content });
    //     setFormFields(prevFields => ({ ...prevFields, description: content }));
    // };
    const MAX_SIZE = 300 * 1024; // 300 KB

    const handleEditorChange = (content) => {
        let totalSize = new Blob([content]).size; // Calculate HTML size
        console.log('totalSize:', totalSize);

        // Extract image sources (base64 and URLs)
        const imgTags = content.match(/<img[^>]+src="([^">]+)"/g) || [];

        let imagePromises = imgTags.map(tag => {
            const srcMatch = tag.match(/src="([^">]+)"/);
            if (!srcMatch) return Promise.resolve(0);

            const src = srcMatch[1];

            // If image is base64 encoded, calculate its size
            if (src.startsWith('data:image')) {
                let base64Str = src.split(',')[1]; // Extract base64 data
                let decodedSize = (base64Str.length * (3 / 4)) - (base64Str.endsWith('==') ? 2 : base64Str.endsWith('=') ? 1 : 0);
                return Promise.resolve(decodedSize);
            }

            // If image is an external URL, fetch it to determine size
            return fetch(src, { method: 'HEAD' })
                .then(res => res.headers.get('content-length') || 0)
                .then(size => parseInt(size, 10))
                .catch(() => 0);
        });

        Promise.all(imagePromises).then(imageSizes => {
            totalSize += imageSizes.reduce((sum, size) => sum + size, 0);
            console.log('totalSize:', totalSize, 'MAX_SIZE', MAX_SIZE);
            if (totalSize > MAX_SIZE) {
                setErrors({ ...errors, description: "Content size exceeds the 300KB limit. Please reduce text or images." });
                setFormFields(prevFields => ({ ...prevFields, description: content }));
            } else {
                setFormFields(prevFields => ({ ...prevFields, description: content }));
                setErrors({ ...errors, description: "" });
            }
        });
    };


    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleUpdateTask();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //------------------------------------------Update Task------------------------------------------------------------
    const handleUpdateTask = () => {
        const newErrors = {};
        const startDate = moment(formFields.startDate);
        const endDate = moment(formFields.endDate);
        const projectStart = moment(milestoneStartDate);
        const projectEnd = moment(milestoneEndDate);

        if (errors && errors.description) {
            newErrors.description = errors.description;
        }
        if (!formFields.taskScopeId || !formFields.taskScopeId.trim()) {
            newErrors.taskScopeId = 'Scope is required';
        }
        if (!formFields.projectId || !formFields.projectId.trim()) {
            newErrors.projectId = 'Project name is required';
        }
        if (!formFields.mileStoneId || !formFields.mileStoneId.trim()) {
            newErrors.mileStoneId = 'Milestone name is required';
        }
        if (!formFields.taskName || !formFields.taskName.trim()) {
            newErrors.taskName = 'Task name is required';
        }
        if (formFields.taskName && formFields.taskName.length >= maxLength) {
            newErrors.taskName = `TaskName name cannot exceed ${maxLength} characters.`;
        }
        if (!formFields.taskTypeId || !formFields.taskTypeId.trim()) {
            newErrors.taskTypeId = 'Task type is required';
        }
        // if (!formFields.assignedUserId || !formFields.assignedUserId.trim()) {
        //     newErrors.assignedUserId = 'Assigned user is required';
        // }
        if (!formFields.taskStatus || !formFields.taskStatus.trim()) {
            newErrors.taskStatus = 'Task status is required';
        }
        if (!formFields.startDate || !formFields.startDate.trim()) {
            newErrors.startDate = 'Start date is required';
        }
        if (!formFields.endDate || !formFields.endDate.trim()) {
            newErrors.endDate = 'End date is required';
        }
        if (formFields && !formFields.allowFlexibleDueDates) {
            if (startDate.isBefore(projectStart) || startDate.isAfter(projectEnd)) {
                newErrors.startDate = `Start date must be between ${projectStart.format('DD/MM/YYYY')} and ${projectEnd.format('DD/MM/YYYY')}`;
            }
            if (endDate.isBefore(startDate) || endDate.isAfter(projectEnd)) {
                newErrors.endDate = `End date must be after start date and before ${projectEnd.format('DD/MM/YYYY')}`;
            }
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.UpdateTaskAction(App[0] && App[0].appId, formFields, props.PROJECTID, isGanttChartUpdate, kanbanFilterProjectId, kanbanFilterUserIds, KanbanStartDate, KanbanEndDate, kanbanFilterTaskName);
        }
    }
    //----------------------------------------------------------------------------------------------------------------

    const createMilestoneOpen = () => {
        props.openMilestoneCreate();
    }

    const handleClose = () => {
        props.closeMilestoneCreate();
    };


    const handleDone = (getProjId) => {
        if (getProjId === formFields && formFields.projectId) {
            props.getAssignedUserAsyncSelectAction(getProjId, '', (existingProjectUsers) => {
                setExistingProjectUser(existingProjectUsers);
            });
            props.getMilestoneSelectAction(getProjId, '', (existingMilestone) => {
                setExistingMilestone(existingMilestone)
            })
        }
    };

    const onRefresh = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "lightseagreen"

        if (formFields && formFields.projectId) {

            props.getMilestoneSelectAction(formFields && formFields.projectId, '', (existingMilestone) => {
                setExistingMilestone(existingMilestone);
            });
            props.getAssignedUserAsyncSelectAction(formFields && formFields.projectId, '', (existingProjectUsers) => {
                setExistingProjectUser(existingProjectUsers);
            });
        }
        setKeyChange(keyChange + 1)
    }
    const onRefreshColor = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "grey"
    }
    //-------------------------------------Status Change----------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, allowUserToEditStatus: !isActive })
    };

    useEffect(() => {
        if (props.MilestoneDate) {
            setMilestoneStartDate(props.MilestoneDate && props.MilestoneDate.startDate);
            setMilestoneEndDate(props.MilestoneDate && props.MilestoneDate.endDate);
        }
    }, [props.MilestoneDate]);

    const startDateMax = formFields.endDate ? moment(formFields.endDate).format('YYYY-MM-DD') : milestoneEndDate ? moment(milestoneEndDate).format('YYYY-MM-DD') : '';
    const endDateMin = formFields.startDate ? moment(formFields.startDate).format('YYYY-MM-DD') : milestoneStartDate ? moment(milestoneStartDate).format('YYYY-MM-DD') : '';

    console.log("TaskDetails:", props.TaskDetails);

    const isTimesheetApp = userAppDetails && userAppDetails.find((app) => (app.appName && app.appName.toLowerCase()) === "timesheets") || {};
    const IsUserTimeSheetAccess = (isTimesheetApp && isTimesheetApp.appName && isTimesheetApp.appName.toLowerCase()) === "timesheets";
    console.log("isTimesheetApp:", isTimesheetApp);
    console.log("IsUserTimeSheetAccess:", IsUserTimeSheetAccess);

    console.log("formfields:", formFields);
    console.log('errors:', errors);

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Project Name<span className="text-danger"> *</span>
                        </Label>
                        <Async
                            autoFocus={true}
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.projectId) ? 'red' : "silver"
                                })
                            }}
                            name="projectId"
                            placeholder="Select project name"
                            isSearchable
                            defaultOptions
                            loadOptions={handleProject}
                            onChange={handleProjectName}
                            value={PrevProjectName}
                            isDisabled
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.projectId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="d-flex justify-content-between">
                            <div>
                                Milestone Name<span className="text-danger"> *</span>
                            </div>
                            {((formFields && formFields.allowToCreateMilestone) && !isGanttChartUpdate) &&
                                <div>
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        id="icon"
                                        onClick={onRefresh}
                                        onMouseLeave={onRefreshColor}
                                        style={{ cursor: 'pointer' }}
                                    />

                                    <Link onClick={createMilestoneOpen}>&nbsp;
                                        Create Milestone
                                    </Link>
                                </div>
                            }
                        </Label>
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.mileStoneId) ? 'red' : "silver"
                                })
                            }}
                            key={keyChange}
                            name="mileStoneId"
                            placeholder="Select milestone name"
                            isSearchable
                            defaultOptions
                            loadOptions={handleMilestone}
                            onChange={handleMilestoneId}
                            value={newMilestone}
                            isDisabled={isGanttChartUpdate}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.mileStoneId}
                            </div>
                        </FormText>
                        {!isEdited ?
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {milestoneError}
                                </div>
                            </FormText> : ''}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Task Name <span className="text-danger"> *</span>
                        </Label>
                        <Input
                            onFocus={handleFocus}
                            type="text"
                            name="taskName"
                            placeholder="Enter task name"
                            onChange={validateOnChange}
                            value={formFields && formFields.taskName}
                            invalid={errors && errors.taskName}
                            maxLength={maxLength}
                        />  <FormFeedback>{errors && errors.taskName}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Task Type <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.taskTypeId) ? 'red' : "silver"
                                })
                            }}
                            name="taskTypeId"
                            placeholder="Select task type"
                            isSearchable
                            defaultOptions
                            loadOptions={handleSelectTaskType}
                            onChange={handleTaskType}
                            value={taskType}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.taskTypeId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Assigned To
                        </Label>
                        <Async
                            key={keyChange}
                            name="assignedUserId"
                            placeholder="Select assigned user"
                            isSearchable
                            defaultOptions
                            loadOptions={handleAssignUser}
                            onChange={handleAddProjectUser}
                            value={prevAssignedUser}
                            isClearable
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Planned Hours </Label>
                        <Input
                            onFocus={handleFocus}
                            type="number"
                            name="plannedHours"
                            placeholder="Enter planned hours"
                            onChange={validateOnChange}
                            value={formFields && formFields.plannedHours}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row><Col md={6}>
                <FormGroup>
                    <Label>Start Date <span className="text-danger"> *</span></Label>
                    {formFields && formFields.allowFlexibleDueDates ?
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="startDate"
                            placeholder="Select start date"
                            onChange={validateOnChangeDate}
                            value={formFields.startDate ? moment(formFields.startDate).format("YYYY-MM-DD") : ""}
                            max={moment(formFields.endDate).format("YYYY-MM-DD")}
                            invalid={errors && errors.startDate}
                        />
                        :
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="startDate"
                            placeholder="Select start date"
                            onChange={validateOnChangeDate}
                            value={formFields.startDate ? moment(formFields.startDate).format("YYYY-MM-DD") : ""}
                            min={milestoneStartDate ? moment(milestoneStartDate).format('YYYY-MM-DD') : ''}
                            max={startDateMax}
                            invalid={errors && errors.startDate}
                        />}
                    <FormFeedback>{errors && errors.startDate}</FormFeedback>
                </FormGroup>
            </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>End Date <span className="text-danger"> *</span></Label>
                        {formFields && formFields.allowFlexibleDueDates ?
                            <Input
                                onFocus={handleFocus}
                                type="date"
                                name="endDate"
                                placeholder="Select end date"
                                onChange={validateOnChangeDate}
                                value={formFields.endDate ? moment(formFields.endDate).format("YYYY-MM-DD") : ""}
                                min={moment(formFields.startDate).format("YYYY-MM-DD")}
                                invalid={errors && errors.endDate}
                            />
                            :
                            <Input
                                onFocus={handleFocus}
                                type="date"
                                name="endDate"
                                placeholder="Select end date"
                                onChange={validateOnChangeDate}
                                value={formFields.endDate ? moment(formFields.endDate).format("YYYY-MM-DD") : ""}
                                min={endDateMin}
                                max={milestoneEndDate ? moment(milestoneEndDate).format('YYYY-MM-DD') : ''}
                                invalid={errors && errors.endDate}
                            />
                        }
                        <FormFeedback>{errors && errors.endDate}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Completed Date</Label>
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="completedDate"
                            placeholder="Select completed date"
                            onChange={validateOnChangeDate}
                            value={formFields.completedDate ? moment(formFields.completedDate).format("YYYY-MM-DD") : ""}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Priority
                        </Label>
                        <Select
                            styles={customStyles}
                            name="priority"
                            placeholder="Select priority"
                            isSearchable
                            defaultOptions
                            options={PRIORITY_OPTION}
                            onChange={handlePriority}
                            value={priorityType}
                            isClearable
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Effort
                        </Label>
                        <Select
                            styles={customStyles}
                            name="effort"
                            placeholder="Select effort"
                            isSearchable
                            // defaultOptions
                            options={EFFORT_OPTION}
                            onChange={handleEffort}
                            value={effortType}
                            isClearable
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Task Status <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            styles={{
                                ...customStyles,
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.taskStatus) ? 'red' : "silver"
                                })
                            }}
                            key={statuskeyChange}
                            name="taskStatus"
                            placeholder="Select status"
                            isSearchable
                            defaultOptions
                            loadOptions={formFields && formFields.isMilestoneOwnerUpdateStatus
                                ? handleTaskMilestoneStatusPercentage
                                : formFields && formFields.isUserUpdateStatus
                                    ? handleTaskUserStatusPercentage
                                    : handleTaskStatusPercentage}
                            onChange={handleTaskStatus}
                            value={statusType}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.taskStatus}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Scope  <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            styles={{
                                ...customStyles,
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.taskScopeId) ? 'red' : "silver"
                                })
                            }}
                            name="taskScopeId"
                            placeholder="Select scope"
                            isSearchable
                            defaultOptions
                            loadOptions={handleTaskScope}
                            onChange={handleScope}
                            value={scopeType}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.taskScopeId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <Label>Reviewer</Label>
                        <Async
                            styles={customStyles}
                            key={keyChange}
                            name="reviewerUserId"
                            defaultOptions
                            placeholder="Select reviewer"
                            loadOptions={handleAssignUser}
                            onChange={handleAddReviewerUser}
                            isClearable
                            value={prevReviewerUser}
                        />
                    </FormGroup>
                </Col >
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Dependencies (Depends On)</Label>
                        <Async
                            styles={customStyles}
                            key={milestonekeyChange}
                            name="dependencies"
                            defaultOptions
                            placeholder="Select dependencies"
                            loadOptions={handleDependencies}
                            isOptionDisabled={(option) => option.value === formFields.id}
                            onChange={handleDependenciesSelect}
                            value={newDependencies}
                            isClearable
                        />
                    </FormGroup>
                </Col >
                <Col md={6}>
                    <FormGroup>
                        <Label>Spent Hours</Label>
                        <div className='d-flex'>
                            <InputGroup>
                                <InputGroupText>
                                    HH
                                </InputGroupText>
                                <Select
                                    styles={customStylesTime}
                                    name="hour"
                                    options={HOURS}
                                    placeholder="00"
                                    menuPlacement='top'
                                    onChange={handleHours}
                                    value={spentHours}
                                    isDisabled={((props.TaskDetails) && !(props.TaskDetails.allowTimesheetCreate)) || (!isSpendHoursUpdate) || (!isEditSpentHours) || (!IsUserTimeSheetAccess)}
                                />
                            </InputGroup>
                            <InputGroup >
                                <InputGroupText>
                                    MM
                                </InputGroupText>
                                <Select
                                    styles={customStylesTime}
                                    name="minute"
                                    placeholder="00"
                                    options={MINUTES}
                                    menuPlacement='top'
                                    onChange={handleMinutes}
                                    value={spentMinutes}
                                    isDisabled={((props.TaskDetails) && !(props.TaskDetails.allowTimesheetCreate)) || (!isSpendHoursUpdate) || (!isEditSpentHours) || (!IsUserTimeSheetAccess)}
                                />
                            </InputGroup>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <Row className='d-flex align-items-center'>
                <Col md={6}>
                    <FormGroup>
                        <label className="col-form-label">Allow User To Edit Status</label>
                        <ul className="form-unstyled-list form-status-list">
                            <li
                                onClick={handleActiveStatusChange}
                                className={`form-status-item ${!isActive ? 'active' : ''}`}
                            >
                                No
                            </li>
                            <li
                                onClick={handleActiveStatusChange}
                                className={`form-status-item ${isActive ? 'active' : ''}`}
                            >
                                Yes
                            </li>
                        </ul>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Label>
                    Task Description
                </Label>
                <div className="quill-container-task">
                    <ReactQuill
                        value={formFields && formFields.description}
                        onChange={handleEditorChange}
                        theme="snow"
                        placeholder="Start From Here..."
                        // readOnly={!(formFields && formFields.isEditable)}
                        // modules={{ toolbar: true }}
                        modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike', 'image'],        // toggled buttons
                                ['clean']
                            ],
                        }}
                    />
                </div>
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.description}
                    </div>
                </FormText>
            </Row>
            <br></br>
            <FormGroup>
                <Button
                    className="me-2"
                    color="success"
                    onClick={handleUpdateTask}
                    disabled={props.isPending || !isEdited}
                >
                    {(!isEdited || props.isPending) ? <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        &nbsp; Submitting...
                    </>
                        : <>Submit</>}
                </Button>
                <Button
                    color="danger"
                    onClick={props.handleClose}
                >
                    Cancel
                </Button>
            </FormGroup>
            <VWModel
                header="CREATE MILESTONE"
                isOpen={props.isCreateMilestoneModalOpen}
                handleClose={handleClose}
            >
                <CreateMilestones projId={formFields && formFields.projectId} ProjName={formFields && formFields.projectName} handleDone={handleDone}
                    handleClose={handleClose}
                />
            </VWModel>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    TaskDetails: state.task.TaskDetails,
    isPending: state.task.isUpdateTaskPending,
    errorMessage: state.task.updateTaskErrorMessage,
    isCreateMilestoneModalOpen: state.task.isCreateMilestoneModalOpen,
    MilestoneDate: state.task.MilestoneDate,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    getTaskStatusAsyncMilestoneSelect: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncMilestoneSelectAction(appId, searchValue, callback)),
    getTaskTypeAsyncSelectAction: (searchValue, callback) => dispatch(getTaskTypeAsyncSelectAction(searchValue, callback)),
    getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeAsyncSelectAction(appId, searchValue, callback)),
    getProjectNameAsyncSelectAction: (searchValue, callback) => dispatch(getProjectNameAsyncSelect(searchValue, callback)),
    getDependenciesAsyncSelect: (appId, milestoneId, searchValue, callback) => dispatch(getDependenciesAsyncSelect(appId, milestoneId, searchValue, callback)),
    getAssignedUserAsyncSelectAction: (projectId, searchValue, callback) => dispatch(getAssignedUserAsyncSelect(projectId, searchValue, callback)),
    getMilestoneSelectAction: (projectId, searchValue, callback) => dispatch(getMilestoneSelectAction(projectId, searchValue, callback)),
    UpdateTaskAction: (appId, formFields, PROJECTID, isGanttChartUpdate, kanbanFilterProjectId, kanbanFilterUserIds, KanbanStartDate, KanbanEndDate, kanbanFilterTaskName) => dispatch(UpdateTasks(appId, formFields, PROJECTID, isGanttChartUpdate, kanbanFilterProjectId, kanbanFilterUserIds, KanbanStartDate, KanbanEndDate, kanbanFilterTaskName)),
    openMilestoneCreate: () => dispatch(openMilestoneCreate()),
    closeMilestoneCreate: () => dispatch(closeMilestoneCreate()),
    updateTaskSuccess: () => dispatch(updateTaskSuccess()),
    getMilestoneDate: (id) => dispatch(getMilestoneDate(id)),
    getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),
    getTaskStatusAsyncUserSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncUserSelectAction(appId, searchValue, callback)),
    // getTaskDetails: (Id) => dispatch(getTaskDetails(Id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTask)