import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import Async from "react-select/async"
import Select from "react-select"
import {
    clearCreateLeadsError,
    createLeadsSuccess,
    createNewLeads,
    getCrmUserAsyncSelect,
    getFollowActionAsyncSelect,
    getLeadCategoryAsyncSelect,
    getLeadSourceAsyncSelect
} from "../../../../store/Modules/crm/Leads/actions";
import {
    QUALIFICATIONLEVELOPTIONS
} from "../../../../store/Modules/crm/Leads/constants";
import FormValidator from '../../../../helper/formValidator';
import { getLeadMandatoryFieldsList } from "../../../../store/Modules/crm/Settings/LeadmandatoryFields/actions";

const CreateLeads = (props) => {
    const { errorMessage, isLeadsCreatePending, leadMandatoryList, filteredData } = props;
    const [formFields, setFormFields] = useState();
    const [errors, setErrors] = useState();
    const [mandatoryFields, setMandatoryFields] = useState([]);
    const [autoFocus, setAutoFocus] = useState(true);

    useEffect(() => {
        props.clearCreateLeadsError();
        props.createLeadsSuccess();
        props.getLeadMandatoryFieldsList();
    }, [])

    useEffect(() => {
        if (leadMandatoryList) {
            const mandatoryFields = leadMandatoryList.filter(field => field.IsMandatory === true);
            const mandatoryFieldNames = mandatoryFields.map(field => field.FieldName);
            console.log(mandatoryFields);
            setMandatoryFields(mandatoryFieldNames);
        }
    }, [leadMandatoryList])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        let result = {};
        if (name === 'email') {
            console.log("email: ", value);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (value === "") {
                result = {
                    isValid: true,
                    message: null,
                };
            } else {
                result = {
                    isValid: emailRegex.test(value),
                    message: 'Enter valid email',
                };
            }
        }
        else if (name === 'phoneNo') {
            if (value) {
                const contactNumberRegex = /^\d{10}$/;
                result = {
                    isValid: contactNumberRegex.test(value),
                    message: 'Enter valid mobile number',
                };
            }
        }
        else {
            result = FormValidator.validate(e.target);
        }
        console.log("result:", result);

        setFormFields({
            ...formFields,
            [name]: value || null
        })
        setErrors({
            ...errors,
            [name.toLowerCase()]: result.isValid ? null : result.message,
        })
    }

    const handleCategorySelect = (value) => {
        if (value === null) {
            setFormFields({
                ...formFields,
                categoryId: null,
            })
        } else {
            setFormFields({
                ...formFields,
                categoryId: value.value
            })
            setErrors({
                ...errors,
                categoryid: null
            })
        }
    }

    const handleUserSelect = (value) => {
        setFormFields({
            ...formFields,
            leadOwnerUserId: value.value
        })
        setErrors({
            ...errors,
            leadowneruserid: null
        })
    }

    const handleLeadeSource = (inputValue, callback) => {
        const { getLeadSourceAsyncSelect } = props;
        return getLeadSourceAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleFollowAction = (inputValue, callback) => {
        const { getFollowActionAsyncSelect } = props;
        return getFollowActionAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleLeadSourceSelect = (value) => {
        if (value === null) {
            setFormFields({
                ...formFields,
                leadSourceId: null
            })
        } else {
            setFormFields({
                ...formFields,
                leadSourceId: value.value
            })
            setErrors({
                ...errors,
                leadsourceid: null
            })
        }
    }

    const handleQualificationSelect = (value) => {
        if (value === null) {
            setFormFields({
                ...formFields,
                qualificationLevel: null
            })
        } else {
            setFormFields({
                ...formFields,
                qualificationLevel: value.value
            })
            setErrors({
                ...errors,
                qualificationlevel: null
            })
        }
    }

    const handleFollowUpSelect = (value) => {
        if (value === value) {
            setFormFields({
                ...formFields,
                followUpActionId: null
            })
        } else {
            setFormFields({
                ...formFields,
                followUpActionId: value.value
            })
            setErrors({
                ...errors,
                followupactionid: null
            })
        }
    }

    const handleUsers = (inputValue, callback) => {
        const { getCrmUserAsyncSelect } = props;
        return getCrmUserAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }

    const handleCategory = (inputValue, callback) => {
        const { getLeadCategoryAsyncSelect } = props;
        return getLeadCategoryAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleLeadCreate();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    const handleLeadCreate = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { leadName, leadWebsite, leadContact, leadDesignation, leadResponsibility,
            email, phoneNo, leadSourceId, qualificationLevel, leadOwnerUserId, categoryId,
            followUpActionId, description } = formFields || {};

        let currentErrors = {};

        // leadOwneruserId and leadname fields is mandatory in API ,so it is manually validated.
        if (!leadOwnerUserId) {
            currentErrors.leadowneruserid = "Lead owner is required";
        }
        if (!leadName) {
            currentErrors.leadname = "Lead name is required";
        }

        if (mandatoryFields && mandatoryFields.length > 0) {
            mandatoryFields.forEach((field) => {
                switch (field.toLowerCase()) {
                    case 'leadname':
                        if (!leadName) {
                            currentErrors.leadname = "Lead name is required";
                        }
                        break;
                    case 'leadwebsite':
                        if (!leadWebsite) {
                            currentErrors.leadwebsite = "Lead website is required";
                        }
                        break;
                    case 'leadcontact':
                        if (!leadContact) {
                            currentErrors.leadcontact = "Lead contact is required";
                        }
                        break;
                    case 'leaddesignation':
                        if (!leadDesignation) {
                            currentErrors.leaddesignation = "Lead designation is required";
                        }
                        break;
                    case 'leadresponsibility':
                        if (!leadResponsibility) {
                            currentErrors.leadresponsibility = "Lead responsibility is required";
                        }
                        break;
                    case 'email':
                        if (!email) {
                            currentErrors.email = "Email is required";
                        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                            currentErrors.email = "Enter valid email";
                        }
                        break;
                    case 'phoneno':
                        if (!phoneNo) {
                            currentErrors.phoneno = "Mobile number is required";
                        } else if (!/^\d{10}$/.test(phoneNo)) {
                            currentErrors.phoneno = "Enter valid mobile number";
                        }
                        break;
                    case 'leadsourceid':
                        if (!leadSourceId) {
                            currentErrors.leadsourceid = "Lead source is required";
                        }
                        break;
                    case 'qualificationlevel':
                        if (!qualificationLevel) {
                            currentErrors.qualificationlevel = "Qualification level is required";
                        }
                        break;
                    case 'leadowneruserid':
                        if (!leadOwnerUserId) {
                            currentErrors.leadowneruserid = "Lead owner is required";
                        }
                        break;
                    case 'categoryid':
                        if (!categoryId) {
                            currentErrors.categoryid = "Lead category is required";
                        }
                        break;
                    case 'followupactionid':
                        if (!followUpActionId) {
                            currentErrors.followupactionid = "Follow up action is required";
                        }
                        break;
                    case 'description':
                        if (!description) {
                            currentErrors.description = "Description is required";
                        }
                        break;
                    default:
                        break;
                }
            });
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.CreateLeads(formFields, filteredData);
        }
    }

    console.log('formfields', formFields);
    console.log('errors', errors);
    console.log('mandatoryFields', mandatoryFields);

    return (
        <div>
            <Form>
                {errorMessage &&
                    (<VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />)
                }
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-primary border-5 mb-3"> &nbsp;Lead Information</div>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Name <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                autoFocus={true}
                                type="text"
                                name="leadName"
                                placeHolder="Enter your lead name"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadName}
                                invalid={errors && errors.leadname}
                            />
                            <FormFeedback>{errors && errors.leadname}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Website {mandatoryFields.find((i) => i.toLowerCase() === 'leadWebsite'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadWebsite"
                                placeHolder="Enter your lead website"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadWebsite}
                                invalid={errors && errors.leadwebsite}
                            />
                            <FormFeedback>{errors && errors.leadwebsite}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Contact {mandatoryFields.find((i) => i.toLowerCase() === 'leadContact'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadContact"
                                placeHolder="Enter your lead contact"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadContact}
                                invalid={errors && errors.leadcontact}
                            />
                            <FormFeedback>{errors && errors.leadcontact}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Designation {mandatoryFields.find((i) => i.toLowerCase() === 'leadDesignation'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadDesignation"
                                placeHolder="Enter your lead designation"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadDesignation}
                                invalid={errors && errors.leaddesignation}
                            />
                            <FormFeedback>{errors && errors.leaddesignation}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Responsibility {mandatoryFields.find((i) => i.toLowerCase() === 'leadResponsibility'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadResponsibility"
                                placeHolder="Enter your lead responsibility"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadResponsibility}
                                invalid={errors && errors.leadresponsibility}
                            />
                            <FormFeedback>{errors && errors.leadresponsibility}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Email Address  {mandatoryFields.find((i) => i.toLowerCase() === 'email'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="email"
                                placeHolder="Enter your email address"
                                onChange={validateOnchange}
                                value={formFields && formFields.email}
                                invalid={errors && errors.email}
                            />
                            <FormFeedback>{errors && errors.email}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Mobile Number {mandatoryFields.find((i) => i.toLowerCase() === 'phoneNo'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                onKeyDown={
                                    (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                min='0'
                                type="number"
                                name="phoneNo"
                                placeHolder="Enter your mobile number"
                                onChange={validateOnchange}
                                value={formFields && formFields.phoneNo}
                                invalid={errors && errors.phoneno}
                            />
                            <FormFeedback>{errors && errors.phoneno}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Source {mandatoryFields.find((i) => i.toLowerCase() === 'leadSourceId'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.leadsourceid) ? 'red' : "#CED4DA"
                                    })
                                }}
                                name="leadSourceId"
                                defaultOptions
                                placeholder="Select your lead source"
                                loadOptions={handleLeadeSource}
                                onChange={handleLeadSourceSelect}
                                isClearable
                            />

                            {(errors && errors.leadsourceid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.leadsourceid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Qualification Level {mandatoryFields.find((i) => i.toLowerCase() === 'qualificationLevel'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.qualificationlevel) ? 'red' : "#CED4DA"
                                    })
                                }}
                                type="text"
                                name="qualificationLevel"
                                placeholder="Select your qualification level"
                                options={QUALIFICATIONLEVELOPTIONS}
                                onChange={handleQualificationSelect}
                                isClearable
                            />
                            {(errors && errors.qualificationlevel) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.qualificationlevel}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Owner <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.leadowneruserid) ? 'red' : "#CED4DA"
                                    })
                                }}
                                name="leadOwnerUserId"
                                defaultOptions
                                placeholder="Select your lead owner"
                                loadOptions={handleUsers}
                                onChange={handleUserSelect}
                            />
                            {(errors && errors.leadowneruserid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.leadowneruserid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Category {mandatoryFields.find((i) => i.toLowerCase() === 'categoryId'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.categoryid) ? 'red' : "#CED4DA"
                                    })
                                }}
                                name="categoryId"
                                defaultOptions
                                placeholder="Select your lead category"
                                loadOptions={handleCategory}
                                onChange={handleCategorySelect}
                                isClearable
                            />
                            {(errors && errors.categoryid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.categoryid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Follow Up Action {mandatoryFields.find((i) => i.toLowerCase() === 'followUpActionId'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.followupactionid) ? 'red' : "#CED4DA"
                                    })
                                }}
                                name="followUpActionId"
                                defaultOptions
                                placeholder="Select your follow up action"
                                loadOptions={handleFollowAction}
                                onChange={handleFollowUpSelect}
                                isClearable
                            />
                            {(errors && errors.followupactionid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.followupactionid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Description {mandatoryFields.find((i) => i.toLowerCase() === 'description'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="description"
                                placeHolder="Enter your description"
                                onChange={validateOnchange}
                                value={formFields && formFields.description}
                                invalid={errors && errors.description}
                            />
                            <FormFeedback>{errors && errors.description}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row >
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleLeadCreate}
                        disabled={isLeadsCreatePending}
                    >
                        {isLeadsCreatePending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form >
        </div >
    )
}

const mapStateToProps = (state) => ({
    errorMessage: state.crmLeads.leadsCreateErrorMessage,
    isLeadsCreateSuccess: state.crmLeads.isLeadsCreateSuccess,
    isLeadsCreatePending: state.crmLeads.isLeadsCreatePending,
    leadMandatoryList: state.LeadMandatory.leadMandatoryList,
})
const mapDispatchToProps = (dispatch) => ({
    getLeadMandatoryFieldsList: () => dispatch(getLeadMandatoryFieldsList()),
    CreateLeads: (data, filteredData) => dispatch(createNewLeads(data, filteredData)),
    clearCreateLeadsError: () => dispatch(clearCreateLeadsError()),
    createLeadsSuccess: () => dispatch(createLeadsSuccess()),
    getCrmUserAsyncSelect: (searchValue, callback) => dispatch(getCrmUserAsyncSelect(searchValue, callback)),
    getLeadCategoryAsyncSelect: (searchValue, callback) => dispatch(getLeadCategoryAsyncSelect(searchValue, callback)),
    getLeadSourceAsyncSelect: (searchValue, callback) => dispatch(getLeadSourceAsyncSelect(searchValue, callback)),
    getFollowActionAsyncSelect: (searchValue, callback) => dispatch(getFollowActionAsyncSelect(searchValue, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateLeads);