// import React, { useEffect, useState } from 'react'
// import VWButton from '../../../../components/vwButton/VWButton'
// import VWMessage from '../../../../components/vwMessage/vwMessage'
// import { Button, Col, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
// import { connect } from 'react-redux'
// import { clearForm } from '../../../../store/form/actions'
// import { clearCreateHolidayMasterError, createHoliday, createHolidayMasterActionSuccess } from '../../../../store/Modules/LeaveTracker/HolidayMaster/actions'

// const HolidayCreate = (props) => {
//     const [formFields, setFormFields] = useState({});
//     const [errors, setErrors] = useState({});
//     const { filteredData } = props;

//     useEffect(() => {
//         props.createHolidayMasterActionSuccess();
//     }, [])
//     const validateOnChange = (event) => {
//         const input = event.target;
//         const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
//         setFormFields({ ...formFields, [input.name]: value });
//         if (input.name === 'holidayName') {
//             setErrors({ ...errors, holidayName: '' });
//         }
//         if (input.name === 'holidayDate') {
//             setErrors({ ...errors, holidayDate: '' });
//         }
//     };


//     //--------------------------------------------Create ---------------------------------------------------------
//     const handleHolidayCreate = () => {
//         const newErrors = {};
//         if (!formFields.holidayName || !formFields.holidayName.trim()) {
//             newErrors.holidayName = 'Holiday Name is required';
//         }
//         if (!formFields.holidayDate || !formFields.holidayDate.trim()) {
//             newErrors.holidayDate = ' Holiday Date is required';
//         }

//         setErrors(newErrors);
//         if (Object.keys(newErrors).length === 0) {
//             props.createHolidayAction(formFields, filteredData);
//         }

//     };


//     console.log("formFields:", formFields);
//     return (
//         <React.Fragment>
//             {props.errorMessage && (
//                 <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
//             )}
//             <>
//                 <Row>
//                     <Col >
//                         <div className="form-group">
//                             <label className="col-form-label">Holiday Name <span className="text-danger">*</span></label>
//                             <Input
//                                 autoFocus={true}
//                                 type="text"
//                                 name="holidayName"
//                                 placeholder="Enter Holiday Name"
//                                 onChange={validateOnChange}
//                                 invalid={errors && errors.holidayName}
//                             />
//                             {<FormFeedback>{errors && errors.holidayName}</FormFeedback>}
//                         </div>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col>
//                         <div className="form-group">
//                             <label className="col-form-label">Holiday Date <span className="text-danger">*</span></label>
//                             <Input
//                                 type="date"
//                                 name="holidayDate"
//                                 placeholder="Select Holiday Date"
//                                 onChange={validateOnChange}
//                                 invalid={errors && errors.holidayDate}
//                             />
//                             {<FormFeedback>{errors && errors.holidayDate}</FormFeedback>}
//                         </div>
//                     </Col>
//                 </Row>
//                 <br></br>
//                 <div className="form-group">
//                     <FormGroup>
//                         <Button
//                             className="me-2"
//                             color="success"
//                             onClick={handleHolidayCreate}
//                             disabled={props.isPending}
//                         >
//                             {props.isPending ? <>
//                                 <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
//                                 &nbsp; Submitting...
//                             </>
//                                 : <>Submit</>}
//                         </Button>
//                         <Button
//                             color="danger"
//                             onClick={props.handleClose}
//                         >
//                             Cancel
//                         </Button>
//                     </FormGroup>
//                 </div>
//             </>
//         </React.Fragment>
//     )
// }
// const mapStateToProps = (state) => ({
//     form: state.form,
//     isPending: state.holidayMaster.isPending,
//     errorMessage: state.holidayMaster.errorMessage,
// });
// const mapDispatchToProps = (dispatch) => ({
//     clearFormAction: () => dispatch(clearForm()),
//     createHolidayAction: (formFields, filteredData) => dispatch(createHoliday(formFields, filteredData)),
//     createHolidayMasterActionSuccess: () => dispatch(createHolidayMasterActionSuccess()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(HolidayCreate)

import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { clearForm } from '../../../../store/form/actions';
import { createHoliday, createHolidayMasterActionSuccess } from '../../../../store/Modules/LeaveTracker/HolidayMaster/actions';

const HolidayCreate = (props) => {
    const [formFields, setFormFields] = useState({
        holidayName: '',
        holidayDate: ''
    });
    const [errors, setErrors] = useState({});
    const [autoFocus, setAutoFocus] = useState(true);
    // const formFieldsRef = useRef(formFields); 

    const { filteredData } = props;

    useEffect(() => {
        props.createHolidayMasterActionSuccess();
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);

            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);

                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                // Set autoFocus to true when Tab is pressed
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter' && autoFocus === false) {
                console.log('enter button');
                handleHolidayCreate();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        console.log('manual focus triggered');
        setAutoFocus(true);
    };

    const validateOnChange = (event) => {
        const { name, value } = event.target;
        const updatedFormFields = { ...formFields, [name]: value };

        setFormFields(updatedFormFields); // Update the form fields state
        // formFieldsRef.current = updatedFormFields; // Update the ref as well

        if (name === 'holidayName') {
            setErrors({ ...errors, holidayName: '' });
        }
        if (name === 'holidayDate') {
            setErrors({ ...errors, holidayDate: '' });
        }
    };

    const handleHolidayCreate = () => {
        // Update form fields in the ref before submitting
        // formFieldsRef.current = formFields;
        console.log('formFields:', formFields);

        const newErrors = {};
        if (!formFields.holidayName.trim()) {
            newErrors.holidayName = 'Holiday Name is required';
        }
        if (!formFields.holidayDate.trim()) {
            newErrors.holidayDate = 'Holiday Date is required';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            // Now submit the form data from the ref (which is always up to date)
            // props.createHolidayAction(formFieldsRef.current, filteredData);
            props.createHolidayAction(formFields, filteredData);
        }
    };

    return (
        <React.Fragment>
            {props.errorMessage && (
                <div className="alert alert-danger">{props.errorMessage}</div>
            )}
            <>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label className="col-form-label">Holiday Name <span className="text-danger">*</span></label>
                            <Input
                                autoFocus={autoFocus}
                                type="text"
                                name="holidayName"
                                placeholder="Enter Holiday Name"
                                value={formFields.holidayName} // Controlled value
                                onChange={validateOnChange}
                                invalid={errors && errors.holidayName}
                                onFocus={handleFocus}
                            />
                            <FormFeedback>{errors && errors.holidayName}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label className="col-form-label">Holiday Date <span className="text-danger">*</span></label>
                            <Input
                                type="date"
                                name="holidayDate"
                                placeholder="Select Holiday Date"
                                value={formFields.holidayDate} // Controlled value
                                onChange={validateOnChange}
                                invalid={errors && errors.holidayDate}
                                onFocus={handleFocus}
                            />
                            <FormFeedback>{errors && errors.holidayDate}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <br />
                <div className="form-group">
                    <FormGroup>
                        <Button
                            className="me-2"
                            color="success"
                            onClick={handleHolidayCreate}
                            disabled={props.isPending}
                        >
                            {props.isPending ? (
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                    &nbsp; Submitting...
                                </>
                            ) : (
                                <>Submit</>
                            )}
                        </Button>
                        <Button color="danger" onClick={props.handleClose}>
                            Cancel
                        </Button>
                    </FormGroup>
                </div>
            </>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    form: state.form,
    isPending: state.holidayMaster.isPending,
    errorMessage: state.holidayMaster.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    clearFormAction: () => dispatch(clearForm()),
    createHolidayAction: (formFields, filteredData) => dispatch(createHoliday(formFields, filteredData)),
    createHolidayMasterActionSuccess: () => dispatch(createHolidayMasterActionSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HolidayCreate);
