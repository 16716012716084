import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, Table } from 'reactstrap'
import VWModel from '../../../../components/Modal/VWModal'
import moment from 'moment';
import { clearForm } from '../../../../store/form/actions'
import AppMasterCreate from './AppMasterCreate'
import { clearAppMasterExportListError, closeCreateAppMasterModalAction, getAppMasterListAction, getExportAppMasterList, openCreateAppMasterModalAction } from '../../../../store/Modules/Admin/AppMaster/actions'
import { getPager } from '../../../../helper/common'
import VWPagination from '../../../../components/vwPagination/vwPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import NoRecord from '../../../../components/NoRecords/noRecords'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import VWSkeleton from '../../../../components/vwSkeleton/vwSkeleton'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { clearSetPath } from '../../../../store/actions'
import Select from 'react-select';
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from '../../../../helper/export'
import { APPMASTER_EXPORT_HEADERS, APPMASTER_EXPORT_HEADERS_SUPERADMIN } from '../../../../store/Modules/Admin/AppMaster/constants'
import { ElementToolTip } from '../../Projects/vwToolTip'

const statusOptions = [
    { label: 'Active', value: "true" },
    { label: 'Inactive', value: "false" },
]
const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const AppMaster = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { errorMessage, isAppMasterExportPending, AppMasterList, isAppMasterListPending, isAppMasterCreateModalOpen, AppMasterExportList, appMasterExportError } = props || {};
    const { userAppDetails } = props.currentProfile || {};

    let isSuperAdmin;
    {
        userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
            userAppDetails
                .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
                .map(userApp => {
                    const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                        const roleName = role.roleName.toLowerCase();
                        return roleName;
                    });
                    isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
                })
    }
    const buildQueryAppName = () => {
        const { AppName } = filter || {};
        let searchAppName = '';
        if (AppName) {
            searchAppName += `${AppName}`;
        }
        return searchAppName
    }
    const buildQueryId = () => {
        const { Id } = filter || {};
        let searchId = '';
        if (Id) {
            searchId += `${Id}`;
        }
        return searchId
    }
    const buildQueryLatestVersion = () => {
        const { LatestVersion } = filter || {};
        let searchLatestVersion = '';
        if (LatestVersion) {
            searchLatestVersion += `${LatestVersion}`;
        }
        return searchLatestVersion
    }
    const buildQueryCreatedDateTime = () => {
        const { CreatedDateTime } = filter || {};
        let searchCreatedDateTime = '';
        if (CreatedDateTime) {
            searchCreatedDateTime += `${CreatedDateTime}`;
        }
        return searchCreatedDateTime
    }
    const buildQueryIsActive = () => {
        const { IsActive } = filter || {};
        let searchIsActive = '';
        if (IsActive) {
            searchIsActive += `${IsActive}`;
        }
        return searchIsActive
    }
    const buildQueryorganizationCount = () => {
        const { organizationCount } = filter || {};
        let searchorganizationCount = '';
        if (organizationCount) {
            searchorganizationCount += `${organizationCount}`;
        }
        return searchorganizationCount
    }
    const buildQueryAssignedTimeStamp = () => {
        const { AssignedTimeStamp } = filter || {};
        let searchAssignedTimeStamp = '';
        if (AssignedTimeStamp) {
            searchAssignedTimeStamp += `${AssignedTimeStamp}`;
        }
        return searchAssignedTimeStamp
    }

    const setPage = (page = 1) => {
        props.getAppMasterList(isSuperAdmin, filter, page, PageSize, buildQueryAppName(), buildQueryId(), buildQueryLatestVersion(), buildQueryCreatedDateTime(), buildQueryIsActive(), buildQueryorganizationCount(), buildQueryAssignedTimeStamp());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.ClearPath();
        props.clearAppMasterExportListError();
    }, [])

    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedAppMasterList = [...props.AppMasterList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        if (sortOrder === 'organizationCount') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        });
    }

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    const searchStatus = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            IsActive: value,
        })
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])
    //-----------------------------------------Modal open & close-----------------------------------------------------
    const handleOpenAppMaster = () => {
        props.openCreateAppMasterModal();
    };
    const handleClose = () => {
        props.clearFormAction()
        props.closeCreateAppMasterModal();
    };
    //-------------------------------------------Roles ------------------------------------------------------------
    const { roles } = props.currentProfile || {};
    const Roles = roles && roles.map((data) => (
        data.roleName
    ))
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/admin/appmaster/details/${Id}`);
    };

    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getExportAppMasterList(isSuperAdmin, buildQueryAppName(), buildQueryId(), buildQueryLatestVersion(), buildQueryCreatedDateTime(), buildQueryIsActive(), buildQueryorganizationCount(), buildQueryAssignedTimeStamp())
            .then((response) => {
                if (response) {
                    if (IsSuperAdmin) {
                        const exporAppMasterExportList = [];
                        const headers = APPMASTER_EXPORT_HEADERS_SUPERADMIN;
                        const headerSizes = [30, 20, 20, 20, 20, 20, 20, 30, 20, 20, 20, 20, 20];
                        const headerExcel = [
                            { headerName: "APP NAME", key: "appName", headerWidhth: 30 },
                            { headerName: "ORGANIZATION COUNT", key: "organizationCount", headerWidhth: 30 },
                            { headerName: "LATEST VERSION", key: "latestVersion", headerWidhth: 30 },
                            { headerName: "CREATED DATE", key: "createdDateTime", headerWidhth: 30 },
                            { headerName: "STATUS", key: "IsActive", headerWidhth: 30 },
                        ]
                        response && response.forEach((element) => {
                            exporAppMasterExportList.push({
                                appName: element.appName || "N/A",
                                organizationCount: element.organizationCount || 0,
                                latestVersion: element.latestVersion || "N/A",
                                createdDateTime: element.createdDateTime ? moment(element.createdDateTime).format('DD MMM YYYY') : 'N/A' || "N/A",
                                IsActive: element.isActive ? "Active" : "Inactive",
                            });
                        });
                        // excelPrint(exporAppMasterExportList, "Appmaster-List", headers, headerSizes);
                        excelSheetExport(headerExcel, exporAppMasterExportList, "Appmaster-List");
                    } else {
                        const exporAppMasterExportList = [];
                        const headers = APPMASTER_EXPORT_HEADERS;
                        const headerSizes = [30, 20, 20, 20, 20, 20, 20, 30, 20, 20, 20, 20, 20];
                        const headerExcel = [
                            { headerName: "APP NAME", key: "appName", headerWidhth: 30 },
                            { headerName: "LATEST VERSION", key: "latestVersion", headerWidhth: 30 },
                            { headerName: "ASSIGNED DATE", key: "assignedTimeStamp", headerWidhth: 30 },
                            { headerName: "STATUS", key: "IsActive", headerWidhth: 30 },
                        ]
                        response && response.forEach((element) => {
                            exporAppMasterExportList.push({
                                appName: element.appName || "N/A",
                                latestVersion: element.latestVersion || "N/A",
                                assignedTimeStamp: element.assignedTimeStamp ? moment(element.assignedTimeStamp).format('DD MMM YYYY') : 'N/A' || "N/A",
                                IsActive: element.isActive ? "Active" : "Inactive",

                            });
                        });
                        excelSheetExport(headerExcel, exporAppMasterExportList, "Appmaster-List");
                        // excelPrint(exporAppMasterExportList, "Appmaster-List", headers, headerSizes);
                    }
                }
            })
    }




    console.log("currentProfile:", props.currentProfile);
    console.log("isSuperAdmin:", isSuperAdmin);
    console.log("AppMasterList:", props.AppMasterList);
    console.log("AppMasterExportList:", props.AppMasterExportList);

    let IsSuperAdmin;
    {
        userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
            userAppDetails
                .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
                .map(userApp => {
                    const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                        const roleName = role.roleName.toLowerCase();
                        return roleName;
                    });
                    IsSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
                })
    }

    console.log("IsSuperAdmin:", IsSuperAdmin);

    return (
        <React.Fragment>
            <div className="page-content">
                <>
                    <Card >
                        <CardTitle
                            tag="h4"
                            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                        >
                            <strong className='d-flex align-items-center'> APP MASTER -&nbsp;<Badge color="danger" id={'appMaster'} style={{ cursor: 'pointer' }}>{props.isAppMasterListPending ? 0 : (props && props.totalCount)}</Badge>
                                <ElementToolTip id={'appMaster'} name={'Apps Count'} />&nbsp;</strong>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
                                userAppDetails
                                    .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
                                    .map(userApp => {
                                        const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                            const roleName = role.roleName.toLowerCase();
                                            return roleName;
                                        });
                                        const isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;

                                        return (
                                            <div>
                                                <React.Fragment key={userApp.id}>
                                                    {/* {isSuperAdmin ? (
                                                        <Link
                                                            type="button"
                                                            className="btn btn-info"
                                                            onClick={handleOpenAppMaster}
                                                        >
                                                            CREATE
                                                        </Link>
                                                    ) : null}&nbsp; */}
                                                    <Button
                                                        className="me-4"
                                                        color="success"
                                                        onClick={handleExports}
                                                        disabled={AppMasterList && AppMasterList.length <= 0 || isAppMasterExportPending}
                                                    >
                                                        {isAppMasterExportPending ? 'EXPORTING...' : 'EXPORT'}
                                                    </Button>
                                                </React.Fragment>
                                            </div>
                                        );
                                    })
                            }
                        </CardTitle>
                    </Card>
                    <Card>
                        <CardBody className="p-4">
                            {(errorMessage || appMasterExportError) ?
                                < div className='w-100'>
                                    {(errorMessage || appMasterExportError) && (
                                        <VWMessage type="danger" defaultMessage={errorMessage || appMasterExportError} baseClassName="alert" ></VWMessage>
                                    )}
                                </div> : ""}
                            <div style={{ height: "60vh", overflowX: "auto" }}>
                                <Table className="table table-striped table-hover table-nowrap w-100" size="sm" style={style.table}>
                                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                        <tr>
                                            <th style={{ minWidth: '150px' }} className='hand-cursor'
                                                onClick={() => handleSort('AppName')}
                                            >
                                                App Name &nbsp;
                                                {sortOrder === 'AppName' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}
                                            </th>
                                            {isSuperAdmin ?
                                                <th style={{ minWidth: '150px' }} className='hand-cursor'
                                                    onClick={() => handleSort('organizationCount')}
                                                >
                                                    Organization Count &nbsp;
                                                    {sortOrder === 'organizationCount' && (
                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                    )}
                                                </th> : ''}
                                            <th style={{ minWidth: '150px' }} className='hand-cursor'
                                                onClick={() => handleSort('LatestVersion')}
                                            >
                                                Latest Version &nbsp;
                                                {sortOrder === 'LatestVersion' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}
                                            </th>
                                            {isSuperAdmin ?
                                                <th style={{ minWidth: '150px' }} className='hand-cursor'
                                                    onClick={() => handleSort('CreatedDateTime')}
                                                >
                                                    Created Date  &nbsp;
                                                    {sortOrder === 'CreatedDateTime' && (
                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                    )}
                                                </th>
                                                :
                                                <th style={{ minWidth: '150px' }} className='hand-cursor'
                                                    onClick={() => handleSort('AssignedTimeStamp')}
                                                >
                                                    Assigned Date  &nbsp;
                                                    {sortOrder === 'AssignedTimeStamp' && (
                                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                    )}
                                                </th>
                                            }
                                            <th style={{ minWidth: '150px' }} className='hand-cursor'
                                                onClick={() => handleSort('IsActive')}
                                            >
                                                Status  &nbsp;
                                                {sortOrder === 'IsActive' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}
                                            </th>
                                            <th className='sticky-column' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                                View
                                            </th>
                                        </tr>
                                        <tr>
                                            <th >
                                                <Input
                                                    type='text'
                                                    placeholder='Search'
                                                    name='AppName'
                                                    onChange={searchInputValidation}
                                                    value={filter && filter.AppName}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </th>
                                            {isSuperAdmin ?
                                                <th >
                                                    <Input
                                                        onKeyDown={
                                                            (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        type='number'
                                                        min='0'
                                                        placeholder='Search'
                                                        name='organizationCount'
                                                        onChange={searchInputValidation}
                                                        value={filter && filter.organizationCount}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </th> : ''}
                                            <th >
                                                <Input
                                                    type='text'
                                                    placeholder='Search'
                                                    name='LatestVersion'
                                                    onChange={searchInputValidation}
                                                    value={filter && filter.LatestVersion}
                                                    onKeyPress={handleKeyPress}
                                                /></th>
                                            {isSuperAdmin ?
                                                <th >
                                                    <Input
                                                        type='date'
                                                        // className='text-center'
                                                        placeholder='Search'
                                                        name='CreatedDateTime'
                                                        onChange={searchInputValidation}
                                                        value={filter && filter.CreatedDateTime}
                                                        onKeyPress={handleKeyPress}
                                                        max="9999-12-31"
                                                    /></th>
                                                :
                                                <th >
                                                    <Input
                                                        type='date'
                                                        // className='text-center'
                                                        placeholder='Search'
                                                        name='AssignedTimeStamp'
                                                        onChange={searchInputValidation}
                                                        value={filter && filter.AssignedTimeStamp}
                                                        onKeyPress={handleKeyPress}
                                                        max="9999-12-31"
                                                    /></th>
                                            }
                                            <th>
                                                <Select
                                                    styles={customStyles}
                                                    name="IsActive"
                                                    isClearable
                                                    defaultOptions
                                                    placeholder="Search"
                                                    options={statusOptions}
                                                    onChange={searchStatus}
                                                />
                                            </th>
                                            <th className='sticky-column' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ position: 'relative' }}>
                                        {!isAppMasterListPending && (AppMasterList && AppMasterList.length > 0) ?
                                            sortedAppMasterList && sortedAppMasterList.map((data) => (
                                                <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                    <td className='ps-3'>{data.AppName || "N/A"}</td>
                                                    {isSuperAdmin ?
                                                        <td className='ps-5'>{data.organizationCount || 0}</td> : ''}
                                                    <td className='ps-5'>{data.LatestVersion || "N/A"}</td>
                                                    {isSuperAdmin ?
                                                        <td className='ps-3'>{data.CreatedDateTime ? moment(data.CreatedDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                                                        :
                                                        <td className='ps-3'>{data.AssignedTimeStamp ? moment(data.AssignedTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                                                    }
                                                    <td className='ps-3'>
                                                        {data.IsActive ? (
                                                            <Badge color="success" className="text-dark-white">
                                                                Active
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger" className="text-dark-white">
                                                                Inactive
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                                                        type="button"
                                                        className="btn btn-sm  btn-light"
                                                        to={`/admin/appmaster/details/${data.Id}`}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                    </Link></td>
                                                </tr>
                                            ))
                                            : isAppMasterListPending ?
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="d-flex justify-content-center mt-2">
                                                            <VWSpinner />
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td colSpan={50}>
                                                        <NoRecord />
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter>
                            {AppMasterList && AppMasterList.length > 0 ?
                                <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                            }
                        </CardFooter>
                    </Card>
                    <VWModel
                        header="CREATE APP MASTER"
                        isOpen={isAppMasterCreateModalOpen}
                        handleClose={handleClose}
                    >
                        <AppMasterCreate
                            handleClose={handleClose}
                            data={isSuperAdmin}
                            filteredData={filter}
                        />
                    </VWModel>
                </>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    isAppMasterCreateModalOpen: state.appMaster.isCreateAppMasterModalOpen,
    AppMasterList: state.appMaster.AppMasterList,
    errorMessage: state.appMaster.appMasterListError,
    totalCount: state.appMaster.totalRecords,
    isAppMasterListPending: state.appMaster.isAppMasterListPending,
    AppMasterExportList: state.appMaster.AppMasterExportList,
    isAppMasterExportPending: state.appMaster.isAppMasterExportPending,
    appMasterExportError: state.appMaster.appMasterExportError,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    getAppMasterList: (isSuperAdmin, filter, page, PageSize, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, searchorganizationCount, searchAssignedTimeStamp) => dispatch(getAppMasterListAction(isSuperAdmin, filter, page, PageSize, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, searchorganizationCount, searchAssignedTimeStamp)),
    getExportAppMasterList: (isSuperAdmin, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, searchorganizationCount, searchAssignedTimeStamp) => dispatch(getExportAppMasterList(isSuperAdmin, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, searchorganizationCount, searchAssignedTimeStamp)),
    closeCreateAppMasterModal: () => dispatch(closeCreateAppMasterModalAction()),
    openCreateAppMasterModal: () => dispatch(openCreateAppMasterModalAction()),
    clearFormAction: () => dispatch(clearForm()),
    ClearPath: () => dispatch(clearSetPath()),
    clearAppMasterExportListError: () => dispatch(clearAppMasterExportListError()),
});


export default connect(mapStateToProps, mapDispatchToProps)(AppMaster)