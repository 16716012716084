import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Badge, Button, Card, CardFooter, CardTitle, Input, Table } from 'reactstrap'
import { clearHolidayMasterExportListError, closeCreateHolidayModalAction, closeDeleteHolidayModalAction, getHolidaymasterExport, getHolidayMasterListAction, openCreateHolidayModalAction, openDeleteHolidayModalAction } from '../../../../store/Modules/LeaveTracker/HolidayMaster/actions'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import NoRecord from '../../../../components/NoRecords/noRecords'
import { getPager } from '../../../../helper/common'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import VWPagination from '../../../../components/vwPagination/vwPagination'
import VWModel from '../../../../components/Modal/VWModal'
import HolidayCreate from './holidayCreate'
import HolidayDelete from './holidayDelete'
import { clearForm } from '../../../../store/form/actions'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { clearSetPath } from '../../../../store/actions'
import { excelPrint, excelSheetExport } from '../../../../helper/export'
import { HOLIDAY_MASTER_EXPORT_HEADERS } from '../../../../store/Modules/LeaveTracker/HolidayMaster/constants'

const HolidayMasterList = (props) => {
    const { userAppDetails } = props.currentProfile;
    const { isHolidayMasterExportPending, holidayMasterExportListError } = props;

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const [deleteList, setDeleteList] = useState({});


    const buildHolidayName = () => {
        const { HolidayName } = filter || {};
        let searchbuildHolidayName = '';
        if (HolidayName) {
            searchbuildHolidayName += `${HolidayName}`;
        }
        return (searchbuildHolidayName) || ''
    }
    const buildHolidayDate = () => {
        const { HolidayDate } = filter || {};
        let searchbuildHolidayDate = '';
        if (HolidayDate) {
            searchbuildHolidayDate += `${HolidayDate}`;
        }
        return (searchbuildHolidayDate) || ''
    }

    //---------------------------------------------------------------------------------------------------------------
    const setPage = (page = 1) => {
        props.getHolidayMasterList(filter, page, PageSize, buildHolidayName(), buildHolidayDate());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.ClearPath();
        props.clearHolidayMasterExportListError();
    }, [])

    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    // ----------------------------------------SORTING------------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedholidaysList = [...props.HolidayMasterList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //-----------------------------------------Modal open & close-----------------------------------------------------

    const handleOpenHolidayApply = () => {
        console.log("open modal")
        props.openCreateHolidayModal();
    };
    const handleClose = () => {
        props.clearFormAction()
        props.closeCreateHolidayModal();
        props.closeDeleteHolidayModal();
    };
    //---------------------------------------Delete List-------------------------------------------------------------

    const selectOnChange = (value, Id) => {

        setDeleteList({
            ...deleteList,
            holidayId: Id
        })

        props.openDeleteHolidayModal();
    }



    console.log("HolidayMasterList:", props.HolidayMasterList);
    console.log("isCreateHolidayModalOpen:", props.isCreateHolidayModalOpen);
    console.log("isDeleteHolidayMasterModalOpen:", props.isDeleteHolidayMasterModalOpen);
    let isAdministrator;
    {
        (userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "leavetracker").length > 0 &&
            userAppDetails
                .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "leavetracker")
                .map(userApp => {
                    const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                        const roleName = role.roleName.toLowerCase();
                        return roleName;
                    });
                    isAdministrator = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
                }))
    }

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/leavetracker/holidaymaster/details/${Id}`);
    };


    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getHolidaymasterExport(buildHolidayName(), buildHolidayDate())
            .then((response) => {
                if (response) {
                    const exportHolidayMasterExportList = [];
                    const headerExcel = [
                        { headerName: "HOLIDAY NAME", key: "holidayName", headerWidhth: 30 },
                        { headerName: "DATE", key: "holidayDate", headerWidhth: 50 },
                    ]
                    response && response.forEach((element) => {
                        exportHolidayMasterExportList.push({
                            holidayName: element.holidayName || "N/A",
                            holidayDate: element.holidayDate ? moment(element.holidayDate).format('DD MMM YYYY') : 'N/A',
                        });
                    });
                    // excelPrint(exportHolidayMasterExportList, "Holiday Master-List", headers, headerSizes);
                    excelSheetExport(headerExcel, exportHolidayMasterExportList, "Holiday Master-List");
                }
            })
    }

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    return (
        <div className="page-content">
            <Card >
                <CardTitle
                    tag="h4"
                    className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                >
                    <strong>HOLIDAY MASTER -&nbsp;<Badge color="danger">{props.isHolidayMasterPending ? 0 : (props && props.totalCount)}</Badge></strong>
                    <div>
                        {isAdministrator ?
                            <Link
                                type="button"
                                className="btn btn-info"
                                onClick={handleOpenHolidayApply}
                            >
                                CREATE
                            </Link>
                            : ''
                        }
                        &nbsp; <Button
                            className="me-4"
                            color="success"
                            onClick={handleExports}
                            disabled={props.HolidayMasterList && props.HolidayMasterList.length <= 0 || isHolidayMasterExportPending}
                        >
                            {isHolidayMasterExportPending ? 'EXPORTING...' : 'EXPORT'}
                        </Button>
                    </div>
                </CardTitle>
            </Card>
            <Card>
                {(props.errorMessage || props.holidayMasterExportListError) ? <div className='w-100'>
                    {props.errorMessage && (
                        <VWMessage type="danger" defaultMessage={props.errorMessage || props.holidayMasterExportListError} baseClassName="alert" ></VWMessage>
                    )}
                </div> : ""}
                <div style={{ height: "60vh", overflowX: "auto" }}>
                    <Table className="table table-striped table-hover table-nowrap w-100 " style={style.table}>
                        <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                            <tr>
                                <th
                                    onClick={() => handleSort('HolidayName')} className='hand-cursor'
                                >
                                    Name &nbsp;
                                    {sortOrder === 'HolidayName' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}
                                </th>
                                <th
                                    onClick={() => handleSort('HolidayDate')} className='hand-cursor'
                                >
                                    Date &nbsp;
                                    {sortOrder === 'HolidayDate' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}
                                </th>
                                {isAdministrator ? (
                                    <th>Delete</th>) : ''}
                                <th >
                                    View
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <Input
                                        type='text'
                                        placeholder='Search'
                                        name='HolidayName'
                                        onChange={searchInputValidation}
                                        value={filter && filter.HolidayName}
                                        onKeyPress={handleKeyPress}
                                    /></th>
                                <th >
                                    <Input
                                        type='date'
                                        placeholder='Search'
                                        name='HolidayDate'
                                        onChange={searchInputValidation}
                                        value={filter && filter.HolidayDate}
                                        onKeyPress={handleKeyPress}
                                        max="9999-12-31"
                                    /></th>
                                <th></th>
                                {isAdministrator ? (
                                    <th></th>) : ''}
                            </tr>
                        </thead>
                        <tbody>
                            {!props.isHolidayMasterPending && (props.HolidayMasterList && props.HolidayMasterList.length > 0) ?
                                sortedholidaysList && sortedholidaysList.map((data) => (
                                    <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                        <td className='ps-5'>{data.HolidayName || 'N/A'}</td>
                                        <td className='ps-5'>{data.HolidayDate ? moment(data.HolidayDate).format('DD MMM YYYY') : 'N/A'}</td>
                                        {isAdministrator ? (
                                            <td>
                                                <Button type="submit" color="danger" size="sm"
                                                    onClick={(e) => { selectOnChange(e, data.Id) }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </td>) : ''}
                                        <td> <Link
                                            type="button"
                                            className="btn btn-sm  btn-light"
                                            to={`/leavetracker/holidaymaster/details/${data.Id}`}
                                        >
                                            <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                        </Link></td>

                                    </tr>
                                ))
                                : props.isHolidayMasterPending ?
                                    <div className="sticky-spinner-row">
                                        <div className="position-relative">
                                            <div className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '60%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                    width: '100vw',
                                                    height: '100vh',
                                                    pointerEvents: 'none',
                                                }}>
                                                <VWSpinner />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="sticky-spinner-row">
                                        <div className="position-relative">
                                            <div className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '60%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                    width: '100vw',
                                                    height: '100vh',
                                                    pointerEvents: 'none',
                                                }}>
                                                <NoRecord />
                                            </div>
                                        </div>
                                    </div>
                            }
                        </tbody>
                    </Table>
                </div>
                <CardFooter>
                    {props.HolidayMasterList && props.HolidayMasterList.length > 0 ?
                        <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                    }
                </CardFooter>
            </Card>
            <VWModel
                header="CREATE HOLIDAY"
                isOpen={props.isCreateHolidayModalOpen}
                handleClose={handleClose}
            >
                <HolidayCreate
                    handleClose={handleClose}
                    filteredData={filter}
                />
            </VWModel>
            <VWModel
                modalSize="small"
                header="DELETE HOLIDAY"
                isOpen={props.isDeleteHolidayMasterModalOpen}
                handleClose={handleClose}
            >
                <HolidayDelete
                    data={deleteList}
                    handleClose={handleClose}
                    filteredData={filter}
                />
            </VWModel>
        </div >
    )
}
const mapStateToProps = (state) => ({
    HolidayMasterList: state.holidayMaster.HolidayMasterList,
    errorMessage: state.holidayMaster.holidayMasterListError,
    totalCount: state.holidayMaster.totalRecords,
    isHolidayMasterPending: state.holidayMaster.isHolidayMasterPending,
    isCreateHolidayModalOpen: state.holidayMaster.isCreateHolidayMasterModalOpen,
    isDeleteHolidayMasterModalOpen: state.holidayMaster.isDeleteHolidayMasterModalOpen,
    HolidayMasterExportList: state.holidayMaster.HolidayMasterExportList,
    isHolidayMasterExportPending: state.holidayMaster.isHolidayMasterExportPending,
    holidayMasterExportListError: state.holidayMaster.holidayMasterExportListError,
    currentProfile: state.login.currentProfile,

});
const mapDispatchToProps = (dispatch) => ({
    getHolidayMasterList: (filter, page, PageSize, searchbuildHolidayName, searchbuildHolidayDate) => dispatch(getHolidayMasterListAction(filter, page, PageSize, searchbuildHolidayName, searchbuildHolidayDate)),
    getHolidaymasterExport: (searchbuildHolidayName, searchbuildHolidayDate) => dispatch(getHolidaymasterExport(searchbuildHolidayName, searchbuildHolidayDate)),
    closeCreateHolidayModal: () => dispatch(closeCreateHolidayModalAction()),
    openCreateHolidayModal: () => dispatch(openCreateHolidayModalAction()),
    openDeleteHolidayModal: () => dispatch(openDeleteHolidayModalAction()),
    closeDeleteHolidayModal: () => dispatch(closeDeleteHolidayModalAction()),
    clearHolidayMasterExportListError: () => dispatch(clearHolidayMasterExportListError()),
    clearFormAction: () => dispatch(clearForm()),
    ClearPath: () => dispatch(clearSetPath())
});
export default connect(mapStateToProps, mapDispatchToProps)(HolidayMasterList)