import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SidebarData from "./SidebarData"
import EmployeeSidebarData from './EmployeeSidebarData'
import {
  LeaveTrackerSidebarDataAdmin,
  LeaveTrackerSidebarDataManager,
  LeaveTrackerSidebarDataUser,
  LeaveTrackerSidebarDataManagerUser
} from "./LeaveTrackerSidebar";
import PayRollSidebarData from "./PayRollSidebarData";
import {
  ProjectsAdminSidebarData,
  ProjectsGlobalReadOnlyuserSidebarData,
  ProjectsUserSidebarData
} from "./ProjectsSidebarData";
import withRouter from "../../components/Common/withRouter";
import {
  AdminSidebarDataSuperAdmin,
  AdminSidebarDataAdministrator,
  AdminSidebarDataMultiAdministrator
} from "./AdminSidebarData";
import ProfileSidebarData from "./ProfileSidebarData";
import VisionDocsSidebar from "./VisionDocsSidebarData";
import {
  MassMailerSidebarData,
  MassMailerUserSidebarData
} from "./MassMailerSideBarData";
import {
  TimeSheetsSidebarData,
  TimeSheetsSidebarAdminData,
  TimeSheetsSidebarUserManagerData,
  TimeSheetsSidebarAdminManagerData,
  TimeSheetsSidebarUserProjectManager,
  TimeSheetsSidebarAdminProjectManagerData,
  TimeSheetsSidebarAdminBothManagerData,
  TimeSheetsSidebarUserBothManager
} from './TimeSheetsSidebarData'
import DBSyncSidebar from "./DBSyncSidebar";
import {
  CrmUserSidebarData,
  CrmAdminSidebarData
} from "./CrmSidebarData"

const Sidebar = (props) => {
  const ref = useRef();
  const [sidebarItems, setSidebarItems] = useState();
  const [activePage, setActivePage] = useState();
  const [activeUrl, setActiveUrl] = useState();
  const { isManager, isProjectManager, isGlobalReadOnlyUser } = props.currentProfile || false;
  const { userAppDetails } = props.currentProfile || [];

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    // Rest of the activation logic...
  }, []);

  const removeActivation = (items) => {
    // Removal of activation logic...
  };

  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    // console.log("pathName:", pathName);
    const params = pathName.split('/')[1];
    // console.log('params:', params);
    setActivePage(params);
    const fullPath = `/${params}`;
    setActiveUrl(pathName);
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu-item");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    if (fullPath === "/employees") {
      setSidebarItems(EmployeeSidebarData);
    }

    else if (fullPath === "/leavetracker") {
      const LeaveTrackerApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "leavetracker");

      if (LeaveTrackerApps && LeaveTrackerApps.length > 0) {
        LeaveTrackerApps && LeaveTrackerApps.forEach(userApp => {
          const leaveTrackerRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
            const roleName = role.roleName.toLowerCase();
            return roleName;
          });
          const isAdmin = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
          const isUser = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "user").length > 0;
          if ((isManager) && (isAdmin)) {
            console.log("manager and admin");
            setSidebarItems(LeaveTrackerSidebarDataManager);
          }
          else if ((isManager) && (isUser)) {
            console.log("manager and user");
            setSidebarItems(LeaveTrackerSidebarDataManagerUser);
          }
          else if (isAdmin) {
            console.log("admin");
            setSidebarItems(LeaveTrackerSidebarDataAdmin);
          }
          else {
            // const isUser = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "user").length > 0;
            if (isUser) {
              console.log("user");
              setSidebarItems(LeaveTrackerSidebarDataUser);
            }
          }
        });
      }
    }
    else if (fullPath === "/timesheets") {
      const timesheetsApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "timesheets");

      if (timesheetsApps && timesheetsApps.length > 0) {
        timesheetsApps && timesheetsApps.forEach(userApp => {
          const leaveTrackerRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
            const roleName = role.roleName.toLowerCase();
            return roleName;
          });
          const isAdmin = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
          const isUser = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "user").length > 0;
          if ((isManager) && (isAdmin) && (isProjectManager)) {
            setSidebarItems(TimeSheetsSidebarAdminBothManagerData);
          }
          else if ((isManager) && (isAdmin)) {
            setSidebarItems(TimeSheetsSidebarAdminManagerData);
          }
          else if ((isAdmin) && (isProjectManager)) {
            setSidebarItems(TimeSheetsSidebarAdminProjectManagerData);
          }
          else if (isAdmin) {
            setSidebarItems(TimeSheetsSidebarAdminData);
          }
          else if ((isManager) && (isUser) && (isProjectManager)) {
            setSidebarItems(TimeSheetsSidebarUserBothManager);
          }
          else if ((isManager) && (isUser)) {
            setSidebarItems(TimeSheetsSidebarUserManagerData);
          }
          else if ((isProjectManager) && (isUser)) {
            setSidebarItems(TimeSheetsSidebarUserProjectManager);
          }
          else {
            if (isUser) {
              setSidebarItems(TimeSheetsSidebarData);
            }
          }
        });
      }
    }

    else if (fullPath === "/salary") {
      setSidebarItems(PayRollSidebarData);
    }
    else if (fullPath === "/projectportal") {
      const crmApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "projectportal");

      if (crmApps && crmApps.length > 0) {
        crmApps && crmApps.forEach(userApp => {
          const leaveTrackerRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
            const roleName = role.roleName.toLowerCase();
            return roleName;
          });
          const isAdmin = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
          const isUser = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "user").length > 0;
          if (isAdmin) {
            setSidebarItems(ProjectsAdminSidebarData);
          }
          else if (isGlobalReadOnlyUser) {
            setSidebarItems(ProjectsGlobalReadOnlyuserSidebarData);
          }
          else if (isUser) {
            setSidebarItems(ProjectsUserSidebarData);
          }
        });
      }
    }

    else if (fullPath === "/visiondocs") {
      setSidebarItems(VisionDocsSidebar);
    }
    else if (fullPath === "/crm") {
      const crmApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "crm");

      if (crmApps && crmApps.length > 0) {
        crmApps && crmApps.forEach(userApp => {
          const leaveTrackerRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
            const roleName = role.roleName.toLowerCase();
            return roleName;
          });
          const isAdmin = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
          const isUser = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "user").length > 0;
          if (isAdmin) {
            setSidebarItems(CrmAdminSidebarData);
          }
          else if (isUser) {
            setSidebarItems(CrmUserSidebarData);
          }
        });
      }
    }
    else if (fullPath === "/admin") {

      let isSuperAdmin;
      let isAdministrator;
      let multiRole;
      if (
        userAppDetails &&
        userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
        userAppDetails
          .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
          // eslint-disable-next-line
          .map(userApp => {
            const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
              const roleName = role.roleName.toLowerCase();
              console.log("roleName:", roleName);
              return roleName;
            });
            isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
            isAdministrator = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
            // multiRole = adminRoles.filter(role => (role.roleName.toLowerCase() === "administrator" && role.roleName.toLowerCase() === "super admin")).length > 0;
          })
      ) {
        if ((isAdministrator) && (isSuperAdmin)) {
          console.log("multiRole", multiRole);
          setSidebarItems(AdminSidebarDataMultiAdministrator);
        }
        else if (isAdministrator) {
          console.log("isAdministrator", isAdministrator);
          setSidebarItems(AdminSidebarDataAdministrator);
        }
        else if (isSuperAdmin) {
          console.log("isSuperAdmin", isSuperAdmin);
          setSidebarItems(AdminSidebarDataSuperAdmin);
        }

      }
    }
    else if (fullPath === "/profile") {
      setSidebarItems(ProfileSidebarData);
    }
    else if (fullPath === "/massmailer") {
      const crmApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "massmailer");

      if (crmApps && crmApps.length > 0) {
        crmApps && crmApps.forEach(userApp => {
          const leaveTrackerRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
            const roleName = role.roleName.toLowerCase();
            return roleName;
          });
          const isAdmin = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
          const isUser = leaveTrackerRoles.filter(role => role.roleName.toLowerCase() === "user").length > 0;
          if (isAdmin) {
            setSidebarItems(MassMailerSidebarData);
          }
          else if (isUser) {
            setSidebarItems(MassMailerUserSidebarData);
          }
        });
      }
    }
    else if (fullPath === "/dbsync") {
      setSidebarItems(DBSyncSidebar);
    }

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
      console.log('matchingMenuItem:', matchingMenuItem);

    }
    // eslint-disable-next-line
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu-item");
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    activeMenu();
  }, [activeMenu]);

  // eslint-disable-next-line
  function scrollElement(item) {
    // Scroll element logic...
  }

  const OAuth_apiUrl = window.__ENV && window.__ENV.OAuth_apiUrl;
  const client_id = window.__ENV && window.__ENV.client_id;

  const OAuthLogout = () => {
    const OAuthLogout = `${OAuth_apiUrl}/v2/logout?client_id=${client_id}&returnTo=${encodeURIComponent(window.location.origin)}`
    // await fetch(OAuthLogout)
    // navigate(OAuthLogout)
    window.location.replace(OAuthLogout)
  }
  console.log("timesheetApprovalTotalRecords:", props.timesheetApprovalTotalRecords);

  const handleTabClick = (url) => {
    if (activeUrl === url) {
      window.location.reload();
    } else {
      setActiveUrl(url);
    }
  };
  console.log('sidebarItems:', sidebarItems);

  if (!props.isAuth) {
    OAuthLogout();
    return <Navigate to='/' />;
  } else {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu-item">
                {sidebarItems && sidebarItems.map((item, key) => (
                  <React.Fragment key={key}>
                    {item.isMainMenu ? (
                      <li className="menu-title" style={{ fontSize: "14px", color: "#FFFFFF" }}>{props.t(item.label)}</li>
                    ) : (
                      <li key={key}>
                        <Link
                          to={item.url ? item.url : "/#"}
                          className={
                            item.issubMenubadge || item.isHasArrow
                              ? ""
                              : "has-arrow"
                          }
                          onClick={() => handleTabClick(item.url)}
                        >
                          <div className={activePage === "profile" || ((activeUrl && activeUrl.split('/')[2]) === (item && item.url && item.url.split('/')[2])) ? "mm-active" : ""}>
                            <i
                              className={item.icon}
                              style={{ marginRight: "2px" }}
                            ></i>
                            {item.issubMenubadge && (
                              <span
                                className={"badge rounded-pill float-end " + item.bgcolor}
                              >
                                {" "}
                                {item.badgeValue}{" "}
                              </span>
                            )}

                            <span>
                              {props.t(item.label)}
                              {(props.t(item.label) === 'My Team Leaves') && item.batch ? (
                                <span className={`badge bg-${item.batch.type} ms-2`}>
                                  {props.myTeamLeavesTotalSubmittedRecords}
                                </span>
                              ) : null}
                            </span>
                            <span>
                              {(props.t(item.label) === 'Timesheet Approval') && item.batch ? (
                                <span className={`badge bg-${item.batch.type} ms-2`}>
                                  {props.timesheetApprovalTotalRecords}
                                </span>
                              ) : null}
                            </span>



                          </div>
                        </Link>
                        {item.subItem && (
                          <ul className="sub-menu">
                            {item.subItem.map((item, key) => (
                              <li key={key}>
                                <Link
                                  to={item.link}
                                  className={item.subMenu && "has-arrow waves-effect"}
                                >
                                  {props.t(item.sublabel)}
                                </Link>
                                {item.subMenu && (
                                  <ul className="sub-menu">
                                    {item.subMenu.map((item, key) => (
                                      <li key={key}>
                                        <Link to="#">{props.t(item.title)}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </SimpleBar>
        </div>
      </React.Fragment>
    );
  };
}

Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

const mapStateToProps = (state) => ({
  currentProfile: state.login.currentProfile,
  isAuth: state.login.isAuth,
  MyTeamLeaveDetailsList: state.myTeamLeaveDetails.MyTeamLeaveDetailsList,
  myTeamLeavesTotalSubmittedRecords: state.myTeamLeaveDetails.totalSubmittedRecords,
  timesheetApprovalTotalRecords: state.TimesheetApproval.timesheetApprovalTotalRecords
});

export default connect(mapStateToProps, null)(withTranslation()(withRouter(Sidebar)));
