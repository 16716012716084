import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import {
    clearUpdateAccountError,
    createNewAccount,
    getAccountCategoryAsyncSelect,
    getCompanyNameAsyncSelect,
    getIndustryNameAsyncSelect,
    getLeadDetailsAsyncSelect,
    getRegionNameAsyncSelect,
    updateAccount,
    updateAccountSuccess
} from "../../../../store/Modules/crm/Accounts/actions";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import AsyncCreatableSelect from 'react-select/async-creatable';
import FormValidator from "../../../../helper/formValidator";
import Async from 'react-select/async';
import { getCrmUserAsyncSelect } from "../../../../store/Modules/crm/Leads/actions";
import './accountStyle.css'

const UpdateAccount = (props) => {
    const { errorMessage, accountDetails, isAccountUpdatePending } = props;
    const [formFields, setFormFields] = useState();
    const [errors, setErrors] = useState();
    const [prevAccountName, setprevAccountName] = useState([{ label: 'Select', value: '' }]);
    const [prevIndustry, setprevIndustry] = useState([{ label: 'Select', value: '' }]);
    const [prevAccountOwner, setprevAccountOwner] = useState([{ label: 'Select', value: '' }]);
    const [prevRegion, setprevRegion] = useState([{ label: 'Select', value: '' }]);
    const [accountCategory, setprevAccountCategory] = useState([{ label: 'Select', value: '' }]);
    const [accountTeam, setprevAccountTeam] = useState([{ label: 'Select', value: '' }]);
    const [isActive, setisActive] = useState(accountDetails && accountDetails.isActive);
    const [autoFocus, setAutoFocus] = useState(true);

    useEffect(() => {
        props.clearUpdateAccountError();
        props.updateAccountSuccess();
        const team = accountDetails && accountDetails.accountTeamUsers.map((item) => item.userId)
        setFormFields({ ...accountDetails, accountTeam: team });
        setprevAccountName({ label: accountDetails && accountDetails.companyName || '', value: accountDetails && accountDetails.companyName || '' });
        setprevIndustry({ label: accountDetails && accountDetails.industryName || 'Select', value: accountDetails && accountDetails.industryId || '' });
        setprevAccountOwner(accountDetails && accountDetails.accountOwnerId ? { label: `${accountDetails.accountOwnerUserName} | ${accountDetails.accountOwnerUserEmail}`, value: accountDetails.accountOwnerId } : { label: 'Select', value: '' });
        setprevRegion({ label: accountDetails && accountDetails.regionName || 'Select', value: accountDetails && accountDetails.regionId });
        setprevAccountCategory(accountDetails && accountDetails.accountCategoryId ? { label: `${accountDetails && accountDetails.accountCategoryName} | ${accountDetails && accountDetails.accountCategoryValue}`, value: accountDetails && accountDetails.accountCategoryId } : { label: 'Select', value: '' });

        if (accountDetails && accountDetails.accountTeamUsers) {
            const AccountTeamList = accountDetails && accountDetails.accountTeamUsers && accountDetails.accountTeamUsers.map((item) => {
                return {
                    label: `${item.userName} | ${item.userEmail}`,
                    value: item.userId,
                };
            });
            setprevAccountTeam(AccountTeamList);
        }
    }, [])

    const validateOnchange = (e) => {
        const input = e.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        let result = {};

        if (input.name === 'companyEmail' && value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            result = {
                isValid: emailRegex.test(value),
                message: 'Enter valid email',
            }
        }
        else if (input.name === 'phoneNo') {
            if (value) {
                const contactNumberRegex = /^\d{10}$/;
                result = {
                    isValid: contactNumberRegex.test(value),
                    message: 'Enter valid mobile number',
                };
            }
        }
        else if (input.name === 'landLineNo') {
            if (value) {
                const contactNumberRegex = /^[0-9]\d{2,4}-\d{6,8}$/;
                result = {
                    isValid: contactNumberRegex.test(value),
                    message: 'Enter valid landline number',
                };
            }
        }
        else if (input.name === 'code') {
            const contactNumberRegex = /^\d{6}$/;
            result = {
                isValid: contactNumberRegex.test(value),
                message: 'Enter valid pincode',
            };
        }
        else {
            result = FormValidator.validate(e.target);
        }
        setFormFields({
            ...formFields,
            [input.name]: value || null

        });
        console.log("result:", result);

        setErrors({
            ...errors,
            [input.name]: result.isValid ? null : result.message
        })
    }

    const handleAddCompanyName = (e) => {
        const input = e.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        console.log("value:", value);
        setprevAccountName(value);
        setFormFields({ ...formFields, companyName: value, leadId: value });
        setErrors({ ...errors, companyName: '' });
    }
    const handleIndustryName = (inputValue, callback) => {
        const { getIndustryNameAsyncSelectAction } = props;
        return getIndustryNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleAddIndustryName = (value) => {
        console.log("value:", value);
        setprevIndustry(value);
        setFormFields({ ...formFields, industryId: value.value });
        setErrors({ ...errors, industryId: '' });
    }
    const handleAccountCategoryName = (inputValue, callback) => {
        const { getAccountCategoryAsyncSelectAction } = props;
        return getAccountCategoryAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleAddAccountCategoryName = (value) => {
        console.log("value:", value);
        setprevAccountCategory(value);
        setFormFields({ ...formFields, accountCategoryId: value.value });
        setErrors({ ...errors, accountCategoryId: '' });
    }
    const handleUsers = (inputValue, callback) => {
        const { getCrmUserAsyncSelect } = props;
        return getCrmUserAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleAccountTeamSelect = (value) => {
        console.log("value:", value);

        setprevAccountTeam(value);
        const userID = value.map((item) => {
            return (
                item.value
            )
        })
        setFormFields({
            ...formFields,
            accountTeam: userID
        })
        setErrors({
            ...errors,
            accountTeam: null
        })
    }
    const handleUserSelect = (value) => {
        console.log("value:", value);
        setprevAccountOwner(value);
        setFormFields({
            ...formFields,
            accountOwnerId: value.value
        })
        setErrors({
            ...errors,
            accountOwnerId: null
        })
    }
    const handleRegionName = (inputValue, callback) => {
        const { getRegionNameAsyncSelectAction } = props;
        return getRegionNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleAddRegionName = (value) => {
        console.log("value:", value);
        setprevRegion(value);
        setFormFields({ ...formFields, regionId: value.value });
        setErrors({ ...errors, regionId: '' });
    }

    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleAccountCreate();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //-------------------------------------Status Change----------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, isActive: !isActive })
    };
    const handleAccountCreate = (e) => {
        const { city, state, country, address1, gst, companyName, companyEmail, industryId, accountCategoryId, accountTeam, accountOwnerId, regionId,
            accountContact, code } = formFields || {};
        let currentErrors = {}
        if (!country) {
            currentErrors.country = "Country is required";
        }
        if (!state) {
            currentErrors.state = "State is required";
        }
        if (!city) {
            currentErrors.city = "City is required";
        }
        if (!gst) {
            currentErrors.gst = "GST number is required";
        }
        if (!companyName) {
            currentErrors.companyName = "Account name is required";
        }
        if (!accountContact) {
            currentErrors.accountContact = "Account contact  is required";
        }
        if (errors && errors.landLineNo) {
            currentErrors.landLineNo = "Enter valid landline Number";
        }
        if (!industryId) {
            currentErrors.industryId = "Industry name is required";
        }
        if (!accountCategoryId) {
            currentErrors.accountCategoryId = "Account category is required";
        }
        if (!accountOwnerId) {
            currentErrors.accountOwnerId = "Account owner is required";
        }
        if (errors && errors.companyEmail) {
            currentErrors.companyEmail = "Enter valid email";
        }
        if ((errors && errors.phoneNo) && (formFields && formFields.phoneNo && formFields.phoneNo.length > 0)) {
            currentErrors.phoneNo = "Enter valid mobile Number";
        }
        if (!address1) {
            currentErrors.address1 = "Address is required";
        }
        if (!regionId) {
            currentErrors.regionId = "Region name is required";
        }
        if (!code) {
            currentErrors.code = "Pincode is required";
        }
        setErrors(currentErrors);

        if (Object.keys(currentErrors).length === 0) {
            props.updateAccount(formFields);
        }
        if (e) {
            e.preventDefault();
        }
    }
    console.log("error message: ", errorMessage);
    console.log("accountDetails: ", accountDetails);
    console.log("formFields: ", formFields);
    console.log("accountTeam: ", accountTeam);
    return (
        <div>
            <Form>
                {errorMessage &&
                    (<VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />)
                }
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-primary border-5 mb-3"> &nbsp;Account Information</div>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Account Name <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                autoFocus={true}
                                type="text"
                                name="companyName"
                                placeHolder="Enter your account name"
                                data-validate='["required"]'
                                onChange={validateOnchange}
                                value={formFields && formFields.companyName}
                                invalid={errors && errors.companyName}
                            />
                            <FormFeedback>
                                {errors && errors.companyName}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="companyEmail"
                                placeHolder="Enter your email"
                                data-validate='["required"]'
                                onChange={validateOnchange}
                                value={formFields && formFields.companyEmail}
                                invalid={errors && errors.companyEmail}
                            />
                            <FormFeedback>
                                {errors && errors.companyEmail}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>GST Number <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="gst"
                                placeHolder="Enter your gst number"
                                onChange={validateOnchange}
                                value={formFields && formFields.gst}
                                invalid={errors && errors.gst}
                            />
                            <FormFeedback>
                                {errors && errors.gst}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Industry Type <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.industryId) ? 'red' : "silver"
                                    })
                                }}
                                name="industryId"
                                placeholder="Select your industry type"
                                // className="vm-react-select"
                                isSearchable
                                defaultOptions
                                loadOptions={handleIndustryName}
                                onChange={handleAddIndustryName}
                                value={prevIndustry}
                            />
                            <FormText  >
                                <div className="text-danger">{errors && errors.industryId}</div>
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Website</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="website"
                                placeHolder="Enter your website"
                                onChange={validateOnchange}
                                value={formFields && formFields.website}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Mobile Number</Label>
                            <Input
                                onFocus={handleFocus}
                                onKeyDown={
                                    (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                min='0'
                                type="number"
                                name="phoneNo"
                                placeHolder="Enter your mobile number"
                                onChange={validateOnchange}
                                value={formFields && formFields.phoneNo}
                                invalid={errors && errors.phoneNo}
                            />
                            <FormFeedback>
                                {errors && errors.phoneNo}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Landline Number</Label>
                            <Input
                                onFocus={handleFocus}
                                onKeyDown={(e) => {
                                    // Allow Backspace (8), Delete (46), Arrow keys (37-40) and hyphen (45)
                                    const isControlKey = [8, 46, 37, 39, 45].includes(e.keyCode);

                                    // Block alphabets (a-zA-Z) and invalid characters (anything other than numbers and hyphen)
                                    const isInvalidKey = /[a-zA-Z]/.test(e.key);

                                    if (isInvalidKey && !isControlKey) {
                                        e.preventDefault();
                                    }
                                }}
                                onWheel={(e) => e.target.blur()}
                                min='0'
                                type="text"
                                name="landLineNo"
                                placeHolder="Enter your landline number"
                                onChange={validateOnchange}
                                value={formFields && formFields.landLineNo}
                                invalid={errors && errors.landLineNo}
                            />
                            <FormFeedback>
                                {errors && errors.landLineNo}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    {/* </Row >
                <Row> */}
                    <Col lg="4">
                        <FormGroup>
                            <Label>Account Category <span className="text-danger">*</span></Label>

                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.accountCategoryId) ? 'red' : "silver"
                                    })
                                }}
                                name="accountCategoryId"
                                placeholder="Select your account category"
                                // className="vm-react-select"
                                isSearchable
                                defaultOptions
                                loadOptions={handleAccountCategoryName}
                                onChange={handleAddAccountCategoryName}
                                value={accountCategory}
                            />
                            <FormText  >
                                <div className="text-danger">{errors && errors.accountCategoryId}</div>
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Designation</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="designation"
                                placeHolder="Enter your designation"
                                onChange={validateOnchange}
                                value={formFields && formFields.designation}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Responsibility</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="responsibility"
                                placeHolder="Enter your responsibility"
                                onChange={validateOnchange}
                                value={formFields && formFields.responsibility}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Account Owner <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.accountOwnerId) ? 'red' : "silver"
                                    })
                                }}
                                name="accountOwnerId"
                                defaultOptions
                                placeholder="Select your account owner"
                                loadOptions={handleUsers}
                                onChange={handleUserSelect}
                                value={prevAccountOwner}
                            />
                            {(errors && errors.accountOwnerId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.accountOwnerId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Account Team</Label>
                            <Async
                                name="accountTeam"
                                isMulti
                                defaultOptions
                                placeholder="Select your account team"
                                loadOptions={handleUsers}
                                onChange={handleAccountTeamSelect}
                                value={accountTeam}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Account Contact <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="accountContact"
                                placeHolder="Enter your account contact"
                                onChange={validateOnchange}
                                value={formFields && formFields.accountContact}
                                invalid={errors && errors.accountContact}
                            />
                            <FormText  >
                                <div className="text-danger">{errors && errors.accountContact}</div>
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <hr className="mt-0" />
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-danger border-5 mb-3">&nbsp;Description Information</div>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <Label>Description</Label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="description"
                                placeHolder="Enter your account description"
                                onChange={validateOnchange}
                                value={formFields && formFields.description}
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="6">
                        <div className="form-group">
                            <label className="col-form-label">Status</label>
                            <ul className="form-unstyled-list form-status-list">
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${!isActive ? 'active' : ''}`}
                                >
                                    Inactive
                                </li>
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${isActive ? 'active' : ''}`}
                                >
                                    Active
                                </li>
                            </ul>
                        </div>
                    </Col >
                </Row >
                <hr className="mt-0" />
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-success border-5 mb-3">&nbsp;Address Information</div>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <Label>Address 1  <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="address1"
                                placeHolder="Enter your address"
                                onChange={validateOnchange}
                                value={formFields && formFields.address1}
                                invalid={errors && errors.address1}
                            />
                            <FormText  >
                                <div className="text-danger">{errors && errors.address1}</div>
                            </FormText>
                        </FormGroup>
                    </Col >
                    <Col lg="6">
                        <FormGroup>
                            <Label>Address 2</Label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="address2"
                                placeHolder="Enter your address"
                                onChange={validateOnchange}
                                value={formFields && formFields.address2}
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>City  <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="city"
                                placeHolder="Enter your city"
                                onChange={validateOnchange}
                                value={formFields && formFields.city}
                                invalid={errors && errors.city}
                            />
                            <FormText>
                                <div className="text-danger">{errors && errors.city}</div>
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>State <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="state"
                                placeHolder="Enter your state name"
                                onChange={validateOnchange}
                                value={formFields && formFields.state}
                                invalid={errors && errors.state}
                            />
                            <FormText  >
                                <div className="text-danger">{errors && errors.state}</div>
                            </FormText>
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Region <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.regionId) ? 'red' : ""
                                    })
                                }}
                                name="regionId"
                                placeholder="Select your region"
                                // className="vm-react-select"
                                isSearchable
                                defaultOptions
                                loadOptions={handleRegionName}
                                onChange={handleAddRegionName}
                                value={prevRegion}
                            />
                            <FormText  >
                                <div className="text-danger">{errors && errors.regionId}</div>
                            </FormText>
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Pincode <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                onKeyDown={(e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()}
                                onWheel={(e) => e.target.blur()}
                                type="number"
                                name="code"
                                placeHolder="Enter your pincode"
                                onChange={validateOnchange}
                                value={formFields && formFields.code}
                                invalid={errors && errors.code}
                            /><FormFeedback>
                                {errors && errors.code}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Country <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="country"
                                placeHolder="Enter your country name"
                                onChange={validateOnchange}
                                value={formFields && formFields.country}
                                invalid={errors && errors.country}
                            />
                            <FormText  >
                                <div className="text-danger">{errors && errors.country}</div>
                            </FormText>
                        </FormGroup>
                    </Col >
                </Row >
                <hr className="mt-0" />
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleAccountCreate}
                        disabled={isAccountUpdatePending}
                    >
                        {isAccountUpdatePending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form >
        </div >
    )
}

const mapStateToProps = (state) => ({
    errorMessage: state.crmAccounts.accountUpdateErrorMessage,
    isAccountUpdatePending: state.crmAccounts.isAccountUpdatePending,
    isAccountUpdateSuccess: state.crmAccounts.isAccountUpdateSuccess,
})
const mapDispatchToProps = (dispatch) => ({
    updateAccount: (data) => dispatch(updateAccount(data)),
    clearUpdateAccountError: () => dispatch(clearUpdateAccountError()),
    updateAccountSuccess: () => dispatch(updateAccountSuccess()),
    getCompanyNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getCompanyNameAsyncSelect(searchValue, callback)),
    getIndustryNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getIndustryNameAsyncSelect(searchValue, callback)),
    getAccountCategoryAsyncSelectAction: (searchValue, callback) =>
        dispatch(getAccountCategoryAsyncSelect(searchValue, callback)),
    getCrmUserAsyncSelect: (searchValue, callback) => dispatch(getCrmUserAsyncSelect(searchValue, callback)),
    getRegionNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getRegionNameAsyncSelect(searchValue, callback)),
    getLeadDetailsAsyncSelectAction: (id, callback) =>
        dispatch(getLeadDetailsAsyncSelect(id, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAccount);