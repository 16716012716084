import React, { useEffect, useState } from "react";
import {
    Link,
    useNavigate,
    useSearchParams
} from "react-router-dom";
import moment from 'moment-timezone';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";
import { connect } from "react-redux";
import {
    CloseCreateTaskModalAction,
    openCreateTaskModalAction,
    getTaskTypeListAsyncSelectAction,
    getTaskScopeListAsyncSelectAction
} from "../../../../store/Modules/ProjectPortal/Task/actions";
import { getPager } from "../../../../helper/common";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import { clearSetPath } from "../../../../store/actions";
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from "../../../../helper/export";
import {
    clearReportListError,
    clearReportUserListError,
    getProjectUserReportList,
    getReportList,
    getReportListExport,
    getReportUserListExport
} from "../../../../store/Modules/ProjectPortal/Report/actions";
import Select from 'react-select/async';
import { REPORT_EXPORT_HEADERS, USER_REPORT_EXPORT_HEADERS } from "../../../../store/Modules/ProjectPortal/Report/constants";
import { ElementToolTip } from "../vwToolTip";
import { getNonUserNameAsyncSelect } from "../../../../store/Modules/ProjectPortal/Projects/actions";
import ProjectCard from './ProjectCard'
import UserCard from './UserCard'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBorderAll } from "@fortawesome/free-solid-svg-icons";
import { sendToggleViewDocs } from "../../../../store/Modules/OverAllDocs/actions";

const ReportbasedOptions = [
    { label: 'Project', value: 'Project' },
    { label: 'User', value: 'User' },
]
const ProjectReport = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [selectedOption, setSelectedOption] = useState('User');
    const { isReportExportPending } = props;
    const [toggleView, setToggleView] = useState(true);

    const getDefaultDateRange = () => {
        const today = new Date();
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(today.getDate() - 7);

        const formatDate = (date) =>
            date.toISOString().split('T')[0]; // Format as YYYY-MM-DD

        return {
            StartDate: formatDate(twoWeeksAgo),
            EndDate: formatDate(today),
        };
    };

    const [filter, setFilter] = useState(getDefaultDateRange);

    const handleOptionChange = (event) => {
        const newOption = event.target.id === 'btnradioProject' ? 'Project' : 'User';
        setSelectedOption(newOption);
        props.clearReportUserListError();
        props.clearReportListError();
        setPage(1, newOption);
    };

    const handleReportOption = (option) => {
        setSelectedOption(option.value);
        props.clearReportUserListError();
        props.clearReportListError();
        setPage(1, option.value);
    }

    const setPage = (page = 1, option = selectedOption, updatedFilter = filter) => {
        const isProject = option === 'Project';
        const totalRecords = isProject ? props.totalCount : props.reportUserListCount;

        if (isProject) {
            props.getTaskListAction(page, PageSize, updatedFilter);
        } else {
            props.getProjectUserReportList(page, PageSize, updatedFilter);
        }


        const totalPages = Math.ceil(totalRecords / PageSize);
        const visiblePages = 4;
        const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);

        const newPages = [];
        for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
            newPages.push(i);
        }

        setPager({
            currentPage: page,
            totalPages,
            pages: newPages,
        });
    };

    useEffect(() => {
        props.clearReportUserListError();
        props.ClearPath();
        if (selectedOption === 'Project') {
            props.getTaskListAction(Pager.currentPage, PageSize, filter);
        } else {
            props.getProjectUserReportList(Pager.currentPage, PageSize, filter);
        }
    }, []);

    useEffect(() => {
        if (props.totalCount || props.reportUserListCount) {
            setPage(Pager.currentPage, selectedOption);
        }
    }, [props.totalCount, props.reportUserListCount]);

    //------------------------------------------Filter--------------------------------------------------------------

    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const debounceTime = window.__ENV && window.__ENV.debounceTime;

    const handleFilterChange = (updatedFilter) => {
        setFilter(updatedFilter);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const timeout = setTimeout(() => {
            setPage(1, selectedOption, updatedFilter);
        }, debounceTime || 500);

        setDebounceTimeout(timeout);
    };
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        const updatedFilter = { ...filter, [name]: value };
        handleFilterChange(updatedFilter);
    };
    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, []);

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        console.log("Id:", Id);
        navigate(`/projectportal/projects/details/${Id}`);
    };

    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getReportListExport(filter)
            .then((response) => {
                if (response) {
                    const exportReportListExportList = [];
                    const headers = REPORT_EXPORT_HEADERS;
                    const headerSizes = [30, 50, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 40];
                    const headerExcel = [
                        { headerName: "PROJECT NAME", key: "projectName", headerWidhth: 30 },
                        { headerName: "PROJECT MANAGER", key: "projectManagerUserName", headerWidhth: 50 },
                        { headerName: "START DATE", key: "startDate", headerWidhth: 30 },
                        { headerName: "END DATE", key: "endDate", headerWidhth: 30 },
                        { headerName: "PENDING TASK", key: "pendingTask", headerWidhth: 30 },
                        { headerName: "TODAY TASK", key: "todayTask", headerWidhth: 20 },
                        { headerName: "RATING", key: "overAllRating", headerWidhth: 20 },
                        { headerName: "PROJECT STATUS", key: "projectStatus", headerWidhth: 20 },
                        { headerName: "TOTAL TASK", key: "totalTask", headerWidhth: 20 },
                        { headerName: "TOTAL MILESTONE", key: "totalMilestone", headerWidhth: 20 },
                    ]
                    response && response.forEach((element) => {
                        exportReportListExportList.push({
                            projectName: element.projectName || "N/A",
                            projectManagerUserName: element.projectManagerUserName || "N/A",
                            startDate: element.startDate ? moment(element.startDate).format('DD MMM YYYY') : 'N/A',
                            endDate: element.endDate ? moment(element.endDate).format('DD MMM YYYY') : 'N/A',
                            pendingTask: element.pendingTask || 0,
                            todayTask: element.todayTask || 0,
                            overAllRating: element.overAllRating || 0,
                            projectStatus: element.projectStatus || 0,
                            totalTask: element.totalTask || 0,
                            totalMilestone: element.totalMilestone || 0,
                        });
                    });
                    // excelPrint(exportReportListExportList, "Project-Report-List", headers, headerSizes);
                    excelSheetExport(headerExcel, exportReportListExportList, "Project-Report-List");
                }
            })
    }
    const handleUserExports = () => {
        props.getReportUserListExport(filter).then((response) => {
            if (response) {
                const exportReportListExportList = [];
                const headers = USER_REPORT_EXPORT_HEADERS;
                const headerSizes = [30, 50, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 40];
                const headerExcel = [
                    { headerName: "USER NAME", key: "userName", headerWidhth: 30 },
                    { headerName: "USER EMAIL", key: "userEmail", headerWidhth: 50 },
                    { headerName: "RUNNING PROJECT", key: "runningProject", headerWidhth: 30 },
                    { headerName: "MANAGED PROJECT", key: "managedProject", headerWidhth: 30 },
                    { headerName: "PENDING TASK", key: "pendingTask", headerWidhth: 30 },
                    { headerName: "TODAY TASK", key: "todayTask", headerWidhth: 20 },
                    { headerName: "TOTAL PROJECT", key: "totalProject", headerWidhth: 20 },
                    { headerName: "COMPLETED PROJECTS", key: "completedProject", headerWidhth: 20 },
                ]
                response && response.forEach((element) => {
                    exportReportListExportList.push({
                        userName: element.userName || "N/A",
                        userEmail: element.userEmail || "N/A",
                        runningProject: element.runningProject || 0,
                        managedProject: element.managedProject || 0,
                        pendingTask: element.pendingTask || 0,
                        todayTask: element.todayTask || 0,
                        totalProject: element.totalProject || 0,
                        completedProject: element.completedProject || 0,
                    });
                });
                excelSheetExport(headerExcel, exportReportListExportList, "Project-Report-List");
                // excelPrint(exportReportListExportList, "Project-Report-List", headers, headerSizes);
            }
        })
    }

    useEffect(() => {
        if (props.toggleViewBool) {
            setToggleView(props.toggleViewBool)
        }
    }, [props.toggleViewBool])

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedProjectList = [...props.reportList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'PendingTask' || sortOrder === 'TodayTask' || sortOrder === 'OverAllRating' || sortOrder === 'ProjectStatus') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    const sortedUserList = [...props.reportUserList || []].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'CompletedProject' || sortOrder === 'TotalProject' || sortOrder === 'OverAllRating' || sortOrder === 'TodayTask' || sortOrder === 'PendingTask' || sortOrder === 'ManagedProject' || sortOrder === 'RunningProject') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    console.log('filter:', filter);
    console.log('isReportExportPending:', isReportExportPending);
    console.log('props.reportList:', props.reportList);

    return (
        <React.Fragment>
            <div className="page-content">
                <Card >
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 pt-2 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <div className="d-flex align-items-center">
                            <strong className="d-flex align-items-center me-4">
                                REPORTS -&nbsp;
                                {selectedOption === 'Project' &&
                                    <>
                                        <Badge color="danger" id={'ReportCount'}>
                                            {props && props.totalCount || 0}
                                        </Badge>
                                        <ElementToolTip id={'ReportCount'} name={'Reports Count'} />
                                    </>
                                }
                                {selectedOption === 'User' &&
                                    <>
                                        <Badge color="danger" id={'ReportUserCount'}>
                                            {props && props.reportUserListCount || 0}
                                        </Badge>
                                        <ElementToolTip id={'ReportUserCount'} name={'Reports Count'} />
                                    </>
                                }
                            </strong>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Row className='border border-info rounded-1' noGutters>
                                <Col
                                    className={toggleView ? "bg-info text-white p-2" : "bg-white text-info p-2 rounded-1"}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setToggleView(true)
                                        props.sendToggleView(true)
                                    }}
                                    id={'GridView'}
                                >
                                    <FontAwesomeIcon icon={faBorderAll} className='fs-4' />
                                    <ElementToolTip id={'GridView'} position={'left'} name={
                                        'Grid View'
                                    } />
                                </Col>
                                <Col
                                    className={!toggleView ? "bg-info p-2 text-white" : "bg-white text-info p-2 rounded-1"}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setToggleView(false)
                                        props.sendToggleView(false)
                                    }}
                                    id={'TableView'}
                                >
                                    <FontAwesomeIcon icon={faBars} className='fs-4' />
                                    <ElementToolTip id={'TableView'} position={'right'} name={
                                        'Table View'
                                    } />
                                </Col>
                            </Row>
                        </div>
                        <div className="content-heading d-flex align-items-center" style={{ width: 'auto' }}>
                            {selectedOption === 'Project' &&
                                <Label className="d-flex align-items-center mt-2" style={{ whiteSpace: 'nowrap' }}>
                                    <Input
                                        type="text"
                                        placeholder="Search by project name"
                                        name="ProjectName"
                                        onChange={searchInputValidation}
                                        value={filter && filter.ProjectName}
                                        className="me-2"
                                        style={{ width: '200px' }}
                                    />
                                </Label>
                            }
                            {selectedOption === 'User' &&
                                <Label className="d-flex align-items-center mt-2" style={{ whiteSpace: 'nowrap' }}>
                                    <Input
                                        type="text"
                                        placeholder="Search by user"
                                        name="UserName"
                                        onChange={searchInputValidation}
                                        value={filter && filter.UserName}
                                        className="me-2"
                                        style={{ width: '200px' }}
                                    />
                                </Label>
                            }
                            <Select
                                name="ReportOption"
                                placeholder="Select"
                                isSearchable
                                defaultOptions={ReportbasedOptions}
                                onChange={handleReportOption}
                                defaultValue={{ label: 'User', value: 'User' }}
                            />
                            <Button
                                className="ms-4"
                                color="success"
                                onClick={selectedOption === 'Project' ? handleExports : handleUserExports}
                                disabled={props.reportList && props.reportList.length <= 0 || isReportExportPending}
                            >
                                {isReportExportPending ? 'EXPORTING...' : 'EXPORT'}
                            </Button>
                        </div>
                    </CardTitle>
                    <CardTitle className='pt-2 mb-0 pb-0 mb-0' >
                        <div className="d-flex justify-content-end align-items-center">
                            <FormGroup className="d-flex mr-3 mx-2">
                                <Label className="d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    From Date
                                    <Input
                                        className='mx-2'
                                        type="date"
                                        name="StartDate"
                                        onChange={searchInputValidation}
                                        value={filter && filter.StartDate}
                                        max={filter.EndDate}
                                    />
                                </Label>
                            </FormGroup>
                            <FormGroup className="d-flex mx-2">
                                <Label className="d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                    To Date
                                    <Input
                                        className='mx-2'
                                        type="date"
                                        name="EndDate"
                                        onChange={searchInputValidation}
                                        value={filter && filter.EndDate}
                                        min={filter.StartDate}
                                        max="9999-12-31" />
                                </Label>
                            </FormGroup>
                        </div>
                    </CardTitle>
                </Card>
                {toggleView ?
                    <Card style={{ backgroundColor: "transparent" }}>
                        <CardBody className="p-2 pt-0">
                            {(props.errorMessage || props.reportUserListErrorMessage) && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage || props.reportUserListErrorMessage} baseClassName="alert" />
                            )}
                            {selectedOption === 'Project' &&
                                <div
                                    style={{ height: '55vh', overflowX: 'auto' }}
                                    className="card-container"
                                >
                                    {selectedOption === 'Project' &&
                                        (!props.isTaskPending) && (props.reportList && props.reportList.length > 0) ?
                                        props.reportList && props.reportList.map((data, index) => (
                                            <ProjectCard key={index} data={data} dates={filter} />
                                        )) : props.isTaskPending ?
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <VWSpinner />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <NoRecord />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            }
                            {selectedOption === 'User' &&
                                <div
                                    style={{ height: '55vh', overflowX: 'auto' }}
                                    className="user-card-container"
                                >
                                    {selectedOption === 'User' &&
                                        (!props.isReportUserListPending) &&
                                        (props.reportUserList &&
                                            props.reportUserList.length > 0) ?
                                        props.reportUserList.map((data, index) => (
                                            <UserCard key={index} user={data} filter={filter} />
                                        )) :
                                        props.isReportUserListPending ?
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <VWSpinner />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <NoRecord />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            }
                        </CardBody>
                        {/* <CardFooter>
                            {selectedOption === 'Project' &&
                                (props.reportList && props.reportList.length > 0) ?
                                <VWPagination
                                    pager={Pager}
                                    setPage={setPage}
                                    totalRecords={props.totalCount}
                                    pageSize={PageSize}
                                /> : null
                            }
                            {selectedOption === 'User' &&
                                (props.reportUserList && props.reportUserList.length > 0) ?
                                <VWPagination
                                    pager={Pager}
                                    setPage={setPage}
                                    totalRecords={props.reportUserListCount}
                                    pageSize={PageSize}
                                /> : null
                            }
                        </CardFooter> */}
                    </Card>
                    :
                    <Card className='p-4'>
                        <div style={{ height: "50vh", overflowX: "auto" }}>
                            {selectedOption === 'Project' &&
                                <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                        <tr>
                                            <th onClick={() => handleSort('ProjectName')} className='hand-cursor'>Project &nbsp;
                                                {sortOrder === 'ProjectName' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('StartDate')} className='hand-cursor'>Start Date &nbsp;
                                                {sortOrder === 'StartDate' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('EndDate')} className='hand-cursor'>End Date &nbsp;
                                                {sortOrder === 'EndDate' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('PendingTask')} className='hand-cursor'>Pending Tasks &nbsp;
                                                {sortOrder === 'PendingTask' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('TodayTask')} className='hand-cursor'>Today Tasks &nbsp;
                                                {sortOrder === 'TodayTask' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('OverAllRating')} className='hand-cursor'>Rating &nbsp;
                                                {sortOrder === 'OverAllRating' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('ProjectStatus')} className='hand-cursor'>Project Status &nbsp;
                                                {sortOrder === 'ProjectStatus' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedOption === 'Project' &&
                                            (!props.isTaskPending) && (props.reportList && props.reportList.length > 0) ?
                                            sortedProjectList && sortedProjectList.map((data) => (
                                                <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                    <td className='ps-3'>{data.ProjectName || "N/A"}<br />
                                                        <small>{data.ProjectManagerUserName || 'N/A'}</small></td>
                                                    <td className='ps-3'>{data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'}</td>
                                                    <td className='ps-3'>{data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}</td>
                                                    <td className='ps-3'> {data.PendingTask || 0}</td>
                                                    <td className='ps-3'>{data.TodayTask || 0}</td>
                                                    <td className='ps-3'> {(data && data.OverAllRating) || 0}/5</td>
                                                    <td className='ps-3'>  {data?.ProjectStatus || 0}%</td>
                                                </tr>
                                            ))
                                            : props.isTaskPending ?
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <VWSpinner />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <NoRecord />
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </tbody>
                                </Table>
                            }

                            {selectedOption === 'User' &&
                                <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                        <tr>
                                            <th onClick={() => handleSort('UserName')} className='hand-cursor'>Username &nbsp;
                                                {sortOrder === 'UserName' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('RunningProject')} className='hand-cursor'>Running Projects &nbsp;
                                                {sortOrder === 'RunningProject' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('ManagedProject')} className='hand-cursor'>Managed Projects &nbsp;
                                                {sortOrder === 'ManagedProject' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('PendingTask')} className='hand-cursor'>Pending Tasks &nbsp;
                                                {sortOrder === 'PendingTask' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('TodayTask')} className='hand-cursor'>Today Tasks &nbsp;
                                                {sortOrder === 'TodayTask' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('OverAllRating')} className='hand-cursor'>Rating &nbsp;
                                                {sortOrder === 'OverAllRating' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('TotalProject')} className='hand-cursor'>Total Projects &nbsp;
                                                {sortOrder === 'TotalProject' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('CompletedProject')} className='hand-cursor'>Completed Projects &nbsp;
                                                {sortOrder === 'CompletedProject' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedOption === 'User' &&
                                            (!props.isReportUserListPending) && (props.reportUserList && props.reportUserList.length > 0) ?
                                            sortedUserList && sortedUserList.map((data) => (
                                                <tr >
                                                    <td className='ps-3'>{data.UserName || "N/A"}<br />
                                                        <small>{data.UserEmail || 'N/A'}</small></td>
                                                    <td className='ps-3'>{data.RunningProject || 0}</td>
                                                    <td className='ps-3'>{data.ManagedProject || 0}</td>
                                                    <td className='ps-3'>{data.PendingTask || 0}</td>
                                                    <td className='ps-3'>{data.TodayTask || 0}</td>
                                                    <td className='ps-3'>{data.OverAllRating || 0}/5</td>
                                                    <td className='ps-3'>{data.TotalProject || 0}</td>
                                                    <td className='ps-3'>{data.CompletedProject || 0}</td>
                                                </tr>
                                            ))
                                            : props.isReportUserListPending ?
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <VWSpinner />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <NoRecord />
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </tbody>
                                </Table>
                            }
                        </div>
                    </Card>
                }
                <Card className='m-0 p-0'>
                    <CardFooter>
                        {selectedOption === 'Project' &&
                            (props.reportList && props.reportList.length > 0) ?
                            <VWPagination
                                pager={Pager}
                                setPage={setPage}
                                totalRecords={props.totalCount}
                                pageSize={PageSize}
                            /> : null
                        }
                        {selectedOption === 'User' &&
                            (props.reportUserList && props.reportUserList.length > 0) ?
                            <VWPagination
                                pager={Pager}
                                setPage={setPage}
                                totalRecords={props.reportUserListCount}
                                pageSize={PageSize}
                            /> : null
                        }
                    </CardFooter>
                </Card>
            </div >
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    reportList: state.projectReport.reportList,
    isReportUserListPending: state.projectReport.isReportUserListPending,
    reportUserListCount: state.projectReport.reportUserListCount,
    reportUserList: state.projectReport.reportUserList,
    isTaskPending: state.projectReport.isReportListPending,
    totalCount: state.projectReport.reportListCount,
    errorMessage: state.projectReport.reportListErrorMessage,
    isReportExportPending: state.projectReport.isReportExportPending,
    reportUserListErrorMessage: state.projectReport.reportUserListErrorMessage,
    toggleViewBool: state.OverAllDocsList.toggleViewBool,
});
const mapDispatchToProps = (dispatch) => ({
    sendToggleView: (toggleView) => dispatch(sendToggleViewDocs(toggleView)),
    getTaskTypeAsyncSelectAction: (searchValue, callback) => dispatch(getTaskTypeListAsyncSelectAction(searchValue, callback)),
    getTaskScopeAsyncSelectAction: (searchValue, callback) => dispatch(getTaskScopeListAsyncSelectAction(searchValue, callback)),
    openCreateTaskModal: () => dispatch(openCreateTaskModalAction()),
    closeCreateTaskModal: () => dispatch(CloseCreateTaskModalAction()),
    getProjectUserReportList: (page, PageSize, filter) => dispatch(getProjectUserReportList(page, PageSize, filter)),
    getTaskListAction: (page, PageSize, filter) =>
        dispatch(getReportList(page, PageSize, filter)),
    getReportListExport: (filter) =>
        dispatch(getReportListExport(filter)),
    getReportUserListExport: (filter) => dispatch(getReportUserListExport(filter)),
    ClearPath: () => dispatch(clearSetPath()),
    getUserNameAsyncSelectAction: (searchValue, callback) => dispatch(getNonUserNameAsyncSelect(searchValue, callback)),
    clearReportUserListError: () => dispatch(clearReportUserListError()),
    clearReportListError: () => dispatch(clearReportListError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectReport);