const TimeSheetsSidebarData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]
const TimeSheetsSidebarUserBothManager = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "My Team Timesheet",
        icon: "mdi mdi-clipboard-list-outline",
        isHasArrow: true,
        url: "/timesheets/myteamtimesheet",
    },
    // {
    //     label: "All Timesheet",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/timesheets/alltimesheet",
    // },
    {
        label: "Timesheet Approval",
        icon: "mdi mdi-clipboard-check-outline",
        isHasArrow: true,
        url: "/timesheets/timesheetapproval",
        batch: {
            type: "danger"
        }
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]
const TimeSheetsSidebarUserProjectManager = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "All Timesheet",
        icon: "mdi mdi-clipboard-text-outline",
        isHasArrow: true,
        url: "/timesheets/alltimesheet",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]

const TimeSheetsSidebarUserManagerData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "My Team Timesheet",
        icon: "mdi mdi-clipboard-list-outline",
        isHasArrow: true,
        url: "/timesheets/myteamtimesheet",
    },
    // {
    //     label: "All Timesheet",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/timesheets/alltimesheet",
    // },
    // {
    //     label: "Timesheet Approval",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/timesheets/timesheetapproval",
    // },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]
const TimeSheetsSidebarUserBothManagerData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "My Team Timesheet",
        icon: "mdi mdi-clipboard-list-outline",
        isHasArrow: true,
        url: "/timesheets/myteamtimesheet",
    },
    // {
    //     label: "All Timesheet",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/timesheets/alltimesheet",
    // },
    {
        label: "Timesheet Approval",
        icon: "mdi mdi-clipboard-check-outline",
        isHasArrow: true,
        url: "/timesheets/timesheetapproval",
        batch: {
            type: "danger"
        }
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]
const TimeSheetsSidebarAdminData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "All Timesheet",
        icon: "mdi mdi-clipboard-text-outline",
        isHasArrow: true,
        url: "/timesheets/alltimesheet",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]
const TimeSheetsSidebarAdminProjectManagerData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "Timesheet Approval",
        icon: "mdi mdi-clipboard-check-outline",
        isHasArrow: true,
        url: "/timesheets/timesheetapproval",
        batch: {
            type: "danger"
        }
    },
    {
        label: "All Timesheet",
        icon: "mdi mdi-clipboard-text-outline",
        isHasArrow: true,
        url: "/timesheets/alltimesheet",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]
const TimeSheetsSidebarAdminBothManagerData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "My Team Timesheet",
        icon: "mdi mdi-clipboard-list-outline",
        isHasArrow: true,
        url: "/timesheets/myteamtimesheet",
    },
    {
        label: "Timesheet Approval",
        icon: "mdi mdi-clipboard-check-outline",
        isHasArrow: true,
        url: "/timesheets/timesheetapproval",
        batch: {
            type: "danger"
        }
    },
    {
        label: "All Timesheet",
        icon: "mdi mdi-clipboard-text-outline",
        isHasArrow: true,
        url: "/timesheets/alltimesheet",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },
]
const TimeSheetsSidebarAdminManagerData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/timesheets/dashboard",
    },
    {
        label: "Timesheet Management",
        isMainMenu: true,
    },
    {
        label: "My Timesheet",
        icon: "mdi mdi-clipboard-account-outline",
        isHasArrow: true,
        url: "/timesheets/mytimesheet",
    },
    {
        label: "My Team Timesheet",
        icon: "mdi mdi-clipboard-list-outline",
        isHasArrow: true,
        url: "/timesheets/myteamtimesheet",
    },
    {
        label: "Timesheet Approval",
        icon: "mdi mdi-clipboard-check-outline",
        isHasArrow: true,
        url: "/timesheets/timesheetapproval",
        batch: {
            type: "danger"
        }
    },
    {
        label: "All Timesheet",
        icon: "mdi mdi-clipboard-text-outline",
        isHasArrow: true,
        url: "/timesheets/alltimesheet",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/timesheets/reports",
    },

]
export { TimeSheetsSidebarAdminData, TimeSheetsSidebarData, TimeSheetsSidebarUserManagerData, TimeSheetsSidebarAdminManagerData, TimeSheetsSidebarUserProjectManager, TimeSheetsSidebarAdminProjectManagerData, TimeSheetsSidebarAdminBothManagerData, TimeSheetsSidebarUserBothManager };

