import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import Async from 'react-select/async';
import { createLeaveMaster, createLeaveMasterActionSuccess, getOrgNameAsyncSelectActions, getUserNameAsyncSelectAction } from '../../../../store/Modules/LeaveTracker/LeaveMaster/actions';
import VWButton from '../../../../components/vwButton/VWButton';
import { getLeaveTypeAsyncSelect, getOrgLeaveTypeAsyncSelect } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';
import Select from "react-select";

const FrequencyOptions = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Yearly', value: 'Yearly' },
]

const LeaveMasterCreate = (props) => {
    const [formFields, setFormFields] = useState({ isActive: true, carryForward: false, allowConsecutiveDays: false });
    const { orgName } = props.currentProfile || {};
    const [errors, setErrors] = useState({});
    const [isActive, setisActive] = useState(true);
    const [prevFrequency, setprevFrequency] = useState([]);
    const [carryForward, setCarryForward] = useState(false);
    const [allowConsecutiveDays, setAllowConsecutiveDays] = useState(false);
    const [autoFocus, setAutoFocus] = useState(true);

    const { filteredData, appId } = props;
    useEffect(() => {
        props.createLeaveMasterActionSuccess();
    }, [])

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });

        if (input.name === 'TotalLeaveday') {
            setErrors({ ...errors, totalLeaveday: '' });
        }
        if (input.name === 'Qty') {
            setErrors({ ...errors, Qty: '' });
        }
        if (input.name === 'consecutiveDaysQty') {
            setErrors({ ...errors, consecutiveDaysQty: '' });
        }
    };

    const handleUser = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        return getUserNameAsyncSelectAction(appId, inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleAddUser = (value) => {
        setFormFields({ ...formFields, userId: value.value });
        setErrors({ ...errors, userId: '' });
    }
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleCreateLeaveMaster();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //--------------------------------------------Create ---------------------------------------------------------
    const handleCreateLeaveMaster = () => {
        const newErrors = {};
        if (!formFields.userId) {
            newErrors.userId = 'User is required';
        }
        if (!formFields.leaveTypeId) {
            newErrors.leaveTypeId = 'Leave type is required';
        }
        if (!formFields.Qty) {
            newErrors.Qty = 'Qty is required';
        }
        if (!formFields.frequency) {
            newErrors.frequency = 'Frequency is required';
        }
        if (!formFields.consecutiveDaysQty) {
            newErrors.consecutiveDaysQty = 'Consecutive days qty is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.createLeaveMasterAction(formFields, filteredData);
        }
    };

    const handleLoadLeaveType = (inputValue, callback) => {
        const { getLeaveTypeAsyncSelectAction } = props;
        return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleAddLeaveTypeChange = (value) => {
        setFormFields({ ...formFields, leaveTypeId: value.value });
        setErrors({ ...errors, leaveTypeId: '' });
    }

    //-------------------------------------Status Change----------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, isActive: !isActive })
    };


    //----------------------------------------------------------------------------------------------------

    const handleChangeFrequencySelect = (e) => {
        console.log("e:", e.value);
        setprevFrequency(e);
        setFormFields({ ...formFields, frequency: e.value });
        setErrors({ ...errors, frequency: '' });

    }
    const handleCarryForwardCheckbox = () => {
        const newCarryForward = !carryForward;
        setCarryForward(newCarryForward);
        setFormFields({ ...formFields, carryForward: newCarryForward });
    }

    const handleAllowConsecutiveDaysCheckbox = () => {
        const newAllowConsecutiveDays = !allowConsecutiveDays;
        setAllowConsecutiveDays(newAllowConsecutiveDays);
        setFormFields({ ...formFields, allowConsecutiveDays: newAllowConsecutiveDays });
    }

    console.log("formFields:", formFields);
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">
                                User<span className="text-danger"> *</span>
                            </label>
                            <Async
                                openMenuOnFocus={true}
                                autoFocus={true}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.userId) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="userId"
                                placeholder="Select User"
                                isSearchable
                                defaultOptions
                                loadOptions={handleUser}
                                onChange={handleAddUser}
                            />
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {errors && errors.userId}
                                </div>
                            </FormText>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">
                                Leave Type <span className="text-danger"> *</span>
                            </label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.leaveTypeId) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="leaveTypeId"
                                placeholder="Select Leave Type"
                                isSearchable
                                defaultOptions
                                loadOptions={handleLoadLeaveType}
                                onChange={handleAddLeaveTypeChange}
                            />
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {errors && errors.leaveTypeId}
                                </div>
                            </FormText>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Qty <span className="text-danger"> *</span></label>
                            <Input
                                onFocus={handleFocus}
                                type="number"
                                name="Qty"
                                placeholder="Enter Qty"
                                onChange={(e) => validateOnChange(e)}
                                value={formFields && formFields.Qty}
                                invalid={errors && errors.Qty}
                            />
                            {<FormFeedback>{errors && errors.Qty}</FormFeedback>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Frequency <span className="text-danger"> *</span></label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.frequency) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="frequency"
                                placeholder="Select Frequency"
                                options={FrequencyOptions}
                                onChange={e => handleChangeFrequencySelect(e)}
                                value={prevFrequency}
                            />
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {errors && errors.frequency}
                                </div>
                            </FormText>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Consecutive Days Qty <span className="text-danger"> *</span></label>
                            <Input
                                onFocus={handleFocus}
                                type="number"
                                name="consecutiveDaysQty"
                                placeholder="Enter Consecutive Qty"
                                onChange={(e) => validateOnChange(e)}
                                value={formFields && formFields.consecutiveDaysQty}
                                invalid={errors && errors.consecutiveDaysQty}
                            />
                            {<FormFeedback>{errors && errors.consecutiveDaysQty}</FormFeedback>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Description</label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="Description"
                                placeholder="Enter Description"
                                onChange={(e) => validateOnChange(e)}
                                value={formFields && formFields.Description}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="form-group">
                            <label className="col-form-label">Status</label>
                            <ul className="form-unstyled-list form-status-list">
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${!isActive ? 'active' : ''}`}
                                >
                                    Inactive
                                </li>
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${isActive ? 'active' : ''}`}
                                >
                                    Active
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group" style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: '40px' }}>
                            <Label>
                                <Input
                                    onFocus={handleFocus}
                                    type='checkbox'
                                    name='carryForward'
                                    onClick={handleCarryForwardCheckbox}
                                    checked={formFields && formFields.carryForward}
                                />&nbsp;Carry Forward
                            </Label>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group" style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: '40px' }}>
                            <Label>
                                <Input
                                    onFocus={handleFocus}
                                    type='checkbox'
                                    name='allowConsecutiveDays'
                                    onClick={handleAllowConsecutiveDaysCheckbox}
                                    checked={formFields && formFields.allowConsecutiveDays}
                                />&nbsp;Allow Consecutive Days
                            </Label>
                        </div>
                    </Col>
                </Row>
                <br></br>
                <div className="form-group">
                    <FormGroup>
                        <Button
                            className="me-2"
                            color="success"
                            onClick={handleCreateLeaveMaster}
                            disabled={props.isPending}
                        >
                            {props.isPending ? <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                &nbsp; Submitting...
                            </>
                                : <>Submit</>}
                        </Button>
                        <Button
                            color="danger"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </FormGroup>
                </div>
            </>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.leaveMaster.isPending,
    errorMessage: state.leaveMaster.errorMessage,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    createLeaveMasterAction: (formFields, filteredData) => dispatch(createLeaveMaster(formFields, filteredData)),
    createLeaveMasterActionSuccess: () => dispatch(createLeaveMasterActionSuccess()),
    getOrgNameAsyncSelectAction: (searchValue, callback, Roles, orgName) =>
        dispatch(getOrgNameAsyncSelectActions(searchValue, callback, Roles, orgName)),
    getUserNameAsyncSelectAction: (appId, searchValue, callback) =>
        dispatch(getUserNameAsyncSelectAction(appId, searchValue, callback)),
    getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
        dispatch(getLeaveTypeAsyncSelect(searchValue, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeaveMasterCreate)