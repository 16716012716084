import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useLocation, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Editor.css"
import VWModel from '../../../components/Modal/VWModal';
import { connect } from 'react-redux';
import { clearIsDocumentUpdated, clearOverAllDocsDetails, clearOverAllDocsDetailsError, clearUpdateOverAllDocsError, closeUpdateOverAllDocsHeaderModal, closeUpdateOverAllDocsModalAction, getOverAllDocsDetailsAction, isDocumentUpdated, isUpdateEditDocspending, openUpdateOverAllDocsHeaderModal, openUpdateOverAllDocsModal, updateOverAllDocs } from '../../../store/Modules/OverAllDocs/actions';
import OverAllDocsEditModal from './OverAllDocsEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faDownload, faSave, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import OverAllDocsEditHeader from './OverAllDocsEditHeader';
import jsPDF from "jspdf";
import VWMessage from '../../../components/vwMessage/vwMessage';
import moment from 'moment-timezone';
import _ from 'lodash';
import VWSpinner from '../../../components/vwSpinner/vwSpinner';

const OverAllDocsEdit = (props) => {
    const [errors, setErrors] = useState({});
    const [headerTitle, setHeaderTitle] = useState('');
    const [formFields, setFormFields] = useState({});

    const initialForm = useRef(props.DocsDetails);
    initialForm.current = props.DocsDetails;
    const formFieldsRef = useRef(formFields);
    formFieldsRef.current = formFields;

    const location = useLocation();
    console.log("location:", location);

    const params = useParams();
    const { id, appid } = params;
    console.log("id:", id);
    console.log("appid:", appid);

    let path = location.pathname;
    let newPath = path.replace(`/edit/${id}/${appid}`, "");
    console.log('props.isDocsUpdated:', props.isDocsUpdated);

    useEffect(() => {
        if ((id && appid) || props.isDocsUpdated) {
            props.getOverAllDocsDetailsAction(id, appid);
            // props.clearOverAllDocsDetails();
            props.clearIsDocumentUpdated();
        }
    }, [id, appid, props.isDocsUpdated]);

    useEffect(() => {
        if (props.DocsDetails) {
            const updatedFormFields = {
                ...props.DocsDetails,
                contentText: (props.DocsDetails && props.DocsDetails.contentText) || formFields.contentText
            };
            setFormFields(updatedFormFields);
            setHeaderTitle(updatedFormFields.headerTitle || '');
        }
    }, [props.DocsDetails]);

    const AutoSave = () => {
        const isFormChanged = _.isEqual(initialForm.current, formFieldsRef.current);
        if (formFieldsRef.current && formFieldsRef.current.isEditable && !isFormChanged) {
            EditVisionDocs(formFieldsRef.current);
        }
    }
    const maxLength = 50;


    useEffect(() => {
        props.clearUpdateOverAllDocsError();
        props.isUpdateEditDocspending();
        return () => {
            AutoSave();
            props.clearOverAllDocsDetailsError();
            props.clearOverAllDocsDetails();
            // EditVisionDocs(formFieldsRef.current);
        }
    }, []);

    const clearVisionDocsInLocalStorage = () => {
        console.log("Clearing OverAllDocsList inside reduxState in localStorage...");

        // Get the reduxState from localStorage
        const reduxState = JSON.parse(localStorage.getItem("reduxState"));
        if (reduxState && reduxState.OverAllDocsList) {
            // Clear the OverAllDocsList
            reduxState.OverAllDocsList.DocsDetails = {};
            // Set the updated reduxState back to localStorage
            localStorage.setItem("reduxState", JSON.stringify(reduxState));
        }
    };

    useEffect(() => {
        const handleTabClose = (event) => {
            clearVisionDocsInLocalStorage();
        };

        window.addEventListener("beforeunload", handleTabClose);
        window.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "hidden") {
                clearVisionDocsInLocalStorage();
            }
        });

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
            window.removeEventListener("visibilitychange", handleTabClose);
        };
    }, []);
    const EditVisionDocs = (currentFormFields) => {
        console.log("currentFormFields:", currentFormFields);
        console.log("CreateDocsID:", props.CreateDocsID);
        console.log("props.documentContent:", props.documentContent);

        const updatedFormFields = currentFormFields;
        setFormFields(updatedFormFields);
        console.log('updatedFormFields:', updatedFormFields);

        const newErrors = {};
        if (!updatedFormFields.title || !updatedFormFields.title.trim()) {
            newErrors.title = 'Document Title is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            props.updateOverAllDocsAction(updatedFormFields);
            props.isDocumentUpdated();
        }
    }
    const handleEditorChange = (content) => {
        // setFormFields({ ...formFields, "contentText": content });
        setFormFields(prevFields => ({ ...prevFields, contentText: content }));
    };


    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        EditVisionDocs(formFieldsRef.current);
    };
    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if the key combination is Ctrl + S
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault(); // Prevent the default save behavior
                handleSubmit(); // Call your handleSubmit function
            }
        };

        // Attach the event listener to the window object
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    const handleClose = () => {
        props.closeUpdateOverAllDocsModalAction();
        props.closeUpdateOverAllDocsHeaderModalAction();
    };
    const handleOpenModal = (e) => {
        e.preventDefault();
        props.openUpdateOverAllDocsModalAction();
    };
    const handleTitleChange = (e) => {
        const { value } = e.target;
        if (value && value.length >= maxLength) {
            setErrors({ ...errors, title: `Document name cannot exceed ${maxLength} characters.` });
        } else {
            setErrors({ ...errors, title: '' });
        }
        // setFormFields({ ...formFields, title: e.target.value });
        setFormFields(prevFields => ({ ...prevFields, title: e.target.value }));
    };
    const handleDone = (updatedFormFields) => {
        setFormFields(updatedFormFields);
        setHeaderTitle(updatedFormFields && updatedFormFields.headerTitle);
        handleClose();
    };

    const handleOpenHeaderModal = (e) => {
        e.preventDefault();
        props.openUpdateOverAllDocsHeaderModalAction();
    };

    const pdfPrint = async (headerTitle, content) => {
        console.log("headerTitle:", headerTitle);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const margin = 10;
        const headerHeight = 20;
        const footerHeight = 10;
        let pageNumber = 1;
        let currentY = headerHeight; // Start content position after header
        if ((headerTitle && headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
            currentY = headerHeight + 17;
        }

        console.log("pageWidth:", pageWidth);

        const addHeader = async () => {
            if ((headerTitle && headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
                pdf.setFontSize(20);
                pdf.text(headerTitle, pageWidth / 2, headerHeight, { align: 'center' });
                // Set the horizontal line under the header
                pdf.setFontSize(12);
                pdf.setDrawColor(0, 0, 0);
                pdf.line(margin, 25, pageWidth - margin, 25);
                // Add the header logo if available
                if (formFields && formFields.headerLogo) {
                    pdf.addImage(formFields.headerLogo, 'PNG', 10, 10, 30, 15);
                }
                if (formFields && formFields.includeUserInfo) {
                    pdf.setFontSize(10);
                    pdf.text(`Document Owner: ${formFields.ownerUserName}`, pageWidth - margin - 50, 10);
                }
            }
            else if (formFields && formFields.includeUserInfo) {
                pdf.setFontSize(10);
                pdf.text(`Document Owner: ${formFields.ownerUserName}`, pageWidth - margin - 50, 10);
            }
        };
        const addFooter = () => {
            pdf.setFontSize(10);
            pdf.text(`Page ${pageNumber}`, pageWidth / 2, pageHeight - footerHeight / 2, { align: 'center' });
            const timestampText = `Last Updated Date: ${formFields.updatedDateTime ? moment(formFields.updatedDateTime).format('DD MMM YYYY') : moment(formFields.createdTimeStamp).format('DD MMM YYYY')}`;
            const timestampX = pageWidth - margin - pdf.getTextWidth(timestampText);
            const timestampY = pageHeight - footerHeight / 2;

            pdf.text(timestampText, timestampX, timestampY);
        };

        // Function to add content to the page
        const addContentToPage = async (startY, content) => {
            let currentY = startY;

            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, 'text/html');
            const elements = htmlDoc.body.childNodes;

            for (const element of elements) {
                pdf.setFont("YourCustomFontName", "normal");
                pdf.setFontSize(12);

                if (element.nodeName === 'P' || element.nodeName === '#text') {
                    const textNodes = element.childNodes;
                    for (const textNode of textNodes) {
                        let text = textNode.textContent || '';

                        if (textNode.nodeName === 'STRONG' || textNode.nodeName === 'B') {
                            pdf.setFont("YourCustomFontName", "bold");
                        } else if (textNode.nodeName === 'EM' || textNode.nodeName === 'I') {
                            pdf.setFont("YourCustomFontName", "italic");
                        }
                        else if (textNode.nodeName === 'U') {
                            pdf.setFont("YourCustomFontName", "underline");
                        }
                        else {
                            pdf.setFont("YourCustomFontName", "normal");
                        }

                        const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
                        for (const line of lines) {
                            const lineHeight = pdf.getTextDimensions(line).h;
                            if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                                pdf.addPage();
                                pageNumber++;
                                currentY = headerHeight; // Reset Y position after new page
                                if ((headerTitle && headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
                                    currentY = headerHeight + 17;
                                }
                                await addHeader(); // Add header on new page
                                addFooter(); // Add footer on new page
                            }
                            pdf.text(line, margin, currentY);
                            currentY += lineHeight;
                        }
                        currentY += 5;

                        if (textNode.nodeName === 'IMG') {
                            const img = textNode;
                            const imgUrl = img.getAttribute('src');
                            const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                            const image = new Image();
                            image.src = imgUrl;

                            await new Promise((resolve) => {
                                image.onload = async () => {
                                    const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                                    const imgHeight = (imgWidth * image.height) / image.width;

                                    if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                        pdf.addPage();
                                        pageNumber++;
                                        currentY = headerHeight + 7; // Reset Y position after new page
                                        await addHeader(); // Add header on new page
                                        addFooter(); // Add footer on new page
                                    }

                                    pdf.addImage(imgUrl, imgFormat, margin, currentY, imgWidth, imgHeight);
                                    currentY += imgHeight + 5;

                                    resolve();
                                };
                                image.onerror = () => {
                                    console.error('Failed to load image:', imgUrl);
                                    resolve();
                                };
                            });
                        }
                    }
                } else if (element.nodeName.startsWith('H')) {
                    const text = element.textContent || '';
                    const headerLevel = parseInt(element.nodeName.charAt(1));
                    pdf.setFontSize(24 - headerLevel * 2);
                    pdf.setFont("YourCustomFontName", "bold");

                    const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
                    for (const line of lines) {
                        const lineHeight = pdf.getTextDimensions(line).h;
                        if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                            pdf.addPage();
                            pageNumber++;
                            currentY = headerHeight + 7; // Reset Y position after new page
                            await addHeader(); // Add header on new page
                            addFooter(); // Add footer on new page
                        }
                        pdf.text(line, margin, currentY);
                        currentY += lineHeight;
                    }
                    currentY += 5;
                } else if (element.nodeName === 'UL' || element.nodeName === 'OL') {
                    const isOrdered = element.nodeName === 'OL';
                    const items = element.getElementsByTagName('LI');
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        let itemText = '';
                        const itemChildren = item.childNodes;

                        for (const child of itemChildren) {
                            if (child.nodeName === 'IMG') {
                                const img = child;
                                const imgUrl = img.getAttribute('src');
                                const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                                const image = new Image();
                                image.src = imgUrl;

                                await new Promise((resolve) => {
                                    image.onload = async () => {
                                        const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                                        const imgHeight = (imgWidth * image.height) / image.width;

                                        if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                            pdf.addPage();
                                            pageNumber++;
                                            currentY = headerHeight + 7; // Reset Y position after new page
                                            await addHeader(); // Add header on new page
                                            addFooter(); // Add footer on new page
                                        }

                                        const bullet = isOrdered ? `${i + 1}. ` : '• ';
                                        pdf.text(bullet, margin, currentY);
                                        pdf.addImage(imgUrl, imgFormat, margin + 10, currentY - 3, imgWidth, imgHeight);
                                        currentY += imgHeight + 5;

                                        resolve();
                                    };
                                    image.onerror = () => {
                                        console.error('Failed to load image:', imgUrl);
                                        resolve();
                                    };
                                });
                            } else if (child.nodeType === Node.TEXT_NODE) {
                                itemText += child.textContent || '';
                            }
                        }

                        if (itemText) {
                            const bullet = isOrdered ? `${i + 1}. ` : '• ';
                            const lines = pdf.splitTextToSize(bullet + itemText, pageWidth - 2 * margin);
                            for (const line of lines) {
                                const lineHeight = pdf.getTextDimensions(line).h;
                                if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                                    pdf.addPage();
                                    pageNumber++;
                                    currentY = headerHeight + 7; // Reset Y position after new page
                                    await addHeader(); // Add header on new page
                                    addFooter(); // Add footer on new page
                                }
                                pdf.text(line, margin, currentY);
                                currentY += lineHeight;
                            }
                            currentY += 5;
                        }
                    }
                } else if (element.nodeName === 'IMG') {
                    const img = element;
                    const imgUrl = img.getAttribute('src');
                    const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                    const image = new Image();
                    image.src = imgUrl;

                    await new Promise((resolve) => {
                        image.onload = async () => {
                            const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                            const imgHeight = (imgWidth * image.height) / image.width;

                            if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                pdf.addPage();
                                pageNumber++;
                                currentY = headerHeight + 7; // Reset Y position after new page
                                await addHeader(); // Add header on new page
                                addFooter(); // Add footer on new page
                            }

                            pdf.addImage(imgUrl, imgFormat, margin, currentY, imgWidth, imgHeight);
                            currentY += imgHeight + 5;

                            resolve();
                        };
                        image.onerror = () => {
                            console.error('Failed to load image:', imgUrl);
                            resolve();
                        };
                    });
                }
            }
        };
        // Add header and footer to the first page
        await addHeader();
        addFooter();
        // Add content to the first page
        await addContentToPage(currentY, content);
        // Save the PDF
        pdf.save(`${formFields && formFields.title}.pdf`);
    };
    const handlePdfDownload = async () => {
        console.log("Editor Content:", formFields && formFields.contentText);

        // Normalize HTML content
        const normalizedContent = normalizeHtmlContent(formFields && formFields.contentText);
        console.log("Normalized Content:", normalizedContent);

        // Generate PDF with normalized content
        await pdfPrint(headerTitle, normalizedContent);
    };

    const normalizeHtmlContent = (html) => {
        // Create a temporary DOM element to parse and normalize the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const body = doc.body;

        // Remove extra spaces and line breaks inside <p> tags
        const paragraphs = body.getElementsByTagName('p');
        for (let p of paragraphs) {
            p.innerHTML = p.innerHTML
                .replace(/[\r\n]+/g, ' ')  // Replace newlines with a space
                .replace(/\s{2,}/g, ' ')   // Replace multiple spaces with a single space
                .trim();
        }

        // Remove unnecessary line breaks and spaces in other tags if needed
        body.innerHTML = body.innerHTML
            .replace(/[\r\n]+/g, ' ')  // Replace newlines with a space
            .replace(/\s{2,}/g, ' ')   // Replace multiple spaces with a single space
            .trim();

        return body.innerHTML;
    };
    console.log("formFields:", formFields);
    console.log("newPath:", newPath);
    console.log("detailsErrorMessage:", props.detailsErrorMessage);
    console.log("errors:", errors);


    return (
        <>
            {props.isUpdateEditDocsSuccess ?
                (<Navigate to={newPath} />) :

                (<React.Fragment>
                    <div className="page-content">
                        <Container fluid={true}>
                            <Row>
                                <Col>
                                    <Card>
                                        <Form>
                                            <CardBody >
                                                {(props.errorMessage || props.detailsErrorMessage) && (
                                                    <VWMessage type="danger" defaultMessage={props.errorMessage || props.detailsErrorMessage} baseClassName="alert"></VWMessage>
                                                )}
                                                <CardTitle className="h4 card-title-container">
                                                    <FormGroup>
                                                        <Input
                                                            style={{ width: '230px', marginRight: '16px' }}
                                                            className='title-input'
                                                            type="text"
                                                            name="title"
                                                            placeholder="Enter Document Title"
                                                            value={formFields.title}
                                                            onChange={handleTitleChange}
                                                            disabled={formFields && !formFields.isEditable}
                                                            maxLength={maxLength}
                                                            invalid={errors && errors.title}
                                                        />
                                                        <FormFeedback>{errors && errors.title}</FormFeedback>
                                                    </FormGroup>
                                                    <div className="button-group">
                                                        {(formFields && formFields.isEditable) && (
                                                            <>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={handleOpenHeaderModal}
                                                                ><FontAwesomeIcon icon={faAdd} style={{ marginRight: '8px' }} />
                                                                    INSERT HEADER
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={handlePdfDownload}
                                                                >
                                                                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: '8px' }} />
                                                                    DOWNLOAD
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    className="share-button"
                                                                    onClick={handleOpenModal}
                                                                >
                                                                    <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '8px' }} />
                                                                    SHARE
                                                                </Button>

                                                                <Button type="submit" color="primary" onClick={handleSubmit} disabled={props.isPendingUpdate}>
                                                                    {props.isPendingUpdate ? <>
                                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                                        &nbsp; Saving...
                                                                    </>
                                                                        : <> <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                                                                            SAVE</>}
                                                                </Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </CardTitle>
                                                {(!props.isPendingDetails && (formFields && formFields.isEditable)) ?
                                                    <div className="quill-container">
                                                        {''}
                                                        <ReactQuill
                                                            value={formFields && formFields.contentText}
                                                            onChange={handleEditorChange}
                                                            theme="snow"
                                                            placeholder="Start From Here..."
                                                            readOnly={!(formFields && formFields.isEditable)}
                                                            modules={{
                                                                toolbar: [
                                                                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                                    ['blockquote', 'code-block'],
                                                                    ['link', 'image', 'video', 'formula'],

                                                                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                                                                    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                                                                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                                                                    [{ 'direction': 'rtl' }],                         // text direction

                                                                    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                                                                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                                    [{ 'font': [] }],
                                                                    [{ 'align': [] }],

                                                                    ['clean']
                                                                ],
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    props.isPendingDetails ?
                                                        <div className="sticky-spinner-row">
                                                            <div className="position-relative">
                                                                <div className="d-flex justify-content-center align-items-center"
                                                                    style={{
                                                                        position: 'fixed',
                                                                        top: '50%',
                                                                        left: '60%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        zIndex: 1000,
                                                                        width: '100vw',
                                                                        height: '100vh',
                                                                        pointerEvents: 'none',
                                                                    }}>
                                                                    <VWSpinner />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="quill-container">
                                                            <ReactQuill
                                                                value={formFields && formFields.contentText}
                                                                onChange={handleEditorChange}
                                                                theme="snow"
                                                                placeholder="Start From Here..."
                                                                readOnly={!(formFields && formFields.isEditable)}
                                                                modules={{ toolbar: false }}
                                                            />
                                                        </div>
                                                }
                                            </CardBody>
                                        </Form>
                                    </Card>
                                    <VWModel
                                        header="UPDATE DOCUMENT"
                                        isOpen={props.isOpenOverAllDocsModal}
                                        handleClose={handleClose}
                                    >
                                        <OverAllDocsEditModal data={formFields}
                                            handleClose={handleClose}
                                            handleDone={handleDone}
                                        />
                                    </VWModel>
                                    <VWModel
                                        header="INSERT HEADER"
                                        isOpen={props.openUpdateOverAllDocsHeader}
                                        handleClose={handleClose}
                                    >
                                        <OverAllDocsEditHeader data={formFields} handleClose={handleClose} handleDone={handleDone} />
                                    </VWModel>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>)
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    isDocsUpdated: state.OverAllDocsList.isDocsUpdated,
    detailsErrorMessage: state.OverAllDocsList.detailsErrorMessage,
    errorMessage: state.OverAllDocsList.updateErrorMessage,
    isPendingDetails: state.OverAllDocsList.isPendingDetails,
    isPendingUpdate: state.OverAllDocsList.isPendingUpdate,
    DocsDetails: state.OverAllDocsList.DocsDetails,
    isUpdateEditDocsSuccess: state.OverAllDocsList.isUpdateEditDocsSuccess,
    isOpenOverAllDocsModal: state.OverAllDocsList.isUpdateOverAllModalOpen,
    openUpdateOverAllDocsHeader: state.OverAllDocsList.openUpdateOverAllDocsHeader,
});

const mapDispatchToProps = (dispatch) => ({
    getOverAllDocsDetailsAction: (id, appid) => dispatch(getOverAllDocsDetailsAction(id, appid)),
    closeUpdateOverAllDocsModalAction: () => dispatch(closeUpdateOverAllDocsModalAction()),
    openUpdateOverAllDocsModalAction: () => dispatch(openUpdateOverAllDocsModal()),
    isUpdateEditDocspending: () => dispatch(isUpdateEditDocspending()),
    updateOverAllDocsAction: (formFields) => dispatch(updateOverAllDocs(formFields)),
    openUpdateOverAllDocsHeaderModalAction: () => dispatch(openUpdateOverAllDocsHeaderModal()),
    closeUpdateOverAllDocsHeaderModalAction: () => dispatch(closeUpdateOverAllDocsHeaderModal()),
    clearUpdateOverAllDocsError: () => dispatch(clearUpdateOverAllDocsError()),
    clearOverAllDocsDetailsError: () => dispatch(clearOverAllDocsDetailsError()),
    clearOverAllDocsDetails: () => dispatch(clearOverAllDocsDetails()),
    isDocumentUpdated: () => dispatch(isDocumentUpdated()),
    clearIsDocumentUpdated: () => dispatch(clearIsDocumentUpdated()),
});
export default connect(mapStateToProps, mapDispatchToProps)(OverAllDocsEdit)