import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    Label,
    Table,
    Tooltip
} from "reactstrap";
import VWModel from "../../../../components/Modal/VWModal";
import {
    clearAccountExportListError,
    closeAccountCreate,
    getAccountCategoryAsync,
    getAccountCategoryAsyncSelect,
    getAccountCategoryAsyncSelectList,
    getAccountCategoryListAsync,
    getAccountCategoryListAsyncSelectList,
    getAccountExportList,
    getAccountsList,
    getIndustryNameAsyncSelect,
    getIndustryNameListAsyncSelect,
    getRegionNameAsyncSelect,
    getRegionNameListAsyncSelect,
    importAccounts,
    isImportAccountPending,
    isImportAccountSuccess,
    openAccountCreate
} from "../../../../store/Modules/crm/Accounts/actions";
import CreateAccount from "./create";
import { getPager } from "../../../../helper/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarAlt, faFont, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import NoRecord from "../../../../components/NoRecords/noRecords";
import Async from "react-select/async"
import VWMessage from "../../../../components/vwMessage/vwMessage";
import Select from 'react-select';
import '../../../Modules/viewList.css'
import moment from "moment-timezone";
import { excelPrint, excelSheetExport } from "../../../../helper/export";
import { ACCOUNT_EXPORT_HEADERS } from "../../../../store/Modules/crm/Accounts/constants";
import UITooltip from "../../../../CommonData/Data/UITooltip";
import { ElementToolTip } from "../../Projects/vwToolTip";
import { importExcelSheet } from "../../../../helper/import";

const statusOptions = [
    { label: 'Active', value: "true" },
    { label: 'Inactive', value: 'false' },
]
const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const CrmAccountList = (props) => {

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { accountsListCount, accountListErrorMessage, isAccountListPending, accountsList, accountsExportList, accountExportListErrorMessage, isAccountExportListPending, currentProfile } = props;
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');
    const [errors, setErrors] = useState({});

    const path = window.location.search;
    const params = new URLSearchParams(path);
    console.log("path:", path);
    console.log("params:", params);

    const StartDate = params.get('startDate');
    const EndDate = params.get('endDate');

    //----------------------------ROLE-------------------------------------------
    const { userAppDetails } = currentProfile || [];
    const appData = userAppDetails && userAppDetails.filter((app) => app.appName === "CRM");
    const { appRoleDetails } = appData.length > 0 ? appData[0] : null;
    const adminRoleData = appRoleDetails && appRoleDetails.filter((role) => role.roleName === "Administrator");
    const isAdmin = adminRoleData && adminRoleData.length > 0;


    const buildQueryCompanyName = () => {
        const { CompanyEmail } = filter || {};
        let searchCompanyName = '';
        if (CompanyEmail) {
            searchCompanyName += `${CompanyEmail.replace(/\+/g, "%2B")}`;
        }
        return searchCompanyName
    }
    const buildQueryAccountOwnerUserEmail = () => {
        const { AccountOwnerUserEmail } = filter || {};
        let searchAccountOwnerUserEmail = '';
        if (AccountOwnerUserEmail) {
            searchAccountOwnerUserEmail += `${AccountOwnerUserEmail}`;
        }
        return searchAccountOwnerUserEmail
    }
    const buildQueryOrgName = () => {
        const { OrgName } = filter || {};
        let searchOrgName = '';
        if (OrgName) {
            searchOrgName += `${OrgName}`;
        }
        return searchOrgName
    }
    const buildQueryRegionName = () => {
        const { RegionName } = filter || {};
        let searchRegionName = '';
        if (RegionName) {
            searchRegionName += `${RegionName}`;
        }
        return searchRegionName
    }
    const buildQueryWebsite = () => {
        const { Website } = filter || {};
        let searchWebsite = '';
        if (Website) {
            searchWebsite += `${Website}`;
        }
        return searchWebsite
    }
    const buildQueryPhoneNo = () => {
        const { PhoneNo } = filter || {};
        let searchPhoneNo = '';
        if (PhoneNo) {
            searchPhoneNo += `${PhoneNo}`;
        }
        return searchPhoneNo
    }
    const buildQueryIsActive = () => {
        const { IsActive } = filter || {};
        let searchIsActive = '';
        if (IsActive) {
            searchIsActive += `${IsActive}`;
        }
        return searchIsActive
    }
    const buildQueryIndustryName = () => {
        const { IndustryName } = filter || {};
        let searchIndustryName = '';
        if (IndustryName) {
            searchIndustryName += `${IndustryName}`;
        }
        return searchIndustryName
    }
    const buildQuerysearchDesignation = () => {
        const { Designation } = filter || {};
        let searchDesignation = '';
        if (Designation) {
            searchDesignation += `${Designation}`;
        }
        return searchDesignation
    }
    const buildQueryResponsibility = () => {
        const { Responsibility } = filter || {};
        let searchResponsibility = '';
        if (Responsibility) {
            searchResponsibility += `${Responsibility}`;
        }
        return searchResponsibility
    }
    const buildQueryGst = () => {
        const { Gst } = filter || {};
        let searchGst = '';
        if (Gst) {
            searchGst += `${Gst}`;
        }
        return searchGst
    }
    const buildQueryAccountCategoryValue = () => {
        const { AccountCategoryValue } = filter || {};
        let searchAccountCategoryValue = '';
        if (AccountCategoryValue) {
            searchAccountCategoryValue += `${AccountCategoryValue}`;
        }
        return searchAccountCategoryValue
    }
    const buildQueryAccountCategoryName = () => {
        const { AccountCategoryName } = filter || {};
        let searchAccountCategoryName = '';
        if (AccountCategoryName) {
            searchAccountCategoryName += `${AccountCategoryName}`;
        }
        return searchAccountCategoryName
    }
    const buildQueryLeadCreatedDate = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedDate = '';
        if (CreatedTimeStamp) {
            searchCreatedDate += `${CreatedTimeStamp}`;
        }
        return searchCreatedDate
    }
    const buildQueryLeadCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }
    const handleIndustryName = (inputValue, callback) => {
        const { getIndustryNameAsyncSelectAction } = props;
        return getIndustryNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const buildQueryCity = () => {
        const { City } = filter || {};
        let searchCity = '';
        if (City) {
            searchCity += `${City}`;
        }
        return searchCity
    }
    const buildQueryState = () => {
        const { State } = filter || {};
        let searchState = '';
        if (State) {
            searchState += `${State}`;
        }
        return searchState
    }
    const buildQueryCountry = () => {
        const { Country } = filter || {};
        let searchCountry = '';
        if (Country) {
            searchCountry += `${Country}`;
        }
        return searchCountry
    }
    const buildQueryCode = () => {
        const { Code } = filter || {};
        let searchCode = '';
        if (Code) {
            searchCode += `${Code}`;
        }
        return searchCode
    }
    const buildQueryAddress1 = () => {
        const { Address1 } = filter || {};
        let searchAddress1 = '';
        if (Address1) {
            searchAddress1 += `${Address1}`;
        }
        return searchAddress1
    }
    const buildQueryAccountContact = () => {
        const { AccountContact } = filter || {};
        let searchAccountContact = '';
        if (AccountContact) {
            searchAccountContact += `${AccountContact}`;
        }
        return searchAccountContact
    }
    const buildQueryLandLineNo = () => {
        const { LandLineNo } = filter || {};
        let searchLandLineNo = '';
        if (LandLineNo) {
            searchLandLineNo += `${LandLineNo}`;
        }
        return searchLandLineNo
    }

    const handleRegionName = (inputValue, callback) => {
        const { getRegionNameAsyncSelectAction } = props;
        return getRegionNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const setPage = (page = 1) => {

        props.getAccountsList(filter, page, PageSize, buildQueryCompanyName(), buildQueryAccountOwnerUserEmail(), buildQueryOrgName(), buildQueryRegionName(), buildQueryWebsite(),
            buildQueryPhoneNo(), buildQueryIsActive(), buildQueryIndustryName(), buildQuerysearchDesignation(), buildQueryResponsibility(), buildQueryGst(), buildQueryAddress1(),
            buildQueryAccountCategoryValue(), buildQueryAccountCategoryName(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy(),
            buildQueryCity(), buildQueryState(), buildQueryCountry(), buildQueryCode(), buildQueryAccountContact(), buildQueryLandLineNo());

        setPager(prevPager => {
            const totalPages = Math.ceil(accountsListCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(accountsListCount, 1, PageSize)
        setPager(currentPager)
    }, [accountsListCount])

    useEffect(() => {
        if (StartDate || EndDate) {
            setFilter({
                ...filter,
                CreatedTimeStamp: StartDate,
                UpdatedTimeStamp: EndDate,
            })
        } else {
            const currentPageNumber = Pager && Pager.currentPage;
            setPage(currentPageNumber);
        }
        props.clearAccountExportListError();
        props.isImportAccountSuccess();
    }, [])

    //------------------------------------------Filter-----------------------------------------------------------
    const handleSearchIndustryName = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            IndustryName: label,
        })
    }
    const handleSearchRegionName = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            RegionName: label,
        })
    }
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const searchStatus = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            IsActive: value,
        })
    }
    const handleAccountCategoryName = (inputValue, callback) => {
        const { getAccountCategoryAsyncSelectAction } = props;
        return getAccountCategoryAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleAccountCategoryValue = (inputValue, callback) => {
        const { getAccountCategoryAsyncSelect } = props;
        return getAccountCategoryAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleSearchAccountCategory = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,

            AccountCategoryValue: label,
        })
    }
    const handleSearchAccountCategoryName = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,

            AccountCategoryName: label,
        })
    }
    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }

    // ----------------------------------------SORTING-----------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedAccountList = [...accountsList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //------------------------------------------ACCOUNT-CREATE-MODAL------------------------------------------------
    const createAccountOpen = () => {
        props.openAccountCreate();
    }
    const handleClose = () => {
        props.closeAccountCreate();
    }

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    console.log("accountListErrorMessage:", accountListErrorMessage);
    console.log("accountsExportList:", accountsExportList);
    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/crm/accounts/details/${Id}`);
    };

    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {

        props.getAccountExport(filter, buildQueryCompanyName(), buildQueryAccountOwnerUserEmail(), buildQueryOrgName(), buildQueryRegionName(), buildQueryWebsite(), buildQueryPhoneNo(),
            buildQueryIsActive(), buildQueryIndustryName(), buildQuerysearchDesignation(), buildQueryResponsibility(), buildQueryGst(), buildQueryAccountCategoryValue(),
            buildQueryAccountCategoryName(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy(), buildQueryAddress1(),
            buildQueryCity(), buildQueryState(), buildQueryCountry(), buildQueryCode(), buildQueryAccountContact(), buildQueryLandLineNo())
            .then((response) => {
                if (response) {
                    const exporAccountList = [];
                    const headerExcel = [
                        { headerName: "Account Name", key: "companyName", headerWidhth: 30 },
                        { headerName: "Account Email", key: "companyEmail", headerWidhth: 50 },
                        { headerName: "Account Owner Name", key: "accountOwnerUserName", headerWidhth: 30 },
                        { headerName: "Account Owner Email", key: "accountOwnerUserEmail", headerWidhth: 50 },
                        { headerName: "Industry", key: "industryName", headerWidhth: 50 },
                        { headerName: "Region", key: "regionName", headerWidhth: 30 },
                        { headerName: "Website", key: "website", headerWidhth: 30 },
                        { headerName: "Phone No", key: "phoneNo", headerWidhth: 30 },
                        { headerName: "Designation", key: "designation", headerWidhth: 30 },
                        { headerName: "Responsibility", key: "responsibility", headerWidhth: 30 },
                        { headerName: "GST", key: "GST", headerWidhth: 30 },
                        { headerName: "Account Category", key: "accountCategoryName", headerWidhth: 30 },
                        { headerName: "Address 1", key: "address1", headerWidhth: 30 },
                        { headerName: "Address 2", key: "address2", headerWidhth: 30 },
                        { headerName: "City", key: "city", headerWidhth: 30 },
                        { headerName: "State", key: "state", headerWidhth: 30 },
                        { headerName: "Country", key: "country", headerWidhth: 30 },
                        { headerName: "Pincode", key: "code", headerWidhth: 30 },
                        { headerName: "Land Line No", key: "landLineNo", headerWidhth: 30 },
                        { headerName: "Account Contact", key: "accountContact", headerWidhth: 30 },
                        { headerName: "Description", key: "description", headerWidhth: 30 },
                        { headerName: "Status", key: "isActive", headerWidhth: 30 },
                        { headerName: "CreatedDate", key: "createdDate", headerWidhth: 30 },
                        { headerName: "CreatedBy", key: "createdBy", headerWidhth: 30 },
                    ]
                    const headers = ACCOUNT_EXPORT_HEADERS;
                    const headerSizes = [20, 50, 30, 20, 20, 20, 20, 30, 20, 20, 20, 10, 10, 10, 10, 20, 20];
                    response && response.forEach((element) => {
                        exporAccountList.push({
                            companyName: element.companyName,
                            companyEmail: element.companyEmail,
                            accountOwnerUserName: element.accountOwnerUserName,
                            accountOwnerUserEmail: element.accountOwnerUserEmail,
                            accountContact: element.accountContact,
                            industryName: element.industryName,
                            regionName: element.regionName,
                            website: element.website,
                            phoneNo: element.phoneNo,
                            designation: element.designation,
                            responsibility: element.responsibility,
                            GST: element.gst,
                            accountCategoryName: element.accountCategoryName ? `${element.accountCategoryName} - ${element.accountCategoryValue}` : "",
                            // accountCategory: element.accountCategoryValue || "N/A",
                            address1: element.address1,
                            address2: element.address2,
                            city: element.city,
                            state: element.state,
                            country: element.country,
                            code: element.code,
                            landLineNo: element.landLineNo,
                            isActive: element.isActive ? "Active" : "Inactive",
                            createdDate: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD MMM YYYY') : "",
                            createdBy: element.createdBy,
                            description: element.description,
                        });
                    });
                    excelSheetExport(headerExcel, exporAccountList, "Account-List")
                    // excelPrint(exporAccountList, "Account-List", headers, headerSizes);
                }
            })
    }

    //---------------------------IMPORT---------------------------------------------
    const [importCardOpen, setImportCardOpen] = useState(false);
    const [excelSheet, setExcelSheet] = useState();
    const [importError, setImportError] = useState();

    const handleImportCard = () => {
        setImportCardOpen(!importCardOpen)
    }

    const handleImport = () => {
        const dataToImport = [
            { headerName: "Account Name", headerWidhth: 30 },
            { headerName: "Account Email", headerWidhth: 50 },
            { headerName: "Account Owner Email", headerWidhth: 30 },
            { headerName: "Industry", headerWidhth: 50 },
            { headerName: "Region", headerWidhth: 30 },
            { headerName: "Website", headerWidhth: 30 },
            { headerName: "Phone No", headerWidhth: 30 },
            { headerName: "Designation", headerWidhth: 30 },
            { headerName: "Responsibility", headerWidhth: 30 },
            { headerName: "GST", headerWidhth: 30 },
            { headerName: "Account Category", headerWidhth: 30 },
            { headerName: "Address1", headerWidhth: 30 },
            { headerName: "Address2", headerWidhth: 30 },
            { headerName: "City", headerWidhth: 30 },
            { headerName: "State", headerWidhth: 30 },
            { headerName: "Country", headerWidhth: 30 },
            { headerName: "Pincode", headerWidhth: 30 },
            { headerName: "Land Line No", headerWidhth: 30 },
            { headerName: "Account Contact", headerWidhth: 30 },
            { headerName: "Description", headerWidhth: 30 },
        ]
        importExcelSheet(dataToImport, "Accounts")
    }

    const selectFile = (e) => {
        const input = e.target;
        const value = input.files[0];
        setExcelSheet(value);
        setErrors({ ...errors, excelSheet: '' });
    }

    const handleSubmit = () => {
        const newErrors = {};
        if (!(excelSheet)) {
            newErrors.excelSheet = 'File is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.importAccounts(excelSheet, filter)
                .then(() => {
                    console.log("It working")
                    setImportCardOpen(false);
                })
                .catch((err) => {
                    console.log("err: ", err);
                    setImportError(err);
                })
        }
    }

    console.log("excelSheet:", excelSheet);

    return (
        <div className="page-content">
            <Card>
                <CardTitle tag="h4"
                    className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                    <strong className='d-flex align-items-center'>ACCOUNTS  -&nbsp;<Badge color="danger" id={'accountCount'}>{isAccountListPending ? 0 : (props && props.accountsListCount || 0)}</Badge>
                        <ElementToolTip id={'accountCount'} name={
                            'Accounts Count'
                        } />
                    </strong>

                    <div className="d-flex gap-2">
                        <Button color="primary" onClick={createAccountOpen}>
                            CREATE
                        </Button>
                        {isAdmin && <Dropdown
                            isOpen={importCardOpen}
                            direction="bottom"
                            toggle={() => {
                                handleImportCard();
                                setImportError();
                                setExcelSheet();
                                setErrors({ ...errors, excelSheet: '' });
                            }}
                        >
                            <DropdownToggle
                                style={{
                                    backgroundColor: "orange",
                                    borderColor: "orange"
                                }}
                            >
                                IMPORT
                            </DropdownToggle>
                            <DropdownMenu style={{ minWidth: "400px", minHeight: "150px" }}>
                                <div className="p-3 pb-0">
                                    <div className="d-flex justify-content-between">
                                        <div className="fs-5 text-dark fw-bold mb-4">
                                            IMPORT ACCOUNTS
                                        </div>
                                        <Link onClick={handleImport}>
                                            <FontAwesomeIcon icon={faDownload} /> Template
                                        </Link>
                                    </div>
                                    {importError &&
                                        < VWMessage type="danger" defaultMessage={importError} baseClassName="alert" />
                                    }
                                    <FormGroup>
                                        <Label>Import File <span className="text-danger"> *</span></Label>
                                        <Input type="file" onChange={selectFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            invalid={errors && errors.excelSheet} />
                                        <FormText className="text-nowrap">
                                            Note: Use the template above to validate and import accounts.
                                        </FormText>
                                        {<FormFeedback>{errors && errors.excelSheet}</FormFeedback>}
                                    </FormGroup>
                                    <FormGroup>
                                        <Button
                                            className="me-3"
                                            color="success"
                                            onClick={handleSubmit}
                                            disabled={props.isImportAccountPending}
                                        >
                                            {props.isImportAccountPending ? <>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                &nbsp; Submitting...
                                            </>
                                                : <>Submit</>}
                                        </Button>
                                        <Button
                                            color="danger"
                                            onClick={() => {
                                                setImportCardOpen(false)
                                                setExcelSheet()
                                                setErrors({ ...errors, excelSheet: '' });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </FormGroup>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                        }
                        {isAdmin &&
                            <Button
                                className="me-4"
                                color="success"
                                onClick={handleExports}
                                disabled={accountsList && accountsList.length <= 0 || isAccountExportListPending}
                            >
                                {isAccountExportListPending ? 'EXPORTING...' : 'EXPORT'}
                            </Button>
                        }
                    </div>
                </CardTitle>
            </Card>
            <Card>
                <CardBody className="p-4">
                    {(accountListErrorMessage || accountExportListErrorMessage) ? <div className='w-100'>
                        {(accountExportListErrorMessage || accountListErrorMessage) && (
                            <VWMessage type="danger" defaultMessage={accountListErrorMessage || accountExportListErrorMessage} baseClassName="alert" ></VWMessage>
                        )}
                    </div> : ""}
                    <div style={{ height: "60vh", overflowX: "auto" }}>
                        <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                <tr>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CompanyEmail')} className='hand-cursor'>
                                        Account Name &nbsp;
                                        {sortOrder === 'CompanyEmail' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('AccountOwnerUserEmail')} className='hand-cursor'>
                                        Account Owner Name &nbsp;
                                        {sortOrder === 'AccountOwnerUserEmail' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('IndustryName')} className='hand-cursor'>
                                        Industry Type &nbsp;
                                        {sortOrder === 'IndustryName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('RegionName')} className='hand-cursor'>
                                        Region &nbsp;
                                        {sortOrder === 'RegionName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Website')} className='hand-cursor'>
                                        Website &nbsp;
                                        {sortOrder === 'Website' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('PhoneNo')} className='hand-cursor'>
                                        Mobile # &nbsp;
                                        {sortOrder === 'PhoneNo' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Designation')} className='hand-cursor'>
                                        Designation &nbsp;
                                        {sortOrder === 'Designation' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Responsibility')} className='hand-cursor'>
                                        Responsibility &nbsp;
                                        {sortOrder === 'Responsibility' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Gst')} className='hand-cursor'>
                                        GST # &nbsp;
                                        {sortOrder === 'Gst' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('AccountCategoryName')} className='hand-cursor'>
                                        Category Name &nbsp;
                                        {sortOrder === 'AccountCategoryName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('AccountCategoryValue')} className='hand-cursor'>
                                        Category &nbsp;
                                        {sortOrder === 'AccountCategoryValue' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Address1')} className='hand-cursor'>
                                        Address &nbsp;
                                        {sortOrder === 'Address1' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('City')} className='hand-cursor'>
                                        City &nbsp;
                                        {sortOrder === 'City' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('State')} className='hand-cursor'>
                                        State &nbsp;
                                        {sortOrder === 'State' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Country')} className='hand-cursor'>
                                        Country &nbsp;
                                        {sortOrder === 'Country' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Code')} className='hand-cursor'>
                                        Pin Code &nbsp;
                                        {sortOrder === 'Code' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('LandLineNo')} className='hand-cursor'>
                                        Landline No  &nbsp;
                                        {sortOrder === 'LandLineNo' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('AccountContact')} className='hand-cursor'>
                                        Account Contact &nbsp;
                                        {sortOrder === 'AccountContact' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('IsActive')} className='hand-cursor'>
                                        Status &nbsp;
                                        {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                        Created By &nbsp;
                                        {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>View</th>
                                </tr>
                                <tr>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="CompanyEmail"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="AccountOwnerUserEmail"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="IndustryName"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleIndustryName}
                                            onChange={handleSearchIndustryName}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="RegionName"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleRegionName}
                                            onChange={handleSearchRegionName}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="Website"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="PhoneNo"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="Designation"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="Responsibility"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="Gst"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="AccountCategoryName"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleAccountCategoryName}
                                            onChange={handleSearchAccountCategoryName}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="AccountCategoryValue"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleAccountCategoryValue}
                                            onChange={handleSearchAccountCategory}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="Address1"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="City"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="State"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="Country"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            onKeyDown={
                                                (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            type="number"
                                            name="Code"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="LandLineNo"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            // size="sm"
                                            name="AccountContact"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            name="IsActive"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            options={statusOptions}
                                            onChange={searchStatus}
                                        />
                                    </th>
                                    <th>
                                        <InputGroup style={{ width: "200px" }}>
                                            {filterSelectionOption === 'name' ?
                                                <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                    <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                    <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                style={{ width: "120px" }}
                                                type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                max={filterSelectionOption !== "name" && "9999-12-31"}
                                            />
                                        </InputGroup>
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                </tr>
                            </thead>
                            <tbody>{console.log("isAccountListPending: ", isAccountListPending)}
                                {!isAccountListPending && (accountsList && accountsList.length > 0) ?
                                    sortedAccountList && sortedAccountList.map((account, index) => (
                                        <tr key={index} onDoubleClick={() => OnDoubleClickAction(account.Id)}>
                                            <td className='ps-3'>
                                                {account.CompanyName || "N/A"}
                                                <br />
                                                <small>{account.CompanyEmail || ""}</small>
                                            </td>
                                            <td className='ps-3'>
                                                {account.AccountOwnerUserName || "N/A"}
                                                <br />
                                                <small>{account.AccountOwnerUserEmail || ""}</small>
                                            </td>
                                            <td className='ps-3'>{account.IndustryName || "N/A"}</td>
                                            <td className='ps-3'>{account.RegionName || "N/A"}</td>
                                            <td className='ps-3'>{account.Website || "N/A"}</td>
                                            <td className='ps-3'>{account.PhoneNo || "N/A"}</td>
                                            <td className='ps-3'>{account.Designation || "N/A"}</td>
                                            <td className='ps-3'>{account.Responsibility || "N/A"}</td>
                                            <td className='ps-3'>{account.Gst || "N/A"}</td>
                                            <td className='ps-3'>{account.AccountCategoryName || "N/A"}</td>
                                            <td className='ps-3'>{account.AccountCategoryValue || "N/A"}</td>
                                            <td className='ps-3'>{account.Address1 || "N/A"}</td>
                                            <td className='ps-3'>{account.City || "N/A"}</td>
                                            <td className='ps-3'>{account.State || "N/A"}</td>
                                            <td className='ps-3'>{account.Country || "N/A"}</td>
                                            <td className='ps-3'>{account.Code || "N/A"}</td>
                                            <td className='ps-3'>{account.LandLineNo || "N/A"}</td>
                                            <td className='ps-3'>{account.AccountContact || "N/A"}</td>
                                            <td className='ps-3'> {account.IsActive === true ? (
                                                <Badge color="success">Active</Badge>
                                            ) : (
                                                <Badge color="danger">Inactive</Badge>
                                            )}</td>
                                            <td>
                                                {account.CreatedBy || "N/A"}<br />
                                                <small>{account.CreatedTimeStamp ? moment(account.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                            </td>
                                            <td className='sticky-column' style={{ backgroundColor: "white" }}>
                                                <Link className="btn btn-sm btn-light" to={`/crm/accounts/details/${account.Id}`}>
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                        </tr>)) :
                                    isAccountListPending ?
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <VWSpinner />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <NoRecord />
                                                </div>
                                            </div>
                                        </div>}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <CardFooter>
                    {accountsList && accountsList.length > 0 ?
                        <VWPagination pager={Pager} setPage={setPage} totalRecords={accountsListCount} pageSize={PageSize} /> : null
                    }
                </CardFooter>
            </Card>
            <VWModel
                header="CREATE ACCOUNT"
                modalSize="extra-large"
                isOpen={props.isCreateAccountOpen}
                handleClose={handleClose}
            >
                <CreateAccount handleClose={handleClose} filteredData={filter} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isImportAccountPending: state.crmAccounts.isImportAccountPending,
    isCreateAccountOpen: state.crmAccounts.isCreateAccountOpen,
    accountsList: state.crmAccounts.accountsList,
    accountsListCount: state.crmAccounts.accountsListCount,
    isAccountListPending: state.crmAccounts.isAccountListPending,
    accountListErrorMessage: state.crmAccounts.accountListErrorMessage,
    accountsExportList: state.crmAccounts.accountsExportList,
    isAccountExportListPending: state.crmAccounts.isAccountExportListPending,
    accountExportListErrorMessage: state.crmAccounts.accountExportListErrorMessage,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    clearAccountExportListError: () => dispatch(clearAccountExportListError()),
    openAccountCreate: () => dispatch(openAccountCreate()),
    closeAccountCreate: () => dispatch(closeAccountCreate()),
    isImportAccountSuccess: () => dispatch(isImportAccountSuccess()),
    getAccountExport: (filter, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo) => dispatch(getAccountExportList(filter, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo)),
    getRegionNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getRegionNameListAsyncSelect(searchValue, callback)),
    getIndustryNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getIndustryNameListAsyncSelect(searchValue, callback)),
    getAccountCategoryAsyncSelectAction: (searchValue, callback) =>
        dispatch(getAccountCategoryListAsyncSelectList(searchValue, callback)),
    getAccountCategoryAsyncSelect: (searchValue, callback) =>
        dispatch(getAccountCategoryListAsync(searchValue, callback)),
    getAccountsList: (filter, page, PageSize, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo) => dispatch(getAccountsList(filter, page, PageSize, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo)),
    importAccounts: (excelSheet, filter) => dispatch(importAccounts(excelSheet, filter))
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmAccountList)