import { CLEAR_HOLIDAY_MASTER_DETAILS_ERROR, CLEAR_HOLIDAY_MASTER_ERROR, CLEAR_HOLIDAY_MASTER_EXPORT_LIST_ERROR, CLEAR_HOLIDAY_MASTER_LIST_ERROR, CLEAR_UPDATE_HOLIDAY_MASTER_ERROR, CLOSE_CREATE_HOLIDAY_MODAL, CLOSE_DELETE_HOLIDAY_MODAL, CLOSE_EDIT_HOLIDAY_MODAL, CREATE_HOLIDAY_MASTER_ACTION_SUCCESS, CREATE_HOLIDAY_MASTER_ERROR, CREATE_HOLIDAY_MASTER_PENDING, DELETE_HOLIDAY_ERROR, DELETE_HOLIDAY_PENDING, OPEN_CREATE_HOLIDAY_MODAL, OPEN_DELETE_HOLIDAY_MODAL, OPEN_EDIT_HOLIDAY_MODAL, SET_HOLIDAY_MASTER_DETAILS, SET_HOLIDAY_MASTER_DETAILS_ERROR, SET_HOLIDAY_MASTER_DETAILS_PENDING, SET_HOLIDAY_MASTER_EXPORT_LIST, SET_HOLIDAY_MASTER_EXPORT_LIST_ERROR, SET_HOLIDAY_MASTER_EXPORT_PENDING, SET_HOLIDAY_MASTER_LIST, SET_HOLIDAY_MASTER_LIST_ERROR, SET_HOLIDAY_MASTER_PENDING, UPDATE_HOLIDAY_MASTER_ERROR, UPDATE_HOLIDAY_MASTER_PENDING, UPDATE_HOLIDAY_MASTER_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    HolidayMasterList: [],
    holidayMasterListError: null,
    totalRecords: 0,
    isHolidayMasterPending: false,
    holidayMasterDetails: null,
    isHolidayMasterDetailsPending: false,
    holidayMasterDetailsError: null,
    isCreateHolidayMasterModalOpen: false,
    isEditHolidayMasterModalOpen: false,
    isUpdateHolidayMasterPending: false,
    updateHolidayMasterErrorMessage: null,
    isDeleteHolidayMasterModalOpen: false,
    isDeleteHolidayMasterPending: false,
    holidayMasterExportListError: null,
    isHolidayMasterExportPending: false,
    HolidayMasterExportList: []

};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_HOLIDAY_MASTER_LIST: {
            const { holidayMasterList } = action.payload;
            return {
                ...localState,
                isHolidayMasterPending: false,
                HolidayMasterList: holidayMasterList && holidayMasterList.value,
                totalRecords: holidayMasterList["@odata.count"],
            }
        }
        case SET_HOLIDAY_MASTER_EXPORT_LIST: {
            const { holidayMasterExportList } = action.payload;
            return {
                ...localState,
                isHolidayMasterExportPending: false,
                HolidayMasterExportList: holidayMasterExportList,
            }
        }
        case SET_HOLIDAY_MASTER_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isHolidayMasterPending: false,
                holidayMasterListError: errorMessage,
            };
        }
        case SET_HOLIDAY_MASTER_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isHolidayMasterExportPending: false,
                holidayMasterExportListError: errorMessage,
            };
        }
        case CLEAR_HOLIDAY_MASTER_LIST_ERROR: {
            return {
                ...localState,
                holidayMasterListError: null,
            };
        }
        case CLEAR_HOLIDAY_MASTER_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                holidayMasterExportListError: null,
                isHolidayMasterExportPending: false
            };
        }
        case SET_HOLIDAY_MASTER_PENDING: {
            return {
                ...localState,
                holidayMasterListError: null,
                isHolidayMasterPending: true,
                // totalRecords: 0,
            }
        }
        case SET_HOLIDAY_MASTER_EXPORT_PENDING: {
            return {
                ...localState,
                holidayMasterExportListError: null,
                isHolidayMasterExportPending: true
            }
        }
        case SET_HOLIDAY_MASTER_DETAILS_PENDING: {
            return {
                ...localState,
                holidayMasterDetailsError: null,
                isHolidayMasterDetailsPending: true
            }
        }
        case SET_HOLIDAY_MASTER_DETAILS: {
            const { holidayMasterDetails } = action.payload;
            return {
                ...localState,
                isHolidayMasterDetailsPending: false,
                holidayMasterDetails: holidayMasterDetails,
            }
        }
        case CLEAR_HOLIDAY_MASTER_DETAILS_ERROR: {
            return {
                ...localState,
                holidayMasterDetailsError: null,
            };
        }
        case SET_HOLIDAY_MASTER_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                holidayMasterDetails: {},
                isHolidayMasterDetailsPending: false,
                holidayMasterDetailsError: errorMessage,
            };
        }
        case OPEN_CREATE_HOLIDAY_MODAL: {
            return {
                ...localState,
                isCreateHolidayMasterModalOpen: true,
            };
        }
        case OPEN_EDIT_HOLIDAY_MODAL: {
            return {
                ...localState,
                isEditHolidayMasterModalOpen: true,
            };
        }
        case OPEN_DELETE_HOLIDAY_MODAL: {
            return {
                ...localState,
                isDeleteHolidayMasterModalOpen: true,
            };
        }
        case CLOSE_DELETE_HOLIDAY_MODAL: {
            return {
                ...localState,
                isDeleteHolidayMasterModalOpen: false,
                isDeleteHolidayMasterPending: false
            };
        }
        case CLOSE_CREATE_HOLIDAY_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateHolidayMasterModalOpen: false,
            };
        }
        case CLOSE_EDIT_HOLIDAY_MODAL: {
            return {
                ...localState,
                isEditHolidayMasterModalOpen: false,
            };
        }
        case CREATE_HOLIDAY_MASTER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_HOLIDAY_MASTER_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CLEAR_HOLIDAY_MASTER_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CREATE_HOLIDAY_MASTER_ACTION_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_UPDATE_HOLIDAY_MASTER_ERROR: {
            return {
                ...localState,
                updateHolidayMasterErrorMessage: null,
                isUpdateHolidayMasterPending: false,
            };
        }
        case UPDATE_HOLIDAY_MASTER_PENDING: {
            return {
                ...localState,
                isUpdateHolidayMasterPending: true,
            };
        }
        case UPDATE_HOLIDAY_MASTER_SUCCESS: {
            return {
                ...localState,
                isUpdateHolidayMasterPending: false,
            };
        }
        case UPDATE_HOLIDAY_MASTER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateHolidayMasterErrorMessage: errorMessage,
                isUpdateHolidayMasterPending: false,
            };
        }
        case DELETE_HOLIDAY_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case DELETE_HOLIDAY_PENDING: {
            return {
                ...localState,
                isDeleteHolidayMasterPending: true,
            };
        }
        default: {
            return localState;
        }
    }
};