import React, { useEffect, useState } from 'react'
import VWButton from '../../../../components/vwButton/VWButton'
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap'
import { connect } from 'react-redux'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { getOrgNameAsyncSelectActions, getUserNameAsyncSelectAction, updateLeaveMaster, updateLeaveMasterSuccess } from '../../../../store/Modules/LeaveTracker/LeaveMaster/actions'
import Async from 'react-select/async';
import { getLeaveTypeAsyncSelect, getOrgLeaveTypeAsyncSelect } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions'
import Select from "react-select";


const FrequencyOptions = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Yearly', value: 'Yearly' },
]
const LeaveMasterUpdate = (props) => {
    const [formFields, setFormFields] = useState({});
    const [prevUser, setprevUser] = useState([{ label: '', value: '' }]);
    const [prevLeaveType, setPrevLeaveType] = useState([{ label: 'select', value: '' }]);
    const { orgName } = props.currentProfile || {};
    const { username, userId, orgId } = props.LeaveMasterDetails || {};
    const [errors, setErrors] = useState({});
    const [isActive, setisActive] = useState(props.LeaveMasterDetails && props.LeaveMasterDetails.isActive);
    const [prevFrequency, setprevFrequency] = useState([]);
    const [carryForward, setCarryForward] = useState(false);
    const [allowConsecutiveDays, setAllowConsecutiveDays] = useState(false);
    const [autoFocus, setAutoFocus] = useState(true);

    useEffect(() => {
        props.updateLeaveMasterSuccess();
        if (props.LeaveMasterDetails) {
            setFormFields(props.LeaveMasterDetails);
            setCarryForward(props.LeaveMasterDetails.carryForward);
            setAllowConsecutiveDays(props.LeaveMasterDetails.allowConsecutiveDays);
        }
        if (props.LeaveMasterDetails && props.LeaveMasterDetails.username) {
            setprevUser({ label: username, value: userId });
        }
        if (props.LeaveMasterDetails) {
            setPrevLeaveType({ label: props.LeaveMasterDetails && props.LeaveMasterDetails.leaveTypeName, value: props.LeaveMasterDetails && props.LeaveMasterDetails.leaveTypeId });
        }
        if (props.LeaveMasterDetails) {
            setprevFrequency({ label: props.LeaveMasterDetails && props.LeaveMasterDetails.frequency, value: props.LeaveMasterDetails && props.LeaveMasterDetails.frequency });
        }
    }, [])

    const validateOnChange = (event) => {
        console.log("test")
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        console.log("value:", value);
        console.log("name:", input.name);
        console.log("name:", input.value);
        setFormFields({ ...formFields, [input.name]: value });

        if (input.name === 'totalLeaveDays') {
            setErrors({ ...errors, totalLeaveDays: '' });
        }
        if (input.name === 'qty') {
            setErrors({ ...errors, qty: '' });
        }
        if (input.name === 'consecutiveDaysQty') {
            setErrors({ ...errors, consecutiveDaysQty: '' });
        }
    };

    const { userAppDetails } = props && props.currentProfile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "LeaveTracker") ? i.appId : ""
    })
    const handleUser = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        return getUserNameAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
    };

    const handleAddUser = (value) => {
        setFormFields({ ...formFields, userId: value.value });
        setprevUser(value);
        setErrors({ ...errors, userId: '' });
    }

    const handleLoadLeaveType = (inputValue, callback) => {
        const { getLeaveTypeAsyncSelectAction } = props;
        return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleUpdateLeaveMaster();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //--------------------------------------------Update ---------------------------------------------------------
    const handleUpdateLeaveMaster = () => {
        const newErrors = {};
        if (!formFields.qty) {
            newErrors.qty = 'Qty is required';
        }
        if (!formFields.leaveTypeId) {
            newErrors.leaveTypeId = 'Leave type is required';
        }
        if (!formFields.frequency) {
            newErrors.frequency = 'Frequency is required';
        }
        if (!formFields.consecutiveDaysQty) {
            newErrors.consecutiveDaysQty = 'Consecutive days qty is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.EditLeaveMasterAction(formFields);
        }
    };
    const handleAddLeaveTypeChange = (value) => {
        setFormFields({ ...formFields, leaveTypeId: value.value });
        setPrevLeaveType(value);
        setErrors({ ...errors, leaveTypeId: '' });
    }
    const handleChangeFrequencySelect = (e) => {
        console.log("e:", e.value);
        setprevFrequency(e);
        setFormFields({ ...formFields, frequency: e.value });
        setErrors({ ...errors, frequency: '' });
    }

    const handleIsCarryForward = () => {
        const newCarryForward = !carryForward;
        setCarryForward(newCarryForward);
        setFormFields({ ...formFields, carryForward: newCarryForward });
    }

    const handleallowConsecutiveDays = () => {
        const newAllowConsecutiveDays = !allowConsecutiveDays;
        setAllowConsecutiveDays(newAllowConsecutiveDays);
        setFormFields({ ...formFields, allowConsecutiveDays: newAllowConsecutiveDays });
    }
    //-------------------------------------Status Change----------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, isActive: !isActive })
    };

    console.log("formFields:", formFields);
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">
                                User<span className="text-danger"> *</span>
                            </label>
                            <Async
                                autoFocus={true}
                                name="userId"
                                placeholder="Select User"
                                //  className="vm-react-select"
                                isSearchable
                                defaultOptions
                                loadOptions={handleUser}
                                onChange={handleAddUser}
                                value={prevUser}
                                isDisabled
                            />
                            {<span className='text-danger'><small>{errors && errors.userId}</small></span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">
                                Leave Type <span className="text-danger"> *</span>
                            </label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.leaveTypeId) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="leaveTypeId"
                                placeholder="Select Leave Type"
                                isSearchable
                                defaultOptions
                                loadOptions={handleLoadLeaveType}
                                onChange={handleAddLeaveTypeChange}
                                value={prevLeaveType}
                            />
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {errors && errors.leaveTypeId}
                                </div>
                            </FormText>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Qty <span className="text-danger"> *</span></label>
                            <Input
                                onFocus={handleFocus}
                                type="number"
                                name="qty"
                                placeholder="Enter Qty"
                                onChange={(e) => validateOnChange(e)}
                                value={formFields && formFields.qty}
                                invalid={errors && errors.qty}
                            />
                            {<FormFeedback>{errors && errors.qty}</FormFeedback>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Frequency <span className="text-danger"> *</span></label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.frequency) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="frequency"
                                placeholder="Select Frequency"
                                options={FrequencyOptions}
                                onChange={e => handleChangeFrequencySelect(e)}
                                value={prevFrequency}
                            />
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {errors && errors.frequency}
                                </div>
                            </FormText>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Consecutive Days Qty <span className="text-danger"> *</span></label>
                            <Input
                                onFocus={handleFocus}
                                type="number"
                                name="consecutiveDaysQty"
                                placeholder="Enter Consecutive Qty"
                                onChange={(e) => validateOnChange(e)}
                                value={formFields && formFields.consecutiveDaysQty}
                                invalid={errors && errors.consecutiveDaysQty}
                            />
                            {<FormFeedback>{errors && errors.consecutiveDaysQty}</FormFeedback>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">Description</label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="description"
                                placeholder="Enter Description"
                                onChange={(e) => validateOnChange(e)}
                                value={formFields && formFields.description}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="form-group">
                            <label className="col-form-label">Status</label>
                            <ul className="form-unstyled-list form-status-list">
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${!isActive ? 'active' : ''}`}
                                >
                                    Inactive
                                </li>
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${isActive ? 'active' : ''}`}
                                >
                                    Active
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group" style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: '40px' }}>
                            <Label>
                                <Input
                                    onFocus={handleFocus}
                                    type='checkbox'
                                    name='carryForward'
                                    onClick={handleIsCarryForward}
                                    checked={carryForward}
                                />&nbsp;Carry Forward
                            </Label>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group" style={{ display: "flex", alignItems: "center", justifyContent: "start", marginTop: '40px' }}>
                            <Label>
                                <Input
                                    onFocus={handleFocus}
                                    type='checkbox'
                                    name='allowConsecutiveDays'
                                    checked={allowConsecutiveDays}
                                    onClick={handleallowConsecutiveDays}
                                />&nbsp;Allow Consecutive Days
                            </Label>
                        </div>
                    </Col>
                </Row>
                <br></br>
                <div className="form-group">
                    <FormGroup>
                        <Button
                            className="me-2"
                            color="success"
                            onClick={handleUpdateLeaveMaster}
                            disabled={props.isPending}
                        >
                            {props.isPending ? <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                &nbsp; Submitting...
                            </>
                                : <>Submit</>}
                        </Button>
                        <Button
                            color="danger"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </FormGroup>
                </div>
            </>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.leaveMaster.isUpdateLeaveMasterPending,
    errorMessage: state.leaveMaster.updateleaveMasterErrorMessage,
    LeaveMasterDetails: state.leaveMaster.leaveMasterDetails,
    currentProfile: state.login.currentProfile,
});

const mapDispatchToProps = (dispatch) => ({
    EditLeaveMasterAction: (formfield) => dispatch(updateLeaveMaster(formfield)),
    updateLeaveMasterSuccess: () => dispatch(updateLeaveMasterSuccess()),
    getOrgNameAsyncSelectAction: (searchValue, callback, Roles, orgName) =>
        dispatch(getOrgNameAsyncSelectActions(searchValue, callback, Roles, orgName)),
    getUserNameAsyncSelectAction: (appId, searchValue, callback) =>
        dispatch(getUserNameAsyncSelectAction(appId, searchValue, callback)),
    getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
        dispatch(getLeaveTypeAsyncSelect(searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveMasterUpdate)