import { getToken, modifyUserLists } from "../../../helper/common";
import trimmedValues from "../../../helper/trim";
import { clearForm } from "../../form/actions";
import agent from "../../services/agent";
import { showSnackbarStatus } from "../../snackbar/actions";
import { CLEAR_ALL_DOCS_LIST, CLEAR_CREATE_OVER_ALL_DOCS_ERROR, CLEAR_CREATE_OVERALL_DOCS, CLEAR_DELETE_OVERALL_DOCS_ERROR, CLEAR_IS_OVERALL_DOCUMENT_UPDATED, CLEAR_OVER_ALL_DOCS_DELETE_ERROR, CLEAR_OVER_ALL_DOCS_DETAILS, CLEAR_OVER_ALL_DOCS_DETAILS_ERROR, CLEAR_OVER_ALL_DOCS_LIST_ERROR, CLEAR_UPDATE_OVER_ALL_DOCS_ERROR, CLOSE_CREATE_OVER_ALL_DOCS_HEADER_MODAL, CLOSE_CREATE_OVER_ALL_DOCS_MODAL, CLOSE_DELETE_OVERALL_DOCS, CLOSE_UPDATE_OVER_ALL_DOCS_HEADER_MODAL, CLOSE_UPDATE_OVER_ALL_DOCS_MODAL, CREATE_OVER_ALL_DOCS_HEADER_MODAL, CREATE_OVER_ALL_DOCS_PENDING, CREATE_OVER_ALL_DOCS_SUCCESS, CREATE_OVER_ALL_VISIONDOCS_ERROR, CREATE_OVERALL_DOCS_TITLE, CREATE_OVERALLDOCS_CONTENT, IS_CREATE_OVER_ALL_DOCS_PENDING, IS_CREATE_OVER_ALL_DOCS_SUCCESS, IS_DELETE_OVER_ALL_DOCS_PENDING, IS_OVERALL_DOCUMENT_UPDATED, IS_UPDATE_OVER_ALL_DOCS_PENDING, IS_UPDATE_OVER_ALL_DOCS_SUCCESS, OPEN_CREATE_OVER_ALL_DOCS_HEADER_MODAL, OPEN_CREATE_OVER_ALL_DOCS_MODAL, OPEN_DELETE_OVERALL_DOCS, OPEN_UPDATE_OVER_ALL_DOCS_HEADER_MODAL, OPEN_UPDATE_OVER_ALL_DOCS_MODAL, OVER_ALL_DOCS_DELETE_ERROR, OVER_ALL_DOCS_DETAILS_ERROR, OVER_ALL_DOCS_DETAILS_PENDING, OVER_ALL_DOCS_DETAILS_SUCCESS, OVER_ALL_DOCS_LIST_ERROR, OVER_ALL_DOCS_LIST_PENDING, OVERALL_DOCS_CREATE_ID, SET_OVER_ALL_DOCS_DETAILS, SET_OVER_ALL_DOCS_LIST, SET_TOGGLE_VIEW_DOCS, UPDATE_OVER_ALL_DOCS_ERROR, UPDATE_OVER_ALL_DOCS_PENDING, UPDATE_OVER_ALL_DOCS_SUCCESS } from "./constants";


export const overAllDocsListPending = () => ({
    type: OVER_ALL_DOCS_LIST_PENDING,
});
export const clearAllDocsList = () => ({
    type: CLEAR_ALL_DOCS_LIST,
});
export const setOverAllDocsList = (list) => ({
    type: SET_OVER_ALL_DOCS_LIST,
    payload: { list },
})
export const ClearVisionDocsListError = () => ({
    type: CLEAR_OVER_ALL_DOCS_LIST_ERROR,
});
export const ClearDeleteOverAllDocsError = () => ({
    type: CLEAR_OVER_ALL_DOCS_DELETE_ERROR,
});
export const OverAllDocsListError = (errorMessage) => ({
    type: OVER_ALL_DOCS_LIST_ERROR,
    payload: { errorMessage },
});
export const openCreateOverAllDocsModalAction = () => ({
    type: OPEN_CREATE_OVER_ALL_DOCS_MODAL,
});
export const closeCreateOverAllDocsModalAction = () => ({
    type: CLOSE_CREATE_OVER_ALL_DOCS_MODAL,
});
export const createOverAllDocsError = (errorMessage) => ({
    type: CREATE_OVER_ALL_VISIONDOCS_ERROR,
    payload: { errorMessage },
});
export const createOverAllDocsPending = () => ({
    type: CREATE_OVER_ALL_DOCS_PENDING,
});
export const clearCreateOverAllDocsError = () => ({
    type: CLEAR_CREATE_OVER_ALL_DOCS_ERROR,
});
export const createOverAllDocsSuccess = () => ({
    type: CREATE_OVER_ALL_DOCS_SUCCESS,
});
export const isCreateEditDocsSuccess = () => ({
    type: IS_CREATE_OVER_ALL_DOCS_SUCCESS,
});
export const isCreateEditDocspending = () => ({
    type: IS_CREATE_OVER_ALL_DOCS_PENDING,
});
export const sendToggleViewDocs = (toggleView) => ({
    type: SET_TOGGLE_VIEW_DOCS,
    payload: { toggleView },
})
export const overAllDocsDetailsPending = () => ({
    type: OVER_ALL_DOCS_DETAILS_PENDING,
});
export const overAllDocsDetailsSuccess = () => ({
    type: OVER_ALL_DOCS_DETAILS_SUCCESS,
});
export const setOverAllDocsDetails = (details) => ({
    type: SET_OVER_ALL_DOCS_DETAILS,
    payload: { details },
})
export const clearOverAllDocsDetailsError = () => ({
    type: CLEAR_OVER_ALL_DOCS_DETAILS_ERROR,
});
export const overAllDocsDetailsError = (errorMessage) => ({
    type: OVER_ALL_DOCS_DETAILS_ERROR,
    payload: { errorMessage },
});
export const OverAllDocsDeleteError = (errorMessage) => ({
    type: OVER_ALL_DOCS_DELETE_ERROR,
    payload: { errorMessage },
});
export const openUpdateOverAllDocsModal = () => ({
    type: OPEN_UPDATE_OVER_ALL_DOCS_MODAL,
});
export const closeUpdateOverAllDocsModalAction = () => ({
    type: CLOSE_UPDATE_OVER_ALL_DOCS_MODAL,
});
export const updateOverAllDocsError = (errorMessage) => ({
    type: UPDATE_OVER_ALL_DOCS_ERROR,
    payload: { errorMessage },
});
export const updateOverAllDocsPending = () => ({
    type: UPDATE_OVER_ALL_DOCS_PENDING,
});
export const clearUpdateOverAllDocsError = () => ({
    type: CLEAR_UPDATE_OVER_ALL_DOCS_ERROR,
});
export const updateOverAllDocsSuccess = () => ({
    type: UPDATE_OVER_ALL_DOCS_SUCCESS,
});
export const isUpdateEditDocsSuccess = () => ({
    type: IS_UPDATE_OVER_ALL_DOCS_SUCCESS,
});
export const isUpdateEditDocspending = () => ({
    type: IS_UPDATE_OVER_ALL_DOCS_PENDING,
});
export const deleteOverAllDocsPending = () => ({
    type: IS_DELETE_OVER_ALL_DOCS_PENDING,
});
export const clearOverAllDocsDetails = () => ({
    type: CLEAR_OVER_ALL_DOCS_DETAILS,
})
export const openCreateOverAllDocsHeaderModalAction = () => ({
    type: OPEN_CREATE_OVER_ALL_DOCS_HEADER_MODAL,
})
export const openUpdateOverAllDocsHeaderModal = () => ({
    type: OPEN_UPDATE_OVER_ALL_DOCS_HEADER_MODAL,
})
export const closeUpdateOverAllDocsHeaderModal = () => ({
    type: CLOSE_UPDATE_OVER_ALL_DOCS_HEADER_MODAL,
})
export const closeCreateVisionDocsHeaderModalAction = () => ({
    type: CLOSE_CREATE_OVER_ALL_DOCS_HEADER_MODAL,
})
export const docsContent = (content) => ({
    type: CREATE_OVERALLDOCS_CONTENT,
    payload: { content },
});
export const createVisionDocsID = (id) => ({
    type: OVERALL_DOCS_CREATE_ID,
    payload: { id },
});
export const ClearCreateOverAllDocsReducer = () => ({
    type: CLEAR_CREATE_OVERALL_DOCS,
});
export const clearDeleteDocsError = () => ({
    type: CLEAR_DELETE_OVERALL_DOCS_ERROR,
});
export const openDeleteOverAllDocsModalAction = () => ({
    type: OPEN_DELETE_OVERALL_DOCS,
});
export const closeDeleteOverAllDocsModalAction = () => ({
    type: CLOSE_DELETE_OVERALL_DOCS,
});
export const docsTitle = (title) => ({
    type: CREATE_OVERALL_DOCS_TITLE,
    payload: { title },
});
export const isDocumentUpdated = () => ({
    type: IS_OVERALL_DOCUMENT_UPDATED,
});
export const clearIsDocumentUpdated = () => ({
    type: CLEAR_IS_OVERALL_DOCUMENT_UPDATED,
});
export const deleteDocsOverAllList = (id, appId, filteredData) => (dispatch) => {
    dispatch(deleteOverAllDocsPending());
    getToken(dispatch).then((accessToken) => {
        agent.VisionDocs.getVisionDocsOverAllDelete(accessToken, id, appId)
            .then(() => {
                dispatch(getOverAllDocsList(appId, filteredData, 1, 20));
                dispatch(closeDeleteOverAllDocsModalAction());
                dispatch(ClearDeleteOverAllDocsError());
                dispatch(showSnackbarStatus('Document deleted successfully'));
            })
            .catch((err) => {
                console.log('deleteDocsList server error', err);
                console.log('deleteDocsList server error', err.response);
                let error;
                if (err.status) {
                    error = err.response && err.response.text
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(OverAllDocsDeleteError(error))
            });
    });
}
export const getOverAllDocsList = (appId, filteredData, page, PageSize, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp) => (dispatch) => {
    dispatch(overAllDocsListPending());
    console.log("appId:", appId);
    getToken(dispatch).then((accessToken) => {
        agent.OverAllDocs.getOverAllDocsList(accessToken, filteredData, page, PageSize, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, appId, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp)
            .then((overAllDocsLists) => {
                console.log("overAllDocsLists:", overAllDocsLists);
                dispatch(setOverAllDocsList(overAllDocsLists));
                dispatch(ClearVisionDocsListError());
            })
            .catch((err) => {
                console.log('getOverAllDocsList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(OverAllDocsListError(error))
            });
    });
}

export const getOrgUserCreateAsyncSelect =
    (userId, appId, searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.OverAllDocs.getUserNameList(accessToken, appId, searchValue)
                    .then((UserLists) => {
                        const { value } = UserLists;
                        const updatedUserList = modifyUserLists(value);
                        const onlyUsers = updatedUserList.filter((item) => {
                            return (item.value !== userId)
                        })
                        //eslint-disable-next-line
                        callback && callback(onlyUsers);
                    })
                    .catch((err) => {
                        console.log('getUserNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
export const getOrgUserNameAsyncSelect =
    (appId, searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.OverAllDocs.getUserNameList(accessToken, appId, searchValue)
                    .then((UserLists) => {
                        const { value } = UserLists;
                        const updatedUserList = modifyUserLists(value);
                        //eslint-disable-next-line
                        callback && callback(updatedUserList);
                    })
                    .catch((err) => {
                        console.log('getUserNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };

export const getUsersNameAsyncSelectAction =
    (appId, userid, searchValue, callback) =>
        (dispatch) => {
            console.log("userid:", userid);
            getToken(dispatch).then((accessToken) => {
                agent.OverAllDocs.getUserNameList(accessToken, appId, searchValue)
                    .then((UserLists) => {
                        const { value } = UserLists;
                        const updatedUserList = modifyUserLists(value);
                        console.log("updatedUserList:", updatedUserList);
                        const onlyUsers = updatedUserList.filter((item) => {
                            return (item.value !== userid)
                        })
                        console.log("onlyUsers:", onlyUsers);
                        //eslint-disable-next-line
                        callback && callback(onlyUsers);
                    })
                    .catch((err) => {
                        console.log('getUserNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };

export const createOverAllDocs = (formFields) => (dispatch) => {
    console.log("formFields:", formFields);
    let dataToSend = {}
    if (!formFields) {
        dispatch(createOverAllDocsError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createOverAllDocsPending());
    dispatch(clearCreateOverAllDocsError());
    getToken(dispatch).then((accessToken) => {
        agent.OverAllDocs.createOverAllDocs(accessToken, dataToSend)
            .then((response) => {
                dispatch(createVisionDocsID(response));
                dispatch(createOverAllDocsSuccess());
                dispatch(clearForm());
                dispatch(closeCreateOverAllDocsModalAction());
                dispatch(getOverAllDocsList(formFields.appId));
                dispatch(showSnackbarStatus('Document created successfully'));
                // dispatch(isCreateEditDocsSuccess());
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createOverAllDocsError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createOverAllDocsError('Network Error'));
                }
                console.log('create Vision Docs server error', err);
                dispatch(isCreateEditDocspending());
                dispatch(clearForm());
                // dispatch(CloseCreateTaskModalAction());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

export const getOverAllDocsDetailsAction = (id, appid) => (dispatch) => {
    dispatch(overAllDocsDetailsPending());
    getToken(dispatch).then((accessToken) => {
        // console.log("accessToken:", accessToken)
        agent.OverAllDocs.getOverAllDocsDetails(accessToken, id, appid).then(
            (DocsDetails) => {
                console.log("DocsDetails:", DocsDetails);
                dispatch(setOverAllDocsDetails(DocsDetails));
                dispatch(clearOverAllDocsDetailsError());
                dispatch(overAllDocsDetailsSuccess());
            }
        )
            .catch((err) => {
                console.log('getOverAllDocsDetailsAction server error', err);
                let error;
                if (err.status === 400) {
                    console.log("err.response:", err.response);
                    error = err.response && err.response && err.response.text;
                    console.log(error);
                    dispatch(showSnackbarStatus(error));
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(overAllDocsDetailsError(error));

            });
    })

}

export const updateOverAllDocs = (formFields) => (dispatch) => {
    console.log("formFields:", formFields);
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateOverAllDocsError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(updateOverAllDocsPending());
    dispatch(clearUpdateOverAllDocsError());
    getToken(dispatch).then((accessToken) => {
        agent.OverAllDocs.updateDocs(accessToken, dataToSend)
            .then(() => {
                dispatch(getOverAllDocsList(formFields.appId));
                dispatch(updateOverAllDocsSuccess());
                dispatch(clearForm());
                dispatch(closeUpdateOverAllDocsModalAction());
                dispatch(showSnackbarStatus('Document updated successfully'));
                    dispatch(isDocumentUpdated());
                // window.location.href = '/visiondocs/list';
                // dispatch(isUpdateEditDocsSuccess());

            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateOverAllDocsError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateOverAllDocsError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Update Vision Docs server error', err);
                dispatch(isUpdateEditDocspending());
                     dispatch(clearIsDocumentUpdated());
                dispatch(clearForm());
                // dispatch(CloseCreateTaskModalAction());
            });
    });
};