import React, { useState } from 'react'
import { connect } from 'react-redux';
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Table
} from 'reactstrap';
import Async from 'react-select/async';
import Select from 'react-select/async';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import {
    CreateProject,
    createProjectSuccess,
    getAdminUserNameAsyncSelect,
    getManagerUserNameAsyncSelectAction,
    getUserNameAsyncSelect
} from '../../../../store/Modules/ProjectPortal/Projects/actions';
import { useEffect } from 'react';

const CreateProjects = (props) => {
    const { filterData } = props;
    console.log("filterData:", filterData);

    const [formFields, setFormFields] = useState({});
    const [UserPermission, setUserPermission] = useState();
    const [isReadWrite, setisReadWrite] = useState([]);
    const [errors, setErrors] = useState({});
    const [autoFocus, setAutoFocus] = useState(true);

    const maxLength = 50;

    const { userAppDetails } = props.currentProfile || {};
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    useEffect(() => {
        props.createProjectSuccess();
    }, [])
    //------------------------------------ Default Options-------------------------------------------------------------
    const handleAdminUser = (inputValue, callback) => {
        const { getAdminUserNameAsyncSelectAction } = props;
        return getAdminUserNameAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handlemanager = (inputValue, callback) => {
        const { getManagerUserNameAsyncSelectAction } = props;
        return getManagerUserNameAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleUser = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        return getUserNameAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    //---------------------------------------Input Field Update--------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'ProjectName') {
            if (value && value.length >= maxLength) {
                setErrors({ ...errors, ProjectName: `Project name cannot exceed ${maxLength} characters.` });
            } else {
                setErrors({ ...errors, ProjectName: '' });
            }
        }
    };
    //--------------------------------------Updating Date Field--------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        // const formattedDate = value ? moment(value).format() : null;
        const formattedDate = value;
        setFormFields({ ...formFields, [name]: formattedDate });
        setErrors({ ...errors, [name]: '' });
    };
    //-----------------------------------Update Manager and User Permission-------------------------------------------
    const handleAddProjectManagerUser = (value) => {
        setFormFields({ ...formFields, projectManagerUserId: value.value })
        setErrors({ ...errors, projectManagerUserId: '' });
    }

    const handleAddProjectUser = (value) => {

        const userID = value.map((item) => ({
            userId: item.value,
            permission: isReadWrite && isReadWrite.find((i) => (i.userId === item.value)) ?
                isReadWrite && isReadWrite.filter((i) => i.userId === item.value)[0].permission
                : item.isUserAdmin ? "Write" : "Read",
        }))

        setisReadWrite(userID);
        setFormFields({ ...formFields, ProjectUsers: userID });
        setUserPermission({ ...UserPermission, value })
    }
    //-------------------------------------Status Change-----------------------------------------------------------
    const handleActiveStatusChange = (userId, Permission) => {
        const updatedUserList = [...isReadWrite];
        const existingIndex = isReadWrite.findIndex((item) => item.userId === userId);
        if (existingIndex !== -1) {
            updatedUserList[existingIndex].permission = Permission
        }
        setisReadWrite(updatedUserList);
        setFormFields({ ...formFields, ProjectUsers: updatedUserList })
    };
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleCreateProject();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //------------------------------------------Create Project----------------------------------------------------
    const handleCreateProject = () => {
        const newErrors = {};

        if (!formFields.ProjectName || !formFields.ProjectName.trim()) {
            newErrors.ProjectName = 'Project name is required';
        }
        if (formFields.ProjectName && formFields.ProjectName.length >= maxLength) {
            newErrors.ProjectName = `Project name cannot exceed ${maxLength} characters.`;
        }
        if (!formFields.projectManagerUserId || !formFields.projectManagerUserId.trim()) {
            newErrors.projectManagerUserId = 'Project manager is required';
        }
        if (!formFields.startDate || !formFields.startDate.trim()) {
            newErrors.startDate = 'Start date is required';
        }
        if (!formFields.endDate || !formFields.endDate.trim()) {
            newErrors.endDate = 'End date is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.CreateProjectAction(formFields, filterData);
        }
    }
    console.log("formFields:", formFields);
    console.log("UserPermission:", UserPermission);
    console.log("errors:", errors);

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
            )}
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Project Name <span className="text-danger"> *</span>
                        </Label>
                        <Input
                            onFocus={handleFocus}
                            autoFocus={true}
                            type="text"
                            name="ProjectName"
                            placeholder="Enter project name"
                            onChange={validateOnChange}
                            value={formFields && formFields.ProjectName}
                            invalid={errors && errors.ProjectName}
                            maxLength={maxLength}
                        />
                        <FormFeedback>{errors && errors.ProjectName}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Project Manager<span className="text-danger"> *</span>
                        </Label>
                        <Select
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.projectManagerUserId) ? 'red' : "silver"
                                })
                            }}
                            name="projectManagerUserId"
                            placeholder="Select project manager"
                            isSearchable
                            defaultOptions
                            loadOptions={handlemanager}
                            onChange={handleAddProjectManagerUser}
                        // isOptionDisabled={(option) =>
                        //     formFields?.ProjectUsers?.some(user => user.userId === option.value)
                        // }
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.projectManagerUserId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Start Date <span className="text-danger"> *</span></Label>
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="startDate"
                            placeholder="Select start date"
                            onChange={validateOnChangeDate}
                            max={formFields.endDate}
                            invalid={errors && errors.startDate}
                        />
                        <FormFeedback>{errors && errors.startDate}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>End Date <span className="text-danger"> *</span></Label>
                        <Input
                            onFocus={handleFocus}
                            type="date"
                            name="endDate"
                            placeholder="Select end date"
                            onChange={validateOnChangeDate}
                            min={formFields.startDate}
                            invalid={errors && errors.endDate}
                        />
                        <FormFeedback>{errors && errors.endDate}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Project Client
                        </Label>
                        <Input
                            onFocus={handleFocus}
                            type="text"
                            name="client"
                            placeholder="Enter project client"
                            onChange={validateOnChange}
                            value={formFields && formFields.client}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Project Description
                        </Label>
                        <Input
                            onFocus={handleFocus}
                            type="textarea"
                            name="Description"
                            placeholder="Enter project description"
                            onChange={validateOnChange}
                            value={formFields && formFields.Description}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Project Users
                        </Label>
                        <Async
                            name="ProjectUsers"
                            styles={{
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 30,
                                }),
                            }}
                            placeholder="Select project users"
                            isSearchable
                            defaultOptions
                            loadOptions={handleUser}
                            isMulti
                            onChange={handleAddProjectUser}
                            isOptionDisabled={(option) => option.value === formFields.projectManagerUserId}
                        />
                        <FormText>
                            <strong>NOTE</strong>: <strong>Project Admins</strong> are always have <strong>write</strong> access.
                            You can't remove or modify their permissions.
                        </FormText>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label>
                            <Input
                                onFocus={handleFocus}
                                className='me-2'
                                type="checkbox"
                                name="allowTimesheetCreate"
                                onChange={validateOnChange}
                            >
                            </Input>
                            Allow Timesheets Autofill
                        </Label>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label>
                            <Input
                                onFocus={handleFocus}
                                className='me-2'
                                type="checkbox"
                                name="AllowFlexibleDueDates"
                                onChange={validateOnChange}
                            >
                            </Input>
                            Allow Flexible Due Date
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            {UserPermission && UserPermission.value && UserPermission.value.length > 0 ?
                <Row>
                    <Col md={6}>
                        <Table className='table-width' responsive borderless>
                            <thead>
                                <tr>
                                    <th>Project User</th>
                                    <th style={{ minWidth: "150px" }}>Permission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {UserPermission && UserPermission.value && UserPermission.value.map((item, index) => (
                                    <tr key={item.value}>
                                        {(index % 2 == 0) ? (
                                            <>
                                                <td>
                                                    {item.assignedUserName}<br />
                                                    {item.IsGlobalReadOnlyUser ?
                                                        "" :
                                                        <small>{item.assignedUserEmail}</small>
                                                    }
                                                </td>
                                                <td style={{ minWidth: "150px" }}>
                                                    <FormGroup>
                                                        <ul className="forms-unstyled-list form-status-list">
                                                            <li
                                                                onClick={() => { !item.isUserAdmin && handleActiveStatusChange(item.value, "Read") }}
                                                                className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Read")).length > 0 ? 'active' : '') : ""}`}
                                                            >
                                                                Read
                                                            </li>
                                                            <li
                                                                onClick={() => handleActiveStatusChange(item.value, "Write")}
                                                                className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Write")).length > 0 ? 'active' : '') : ""}`}
                                                            >
                                                                Write
                                                            </li>
                                                        </ul>
                                                    </FormGroup>
                                                </td>
                                            </>
                                        ) : ''}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    {formFields.ProjectUsers && formFields.ProjectUsers.length > 1 ?
                        <Col md={6}>
                            <Table className='table-width' responsive borderless>
                                <thead>
                                    <tr>
                                        <th>Project User</th>
                                        <th style={{ minWidth: "150px" }}>Permission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {UserPermission && UserPermission.value && UserPermission.value.map((item, index) => (
                                        <tr key={item.value}>
                                            {(index % 2 == 1) ? (
                                                <>
                                                    <td>
                                                        {item.assignedUserName}<br />
                                                        {item.IsGlobalReadOnlyUser ?
                                                            "" :
                                                            <small>{item.assignedUserEmail}</small>
                                                        }
                                                    </td>
                                                    <td style={{ minWidth: "150px" }}>
                                                        <FormGroup>
                                                            <ul className="forms-unstyled-list form-status-list">
                                                                <li
                                                                    onClick={() => { !item.isUserAdmin && handleActiveStatusChange(item.value, "Read") }}
                                                                    className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Read")).length > 0 ? 'active' : '') : ""}`}
                                                                >
                                                                    Read
                                                                </li>
                                                                <li
                                                                    onClick={() => handleActiveStatusChange(item.value, "Write")}
                                                                    className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Write")).length > 0 ? 'active' : '') : ""}`}
                                                                >
                                                                    Write
                                                                </li>
                                                            </ul>
                                                        </FormGroup>
                                                    </td>
                                                </>
                                            ) : ''}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col> : ""}
                </Row>
                : ""
            }
            <br></br>
            <div className="form-group">
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateProject}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </div>
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    isPending: state.ProjectPortal.isPending,
    errorMessage: state.ProjectPortal.errorMessage,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    getAdminUserNameAsyncSelectAction: (searchValue, callback) => dispatch(getAdminUserNameAsyncSelect(searchValue, callback)),
    getUserNameAsyncSelectAction: (appId, searchValue, callback) => dispatch(getUserNameAsyncSelect(appId, searchValue, callback)),
    getManagerUserNameAsyncSelectAction: (appId, searchValue, callback) => dispatch(getManagerUserNameAsyncSelectAction(appId, searchValue, callback)),
    CreateProjectAction: (formFields, filterData) => dispatch(CreateProject(formFields, filterData)),
    createProjectSuccess: () => dispatch(createProjectSuccess())

});
export default connect(mapStateToProps, mapDispatchToProps)(CreateProjects)