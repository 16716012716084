import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Table,
  Badge,
  Row,
  Col,
  Tooltip
} from "reactstrap";
import { connect } from "react-redux";
import {
  Link,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import moment from "moment";
import Badge1 from '@mui/material/Badge';
import VWModel from "../../../../components/Modal/VWModal";
import VWPagination from "../../../../components/vwPagination/vwPagination"
import CreateTimeSheets from "./CreateTimeSheets";
import {
  clearTimesheetList,
  closeActionTimeSheetDeleteModalAction,
  closeCreateTimeSheetModal,
  getTimeSheetExportListAction,
  getTimeSheetListActionModal,
  openActionTimeSheetDeleteModalAction,
  openCreateTimeSheetModalAction
} from "../../../../store/Modules/Timesheet/myTimesheet/actions";
import { getPager } from "../../../../helper/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import TimeSheetDelete from './TimeSheetDelete';
import VWMessage from "../../../../components/vwMessage/vwMessage";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import { clearSetPath } from "../../../../store/actions";
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from "../../../../helper/export";
import { TIMESHEET_EXPORT_HEADERS } from "../../../../store/Modules/Timesheet/myTimesheet/constants";
import {
  getTaskScopeListAsyncSelectAction,
  getTaskStatusAsyncSelectAction,
  getTaskStatusAsyncSelectTimesheet
} from "../../../../store/Modules/ProjectPortal/Task/actions";
import Async from "react-select/async";
import { ElementToolTip } from "../../Projects/vwToolTip";
import { getProjectDropDownUserListAsyncSelectAction, getUserNameAsyncSelect } from "../../../../store/Modules/ProjectPortal/Projects/actions";

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};
const ScopeOptions = [
  { label: 'Development', value: 'Development' },
  { label: 'Testing', value: 'Testing' },
  { label: 'Discussion', value: 'Discussion' },
  { label: 'Review', value: 'Review' },
  { label: 'Deployment', value: 'Deployment' },
  { label: 'Documentation', value: 'Documentation' },
]
const TimeSheet = (props) => {
  const { isTimesheetExportPending } = props;

  const [all, setAll] = useState(true);
  const [Submitted, setSubmitted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [deleteList, setDeleteList] = useState({});
  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 });
  const [filter, setFilter] = useState();
  const [filterparams, setFilterParams] = useSearchParams();

  const { TimeSheetExportList } = props;
  const path = window.location.search;
  const params = new URLSearchParams(path);
  const id = params.get('filter');
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');
  console.log("startDate:", startDate);
  console.log("endDate:", endDate);
  const [badgeCount, setBadgeCount] = useState(0);
  //-----------------Default Status---------------------------------
  useEffect(() => {
    if (id === "Submitted") {
      setAll(false)
      setSubmitted(true)
      setFilter({
        ...filter,
        Status: "Submitted",
        startDate: startDate || '',
        endDate: endDate || ''
      })
    }
    else if (id === "Approved") {
      console.log('id', id)
      setAll(false);
      setSubmitted(false);
      setRejected(false);
      setApproved(true);
      setFilter({
        ...filter,
        Status: "Approved",
        startDate: startDate || '',
        endDate: endDate || ''
      })
    }
    else if (id === "Rejected") {
      setAll(false);
      setSubmitted(false);
      setRejected(true);
      setApproved(false);
      setFilter({
        ...filter,
        Status: "Rejected",
        startDate: startDate || '',
        endDate: endDate || ''
      })
    }
    else if (id === "All") {
      setAll(true);
      setSubmitted(false);
      setRejected(false);
      setApproved(false);
      setFilter({
        ...filter,
        Status: "All",
        startDate: startDate || '',
        endDate: endDate || ''
      })
    }
    else {
      setFilterParams('filter=All');
      setFilter({
        ...filter,
        Status: "All",
        startDate: startDate || '',
        endDate: endDate || ''
      })
    }
  }, [])

  const buildQueryuserName = () => {
    const { UserName } = filter || {};
    let searchuserName = '';
    if (UserName) {
      searchuserName += `${UserName}`;
    }
    return encodeURIComponent(searchuserName)
  }
  const buildQueryprojectName = () => {
    const { ProjectName } = filter || {};
    let searchprojectName = '';
    if (ProjectName) {
      searchprojectName += `${ProjectName}`;
    }
    return (searchprojectName)
  }
  const buildQuerytaskName = () => {
    const { TaskName } = filter || {};
    let searchtaskName = '';
    if (TaskName) {
      searchtaskName += `${TaskName}`;
    }
    return (searchtaskName)
  }
  const buildQueryCurrentTaskStatus = () => {
    const { CurrentTaskStatus } = filter || {};
    let searchCurrentTaskStatus = '';
    if (CurrentTaskStatus) {
      searchCurrentTaskStatus += `${CurrentTaskStatus}`;
    }
    return (searchCurrentTaskStatus)
  }
  const buildQueryType = () => {
    const { ScopeName } = filter || {};
    let searchType = '';
    if (ScopeName) {
      searchType += `${ScopeName}`;
    }
    return (searchType)
  }
  const buildworkHours = () => {
    const { WorkHours } = filter || {};
    let searchworkHours = '';
    if (WorkHours) {
      searchworkHours += `${WorkHours}`;
    }
    return (searchworkHours)
  }
  const buildworkDate = () => {
    const { WorkDate } = filter || {};
    let searchworkDate = '';
    if (WorkDate) {
      searchworkDate += `${WorkDate}`;
    }
    return (searchworkDate)
  }
  const buildcreatedDateTime = () => {
    const { CreatedDateTime } = filter || {};
    let searchcreatedDateTime = '';
    if (CreatedDateTime) {
      searchcreatedDateTime += `${CreatedDateTime}`;
    }
    return searchcreatedDateTime
  }
  const buildStatus = () => {
    const { Status } = filter || {};
    let searchStatus = '';
    if (Status) {
      searchStatus += `${Status}`;
    }
    return (searchStatus)
  }

  const setPage = (page = 1) => {
    props.getTimeSheetListAction(filter, page, PageSize, buildQueryuserName(), buildQueryprojectName(), buildQuerytaskName(), buildQueryCurrentTaskStatus(),
      buildQueryType(), buildworkHours(), buildworkDate(), buildcreatedDateTime(), buildStatus(), id, startDate, endDate);

    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }
      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    const currentPager = getPager(props.totalCount, 1, PageSize)
    setPager(currentPager)
    setBadgeCount(props.totalCount);
  }, [props.totalCount])

  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleSearchClick = () => {
    setPage();
  }

  const searchType = (option) => {
    const { label, value } = option || {};
    setFilter({
      ...filter,
      ScopeName: label,
    })
  }

  const searchStatus = (option) => {
    const { value } = option || {};
    setFilter({
      ...filter,
      CurrentTaskStatus: value,
    })
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const searchInputValidationDate = (event) => {
    const { value, name } = event.target;
    setFilter({
      ...filter,
      [name]: value,
    })
  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
      props.ClearPath();
    }
  }, [filter])

  useEffect(() => {
    return (() => {
      props.clearTimesheetList();
    })
  }, [])
  //-----------------------------------------Modal open & close-----------------------------------------------------

  const handleOpenTimeSheet = () => {
    props.openCreateTimeSheetModalAction();
  };
  const handleClose = () => {
    props.closeCreateTimeSheetModalAction();
    props.closeActionTimeSheetDeleteModalAction()
  };

  //---------------------------------------Delete List-------------------------------------------------------------

  const selectOnChange = (value, Id) => {
    setDeleteList({
      ...deleteList,
      timesheetId: Id
    })

    props.openActionTimeSheetDeleteModal();
  }
  //------------------------------------------Status filter -----------------------------------------------------
  const handleTaskScope = (inputValue, callback) => {
    const { getTaskScopeAsyncSelectAction } = props;
    return getTaskScopeAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleTaskStatus = (inputValue, callback) => {
    const { getTaskStatusAsyncSelectAction } = props;
    return getTaskStatusAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };

  const handleAllAction = () => {
    setAll(true);
    setRejected(false);
    setApproved(false);
    setSubmitted(false);
    setBadgeCount(0);
    setFilterParams({
      filter: "All"
    })
    setFilter({
      ...filter,
      Status: "All",
    })
  };
  const handleSubmittedAction = () => {
    setAll(false);
    setRejected(false);
    setApproved(false);
    setSubmitted(true);
    setBadgeCount(0);
    setFilterParams({
      filter: "Submitted"
    })
    setFilter({
      ...filter,
      Status: "Submitted",
    })
  };
  const handleRejectedAction = () => {
    setAll(false);
    setRejected(true);
    setApproved(false);
    setSubmitted(false);
    setBadgeCount(0);
    setFilterParams({
      filter: "Rejected"
    })
    setFilter({
      ...filter,
      Status: "Rejected",
    })
  };
  const handleApprovedAction = () => {
    setAll(false);
    setRejected(false);
    setApproved(true);
    setSubmitted(false);
    setBadgeCount(0);
    setFilterParams({
      filter: "Approved"
    })
    setFilter({
      ...filter,
      Status: "Approved",
    })
  };

  // ----------------------------------------SORTING-----------------------------------------------------------//
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedTimesheetList = [...props.TimeSheetList].sort((a, b) => {
    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });


  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };

  const navigate = useNavigate();
  const OnDoubleClickAction = (Id) => {
    navigate(`/timesheets/mytimesheet/details/${Id}`);
  };
  const handleProjectUser = (inputValue, callback) => {
    const { getUserNameAsyncSelectAction } = props;
    return getUserNameAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSearchprojectUser = (Value) => {
    console.log("value:", Value);
    const { label, assignedUserEmail, assignedUserName } = Value || {};
    setFilter({
      ...filter,
      UserName: assignedUserEmail || assignedUserName,
    })
  }
  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getTimeSheetExportListAction(buildQueryuserName(), buildQueryprojectName(), buildQuerytaskName(), buildQueryCurrentTaskStatus(),
      buildQueryType(), buildworkHours(), buildworkDate(), buildcreatedDateTime(), buildStatus(), id, startDate, endDate)
      .then((TimeSheetLists) => {
        const exportMyTimesheetExportList = [];
        const headerExcel = [
          { headerName: "USER NAME", key: "userName", headerWidhth: 30 },
          { headerName: "USER EMAIL", key: "userEmail", headerWidhth: 50 },
          { headerName: "PROJECT NAME", key: "projectName", headerWidhth: 30 },
          { headerName: "TASK NAME", key: "taskName", headerWidhth: 30 },
          { headerName: "TASK CURRENT STATUS", key: "currentTaskStatus", headerWidhth: 30 },
          { headerName: "SCOPE", key: "scopeName", headerWidhth: 20 },
          { headerName: "WORK DATE", key: "workDate", headerWidhth: 20 },
          { headerName: "CREATED DATE", key: "createdDateTime", headerWidhth: 20 },
          { headerName: "STATUS", key: "status", headerWidhth: 20 },
          { headerName: "WORK HOURS", key: "workHours", headerWidhth: 20 },
        ]
        TimeSheetLists && TimeSheetLists.forEach((element) => {
          exportMyTimesheetExportList.push({
            userName: element.userName || "N/A",
            userEmail: element.userEmail || "N/A",
            projectName: element.projectName || "N/A",
            taskName: element.taskName || "N/A",
            currentTaskStatus: element.currentTaskStatus || "N/A",
            scopeName: element.scopeName || "N/A",
            workDate: element.workDate ? moment(element.workDate).format('DD MMM YYYY') : 'N/A',
            createdDateTime: element.createdDateTime ? moment(element.createdDateTime).format('DD MMM YYYY') : 'N/A',
            status: element.status || "N/A",
            workHours: element.workHours || "N/A",

          });
        });
        excelSheetExport(headerExcel, exportMyTimesheetExportList, "My Timesheet-List");
      })
  }
  console.log("filter:", filter);

  const { userAppDetails } = props && props.currentProfile;
  const App = userAppDetails.filter((i) => {
    return (i.appName === "Timesheets") ? i.appId : ""
  })

  console.log("filter:", filter);


  return (
    <React.Fragment>

      <div className="page-content">
        <>
          <Card >
            <CardTitle
              tag="h4"
              className="border-bottom px-4 py-3 mb-0 "
            >
              <Row>
                <Col lg='3' md='3' sm='4' className='d-flex align-items-center ps-0'>
                  <strong className='d-flex align-items-center' > MY TIMESHEET -&nbsp;<Badge color="danger" id={'myTimesheetCount'} style={{ cursor: 'pointer' }}>{props.isTimesheetPending ? 0 : (props && props.totalCount || 0)}</Badge>
                    <ElementToolTip id={'myTimesheetCount'} name={
                      'My Timesheet Count'
                    } /></strong>
                </Col>
                <Col lg='7' md='7' sm='6' className='d-flex align-items-center justify-content-around gap-4 ps-0'>
                  <Row>
                    <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                      <Badge1 badgeContent={props.isTimesheetPending ? null : badgeCount} color="primary" invisible={all !== true}>
                        <Button
                          style={{ width: '100px' }}
                          onClick={handleAllAction}
                          className={all !== true ? "btn btn-outline-secondary" : 'btn btn-outline-secondary active'}
                          outline color="secondary"
                        ><b>All</b>
                        </Button>
                      </Badge1>
                    </Col>
                    <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                      <Badge1 badgeContent={props.isTimesheetPending ? null : badgeCount} color="primary" invisible={Submitted !== true}>
                        <Button
                          style={{ width: '100px' }}
                          onClick={handleSubmittedAction}
                          className={Submitted !== true ? "btn btn-outline-primary " : 'btn btn-outline-primary  active'}
                          outline color="primary"
                        ><b>Submitted</b>
                        </Button>
                      </Badge1>
                    </Col>
                    <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                      <Badge1 badgeContent={props.isTimesheetPending ? null : badgeCount} color="primary" invisible={approved !== true}>
                        <Button
                          style={{ width: '100px' }}
                          onClick={handleApprovedAction}
                          className={approved !== true ? "btn btn-outline-success" : 'btn btn-outline-success active'}
                          outline color="success"
                        ><b>Approved</b>
                        </Button>
                      </Badge1>
                    </Col>
                    <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                      <Badge1 badgeContent={props.isTimesheetPending ? null : badgeCount} color="primary" invisible={rejected !== true}>
                        <Button
                          style={{ width: '100px' }}
                          onClick={handleRejectedAction}
                          className={rejected !== true ? "btn btn-outline-danger " : 'btn btn-outline-danger  active'}
                          outline color="danger"
                        ><b>Rejected</b>
                        </Button>
                      </Badge1>
                    </Col>
                  </Row>
                </Col>

                <Col lg='2' md='2' sm='2' className='d-flex align-items-center justify-content-end pe-0'>
                  <div className="content-heading">
                    <div className="ml-auto d-flex align-items-center pt-1 flex-wrap">
                      <Link
                        type="button"
                        className="btn btn-info me-2 mb-2"
                        onClick={handleOpenTimeSheet}
                      >
                        CREATE
                      </Link>
                      &nbsp;

                      <Button
                        className="me-2 mb-2"
                        color="success"
                        onClick={handleExports}
                        disabled={isTimesheetExportPending || !props.totalCount}
                      >
                        {isTimesheetExportPending ? 'EXPORTING' : 'EXPORT'}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardTitle>
          </Card>
          <Card>

            <>
              <CardBody className="p-4">
                {props.timesheetErrorMessage && (
                  <VWMessage type="danger" defaultMessage={props.timesheetErrorMessage} baseClassName="alert" />
                )}
                <div style={{ height: "60vh", overflowX: "auto" }}>
                  <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                      <tr>
                        <th onClick={() => handleSort('UserName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          User &nbsp;
                          {sortOrder === 'UserName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('ProjectName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          Project Name &nbsp;
                          {sortOrder === 'ProjectName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('TaskName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          Task Name &nbsp;
                          {sortOrder === 'TaskName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('CurrentTaskStatus')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          Task Current Status &nbsp;
                          {sortOrder === 'CurrentTaskStatus' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('ScopeName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          Scope &nbsp;
                          {sortOrder === 'ScopeName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('WorkDate')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          Work Date &nbsp;
                          {sortOrder === 'WorkDate' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('CreatedDateTime')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          Created Date &nbsp;
                          {sortOrder === 'CreatedDateTime' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('Status')} style={{ minWidth: '200px' }} className='hand-cursor' >
                          Status &nbsp;
                          {sortOrder === 'Status' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th onClick={() => handleSort('WorkHours')} style={{ minWidth: '200px' }} className='hand-cursor'>
                          Work Hours &nbsp;
                          {sortOrder === 'WorkHours' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                        </th>
                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                          View
                        </th>
                        {/* <th >
                          Delete
                        </th> */}
                      </tr>
                      <tr>
                        <th >
                          {/* <Input
                            type='text'
                            placeholder='Search'
                            name='UserName'
                            onChange={searchInputValidation}
                            value={filter && filter.UserName}
                            onKeyPress={handleKeyPress}
                          /> */}
                          <Async
                            styles={customStyles}
                            name="UserName"
                            isClearable
                            defaultOptions
                            placeholder="Search"
                            loadOptions={handleProjectUser}
                            onChange={handleSearchprojectUser}
                          />
                        </th>
                        <th >
                          <Input
                            type='text'
                            placeholder='Search'
                            name='ProjectName'
                            onChange={searchInputValidation}
                            value={filter && filter.ProjectName}
                            onKeyPress={handleKeyPress}
                          />
                        </th>
                        <th >
                          <Input
                            type='text'
                            placeholder='Search'
                            name='TaskName'
                            onChange={searchInputValidation}
                            value={filter && filter.TaskName}
                            onKeyPress={handleKeyPress}
                          />
                        </th>
                        <th >
                          <Async
                            styles={customStyles}
                            name="CurrentTaskStatus"
                            isClearable
                            defaultOptions
                            placeholder="Search"
                            loadOptions={handleTaskStatus}
                            onChange={searchStatus}
                          />
                        </th>
                        <th >
                          <Async
                            styles={customStyles}
                            name="ScopeName"
                            isClearable
                            defaultOptions
                            placeholder="Search"
                            loadOptions={handleTaskScope}
                            onChange={searchType}
                          />
                        </th>
                        <th >
                          <Input
                            type='date'
                            placeholder='Search'
                            name='WorkDate'
                            onChange={searchInputValidation}
                            value={filter && filter.WorkDate}
                            onKeyPress={handleKeyPress}
                            max="9999-12-31"
                          />
                        </th>
                        <th >
                          <Input
                            type='date'
                            placeholder='Search'
                            name='CreatedDateTime'
                            onChange={searchInputValidationDate}
                            value={filter && filter.CreatedDateTime}
                            onKeyPress={handleKeyPress}
                            max="9999-12-31"
                          />
                        </th>
                        <th >
                          <Input
                            id="exampleSelect"
                            type="text"
                            name="Status"
                            onChange={searchInputValidationDate}
                            value={filter && filter.Status}
                            disabled
                          />
                        </th>
                        <th >
                          <Input
                            type='text'
                            placeholder='Search'
                            name='WorkHours'
                            onChange={searchInputValidation}
                            value={filter && filter.WorkHours}
                            onKeyPress={handleKeyPress}
                          />
                        </th>
                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!props.isTimesheetPending && (props.TimeSheetList && props.TimeSheetList.length > 0) ?
                        sortedTimesheetList && sortedTimesheetList.map((data) => (

                          <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                            <td className='ps-3'>{data.UserName || "N/A"}<br />
                              <small>{data.UserEmail || ""}</small>
                            </td>
                            <td className='ps-3'>{data.ProjectName || "N/A"}</td>
                            {/* <td className='ps-3'>{data.TaskName ? (data.TaskName && data.TaskName.length > 20) ? data.TaskName && data.TaskName.substring(0, 20) + '...' : data.TaskName : "N/A"}</td> */}
                            <td className='ps-3'>{data.TaskName ? (data.TaskName && data.TaskName.length > 20) ? <><span id={`taskName-${data.Id}`}>{data.TaskName && data.TaskName.substring(0, 20) + '...'}</span>
                              <ElementToolTip
                                id={`taskName-${data.Id}`}
                                name={data.TaskName && data.TaskName.length > 20 ? data.TaskName : ''}
                              >
                                {data.TaskName && data.TaskName.length > 20
                                  ? data.TaskName && data.TaskName.substring(0, 20) + '...'
                                  : data.TaskName}
                              </ElementToolTip>
                            </>
                              : data.TaskName : "N/A"}
                            </td>
                            <td className='ps-3'>{data.CurrentTaskStatus || "N/A"}</td>
                            <td className='ps-3'>{data.ScopeName || "N/A"}</td>
                            <td className='ps-3'>{data.WorkDate ? moment(data.WorkDate).format('DD MMM YYYY') : 'N/A'}</td>
                            <td className='ps-3'>{data.CreatedDateTime ? moment(data.CreatedDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                            <td className='ps-3'>{data.Status || "N/A"}</td>
                            <td className='ps-3'>{`${data.WorkHours} hrs` || "N/A"}</td>
                            <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                              type="button"
                              className="btn btn-sm  btn-light"
                              to={`/timesheets/mytimesheet/details/${data.Id}`}
                            >
                              <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                            </Link></td>
                          </tr>

                        ))
                        : props.isTimesheetPending ? <div className="sticky-spinner-row">
                          <div className="position-relative">
                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                position: 'fixed',
                                top: '50%',
                                left: '60%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                                width: '100vw',
                                height: '100vh',
                                pointerEvents: 'none',
                              }}>
                              <VWSpinner />
                            </div>
                          </div>
                        </div>
                          :
                          <div className="sticky-spinner-row">
                            <div className="position-relative">
                              <div className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: 'fixed',
                                  top: '50%',
                                  left: '60%',
                                  transform: 'translate(-50%, -50%)',
                                  zIndex: 1,
                                  width: '100vw',
                                  height: '100vh',
                                  pointerEvents: 'none',
                                }}>
                                <NoRecord />
                              </div>
                            </div>
                          </div>}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter>
                {props.TimeSheetList && props.TimeSheetList.length > 0 ?
                  <VWPagination
                    pager={Pager}
                    setPage={setPage}
                    totalRecords={props.totalCount}
                    pageSize={PageSize}
                  />
                  : null
                }
              </CardFooter>
            </>
          </Card>
          <VWModel
            modalSize='extra-large'
            header="CREATE TIMESHEET"
            isOpen={props.isCreateTimeSheetModalOpen}
            handleClose={handleClose}
          >
            <CreateTimeSheets
              filteredData={filter}
              handleClose={handleClose}
              appId={App[0] && App[0].appId}
            />
          </VWModel>
          <VWModel
            //eslint-disable-next-line
            centered={true}
            header="DELETE TIMESHEET"
            isOpen={props.isDeleteTimeSheetModalOpen}
            handleClose={handleClose}
          >
            <TimeSheetDelete data={deleteList} handleClose={handleClose} filteredData={filter} />
          </VWModel>
        </>
      </div>

    </React.Fragment >
  )
}

const mapStateToProps = (state) => ({
  isCreateTimeSheetModalOpen: state.TimeSheets.isCreateTimeSheetModalOpen,
  isDeleteTimeSheetModalOpen: state.TimeSheets.isDeleteTimeSheetModalOpen,
  TimeSheetList: state.TimeSheets.TimeSheetList,
  totalCount: state.TimeSheets.totalRecords,
  profile: state.login.profile,
  timesheetErrorMessage: state.TimeSheets.timesheetErrorMessage,
  isTimesheetPending: state.TimeSheets.isTimesheetPending,
  TimeSheetExportList: state.TimeSheets.TimeSheetExportList,
  isTimesheetExportPending: state.TimeSheets.isTimesheetExportPending,
  currentProfile: state.login.currentProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getTimeSheetListAction: (filter, page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours,
    searchworkDate, searchcreatedDateTime, searchStatus, id, startDate, endDate) => dispatch(getTimeSheetListActionModal(filter, page, PageSize,
      searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime,
      searchStatus, id, startDate, endDate)),
  getTimeSheetExportListAction: (searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours,
    searchworkDate, searchcreatedDateTime, searchStatus, id, startDate, endDate) => dispatch(getTimeSheetExportListAction(searchuserName,
      searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchStatus,
      id, startDate, endDate)),
  closeCreateTimeSheetModalAction: () => dispatch(closeCreateTimeSheetModal()),
  openCreateTimeSheetModalAction: () => dispatch(openCreateTimeSheetModalAction()),
  closeActionTimeSheetDeleteModalAction: () => dispatch(closeActionTimeSheetDeleteModalAction()),
  openActionTimeSheetDeleteModal: () => dispatch(openActionTimeSheetDeleteModalAction()),
  ClearPath: () => dispatch(clearSetPath()),
  clearTimesheetList: () => dispatch(clearTimesheetList()),
  getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeListAsyncSelectAction(appId, searchValue, callback)),
  getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectTimesheet(appId, searchValue, callback)),
  getUserNameAsyncSelectAction: (appId, searchValue, callback) => dispatch(getProjectDropDownUserListAsyncSelectAction(appId, searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheet);

