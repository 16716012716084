import { CLEAR_CREATE_GROUP_ERROR, CLEAR_GROUP_DETAILS_ERROR, CLEAR_GROUP_EXPORT_LIST_ERROR, CLEAR_GROUP_LIST_ERROR, CLEAR_UPDATE_GROUP_ERROR, CLOSE_CREATE_GROUP_MODAL, CLOSE_EDIT_GROUP_MODAL, CREATE_GROUP_ERROR, CREATE_GROUP_PENDING, CREATE_GROUP_SUCCESS, OPEN_CREATE_GROUP_MODAL, OPEN_EDIT_GROUP_MODAL, SET_GROUP_DETAILS, SET_GROUP_DETAILS_ERROR, SET_GROUP_DETAILS_PENDING, SET_GROUP_EXPORT_LIST, SET_GROUP_EXPORT_LIST_ERROR, SET_GROUP_EXPORT_LIST_PENDING, SET_GROUP_LIST, SET_GROUP_LIST_ERROR, SET_GROUP_LIST_PENDING, UPDATE_GROUP_ERROR, UPDATE_GROUP_PENDING, UPDATE_GROUP_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    isCreateGroupModalOpen: false,
    isEditGroupModalOpen: false,
    GroupList: [],
    groupListError: null,
    totalRecords: 0,
    groupDetails: null,
    groupDetailsError: null,
    updateGroupErrorMessage: null,
    isUpdateGroupPending: false,
    isGroupListPending: false,
    isGroupDetailsPending: false,
    groupExportListError: null,
    isGroupExportListPending: false,
    GroupExportList: []
};
export default (localState = initialState, action) => {
    switch (action.type) {

        case OPEN_CREATE_GROUP_MODAL: {
            return {
                ...localState,
                isCreateGroupModalOpen: true,
            };
        }
        case OPEN_EDIT_GROUP_MODAL: {
            return {
                ...localState,
                isEditGroupModalOpen: true,
            };
        }

        case CLOSE_CREATE_GROUP_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateGroupModalOpen: false,
            };
        }
        case CLOSE_EDIT_GROUP_MODAL: {
            return {
                ...localState,
                updateGroupErrorMessage: null,
                isEditGroupModalOpen: false,
            };
        }
        case SET_GROUP_LIST: {
            const { groupList } = action.payload;
            return {
                ...localState,
                isGroupListPending: false,
                GroupList: groupList && groupList.value,
                totalRecords: groupList["@odata.count"],
            }
        }
        case SET_GROUP_EXPORT_LIST: {
            const { groupExportList } = action.payload;
            return {
                ...localState,
                isGroupExportListPending: false,
                GroupExportList: groupExportList,
            }
        }
        case SET_GROUP_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isGroupListPending: false,
                groupListError: errorMessage,
            }
        }
        case SET_GROUP_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isGroupExportListPending: false,
                groupExportListError: errorMessage,
            }
        }
        case CLEAR_GROUP_LIST_ERROR: {
            return {
                ...localState,
                groupListError: null,
            }
        }
        case CLEAR_GROUP_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                groupExportListError: null,
                isGroupExportListPending: false,
            }
        }
        case SET_GROUP_DETAILS: {
            const { groupDetails } = action.payload;
            return {
                ...localState,
                isGroupDetailsPending: false,
                groupDetails: groupDetails,
            }
        }
        case SET_GROUP_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                groupDetails: {},
                isGroupDetailsPending: false,
                groupDetailsError: errorMessage,
            }
        }
        case CLEAR_GROUP_DETAILS_ERROR: {
            return {
                ...localState,
                groupDetailsError: null,
            }
        }
        case CREATE_GROUP_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CLEAR_CREATE_GROUP_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CREATE_GROUP_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CREATE_GROUP_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CLEAR_UPDATE_GROUP_ERROR: {
            return {
                ...localState,
                updateGroupErrorMessage: null,
                isUpdateGroupPending: false,
            };
        }
        case UPDATE_GROUP_PENDING: {
            return {
                ...localState,
                isUpdateGroupPending: true,
            };
        }
        case UPDATE_GROUP_SUCCESS: {
            return {
                ...localState,
                isUpdateGroupPending: false,
            };
        }
        case UPDATE_GROUP_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateGroupErrorMessage: errorMessage,
                isUpdateGroupPending: false,
            };
        }
        case SET_GROUP_LIST_PENDING: {
            return {
                ...localState,
                groupListError: null,
                isGroupListPending: true,
                // totalRecords: 0,
            }
        }
        case SET_GROUP_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                groupExportListError: null,
                isGroupExportListPending: true
            }
        }
        case SET_GROUP_DETAILS_PENDING: {
            return {
                ...localState,
                groupDetailsError: null,
                isGroupDetailsPending: true
            }
        }
        default: {
            return localState;
        }
    }
};