import { CLEAR_LEAVE_REPORT_LIST_ERROR, CLEAR_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR, SET_LEAVE_RECORD_LIST, SET_LEAVE_REPORT_PENDING, SET_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR, SET_LEAVE_TRACKER_REPORT_EXPORT_PENDING, SET_LEAVE_TRACKER_REPORT_LIST_ERROR } from "./constants";

const initialState = {
    isLeaveTrackerRecordPending: false,
    leaveTrackerRecordListError: null,
    LeaveTrackerReportList: [],
    totalRecords: 0,
    leaveTrackerRecordExportListError: null,
    isLeaveTrackerRecordExportPending: false
};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_LEAVE_RECORD_LIST: {
            const { leaveRecordList } = action.payload;
            return {
                ...localState,
                isLeaveTrackerRecordPending: false,
                LeaveTrackerReportList: leaveRecordList && leaveRecordList.value,
                totalRecords: leaveRecordList["@odata.count"],
            }
        }
        case SET_LEAVE_REPORT_PENDING: {
            return {
                ...localState,
                leaveTrackerRecordListError: null,
                isLeaveTrackerRecordPending: true,
                // totalRecords: 0,
            }
        }
        case SET_LEAVE_TRACKER_REPORT_EXPORT_PENDING: {
            return {
                ...localState,
                leaveTrackerRecordExportListError: null,
                isLeaveTrackerRecordExportPending: true
            }
        }
        case CLEAR_LEAVE_REPORT_LIST_ERROR: {
            return {
                ...localState,
                leaveTrackerRecordListError: null,
            };
        }
        case CLEAR_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                leaveTrackerRecordExportListError: null,
                isLeaveTrackerRecordExportPending: false
            };
        }
        case SET_LEAVE_TRACKER_REPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveTrackerRecordPending: false,
                leaveTrackerRecordListError: errorMessage,
            };
        }
        case SET_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveTrackerRecordExportPending: false,
                leaveTrackerRecordExportListError: errorMessage,
            };
        }

        default: {
            return localState;
        }
    }
};