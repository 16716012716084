export const OVER_ALL_DOCS_LIST_PENDING = "OVER_ALL_DOCS_LIST_PENDING"
export const SET_OVER_ALL_DOCS_LIST = "SET_OVER_ALL_DOCS_LIST"
export const SET_TOGGLE_VIEW = "SET_TOGGLE_VIEW"
export const CLEAR_OVER_ALL_DOCS_LIST_ERROR = "CLEAR_OVER_ALL_DOCS_LIST_ERROR"
export const OVER_ALL_DOCS_LIST_ERROR = "OVER_ALL_DOCS_LIST_ERROR"
export const CLEAR_ALL_DOCS_LIST = "CLEAR_ALL_DOCS_LIST"
export const OPEN_CREATE_OVER_ALL_DOCS_MODAL = "OPEN_CREATE_OVER_ALL_DOCS_MODAL"
export const CLOSE_CREATE_OVER_ALL_DOCS_MODAL = "CLOSE_CREATE_OVER_ALL_DOCS_MODAL"
export const CREATE_OVER_ALL_VISIONDOCS_ERROR = "CREATE_OVER_ALL_VISIONDOCS_ERROR"
export const CREATE_OVER_ALL_DOCS_PENDING = "CREATE_OVER_ALL_DOCS_PENDING"
export const CLEAR_CREATE_OVER_ALL_DOCS_ERROR = "CLEAR_CREATE_OVER_ALL_DOCS_ERROR"
export const CREATE_OVER_ALL_DOCS_SUCCESS = "CREATE_OVER_ALL_DOCS_SUCCESS"
export const IS_CREATE_OVER_ALL_DOCS_SUCCESS = "IS_CREATE_OVER_ALL_DOCS_SUCCESS"
export const IS_CREATE_OVER_ALL_DOCS_PENDING = "IS_CREATE_OVER_ALL_DOCS_PENDING"
export const SET_TOGGLE_VIEW_DOCS = "SET_TOGGLE_VIEW_DOCS"
export const OVER_ALL_DOCS_DETAILS_PENDING = "OVER_ALL_DOCS_DETAILS_PENDING"
export const SET_OVER_ALL_DOCS_DETAILS = "SET_OVER_ALL_DOCS_DETAILS"
export const CLEAR_OVER_ALL_DOCS_DETAILS_ERROR = "CLEAR_OVER_ALL_DOCS_DETAILS_ERROR"
export const OVER_ALL_DOCS_DETAILS_ERROR = "OVER_ALL_DOCS_DETAILS_ERROR"
export const OPEN_UPDATE_OVER_ALL_DOCS_MODAL = "OPEN_UPDATE_OVER_ALL_DOCS_MODAL"
export const CLOSE_UPDATE_OVER_ALL_DOCS_MODAL = "CLOSE_UPDATE_OVER_ALL_DOCS_MODAL"
export const UPDATE_OVER_ALL_DOCS_ERROR = "UPDATE_OVER_ALL_DOCS_ERROR"
export const UPDATE_OVER_ALL_DOCS_PENDING = "UPDATE_OVER_ALL_DOCS_PENDING"
export const CLEAR_UPDATE_OVER_ALL_DOCS_ERROR = "CLEAR_UPDATE_OVER_ALL_DOCS_ERROR"
export const UPDATE_OVER_ALL_DOCS_SUCCESS = "UPDATE_OVER_ALL_DOCS_SUCCESS"
export const IS_UPDATE_OVER_ALL_DOCS_SUCCESS = "IS_UPDATE_OVER_ALL_DOCS_SUCCESS"
export const IS_UPDATE_OVER_ALL_DOCS_PENDING = "IS_UPDATE_OVER_ALL_DOCS_PENDING"
export const CLEAR_OVER_ALL_DOCS_DETAILS = "CLEAR_OVER_ALL_DOCS_DETAILS"
export const OPEN_CREATE_OVER_ALL_DOCS_HEADER_MODAL = "OPEN_CREATE_OVER_ALL_DOCS_HEADER_MODAL"
export const CLOSE_CREATE_OVER_ALL_DOCS_HEADER_MODAL = "CLOSE_CREATE_OVER_ALL_DOCS_HEADER_MODAL"
export const CREATE_OVERALLDOCS_CONTENT = "CREATE_OVERALLDOCS_CONTENT"
export const OVERALL_DOCS_CREATE_ID = "OVERALL_DOCS_CREATE_ID"
export const CLEAR_CREATE_OVERALL_DOCS = "CLEAR_CREATE_OVERALL_DOCS"
export const CREATE_OVERALL_DOCS_TITLE = "CREATE_OVERALL_DOCS_TITLE"
export const OPEN_UPDATE_OVER_ALL_DOCS_HEADER_MODAL = "OPEN_UPDATE_OVER_ALL_DOCS_HEADER_MODAL"
export const CLOSE_UPDATE_OVER_ALL_DOCS_HEADER_MODAL = "CLOSE_UPDATE_OVER_ALL_DOCS_HEADER_MODAL"
export const OPEN_DELETE_OVERALL_DOCS = "OPEN_DELETE_OVERALL_DOCS"
export const CLOSE_DELETE_OVERALL_DOCS = "CLOSE_DELETE_OVERALL_DOCS"
export const OVER_ALL_DOCS_DELETE_ERROR = "OVER_ALL_DOCS_DELETE_ERROR"
export const CLEAR_DELETE_OVERALL_DOCS_ERROR = "CLEAR_DELETE_OVERALL_DOCS_ERROR"
export const IS_DELETE_OVER_ALL_DOCS_PENDING = "IS_DELETE_OVER_ALL_DOCS_PENDING"
export const CLEAR_OVER_ALL_DOCS_DELETE_ERROR = "CLEAR_OVER_ALL_DOCS_DELETE_ERROR"
export const OVER_ALL_DOCS_DETAILS_SUCCESS = "OVER_ALL_DOCS_DETAILS_SUCCESS"
export const IS_OVERALL_DOCUMENT_UPDATED = "IS_OVERALL_DOCUMENT_UPDATED"
export const CLEAR_IS_OVERALL_DOCUMENT_UPDATED = "CLEAR_IS_OVERALL_DOCUMENT_UPDATED"