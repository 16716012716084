import { CLEAR_MY_TEAM_LEAVE_DETAILS_LIST_ERROR, CLEAR_MY_TEAM_LEAVE_EXPORT_LIST_ERROR, MY_TEAM_LEAVE_DETAILS_LIST_ERROR, MY_TEAM_LEAVE_EXPORT_LIST_ERROR, PENDING_SUBMITTED_COUNT, SET_MY_TEAM_LEAVE_DETAILS_LIST, SET_MY_TEAM_LEAVE_DETAILS_PENDING, SET_MY_TEAM_LEAVE_EXPORT_LIST, SET_MY_TEAM_LEAVE_EXPORT_PENDING, SET_MY_TEAM_LEAVE_EXPORT_SUCCESS } from "./constants";

const initialState = {
    myTeamLeaveDetailsListError: null,
    isMyTeamLeaveDetailsListPending: false,
    MyTeamLeaveDetailsList: [],
    totalRecords: 0,
    submittedCount: [],
    totalSubmittedRecords: 0,
    myTeamLeaveExportListError: null,
    isMyTeamLeaveExportListPending: false,
    MyTeamLeaveExportList: [],
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_MY_TEAM_LEAVE_DETAILS_LIST: {
            const { myTeamLeaveDetails } = action.payload;
            const myTeamLeaveDetailsList = myTeamLeaveDetails || {};
            return {
                ...localState,
                isMyTeamLeaveDetailsListPending: false,
                MyTeamLeaveDetailsList: myTeamLeaveDetailsList && myTeamLeaveDetailsList.value || [],
                totalRecords: myTeamLeaveDetailsList["@odata.count"] || 0,
            }
        }
        case SET_MY_TEAM_LEAVE_EXPORT_LIST: {
            const { myTeamLeaveExport } = action.payload;
            return {
                ...localState,
                isMyTeamLeaveExportListPending: false,
                MyTeamLeaveExportList: myTeamLeaveExport || [],
            }
        }
        case PENDING_SUBMITTED_COUNT: {
            const { submittedCount } = action.payload;
            console.log(submittedCount)
            return {
                ...localState,
                submittedCount: submittedCount && submittedCount.value,
                totalSubmittedRecords: submittedCount["@odata.count"],
            }
        }
        case MY_TEAM_LEAVE_DETAILS_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isMyTeamLeaveDetailsListPending: false,
                myTeamLeaveDetailsListError: errorMessage,
            };
        }
        case MY_TEAM_LEAVE_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isMyTeamLeaveExportListPending: false,
                myTeamLeaveExportListError: errorMessage,
            };
        }
        case CLEAR_MY_TEAM_LEAVE_DETAILS_LIST_ERROR: {
            return {
                ...localState,
                myTeamLeaveDetailsListError: null,
            };
        }
        case CLEAR_MY_TEAM_LEAVE_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                myTeamLeaveExportListError: null,
            };
        }
        case SET_MY_TEAM_LEAVE_DETAILS_PENDING: {
            return {
                ...localState,
                myTeamLeaveDetailsListError: null,
                isMyTeamLeaveDetailsListPending: true,
                // totalRecords: 0
            }
        }
        case SET_MY_TEAM_LEAVE_EXPORT_PENDING: {
            return {
                ...localState,
                myTeamLeaveExportListError: null,
                isMyTeamLeaveExportListPending: true
            }
        }
        case SET_MY_TEAM_LEAVE_EXPORT_SUCCESS: {
            return {
                ...localState,
                myTeamLeaveExportListError: null,
                isMyTeamLeaveExportListPending: false
            }
        }

        default: {
            return localState;
        }
    }
};