import {
    CLEAR_MILESTONE_DATE,
    CLEAR_MILESTONE_PIE_CHART_LIST_ERROR,
    CLEAR_MILESTONE_TASK_DATE_ERROR,
    CLEAR_MILESTONE_TASK_LIST,
    CLEAR_MILESTONE_TASK_LIST_ERROR,
    CLEAR_PROJECT_VALIDATION_ERROR,
    CLEAR_TASK_DETAILS_ERROR,
    CLEAR_TASK_ERROR,
    CLEAR_TASK_EXPORT_LIST_ERROR,
    CLEAR_TASK_LIST,
    CLEAR_TASK_LIST_ERROR,
    CLEAR_UPDATE_TASK_ERROR,
    CLEAR_UPDATE_TASK_STATUS_ERROR,
    CLOSE_CLONE_TASK_MODAL,
    CLOSE_CREATE_TASK_MODAL,
    CLOSE_EDIT_TASK_MODAL,
    CLOSE_EDIT_TASK_STATUS,
    CLOSE_KANBAN_EDIT_TASK_MODAL,
    CREATE_TASK_ERROR,
    CREATE_TASK_PENDING,
    CREATE_TASK_SUCCESS,
    IS_STATUS_TASK_EDIT_OPEN,
    IS_TASK_STATUS_LOADING_BOOLEAN,
    IS_TASK_STATUS_LOADING_BOOLEAN_CLOSE,
    IS_TASK_STATUS_LOADING_BOOLEAN_OPEN,
    KANBAN_LIST_ERROR,
    KANBAN_LIST_SUCCESS,
    KANBAN_TASK_LIST,
    MILESTONE_CREATE_CLOSE,
    MILESTONE_CREATE_OPEN,
    MILESTONE_PIE_CHART_LIST_ERROR,
    MILESTONE_TASK_DATE_ERROR,
    MILESTONE_TASK_LIST_ERROR,
    OPEN_CLONE_TASK_MODAL,
    OPEN_CREATE_TASK_MODAL,
    OPEN_EDIT_TASK_MODAL,
    OPEN_EDIT_TASK_STATUS,
    PROJECT_ACCESS_VALIDATION_ERROR,
    PROJECT_ACCESS_VALIDATION_PENDING,
    SET_GANTT_PROJECT_LIST,
    SET_KANBAN_LIST_PENDING,
    SET_MILESTONE_PIE_CHART_LIST,
    SET_MILESTONE_PIE_CHART_TASK_PENDING,
    SET_MILESTONE_TASK_DATE, SET_MILESTONE_TASK_DATE_PENDING,
    SET_MILESTONE_TASK_LIST, SET_MILESTONE_TASK_PENDING,
    SET_PROJECT_USER_LIST, SET_TASK_DETAILS,
    SET_TASK_DETAILS_ERROR, SET_TASK_DETAILS_PENDING,
    SET_TASK_EXPORT_LIST,
    SET_TASK_EXPORT_PENDING,
    SET_TASK_LIST, SET_TASK_PENDING,
    TASK_EXPORT_LIST_ERROR,
    TASK_EXPORT_SUCCESS,
    TASK_LIST_ERROR,
    UPDATE_KANBAN_TASK_ERROR,
    UPDATE_RATING_ERROR,
    UPDATE_RATING_ERROR_CLEAR,
    UPDATE_RATING_PENDING,
    UPDATE_RATING_SUCCESS,
    UPDATE_TASK_ERROR, UPDATE_TASK_PENDING,
    UPDATE_TASK_STATUS_ERROR,
    UPDATE_TASK_STATUS_PENDING,
    UPDATE_TASK_STATUS_SUCCESS,
    UPDATE_TASK_SUCCESS
} from "./constants";

const initialState = {
    isPending: false,
    isTaskPending: false,
    errorMessage: null,
    taskList: [],
    KanbanTaskList: [],
    totalRecords: null,
    KanbantotalRecords: null,
    taskListError: false,
    isCreateTaskModalOpen: false,
    projectUserList: null,
    TaskDetails: null,
    taskDetailsError: null,
    isEditTaskModalOpen: false,
    updateTaskErrorMessage: null,
    updateKanbanTaskErrorMessage: null,
    isUpdateTaskPending: false,
    isTaskDetailsPending: false,
    MilestoneTaskList: [],
    MilestonetaskListError: null,
    isMilestoneTaskPending: false,
    MilestoneTasktotalRecords: 0,
    MilestonePieChartListError: null,
    isMilestonePieChartPending: false,
    MilestonePieChartList: null,
    isCreateMilestoneModalOpen: false,
    MilestoneTaskDateError: null,
    isMilestoneTaskDatePending: false,
    MilestoneDate: null,
    taskExportListError: null,
    isTaskExportPending: false,
    taskExportList: [],
    isCloneTaskModalOpen: false,
    GanttProjectsList: [],
    isTaskStatusOpen: false,
    updateTaskStatusErrorMessage: null,
    isUpdateTaskStatusPending: false,
    isKanbanListPending: false,
    isStatusTaskEditID: null,
    isTaskEditStatusLoadingOpen: false,
    projectAccessValidationError: null,
    isGetProjectAccessValidationPending: false,
    //----------------------------------------Rating Update----------------------------------------------------------------------
    isUpdateRatingPending: false,
    updateRatingErrorMessage: null,
}

export default (localState = initialState, action) => {
    switch (action.type) {
        case PROJECT_ACCESS_VALIDATION_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isGetProjectAccessValidationPending: false,
                projectAccessValidationError: errorMessage,
            };
        }
        case CLEAR_PROJECT_VALIDATION_ERROR: {
            return {
                ...localState,
                projectAccessValidationError: null,
            };
        }
        case PROJECT_ACCESS_VALIDATION_PENDING: {
            return {
                ...localState,
                projectAccessValidationError: null,
                isGetProjectAccessValidationPending: true,
            };
        }

        case IS_STATUS_TASK_EDIT_OPEN: {
            const { id } = action.payload;
            return {
                ...localState,
                isStatusTaskEditID: id,
            };

        }
        case IS_TASK_STATUS_LOADING_BOOLEAN_OPEN: {
            return {
                ...localState,
                isTaskEditStatusLoadingOpen: true,
            };
        }
        case IS_TASK_STATUS_LOADING_BOOLEAN_CLOSE: {
            return {
                ...localState,
                isTaskEditStatusLoadingOpen: false,
            };
        }
        case MILESTONE_CREATE_OPEN: {
            return {
                ...localState,
                isCreateMilestoneModalOpen: true,
            };
        }
        case MILESTONE_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateMilestoneModalOpen: false,
            };
        }
        case OPEN_CREATE_TASK_MODAL: {
            return {
                ...localState,
                isCreateTaskModalOpen: true,
            };
        }
        case OPEN_EDIT_TASK_MODAL: {
            return {
                ...localState,
                isEditTaskModalOpen: true,
            };
        }
        case OPEN_CLONE_TASK_MODAL: {
            return {
                ...localState,
                isCloneTaskModalOpen: true,
            };
        }
        case OPEN_EDIT_TASK_STATUS: {
            return {
                ...localState,
                isTaskStatusOpen: true,
            };
        }
        case CLOSE_EDIT_TASK_STATUS: {
            return {
                ...localState,
                isTaskStatusOpen: false,
            };
        }
        case CLOSE_CLONE_TASK_MODAL: {
            return {
                ...localState,
                isCloneTaskModalOpen: false,
            };
        }
        case CLOSE_CREATE_TASK_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateTaskModalOpen: false,
            };
        }
        case CLOSE_EDIT_TASK_MODAL: {
            return {
                ...localState,
                updateTaskErrorMessage: null,
                isEditTaskModalOpen: false,
            };
        }
        case CLOSE_KANBAN_EDIT_TASK_MODAL: {
            return {
                ...localState,
                updateKanbanTaskErrorMessage: null,
                taskListError: null
            };
        }
        case SET_PROJECT_USER_LIST: {
            return {
                ...localState,
                projectUserList: action.payload,
            };
        }
        case CREATE_TASK_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_TASK_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CREATE_TASK_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_TASK_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case SET_TASK_PENDING: {
            return {
                ...localState,
                taskListError: null,
                isTaskPending: true,
                // totalRecords: 0
            }
        }
        case SET_TASK_EXPORT_PENDING: {
            return {
                ...localState,
                taskExportListError: null,
                isTaskExportPending: true
            }
        }
        case SET_MILESTONE_TASK_PENDING: {
            return {
                ...localState,
                MilestonetaskListError: null,
                isMilestoneTaskPending: true
            }
        }
        case SET_MILESTONE_TASK_DATE_PENDING: {
            return {
                ...localState,
                MilestoneTaskDateError: null,
                isMilestoneTaskDatePending: true
            }
        }
        case SET_MILESTONE_TASK_DATE: {
            const { taskDate } = action.payload;
            return {
                ...localState,
                isMilestoneTaskDatePending: false,
                MilestoneDate: taskDate,
            }
        }
        case CLEAR_MILESTONE_DATE: {
            return {
                ...localState,
                MilestoneDate: null,
            };
        }
        case CLEAR_MILESTONE_TASK_DATE_ERROR: {
            return {
                ...localState,
                MilestoneTaskDateError: null,
            };
        }
        case MILESTONE_TASK_DATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isMilestoneTaskDatePending: false,
                MilestoneTaskDateError: errorMessage,
            };
        }
        case SET_MILESTONE_PIE_CHART_TASK_PENDING: {
            return {
                ...localState,
                MilestonePieChartListError: null,
                isMilestonePieChartPending: true
            }
        }
        case SET_TASK_DETAILS_PENDING: {
            return {
                ...localState,
                taskDetailsError: null,
                isTaskDetailsPending: true
            }
        }
        case SET_KANBAN_LIST_PENDING: {
            return {
                ...localState,
                // KanbanListErrorMessage: null,
                isKanbanListPending: true,
                KanbantotalRecords: 0
            }
        }
        case KANBAN_LIST_SUCCESS: {
            return {
                ...localState,
                isKanbanListPending: false,
            }
        }
        case SET_TASK_LIST: {
            const { taskList } = action.payload;
            return {
                ...localState,
                isTaskPending: false,
                taskList: taskList && taskList.value,
                totalRecords: taskList["@odata.count"],
            }
        }
        case CLEAR_TASK_LIST: {
            return {
                ...localState,
                isTaskPending: false,
                taskList: [],
                totalRecords: 0,
            }
        }
        case TASK_EXPORT_SUCCESS: {
            return {
                ...localState,
                isTaskExportPending: false,
            }
        }
        case SET_MILESTONE_TASK_LIST: {
            const { taskList } = action.payload;
            return {
                ...localState,
                isMilestoneTaskPending: false,
                MilestoneTaskList: taskList && taskList.value,
                MilestoneTasktotalRecords: taskList["@odata.count"],
            }
        }
        case SET_MILESTONE_PIE_CHART_LIST: {
            const { taskCountList } = action.payload;
            return {
                ...localState,
                isMilestonePieChartPending: false,
                MilestonePieChartList: taskCountList,
            }
        }
        case TASK_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isTaskPending: false,
                taskListError: errorMessage,
            };
        }
        case TASK_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isTaskExportPending: false,
                taskExportListError: errorMessage,
            };
        }
        case MILESTONE_TASK_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isMilestoneTaskPending: false,
                MilestonetaskListError: errorMessage,
                MilestoneTaskList: [],
                MilestoneTasktotalRecords: 0,
            };
        }

        case MILESTONE_PIE_CHART_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isMilestonePieChartPending: false,
                MilestonePieChartListError: errorMessage,
            };
        }
        case CLEAR_TASK_LIST_ERROR: {
            return {
                ...localState,
                taskListError: null,
            };
        }
        case CLEAR_TASK_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                taskExportListError: null,
            };
        }
        case CLEAR_MILESTONE_TASK_LIST: {
            return {
                ...localState,
                MilestoneTaskList: [],
                MilestoneTasktotalRecords: 0,
            };
        }
        case CLEAR_MILESTONE_TASK_LIST_ERROR: {
            return {
                ...localState,
                MilestonetaskListError: null,
            };
        }

        case CLEAR_MILESTONE_PIE_CHART_LIST_ERROR: {
            return {
                ...localState,
                MilestonePieChartListError: null,
            };
        }
        case SET_TASK_DETAILS: {
            const { taskDetails } = action.payload;
            return {
                ...localState,
                isTaskDetailsPending: false,
                TaskDetails: taskDetails,
            }
        }

        case SET_TASK_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                TaskDetails: {},
                isTaskDetailsPending: false,
                taskDetailsError: errorMessage,
            };
        }
        case CLEAR_TASK_DETAILS_ERROR: {
            return {
                ...localState,
                taskDetailsError: null,
            };
        }
        case CLEAR_UPDATE_TASK_ERROR: {
            return {
                ...localState,
                updateTaskErrorMessage: null,
                isUpdateTaskPending: false,
            };
        }
        case CLEAR_UPDATE_TASK_STATUS_ERROR: {
            return {
                ...localState,
                updateTaskStatusErrorMessage: null,
                isUpdateTaskStatusPending: false,
            };
        }
        case UPDATE_TASK_STATUS_PENDING: {
            return {
                ...localState,
                isUpdateTaskStatusPending: true,
            };
        }
        case UPDATE_TASK_PENDING: {
            return {
                ...localState,
                isUpdateTaskPending: true,
            };
        }
        case UPDATE_TASK_SUCCESS: {
            return {
                ...localState,
                isUpdateTaskPending: false,
            };
        }
        case UPDATE_TASK_STATUS_SUCCESS: {
            return {
                ...localState,
                isUpdateTaskStatusPending: false,
            };
        }
        case UPDATE_TASK_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateTaskErrorMessage: errorMessage,
                isUpdateTaskPending: false,
            };
        }
        case UPDATE_TASK_STATUS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateTaskStatusErrorMessage: errorMessage,
                isUpdateTaskStatusPending: false,
            };
        }
        case UPDATE_KANBAN_TASK_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateKanbanTaskErrorMessage: errorMessage,
            };
        }
        // case KANBAN_LIST_ERROR: {
        //     const { errorMessage } = action.payload;
        //     return {
        //         ...localState,
        //         KanbanListErrorMessage: errorMessage,
        //     };
        // }
        case KANBAN_TASK_LIST: {
            const { KanbantaskList } = action.payload;
            return {
                ...localState,
                isTaskPending: false,
                isKanbanListPending: false,
                KanbanTaskList: KanbantaskList && KanbantaskList.value,
                KanbantotalRecords: KanbantaskList["@odata.count"],
            }
        }
        case SET_GANTT_PROJECT_LIST: {
            const { ganttProjectList } = action.payload;
            console.log("ganttProjectList:", ganttProjectList);

            return {
                ...localState,
                GanttProjectsList: ganttProjectList,
            }
        }
        //----------------------------------------Rating Update----------------------------------------------------------------------
        case UPDATE_RATING_PENDING: {
            return {
                ...localState,
                isUpdateRatingPending: true,
                updateRatingErrorMessage: null,
            }
        }
        case UPDATE_RATING_SUCCESS: {
            return {
                ...localState,
                isUpdateRatingPending: false,
                updateRatingErrorMessage: null,
            }
        }
        case UPDATE_RATING_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isUpdateRatingPending: false,
                updateRatingErrorMessage: errorMessage,
            }
        }
        case UPDATE_RATING_ERROR_CLEAR: {
            return {
                ...localState,
                isUpdateRatingPending: false,
                updateRatingErrorMessage: null,
            }
        }

        default: {
            return localState;
        }
    }
}