import React, { useEffect, useState } from 'react'
import VWButton from '../../../../components/vwButton/VWButton'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { connect } from 'react-redux'
import moment from 'moment-timezone';
import Async from 'react-select/async';
import { createEmployee, createEmployeeSuccess, getEmployeeIDlist, getEmployeeUserList, getManagerEmployeelist, getManagerNameAsyncSelect, getUserNameAsyncSelect } from '../../../../store/Modules/Employees/actions'
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import Select from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';

const TitleOptions = [
  { label: 'Miss', value: 'Miss' },
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Mrs.', value: 'Mrs.' },
]
const GenderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
]
const EmployeeTyeOptions = [
  { label: 'Full Time', value: 'Full Time' },
  { label: 'Part Time', value: 'Part Time' },
  { label: 'Consultant', value: 'Consultant' },
]
const CreateEmployee = (props) => {

  const [formFields, setFormFields] = useState({ IsActive: true, isRehired: false, isPeopleManager: false });
  const [IsActive, setIsActive] = useState(true);
  const [isRehired, setIsRehired] = useState(false)
  const [isPeopleManager, setIsPeopleManager] = useState(false)
  const [errors, setErrors] = useState({});
  const [isMailNotificationEnable, setIsMailNotificationEnable] = useState(true);
  const { filteredData, appId } = props;
  const [autoFocus, setAutoFocus] = useState(true);

  useEffect(() => {
    props.createEmployeeSuccess();
  }, [])

  const handleUserName = (inputValue, callback) => {
    const { getUserNameAsyncSelectAction } = props;
    return getUserNameAsyncSelectAction(appId, inputValue.length > 0 ? inputValue : '', callback);
  };

  const handleManagerName = (inputValue, callback) => {
    const { getManagerNameAsyncSelect } = props;
    return getManagerNameAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
  };


  const handleActiveStatusChange = () => {
    setIsActive(!IsActive);
    setFormFields({ ...formFields, IsActive: !IsActive })
  };

  const handleIsRehiredStatusChange = () => {
    const newIsRehired = !isRehired;
    setIsRehired(newIsRehired);
    setFormFields({ ...formFields, isRehired: newIsRehired });
  };

  const handleIsPeopleManagerStatusChange = () => {
    const newIsPeopleManager = !isPeopleManager;
    setIsPeopleManager(newIsPeopleManager);
    setFormFields({ ...formFields, isPeopleManager: newIsPeopleManager });
  };

  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
    setFormFields({ ...formFields, [input.name]: value });
    if (input.name === 'EmployeeId') {
      setErrors({ ...errors, EmployeeId: '' });
    }
    if (input.name === 'EmployeeFirstName') {
      setErrors({ ...errors, EmployeeFirstName: '' });
    }
    if (input.name === 'EmployeeLastName') {
      setErrors({ ...errors, EmployeeLastName: '' });
    }
  };
  const ValidateEmployeeId = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
    setFormFields({
      ...formFields,
      EmployeeId: value,
    });
    props.getEmployeeIDlist(appId, value)
      .then(() => {
        setErrors({ ...errors, EmployeeId: '' });
      })
      .catch((error) => {
        console.error("Error checking new user:", error);
        setErrors({
          ...errors,
          EmployeeId: error || 'Failed to fetch user data. Please try again.'
        });
      })
  }

  const handleAddUserName = (value) => {
    console.log("value:", value);
    if ((value.__isNew__)) {
      props.getEmployeeUserList(appId, value.value).then(() => {
        setFormFields({
          ...formFields,
          Email: value.value,
          UserId: null,
          EmployeeFirstName: '',
          EmployeeLastName: ''
        });
        setErrors({ ...errors, UserId: '' });
      })
        .catch((error) => {
          console.error("Error checking new user:", error);
          setErrors({
            ...errors,
            UserId: error || 'Failed to fetch user data. Please try again.'
          });
        })
    }
    else {
      setFormFields({
        ...formFields,
        UserId: value.value,
        Email: '',
        EmployeeFirstName: value.FirstName,
        EmployeeLastName: value.LastName,
        EnableMailNotification: value.EnableMailNotification,
      });
      setIsMailNotificationEnable(value.EnableMailNotification);
      setErrors({ ...errors, UserId: '', EmployeeLastName: '', EmployeeFirstName: '' });
    }
  }

  const handleAddManagerName = (value) => {
    if (value === null) {
      setFormFields({ ...formFields, managerUserId: null });
    }
    else {
      // props.getManagerEmployeelist(appId, value.value)
      //   .then(() => {
      setFormFields({
        ...formFields,
        managerUserId: value.value,
      });
      setErrors({ ...errors, managerUserId: '' });
      // .catch((error) => {
      //   console.error("Error checking new user:", error);
      //   setErrors({
      //     ...errors,
      //     managerUserId: error || 'Failed to fetch user data. Please try again.'
      //   });
      // })
    }
  }

  const validateOnChangeDate = (e) => {
    const { name, value } = e.target;
    const formattedDate = value ? moment(value).format() : null;
    setFormFields({ ...formFields, [name]: formattedDate });
    if (name === 'DateOfBirth') {
      setErrors({ ...errors, DateOfBirth: '' });
    }
    if (name === 'DateOfJoin') {
      setErrors({ ...errors, DateOfJoin: '' });
    }
  };

  const handleChangeGenderSelect = (e) => {
    setFormFields({ ...formFields, gender: e.value });
    setErrors({ ...errors, gender: '' });
  }

  const handleChangeTitleSelect = (e) => {
    setFormFields({ ...formFields, EmployeeTitle: e.value });
  }
  const handleChangeEmployeeTypeSelect = (options) => {
    setFormFields({ ...formFields, employeeType: options.value });
  }
  //--------------------------------------------Status ---------------------------------------------------------
  const handleEmailNotificationStatusChange = () => {
    setIsMailNotificationEnable(!isMailNotificationEnable);
    setFormFields({ ...formFields, enableMailNotification: !isMailNotificationEnable })
  };
  //------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log('autoFocus:', autoFocus);
      if (event.key === 'Escape') {
        console.log('escape key');
        setAutoFocus(false);
        const inputs = document.querySelectorAll('input');
        inputs.forEach(input => input.blur());
      }
      else if (event.key === 'Tab') {
        console.log('tab key');
        setAutoFocus(true);
      }
      else if (event.key === 'Enter') {
        const activeElement = document.activeElement;

        if (activeElement.type === 'checkbox') {
          activeElement.click();
        }
        else if (autoFocus === false) {
          console.log('enter button');
          handleEmployeeCreate();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [autoFocus]);

  const handleFocus = (event) => {
    setAutoFocus(true);
  };
  //--------------------------------------------Create ---------------------------------------------------------
  const handleEmployeeCreate = () => {
    const newErrors = {};
    if ((!formFields.UserId || !formFields.UserId.trim()) && !formFields.Email) {
      newErrors.UserId = 'User is required';
    }
    if (errors && errors.UserId) {
      newErrors.UserId = errors && errors.UserId;
    }
    if (errors && errors.EmployeeId) {
      newErrors.EmployeeId = errors && errors.EmployeeId;
    }
    if (errors && errors.managerUserId) {
      newErrors.managerUserId = errors && errors.managerUserId;
    }
    if (errors && errors.EmployeeId) {
      newErrors.EmployeeId = errors && errors.EmployeeId;
    }
    if (!formFields.EmployeeId || !formFields.EmployeeId.trim()) {
      newErrors.EmployeeId = 'Employee ID is required';
    }
    if (!formFields.EmployeeFirstName || !formFields.EmployeeFirstName.trim()) {
      newErrors.EmployeeFirstName = 'Employee first name is required';
    }
    if (!formFields.EmployeeLastName || !formFields.EmployeeLastName.trim()) {
      newErrors.EmployeeLastName = 'Employee last name is required';
    }
    if (!formFields.DateOfBirth || !formFields.DateOfBirth.trim()) {
      newErrors.DateOfBirth = 'Date of birth is required';
    }
    if (!formFields.DateOfJoin || !formFields.DateOfJoin.trim()) {
      newErrors.DateOfJoin = 'Date of join is required';
    }
    if (!formFields.gender || !formFields.gender.trim()) {
      newErrors.gender = 'Gender is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      props.createEmployeeAction(formFields, filteredData);
    }

  };
  console.log("formfields:", formFields);
  console.log("error:", errors);

  return (
    <React.Fragment>
      {props.errorMessage && (
        <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
      )}
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              User (Email ID)<span className="text-danger"> *</span>
            </Label>
            <AsyncCreatableSelect
              openMenuOnFocus={true}
              autoFocus={true}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.UserId) ? '#FF3D60' : "#CED4DA"
                })
              }}
              name="UserId"
              placeholder="Select user"
              isSearchable
              defaultOptions
              loadOptions={handleUserName}
              onChange={handleAddUserName}
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.UserId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Manager Name
            </Label>
            <Async
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.managerUserId) ? '#FF3D60' : "#CED4DA"
                })
              }}
              name="managerUserId"
              placeholder="Select manager name"
              isSearchable
              defaultOptions
              loadOptions={handleManagerName}
              onChange={handleAddManagerName}
              isClearable
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.managerUserId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Employee ID <span className="text-danger"> *</span>
            </Label>
            <Input
              onFocus={handleFocus}
              type="text"
              name="EmployeeId"
              placeholder="Enter employee ID"
              onChange={ValidateEmployeeId}
              value={formFields && formFields.EmployeeId}
              invalid={errors && errors.EmployeeId}
            />
            {<FormFeedback>{errors && errors.EmployeeId}</FormFeedback>}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>
              Employee First Name <span className="text-danger"> *</span>
            </Label>
            <Input
              onFocus={handleFocus}
              type="text"
              name="EmployeeFirstName"
              placeholder="Enter employee first name"
              onChange={validateOnChange}
              value={formFields && formFields.EmployeeFirstName}
              invalid={errors && errors.EmployeeFirstName}
            />
            {<FormFeedback>{errors && errors.EmployeeFirstName}</FormFeedback>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Employee Last Name <span className="text-danger"> *</span>
            </Label>
            <Input
              onFocus={handleFocus}
              type="text"
              name="EmployeeLastName"
              placeholder="Enter employee last name"
              onChange={validateOnChange}
              value={formFields && formFields.EmployeeLastName}
              invalid={errors && errors.EmployeeLastName}
            />
            {<FormFeedback>{errors && errors.EmployeeLastName}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Employee Type
            </Label>
            <Select
              name="employeeType"
              placeholder="Select employee type"
              options={EmployeeTyeOptions}
              onChange={handleChangeEmployeeTypeSelect}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Employee Status <span className="text-danger">*</span></Label>
            <Input
              onFocus={handleFocus}
              type="text"
              name="employeeStatus"
              placeholder="Select employee status"
              onChange={handleActiveStatusChange}
              value={IsActive ? 'Active' : ''}
              disabled
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Employee Designation
            </Label>
            <Input
              onFocus={handleFocus}
              type="text"
              name="EmployeeTitle"
              placeholder="Enter employee designation"
              onChange={validateOnChange}
              value={formFields && formFields.EmployeeTitle}
            />
            {/* <Select
              name="EmployeeTitle"
              placeholder="Select title"
              options={TitleOptions}
              onChange={e => handleChangeTitleSelect(e)}
            /> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Date Of Birth <span className="text-danger">*</span></Label>
            <Input
              onFocus={handleFocus}
              type="date"
              name="DateOfBirth"
              placeholder="Select date of birth"
              onChange={validateOnChangeDate}
              invalid={errors && errors.DateOfBirth}
              max={new Date().toISOString().split('T')[0]}
            />
            {<FormFeedback>{errors && errors.DateOfBirth}</FormFeedback>}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Date Of Join <span className="text-danger">*</span></Label>
            <Input
              onFocus={handleFocus}
              type="date"
              name="DateOfJoin"
              placeholder="Select date of join"
              onChange={validateOnChangeDate}
              invalid={errors && errors.DateOfJoin}
              max={new Date().toISOString().split('T')[0]}
            />
            {<FormFeedback>{errors && errors.DateOfJoin}</FormFeedback>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Gender <span className="text-danger">*</span></Label>
            <Select
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.gender) ? '#FF3D60' : "#CED4DA"
                })
              }}
              name="gender"
              placeholder="Select gender"
              options={GenderOptions}
              onChange={e => handleChangeGenderSelect(e)}
            /> <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.gender}
              </div>
            </FormText>
          </FormGroup>
        </Col>
        <Col md={3} className='mt-4'>
          <FormGroup>
            <div>
              <Label>
                <Input
                  onFocus={handleFocus}
                  type="checkbox"
                  onClick={handleIsRehiredStatusChange}
                />
                &nbsp;Is Rehired
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={3} className='mt-4'>
          <FormGroup>
            <div>
              <Label>
                <Input
                  onFocus={handleFocus}
                  type="checkbox"
                  onClick={handleIsPeopleManagerStatusChange}
                />
                &nbsp;Is People Manager
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <FormGroup>
            <Label>Email Notification Status</Label>
            <ul className="form-unstyled-list form-status-list">
              <li
                onClick={handleEmailNotificationStatusChange}
                className={`form-status-item ${!isMailNotificationEnable ? 'active' : ''}`}
              >
                Disable
              </li>
              <li
                onClick={handleEmailNotificationStatusChange}
                className={`form-status-item ${isMailNotificationEnable ? 'active' : ''}`}
              >
                Enable
              </li>
            </ul>
          </FormGroup>
        </Col>
      </Row>
      <br></br>
      <div className="form-group">
        <FormGroup>
          <Button
            className="me-2"
            color="success"
            onClick={handleEmployeeCreate}
            disabled={props.isPending}
          >
            {props.isPending ? <>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              &nbsp; Submitting...
            </>
              : <>Submit</>}
          </Button>
          <Button
            color="danger"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
        </FormGroup>
      </div>
    </React.Fragment >
  )
}

const mapStateToProps = (state) => ({
  isPending: state.Employee.isPending,
  errorMessage: state.Employee.errorMessage,
  EmployeeList: state.Employee.employeeUserIDList,
});
const mapDispatchToProps = (dispatch) => ({
  getUserNameAsyncSelectAction: (appId, searchValue, callback) =>
    dispatch(getUserNameAsyncSelect(appId, searchValue, callback)),
  getManagerNameAsyncSelect: (searchValue, callback) =>
    dispatch(getManagerNameAsyncSelect(searchValue, callback)),
  createEmployeeAction: (formFields, filteredData) => dispatch(createEmployee(formFields, filteredData)),
  createEmployeeSuccess: () => dispatch(createEmployeeSuccess()),
  getEmployeeUserList: (appId, userId) => dispatch(getEmployeeUserList(appId, userId)),
  getEmployeeIDlist: (appId, empId) => dispatch(getEmployeeIDlist(appId, empId)),
  getManagerEmployeelist: (appId, ManagerId) => dispatch(getManagerEmployeelist(appId, ManagerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmployee)