import { DASHBOARD_PROJECT_CLEAR_ERROR, DASHBOARD_PROJETS_ERROR_MESSAGE, SET_DASHBOARD, SET_PROJECT_DASHBOARD_PENDING, SET_PROJECT_DASHBOARD_SUCCESS } from "./constants";

const initialState = {
    DashboardCounts: {},
    isDashboardProjectsPending: false,
    dashboardProjectsErrorMessage: null,
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD: {
            const { dashboard } = action.payload;
            return {
                ...localState,
                DashboardCounts: dashboard,
                isDashboardProjectsPending: false,
            }
        }
        case DASHBOARD_PROJETS_ERROR_MESSAGE: {
            const { dashboardProjectsErrorMessage } = action.payload;
            return {
                ...localState,
                isDashboardTimesheetsPending: false,
                dashboardProjectsErrorMessage: dashboardProjectsErrorMessage,
            }
        }
        case DASHBOARD_PROJECT_CLEAR_ERROR: {
            return {
                ...localState,
                dashboardProjectsErrorMessage: null,
            }
        }

        case SET_PROJECT_DASHBOARD_PENDING: {
            return {
                ...localState,
                dashboardProjectsErrorMessage: null,
                isDashboardProjectsPending: true
            }
        }
        case SET_PROJECT_DASHBOARD_SUCCESS: {
            return {
                ...localState,
                // dashboardProjectsErrorMessage: null,
                isDashboardProjectsPending: false
            }
        }

        default: {
            return localState;
        }
    }
};