import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Calendar
import calendar from "./calendar/reducer";

// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import account from "./auth/register/reducer";
import leaveTracker from "./Modules/LeaveTracker/LeaveTracking/reducers";
import leaveType from "./Modules/LeaveTracker/LeaveType/reducers";
import leaveMaster from "./Modules/LeaveTracker/LeaveMaster/reducers";
import LeaveTrackerDashboard from './Modules/LeaveTracker/Dashboard/reducers';
import TimeSheets from "./Modules/Timesheet/myTimesheet/reducers";
import MyTeamTimeSheet from "./Modules/Timesheet/myTeamTimesheet/reducers";
import AllTimesheet from "./Modules/Timesheet/allTimeSheet/reducers";
import TimesheetDashboard from "./Modules/Timesheet/Dashboard/reducers";
import users from "./users/reducer";
import userdetails from "../store/userdetails/reducer";
import zones from "../store/zones/reducer";
import organisation from '../store/organisation/reducer';
import device from '../store/device/reducer';
import products from "../store/products/reducer";
import deviceDetails from "../store/deviceDetails/reducer";
import requests from "../store/requests/reducer";
import approvals from "../store/approvals/reducer";
import organisationDetails from "../store/organisationDetails/reducer";
import form from "../store/form/reducer";
import appMaster from "./Modules/Admin/AppMaster/reducers";
import Organization from "./Modules/Admin/organization/reducers";
import AdminUser from "./Modules/Admin/AdminUser/reducers";
import OrgUser from "./Modules/Admin/OrgUser/reducers";
import AdminGroup from "./Modules/Admin/Group/reducers";
import RoleList from "./Modules/Admin/Role/reducers";
import Employee from "./Modules/Employees/reducers";
import snackbar from "./snackbar/reducer";
import ProjectPortal from "./Modules/ProjectPortal/Projects/reducers";
import Milestones from "./Modules/ProjectPortal/Milestones/reducers";
import task from "./Modules/ProjectPortal/Task/reducers";
import visionDocs from "./Modules/VisionDocs/reducers";
import massmailerDashboard from "./Modules/MassMailer/Dashboard/reducers";
import massmailerContactList from "./Modules/MassMailer/ContactList/reducers";
import campaign from './Modules/MassMailer/Campaign/reducers';
import mailSetting from './Modules/MassMailer/MailSettings/reducers';
import unsubscribe from './Modules/MassMailer/Unsubscribe/reducers';
import dbSync from './Modules/DB/reducer';
import myTeamLeaveDetails from './Modules/LeaveTracker/MyTeamLD/reducers'
import holidayMaster from './Modules/LeaveTracker/HolidayMaster/reducers'
import weeklyoffDays from './Modules/LeaveTracker/WeeklyOffDays/reducers';
import ProjectDashboard from './Modules/ProjectPortal/Dashboard/reducers';
import TimesheetApproval from "./Modules/Timesheet/TimesheetApproval/reducers";
import OverAllDocsList from "./Modules/OverAllDocs/reducers";

import crmAccounts from "./Modules/crm/Accounts/reducers";
import crmContacts from "./Modules/crm/Contacts/reducers";
import crmLeads from "./Modules/crm/Leads/reducers";
import crmOpportunity from "./Modules/crm/Opportunity/reducers";
import crmLeadCategory from "./Modules/crm/Settings/LeadCategory/reducers";
import crmLeadSource from "./Modules/crm/Settings/LeadSource/reducers";
import crmFollowUpAction from "./Modules/crm/Settings/FollowUpAction/reducers";
import crmAccountCategory from "./Modules/crm/Settings/AccountCategory/reducers";
import crmRegion from "./Modules/crm/Settings/Region/reducers";
import crmIndustry from "./Modules/crm/Settings/Industry/reducers";
import crmOpportunityCategory from "./Modules/crm/Settings/OpportunityCategory/reducers";
import crmOpportunityType from "./Modules/crm/Settings/OpportunityType/reducers";
import crmOpportunityActionTime from "./Modules/crm/Settings/OpportunityActionTime/reducers";
import crmOpportunityCurrentPhase from "./Modules/crm/Settings/OpportunityCurrentPhase/reducers";
import GanttChartList from "./Modules/ProjectPortal/GanttChart/reducers";
import GeneralTasks from "./Modules/ProjectPortal/GeneralTasks/reduers";
import CrmDashboard from "./Modules/crm/Dashboard/reducers";
import VisitType from "./Modules/crm/Settings/VisitType/reducers";
import CrmVisit from "./Modules/crm/Visit/reducers";
import TaskScope from "./Modules/ProjectPortal/Settings/TaskScope/reducers";
import TaskType from "./Modules/ProjectPortal/Settings/TaskType/reducers";
import projectReport from "./Modules/ProjectPortal/Report/reducers";
import TaskPercentage from "./Modules/ProjectPortal/Settings/TaskPercentageStatus/reducers"
import LeadMandatory from "./Modules/crm/Settings/LeadmandatoryFields/reducer"
import LeaveTrackerRecord from "./Modules/LeaveTracker/LeaveTrackerReport/reducers"
import SettingsInlineEdit from "./Modules/crm/Settings/SettingsInlineUpdate/reducers"
import OrgPortalManagement from "./Modules/Admin/PortalManagement/reducers"
import TimesheetReport from "./Modules/Timesheet/Reports/reducers"

const rootReducer = combineReducers({
  // public
  Layout,
  calendar,
  forgetPassword,
  login,
  profile,
  account,
  users,
  userdetails,
  zones,
  organisation,
  device,
  products,
  deviceDetails,
  requests,
  approvals,
  organisationDetails,
  form,
  TimeSheets,
  MyTeamTimeSheet,
  AllTimesheet,
  TimesheetDashboard,
  leaveTracker,
  leaveType,
  leaveMaster,
  LeaveTrackerDashboard,
  appMaster,
  Organization,
  AdminUser,
  OrgUser,
  AdminGroup,
  RoleList,
  Employee,
  snackbar,
  ProjectPortal,
  Milestones,
  task,
  visionDocs,
  massmailerDashboard,
  massmailerContactList,
  campaign,
  mailSetting,
  unsubscribe,
  dbSync,
  myTeamLeaveDetails,
  holidayMaster,
  weeklyoffDays,
  ProjectDashboard,
  TimesheetApproval,
  OverAllDocsList,
  crmAccounts,
  crmContacts,
  crmLeads,
  crmOpportunity,
  GeneralTasks,
  crmLeadCategory,
  crmFollowUpAction,
  crmLeadSource,
  crmAccountCategory,
  crmRegion,
  crmIndustry,
  crmOpportunityCategory,
  crmOpportunityType,
  crmOpportunityActionTime,
  crmOpportunityCurrentPhase,
  GanttChartList,
  CrmDashboard,
  VisitType,
  CrmVisit,
  TaskScope,
  TaskType,
  projectReport,
  TaskPercentage,
  LeadMandatory,
  LeaveTrackerRecord,
  SettingsInlineEdit,
  OrgPortalManagement,
  TimesheetReport
});

export default rootReducer;
