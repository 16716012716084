import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, Table } from "reactstrap";
import VWModel from "../../../../components/Modal/VWModal";
import { connect } from "react-redux";
import CreateTask from "./CreateTask";
import { getTaskList, CloseCreateTaskModalAction, openCreateTaskModalAction, getTaskListExportAction, getTaskTypeAsyncSelectAction, getTaskScopeAsyncSelectAction, getTaskTypeListAsyncSelectAction, getTaskScopeListAsyncSelectAction, getTaskStatusAsyncSelectAction, isStatusTaskEditOpen, UpdateTaskStatus, getTaskStatusAsyncMilestoneSelectAction, getTaskStatusAsyncUserSelectAction, closeEditTaskStatus, isTaskStatusLoadingOpen, isTaskStatusLoadingClose, clearUpdateTaskStatusError, clearTaskList } from "../../../../store/Modules/ProjectPortal/Task/actions";
import { getPager } from "../../../../helper/common";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import { clearSetPath } from "../../../../store/actions";
import Select from 'react-select';
import Async from 'react-select/async';
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from "../../../../helper/export";
import { TASK_EXPORT_HEADERS } from "../../../../store/Modules/ProjectPortal/Task/constants";
import { ElementToolTip } from "../vwToolTip";
import { getNonUserNameAsyncSelect, getUserNameAsyncSelect } from "../../../../store/Modules/ProjectPortal/Projects/actions";
import { ClearMilestoneName } from "../../../../store/Modules/ProjectPortal/Milestones/actions";

const priorityOption = [
    { label: "Critical", value: "Critical" },
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
    { label: "Lowest", value: "Lowest" },
]
const effortOption = [
    { label: "XS", value: "XS" },
    { label: "S", value: "S" },
    { label: "M", value: "M" },
    { label: "L", value: "L" },
    { label: "XL", value: "XL" },
    { label: "XXL", value: "XXL" },
]

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal',
        minWidth: '250px',
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};
const customStylesDropdown = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal',
        minWidth: '150px',
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const TaskList = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { isTaskExportPending } = props;

    const path = window.location.search;
    const params = new URLSearchParams(path);
    console.log("path:", path);
    console.log("params:", params);

    const id = params.get('filter');
    const username = params.get('Username');
    const userEmail = params.get('UserEmail');
    const loginUserId = params.get('UserId');
    const startDate = params.get('StartDate');
    const endDate = params.get('EndDate');
    const projectName = params.get('ProjectName');
    const pendingTask = params.get('pendingTask');
    console.log("status:", id);
    console.log("username:", username);
    console.log("userEmail:", userEmail);
    console.log("startDate:", startDate);
    console.log("endDate:", endDate);
    console.log("projectName:", projectName);

    const { userAppDetails } = props && props.currentProfile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    const [TaskStatusFilter, setStatusFilter] = useState();
    const [usernameFilter, setUsernameFilter] = useState();

    const buildsearchMilestoneName = () => {
        const { MilestoneName } = filter || {};
        let searchMilestoneName = '';
        if (MilestoneName) {
            searchMilestoneName += `${MilestoneName}`;
        }
        return (searchMilestoneName) || ''
    }
    const buildsearchTaskName = () => {
        const { TaskName } = filter || {};
        let searchTaskName = '';
        if (TaskName) {
            searchTaskName += `${TaskName}`;
        }
        return (searchTaskName) || ''
    }
    const buildsearchTaskStatus = () => {
        const { TaskStatus } = filter || {};
        let searchTaskStatus = '';
        if (TaskStatus) {
            searchTaskStatus += `${TaskStatus}`;
        }
        return (searchTaskStatus) || ''
    }
    const buildsearchTaskType = () => {
        const { TaskTypeName } = filter || {};
        let searchTaskType = '';
        if (TaskTypeName) {
            searchTaskType += `${TaskTypeName}`;
        }
        return (searchTaskType) || ''
    }
    const buildsearchTaskScope = () => {
        const { taskScopeName } = filter || {};
        let searchTaskScope = '';
        if (taskScopeName) {
            searchTaskScope += `${taskScopeName}`;
        }
        return (searchTaskScope) || ''
    }
    const buildsearchAssignedUserName = () => {
        const { AssignedUserName } = filter || {};
        let searchAssignedUserName = '';
        if (AssignedUserName) {
            searchAssignedUserName += `${AssignedUserName}`;
        }
        return (searchAssignedUserName) || ''
    }
    const buildsearchReviewerUserName = () => {
        const { ReviewerUserName } = filter || {};
        let searchReviewerUserName = '';
        if (ReviewerUserName) {
            searchReviewerUserName += `${ReviewerUserName}`;
        }
        return (searchReviewerUserName) || ''
    }
    const buildsearchPriority = () => {
        const { Priority } = filter || {};
        let searchPriority = '';
        if (Priority) {
            searchPriority += `${Priority}`;
        }
        return (searchPriority) || ''
    }
    const buildsearchEffort = () => {
        const { Effort } = filter || {};
        let searchEffort = '';
        if (Effort) {
            searchEffort += `${Effort}`;
        }
        return (searchEffort) || ''
    }
    const buildsearchStartTimeStemp = () => {
        const { StartDate } = filter || {};
        let searchStartTimeStemp = '';
        if (StartDate) {
            searchStartTimeStemp += `${StartDate}`;
        }
        return searchStartTimeStemp || ''
    }
    const buildsearchEndTimeStemp = () => {
        const { EndDate } = filter || {};
        let searchEndTimeStemp = '';
        if (EndDate) {
            searchEndTimeStemp += `${EndDate}`;
        }
        return searchEndTimeStemp || ''
    }
    const buildsearchPlannedHours = () => {
        const { PlannedHours } = filter || {};
        let searchPlannedHours = '';
        if (PlannedHours) {
            searchPlannedHours += `${PlannedHours}`;
        }
        return (searchPlannedHours) || ''
    }
    const buildsearchProjectName = () => {
        const { ProjectName } = filter || {};
        let searchProjectName = '';
        if (ProjectName) {
            searchProjectName += `${ProjectName}`;
        }
        return (searchProjectName) || ''
    }
    const buildsearchUpdatedDate = () => {
        const { UpdatedTimeStamp } = filter || {};
        let searchUpdatedDate = '';
        if (UpdatedTimeStamp) {
            searchUpdatedDate += `${UpdatedTimeStamp}`;
        }
        return searchUpdatedDate || ''
    }
    const buildsearchCreatedDate = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedDate = '';
        if (CreatedTimeStamp) {
            searchCreatedDate += `${CreatedTimeStamp}`;
        }
        return searchCreatedDate || ''
    }
    const buildsearchDescription = () => {
        const { Description } = filter || {};
        let searchDescription = '';
        if (Description) {
            searchDescription += `${Description}`;
        }
        return searchDescription || ''
    }

    const handleTaskScope = (inputValue, callback) => {
        const { getTaskScopeAsyncSelectAction } = props;
        return getTaskScopeAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleSelectTaskType = (inputValue, callback) => {
        const { getTaskTypeAsyncSelectAction } = props;
        return getTaskTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const setPage = (page = 1) => {
        props.getTaskListAction(App[0] && App[0].appId || '', filter, page, PageSize, buildsearchMilestoneName(), buildsearchTaskName(), buildsearchTaskType(), buildsearchAssignedUserName(), buildsearchPriority(), buildsearchEffort(), buildsearchStartTimeStemp(), buildsearchEndTimeStemp(), buildsearchPlannedHours(), buildsearchProjectName(), buildsearchTaskStatus(), buildsearchUpdatedDate(), buildsearchCreatedDate(), buildsearchTaskScope(), buildsearchReviewerUserName(), buildsearchDescription());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        props.ClearMilestoneName(); // to clear milestone name for create task milestone fields
        props.clearUpdateTaskStatusError();
        props.isTaskStatusLoadingClose();
        props.isStatusTaskEditOpen(null);
        props.closeEditTaskStatus();
        props.ClearPath();
        // navigate filter from dashboard
        if (id || username || startDate || endDate || projectName || userEmail || loginUserId || pendingTask) {
            setFilter({
                ...filter,
                TaskStatus: id,
                AssignedUserName: userEmail,
                StartDate: startDate,
                EndDate: endDate,
                ProjectName: projectName,
                PendingTask: pendingTask

            })
            setStatusFilter({ label: id || 'Search', value: '' });
            setUsernameFilter((userEmail && loginUserId) ? { label: `${username} | ${userEmail}`, value: loginUserId } : 'Search');
        }
        else {
            const currentPageNumber = Pager && Pager.currentPage;
            setPage(currentPageNumber);
        }
        return (() => {
            props.clearTaskList();
        })
    }, [])
    //------------------------------------------Filter--------------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])


    const searchStatus = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            TaskStatus: value,
        })
        setStatusFilter({ label: label || 'Search', value: value || '' })
    }

    const searchUsers = (Value) => {
        console.log("Value:", Value);
        const { label, value, assignedUserEmail } = Value || {};
        if (value === "N/A") {
            setFilter({
                ...filter,
                AssignedUserName: 'N/A',
            })
        } else {
            setFilter({
                ...filter,
                AssignedUserName: assignedUserEmail,
            })
        }
        setUsernameFilter({ label: label || 'Search', value: value || '' });
    }
    const searchPriority = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            Priority: value,
        })
    }
    const searchEffort = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            Effort: value,
        })
    }
    const searchTaskType = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            TaskTypeName: label,
        })
    }
    const searchTaskScope = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            taskScopeName: label,
        })
    }
    const handleUser = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        return getUserNameAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    // --------------------------------------------SORTING------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedTaskList = [...props.taskList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'IsProjectActive') {
            return sortDirection === 'asc' ? b.IsProjectActive - a.IsProjectActive : a.IsProjectActive - b.
                IsProjectActive;
        }
        if (sortOrder === 'CurrentStatusPercentage') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //-------------------------------------------Modal Open and Close--------------------------------------------------
    const handleOpenCreateTask = () => {
        props.openCreateTaskModal();
    };

    const handleClose = () => {
        props.closeCreateTaskModal();
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    // const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        console.log("Id:", Id);
        // navigate(`/projectportal/tasks/details/${Id}`);
        window.open(`/projectportal/tasks/details/${Id}`, "_blank", "noopener,noreferrer");
    };
    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getTaskListExportAction(App[0] && App[0].appId || '', buildsearchMilestoneName(), buildsearchTaskName(), buildsearchTaskType(), buildsearchAssignedUserName(), buildsearchPriority(), buildsearchEffort(), buildsearchStartTimeStemp(), buildsearchEndTimeStemp(), buildsearchPlannedHours(), buildsearchProjectName(), buildsearchTaskStatus(), buildsearchUpdatedDate(), buildsearchCreatedDate(), buildsearchTaskScope(), buildsearchReviewerUserName(), buildsearchDescription())
            .then((response) => {
                if (response) {
                    const exportTaskListExportList = [];
                    const headers = TASK_EXPORT_HEADERS;
                    const headerSizes = [30, 50, 30, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
                    const headerExcel = [
                        { headerName: "TASK NAME", key: "taskName", headerWidhth: 30 },
                        { headerName: "PROJECT NAME", key: "projectName", headerWidhth: 50 },
                        { headerName: "MILESTONE NAME", key: "milestoneName", headerWidhth: 50 },
                        // { headerName: "DESCRIPTION", key: "description", headerWidhth: 30 },
                        { headerName: "TYPE", key: "taskType", headerWidhth: 30 },
                        { headerName: "SCOPE", key: "taskScope", headerWidhth: 30 },
                        { headerName: "STATUS", key: "taskStatus", headerWidhth: 30 },
                        { headerName: "ASSIGNED USERNAME", key: "assignedUserName", headerWidhth: 30 },
                        { headerName: "ASSIGNED USER EMAIL", key: "assignedUserEmail", headerWidhth: 50 },
                        { headerName: "REVIEWER", key: "reviewerUserName", headerWidhth: 30 },
                        { headerName: "REVIEWER EMAIL", key: "reviewerUserEmail", headerWidhth: 50 },
                        { headerName: "PRIORITY", key: "priority", headerWidhth: 20 },
                        { headerName: "EFFORTS", key: "effort", headerWidhth: 20 },
                        { headerName: "START DATE", key: "startDate", headerWidhth: 20 },
                        { headerName: "END DATE", key: "endDate", headerWidhth: 20 },
                        { headerName: "PLANNED HOURS", key: "plannedHours", headerWidhth: 20 },
                        { headerName: "CREATED DATE", key: "createdTimeStamp", headerWidhth: 20 },
                        { headerName: "UPDATED DATE", key: "updatedTimeStamp", headerWidhth: 20 },
                    ]
                    response && response.forEach((element) => {
                        exportTaskListExportList.push({
                            taskName: element.taskName || "N/A",
                            projectName: element.projectName || "N/A",
                            milestoneName: element.milestoneName || "N/A",
                            // description: element.description || "N/A",
                            taskType: element.taskTypeName || "N/A",
                            taskScope: element.taskScopeName || "N/A",
                            taskStatus: element.taskStatus || "N/A",
                            assignedUserName: element.assignedUserName || "N/A",
                            assignedUserEmail: element.assignedUserEmail || "N/A",
                            reviewerUserName: element.reviewerUserName || "N/A",
                            reviewerUserEmail: element.reviewerUserEmail || "N/A",
                            priority: element.priority || "N/A",
                            effort: element.effort || "N/A",
                            startDate: element.startDate ? moment(element.startDate).format('DD MMM YYYY') : 'N/A',
                            endDate: element.endDate ? moment(element.endDate).format('DD MMM YYYY') : 'N/A',
                            plannedHours: element.plannedHours || "N/A",
                            createdTimeStamp: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD MMM YYYY') : 'N/A',
                            updatedTimeStamp: element.updatedTimeStamp ? moment(element.updatedTimeStamp).format('DD MMM YYYY') : 'N/A',
                        });
                    });
                    // excelPrint(exportTaskListExportList, "Task-List", headers, headerSizes);
                    excelSheetExport(headerExcel, exportTaskListExportList, "Task-List");
                }
            })
    }

    const handleTaskStatus = (inputValue, callback) => {
        const { getTaskStatusAsyncSelectAction } = props;
        return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };

    const { isGlobalReadOnlyUser } = props.currentProfile || {};

    //-------------------------------------------------Task status Update -------------------------------------------------

    const [taskStatusValues, setTaskStatusValues] = useState({});
    const APIList = 'List';
    const handleTaskSelectChange = (selectedOption, Id, milestoneId) => {
        setTaskStatusValues(selectedOption);
        const updatedStatus = selectedOption ? selectedOption.value : '';
        props.isTaskStatusLoadingOpen();
        props.UpdateTaskAction({ TaskStatus: updatedStatus, taskId: Id, MileStoneId: milestoneId, CurrentStatusPercentage: selectedOption.Percentage }, filter, APIList, App[0] && App[0].appId,);
    };
    const { userId } = props.currentProfile || {};
    console.log("userId:", userId);

    const handleTaskEditClick = (itemId, TaskStatus, CurrentStatusPercentage) => {
        props.isStatusTaskEditOpen(itemId);
        setTaskStatusValues({ label: `${TaskStatus} - ${CurrentStatusPercentage || 0}%`, value: TaskStatus });
    };

    //---------------------------------dropdown close action for outside click------------------------------------------
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                props.isStatusTaskEditOpen(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //----------------------------------------------------------------------------------------------------------------------------
    const handleTaskMilestoneStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncMilestoneSelect } = props;
        return getTaskStatusAsyncMilestoneSelect(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };

    const handleTaskUserStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncUserSelectAction } = props;
        return getTaskStatusAsyncUserSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncSelectAction } = props;
        return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };

    console.log("props.isStatusTaskEditID:", props.isStatusTaskEditID);
    console.log("filter", filter);




    return (
        <React.Fragment>
            <div className="page-content">
                <Card >
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong className='d-flex align-items-center' style={{ cursor: 'pointer' }}>TASKS -&nbsp;<Badge id='tasksCount' color="danger">{props.isTaskPending ? 0 : (props && props.totalCount || 0)}</Badge>
                            <ElementToolTip id={'tasksCount'} name={
                                'Tasks Count'
                            } /></strong>
                        <div className="content-heading">
                            <div className="ml-auto">
                                {(!isGlobalReadOnlyUser) ? (
                                    <Link
                                        type="button"
                                        className="btn btn-info"
                                        onClick={handleOpenCreateTask}
                                    >
                                        CREATE
                                    </Link>) : null}&nbsp;<Button
                                        className="me-4"
                                        color="success"
                                        onClick={handleExports}
                                        disabled={props.taskList && props.taskList.length <= 0 || isTaskExportPending}
                                    >
                                    {isTaskExportPending ? 'EXPORTING...' : 'EXPORT'}
                                </Button>
                            </div>
                        </div>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody className="p-4">
                        {(props.errorMessage || props.updateTaskStatusErrorMessage) ? <div >
                            {(props.errorMessage || props.updateTaskStatusErrorMessage) && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage || props.updateTaskStatusErrorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        <div style={{ height: "60vh", overflowX: "auto" }}>
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                    <tr>
                                        <th style={{ minWidth: '200px' }}
                                            onClick={() => handleSort('TaskName')} className='hand-cursor'
                                        >
                                            Task Name &nbsp;
                                            {sortOrder === 'TaskName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '200px' }}
                                            onClick={() => handleSort('ProjectName')} className='hand-cursor'
                                        >
                                            Project Name &nbsp;
                                            {sortOrder === 'ProjectName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '200px' }}
                                            onClick={() => handleSort('MilestoneName')} className='hand-cursor'
                                        >
                                            Milestone Name &nbsp;
                                            {sortOrder === 'MilestoneName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '200px' }}
                                            onClick={() => handleSort('CurrentStatusPercentage')} className='hand-cursor'
                                        >Status &nbsp;
                                            {sortOrder === 'CurrentStatusPercentage' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                            }
                                        </th>
                                        {/* <th style={{ minWidth: '200px' }}
                                            onClick={() => handleSort('Description')} className='hand-cursor'
                                        >
                                            Description &nbsp;
                                            {sortOrder === 'Description' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th> */}

                                        <th style={{ minWidth: '100px' }}
                                            onClick={() => handleSort('TaskTypeName')} className='hand-cursor'
                                        >Type &nbsp;
                                            {sortOrder === 'TaskTypeName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                            }
                                        </th>
                                        <th style={{ minWidth: '100px' }}
                                            onClick={() => handleSort('TaskScopeName')} className='hand-cursor'
                                        >Scope &nbsp;
                                            {sortOrder === 'TaskScopeName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                            }
                                        </th>

                                        <th style={{ minWidth: '200px' }}
                                            onClick={() => handleSort('AssignedUserName')} className='hand-cursor'
                                        >Assigned Username &nbsp;
                                            {sortOrder === 'AssignedUserName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                            }
                                        </th>
                                        <th style={{ minWidth: '200px' }}
                                            onClick={() => handleSort('ReviewerUserName')} className='hand-cursor'
                                        >Reviewer &nbsp;
                                            {sortOrder === 'ReviewerUserName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                            }
                                        </th>
                                        <th style={{ minWidth: '100px' }}
                                            onClick={() => handleSort('Priority')} className='hand-cursor'
                                        >
                                            Priority &nbsp;
                                            {sortOrder === 'Priority' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '100px' }} className='hand-cursor'
                                            onClick={() => handleSort('Effort')}
                                        >
                                            Efforts &nbsp;
                                            {sortOrder === 'Effort' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '200px' }} className='hand-cursor'
                                            onClick={() => handleSort('StartDate')}
                                        >
                                            Start Date &nbsp;
                                            {sortOrder === 'StartDate' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '200px' }} className='hand-cursor'
                                            onClick={() => handleSort('EndDate')}
                                        >
                                            End Date &nbsp;
                                            {sortOrder === 'EndDate' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '100px' }} className='hand-cursor'
                                            onClick={() => handleSort('PlannedHours')}
                                        >
                                            Planned Hours &nbsp;
                                            {sortOrder === 'PlannedHours' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '200px' }} className='hand-cursor'
                                            onClick={() => handleSort('CreatedTimeStamp')}
                                        >
                                            Created Date &nbsp;
                                            {sortOrder === 'CreatedTimeStamp' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '200px' }} className='hand-cursor'
                                            onClick={() => handleSort('UpdatedTimeStamp')}
                                        >
                                            Updated Date &nbsp;
                                            {sortOrder === 'UpdatedTimeStamp' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                            View
                                        </th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='TaskName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.TaskName}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='ProjectName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.ProjectName}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='MilestoneName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.MilestoneName}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >
                                            <Async
                                                styles={customStyles}
                                                name="TaskStatus"
                                                placeholder="Search"
                                                isSearchable
                                                isClearable
                                                defaultOptions
                                                loadOptions={handleTaskStatus}
                                                onChange={searchStatus}
                                                value={TaskStatusFilter}
                                            />
                                        </th>
                                        {/* <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='Description'
                                                onChange={searchInputValidation}
                                                value={filter && filter.Description}
                                                onKeyPress={handleKeyPress}
                                            /></th> */}

                                        <th >
                                            <Async
                                                styles={customStylesDropdown}
                                                name="TaskTypeName"
                                                isClearable
                                                defaultOptions
                                                placeholder="Search"
                                                loadOptions={handleSelectTaskType}
                                                onChange={searchTaskType}
                                            />
                                        </th>
                                        <th >
                                            <Async
                                                styles={customStylesDropdown}
                                                name="TaskScopeName"
                                                isClearable
                                                defaultOptions
                                                placeholder="Search"
                                                loadOptions={handleTaskScope}
                                                onChange={searchTaskScope}
                                            />
                                        </th>

                                        <th >
                                            <Async
                                                styles={customStyles}
                                                name="AssignedUserName"
                                                placeholder="Search"
                                                isSearchable
                                                isClearable
                                                defaultOptions
                                                loadOptions={handleUser}
                                                onChange={searchUsers}
                                                value={usernameFilter}
                                            />
                                        </th>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='ReviewerUserName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.ReviewerUserName}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >

                                            <Select
                                                styles={customStylesDropdown}
                                                name="Priority"
                                                isClearable
                                                defaultOptions
                                                placeholder="Search"
                                                options={priorityOption}
                                                onChange={searchPriority}
                                            />
                                        </th>
                                        <th >

                                            <Select
                                                styles={customStylesDropdown}
                                                name="Effort"
                                                isClearable
                                                defaultOptions
                                                placeholder="Search"
                                                options={effortOption}
                                                onChange={searchEffort}
                                            />
                                        </th>
                                        <th >
                                            <Input
                                                type='date'
                                                placeholder='Search'
                                                name='StartDate'
                                                onChange={searchInputValidation}
                                                value={filter && filter.StartDate}
                                                onKeyPress={handleKeyPress}
                                                max="9999-12-31"
                                            /></th>
                                        <th >
                                            <Input
                                                type='date'
                                                placeholder='Search'
                                                name='EndDate'
                                                onChange={searchInputValidation}
                                                value={filter && filter.EndDate}
                                                onKeyPress={handleKeyPress}
                                                max="9999-12-31"
                                            /></th>
                                        <th >
                                            <Input
                                                onKeyDown={
                                                    (e) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                type='number'
                                                placeholder='Search'
                                                name='PlannedHours'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PlannedHours}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >
                                            <Input
                                                type='date'
                                                placeholder='Search'
                                                name='CreatedTimeStamp'
                                                onChange={searchInputValidation}
                                                value={filter && filter.CreatedTimeStamp}
                                                onKeyPress={handleKeyPress}
                                                max="9999-12-31"
                                            /></th>
                                        <th >
                                            <Input
                                                type='date'
                                                placeholder='Search'
                                                name='UpdatedTimeStamp'
                                                onChange={searchInputValidation}
                                                value={filter && filter.UpdatedTimeStamp}
                                                onKeyPress={handleKeyPress}
                                                max="9999-12-31"
                                            /></th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!props.isTaskPending && (props.taskList && props.taskList.length > 0) ?
                                        sortedTaskList && sortedTaskList.map((data) => (
                                            <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                <td className='ps-3 text-wrap'>{data.TaskName || "N/A"}</td>
                                                <td className='ps-3 text-wrap' >{data.ProjectName || "N/A"}</td>
                                                <td className='ps-3 text-wrap'>{data.MilestoneName || "N/A"}</td>
                                                <td>
                                                    {data.IsEditable ? (
                                                        <>
                                                            {data.IsMilestoneOwnerUpdateStatus ? (
                                                                props.isStatusTaskEditID === data.Id ? (
                                                                    <div className='d-flex' ref={dropdownRef}>
                                                                        <Async
                                                                            styles={customStyles}
                                                                            name="TaskStatus"
                                                                            placeholder="Select status"
                                                                            isSearchable
                                                                            defaultOptions
                                                                            loadOptions={handleTaskMilestoneStatusPercentage}
                                                                            onChange={(e) => { handleTaskSelectChange(e, data.Id, data.MileStoneId) }}
                                                                            value={taskStatusValues}
                                                                        // isDisabled={props.isTaskEditStatusLoadingOpen}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex justify-content-between">
                                                                        <span>{`${data.TaskStatus} - ${data.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                        <FontAwesomeIcon
                                                                            icon={faEdit}
                                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                            onClick={() => handleTaskEditClick(data.Id, data.TaskStatus, data.CurrentStatusPercentage)}
                                                                        />
                                                                    </div>
                                                                )
                                                            ) : (

                                                                data.IsUserUpdateStatus && (userId === data.AssignedUserId) ? (
                                                                    props.isStatusTaskEditID === data.Id ? (
                                                                        <div className='d-flex' ref={dropdownRef}>
                                                                            <Async
                                                                                styles={customStyles}
                                                                                name="taskStatus"
                                                                                placeholder="Select status"
                                                                                isSearchable
                                                                                defaultOptions
                                                                                loadOptions={handleTaskUserStatusPercentage}
                                                                                onChange={(e) => { handleTaskSelectChange(e, data.Id, data.MileStoneId) }}
                                                                                value={taskStatusValues}
                                                                            // isDisabled={props.isTaskEditStatusLoadingOpen}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="d-flex justify-content-between">
                                                                            <span>{`${data.TaskStatus} - ${data.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                onClick={() => handleTaskEditClick(data.Id, data.TaskStatus, data.CurrentStatusPercentage)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    props.isStatusTaskEditID === data.Id ? (
                                                                        <div className='d-flex' ref={dropdownRef}>
                                                                            <Async
                                                                                styles={customStyles}
                                                                                name="taskStatus"
                                                                                placeholder="Select status"
                                                                                isSearchable
                                                                                defaultOptions
                                                                                loadOptions={handleTaskStatusPercentage}
                                                                                onChange={(e) => { handleTaskSelectChange(e, data.Id, data.MileStoneId) }}
                                                                                value={taskStatusValues}
                                                                            // isDisabled={props.isTaskEditStatusLoadingOpen}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="d-flex justify-content-between">
                                                                            <span>{`${data.TaskStatus} - ${data.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                onClick={() => handleTaskEditClick(data.Id, data.TaskStatus, data.CurrentStatusPercentage)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                )
                                                            )}
                                                        </>
                                                    ) : data.IsUserEdit ? (

                                                        props.isStatusTaskEditID === data.Id ? (
                                                            <div className='d-flex' ref={dropdownRef}>
                                                                <Async
                                                                    styles={customStyles}
                                                                    name="taskStatus"
                                                                    placeholder="Select status"
                                                                    isSearchable
                                                                    defaultOptions
                                                                    loadOptions={handleTaskUserStatusPercentage}
                                                                    onChange={(e) => { handleTaskSelectChange(e, data.Id, data.MileStoneId) }}
                                                                    value={taskStatusValues}
                                                                // isDisabled={props.isTaskEditStatusLoadingOpen}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex justify-content-between">
                                                                <span>{`${data.TaskStatus} - ${data.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                    onClick={() => handleTaskEditClick(data.Id, data.TaskStatus, data.CurrentStatusPercentage)}
                                                                />
                                                            </div>
                                                        )
                                                    ) : (
                                                        <span>{`${data.TaskStatus} - ${data.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                    )}
                                                </td>
                                                {/* <td className='ps-3'>{data.Description ? (data.Description && data.Description.length > 20) ? data.Description && data.Description.substring(0, 20) + '...' : data.Description : "N/A"}</td> */}
                                                {/* <td className='ps-3'>{data.Description ? (data.Description && data.Description.length > 20) ? <><span id={`description-${data.Id}`}>{data.Description && data.Description.substring(0, 20) + '...'}</span>
                                                    <ElementToolTip
                                                        id={`description-${data.Id}`}
                                                        name={data.Description && data.Description.length > 20 ? data.Description : ''}
                                                    >
                                                        {data.Description && data.Description.length > 20
                                                            ? data.Description && data.Description.substring(0, 20) + '...'
                                                            : data.Description}
                                                    </ElementToolTip>
                                                </>
                                                    : data.Description : "N/A"}
                                                </td> */}
                                                <td className='ps-3'>{data.TaskTypeName || "N/A"}</td>
                                                <td className='ps-3'>{data.TaskScopeName || "N/A"}</td>

                                                <td className='ps-3'>{data.AssignedUserName || "N/A"}
                                                    <br />
                                                    <small>{data.AssignedUserEmail || "N/A"}</small>
                                                </td>
                                                <td className='ps-3'>{data.ReviewerUserName || "N/A"}
                                                    <br />
                                                    <small>{data.ReviewerUserEmail || "N/A"}</small>
                                                </td>
                                                <td className='ps-3'>{data.Priority || 'N/A'}</td>
                                                <td className='ps-3'>{data.Effort || 'N/A'}</td>
                                                <td className='ps-3'>{data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'}</td>
                                                <td className='ps-3'>{data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}</td>
                                                <td className='ps-3'>{data.PlannedHours || 'N/A'}</td>
                                                <td className='ps-3'>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                                                <td className='ps-3'>{data.UpdatedTimeStamp ? moment(data.UpdatedTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                                                <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                                                    type="button"
                                                    className="btn btn-sm  btn-light"
                                                    to={`/projectportal/tasks/details/${data.Id}`}
                                                    target="_blank"
                                                >
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                            </tr>
                                        ))
                                        : props.isTaskPending ?
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <VWSpinner />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <NoRecord />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.taskList && props.taskList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="CREATE TASK"
                    isOpen={props.isCreateTaskModalOpen}
                    handleClose={handleClose}
                    modalSize="large"
                >
                    <CreateTask
                        handleClose={handleClose}
                        filteredData={filter}
                    />
                </VWModel>
            </div >
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    isCreateTaskModalOpen: state.task.isCreateTaskModalOpen,
    taskList: state.task.taskList,
    isTaskPending: state.task.isTaskPending,
    totalCount: state.task.totalRecords,
    errorMessage: state.task.taskListError,
    updateTaskStatusErrorMessage: state.task.updateTaskStatusErrorMessage,
    isTaskExportPending: state.task.isTaskExportPending,
    currentProfile: state.login.currentProfile,
    isStatusTaskEditID: state.task.isStatusTaskEditID,
    isUpdateTaskStatusPending: state.task.isUpdateTaskStatusPending,
    isTaskEditStatusLoadingOpen: state.task.isTaskEditStatusLoadingOpen,
});
const mapDispatchToProps = (dispatch) => ({
    ClearMilestoneName: () => dispatch(ClearMilestoneName()),
    getTaskStatusAsyncUserSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncUserSelectAction(appId, searchValue, callback)),
    getTaskStatusAsyncMilestoneSelect: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncMilestoneSelectAction(appId, searchValue, callback)),
    UpdateTaskAction: (formFields, filter, APIList, AppId) => dispatch(UpdateTaskStatus(formFields, filter, APIList, AppId)),
    isStatusTaskEditOpen: (id) => dispatch(isStatusTaskEditOpen(id)),
    getTaskTypeAsyncSelectAction: (searchValue, callback) => dispatch(getTaskTypeListAsyncSelectAction(searchValue, callback)),
    getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeListAsyncSelectAction(appId, searchValue, callback)),
    openCreateTaskModal: () => dispatch(openCreateTaskModalAction()),
    closeCreateTaskModal: () => dispatch(CloseCreateTaskModalAction()),
    isTaskStatusLoadingOpen: () => dispatch(isTaskStatusLoadingOpen()),
    isTaskStatusLoadingClose: () => dispatch(isTaskStatusLoadingClose()),
    getTaskListAction: (appId, filter, page, PageSize, searchMilestoneName, searchTaskName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription) => dispatch(getTaskList(appId, filter, page, PageSize, searchMilestoneName, searchTaskName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription)),
    getTaskListExportAction: (appId, searchMilestoneName, searchTaskName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription) => dispatch(getTaskListExportAction(appId, searchMilestoneName, searchTaskName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription)),
    ClearPath: () => dispatch(clearSetPath()),
    closeEditTaskStatus: () => dispatch(closeEditTaskStatus()),
    clearUpdateTaskStatusError: () => dispatch(clearUpdateTaskStatusError()),
    clearTaskList: () => dispatch(clearTaskList()),
    getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),
    getUserNameAsyncSelectAction: (appId, searchValue, callback) => dispatch(getNonUserNameAsyncSelect(appId, searchValue, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);