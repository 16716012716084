import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import VWMessage from '../../../../../components/vwMessage/vwMessage';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { EditOrgUser, getOrgNameAsyncSelect, updateOrgUserSuccess } from '../../../../../store/Modules/Admin/OrgUser/actions';
import FormValidator from '../../../../../helper/formValidator';

const OrgUserEdit = (props) => {
    const [formFields, setFormFields] = useState({});
    const [validation, setValidation] = useState({ emailValid: true });
    const [prevOrgName, setprevOrgName] = useState([{ label: '', value: '' }]);
    const [errors, setErrors] = useState({});
    const [IsActive, setIsActive] = useState(true);
    const [isPortalEnabled, setIsPortalEnabled] = useState(true);
    const [isMailNotificationEnable, setIsMailNotificationEnable] = useState();
    const [isGlobalReadOnlyUser, setIsGlobalReadOnlyUser] = useState(false);
    const [autoFocus, setAutoFocus] = useState(true);

    useEffect(() => {
        props.updateOrgUserSuccess();
        if (props.orgUserDetails) {
            setIsGlobalReadOnlyUser(props.orgUserDetails && props.orgUserDetails.isGlobalReadOnlyUser);
            setFormFields(props.orgUserDetails);
            setIsActive(props.orgUserDetails && props.orgUserDetails.isActive);
            setIsPortalEnabled(props.orgUserDetails && props.orgUserDetails.isPortalEnable);
            setIsMailNotificationEnable(props.orgUserDetails && props.orgUserDetails.enableMailNotification);
            setprevOrgName({ label: props.orgUserDetails && props.orgUserDetails.orgName, value: props.orgUserDetails && props.orgUserDetails.orgId })
        }
    }, [])

    //-------------------------------- Default Option for Org name---------------------------------------------
    const handleOrgName = (inputValue, callback) => {
        const { getOrgNameAsyncSelectAction } = props;
        return getOrgNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //-----------------------------------------OnChange---------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;

        if (input.name === 'firstName') {
            setErrors({ ...errors, firstName: null });
        }
        if (input.name === 'lastName') {
            setErrors({ ...errors, lastName: null });
        }
        let result = FormValidator.validate(input);
        if (input.name === 'userEmail') {
            let emailValid = true;
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;
            if (!emailRegex.test(value)) {
                result = 'Invalid email';
                emailValid = false;
            }
            else if (prohibitedChars.test(value)) {
                result = 'Invalid email';
                emailValid = false;
            }
            else {
                emailValid = true
            }
            setErrors({
                ...errors,
                userEmail: emailValid ? null : result,
            });
        }
        setFormFields({
            ...formFields, [input.name]: value, errors: {
                ...(formFields && formFields.errors),
                [input.name]: result,
            },
        });
    };


    //------------------------------------- Change Org name -----------------------------------------------------

    const handleAddOrgName = (value) => {
        setprevOrgName(value);
        setFormFields({ ...formFields, orgId: value.value, orgName: value.label });
    }

    //---------------------------- Status Update -----------------------------------------------------------------
    const handleActiveStatusChange = () => {
        setIsActive(!IsActive);
        setFormFields({ ...formFields, isActive: !IsActive })
    };
    const handleAccountStatusChange = () => {
        setIsPortalEnabled(!isPortalEnabled);
        setFormFields({ ...formFields, isPortalEnable: !isPortalEnabled })
    };
    const handleEmailNotificationStatusChange = () => {
        setIsMailNotificationEnable(!isMailNotificationEnable);
        setFormFields({ ...formFields, enableMailNotification: !isMailNotificationEnable })
    };
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleUpdateOrganizationUser();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    //--------------------------------------------Update Form ------------------------------------------------------
    const handleUpdateOrganizationUser = () => {
        // props.EditOrgUserAction(formFields);
        const newErrors = {};
        if (!formFields.firstName || !formFields.firstName.trim()) {
            newErrors.firstName = 'First name is required';
        }
        if (!formFields.lastName || !formFields.lastName.trim()) {
            newErrors.lastName = 'Last name is required';
        }
        if (!formFields.userEmail || !formFields.userEmail.trim()) {
            newErrors.userEmail = 'Email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(formFields.userEmail)) {
                newErrors.userEmail = 'Invalid email';
            } else if (prohibitedChars.test(formFields.userEmail)) {
                newErrors.userEmail = 'Invalid email';
            }
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0 && validation.emailValid === true) {
            props.EditOrgUserAction(formFields);
        }
    };

    const handleIsGlobalReadOnlyUserStatusChange = () => {
        const newIsGlobalReadOnlyUser = !isGlobalReadOnlyUser;
        setIsGlobalReadOnlyUser(newIsGlobalReadOnlyUser);
        setFormFields({ ...formFields, isGlobalReadOnlyUser: newIsGlobalReadOnlyUser });
    };
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <FormGroup>
                <Label>
                    Email <span className="text-danger"> *</span>
                </Label>
                <Input
                    onFocus={handleFocus}
                    autoFocus={true}
                    type="text"
                    name="userEmail"
                    placeholder="Enter email"
                    invalid={errors && errors.userEmail}
                    onChange={validateOnChange}
                    value={formFields && formFields.userEmail}
                />
                <FormFeedback>{errors && errors.userEmail}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label>
                    First Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    onFocus={handleFocus}
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    onChange={validateOnChange}
                    value={formFields && formFields.firstName}
                    invalid={errors && errors.firstName}
                />
                {<FormFeedback>{errors && errors.firstName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    Last Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    onFocus={handleFocus}
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    onChange={validateOnChange}
                    value={formFields && formFields.lastName}
                    invalid={errors && errors.lastName}
                />
                {<FormFeedback>{errors && errors.lastName}</FormFeedback>}
            </FormGroup>
            <FormGroup className="mt-2">
                <Label>
                    <Input
                        onFocus={handleFocus}
                        type="checkbox"
                        onClick={handleIsGlobalReadOnlyUserStatusChange}
                        checked={isGlobalReadOnlyUser}
                    />
                    &nbsp;Is Global Read Only User ?
                </Label>
            </FormGroup>
            <Row>
                <Col lg="6" md="6" sm="12">
                    <FormGroup>
                        <Label>User Status</Label>
                        <ul className="form-unstyled-list form-status-list">
                            <li
                                onClick={handleActiveStatusChange}
                                className={`form-status-item ${!IsActive ? 'active' : ''}`}
                            >
                                Inactive
                            </li>
                            <li
                                onClick={handleActiveStatusChange}
                                className={`form-status-item ${IsActive ? 'active' : ''}`}
                            >
                                Active
                            </li>
                        </ul>
                    </FormGroup>
                </Col>
                <Col lg="6" md="6" sm="12">
                    <FormGroup>
                        <Label>Account Status</Label>
                        <ul className="form-unstyled-list form-status-list">
                            <li
                                onClick={handleAccountStatusChange}
                                className={`form-status-item ${!isPortalEnabled ? 'active' : ''}`}
                            >
                                Disable
                            </li>
                            <li
                                onClick={handleAccountStatusChange}
                                className={`form-status-item ${isPortalEnabled ? 'active' : ''}`}
                            >
                                Enable
                            </li>
                        </ul>
                    </FormGroup>
                </Col>
                <Col lg="6" md="6" sm="12">
                    <FormGroup>
                        <Label>Email Notification Status</Label>
                        <ul className="form-unstyled-list form-status-list">
                            <li
                                onClick={handleEmailNotificationStatusChange}
                                className={`form-status-item ${!isMailNotificationEnable ? 'active' : ''}`}
                            >
                                Disable
                            </li>
                            <li
                                onClick={handleEmailNotificationStatusChange}
                                className={`form-status-item ${isMailNotificationEnable ? 'active' : ''}`}
                            >
                                Enable
                            </li>
                        </ul>
                    </FormGroup>
                </Col>
            </Row>
            <br></br>
            <FormGroup>
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleUpdateOrganizationUser}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </FormGroup>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    orgUserDetails: state.OrgUser.OrgUserDetails,
    isPending: state.OrgUser.isUpdateOrgUserPending,
    errorMessage: state.OrgUser.updateOrgUserErrorMessage,
});
const mapDispatchToProps = (dispatch) => ({
    getOrgNameAsyncSelectAction: (searchValue, callback) => dispatch(getOrgNameAsyncSelect(searchValue, callback)),
    EditOrgUserAction: (formFields) => dispatch(EditOrgUser(formFields)),
    updateOrgUserSuccess: () => dispatch(updateOrgUserSuccess()),
});


export default connect(mapStateToProps, mapDispatchToProps)(OrgUserEdit)