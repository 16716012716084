import { getToken, modifyEmployeeList, modifyEmployeeManagerList, modifyUserList } from "../../../helper/common";
import trimmedValues from "../../../helper/trim";
import { logoutUser } from "../../actions";
import { clearForm } from "../../form/actions";
import agent from "../../services/agent";
import { showSnackbarStatus } from "../../snackbar/actions";
import { CLEAR_EMPLOYEE_DETAILS_ERROR, CLEAR_EMPLOYEE_ERROR, CLEAR_EMPLOYEE_EXPORT_LIST_ERROR, CLEAR_EMPLOYEE_LIST_ERROR, CLEAR_EMPLOYEE_USER_ID_LIST_ERROR, CLEAR_UPDATE_EMPLOYEE_ERROR, CLOSE_CREATE_EMPLOYEE_MODAL, CLOSE_EDIT_EMPLOYEE_MODAL, CREATE_EMPLOYEE_ERROR, CREATE_EMPLOYEE_PENDING, CREATE_EMPLOYEE_SUCCESS, EMPLOYEE_IMPORT_PENDING, EMPLOYEE_IMPORT_SUCCESS, OPEN_CREATE_EMPLOYEE_MODAL, OPEN_EDIT_EMPLOYEE_MODAL, SET_EMPLOYEE_DETAILS, SET_EMPLOYEE_DETAILS_PENDING, SET_EMPLOYEE_EXPORT_LIST, SET_EMPLOYEE_EXPORT_PENDING, SET_EMPLOYEE_LIST, SET_EMPLOYEE_PENDING, SET_EMPLOYEE_USERID_LIST, SET_EMPLOYEE_USERID_PENDING, SET_ORG_EMPLOYEE_DETAILS_ERROR, SET_ORG_EMPLOYEE_EXPORT_LIST_ERROR, SET_ORG_EMPLOYEE_LIST_ERROR, SET_ORG_EMPLOYEE_USER_ID_LIST_ERROR, UPDATE_EMPLOYEE_ERROR, UPDATE_EMPLOYEE_PENDING, UPDATE_EMPLOYEE_SUCCESS } from "./constants";

export const setEmployeesList = (employeeList) => ({
    type: SET_EMPLOYEE_LIST,
    payload: { employeeList },
})
export const setEmployeesUserIdList = (employeeUserIDList) => ({
    type: SET_EMPLOYEE_USERID_LIST,
    payload: { employeeUserIDList },
})
export const setEmployeesExportList = (employeeList) => ({
    type: SET_EMPLOYEE_EXPORT_LIST,
    payload: { employeeList },
})
export const clearEmployeesListError = () => ({
    type: CLEAR_EMPLOYEE_LIST_ERROR,
})
export const clearEmployeesUserIdListError = () => ({
    type: CLEAR_EMPLOYEE_USER_ID_LIST_ERROR,
})
export const clearEmployeesExportListError = () => ({
    type: CLEAR_EMPLOYEE_EXPORT_LIST_ERROR,
})
export const orgEmployeesListError = (errorMessage) => ({
    type: SET_ORG_EMPLOYEE_LIST_ERROR,
    payload: { errorMessage },
})
export const orgEmployeesUserIdListError = (errorMessage) => ({
    type: SET_ORG_EMPLOYEE_USER_ID_LIST_ERROR,
    payload: { errorMessage },
})
export const orgEmployeesExportListError = (errorMessage) => ({
    type: SET_ORG_EMPLOYEE_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const openCreateEmployeeModalAction = () => ({
    type: OPEN_CREATE_EMPLOYEE_MODAL,
});
export const openEditEmployeeModal = () => ({
    type: OPEN_EDIT_EMPLOYEE_MODAL,
});
export const closeCreateEmployeeModalAction = () => ({
    type: CLOSE_CREATE_EMPLOYEE_MODAL,
});
export const closeEditEmployeeModal = () => ({
    type: CLOSE_EDIT_EMPLOYEE_MODAL,
});

export const setEmployeeDetails = (EmployeeDetails) => ({
    type: SET_EMPLOYEE_DETAILS,
    payload: { EmployeeDetails },
})
export const clearEmployeesDetailsError = () => ({
    type: CLEAR_EMPLOYEE_DETAILS_ERROR,
})
export const orgEmployeesDetailsError = (errorMessage) => ({
    type: SET_ORG_EMPLOYEE_DETAILS_ERROR,
    payload: { errorMessage },
})
export const createEmployeeError = (errorMessage) => ({
    type: CREATE_EMPLOYEE_ERROR,
    payload: { errorMessage },
});

export const createEmployeePending = () => ({
    type: CREATE_EMPLOYEE_PENDING,
});
export const clearCreateEmployeeError = () => ({
    type: CLEAR_EMPLOYEE_ERROR,
});
export const createEmployeeSuccess = () => ({
    type: CREATE_EMPLOYEE_SUCCESS,
});

export const clearUpdateEmployeeError = () => ({
    type: CLEAR_UPDATE_EMPLOYEE_ERROR,
});
export const updateEmployeePending = () => ({
    type: UPDATE_EMPLOYEE_PENDING,
});
export const updateEmployeeSuccess = () => ({
    type: UPDATE_EMPLOYEE_SUCCESS,
});
export const updateEmployeeError = (errorMessage) => ({
    type: UPDATE_EMPLOYEE_ERROR,
    payload: { errorMessage },
});
export const setEmployeePending = () => ({
    type: SET_EMPLOYEE_PENDING,
});
export const setEmployeeUserIdPending = () => ({
    type: SET_EMPLOYEE_USERID_PENDING,
});
export const setEmployeeExportPending = () => ({
    type: SET_EMPLOYEE_EXPORT_PENDING,
});
export const setEmployeeDetailsPending = () => ({
    type: SET_EMPLOYEE_DETAILS_PENDING,
});
export const isImportEmployeeSuccess = () => ({
    type: EMPLOYEE_IMPORT_SUCCESS
})
export const isImportEmployeesPending = () => ({
    type: EMPLOYEE_IMPORT_PENDING
})
//--------------------EMPLOYEE-IMPORT-----------------------------------
export const importEmployee = (excelSheet,filter) => (dispatch) => {
    dispatch(isImportEmployeesPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Employee.importEmployee(accessToken, excelSheet)
            .then((result) => {
                dispatch(isImportEmployeeSuccess());
                dispatch(getEmployeeListAction(filter));
                dispatch(showSnackbarStatus('Employees imported successfully!'));
                return Promise.resolve(result)
            })
            .catch((err) => {
                dispatch(isImportEmployeeSuccess());
                let message;
                if (err && err.response) {
                    message = err.response && err.response.text;
                    dispatch(showSnackbarStatus(message));
                }
                else {
                    message = 'Network Error'
                    dispatch(showSnackbarStatus(message));
                }
                return Promise.reject(message)
            })
    })
}
//-----------------------------------------------------------------------------------------------------------------------
export const getEmployeeExportList = (searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchEnableMailNotification, searchIsUserActive) => (dispatch) => {
    dispatch(setEmployeeExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Employee.getEmployeeExportlist(accessToken, searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchEnableMailNotification, searchIsUserActive)
            .then((employeeList) => {
                // dispatch(setEmployeesExportList(employeeList));
                dispatch(clearEmployeesExportListError());
                return Promise.resolve(employeeList);
            })
            .catch((err) => {
                console.log('getEmployeeExportList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgEmployeesExportListError(error))
            });
    });
};
export const getEmployeeListAction = (filteredData, page, PageSize, searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchIsUserActive) => (dispatch) => {
    dispatch(setEmployeePending());
    getToken(dispatch).then((accessToken) => {
        agent.Employee.getEmployeelist(accessToken, filteredData, page, PageSize, searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchIsUserActive)
            .then((employeeList) => {
                dispatch(setEmployeesList(employeeList));
                dispatch(clearEmployeesListError());
            })
            .catch((err) => {
                console.log("err:", err.status);
                console.log('getEmployeeListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgEmployeesListError(error))
                // if ((err && err.status === 401)){
                //         dispatch && dispatch(logoutUser());
                // }
            });
    });
};
export const getEmployeeList = () => (dispatch) => {
    dispatch(setEmployeePending());
    getToken(dispatch).then((accessToken) => {
        agent.Employee.getUserEmployeelist(accessToken)
            .then((employeeList) => {
                dispatch(setEmployeesList(employeeList));
                dispatch(clearEmployeesListError());
            })
            .catch((err) => {
                console.log('getEmployeeListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgEmployeesListError(error))
            });
    });
};
export const getEmployeeUserList = (appId, User) => (dispatch) => {
    dispatch(setEmployeeUserIdPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Employee.getUserIdEmployeelist(accessToken, appId, User)
            .then((employeeList) => {
                dispatch(clearEmployeesUserIdListError());
                return Promise.resolve(employeeList && employeeList.value);
            })
            .catch((err) => {
                console.log('getEmployeeListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response.text;
                } else {
                    error = "Network Error";
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log("error:", error);

                dispatch(orgEmployeesUserIdListError(error));
                return Promise.reject(error);
            });
    });
};
export const getEmployeeIDlist = (appId, EmpId, ID) => (dispatch) => {
    dispatch(setEmployeeUserIdPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Employee.getEmployeeIDlist(accessToken, appId, EmpId, ID)
            .then((employeeList) => {
                dispatch(clearEmployeesUserIdListError());
                return Promise.resolve(employeeList && employeeList.value);
            })
            .catch((err) => {
                console.log('getEmployeeListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgEmployeesUserIdListError(error))
                return Promise.reject(error);
            });
    });
};
export const getManagerEmployeelist = (appId, ManagerId) => (dispatch) => {
    dispatch(setEmployeeUserIdPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Employee.getIsManagerEmployeelist(accessToken, appId, ManagerId)
            .then((employeeList) => {
                dispatch(clearEmployeesUserIdListError());
                return Promise.resolve(employeeList && employeeList.value);
            })
            .catch((err) => {
                console.log('getEmployeeListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgEmployeesUserIdListError(error))
                return Promise.reject(error);
            });
    });
};

export const getEmployeeDetailsAction = (id) => (dispatch) => {
    dispatch(setEmployeeDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.Employee.getGroupDetails(accessToken, id)
            .then(
                (EmployeeDetails) => {
                    dispatch(setEmployeeDetails(EmployeeDetails));
                    dispatch(clearEmployeesDetailsError());
                }
            )
            .catch((err) => {
                console.log('getEmployeeDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgEmployeesDetailsError(error))
            });
    });
}


export const getUserNameAsyncSelect =
    (appId, searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.Employee.getUserNameList(accessToken, appId, searchValue)
                    .then((UserLists) => {
                        const { value } = UserLists;
                        const updatedUserList = modifyUserList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedUserList);
                    })
                    .catch((err) => {
                        console.log('getUserNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };

export const getManagerNameAsyncSelect =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.Employee.getManagerNameList(accessToken, searchValue)
                    .then((EmployeeLists) => {
                        const { value } = EmployeeLists;
                        const updatedEmployeeList = modifyEmployeeList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedEmployeeList);
                    })
                    .catch((err) => {
                        console.log('getEmployeeAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
export const getManagerNameListAsyncSelect =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.Employee.getManagerNameList(accessToken, searchValue)
                    .then((EmployeeLists) => {
                        const { value } = EmployeeLists;
                        const updatedEmployeeList = modifyEmployeeManagerList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedEmployeeList);
                    })
                    .catch((err) => {
                        console.log('getEmployeeAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };

export const createEmployee = (formFields, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createEmployeeError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createEmployeePending());
    dispatch(clearCreateEmployeeError());
    getToken(dispatch).then((accessToken) => {
        agent.Employee.createEmployee(accessToken, dataToSend)
            .then(() => {
                dispatch(createEmployeeSuccess());
                dispatch(clearForm());
                dispatch(closeCreateEmployeeModalAction());
                dispatch(getEmployeeListAction(filteredData));
                // dispatch(getEmployeeExportList());
                dispatch(showSnackbarStatus('Employee created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createEmployeeError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createEmployeeError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create Leave server error', err);
                dispatch(clearForm());
                dispatch(closeCreateEmployeeModalAction());
            });
    });
};

export const EditEmployeeMaster = (formFields) => (dispatch) => {
    const dataToSend = trimmedValues({
        ...formFields,
        Id: formFields.id,
        employeeTitle: (formFields.employeeTitle && formFields.employeeTitle.length) > 0 ? formFields.employeeTitle : null
    })
    console.log((formFields.employeeTitle && formFields.employeeTitle.length) > 0)
    dispatch(clearUpdateEmployeeError());
    dispatch(updateEmployeePending());
    getToken(dispatch).then((accessToken) => {
        agent.Employee.editEmployee(accessToken, dataToSend)
            .then(() => {
                dispatch(updateEmployeeSuccess());
                dispatch(closeEditEmployeeModal());
                dispatch(getEmployeeDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('Employee details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateEmployeeError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateEmployeeError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit EmployeeMaster server error', err);
                dispatch(updateEmployeeError(null));
                dispatch(closeEditEmployeeModal());
            });
    });
};