import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import Async from "react-select/async";
import Select from "react-select";
import { getAccountsAsyncSelect } from "../../../../store/Modules/crm/Contacts/actions";
import {
    clearUpdateLeadsError,
    getCrmUserAsyncSelect,
    getFollowActionAsyncSelect,
    getLeadCategoryAsyncSelect,
    getLeadSourceAsyncSelect,
    updateLeads,
    updateLeadsSuccess
} from "../../../../store/Modules/crm/Leads/actions";
import {
    QUALIFICATIONLEVELOPTIONS
} from "../../../../store/Modules/crm/Leads/constants";
import FormValidator from "../../../../helper/formValidator";
import { getLeadMandatoryFieldsList } from "../../../../store/Modules/crm/Settings/LeadmandatoryFields/actions";

const UpdateLeads = (props) => {
    const { errorMessage, isLeadsUpdatePending, leadsDetails, leadMandatoryList } = props;
    const [formFields, setFormFields] = useState();
    const [errors, setErrors] = useState();
    const [isActive, setIsActive] = useState();
    const [leadOwner, setLeadOwner] = useState();
    const [qualification, setQualification] = useState();
    const [leadSources, setLeadSource] = useState();
    const [categories, setcategories] = useState();
    const [followUp, setFollowUp] = useState();
    const [mandatoryFields, setMandatoryFields] = useState([]);
    const [autoFocus, setAutoFocus] = useState(true);

    useEffect(() => {
        props.getLeadMandatoryFieldsList();
        props.clearUpdateLeadsError();
        props.updateLeadsSuccess();
        if (leadsDetails) {
            setFormFields({ ...leadsDetails });
            setQualification(leadsDetails && leadsDetails.qualificationLevel ? { label: leadsDetails.qualificationLevel, value: leadsDetails.qualificationLevel } : 'Select your qualification level');
            setLeadSource(leadsDetails && leadsDetails.leadSourceName ? { label: leadsDetails.leadSourceName, value: leadsDetails.leadSourceId } : 'Select your lead source');
            setcategories(leadsDetails && leadsDetails.categoryName ? { label: leadsDetails.categoryName, value: leadsDetails.categoryId } : 'Select your category');
            setFollowUp(leadsDetails && leadsDetails.followUpActionName ? { label: leadsDetails.followUpActionName, value: leadsDetails.followUpActionId } : 'Select  your follow up action');
            setIsActive(leadsDetails.leadStatus === "Active" ? true : false);
            setLeadOwner(leadsDetails && leadsDetails.leadOwnerUserName ? { label: `${leadsDetails.leadOwnerUserName} | ${leadsDetails.leadOwnerUserEmail}`, value: leadsDetails.leadOwnerUserId } : 'Select your lead owner')
        }
    }, [])

    useEffect(() => {
        if (leadMandatoryList) {
            const mandatoryFields = leadMandatoryList.filter(field => field.IsMandatory === true);
            const mandatoryFieldNames = mandatoryFields.map(field => field.FieldName);
            console.log(mandatoryFields);
            setMandatoryFields(mandatoryFieldNames);
        }
    }, [leadMandatoryList])
    const validateOnchange = (e) => {
        const { name, value } = e.target;
        let result = {};

        if (name === 'email') {
            console.log("email: ", value);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (value === "") {
                result = {
                    isValid: true,
                    message: null,
                };
            } else {
                result = {
                    isValid: emailRegex.test(value),
                    message: 'Enter valid email',
                };
            }
        }
        else if (name === 'phoneNo') {
            if (value) {
                const contactNumberRegex = /^\d{10}$/;
                result = {
                    isValid: contactNumberRegex.test(value),
                    message: 'Enter valid mobile number',
                };
            }
        }
        else {
            result = FormValidator.validate(e.target);
        }

        setFormFields({
            ...formFields,
            [name]: value || null
        })
        setErrors({
            ...errors,
            [name.toLowerCase()]: result.isValid ? null : result.message,
        })
    }
    const handleLeadeSource = (inputValue, callback) => {
        const { getLeadSourceAsyncSelect } = props;
        return getLeadSourceAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleLeadSourceSelect = (value) => {
        if (value === null) {
            setLeadSource(value)
            setFormFields({
                ...formFields,
                leadSourceId: null
            })
        } else {
            setLeadSource(value)
            setFormFields({
                ...formFields,
                leadSourceId: value.value
            })
            setErrors({
                ...errors,
                leadsourceid: null
            })
        }

    }

    const handleUserSelect = (value) => {
        setLeadOwner(value);
        setFormFields({
            ...formFields,
            leadOwnerUserId: value.value,
            leadOwnerUserName: value.label
        })
        setErrors({
            ...errors,
            leadowneruserid: null
        })
    }
    const handleQualificationSelect = (value) => {
        if (value === null) {
            setQualification(value)
            setFormFields({
                ...formFields,
                qualificationLevel: null
            })
        } else {
            setQualification(value)
            setFormFields({
                ...formFields,
                qualificationLevel: value.value
            })
            setErrors({
                ...errors,
                qualificationlevel: null
            })
        }

    }
    const handleFollowAction = (inputValue, callback) => {
        const { getFollowActionAsyncSelect } = props;
        return getFollowActionAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleFollowUpSelect = (value) => {
        if (value === null) {
            setFollowUp(value);
            setFormFields({
                ...formFields,
                followUpActionId: null
            })
        } else {
            setFollowUp(value);
            setFormFields({
                ...formFields,
                followUpActionId: value.value
            })
            setErrors({
                ...errors,
                followupactionid: null
            })
        }
    }
    const handleActiveStatusChange = () => {
        const status = !isActive;
        setFormFields({
            ...formFields,
            leadStatus: status ? 'Active' : 'Inactive'
        })
        setIsActive(!isActive)
        setErrors({
            ...errors,
            leadstatus: null
        })
    }
    const handleCategorySelect = (value) => {
        if (value === null) {
            setcategories(value)
            setFormFields({
                ...formFields,
                categoryId: null,
                categoryName: null
            })
        } else {
            setcategories(value)
            setFormFields({
                ...formFields,
                categoryId: value.value,
                categoryName: value.label
            })
            setErrors({
                ...errors,
                categoryid: null
            })
        }

    }
    const handleCategory = (inputValue, callback) => {
        const { getLeadCategoryAsyncSelect } = props;
        return getLeadCategoryAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleUsers = (inputValue, callback) => {
        const { getCrmUserAsyncSelect } = props;
        return getCrmUserAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleLeadEdit();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };

    const handleLeadEdit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { leadName, leadWebsite, leadContact, leadDesignation, leadResponsibility,
            email, phoneNo, leadSourceId, qualificationLevel, leadOwnerUserId, categoryId,
            followUpActionId, description } = formFields || {};
        let currentErrors = {};

        // leadOwneruserId and leadname fields is mandatory in API ,so it is manually validated.
        if (!leadOwnerUserId) {
            currentErrors.leadowneruserid = "Lead owner is required";
        }
        if (!leadName) {
            currentErrors.leadname = "Lead name is required";
        }

        if (mandatoryFields && mandatoryFields.length > 0) {
            mandatoryFields.forEach((field) => {
                switch (field.toLowerCase()) {
                    case 'leadname':
                        if (!leadName) {
                            currentErrors.leadname = "Lead name is required";
                        }
                        break;
                    case 'leadwebsite':
                        if (!leadWebsite) {
                            currentErrors.leadwebsite = "Lead website is required";
                        }
                        break;
                    case 'leadcontact':
                        if (!leadContact) {
                            currentErrors.leadcontact = "Lead contact is required";
                        }
                        break;
                    case 'leaddesignation':
                        if (!leadDesignation) {
                            currentErrors.leaddesignation = "Lead designation is required";
                        }
                        break;
                    case 'leadresponsibility':
                        if (!leadResponsibility) {
                            currentErrors.leadresponsibility = "Lead responsibility is required";
                        }
                        break;
                    case 'email':
                        if (!email) {
                            currentErrors.email = "Email is required";
                        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                            currentErrors.email = "Enter valid email";
                        }
                        break;
                    case 'phoneno':
                        if (!phoneNo) {
                            currentErrors.phoneno = "Mobile number is required";
                        } else if (!/^\d{10}$/.test(phoneNo)) {
                            currentErrors.phoneno = "Enter valid mobile number";
                        }
                        break;
                    case 'leadsourceid':
                        if (!leadSourceId) {
                            currentErrors.leadsourceid = "Lead source is required";
                        }
                        break;
                    case 'qualificationlevel':
                        if (!qualificationLevel) {
                            currentErrors.qualificationlevel = "Qualification level is required";
                        }
                        break;
                    case 'leadowneruserid':
                        if (!leadOwnerUserId) {
                            currentErrors.leadowneruserid = "Lead owner is required";
                        }
                        break;
                    case 'categoryid':
                        if (!categoryId) {
                            currentErrors.categoryid = "Lead category is required";
                        }
                        break;
                    case 'followupactionid':
                        if (!followUpActionId) {
                            currentErrors.followupactionid = "Follow up action is required";
                        }
                        break;
                    case 'description':
                        if (!description) {
                            currentErrors.description = "Description is required";
                        }
                        break;
                    default:
                        break;
                }
            });
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateLeads(formFields);
        }
    }
    console.log("formfield:", formFields);
    console.log("errors:", errors);

    return (
        <div>
            <Form>
                {errorMessage &&
                    (<VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />)
                }
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-primary border-5 mb-3"> &nbsp;Lead Information</div>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Name <span className="text-danger">*</span></Label>
                            <Input
                                onFocus={handleFocus}
                                autoFocus={true}
                                type="text"
                                name="leadName"
                                placeHolder="Enter your lead name"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadName}
                                invalid={errors && errors.leadname}
                            />
                            <FormFeedback>{errors && errors.leadname}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Website {mandatoryFields.find((i) => i.toLowerCase() === 'leadWebsite'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadWebsite"
                                placeHolder="Enter your lead website"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadWebsite}
                                invalid={errors && errors.leadwebsite}
                            />
                            <FormFeedback>{errors && errors.leadwebsite}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Contact {mandatoryFields.find((i) => i.toLowerCase() === 'leadContact'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadContact"
                                placeHolder="Enter your lead contact"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadContact}
                                invalid={errors && errors.leadcontact}
                            />
                            <FormFeedback>{errors && errors.leadcontact}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Designation {mandatoryFields.find((i) => i.toLowerCase() === 'leadDesignation'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadDesignation"
                                placeHolder="Enter your lead designation"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadDesignation}
                                invalid={errors && errors.leaddesignation}
                            />
                            <FormFeedback>{errors && errors.leaddesignation}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Responsibility {mandatoryFields.find((i) => i.toLowerCase() === 'leadResponsibility'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="leadResponsibility"
                                placeHolder="Enter your lead responsibility"
                                onChange={validateOnchange}
                                value={formFields && formFields.leadResponsibility}
                                invalid={errors && errors.leadresponsibility}
                            />
                            <FormFeedback>{errors && errors.leadresponsibility}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Email Address {mandatoryFields.find((i) => i.toLowerCase() === 'email'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="text"
                                name="email"
                                placeHolder="Enter your email address"
                                onChange={validateOnchange}
                                value={formFields && formFields.email}
                                invalid={errors && errors.email}
                            />
                            <FormFeedback>{errors && errors.email}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Mobile Number {mandatoryFields.find((i) => i.toLowerCase() === 'phoneNo'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                onKeyDown={
                                    (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                min='0'
                                type="number"
                                name="phoneNo"
                                placeHolder="Enter your mobile number"
                                onChange={validateOnchange}
                                value={formFields && formFields.phoneNo}
                                invalid={errors && errors.phoneno}
                            />
                            <FormFeedback>{errors && errors.phoneno}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Source {mandatoryFields.find((i) => i.toLowerCase() === 'leadSourceId'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.leadsourceid) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="leadSourceId"
                                defaultOptions
                                placeholder="Select your lead source"
                                loadOptions={handleLeadeSource}
                                onChange={handleLeadSourceSelect}
                                value={leadSources}
                                isClearable
                            />
                            {(errors && errors.leadsourceid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.leadsourceid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Qualification Level {mandatoryFields.find((i) => i.toLowerCase() === 'qualificationLevel'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.qualificationlevel) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                type="text"
                                name="qualificationLevel"
                                placeHolder="Select your qualification level"
                                options={QUALIFICATIONLEVELOPTIONS}
                                onChange={handleQualificationSelect}
                                value={qualification}
                                isClearable
                            />
                            {(errors && errors.qualificationlevel) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.qualificationlevel}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Owner <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.leadowneruserid) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="leadOwnerUserId"
                                defaultOptions
                                placeholder="Select your lead owner"
                                loadOptions={handleUsers}
                                onChange={handleUserSelect}
                                value={leadOwner}
                            />
                            {(errors && errors.leadowneruserid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.leadowneruserid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Lead Category {mandatoryFields.find((i) => i.toLowerCase() === 'categoryId'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.categoryid) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                type="text"
                                name="categoryId"
                                placeHolder="Select your category"
                                defaultOptions
                                loadOptions={handleCategory}
                                onChange={handleCategorySelect}
                                value={categories}
                                isClearable
                            />
                            {(errors && errors.categoryid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.categoryid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Follow Up Action {mandatoryFields.find((i) => i.toLowerCase() === 'followUpActionId'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.followupactionid) ? '#FF3D60' : "#CED4DA"
                                    })
                                }}
                                name="followUpActionId"
                                defaultOptions
                                placeholder="Select  your follow up action"
                                loadOptions={handleFollowAction}
                                onChange={handleFollowUpSelect}
                                value={followUp}
                                isClearable
                            />
                            {(errors && errors.followupactionid) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.followupactionid}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Description {mandatoryFields.find((i) => i.toLowerCase() === 'description'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <Input
                                onFocus={handleFocus}
                                type="textarea"
                                name="description"
                                placeHolder="Enter your description"
                                onChange={validateOnchange}
                                value={formFields && formFields.description}
                                invalid={errors && errors.description}
                            />
                            <FormFeedback>{errors && errors.description}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label className="col-form-label">Lead Status {mandatoryFields.find((i) => i.toLowerCase() === 'leadStatus'.toLowerCase()) ? <span style={{ color: 'red' }}>*</span> : ''}</Label>
                            <ul className="form-unstyled-list form-status-list">
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${!isActive ? 'active' : ''}`}
                                >
                                    Inactive
                                </li>
                                <li
                                    onClick={handleActiveStatusChange}
                                    className={`form-status-item ${isActive ? 'active' : ''}`}
                                >
                                    Active
                                </li>
                            </ul>
                        </FormGroup>
                    </Col>
                </Row >
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleLeadEdit}
                        disabled={isLeadsUpdatePending}
                    >
                        {isLeadsUpdatePending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form >
        </div >
    )
}

const mapStateToProps = (state) => ({
    errorMessage: state.crmLeads.leadsUpdateErrorMessage,
    isLeadsUpdateSuccess: state.crmLeads.isLeadsUpdateSuccess,
    isLeadsUpdatePending: state.crmLeads.isLeadsUpdatePending,
    leadMandatoryList: state.LeadMandatory.leadMandatoryList,
})
const mapDispatchToProps = (dispatch) => ({
    getLeadMandatoryFieldsList: (page, PageSize) => dispatch(getLeadMandatoryFieldsList(page, PageSize)),
    updateLeads: (data) => dispatch(updateLeads(data)),
    clearUpdateLeadsError: () => dispatch(clearUpdateLeadsError()),
    updateLeadsSuccess: () => dispatch(updateLeadsSuccess()),
    getCrmUserAsyncSelect: (searchValue, callback) => dispatch(getCrmUserAsyncSelect(searchValue, callback)),
    getAccountsAsyncSelect: (searchValue, callback) => dispatch(getAccountsAsyncSelect(searchValue, callback)),
    getLeadCategoryAsyncSelect: (searchValue, callback) => dispatch(getLeadCategoryAsyncSelect(searchValue, callback)),
    getLeadSourceAsyncSelect: (searchValue, callback) => dispatch(getLeadSourceAsyncSelect(searchValue, callback)),
    getFollowActionAsyncSelect: (searchValue, callback) => dispatch(getFollowActionAsyncSelect(searchValue, callback)),

})
export default connect(mapStateToProps, mapDispatchToProps)(UpdateLeads);