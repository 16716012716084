import React from 'react'
import Avatar from 'react-avatar'
import './UserCard.css'
import { ElementToolTip } from '../vwToolTip';
import Rating from 'react-rating';
import { Link } from 'react-router-dom';

const UserCard = ({ user, filter }) => {
  console.log("user:", user);
  const { StartDate, EndDate } = filter;
  const pendingTasksUrl = `/projectportal/tasks?StartDate=${StartDate}&EndDate=${EndDate}&UserEmail=${user.UserEmail}&Username=${user.UserName}&UserId=${user.Id}&pendingTask=true`;
  const todayTasksUrl = `/projectportal/tasks?StartDate=${EndDate}&EndDate=${EndDate}&UserEmail=${user.UserEmail}&Username=${user.UserName}&UserId=${user.Id}`;
  return (
    <>
      <div className="user-card-container">
        <div className="user-card">
          <div className="usercard-header">
            <Avatar name={user.UserName || "N/A"} round size="40" className="user-avatar" />
            <div className="user-info">
              <h5 ><span style={{ cursor: 'pointer' }} id={`username-${user.Id}`}>{user.UserName || "N/A"}</span> <ElementToolTip id={`username-${user.Id}`} name={`Username: ${user.UserName}`} /></h5>
              <p ><span style={{ cursor: 'pointer' }} id={`useremail-${user.Id}`}>{user.UserEmail || "N/A"}</span><ElementToolTip id={`useremail-${user.Id}`} name={`User Email: ${user.UserEmail}`} /></p>
            </div>
          </div>
          <div className="user-stats">
            <div>
              <p>Running Projects: <span>{user.RunningProject || 0}</span></p>
            </div>
            <div>
              <p>Managed Projects: <span style={{ textAlign: 'center' }}>{user.ManagedProject || 0}</span>
              </p>
            </div>
            <div>
              <Link to={pendingTasksUrl} style={{ cursor: 'pointer' }}>
                <p style={{ cursor: 'pointer' }}>Pending Tasks: <span style={{ textAlign: 'center' }}>{user.PendingTask || 0}</span></p>
              </Link>
            </div>
            <div>
              <Link to={todayTasksUrl} style={{ cursor: 'pointer' }}>
                <p>Today Tasks: <span style={{ textAlign: 'center' }}>{user.TodayTask || 0}</span></p>
              </Link>
            </div>
          </div>
          <div className='d-flex align-items-center ms-0'>
            <strong className="user-rating-label">Rating</strong>
            <div style={{ textAlign: 'center' }} className='p-1'>
              <Rating
                className="user-rating"
                emptySymbol="mdi mdi-star-outline text-warning fa-2x"
                fullSymbol="mdi mdi-star text-warning fa-2x"
                initialRating={(user && user.OverAllRating) || 0}
                readonly
              />
            </div>
            <div style={{ flex: 0.30, textAlign: 'center' }}>
              <span>{(user && user.OverAllRating) || 0}/5</span>
            </div>
          </div>


          <div className="user-summary mt-2">
            <button className="total-projects" style={{ cursor: 'default' }}>
              <span className=''>{user.TotalProject || 0}</span>
              Total Projects

            </button>
            <button className="completed-projects" style={{ cursor: 'default' }}>
              <span>{user.CompletedProject || 0}</span>
              Completed Projects
            </button>
          </div>
        </div>
      </div>
    </>

  )
}

export default UserCard