import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_TIMESHEET_REPORT_LIST_ERROR, CLEAR_TIMESHEET_REPORT_USER_LIST_ERROR, CLOSE_MODAL_TIMESHEET_REPORT, OPEN_MODAL_TIMESHEET_REPORT, SET_TIMESHEET_REPORT_EXPORT_PENDING, SET_TIMESHEET_REPORT_LIST, SET_TIMESHEET_REPORT_LIST_PENDING, SET_TIMESHEET_REPORT_USER_LIST, SET_TIMESHEET_REPORT_USER_LIST_PENDING, SET_TIMESHEET_TOGGLE_VIEW_DOCS, TIMESHEET_REPORT_EXPORT_ERROR, TIMESHEET_REPORT_EXPORT_SUCCESS, TIMESHEET_REPORT_LIST_ERROR, TIMESHEET_REPORT_USER_LIST_ERROR } from "./constants";

export const sendTimesheetToggleViewDocs = (toggleView) => ({
    type: SET_TIMESHEET_TOGGLE_VIEW_DOCS,
    payload: { toggleView },
})

//-----------------------------------------------------------LIST-----------------------------------------------------------------------------------
export const openReportTimeSheetModalAction = () => ({
    type: OPEN_MODAL_TIMESHEET_REPORT
});
export const closeReportTimeSheetModalAction = () => ({
    type: CLOSE_MODAL_TIMESHEET_REPORT
});
export const setReportListPending = () => ({
    type: SET_TIMESHEET_REPORT_LIST_PENDING
});
export const setReportList = (reportList) => ({
    type: SET_TIMESHEET_REPORT_LIST,
    payload: { reportList }
});
export const clearReportListError = () => ({
    type: CLEAR_TIMESHEET_REPORT_LIST_ERROR
});
export const reportListError = (errorMessage) => ({
    type: TIMESHEET_REPORT_LIST_ERROR,
    payload: { errorMessage },
})

export const setReportUserListPending = () => ({
    type: SET_TIMESHEET_REPORT_USER_LIST_PENDING
});
export const setReportUserList = (reportUserList) => ({
    type: SET_TIMESHEET_REPORT_USER_LIST,
    payload: { reportUserList }
});
export const clearReportUserListError = () => ({
    type: CLEAR_TIMESHEET_REPORT_USER_LIST_ERROR
});
export const reportUserListError = (errorMessage) => ({
    type: TIMESHEET_REPORT_USER_LIST_ERROR,
    payload: { errorMessage },
})

export const getTimesheetReportList = (page, PageSize, filter) => (dispatch) => {
    dispatch(setReportListPending());
    getToken(dispatch).then((accessToken) => {
        agent.TimeSheet.getTimesheetReportList(accessToken, page, PageSize, filter)
            .then((reportList) => {
                dispatch(setReportList(reportList));
                dispatch(clearReportListError());
            })
            .catch((err) => {
                console.log('getTimesheetReportList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportListError(error))
            });
    });
}

export const getTimesheetProjectUserReportList = (page, PageSize, filter) => (dispatch) => {
    dispatch(setReportUserListPending());
    getToken(dispatch).then((accessToken) => {
        agent.TimeSheet.getTimesheetProjectUserReportList(accessToken, page, PageSize, filter)
            .then((reportList) => {
                dispatch(setReportUserList(reportList));
                dispatch(clearReportUserListError());
            })
            .catch((err) => {
                console.log('getProjectUserReportList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportUserListError(error))
            });
    });
}

export const setReportExportPending = () => ({
    type: SET_TIMESHEET_REPORT_EXPORT_PENDING
});

export const setReportExportSuccess = () => ({
    type: TIMESHEET_REPORT_EXPORT_SUCCESS,
});

export const reportExportListError = (errorMessage) => ({
    type: TIMESHEET_REPORT_EXPORT_ERROR,
    payload: { errorMessage },
});

export const getTimesheetReportListExport = (filter) => (dispatch) => {
    dispatch(setReportExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.TimeSheet.getTimesheetReportListExport(accessToken, filter)
            .then((taskList) => {
                dispatch(setReportExportSuccess());
                // dispatch(clearTaskExportListError());
                return Promise.resolve(taskList);
            })
            .catch((err) => {
                console.log('getTimesheetReportListExport server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportExportListError(error))
            });
    });
}

export const getTimesheetReportUserListExport = (filter) => (dispatch) => {
    dispatch(setReportExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.TimeSheet.getTimesheetReportUserListExport(accessToken, filter)
            .then((userList) => {
                dispatch(setReportExportSuccess());
                // dispatch(clearTaskExportListError());
                return Promise.resolve(userList);
            })
            .catch((err) => {
                console.log('getTimesheetReportUserListExport server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportExportListError(error))
            });
    });
}