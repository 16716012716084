import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { DASHBOARD_PROJECT_CLEAR_ERROR, DASHBOARD_PROJETS_ERROR_MESSAGE, SET_DASHBOARD, SET_PROJECT_DASHBOARD_PENDING, SET_PROJECT_DASHBOARD_SUCCESS } from "./constants";


export const setDashboard = (dashboard) => ({
    type: SET_DASHBOARD,
    payload: { dashboard },
});

export const setProjectDashboardPending = () => ({
    type: SET_PROJECT_DASHBOARD_PENDING,
})
export const setProjectDashboardSuccess = () => ({
    type: SET_PROJECT_DASHBOARD_SUCCESS,
})

export const dashboardProjectErrorMessage = (dashboardProjectsErrorMessage) => ({
    type: DASHBOARD_PROJETS_ERROR_MESSAGE,
    payload: { dashboardProjectsErrorMessage },
});

export const dashboardProjectsClearError = () => ({
    type: DASHBOARD_PROJECT_CLEAR_ERROR,
})

export const getProjectDashboard = (formfields) => (dispatch) => {
    dispatch(setProjectDashboardPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectDashboard.getDashboardCounts(accessToken, formfields)
            .then((dashboardCounts) => {
                console.log("Dashboard :", dashboardCounts);
                dispatch(setDashboard(dashboardCounts));
                dispatch(dashboardProjectsClearError());
                dispatch(setProjectDashboardSuccess());
            })
            .catch((err) => {
                console.log('getProjectDashboard server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(dashboardProjectErrorMessage(error));
                dispatch(setProjectDashboardSuccess());
            });
    })
}