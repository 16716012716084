import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import {
    clearCreateIndustryError,
    createIndustrySuccess,
    createNewIndustry
} from '../../../../../../store/Modules/crm/Settings/Industry/actions';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';

const AddIndustry = (props) => {
    const { isIndustryCreatePending, errorMessage } = props;
    const [regionDetails, setRegionDetails] = useState();
    const [errors, setErrors] = useState();
    const [autoFocus, setAutoFocus] = useState(true);
    useEffect(() => {
        props.clearCreateIndustryError();
        props.createIndustrySuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setRegionDetails({
            ...regionDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }
    //------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            console.log('autoFocus:', autoFocus);
            if (event.key === 'Escape') {
                console.log('escape key');
                setAutoFocus(false);
                const inputs = document.querySelectorAll('input');
                inputs.forEach(input => input.blur());
            }
            else if (event.key === 'Tab') {
                console.log('tab key');
                setAutoFocus(true);
            }
            else if (event.key === 'Enter') {
                const activeElement = document.activeElement;

                if (activeElement.type === 'checkbox') {
                    activeElement.click();
                }
                else if (autoFocus === false) {
                    console.log('enter button');
                    handleSubmit();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoFocus]);

    const handleFocus = (event) => {
        setAutoFocus(true);
    };
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let currentErrors = {};
        const { name } = regionDetails || {};

        if (!name) {
            currentErrors.name = 'Industry type is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createIndustry(regionDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label>Industry Type <span className='text-danger'>*</span></Label>
                <Input
                    onFocus={handleFocus}
                    autoFocus={true}
                    type="text"
                    name="name"
                    placeHolder="Enter industry type"
                    onChange={validateOnchange}
                    value={regionDetails && regionDetails.name}
                    invalid={errors && errors.name}
                />
                <FormFeedback>{errors && errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isIndustryCreatePending}
                >
                    {isIndustryCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isIndustryCreatePending: state.crmIndustry.isIndustryCreatePending,
    errorMessage: state.crmIndustry.industryCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createIndustry: (industryDetails) => dispatch(createNewIndustry(industryDetails)),
    clearCreateIndustryError: () => dispatch(clearCreateIndustryError()),
    createIndustrySuccess: () => dispatch(createIndustrySuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddIndustry);