export const OPEN_CREATE_TASK_MODAL = "OPEN_CREATE_TASK_MODAL";
export const CLOSE_CREATE_TASK_MODAL = "CLOSE_CREATE_TASK_MODAL";
export const SET_PROJECT_USER_LIST = "SET_PROJECT_USER_LIST";
export const CREATE_TASK_ERROR = "CREATE_TASK_ERROR";
export const CREATE_TASK_PENDING = "CREATE_TASK_PENDING";
export const CLEAR_TASK_ERROR = "CLEAR_TASK_ERROR";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const SET_TASK_PENDING = "SET_TASK_PENDING";
export const SET_TASK_LIST = "SET_TASK_LIST";
export const CLEAR_TASK_LIST_ERROR = "CLEAR_TASK_LIST_ERROR";
export const TASK_LIST_ERROR = "TASK_LIST_ERROR";
export const SET_TASK_DETAILS = "SET_TASK_DETAILS";
export const CLEAR_TASK_DETAILS_ERROR = "CLEAR_TASK_DETAILS_ERROR";
export const SET_TASK_DETAILS_ERROR = "SET_TASK_DETAILS_ERROR";
export const OPEN_EDIT_TASK_MODAL = "OPEN_EDIT_TASK_MODAL";
export const CLOSE_EDIT_TASK_MODAL = "CLOSE_EDIT_TASK_MODAL";
export const CLEAR_UPDATE_TASK_ERROR = "CLEAR_UPDATE_TASK_ERROR";
export const UPDATE_TASK_PENDING = "UPDATE_TASK_PENDING";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_ERROR = "UPDATE_TASK_ERROR";
export const SET_TASK_DETAILS_PENDING = "SET_TASK_DETAILS_PENDING";
export const KANBAN_TASK_LIST = "KANBAN_TASK_LIST";
export const CLOSE_KANBAN_EDIT_TASK_MODAL = "CLOSE_KANBAN_EDIT_TASK_MODAL";
export const UPDATE_KANBAN_TASK_ERROR = "UPDATE_KANBAN_TASK_ERROR";
export const SET_MILESTONE_TASK_LIST = "SET_MILESTONE_TASK_LIST";
export const SET_MILESTONE_TASK_PENDING = "SET_MILESTONE_TASK_PENDING";
export const CLEAR_MILESTONE_TASK_LIST_ERROR = "CLEAR_MILESTONE_TASK_LIST_ERROR";
export const MILESTONE_TASK_LIST_ERROR = "MILESTONE_TASK_LIST_ERROR";
export const CLEAR_MILESTONE_TASK_LIST = "CLEAR_MILESTONE_TASK_LIST";
export const SET_MILESTONE_PIE_CHART_TASK_PENDING = "SET_MILESTONE_PIE_CHART_TASK_PENDING";
export const SET_MILESTONE_PIE_CHART_LIST = "SET_MILESTONE_PIE_CHART_LIST";
export const CLEAR_MILESTONE_PIE_CHART_LIST_ERROR = "CLEAR_MILESTONE_PIE_CHART_LIST_ERROR";
export const MILESTONE_PIE_CHART_LIST_ERROR = "MILESTONE_PIE_CHART_LIST_ERROR";
export const MILESTONE_CREATE_OPEN = "MILESTONE_CREATE_OPEN";
export const MILESTONE_CREATE_CLOSE = "MILESTONE_CREATE_CLOSE";
export const SET_MILESTONE_TASK_DATE_PENDING = "SET_MILESTONE_TASK_DATE_PENDING";
export const SET_MILESTONE_TASK_DATE = "SET_MILESTONE_TASK_DATE";
export const CLEAR_MILESTONE_TASK_DATE_ERROR = "CLEAR_MILESTONE_TASK_DATE_ERROR";
export const MILESTONE_TASK_DATE_ERROR = "MILESTONE_TASK_DATE_ERROR";
export const CLEAR_MILESTONE_DATE = "CLEAR_MILESTONE_DATE";
export const SET_TASK_EXPORT_PENDING = "SET_TASK_EXPORT_PENDING";
export const SET_TASK_EXPORT_LIST = "SET_TASK_EXPORT_LIST";
export const CLEAR_TASK_EXPORT_LIST_ERROR = "CLEAR_TASK_EXPORT_LIST_ERROR";
export const TASK_EXPORT_LIST_ERROR = "TASK_EXPORT_LIST_ERROR";
export const OPEN_CLONE_TASK_MODAL = "OPEN_CLONE_TASK_MODAL";
export const CLOSE_CLONE_TASK_MODAL = "CLOSE_CLONE_TASK_MODAL";
export const SET_GANTT_PROJECT_LIST = "SET_GANTT_PROJECT_LIST";
export const TASK_EXPORT_SUCCESS = "TASK_EXPORT_SUCCESS";
export const OPEN_EDIT_TASK_STATUS = "OPEN_EDIT_TASK_STATUS";
export const CLOSE_EDIT_TASK_STATUS = "CLOSE_EDIT_TASK_STATUS";
export const CLEAR_UPDATE_TASK_STATUS_ERROR = "CLEAR_UPDATE_TASK_STATUS_ERROR";
export const UPDATE_TASK_STATUS_PENDING = "UPDATE_TASK_STATUS_PENDING";
export const UPDATE_TASK_STATUS_SUCCESS = "UPDATE_TASK_STATUS_SUCCESS";
export const UPDATE_TASK_STATUS_ERROR = "UPDATE_TASK_STATUS_ERROR";
export const SET_KANBAN_LIST_PENDING = "SET_KANBAN_LIST_PENDING";
export const IS_STATUS_TASK_EDIT_OPEN = "IS_STATUS_TASK_EDIT_OPEN"
export const IS_TASK_STATUS_LOADING_BOOLEAN_OPEN = "IS_TASK_STATUS_LOADING_BOOLEAN_OPEN"
export const IS_TASK_STATUS_LOADING_BOOLEAN_CLOSE = "IS_TASK_STATUS_LOADING_BOOLEAN_CLOSE"
export const PROJECT_ACCESS_VALIDATION_PENDING = "PROJECT_ACCESS_VALIDATION_PENDING"
export const CLEAR_PROJECT_VALIDATION_ERROR = "CLEAR_PROJECT_VALIDATION_ERROR"
export const PROJECT_ACCESS_VALIDATION_ERROR = "PROJECT_ACCESS_VALIDATION_ERROR"
export const CLEAR_TASK_LIST = "CLEAR_TASK_LIST"
export const KANBAN_LIST_SUCCESS = "KANBAN_LIST_SUCCESS"
// export const KANBAN_LIST_ERROR = "KANBAN_LIST_ERROR";

//----------------------------------------Rating Update----------------------------------------------------------------------
export const UPDATE_RATING_PENDING = "UPDATE_RATING_PENDING";
export const UPDATE_RATING_SUCCESS = "UPDATE_RATING_SUCCESS";
export const UPDATE_RATING_ERROR = "UPDATE_RATING_ERROR";
export const UPDATE_RATING_ERROR_CLEAR = "UPDATE_RATING_ERROR_CLEAR";


export const TASK_EXPORT_HEADERS = [
    "TASK NAME",
    "PROJECT NAME",
    "MILESTONE NAME",
    // "DESCRIPTION",
    "TYPE",
    "SCOPE",
    "STATUS",
    "ASSIGNED USERNAME",
    "ASSIGNED USER EMAIL",
    "REVIEWER",
    "REVIEWER EMAIL",
    "PRIORITY",
    "EFFORTS",
    "START DATE",
    "END DATE",
    "PLANNED HOURS",
    "CREATED DATE",
    "UPDATED DATE"
];

export const HOURS = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
]

export const MINUTES = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '47', label: '47' },
    { value: '48', label: '48' },
    { value: '49', label: '49' },
    { value: '50', label: '50' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '53', label: '53' },
    { value: '54', label: '54' },
    { value: '55', label: '55' },
    { value: '56', label: '56' },
    { value: '57', label: '57' },
    { value: '58', label: '58' },
    { value: '59', label: '59' },
]

export const TASK_TYPE_OPTION = [
    { label: "Task", value: "Task" },
    { label: "Bug", value: "Bug" },
    { label: "Discussion", value: "Discussion" },
]

export const PRIORITY_OPTION = [
    { label: "Critical", value: "Critical" },
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
    { label: "Lowest", value: "Lowest" },
]

export const EFFORT_OPTION = [
    { label: "XS", value: "XS" },
    { label: "S", value: "S" },
    { label: "M", value: "M" },
    { label: "L", value: "L" },
    { label: "XL", value: "XL" },
    { label: "XXL", value: "XXL" },
]

export const STATUS_OPTION = [
    { label: "New", value: "New" },
    { label: "InProgress", value: "InProgress" },
    { label: "OnHold", value: "OnHold" },
    { label: "Internal Testing", value: "Internal Testing" },
    { label: "Internal Testing Done", value: "Internal Testing Done" },
    { label: "Waiting for Approval", value: "Waiting for Approval" },
    { label: "Done", value: "Done" },
]

export const STATUS_OPTION_USER_EDIT = [
    { label: "New", value: "New" },
    { label: "InProgress", value: "InProgress" },
    { label: "OnHold", value: "OnHold" },
    { label: "Internal Testing", value: "Internal Testing" }
]

export const SCOPE_OPTIONS = [
    { label: "API", value: "API" },
    { label: "Console App", value: "Console App" },
    { label: "Desktop App", value: "Desktop App" },
    // { label: "General", value: "General" },
    { label: "Mobile App", value: "Mobile App" },
    { label: "UI", value: "UI" },
    { label: "Others", value: "Others" },
]
