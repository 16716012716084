export const SET_TIMESHEET_TOGGLE_VIEW_DOCS = "SET_TIMESHEET_TOGGLE_VIEW_DOCS"
export const SET_TIMESHEET_REPORT_LIST_PENDING = "SET_TIMESHEET_REPORT_LIST_PENDING"
export const SET_TIMESHEET_REPORT_LIST = "SET_TIMESHEET_REPORT_LIST"
export const CLEAR_TIMESHEET_REPORT_LIST_ERROR = "CLEAR_TIMESHEET_REPORT_LIST_ERROR"
export const TIMESHEET_REPORT_LIST_ERROR = "TIMESHEET_REPORT_LIST_ERROR"
export const SET_TIMESHEET_REPORT_USER_LIST_PENDING = "SET_TIMESHEET_REPORT_USER_LIST_PENDING"
export const SET_TIMESHEET_REPORT_USER_LIST = "SET_TIMESHEET_REPORT_USER_LIST"
export const CLEAR_TIMESHEET_REPORT_USER_LIST_ERROR = "CLEAR_TIMESHEET_REPORT_USER_LIST_ERROR"
export const TIMESHEET_REPORT_USER_LIST_ERROR = "TIMESHEET_REPORT_USER_LIST_ERROR"
export const SET_TIMESHEET_REPORT_EXPORT_PENDING = "SET_TIMESHEET_REPORT_EXPORT_PENDING"
export const TIMESHEET_REPORT_EXPORT_SUCCESS = "TIMESHEET_REPORT_EXPORT_SUCCESS"
export const TIMESHEET_REPORT_EXPORT_ERROR = "TIMESHEET_REPORT_EXPORT_ERROR"
export const OPEN_MODAL_TIMESHEET_REPORT = "OPEN_MODAL_TIMESHEET_REPORT"
export const CLOSE_MODAL_TIMESHEET_REPORT = "CLOSE_MODAL_TIMESHEET_REPORT"