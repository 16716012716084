import EditTaskPercentageStatus from './EditTaskPercentageStatus'
import React, { useEffect } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Table
} from "reactstrap";
import { connect } from "react-redux";
import moment from 'moment';
import VWModel from "../../../../../../components/Modal/VWModal";
import VWMessage from "../../../../../../components/vwMessage/vwMessage";
import NoRecord from "../../../../../../components/NoRecords/noRecords";
import VWPagination from "../../../../../../components/vwPagination/vwPagination";
import { useState } from "react";
import { getPager } from "../../../../../../helper/common";
import VWSpinner from "../../../../../../components/vwSpinner/vwSpinner";
import { clearUpdateTaskPercentageError, closeEditTaskPercentageModal, getTaskPercentageStatusList, openEditTaskPercetageStatusModal, setTaskPercentageDetails, updateTaskPercentage, updateTaskPercentageSuccess } from '../../../../../../store/Modules/ProjectPortal/Settings/TaskPercentageStatus/actions';
import { setIsInlineUpdate } from '../../../../../../store/Modules/crm/Settings/SettingsInlineUpdate/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faXmark } from '@fortawesome/free-solid-svg-icons';

const TaskPercentageStatus = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const { userAppDetails } = props.currentProfile || {};
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    const setPage = (page = 1) => {
        props.getTaskPercentageStatusList(App[0] && App[0].appId, page, PageSize);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.setIsInlineUpdate();
        props.clearUpdateTaskPercentageError();
        props.updateTaskPercentageSuccess();
    }, [])
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedTaskpercentageList = [...props.taskPercentageStatusList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'Percentage') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleAddOpenTaskScope = () => {
        props.openAddTaskScopeModal();
    }
    const handleClose = () => {
        props.closeAddTaskScopeModal();
    };

    const handlepercentageStatusEdit = (id) => {
        const { setTaskPercentageDetails, openEditTaskPercetageStatusModal } = props;
        const selected_Percentage = sortedTaskpercentageList.find((category) => {
            return category.Id === id;
        });
        setTaskPercentageDetails(selected_Percentage);
        console.log("selected_Percentage:", selected_Percentage);

        openEditTaskPercetageStatusModal();
    }
    const handleEditClose = () => {
        props.closeEditTaskPercentageModal();
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    const [errors, setErrors] = useState();
    const [editInlineList, setEditInlineList] = useState({ Percentage: '' });

    const handleEditClick = (itemId, IsActive, Percentage) => {
        props.setIsInlineUpdate(itemId);
        setEditInlineList({ ...editInlineList, Percentage: Percentage, isActive: IsActive, Id: itemId });
        setErrors({});
    };
    const handleClearClick = () => {
        props.setIsInlineUpdate(null);
    };

    const handleSave = (e) => {
        let currentErrors = {};
        const { Percentage } = editInlineList || {};


        if (!Percentage) {
            currentErrors.Percentage = 'Task percentage is required';
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: currentErrors
        }));
        if (Object.keys(currentErrors).length === 0) {
            props.updateTaskPercentage(editInlineList, App[0] && App[0].appId);
        }
    }


    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setEditInlineList({
            ...editInlineList,
            [name]: value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: {
                ...prevErrors[editInlineList.Id],
                [name]: value ? null : `Task percentage is required`
            }
        }));
    }

    return (
        <React.Fragment>
            <div>
                <Card >
                    <CardTitle className=" fs-5 border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center fw-bold">
                        TASK STATUS PERCENTAGE
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody>
                        <div style={{ height: "380px", overflowX: "auto" }}>
                            {props.errorMessage ? <div className='w-100'>
                                {props.errorMessage && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th onClick={() => handleSort('StatusName')} className='hand-cursor'>
                                            Status Name &nbsp;
                                            {sortOrder === 'StatusName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('Percentage')} className='hand-cursor'>
                                            Percentage &nbsp;
                                            {sortOrder === 'Percentage' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.taskPercentageStatusList && props.taskPercentageStatusList.length > 0 ?
                                        sortedTaskpercentageList && sortedTaskpercentageList.map((data) => (
                                            <tr class="middle-align">
                                                <td>{data.StatusName || "N/A"}</td>
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <InputGroup size='sm'>
                                                                <Input
                                                                    autoFocus={true}
                                                                    size='sm'
                                                                    type="number"
                                                                    className='border-end-0'
                                                                    name="Percentage"
                                                                    placeHolder="Enter task percentage"
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;

                                                                        if (value === "") {
                                                                            validateOnchange(e);
                                                                        } else if (/^(0|0[0-9]|0[1-9][0-9]|[1-9][0-9]?|100)$/.test(value)) {
                                                                            // Allow "0", "01" to "09", "099", and valid numbers from 1 to 100
                                                                            validateOnchange(e);
                                                                        } else if (/^00[0-9]*$/.test(value)) {
                                                                            // Block inputs like "001", "00010", etc.
                                                                            e.preventDefault();
                                                                        } else {
                                                                            // Block any other invalid inputs
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    value={editInlineList && editInlineList.Percentage}
                                                                    invalid={errors && errors[editInlineList.Id]?.Percentage}
                                                                    onKeyDown={
                                                                        (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onBlur={(e) => {
                                                                        let value = parseFloat(e.target.value);
                                                                        if (value < 0) {
                                                                            e.target.value = 0;
                                                                        } else if (value > 100) {
                                                                            e.target.value = 100;
                                                                        }
                                                                    }}
                                                                />
                                                                <Button
                                                                    disabled
                                                                    style={errors && errors[editInlineList.Id]?.Percentage ?
                                                                        {
                                                                            borderColor: 'red',
                                                                            borderRadius: "0px 5px 5px 0px",
                                                                            borderLeft: 'none'

                                                                        }
                                                                        : {
                                                                            borderRadius: "0px 5px 5px 0px",
                                                                            borderColor: 'silver',
                                                                        }
                                                                    }
                                                                    className='bg-white border-start-0'
                                                                >
                                                                    <div className='text-dark fs-5 fw-bold'> % </div>
                                                                </Button>
                                                                <FormFeedback>{errors && errors[editInlineList.Id]?.Percentage}</FormFeedback>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>{`${data.Percentage || 0}%`}</td>
                                                }
                                                <td>
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{ color: "green", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleSave()}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faXmark}
                                                                style={{ color: "red", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleClearClick(data.Id)}
                                                            />
                                                        </>
                                                    </td>
                                                    :
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                            onClick={() => handleEditClick(data.Id, data.IsActive, data.Percentage)}
                                                        />
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                        : props.isTaskpercentageStatusListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.taskPercentageStatusList && props.taskPercentageStatusList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="EDIT TASK STATUS PERCENTAGE"
                    modalSize="medium"
                    isOpen={props.isUpdateTaskPercentageOpen}
                    handleClose={handleEditClose}
                >
                    <EditTaskPercentageStatus
                        handleClose={handleEditClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    taskPercentageStatusList: state.TaskPercentage.taskPercentageStatusList,
    totalCount: state.TaskPercentage.taskPercentageStatusListCount,
    isTaskpercentageStatusListPending: state.TaskPercentage.isTaskpercentageStatusListPending,
    errorMessage: state.TaskPercentage.taskPercentageStatusListErrorMessage,
    isUpdateTaskPercentageOpen: state.TaskPercentage.isUpdateTaskPercentageOpen,
    isInlineEditID: state.SettingsInlineEdit.isInlineEditID,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    getTaskPercentageStatusList: (appId, page, PageSize) => dispatch(getTaskPercentageStatusList(appId, page, PageSize)),
    openEditTaskPercetageStatusModal: () => dispatch(openEditTaskPercetageStatusModal()),
    closeEditTaskPercentageModal: () => dispatch(closeEditTaskPercentageModal()),
    setTaskPercentageDetails: (selected_Percentage) => dispatch(setTaskPercentageDetails(selected_Percentage)),
    setIsInlineUpdate: (itemId) => dispatch(setIsInlineUpdate(itemId)),
    updateTaskPercentage: (taskStatusPercentageDetails, appId) => dispatch(updateTaskPercentage(taskStatusPercentageDetails, appId)),
    clearUpdateTaskPercentageError: () => dispatch(clearUpdateTaskPercentageError()),
    updateTaskPercentageSuccess: () => dispatch(updateTaskPercentageSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(TaskPercentageStatus)
