import React, { useEffect, useState } from 'react'
import Select from "react-select";
import moment from 'moment';
import Async from 'react-select/async';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table } from 'reactstrap'
import ComponentCard from '../../formGroup/formGroup'
import { connect } from 'react-redux';
import { createTimeSheet, createTimeSheetSuccess, getLoggedHoursAction, getProjectAsyncSelect, getTaskListSelect } from '../../../../store/Modules/Timesheet/myTimesheet/actions';
import VWMessage from "../../../../components/vwMessage/vwMessage"
import VWButton from "../../../../components/vwButton/VWButton"
import { getTaskScopeAsyncSelectAction } from '../../../../store/Modules/ProjectPortal/Task/actions';

const hours = [

  { value: '00', label: '00' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },

]

const minutes = [
  { value: '00', label: '00' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
  { value: '32', label: '32' },
  { value: '33', label: '33' },
  { value: '34', label: '34' },
  { value: '35', label: '35' },
  { value: '36', label: '36' },
  { value: '37', label: '37' },
  { value: '38', label: '38' },
  { value: '39', label: '39' },
  { value: '40', label: '40' },
  { value: '41', label: '41' },
  { value: '42', label: '42' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '45', label: '45' },
  { value: '46', label: '46' },
  { value: '47', label: '47' },
  { value: '48', label: '48' },
  { value: '49', label: '49' },
  { value: '50', label: '50' },
  { value: '51', label: '51' },
  { value: '52', label: '52' },
  { value: '53', label: '53' },
  { value: '54', label: '54' },
  { value: '55', label: '55' },
  { value: '56', label: '56' },
  { value: '57', label: '57' },
  { value: '58', label: '58' },
  { value: '59', label: '59' },
]
const ScopeOptions = [
  { label: 'Development', value: 'Development' },
  { label: 'Testing', value: 'Testing' },
  { label: 'Discussion', value: 'Discussion' },
  { label: 'Review', value: 'Review' },
  { label: 'Deployment', value: 'Deployment' },
  { label: 'Documentation', value: 'Documentation' },
]
const CreateTimeSheets = (props) => {
  const [number, setNumber] = useState(0);
  const [hour, setHours] = useState('00');
  const [minute, setMinutes] = useState('00');
  const [currentDate, setCurrentDate] = useState(moment().format());
  const [keyChange, setKeyChange] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [scopeType, setScopeType] = useState([]);
  const [autoFocus, setAutoFocus] = useState(true);

  useEffect(() => {
    props.getLoggedHours(currentDate);
    props.createTimeSheetSuccess();
  }, [])

  const { userAppDetails } = props && props.currentProfile;
  const App = userAppDetails.filter((i) => {
    return (i.appName === "Timesheets") ? i.appId : ""
  })
  const { filteredData, appId } = props;
  console.log("filteredData:", filteredData);

  const handleAdd = () => {
    const No = number + 1
    const object = {
      TempId: No,
      userId: props.userDetails && props.userDetails.userId,
      workDate: currentDate,
      // projectName: '',
      taskList: [],
      taskValue: { label: 'Select task', value: '' },
      ProjectValue: { label: 'Select project', value: '' },
      taskId: "",
      Hours: hour,
      Mint: minute,
      scopeId: '',
      projectId: ''
    }
    setFormFields([...formFields, object])
    setNumber(No)
  }

  const handleChangeProjectSelect = (e, i) => {
    if (keyChange[i]) {
      keyChange[i] = keyChange[i] + 1;
    }
    else {
      keyChange[i] = 1;
    }

    const item = [...formFields];
    item[i].projectId = e.value;
    // Clear task selection when project changes
    item[i].taskId = "";
    item[i].ProjectValue.label = e.label;
    item[i].ProjectValue.value = e.value;
    item[i].taskValue.label = 'Select task';
    item[i].taskValue.value = '';
    item[i].taskList = []; // Clear previous tasks

    setFormFields(item);
    setKeyChange(keyChange);
    // Fetch the task list for the selected project
    if (e.value) {
      props.getTaskListSelectAction(
        appId,
        props.currentProfile && props.currentProfile.userId,
        e.value,
        '',
        (TaskLists) => {
          const data = [...formFields];
          data[i].taskList = TaskLists || [];
          setFormFields(data);
        }
      );
    }
  };
  const handleChangeTaskSelect = (e, i) => {
    const data = [...formFields];
    data[i].taskId = e.value;
    data[i].taskValue.label = e.label;
    data[i].taskValue.value = e.value;
    setFormFields(data);
  }

  const handleChangeScopeSelect = (e, i) => {
    const data = [...formFields];
    data[i].scopeId = e.value;
    setFormFields(data);
  }

  const selectOnChangeHours = (e, i) => {
    const data = [...formFields];
    data[i].Hours = e.value;
    setFormFields(data);
  }
  const selectOnChangeMinutes = (e, i) => {
    const data = [...formFields];
    data[i].Mint = e.value;
    setFormFields(data);
  }

  const handleChangeDateSelect = (e) => {
    const { name, value } = e.target || {};
    const updatedFormFields = formFields.map((obj) => ({ ...obj, workDate: value }));
    setFormFields(updatedFormFields);
    setCurrentDate(value);
    props.getLoggedHours(value);
  }
  const handleDelete = (i) => {
    const updatedFormFields = [...formFields];

    // const updatedKeyChange = [...keyChange];

    updatedFormFields.splice(i, 1);
    // updatedKeyChange.splice(i, 1);

    setFormFields(updatedFormFields);
    // setKeyChange(updatedKeyChange);
  }
  const handleLoadProject = (inputValue, callback) => {
    const { getProjectAsyncSelectAction } = props;
    return getProjectAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
  };

  //------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log('autoFocus:', autoFocus);
      if (event.key === 'Escape') {
        console.log('escape key');
        setAutoFocus(false);
        const inputs = document.querySelectorAll('input');
        inputs.forEach(input => input.blur());
      }
      else if (event.key === 'Tab') {
        console.log('tab key');
        setAutoFocus(true);
      }
      else if (event.key === 'Enter') {
        const activeElement = document.activeElement;

        if (activeElement.type === 'checkbox') {
          activeElement.click();
        }
        else if (autoFocus === false) {
          console.log('enter button');
          handleCreateTimeSheet();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [autoFocus]);

  const handleFocus = (event) => {
    setAutoFocus(true);
  };

  const handleCreateTimeSheet = (e) => {
    if (e) {
      e.preventDefault();
    }
    props.createTimeSheetAction(formFields, appId, filteredData);
  };

  const handleTaskScope = (inputValue, callback) => {
    const { getTaskScopeAsyncSelectAction } = props;
    return getTaskScopeAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };

  var valid = function (current) {
    var yesterday = moment();
    return current.isBefore(yesterday);
  }
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    container: (provided) => ({
      ...provided,
      minWidth: '150px',
    }),
  };
  const customStylesTime = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    container: (provided) => ({
      ...provided,
      minWidth: '50px',
    }),
  };

  const handleLoadTask = (inputValue, projectId, callback) => {
    const { getTaskListSelectAction, currentProfile } = props;
    const { userId } = currentProfile || {};
    return getTaskListSelectAction(appId, userId, projectId, inputValue, callback);
  };

  console.log("formfields:", formFields);

  return (
    <React.Fragment>
      <>
        <Container>
          <Card className="shadow-none">
            {props.errorMessage && (
              <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            {/* <CardBody className="p-4"> */}
            <table className="table table-striped table-hover w-100">
              <tbody>
                <Row>
                  <Col sm='4' md='4' lg="2">
                    <Button
                      type="button"
                      color='primary'
                      onClick={() => handleAdd()}
                    >
                      Add Task
                    </Button>
                  </Col>
                  <Col sm="4" md="8" lg="5" className='d-flex justify-content-end'>
                    <ComponentCard title="Input Addons Left Icon">
                      <Form>
                        <InputGroup>
                          <InputGroupText>Date</InputGroupText>
                          <Input
                            type='date'
                            value={currentDate ? moment(currentDate).format('YYYY-MM-DD') : 'N/A'}
                            // value={moment(currentDate)}
                            onChange={handleChangeDateSelect}
                            dateFormat="DD-MM-YYYY"
                            max={new Date().toISOString().split('T')[0]}
                            // timeFormat={false}
                            // inputProps={{ placeholder: 'Select Date' }}
                            // utc={true}
                            isValidDate={valid}
                          />
                        </InputGroup>
                      </Form>
                    </ComponentCard>
                  </Col>
                  <Col sm="4" md="12" lg="5">
                    <InputGroup className="d-flex justify-content-end">
                      <InputGroupText>
                        Total Logged Hours
                      </InputGroupText>
                      <Input
                        style={{
                          maxWidth: "80px"
                        }}
                        type="text"
                        value={props.LoggedHours && props.LoggedHours.total_LoggedHour}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </tbody>
            </table>
            {formFields && formFields.length > 0 ? (
              <Table responsive className="text-nowrap shadow-none">
                <thead>
                  <th className='text-center'>Project Name<span className="text-danger"> *</span></th>
                  <th className='text-center'> Task<span className="text-danger"> *</span></th>
                  <th className='text-center'> Scope<span className="text-danger"> *</span></th>
                  <th className='text-center' style={{ minWidth: "300px" }}> Time </th>
                </thead>
                <tbody>
                  {formFields.map((data, index) => {
                    // const taskLT = data.taskList
                    return (
                      <tr
                        key={data.TempId}
                      >
                        <td className='shadow-none'>
                          <Async
                            openMenuOnFocus={true}
                            autoFocus={true}
                            id="selectedProject"
                            name="selectedProject"
                            placeholder="Select project"
                            styles={customStyles}
                            isSearchable
                            defaultOptions
                            loadOptions={handleLoadProject}
                            onChange={e => handleChangeProjectSelect(e, index)}
                            menuPortalTarget={document.body}
                            value={data.ProjectValue}
                          />
                        </td>
                        <td>
                          <Async
                            key={keyChange[index]}
                            id="selectedTask"
                            name="selectedTask"
                            placeholder="Select task"
                            styles={customStyles}
                            isSearchable
                            defaultOptions
                            loadOptions={(inputValue, callback) => handleLoadTask(inputValue, data && data.projectId, callback)} // Pass projectId
                            onChange={e => handleChangeTaskSelect(e, index)}
                            value={data.taskValue}
                            menuPortalTarget={document.body}
                          />
                        </td>
                        <td>
                          <Async
                            id="scopeId"
                            name="scopeId"
                            styles={customStyles}
                            placeholder="Select scope"
                            isSearchable
                            defaultOptions
                            loadOptions={handleTaskScope}
                            onChange={e => handleChangeScopeSelect(e, index)}
                            menuPortalTarget={document.body}
                          // value={scopeType}
                          />
                        </td>
                        <td className='d-flex justify-content-between'>
                          <Form className='d-flex flex-row'>
                            <InputGroup>
                              <InputGroupText >
                                HH
                              </InputGroupText>
                              <Select
                                styles={customStylesTime}
                                name="hour"
                                options={hours}
                                placeholder="00"
                                // defaultValue={hour}
                                onChange={e => selectOnChangeHours(e, index)}
                                menuPortalTarget={document.body}
                              />
                            </InputGroup>
                          </Form>
                          <Form  >
                            <InputGroup >
                              <InputGroupText>
                                MM
                              </InputGroupText>
                              <div>
                                <Select
                                  styles={customStylesTime}
                                  name="minute"
                                  // defaultValue={data.projectName}
                                  placeholder="00"
                                  options={minutes}
                                  onChange={e => selectOnChangeMinutes(e, index)}
                                  menuPortalTarget={document.body}
                                />
                              </div>
                            </InputGroup>
                          </Form>
                          <Button type="submit" color="danger" size="sm"
                            onClick={() => handleDelete(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} color='danger' /></Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            ) : ""}
            {
              formFields && formFields.length > 0 ? (
                <div className="form-group pt-2">
                  <FormGroup>
                    <Button
                      className="me-2"
                      color="success"
                      onClick={handleCreateTimeSheet}
                      disabled={props.isPending}
                    >
                      {props.isPending ? <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        &nbsp; Submitting...
                      </>
                        : <>Submit</>}
                    </Button>
                    <Button
                      color="danger"
                      onClick={props.handleClose}
                    >
                      Cancel
                    </Button>
                  </FormGroup>
                </div>
              ) : ""
            }
          </Card>
        </Container>
      </>
    </React.Fragment >
  )
}

const mapStateToProps = (state) => ({
  userDetails: state.login.currentProfile,
  errorMessage: state.TimeSheets.errorMessage,
  isPending: state.TimeSheets.isPending,
  LoggedHours: state.TimeSheets.LoggedHours,
  currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
  createTimeSheetAction: (formFields, appId, filteredData) => dispatch(createTimeSheet(formFields, appId, filteredData)),
  getProjectAsyncSelectAction: (searchValue, callback) => dispatch(getProjectAsyncSelect(searchValue, callback)),
  getTaskListSelectAction: (appId, userid, ProjectID, searchValue, callback) => dispatch(getTaskListSelect(appId, userid, ProjectID, searchValue, callback)),
  getLoggedHours: (date) => dispatch(getLoggedHoursAction(date)),
  createTimeSheetSuccess: () => dispatch(createTimeSheetSuccess()),
  getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeAsyncSelectAction(appId, searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTimeSheets);