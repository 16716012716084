const LeaveTrackerSidebarDataUser = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/leavetracker/dashboard",
    },

    {
        label: "Leave Management",
        isMainMenu: true,
    },
    {
        label: "My Leaves",
        icon: "mdi mdi-notebook-plus-outline",
        isHasArrow: true,
        url: "/leavetracker/applyleave",
    },

    {
        label: "Holiday Master",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/holidaymaster",
    },
    {
        label: "Leave Tracker Report",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/report",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/leavetracker/documents",
    },
]


const LeaveTrackerSidebarDataAdmin = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/leavetracker/dashboard",
    },

    {
        label: "Leave Management",
        isMainMenu: true,
    },
    {
        label: "My Leaves",
        icon: "mdi mdi-notebook-plus-outline",
        isHasArrow: true,
        url: "/leavetracker/applyleave",
    },
    {
        label: "Leave Type",
        icon: "mdi mdi-dns-outline",
        isHasArrow: true,
        url: "/leavetracker/leavetype",
    },
    {
        label: "Custom Leave Master",
        icon: "mdi mdi-doctor",
        isHasArrow: true,
        url: "/leavetracker/leavemaster",
    },
    {
        label: "Holiday Master",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/holidaymaster",
    },
    {
        label: "Weekly Off",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/weeklyoffdays",
    },
    {
        label: "Leave Tracker Report",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/report",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/leavetracker/documents",
    },
]

const LeaveTrackerSidebarDataManager = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/leavetracker/dashboard",
    },

    {
        label: "Leave Management",
        isMainMenu: true,
    },
    {
        label: "My Leaves",
        icon: "mdi mdi-notebook-plus-outline",
        isHasArrow: true,
        url: "/leavetracker/applyleave",
    },
    {
        label: "My Team Leaves",
        icon: "mdi mdi-card-account-details",
        isHasArrow: true,
        url: "/leavetracker/myteamleaves",
        batch: {
            type: "danger"
        }
    },
    {
        label: "Leave Type",
        icon: "mdi mdi-dns-outline",
        isHasArrow: true,
        url: "/leavetracker/leavetype",
    },
    {
        label: "Custom Leave Master",
        icon: "mdi mdi-doctor",
        isHasArrow: true,
        url: "/leavetracker/leavemaster",
    },
    {
        label: "Holiday Master",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/holidaymaster",
    },
    {
        label: "Weekly Off",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/weeklyoffdays",
    },
    {
        label: "Leave Tracker Report",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/report",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/leavetracker/documents",
    },
]
const LeaveTrackerSidebarDataManagerUser = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/leavetracker/dashboard",
    },

    {
        label: "Leave Management",
        isMainMenu: true,
    },
    {
        label: "My Leaves",
        icon: "mdi mdi-notebook-plus-outline",
        isHasArrow: true,
        url: "/leavetracker/applyleave",
    },
    {
        label: "My Team Leaves",
        icon: "mdi mdi-card-account-details",
        isHasArrow: true,
        url: "/leavetracker/myteamleaves",
        batch: {
            type: "danger"
        }
    },
    // {
    //     label: "Leave Type",
    //     icon: "mdi mdi-dns-outline",
    //     isHasArrow: true,
    //     url: "/leavetracker/leavetype",
    // },
    // {
    //     label: "Custom Leave Master",
    //     icon: "mdi mdi-doctor",
    //     isHasArrow: true,
    //     url: "/leavetracker/leavemaster",
    // },
    {
        label: "Holiday Master",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/holidaymaster",
    },
    // {
    //     label: "Weekly Off",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/leavetracker/weeklyoffdays",
    // },
    {
        label: "Leave Tracker Report",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/leavetracker/report",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/leavetracker/documents",
    },
]
export { LeaveTrackerSidebarDataUser, LeaveTrackerSidebarDataAdmin, LeaveTrackerSidebarDataManager, LeaveTrackerSidebarDataManagerUser };