import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { clearForm, updateForm } from '../../../../store/form/actions';
import { createAppMaster, createAppMasterSuccess } from '../../../../store/Modules/Admin/AppMaster/actions';
import VWButton from '../../../../components/vwButton/VWButton';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import VWMessage from '../../../../components/vwMessage/vwMessage';

const AppMasterCreate = (props) => {
    const { data, filteredData } = props;
    console.log("data:", data);

    const [formFields, setFormFields] = useState({});
    const [isActive, setisActive] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        props.createAppMasterSuccess();
    }, [])
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'AppName') {
            setErrors({ ...errors, AppName: null });
        }
        if (input.name === 'LatestVersion') {
            setErrors({ ...errors, LatestVersion: null });
        }
    };
    //--------------------------------------------Create ---------------------------------------------------------
    const handleCreateAppMaster = () => {
        const newErrors = {};
        if (!(formFields && formFields.AppName) || !(formFields.AppName && formFields.AppName.trim())) {
            newErrors.AppName = 'App name is required';
        }
        if (!(formFields && formFields.LatestVersion) || !(formFields.LatestVersion && formFields.LatestVersion.trim())) {
            newErrors.LatestVersion = 'Latest version is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.createAppMasterAction(formFields, data, filteredData);
        }
    };
    //-------------------------------------Status Change----------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, isActive: !isActive })
    };

    console.log("formfields", formFields);
    console.log("filteredData", filteredData);

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <form className="mb-3" name="formAddCustomer">
                <FormGroup>
                    <Label>
                        App Name <span className="text-danger"> *</span>
                    </Label>
                    <Input
                        autoFocus={true}
                        type="text"
                        name="AppName"
                        placeholder="Enter app name"
                        onChange={validateOnChange}
                        value={formFields && formFields.AppName}
                        invalid={errors && errors.AppName}
                    />
                    {<FormFeedback>{errors && errors.AppName}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>
                        Latest Version <span className="text-danger"> *</span>
                    </Label>
                    <Input
                        type="text"
                        name="LatestVersion"
                        placeholder="Enter latest version"
                        onChange={validateOnChange}
                        value={formFields && formFields.LatestVersion}
                        invalid={errors && errors.LatestVersion}
                    />
                    {<FormFeedback>{errors && errors.LatestVersion}</FormFeedback>}
                </FormGroup>
                <FormGroup>
                    <Label>Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!isActive ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${isActive ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
            </form>
            <br></br>
            <div className="form-group">
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateAppMaster}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isPending: state.appMaster.isPending,
    errorMessage: state.appMaster.errorMessage,
});
const mapDispatchToProps = (dispatch) => ({
    updateFormAction: (data) => dispatch(updateForm(data)),
    createAppMasterSuccess: () => dispatch(createAppMasterSuccess()),
    clearFormAction: () => dispatch(clearForm()),
    createAppMasterAction: (formFields, data, filteredData) => dispatch(createAppMaster(formFields, data, filteredData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppMasterCreate)