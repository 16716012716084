import { CLEAR_TIMESHEET_APPROVAL_ACTION_ERROR, CLEAR_TIMESHEET_APPROVAL_EXPORT_LIST_ERROR, CLEAR_TIMESHEET_APPROVAL_LIST_ERROR, CLOSE_ACTION_TIMESHEET_APPROVED_MODAL, OPEN_ACTION_TIMESHEET_APPROVED_MODAL, SET_TIMESHEET_APPROVAL_COUNT, SET_TIMESHEET_APPROVAL_EXPORT_LIST, SET_TIMESHEET_APPROVAL_LIST, TIMESHEET_APPROVAL_EXPORT_LIST_ERROR, TIMESHEET_APPROVAL_EXPORT_LIST_PENDING, TIMESHEET_APPROVAL_LIST_ERROR, TIMESHEET_APPROVAL_LIST_PENDING } from "./constants";

const initialState = {
    errorMessage: null,
    // isActionTimeSheetModalOpen: false,
    TimeSheetApprovalList: [],
    totalRecords: 0,
    isTimesheetApprovalPending: false,
    TimesheetApprovalErrorMessage: null,
    isActionTimeSheetApprovedModalOpen: false,
    timesheetApprovalTotalRecords: 0,
    isTimesheetApprovalExportPending: false,
    TimeSheetApprovalExportList: [],
    TimesheetApprovalExportErrorMessage: null,


};

export default (localState = initialState, action) => {
    switch (action.type) {
        case OPEN_ACTION_TIMESHEET_APPROVED_MODAL: {
            return {
                ...localState,
                isActionTimeSheetApprovedModalOpen: true,
            };
        }

        case CLOSE_ACTION_TIMESHEET_APPROVED_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isActionTimeSheetApprovedModalOpen: false,
            };
        }

        case SET_TIMESHEET_APPROVAL_LIST: {
            const { TimeSheetApprovalLists } = action.payload;
            return {
                ...localState,
                isTimesheetApprovalPending: false,
                TimeSheetApprovalList: TimeSheetApprovalLists && TimeSheetApprovalLists.value,
                totalRecords: TimeSheetApprovalLists["@odata.count"],
            }
        }
        case SET_TIMESHEET_APPROVAL_EXPORT_LIST: {
            const { TimeSheetApprovalLists } = action.payload;
            return {
                ...localState,
                isTimesheetApprovalExportPending: false,
                TimeSheetApprovalExportList: TimeSheetApprovalLists,
            }
        }
        case TIMESHEET_APPROVAL_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                isTimesheetApprovalExportPending: true,
            }
        }
        case SET_TIMESHEET_APPROVAL_COUNT: {
            const { TimeSheetApprovalCount } = action.payload;
            return {
                ...localState,
                timesheetApprovalTotalRecords: TimeSheetApprovalCount["@odata.count"],
            }
        }
        case TIMESHEET_APPROVAL_LIST_ERROR: {
            const { TimesheetApprovalErrorMessage } = action.payload;
            return {
                ...localState,
                isMyTeamTimesheetPending: false,
                MyTeamTimeSheetList: [],
                TimesheetApprovalErrorMessage: TimesheetApprovalErrorMessage,
            };
        }
        case TIMESHEET_APPROVAL_EXPORT_LIST_ERROR: {
            const { TimesheetApprovalErrorMessage } = action.payload;
            return {
                ...localState,
                isTimesheetApprovalExportPending: false,
                TimeSheetApprovalExportList: [],
                TimesheetApprovalExportErrorMessage: TimesheetApprovalErrorMessage,
            };
        }
        case CLEAR_TIMESHEET_APPROVAL_LIST_ERROR: {
            return {
                ...localState,
                TimesheetApprovalErrorMessage: null,
            };
        }
        case CLEAR_TIMESHEET_APPROVAL_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                TimesheetApprovalExportErrorMessage: null,
            };
        }

        case TIMESHEET_APPROVAL_LIST_PENDING: {
            return {
                ...localState,
                TimesheetApprovalErrorMessage: null,
                isTimesheetApprovalPending: true,
                // totalRecords: 0
            }
        }
        case CLEAR_TIMESHEET_APPROVAL_ACTION_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        default: {
            return localState;
        }
    }
};