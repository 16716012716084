import { CLEAR_CREATED_MIESTONE_NAME, CLEAR_MILESTONE_DETAILS, CLEAR_MILESTONE_DETAILS_ERROR, CLEAR_MILESTONE_ERROR, CLEAR_MILESTONE_EXPORT_LIST_ERROR, CLEAR_MILESTONE_LIST, CLEAR_MILESTONE_LIST_ERROR, CLEAR_MILESTONE_LISTS, CLEAR_MILESTONE_PROJECT_DATE, CLEAR_MILESTONE_PROJECT_DATES_ERROR, CLEAR_PROJECT_MILESTONE_LIST_ERROR, CLEAR_STATUS_UPDATE_MILESTONE_ERROR, CLEAR_UPDATE_MILESTONE_ERROR, CLOSE_CLONE_MILESTONE_MODAL, CLOSE_CREATE_MILESTONE_MODAL, CLOSE_EDIT_MILESTONE_MODAL, CREATE_MILESTONE_ERROR, CREATE_MILESTONE_PENDING, CREATE_MILESTONE_SUCCESS, CREATED_MILESTONE_NAME, IS_STATUS_MILESTONE_EDIT, IS_STATUS_MILESTONE_EDIT_OPEN, IS_STATUS_TASK_EDIT_OPEN, MILESTONE_EXPORT_LIST_ERROR, MILESTONE_EXPORT_SUCCESS, MILESTONE_LIST_ERROR, OPEN_CLONE_MILESTONE_MODAL, OPEN_CREATE_MILESTONE_MODAL, OPEN_EDIT_MILESTONE_MODAL, PROJECT_MILESTONE_LIST_ERROR, SET_MILESTONE_DETAILS, SET_MILESTONE_DETAILS_ERROR, SET_MILESTONE_DETAILS_PENDING, SET_MILESTONE_EXPORT_LIST, SET_MILESTONE_EXPORT_PENDING, SET_MILESTONE_LIST, SET_MILESTONE_PENDING, SET_MILESTONE_PROJECT_DATE_ERROR, SET_MILESTONE_PROJECT_DATES, SET_MILESTONE_PROJECT_DATES_PENDING, SET_PROJECT_MILESTONE_LIST, SET_PROJECT_MILESTONE_PENDING, UPDATE_MILESTONE_ERROR, UPDATE_MILESTONE_PENDING, UPDATE_MILESTONE_SUCCESS, UPDATE_STATUS_MILESTONE_ERROR, UPDATE_STATUS_MILESTONE_PENDING, UPDATE_STATUS_MILESTONE_SUCCESS } from "./constants";

const initialState = {
    isCreateMilestoneModalOpen: false,
    isEditMilestoneModalOpen: false,
    errorMessage: null,
    isPending: false,
    isMilestonePending: false,
    milestoneListError: null,
    MilestoneLists: [],
    totalRecords: 0,
    milestoneDetailsError: null,
    MilestoneDetails: null,
    updateMilestoneErrorMessage: null,
    isUpdateMilestonePending: false,
    isMilestoneDetailsPending: false,
    ProjectMilestoneLists: [],
    ProjectMilestoneListError: null,
    isProjectMilestonePending: false,
    MilestonetotalRecords: 0,
    MilestoneProjectDates: null,
    isMilestoneProjectDatesPending: false,
    milestoneProjectDatesError: null,
    milestoneExportListError: null,
    isMilestoneExportPending: false,
    MilestoneExportLists: [],
    isCloneMilestoneModalOpen: false,
    isStatusMilestoneEdit: false,
    updateStatusMilestoneErrorMessage: null,
    isUpdateStatusMilestonePending: false,
    isUpdateStatusMilestonePending: false,
    isStatusMilestoneEditID: null,
    CreatedMilestoneName: null

}

export default (localState = initialState, action) => {
    switch (action.type) {

        case CREATED_MILESTONE_NAME: {
            const { createdMilestoneName } = action.payload;
            return {
                ...localState,
                CreatedMilestoneName: createdMilestoneName,
            };
        }
        case CLEAR_CREATED_MIESTONE_NAME: {
            return {
                ...localState,
                CreatedMilestoneName: null,
            };
        }
        case IS_STATUS_MILESTONE_EDIT_OPEN: {
            const { id } = action.payload;
            return {
                ...localState,
                isStatusMilestoneEditID: id,
            };
        }
        case OPEN_CREATE_MILESTONE_MODAL: {
            return {
                ...localState,
                isCreateMilestoneModalOpen: true,
            };
        }
        case OPEN_EDIT_MILESTONE_MODAL: {
            return {
                ...localState,
                isEditMilestoneModalOpen: true,
            };
        }
        case OPEN_CLONE_MILESTONE_MODAL: {
            return {
                ...localState,
                isCloneMilestoneModalOpen: true,
            };
        }
        case CLOSE_CLONE_MILESTONE_MODAL: {
            return {
                ...localState,
                isCloneMilestoneModalOpen: false,
            };
        }
        case CLOSE_CREATE_MILESTONE_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateMilestoneModalOpen: false,
            };
        }
        case CLOSE_EDIT_MILESTONE_MODAL: {
            return {
                ...localState,
                updateMilestoneErrorMessage: null,
                isEditMilestoneModalOpen: false,
            };
        }
        case CREATE_MILESTONE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_MILESTONE_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CREATE_MILESTONE_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_MILESTONE_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case SET_MILESTONE_PENDING: {
            return {
                ...localState,
                milestoneListError: null,
                isMilestonePending: true,
                // totalRecords: 0
            }
        }
        case SET_MILESTONE_EXPORT_PENDING: {
            return {
                ...localState,
                milestoneExportListError: null,
                isMilestoneExportPending: true
            }
        }
        case SET_PROJECT_MILESTONE_PENDING: {
            return {
                ...localState,
                ProjectMilestoneListError: null,
                isProjectMilestonePending: true
            }
        }
        case SET_MILESTONE_DETAILS_PENDING: {
            return {
                ...localState,
                milestoneDetailsError: null,
                isMilestoneDetailsPending: true
            }
        }
        case SET_MILESTONE_LIST: {
            const { milestoneList } = action.payload;
            return {
                ...localState,
                isMilestonePending: false,
                MilestoneLists: milestoneList && milestoneList.value,
                totalRecords: milestoneList["@odata.count"],
            }
        }
        case CLEAR_MILESTONE_LISTS: {
            return {
                ...localState,
                isMilestonePending: false,
                MilestoneLists: [],
                totalRecords: 0,
            }
        }
        case MILESTONE_EXPORT_SUCCESS: {
            return {
                ...localState,
                isMilestoneExportPending: false,
            }
        }
        case SET_PROJECT_MILESTONE_LIST: {
            const { milestoneList } = action.payload;
            return {
                ...localState,
                isProjectMilestonePending: false,
                ProjectMilestoneLists: milestoneList && milestoneList.value,
                MilestonetotalRecords: milestoneList["@odata.count"],
            }
        }
        case MILESTONE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isMilestonePending: false,
                milestoneListError: errorMessage,
            };
        }
        case MILESTONE_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isMilestoneExportPending: false,
                milestoneExportListError: errorMessage,
            };
        }
        case PROJECT_MILESTONE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isProjectMilestonePending: false,
                ProjectMilestoneListError: errorMessage,
                ProjectMilestoneLists: [],
                MilestonetotalRecords: 0,
            };
        }
        case CLEAR_MILESTONE_LIST_ERROR: {
            return {
                ...localState,
                milestoneListError: null,
            };
        }
        case CLEAR_MILESTONE_LIST: {
            return {
                ...localState,
                milestoneListError: null,
                ProjectMilestoneLists: [],
                MilestonetotalRecords: 0,
            };
        }
        case CLEAR_MILESTONE_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                milestoneExportListError: null,
            };
        }
        case CLEAR_PROJECT_MILESTONE_LIST_ERROR: {
            return {
                ...localState,
                projectMilestoneListError: null,
            };
        }
        case SET_MILESTONE_DETAILS: {
            const { milestoneDetails } = action.payload;
            return {
                ...localState,
                isMilestoneDetailsPending: false,
                MilestoneDetails: milestoneDetails,
            }
        }
        case CLEAR_MILESTONE_DETAILS: {
            return {
                ...localState,
                MilestoneDetails: null,
            };
        }
        case SET_MILESTONE_PROJECT_DATES: {
            const { ProjectDates } = action.payload;
            return {
                ...localState,
                isMilestoneProjectDatesPending: false,
                MilestoneProjectDates: ProjectDates,
            }
        }
        case SET_MILESTONE_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                MilestoneDetails: {},
                isMilestoneDetailsPending: false,
                milestoneDetailsError: errorMessage,
            };
        }
        case SET_MILESTONE_PROJECT_DATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                MilestoneProjectDates: null,
                isMilestoneProjectDatesPending: false,
                milestoneProjectDatesError: errorMessage,
            };
        }
        case CLEAR_MILESTONE_PROJECT_DATE: {
            return {
                ...localState,
                MilestoneProjectDates: null,
            };
        }
        case CLEAR_MILESTONE_DETAILS_ERROR: {
            return {
                ...localState,
                milestoneDetailsError: null,
            };
        }
        case CLEAR_MILESTONE_PROJECT_DATES_ERROR: {
            return {
                ...localState,
                milestoneProjectDatesError: null,
            };
        }
        case CLEAR_UPDATE_MILESTONE_ERROR: {
            return {
                ...localState,
                updateMilestoneErrorMessage: null,
                isUpdateMilestonePending: false,
            };
        }
        case CLEAR_STATUS_UPDATE_MILESTONE_ERROR: {
            return {
                ...localState,
                updateStatusMilestoneErrorMessage: null,
                isUpdateStatusMilestonePending: false,
            };
        }
        case UPDATE_MILESTONE_PENDING: {
            return {
                ...localState,
                isUpdateMilestonePending: true,
            };
        }
        case UPDATE_STATUS_MILESTONE_PENDING: {
            return {
                ...localState,
                isUpdateStatusMilestonePending: true,
            };
        }
        case SET_MILESTONE_PROJECT_DATES_PENDING: {
            return {
                ...localState,
                isMilestoneProjectDatesPending: true,
            };
        }
        case UPDATE_MILESTONE_SUCCESS: {
            return {
                ...localState,
                isUpdateMilestonePending: false,
            };
        }
        case UPDATE_STATUS_MILESTONE_SUCCESS: {
            return {
                ...localState,
                isUpdateStatusMilestonePending: false,
            };
        }
        case UPDATE_MILESTONE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateMilestoneErrorMessage: errorMessage,
                isUpdateMilestonePending: false,
            };
        }
        case UPDATE_STATUS_MILESTONE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateStatusMilestoneErrorMessage: errorMessage,
                isUpdateStatusMilestonePending: false,
            };
        }

        default: {
            return localState;
        }
    }
}