import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const VWModel = ({ header, children, isOpen, showFooter, handleClose, modalSize, className }) => {
  const [sizeClassName, setSizeClassName] = useState('');

  useEffect(() => {
    const baseClassName = 'modal';
    switch (modalSize) {
      case 'extra-small':
        setSizeClassName(` ${baseClassName}-xs`);
        break;
      case 'small':
        setSizeClassName(` ${baseClassName}-sm`);
        break;
      case 'medium':
        setSizeClassName(` ${baseClassName}-md`);
        break;
      case 'large':
        setSizeClassName(` ${baseClassName}-lg`);
        break;
      case 'extra-large':
        setSizeClassName(` ${baseClassName}-xl`);
        break;
      case 'xxl':
        setSizeClassName(` ${baseClassName}-xxl`);
        break;
      default:
        setSizeClassName(` ${baseClassName}-custom-width`);
    }
  }, [modalSize]);

  const renderClassName = (modalSize, className) => {
    console.log('className:', className);

    if (className) {
      return `${sizeClassName} ${className}`;
    }
    return `${sizeClassName}`;
  };
  console.log('sizeClassName:', sizeClassName);

  return (
    <Modal isOpen={isOpen} className={renderClassName(modalSize, className)} autoFocus={false}>
      <ModalHeader toggle={handleClose}><strong>{header}</strong></ModalHeader>
      <ModalBody className='px-4 ps-3'>{children}</ModalBody>
      {showFooter && <ModalFooter></ModalFooter>}
    </Modal>
  );
}

export default VWModel;