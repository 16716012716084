import React from 'react'
import Avatar from 'react-avatar';
import Rating from 'react-rating-tooltip';
import { ElementToolTip } from '../../Projects/vwToolTip';
import './UserCard.css'
import moment from 'moment-timezone';

const UserCard = ({ user, filter }) => {
    const { StartDate, EndDate } = filter;

    return (
        <>
            <div className="user-card-container">
                <div className="user-card">
                    <div className="usercard-header">
                        <Avatar src={user.ProfilePicture || null} name={user.ProfilePicture ? null : user.UserName} round size="40" className="user-avatar" />
                        <div className="user-info">
                            <h5 ><span style={{ cursor: 'pointer' }} id={`username-${user.Id}`}>{user.UserName || "N/A"}</span> <ElementToolTip id={`username-${user.Id}`} name={`Username: ${user.UserName}`} /></h5>
                            <p ><span style={{ cursor: 'pointer' }} id={`useremail-${user.Id}`}>{user.UserEmail || "N/A"}</span><ElementToolTip id={`useremail-${user.Id}`} name={`User Email: ${user.UserEmail}`} /></p>
                        </div>
                    </div>
                    {StartDate && EndDate ?
                        <div className="date-range">
                            <span role="img" aria-label="calendar">📅</span> <span id={`projectdate-${user.Id}`}>{StartDate ? moment(StartDate).format('DD MMM YYYY') : 'N/A'} - {EndDate ? moment(EndDate).format('DD MMM YYYY') : 'N/A'}</span>
                            <ElementToolTip id={`projectdate-${user.Id}`} name={`Project Duration`} />
                        </div>
                        : ''}
                    <div className="date-range">
                        <div >
                            <span role="img" aria-label="clock"><strong>🕒 Project Hours : </strong>{user.TotalHrs || 0} hrs</span>
                        </div>
                        <div >
                            <span role="img" aria-label="clock"><strong>🕒 Logged Hours : </strong>{user.CustomDatesHrs || 0} hrs</span>
                        </div>
                    </div>
                    {/* <div className="timesheet-user-summary mt-2">
                        <button className="total-Hrs" style={{ cursor: 'default' }}>
                            <span className=''>{user.TotalHrs || 0}</span>
                            Total Hrs
                        </button>
                        <button className="current-month" style={{ cursor: 'default' }}>
                            <span>{user.CurrentMonthHrs || 0}</span>
                            Current Month Hrs
                        </button>
                    </div>
                    <div className="timesheet-user-summary mt-2">
                        <button className="current-week" style={{ cursor: 'default' }}>
                            <span>{user.CurrentWeekHrs || 0}</span>
                            Current Week Hrs
                        </button>
                        <button className="custom-date" style={{ cursor: 'default' }}>
                            <span>{user.CustomDatesHrs || 0}</span>
                            Custom Dates Hrs
                        </button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default UserCard