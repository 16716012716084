import React, { useEffect, useLayoutEffect, useState } from 'react';
import './Kanban.css';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Input,
    Label,
    Toast,
    ToastBody,
    ToastHeader,
    Tooltip,
    UncontrolledAlert
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    CloseCreateTaskModalAction,
    CloseEditKanbanTaskModalAction,
    CloseEditTaskModalAction,
    UpdateKanbanTasks,
    getAllTaskStatusPercentage,
    getKanbanTaskList,
    getTaskDetails,
    getTaskStatusAsyncSelectTimesheet,
    openCreateTaskModalAction,
    openEditTaskModalAction
} from '../../../store/Modules/ProjectPortal/Task/actions';
import VWModel from '../../../components/Modal/VWModal';
import CreateTask from './Task/CreateTask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faX } from '@fortawesome/free-solid-svg-icons';
import UpdateTask from './Task/UpdateTask';
import VWMessage from '../../../components/vwMessage/vwMessage';
import { getAdminProjectNameAsyncSelectAction, getProjectNameAsync, getProjectNameAsyncSelect } from '../../../store/Modules/ProjectPortal/Milestones/actions';
import Async from 'react-select/async';
import moment from 'moment-timezone';
import Avatar from 'react-avatar';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { clearSetLoginPath, clearSetPath } from '../../../store/actions';
import VWSpinner from '../../../components/vwSpinner/vwSpinner';
import { ElementToolTip } from './vwToolTip';
import { getManagerUserNameAsyncSelectAction } from '../../../store/Modules/ProjectPortal/Projects/actions';


const KanbanBoard = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState({});
    const [hideColumn, setHideColumn] = useState(false);
    const { isGlobalReadOnlyUser } = props.currentProfile || {};
    const toggleTooltip = (itemId) => {
        setTooltipOpen({
            ...tooltipOpen,
            [itemId]: !tooltipOpen[itemId]
        });
    };

    const [tasks, setTasks] = useState([props.taskList]);
    const [assignedStatus, setAssignedStatus] = useState();
    const [existingProjectUser, setExistingProjectUser] = useState([]);
    const [existingUsers, setExistingUsers] = useState([]);
    const [filterProjects, setFilterProjects] = useState([]);
    const [filterUsers, setFilterUsers] = useState([]);
    const [formFields, setFormFields] = useState({ EndDate: '', StartDate: '' });
    console.log("filterProjects:", filterProjects);

    const { userAppDetails } = props && props.currentProfile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    useEffect(() => {
        const eDate = moment().format("YYYY-MM-DD");
        const sDate = moment().subtract(14, 'd').format('YYYY-MM-DD');
        setFormFields({
            EndDate: eDate,
            StartDate: sDate,
        })
    }, [])

    const handleDragStart = (e, Id, TaskStatus) => {
        e.dataTransfer.setData('taskId', Id);
        e.dataTransfer.setData('status', TaskStatus);
        console.log("status:", TaskStatus);
        console.log("taskId:", Id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, newStatus, Percentage) => {
        console.log('Percentage:', Percentage);
        console.log("newStatus: ", newStatus);
        const taskId = e.dataTransfer.getData('taskId');
        console.log("taskId:", taskId);
        const prevStatus = e.dataTransfer.getData('status');
        const percentage = e.dataTransfer.getData('Percentage');

        if (prevStatus !== newStatus) {
            const updatedTasks = tasks.map(task => {
                if (task.Id === taskId) {
                    console.log("specific task:", task);
                    props.UpdateTaskAction(App[0] && App[0].appId,
                        {
                            ...task,
                            TaskStatus: newStatus,
                            CurrentStatusPercentage: Percentage,
                            userId: task.UserDetails && task.UserDetails.AssignedUserId, id: task.Id,
                        },
                        filterProjects, filterUsers, formFields.StartDate, formFields.EndDate, formFields.TaskName);
                    return { ...task, TaskStatus: newStatus };
                } else {
                    return task;
                }
            });
            setTasks(updatedTasks);
        }
    };

    const handleOpenCreateTask = (status) => {
        props.openCreateTaskModal();
        setAssignedStatus(status);
    };
    const getTaskDetail = async (TaskId) => {

        try {
            await props.getTaskDetails(TaskId);
            // props.openEditTaskModal();

            window.open(`/projectportal/tasks/details/${TaskId}`, '_blank');
        } catch (error) {
            console.error("Error fetching task details:", error);
        }
    }
    // const OnDoubleClickAction = async (TaskId) => {

    //     try {
    //         await props.getTaskDetails(TaskId);
    //         props.openEditTaskModal();

    //         // window.open(`/projectportal/tasks/details/${TaskId}`, '_blank');
    //     } catch (error) {
    //         console.error("Error fetching task details:", error);
    //     }
    // }

    let clickTimeout = null;
    let isDoubleClick = false;

    const handleClick = async (TaskId) => {
        // Prevent double-click from triggering single-click logic
        clickTimeout = setTimeout(async () => {
            if (!isDoubleClick) {
                try {
                    await props.getTaskDetails(TaskId);
                    window.open(`/projectportal/tasks/details/${TaskId}`, '_blank');
                } catch (error) {
                    console.error("Error fetching task details:", error);
                }
            }
            isDoubleClick = false; // Reset flag
        }, 500); // Delay to differentiate between single and double click
    };

    const OnDoubleClickAction = async (TaskId) => {
        // Clear single-click timeout to avoid triggering
        clearTimeout(clickTimeout);
        isDoubleClick = true; // Set flag to indicate a double-click

        try {
            await props.getTaskDetails(TaskId);
            props.openEditTaskModal();
        } catch (error) {
            console.error("Error fetching task details:", error);
        }
    };

    const handleClose = () => {
        props.closeCreateTaskModal();
    };

    console.log("tasks: ", tasks);

    useEffect(() => {
        setTasks(props.taskList);
        console.log("Every update list showed");
    }, [props.taskList])

    useEffect(() => {
        console.log("get Kanban Task List:");
        props.closeKanbanToast();
        // props.getKanbanTaskListAction(filterProjects, filterUsers);

        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
        }
    }, [])

    //---------------------------------------- Modal Open & Close----------------------------------------------------

    const handleCloseEditTaskModal = () => {
        props.closeEditTaskModal();
    };

    const handleCloseToast = () => {
        props.closeKanbanToast();
        setFiterErrorMessage('');
    }
    //--------------------------------------- Default Options ---------------------------------------------------------
    const handleProject = (inputValue, callback) => {
        const { getAdminProjectNameAsyncSelectAction } = props;
        return getAdminProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleUsers = (inputValue, callback) => {
        const { getUserNameAsyncSelect } = props;
        return getUserNameAsyncSelect(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    //----------------------------------------Add Project Name-------------------------------------------------------
    const MAX_FILTER_LIMIT = 20;
    const [filterErrorMessage, setFiterErrorMessage] = useState('');

    const handleAddProjectName = (value) => {
        if (value.length > MAX_FILTER_LIMIT) {
            setFiterErrorMessage(`You can only select a maximum of ${MAX_FILTER_LIMIT} projects.`);
            return;
        }
        setFiterErrorMessage('');
        setExistingProjectUser(value);
        const selectedProjectIds = value ? value.map(option => option.value) : [];
        console.table('selectedProjectsIds:', selectedProjectIds);
        props.getKanbanTaskListAction(App[0] && App[0].appId, selectedProjectIds, filterUsers || [], formFields.StartDate, formFields.EndDate, formFields.TaskName);
        setFilterProjects(selectedProjectIds);
    }
    //-------------------------------------Add Users List-----------------------------------------------------------
    const handleAddUsersName = (options) => {
        if (options.length > MAX_FILTER_LIMIT) {
            setFiterErrorMessage(`You can only select a maximum of ${MAX_FILTER_LIMIT} users.`);
            return;
        }
        setFiterErrorMessage('');
        console.log("options", options);
        setExistingUsers(options);
        const selectedUsersIds = options ? options.map(option => option.value) : [];
        console.table('selectedUsersIds:', selectedUsersIds);
        props.getKanbanTaskListAction(App[0] && App[0].appId, filterProjects || [], selectedUsersIds, formFields.StartDate, formFields.EndDate, formFields.TaskName);
        setFilterUsers(selectedUsersIds);
    }

    const handleCheckbox = (e) => {
        const isChecked = e.target.checked;
        setHideColumn(isChecked);
    };

    // const FilterProjectIds = existingProjectUser && existingProjectUser.map((i) => i.value);
    // const FilterProjectName = existingProjectUser && existingProjectUser.map((i) => i.label);
    const FilterProjectIds = existingProjectUser && existingProjectUser.length > 1 ? null : existingProjectUser[0]?.value;
    const FilterProjectName = existingProjectUser && existingProjectUser.length > 1 ? null : existingProjectUser[0]?.label;

    // const FilterUserIds = existingUsers && existingUsers.map((i) => i.value);
    const FilterUserIds = existingUsers && existingUsers.length > 1 ? null : existingUsers[0]?.value;
    const FilterUserName = existingUsers && existingUsers.length > 1 ? null : existingUsers[0]?.label;

    const [tasksCountTooltipOpen, setTasksCountTooltipOpen] = useState(false);
    const toggleTaksCountTooltipOpen = () => setTasksCountTooltipOpen(!tasksCountTooltipOpen);

    // const searchInputValidation = (event) => {
    //     const { value, name } = event.target;
    //     setFormFields({
    //         ...formFields,
    //         [name]: value,
    //     })
    // }
    const [searchTimeout, setSearchTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;

        setFormFields({
            ...formFields,
            [name]: value,
        });
        if (name === 'TaskName') {
            console.log('task-value:', value);

            // Clear the previous timeout
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }

            // Set a new timeout    
            const newTimeout = setTimeout(() => {
                props.getKanbanTaskListAction(
                    App[0] && App[0].appId,
                    filterProjects,
                    filterUsers,
                    formFields.StartDate,
                    formFields.EndDate,
                    value
                );
            }, 500); // Adjust the debounce delay as needed (500ms here)

            setSearchTimeout(newTimeout);
        }
    };

    //--------------------------------------------filter in api --------------------------------------------------------------------
    useEffect(() => {
        if (formFields.StartDate || formFields.EndDate) {
            // let filterString = '';
            // if (filterProjects && filterProjects.length > 0) {
            //     filterString = filterProjects.map(id => `ProjectId eq '${id}'`).join(' or ');
            // }

            // if (filterUsers && filterUsers.length > 0) {
            //     const userFilter = filterUsers.map(id => `AssignedUserId eq '${id}'`).join(' or ');
            //     if (filterString) {
            //         filterString = `(${filterString}) and (${userFilter})`;
            //     } else {
            //         filterString = userFilter;
            //     }
            // }

            // if (formFields.StartDate && formFields.EndDate) {
            //     const startDateFilter = `StartDate ge ${encodeURIComponent(formFields.StartDate)} and StartDate le ${encodeURIComponent(formFields.EndDate)}`;
            //     const endDateFilter = `EndDate ge ${encodeURIComponent(formFields.StartDate)} and EndDate le ${encodeURIComponent(formFields.EndDate)}`;
            //     filterString = `${filterString ? filterString + ' and ' : ''}(${startDateFilter}) and (${endDateFilter})`;
            // } else if (formFields.StartDate) {
            //     const startDateFilter = `StartDate ge ${encodeURIComponent(formFields.StartDate)}`;
            //     filterString = `${filterString ? filterString + ' and ' : ''}${startDateFilter}`;
            // } else if (formFields.EndDate) {
            //     const endDateFilter = `EndDate le ${encodeURIComponent(formFields.EndDate)}`;
            //     filterString = `${filterString ? filterString + ' and ' : ''}${endDateFilter}`;
            // }
            // if (filterString) {
            props.getKanbanTaskListAction(App[0] && App[0].appId, filterProjects, filterUsers, formFields.StartDate, formFields.EndDate, formFields.TaskName);
            // }
        }
        else if (formFields.StartDate === '' && formFields.EndDate === '') {
            props.getKanbanTaskListAction(App[0] && App[0].appId, filterProjects, filterUsers, formFields.StartDate, formFields.EndDate, formFields.TaskName);
        }
    }, [formFields.StartDate, formFields.EndDate]);
    //-------------------------------------------------------------------------------------------------------------------------------
    const [statusData, setStatusData] = useState({});
    useEffect(() => {
        props.getTaskStatusAsyncSelectAction(App[0] && App[0].appId).then(data => {
            console.log('data:', data);
            const statusObject = data.reduce((acc, status) => {
                acc[status.value] = status.Percentage;
                return acc;
            }, {});

            setStatusData(statusObject);
        })
    }, []);
    console.log('statusData:', statusData);
    console.log('statusData && statusData[Internal Testing]', statusData && statusData["Internal Testing"]);
    console.log('existingProjectUser:', existingProjectUser);
    console.log('FilterProjectIds:', FilterProjectIds);
    console.log('FilterProjectName:', FilterProjectName);
    console.log('form:', formFields);

    return (
        <div className='page-content'>
            <Card style={{ zIndex: '10' }} className="my-2">
                <CardTitle
                    tag="h4"
                    className="border-bottom px-4 py-2 mb-0 d-flex justify-content-between align-items-center"
                >
                    <strong className='d-flex align-items-center'>KANBAN BOARD
                        -&nbsp;<Badge color="danger" id='tasksCount' style={{ cursor: 'pointer' }}>{props && props.totalCount || 0}</Badge>
                        <Tooltip
                            isOpen={tasksCountTooltipOpen}
                            target="tasksCount"
                            toggle={toggleTaksCountTooltipOpen}
                        >
                            Tasks Count
                        </Tooltip>
                    </strong> &nbsp;&nbsp;
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <Input
                                id="hideCol"
                                name="hideColumn"
                                type="checkbox"
                                onChange={(e) => handleCheckbox(e)}
                                checked={hideColumn}
                                className="me-2"
                            />
                            <Label htmlFor="hideCol" style={{ fontSize: "14px" }} className='pt-2 mt-1'>
                                Hide Columns With No Tasks
                            </Label>
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    minWidth: "250px",
                                    maxWidth: "250px",
                                    minHeight: "40px",
                                    marginRight: '10px',
                                }), menu: (provided) => ({
                                    ...provided,
                                    zIndex: 5, // Ensure dropdown doesn't hide due to z-index
                                }),
                            }}
                            isClearable
                            name="ProjectId"
                            placeholder="Select Projects"
                            className="vm-react-select"
                            isSearchable
                            isMulti
                            defaultOptions
                            loadOptions={handleProject}
                            onChange={handleAddProjectName}
                            value={existingProjectUser}
                        />
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    minWidth: "250px",
                                    maxWidth: "250px",
                                    minHeight: "40px"
                                }),
                            }}
                            isClearable
                            name="AssignedUserId"
                            placeholder="Select users"
                            className="vm-react-select"
                            isSearchable
                            isMulti
                            defaultOptions
                            loadOptions={handleUsers}
                            onChange={handleAddUsersName}
                            value={existingUsers}
                        />
                    </div>
                </CardTitle>
                <CardTitle className='pt-2 mb-0 pb-0 mb-0' >
                    <div className="d-flex justify-content-end align-items-center">
                        <FormGroup className="d-flex mr-3 mx-2">
                            <Label className="d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                Task Name
                                <Input
                                    className='mx-2'
                                    type="text"
                                    placeholder='Enter the task name'
                                    name="TaskName"
                                    onChange={searchInputValidation}
                                    value={formFields && formFields.TaskName}
                                />
                            </Label>
                        </FormGroup>
                        <FormGroup className="d-flex mr-3 mx-2">
                            <Label className="d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                From Date
                                <Input
                                    className='mx-2'
                                    type="date"
                                    name="StartDate"
                                    onChange={searchInputValidation}
                                    value={formFields && formFields.StartDate}
                                    max={formFields.EndDate}
                                />
                            </Label>
                        </FormGroup>
                        <FormGroup className="d-flex mx-2">
                            <Label className="d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                To Date
                                <Input
                                    className='mx-2'
                                    type="date"
                                    name="EndDate"
                                    onChange={searchInputValidation}
                                    value={formFields && formFields.EndDate}
                                    min={formFields.StartDate}
                                    max="9999-12-31" />
                            </Label>
                        </FormGroup>
                    </div>
                </CardTitle>
            </Card>
            {
                filterErrorMessage ?
                    <div className="alert alert-danger mb-0 d-flex justify-content-between align-items-center" role="alert">
                        {filterErrorMessage}
                        <div className="content-heading">
                            <div className="ml-auto">
                                <FontAwesomeIcon icon={faX} onClick={handleCloseToast} />
                            </div>
                        </div>
                    </div> : ''
            }
            {
                props.TaskUpdateErrorMessage ?
                    <div className="alert alert-danger mb-0 d-flex justify-content-between align-items-center" role="alert">
                        {props.TaskUpdateErrorMessage}
                        <div className="content-heading">
                            <div className="ml-auto">
                                <FontAwesomeIcon icon={faX} onClick={handleCloseToast} />
                            </div>
                        </div>
                    </div> : ''
            }
            {
                props.TaskListErrorMessage ?
                    <div className="alert alert-danger mb-0 d-flex justify-content-between align-items-center" role="alert">
                        {props.TaskListErrorMessage}
                        <div className="content-heading">
                            <div className="ml-auto">
                                <FontAwesomeIcon icon={faX} onClick={handleCloseToast} />
                            </div>
                        </div>
                    </div> : ''
            }
            {
                props.isKanbanListPending ? (
                    <div className="d-flex justify-content-center mt-2">
                        <VWSpinner />
                    </div>
                ) :
                    <div className="App" style={{ zIndex: '-2' }} >
                        {
                            ((tasks && tasks.filter((task) => task.TaskStatus === "New").length > 0) || !hideColumn) ? (
                                <div className="column" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, 'New', statusData && statusData.New || 0)}>
                                    <Card className="shadow-none" style={{ backgroundColor: "#fff" }}>
                                        <div className='overflow-hidden sticky-top' style={{ backgroundColor: "#fff", overflow: "hidden", zIndex: "1" }}>
                                            <CardTitle tag="h4" className='p-2 m-2' style={{ borderBottom: "2px solid #64B5F6" }}>
                                                <h6 className='fw-bold'>
                                                    NEW | <Badge>
                                                        {tasks && tasks.filter((task) => task.TaskStatus === "New").length}
                                                    </Badge>
                                                </h6>
                                            </CardTitle>
                                            {isGlobalReadOnlyUser ? '' :
                                                <Link
                                                    type="button"
                                                    className="fs-5 m-2 mt-0 mb-0 p-0 d-flex justify-content-center align-items-center gap-2 text-white rounded"
                                                    onClick={() => handleOpenCreateTask('New')}
                                                    style={{ backgroundColor: "#64B5F6" }}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} />  Add Task
                                                </Link>}&nbsp;
                                        </div>
                                        <CardBody className='p-0 m-1'>
                                            {tasks && tasks.filter((task) => task.TaskStatus === "New").map((data) => (
                                                <Card
                                                    style={{
                                                        minHeight: "90px",
                                                        borderLeft: "5px solid #64B5F6",
                                                        borderRight: "1px solid #64B5F6",
                                                        borderTop: "1px solid #64B5F6",
                                                        borderBottom: "1px solid #64B5F6",
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleClick(data.Id)}
                                                    onDoubleClick={() => OnDoubleClickAction(data.Id)}
                                                    className='p-0 m-2 mt-0 shadow-lg rounded-1'
                                                >
                                                    <div key={data.Id} draggable onDragStart={(e) => handleDragStart(e, data.Id, 'New')} className='pt-2'>
                                                        <div className='ms-2 text-secondary fst-italic font-size-12'>
                                                            <FontAwesomeIcon icon={faThumbtack} />  {data.TaskType} | <span id={`pn-${data.Id}`}>{data.ProjectName}</span>
                                                            <ElementToolTip id={`pn-${data.Id}`} name={'Project Name'} />
                                                            <span> | <span id={`ms-${data.Id}`}>{data.MilestoneName}</span>
                                                                <ElementToolTip id={`ms-${data.Id}`} name={'Milestone Name'} />
                                                            </span>
                                                        </div>

                                                        <div className='d-flex justify-content-between'>
                                                            <div className='ms-2 mt-2' style={{ maxWidth: '100%' }}>
                                                                <strong
                                                                    style={{ cursor: 'pointer' }}
                                                                    className='font-size-14'
                                                                >
                                                                    {data.TaskName}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-3 mx-2' >
                                                            <div className='mb-1'>
                                                                <span id={`avatar-${data.Id}`}>
                                                                    <Avatar
                                                                        name={data && data.AssignedUserName}
                                                                        round size={25}
                                                                        title={""}
                                                                    />
                                                                </span>
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={`Assigned to: ${data && data.AssignedUserName}`} />
                                                            </div>
                                                            <div>
                                                                <span className=" badge badge-soft-success" id={`startDate-${data.Id}`}>
                                                                    {data.StartDate ? moment(data.StartDate).format('DD/MMM/yyyy') : ''}
                                                                </span>
                                                                <ElementToolTip id={`startDate-${data.Id}`} name={'Start Date'} />
                                                                {data.StartDate ? "-" : ""}
                                                                <span className="badge badge-soft-danger" id={`endDate-${data.Id}`}>
                                                                    {data.EndDate ? moment(data.EndDate).format('DD/MMM/YYYY') : ''}
                                                                </span>
                                                                <ElementToolTip id={`endDate-${data.Id}`} name={'End Date'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </div>
                            ) : ""
                        }
                        {((tasks && tasks.filter((task) => task.TaskStatus === "InProgress").length > 0) || !hideColumn) ? (
                            <div className="column" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, 'InProgress', statusData && statusData.InProgress || 0)}>
                                <Card className="shadow-none" style={{ backgroundColor: "#fff" }}>
                                    <div className='overflow-hidden sticky-top' style={{ backgroundColor: "#fff", overflow: "hidden", zIndex: "1" }}>
                                        <CardTitle tag="h4" className='p-2 m-2' style={{ borderBottom: "2px solid #FFB300" }}>
                                            <h6>IN PROGRESS | <Badge color="secondary">
                                                {tasks && tasks.filter((task) => task.TaskStatus === "InProgress").length}
                                            </Badge>
                                            </h6>
                                        </CardTitle>
                                        {isGlobalReadOnlyUser ? '' :
                                            <Link
                                                type="button"
                                                className="text-white fs-5 m-2 mt-0 mb-0 p-0  d-flex justify-content-center align-items-center gap-2 rounded"
                                                onClick={() => handleOpenCreateTask('InProgress')}
                                                style={{ backgroundColor: "#FFB300" }}
                                            >
                                                <FontAwesomeIcon icon={faCirclePlus} />  Add Task
                                            </Link>}&nbsp;
                                    </div>
                                    <CardBody className='p-0 m-1'>
                                        {tasks && tasks.filter((task) => task.TaskStatus === "InProgress").map((data) => (
                                            <Card style={{
                                                minHeight: "100px",
                                                borderLeft: "5px solid #FFB300",
                                                borderRight: "1px solid #FFB300",
                                                borderTop: "1px solid #FFB300",
                                                borderBottom: "1px solid #FFB300",
                                                cursor: 'pointer'
                                            }}
                                                // onClick={() => getTaskDetail(data.Id)}
                                                onClick={() => handleClick(data.Id)}
                                                onDoubleClick={() => OnDoubleClickAction(data.Id)}
                                                className='p-0 m-2 mt-0'
                                            >
                                                <div className='pt-2' key={data.Id} draggable onDragStart={(e) => handleDragStart(e, data.Id, 'InProgress')}>
                                                    <div className='ms-2 text-secondary fst-italic font-size-12'>
                                                        <FontAwesomeIcon icon={faThumbtack} />  {data.TaskType}  | <span id={`pn-${data.Id}`}>{data.ProjectName}</span>
                                                        <ElementToolTip id={`pn-${data.Id}`} name={'Project Name'} />
                                                        <span> | <span id={`ms-${data.Id}`}>{data.MilestoneName}</span>
                                                            <ElementToolTip id={`ms-${data.Id}`} name={'Milestone Name'} />
                                                        </span>
                                                    </div>

                                                    <div className='d-flex justify-content-between'>
                                                        <div className=' ms-2 mt-2' style={{ maxWidth: '100%' }}>
                                                            <strong
                                                                className='font-size-14'
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {data.TaskName}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between mt-3 mx-2' >
                                                        <div className='mb-1'>
                                                            <span id={`avatar-${data.Id}`}>
                                                                <Avatar name={data && data.AssignedUserName} round size={25} title={""} />
                                                            </span>
                                                            <ElementToolTip id={`avatar-${data.Id}`} name={`Assigned to: ${data && data.AssignedUserName}`} />
                                                        </div>
                                                        <div>
                                                            <span className=" badge badge-soft-success" id={`startDate-${data.Id}`}>
                                                                {data.StartDate ? moment(data.StartDate).format('DD/MMM/yyyy') : ''}
                                                            </span>
                                                            <ElementToolTip id={`startDate-${data.Id}`} name={'Start Date'} />
                                                            {data.StartDate ? "-" : ""}
                                                            <span className="badge badge-soft-danger" id={`endDate-${data.Id}`}>
                                                                {data.EndDate ? moment(data.EndDate).format('DD/MMM/YYYY') : ''}
                                                            </span>
                                                            <ElementToolTip id={`endDate-${data.Id}`} name={'End Date'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                    </CardBody>
                                </Card>
                            </div>) : ''
                        }

                        {
                            ((tasks && tasks.filter((task) => task.TaskStatus === "OnHold").length > 0) || !hideColumn) ? (
                                <div className="column" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, 'OnHold', statusData && statusData.OnHold || 0)}>
                                    <Card style={{ backgroundColor: "#fff" }} className="shadow-none">
                                        <div className='overflow-hidden sticky-top' style={{ backgroundColor: "#fff", overflow: "hidden", zIndex: "1" }}>
                                            <CardTitle tag="h4" className='p-2 m-2' style={{ borderBottom: "2px solid #FF5252" }}
                                            ><h6>ON HOLD | <Badge color="secondary">{tasks && tasks.filter((task) => task.TaskStatus === "OnHold").length}</Badge></h6></CardTitle>
                                            {isGlobalReadOnlyUser ? '' :
                                                <Link
                                                    type="button"
                                                    className="text-white fs-5 m-2 mt-0 mb-0 p-0  d-flex justify-content-center align-items-center gap-2  rounded"
                                                    onClick={() => handleOpenCreateTask('OnHold')}
                                                    style={{
                                                        backgroundColor: "#FF5252"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} />  Add Task
                                                </Link>}&nbsp;
                                        </div>
                                        <CardBody className='p-0 m-1'>
                                            {tasks && tasks.filter((task) => task.TaskStatus === "OnHold").map((data) => (
                                                <Card style={{
                                                    minHeight: "100px",
                                                    borderLeft: "5px solid #FF5252",
                                                    borderRight: "1px solid #FF5252",
                                                    borderTop: "1px solid #FF5252",
                                                    borderBottom: "1px solid #FF5252",
                                                    cursor: 'pointer'
                                                }}
                                                    // onClick={() => getTaskDetail(data.Id)}
                                                    onClick={() => handleClick(data.Id)}
                                                    onDoubleClick={() => OnDoubleClickAction(data.Id)}
                                                    className='p-0 m-2 mt-0'>
                                                    <div className="pt-2" key={data.Id} draggable onDragStart={(e) => handleDragStart(e, data.Id, 'OnHold')}>
                                                        <div className='ms-2 text-secondary fst-italic font-size-12'>
                                                            <FontAwesomeIcon icon={faThumbtack} />  {data.TaskType}  | <span id={`pn-${data.Id}`}
                                                            >{data.ProjectName}</span>
                                                            <ElementToolTip id={`pn-${data.Id}`} name={'Project Name'} />
                                                            <span> | <span id={`ms-${data.Id}`}
                                                            >{data.MilestoneName}</span>
                                                                <ElementToolTip id={`ms-${data.Id}`} name={'Milestone Name'} />
                                                            </span>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='ms-2 mt-2' style={{ maxWidth: '100%' }}>
                                                                <strong
                                                                    className='font-size-14'

                                                                    style={{ cursor: 'pointer' }}>
                                                                    {data.TaskName}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-3 mx-2' >
                                                            <div className='mb-1'>
                                                                <span id={`avatar-${data.Id}`}>
                                                                    <Avatar name={data && data.AssignedUserName} round size={25} title={""} />
                                                                </span>
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={`Assigned to: ${data && data.AssignedUserName}`} />
                                                            </div>
                                                            <div>
                                                                <span className=" badge badge-soft-success" id={`startDate-${data.Id}`}>
                                                                    {data.StartDate ? moment(data.StartDate).format('DD/MMM/yyyy') : ''}
                                                                </span>
                                                                <ElementToolTip id={`startDate-${data.Id}`} name={'Start Date'} />
                                                                {data.StartDate ? "-" : ""}
                                                                <span className="badge badge-soft-danger" id={`endDate-${data.Id}`}>
                                                                    {data.EndDate ? moment(data.EndDate).format('DD/MMM/YYYY') : ''}
                                                                </span>
                                                                <ElementToolTip id={`endDate-${data.Id}`} name={'End Date'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </div>) : ''
                        }
                        {
                            ((tasks && tasks.filter((task) => task.TaskStatus === "Internal Testing").length > 0) || !hideColumn) ? (
                                <div className="column" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, 'Internal Testing', statusData && statusData["Internal Testing"])}>
                                    <Card style={{ backgroundColor: "#fff" }} className="shadow-none">
                                        <div className='overflow-hidden sticky-top' style={{ backgroundColor: "#fff", overflow: "hidden", zIndex: "1" }}>
                                            <CardTitle tag="h4" className='p-2 m-2' style={{ borderBottom: "2px solid #FF4081" }}>
                                                <h6>INTERNAL TESTING | <Badge color="secondary">{tasks && tasks.filter((task) => task.TaskStatus === "Internal Testing").length}</Badge></h6></CardTitle>
                                            {isGlobalReadOnlyUser ? '' :
                                                <Link
                                                    type="button"
                                                    className="text-white fs-5 m-2 mt-0 mb-0 p-0  d-flex justify-content-center align-items-center gap-2  rounded"
                                                    onClick={() => handleOpenCreateTask('Internal Testing')}
                                                    style={{
                                                        backgroundColor: "#FF4081"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} />  Add Task
                                                </Link>}&nbsp;
                                        </div>
                                        <CardBody className='p-0 m-1'>
                                            {tasks && tasks.filter((task) => task.TaskStatus === "Internal Testing").map((data) => (
                                                <Card style={{
                                                    minHeight: "100px",
                                                    borderLeft: "5px solid #FF4081",
                                                    borderRight: "1px solid #FF4081",
                                                    borderTop: "1px solid #FF4081",
                                                    borderBottom: "1px solid #FF4081",
                                                    cursor: 'pointer'
                                                }}
                                                    // onClick={() => getTaskDetail(data.Id)}
                                                    onClick={() => handleClick(data.Id)}
                                                    onDoubleClick={() => OnDoubleClickAction(data.Id)}
                                                    className='p-0 m-2 mt-0' >
                                                    <div className="pt-2" key={data.Id} draggable onDragStart={(e) => handleDragStart(e, data.Id, 'Internal Testing')}>
                                                        <div className='ms-2 text-secondary fst-italic font-size-12'>
                                                            <FontAwesomeIcon icon={faThumbtack} />  {data.TaskType} | <span id={`pn-${data.Id}`}>{data.ProjectName}</span>
                                                            <ElementToolTip id={`pn-${data.Id}`} name={'Project Name'} />
                                                            <span> | <span id={`ms-${data.Id}`}>{data.MilestoneName}</span>
                                                                <ElementToolTip id={`ms-${data.Id}`} name={'Milestone Name'} />
                                                            </span>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='ms-2 mt-2' style={{ maxWidth: '100%' }}>
                                                                <strong
                                                                    className='font-size-14'
                                                                    // onClick={() => getTaskDetail(data.Id)}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {data.TaskName}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-3 mx-2' >
                                                            <div className='mb-1'>
                                                                <span id={`avatar-${data.Id}`}>
                                                                    <Avatar name={data && data.AssignedUserName} round size={25} title={""} />
                                                                </span>
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={`Assigned to: ${data && data.AssignedUserName}`} />
                                                            </div>
                                                            <div>
                                                                <span className=" badge badge-soft-success" id={`startDate-${data.Id}`}>
                                                                    {data.StartDate ? moment(data.StartDate).format('DD/MMM/yyyy') : ''}
                                                                </span>
                                                                <ElementToolTip id={`startDate-${data.Id}`} name={'Start Date'} />
                                                                {data.StartDate ? "-" : ""}
                                                                <span className="badge badge-soft-danger" id={`endDate-${data.Id}`}>
                                                                    {data.EndDate ? moment(data.EndDate).format('DD/MMM/YYYY') : ''}
                                                                </span>
                                                                <ElementToolTip id={`endDate-${data.Id}`} name={'End Date'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </div>)
                                : ''
                        }
                        {
                            ((tasks && tasks.filter((task) => task.TaskStatus === "Internal Testing Done").length > 0) || !hideColumn) ? (
                                <div className="column" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, 'Internal Testing Done', statusData && statusData["Internal Testing Done"])}>
                                    <Card style={{ backgroundColor: "#fff" }} className="shadow-none">
                                        <div className='overflow-hidden sticky-top' style={{ backgroundColor: "#fff", overflow: "hidden", zIndex: "1" }}>
                                            <CardTitle tag="h4" className='p-2 m-2' style={{ borderBottom: "2px solid #7C4DFF" }}
                                            ><h6>INTERNAL TESTING DONE  | <Badge color="secondary">{tasks && tasks.filter((task) => task.TaskStatus === "Internal Testing Done").length}</Badge></h6></CardTitle>
                                            {isGlobalReadOnlyUser ? '' :
                                                <Link
                                                    type="button"
                                                    className="text-white fs-5 m-2 mt-0 mb-0 p-0  d-flex justify-content-center align-items-center gap-2 rounded"
                                                    onClick={() => handleOpenCreateTask('Internal Testing Done')}
                                                    style={{
                                                        backgroundColor: "#7C4DFF"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} />  Add Task
                                                </Link>}&nbsp;
                                        </div>
                                        <CardBody className='p-0 m-1'>
                                            {tasks && tasks.filter((task) => task.TaskStatus === "Internal Testing Done").map((data) => (
                                                <Card style={{
                                                    minHeight: "100px",
                                                    borderLeft: "5px solid #7C4DFF",
                                                    borderRight: "1px solid #7C4DFF",
                                                    borderTop: "1px solid #7C4DFF",
                                                    borderBottom: "1px solid #7C4DFF",
                                                    cursor: 'pointer'
                                                }} className='p-0 m-2 mt-0'
                                                    // onClick={() => getTaskDetail(data.Id)}
                                                    onClick={() => handleClick(data.Id)}
                                                    onDoubleClick={() => OnDoubleClickAction(data.Id)}
                                                >
                                                    <div className='pt-2' key={data.Id} draggable onDragStart={(e) => handleDragStart(e, data.Id, 'Internal Testing Done')}>
                                                        <div className='ms-2 text-secondary fst-italic font-size-12'>
                                                            <FontAwesomeIcon icon={faThumbtack} />  {data.TaskType}  | <span id={`pn-${data.Id}`}>{data.ProjectName}</span>
                                                            <ElementToolTip id={`pn-${data.Id}`} name={'Project Name'} />
                                                            <span> | <span id={`ms-${data.Id}`}>{data.MilestoneName}</span>
                                                                <ElementToolTip id={`ms-${data.Id}`} name={'Milestone Name'} />
                                                            </span>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='ms-2 mt-2' style={{ maxWidth: '100%' }}>
                                                                <strong
                                                                    className='font-size-14'
                                                                    // onClick={() => getTaskDetail(data.Id)}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {data.TaskName}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-3 mx-2' >
                                                            <div className='mb-1'>
                                                                <span id={`avatar-${data.Id}`}>
                                                                    <Avatar name={data && data.AssignedUserName} round size={25} title={""} />
                                                                </span>
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={`Assigned to: ${data && data.AssignedUserName}`} />
                                                            </div>
                                                            <div>
                                                                <span className=" badge badge-soft-success" id={`startDate-${data.Id}`}>
                                                                    {data.StartDate ? moment(data.StartDate).format('DD/MMM/yyyy') : ''}
                                                                </span>
                                                                <ElementToolTip id={`startDate-${data.Id}`} name={'Start Date'} />
                                                                {data.StartDate ? "-" : ""}
                                                                <span className="badge badge-soft-danger" id={`endDate-${data.Id}`}>
                                                                    {data.EndDate ? moment(data.EndDate).format('DD/MMM/YYYY') : ''}
                                                                </span>
                                                                <ElementToolTip id={`endDate-${data.Id}`} name={'End Date'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </div>)
                                : ""
                        }
                        {
                            ((tasks && tasks.filter((task) => task.TaskStatus === "Waiting For Approval").length > 0) || !hideColumn) ? (
                                <div className="column" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, 'Waiting For Approval', statusData && statusData["Waiting For Approval"])}>
                                    <Card style={{ backgroundColor: "#fff" }} className="shadow-none">
                                        <div className='overflow-hidden sticky-top' style={{ backgroundColor: "#fff", overflow: "hidden", zIndex: "1" }}>
                                            <CardTitle tag="h4" className='p-2 m-2' style={{ borderBottom: "2px solid #FF7043" }}
                                            ><h6>WAITING FOR APPROVAL  | <Badge color="secondary">{tasks && tasks.filter((task) => task.TaskStatus === "Waiting For Approval").length}</Badge></h6></CardTitle>
                                            {isGlobalReadOnlyUser ? '' :
                                                <Link
                                                    type="button"
                                                    className="text-white fs-5 m-2 mt-0 mb-0 p-0  d-flex justify-content-center align-items-center gap-2 rounded"
                                                    onClick={() => handleOpenCreateTask('Waiting For Approval')}
                                                    style={{
                                                        backgroundColor: "#FF7043"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} />  Add Task
                                                </Link>}&nbsp;
                                        </div>
                                        <CardBody className='p-0 m-1'>
                                            {tasks && tasks.filter((task) => task.TaskStatus === "Waiting For Approval").map((data) => (
                                                <Card style={{
                                                    minHeight: "100px",
                                                    borderLeft: "5px solid #FF7043",
                                                    borderRight: "1px solid #FF7043",
                                                    borderTop: "1px solid #FF7043",
                                                    borderBottom: "1px solid #FF7043",
                                                    cursor: 'pointer'
                                                }}
                                                    // onClick={() => getTaskDetail(data.Id)}
                                                    onClick={() => handleClick(data.Id)}
                                                    onDoubleClick={() => OnDoubleClickAction(data.Id)}
                                                    className='p-0 m-2 mt-0' >
                                                    <div className='pt-2' key={data.Id} draggable onDragStart={(e) => handleDragStart(e, data.Id, 'Waiting For Approval')}>
                                                        <div className='ms-2 text-secondary fst-italic font-size-12'>
                                                            <FontAwesomeIcon icon={faThumbtack} />  {data.TaskType}  | <span id={`pn-${data.Id}`}>{data.ProjectName}</span>
                                                            <ElementToolTip id={`pn-${data.Id}`} name={'Project Name'} />
                                                            <span> | <span id={`ms-${data.Id}`}>{data.MilestoneName}</span>
                                                                <ElementToolTip id={`ms-${data.Id}`} name={'Milestone Name'} />
                                                            </span>
                                                        </div>

                                                        <div className='d-flex justify-content-between'>
                                                            <div className='ms-2 mt-2' style={{ maxWidth: '100%' }}>
                                                                <strong
                                                                    className='font-size-14'
                                                                    // onClick={() => getTaskDetail(data.Id)}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {data.TaskName}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-3 mx-2' >
                                                            <div className='mb-1'>
                                                                <span id={`avatar-${data.Id}`}>
                                                                    <Avatar name={data && data.AssignedUserName} round size={25} title={""} />
                                                                </span>
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={`Assigned to: ${data && data.AssignedUserName}`} />
                                                            </div>
                                                            <div>
                                                                <span className=" badge badge-soft-success" id={`startDate-${data.Id}`}>
                                                                    {data.StartDate ? moment(data.StartDate).format('DD/MMM/yyyy') : ''}
                                                                </span>
                                                                <ElementToolTip id={`startDate-${data.Id}`} name={'Start Date'} />
                                                                {data.StartDate ? "-" : ""}
                                                                <span className="badge badge-soft-danger" id={`endDate-${data.Id}`}>
                                                                    {data.EndDate ? moment(data.EndDate).format('DD/MMM/YYYY') : ''}
                                                                </span>
                                                                <ElementToolTip id={`endDate-${data.Id}`} name={'End Date'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </div>) : ''
                        }
                        {
                            ((tasks && tasks.filter((task) => task.TaskStatus === "Done").length > 0) || !hideColumn) ? (
                                <div className="column" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, 'Done', statusData && statusData.Done)}>
                                    <Card style={{ backgroundColor: "#fff" }} className="shadow-none">
                                        <div className='overflow-hidden sticky-top' style={{ backgroundColor: "#fff", overflow: "hidden", zIndex: "1" }}>
                                            <CardTitle tag="h4" className='p-2 m-2' style={{ borderBottom: "2px solid #00796B" }}
                                            ><h6>DONE  | <Badge color="secondary">{tasks && tasks.filter((task) => task.TaskStatus === "Done").length}</Badge></h6></CardTitle>
                                            {isGlobalReadOnlyUser ? '' :
                                                <Link
                                                    type="button"
                                                    className="text-white fs-5 m-2 mt-0 mb-0 p-0  d-flex justify-content-center align-items-center gap-2 rounded"
                                                    onClick={() => handleOpenCreateTask('Done')}
                                                    style={{
                                                        backgroundColor: "#00796B"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlus} />  Add Task
                                                </Link>}&nbsp;
                                        </div>
                                        <CardBody className='p-0 m-1'>
                                            {tasks && tasks.filter((task) => task.TaskStatus === "Done").map((data) => (
                                                <Card style={{
                                                    minHeight: "100px",
                                                    borderLeft: "5px solid #00796B",
                                                    borderRight: "1px solid #00796B",
                                                    borderTop: "1px solid #00796B",
                                                    borderBottom: "1px solid #00796B",
                                                    cursor: 'pointer'
                                                }}
                                                    // onClick={() => getTaskDetail(data.Id)}
                                                    onClick={() => handleClick(data.Id)}
                                                    onDoubleClick={() => OnDoubleClickAction(data.Id)}
                                                    className='p-0 m-2 mt-0' >
                                                    <div className='pt-2' key={data.Id} draggable onDragStart={(e) => handleDragStart(e, data.Id, 'Done')}>
                                                        <div className='ms-2 text-secondary fst-italic font-size-12'>
                                                            <FontAwesomeIcon icon={faThumbtack} />  {data.TaskType}  | <span id={`pn-${data.Id}`}>{data.ProjectName}</span>
                                                            <ElementToolTip id={`pn-${data.Id}`} name={'Project Name'} />
                                                            <span> | <span id={`ms-${data.Id}`}>{data.MilestoneName}</span>
                                                                <ElementToolTip id={`ms-${data.Id}`} name={'Milestone Name'} />
                                                            </span>
                                                        </div>

                                                        <div className='d-flex justify-content-between'>
                                                            <div className='ms-2 mt-2' style={{ maxWidth: '100%' }}>
                                                                <strong
                                                                    className='font-size-14'
                                                                    style={{ cursor: 'pointer' }}
                                                                // onClick={() => getTaskDetail(data.Id)}
                                                                >
                                                                    {data.TaskName}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-3 mx-2' >
                                                            <div className='mb-1'>
                                                                <span id={`avatar-${data.Id}`}>
                                                                    <Avatar name={data && data.AssignedUserName} round size={25} title={""} />
                                                                </span>
                                                                <ElementToolTip id={`avatar-${data.Id}`} name={`Assigned to: ${data && data.AssignedUserName}`} />
                                                            </div>
                                                            <div>
                                                                <span className=" badge badge-soft-success" id={`startDate-${data.Id}`}>
                                                                    {data.StartDate ? moment(data.StartDate).format('DD/MMM/yyyy') : ''}
                                                                </span>
                                                                <ElementToolTip id={`startDate-${data.Id}`} name={'Start Date'} />
                                                                {data.StartDate ? "-" : ""}
                                                                <span className="badge badge-soft-danger" id={`endDate-${data.Id}`}>
                                                                    {data.EndDate ? moment(data.EndDate).format('DD/MMM/YYYY') : ''}
                                                                </span>
                                                                <ElementToolTip id={`endDate-${data.Id}`} name={'End Date'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </div>) : ''
                        }
                    </div >
            }
            <VWModel
                header="CREATE TASK"
                isOpen={props.isCreateTaskModalOpen}
                handleClose={handleClose}
                modalSize="large"
            >
                <CreateTask
                    selectedUsersIds={FilterUserIds}
                    selectedUsername={FilterUserName}
                    selectedProjectName={FilterProjectName}
                    selectedProjectId={FilterProjectIds}
                    appId={App[0] && App[0].appId}
                    // fixedStatus={assignedStatus}
                    projectId={FilterProjectIds}
                    kanbanFilterProjectId={filterProjects}
                    kanbanFilterUserIds={filterUsers}
                    KanbanStartDate={formFields && formFields.StartDate}
                    KanbanEndDate={formFields && formFields.EndDate}
                    handleClose={handleClose}
                    kanbanFilterTaskName={formFields && formFields.TaskName}
                />
            </VWModel>
            <VWModel
                header="EDIT TASK"
                modalSize="large"
                isOpen={props.isEditTaskModalOpen}
                handleClose={handleCloseEditTaskModal}
            >
                <UpdateTask
                    TaskKanbanDetails={props.TaskDetails}
                    handleClose={handleCloseEditTaskModal}
                    PROJECTID={FilterProjectIds}
                    appId={App[0] && App[0].appId}
                    KanbanStartDate={formFields && formFields.StartDate}
                    KanbanEndDate={formFields && formFields.EndDate}
                    kanbanFilterProjectId={filterProjects}
                    kanbanFilterUserIds={filterUsers}
                    kanbanFilterTaskName={formFields && formFields.TaskName}
                />
            </VWModel>
        </div >
    );
};
const mapStateToProps = (state) => ({
    isCreateTaskModalOpen: state.task.isCreateTaskModalOpen,
    isKanbanListPending: state.task.isKanbanListPending,
    taskList: state.task.KanbanTaskList,
    isTaskPending: state.task.isTaskPending,
    totalCount: state.task.KanbantotalRecords,
    TaskUpdateErrorMessage: state.task.updateKanbanTaskErrorMessage,
    TaskListErrorMessage: state.task.taskListError,
    TaskDetails: state.task.TaskDetails,
    isEditTaskModalOpen: state.task.isEditTaskModalOpen,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    getKanbanTaskListAction: (appId, filterProjects, filterUsers, startDate, EndDate, TaskName) => dispatch(getKanbanTaskList(appId, filterProjects, filterUsers, startDate, EndDate, TaskName)),
    openCreateTaskModal: () => dispatch(openCreateTaskModalAction()),
    closeCreateTaskModal: () => dispatch(CloseCreateTaskModalAction()),
    UpdateTaskAction: (appId, formFields, projectId, UserId, startDate, EndDate, TaskName) => dispatch(UpdateKanbanTasks(appId, formFields, projectId, UserId, startDate, EndDate, TaskName)),
    getTaskDetails: (Id) => dispatch(getTaskDetails(Id)),
    openEditTaskModal: () => dispatch(openEditTaskModalAction()),
    closeEditTaskModal: () => dispatch(CloseEditTaskModalAction()),
    closeKanbanToast: () => dispatch(CloseEditKanbanTaskModalAction()),
    getAdminProjectNameAsyncSelectAction: (searchValue, callback) => dispatch(getAdminProjectNameAsyncSelectAction(searchValue, callback)),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
    ClearPath: () => dispatch(clearSetPath()),
    getUserNameAsyncSelect: (appId, searchValue, callback) => dispatch(getManagerUserNameAsyncSelectAction(appId, searchValue, callback)),
    getTaskStatusAsyncSelectAction: (appId) => dispatch(getAllTaskStatusPercentage(appId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(KanbanBoard);
