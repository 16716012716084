import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    Label,
    Table
} from "reactstrap";
import VWModel from "../../../../components/Modal/VWModal";
import CreateLeads from "./create";
import { getPager } from "../../../../helper/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faCalendarAlt,
    faFont,
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import {
    clearLeadsExportListError,
    closeLeadsCreate, filterFollowActionAsyncSelect,
    filterLeadCategoryAsyncSelect,
    filterLeadSourceAsyncSelect,
    getCrmUserAsyncSelect,
    getExportLeadList,
    getLeadsList,
    importLeads,
    isImportLeadsSuccess,
    openLeadsCreate
} from "../../../../store/Modules/crm/Leads/actions";
import Async from "react-select/async"
import Select from "react-select"
import {
    LEAD_EXPORT_HEADERS,
    LEADSTATUSOPTIONS,
    QUALIFICATIONLEVELOPTIONS
} from "../../../../store/Modules/crm/Leads/constants";
import '../../../Modules/viewList.css'
import moment from "moment-timezone";
import { excelPrint, excelSheetExport } from "../../../../helper/export";
import UITooltip from "../../../../CommonData/Data/UITooltip";
import { ElementToolTip } from "../../Projects/vwToolTip";
import { importExcelSheet } from "../../../../helper/import";

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const CrmLeadsList = (props) => {
    const [errors, setErrors] = useState({});
    const path = window.location.search;
    const params = new URLSearchParams(path);
    console.log("path:", path);
    console.log("params:", params);

    const StartDate = params.get('startDate');
    const EndDate = params.get('endDate');

    console.log('StartDate:', StartDate);
    console.log('EndDate:', EndDate);

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();

    const { LeadsListErrorMessage, isLeadsListPending, leadsListCount, leadsList, isCreateLeadsOpen, leadsListExportErrorMessage, isLeadsExportListPending } = props;
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');

    const { userAppDetails } = props.currentProfile || {};
    let isAdmin, isUser;

    const crmApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "crm");
    if (crmApps && crmApps.length > 0) {
        const Roles = crmApps[0];
        const { appRoleDetails } = Roles || {};
        isAdmin = appRoleDetails.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
        isUser = appRoleDetails.filter(role => role.roleName.toLowerCase() === "user").length > 0;
    }

    const buildQueryLeadName = () => {
        const { leadName } = filter || {};
        let searchLeadName = '';
        if (leadName) {
            searchLeadName += `${leadName}`;
        }
        return searchLeadName
    }
    const buildQueryLeadContact = () => {
        const { leadContact } = filter || {};
        let searchLeadContact = '';
        if (leadContact) {
            searchLeadContact += `${leadContact}`;
        }
        return searchLeadContact
    }
    const buildQueryLeadSource = () => {
        const { LeadSourceName } = filter || {};
        let searchLeadSource = '';
        if (LeadSourceName) {
            searchLeadSource += `${LeadSourceName}`;
        }
        return searchLeadSource
    }
    const buildQueryEmail = () => {
        const { email } = filter || {};
        let searchEmail = '';
        if (email) {
            searchEmail += `${email.replace(/\+/g, "%2B")}`;
        }
        return searchEmail
    }
    const buildQueryPhoneNo = () => {
        const { phoneNo } = filter || {};
        let searchPhoneNo = '';
        if (phoneNo) {
            searchPhoneNo += `${phoneNo}`;
        }
        return searchPhoneNo
    }
    const buildQueryLeadOwnerUserName = () => {
        const { leadOwnerUserName } = filter || {};
        let searchLeadOwnerUserName = '';
        if (leadOwnerUserName) {
            searchLeadOwnerUserName += `${leadOwnerUserName}`;
        }
        return searchLeadOwnerUserName
    }
    const buildQueryLeadOwnerUserEmail = () => {
        const { leadOwnerUserEmail } = filter || {};
        let searchLeadOwnerUserEmail = '';
        if (leadOwnerUserEmail) {
            searchLeadOwnerUserEmail += `${leadOwnerUserEmail.replace(/\+/g, "%2B")}`;
        }
        return searchLeadOwnerUserEmail
    }
    const buildQueryLeadStatus = () => {
        const { leadStatus } = filter || {};
        let searchLeadStatus = '';
        if (leadStatus) {
            searchLeadStatus += `${leadStatus}`;
        }
        return searchLeadStatus
    }
    const buildQueryQualificationLevel = () => {
        const { QualificationLevel } = filter || {};
        let searchQualificationLevel = '';
        if (QualificationLevel) {
            searchQualificationLevel += `${QualificationLevel}`;
        }
        return searchQualificationLevel
    }
    const buildQueryCategoryName = () => {
        const { CategoryName } = filter || {};
        let searchCategoryName = '';
        if (CategoryName) {
            searchCategoryName += `${CategoryName}`;
        }
        return searchCategoryName
    }
    const buildQueryFollowUpActionName = () => {
        const { FollowUpActionName } = filter || {};
        let searchFollowUpActionName = '';
        if (FollowUpActionName) {
            searchFollowUpActionName += `${FollowUpActionName}`;
        }
        return searchFollowUpActionName
    }
    const buildQueryLeadWebsite = () => {
        const { LeadWebsite } = filter || {};
        let searchLeadWebsite = '';
        if (LeadWebsite) {
            searchLeadWebsite += `${LeadWebsite}`;
        }
        return searchLeadWebsite
    }
    const buildQueryLeadDesignation = () => {
        const { LeadDesignation } = filter || {};
        let searchLeadDesignation = '';
        if (LeadDesignation) {
            searchLeadDesignation += `${LeadDesignation}`;
        }
        return searchLeadDesignation
    }
    const buildQueryLeadResponsibility = () => {
        const { LeadResponsibility } = filter || {};
        let searchLeadResponsibility = '';
        if (LeadResponsibility) {
            searchLeadResponsibility += `${LeadResponsibility}`;
        }
        return searchLeadResponsibility
    }
    const buildQueryLeadCreatedDate = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedDate = '';
        if (CreatedTimeStamp) {
            searchCreatedDate += `${CreatedTimeStamp}`;
        }
        return searchCreatedDate
    }
    const buildQueryLeadCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }

    const setPage = (page = 1) => {
        props.getLeadsList(filter, page, PageSize, buildQueryLeadName(), buildQueryLeadContact(), buildQueryLeadSource(), buildQueryEmail(), buildQueryPhoneNo(),
            buildQueryLeadOwnerUserName(), buildQueryLeadOwnerUserEmail(), buildQueryLeadStatus(), buildQueryQualificationLevel(), buildQueryCategoryName(),
            buildQueryFollowUpActionName(), buildQueryLeadWebsite(), buildQueryLeadDesignation(), buildQueryLeadResponsibility(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy());
        setPager(prevPager => {
            const totalPages = Math.ceil(leadsListCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(leadsListCount, 1, PageSize)
        setPager(currentPager)
    }, [leadsListCount])

    useEffect(() => {
        if (StartDate || EndDate) {
            setFilter({
                ...filter,
                CreatedTimeStamp: StartDate,
                UpdatedTimeStamp: EndDate,
            })
        } else {
            const currentPageNumber = Pager && Pager.currentPage;
            setPage(currentPageNumber);
        }
        props.clearLeadsExportListError();
        props.isImportLeadsSuccess();
    }, [])

    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const searchFollowUpActionDD = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            FollowUpActionName: label,
        })
    }
    const searchLeadStatusDD = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            leadStatus: label,
        })
    }
    const searchQualificationLevelDD = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            QualificationLevel: label,
        })
    }
    const searchLeadCategoryDD = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            CategoryName: label,
        })
    }
    const searchLeadSourceDD = (value) => {
        const { label } = value || {};
        setFilter({
            ...filter,
            LeadSourceName: label,
        })
    }
    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    const handleLeadeSource = (inputValue, callback) => {
        const { getLeadSourceAsyncSelect } = props;
        return getLeadSourceAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleFollowAction = (inputValue, callback) => {
        const { getFollowActionAsyncSelect } = props;
        return getFollowActionAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }


    const handleCategory = (inputValue, callback) => {
        const { getLeadCategoryAsyncSelect } = props;
        return getLeadCategoryAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }

    // ----------------------------------------SORTING-----------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };
    const sortedLeadsList = [...leadsList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'IsTerminated') {
            return sortDirection === 'asc' ? b.IsTerminated - a.IsTerminated : a.IsTerminated - b.IsTerminated;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //------------------------------------------ACCOUNT-CREATE-MODAL------------------------------------------------
    const createLeadOpen = () => {
        props.openLeadsCreate();
    }
    const handleClose = () => {
        props.closeLeadsCreate();
    }
    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/crm/leads/details/${Id}`);
    };

    const handleExports = () => {
        props.getExportLeadList(filter, buildQueryLeadName(), buildQueryLeadContact(), buildQueryLeadSource(), buildQueryEmail(), buildQueryPhoneNo(),
            buildQueryLeadOwnerUserName(), buildQueryLeadOwnerUserEmail(), buildQueryLeadStatus(), buildQueryQualificationLevel(), buildQueryCategoryName(),
            buildQueryFollowUpActionName(), buildQueryLeadWebsite(), buildQueryLeadDesignation(), buildQueryLeadResponsibility(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy())
            .then((response) => {
                if (response) {
                    const exportLeadList = [];
                    const headers = LEAD_EXPORT_HEADERS;
                    const headerExcel = [
                        { headerName: "Lead Name", key: "leadName", headerWidhth: 30 },
                        { headerName: "Email", key: "email", headerWidhth: 50 },
                        { headerName: "Lead Owner Name", key: "leadOwnerUserName", headerWidhth: 50 },
                        { headerName: "Lead Owner Email", key: "leadOwnerUserEmail", headerWidhth: 50 },
                        { headerName: "Lead Contact", key: "leadContact", headerWidhth: 30 },
                        { headerName: "Phone No", key: "phoneNo", headerWidhth: 30 },
                        { headerName: "Lead Source", key: "leadSource", headerWidhth: 30 },
                        { headerName: "Follow Up Action", key: "followUpAction", headerWidhth: 30 },
                        { headerName: "Lead Status", key: "leadStatus", headerWidhth: 30 },
                        { headerName: "Qualification Level", key: "qualificationLevel", headerWidhth: 30 },
                        { headerName: "Category Name", key: "categoryName", headerWidhth: 30 },
                        { headerName: "Lead Website", key: "leadWebsite", headerWidhth: 30 },
                        { headerName: "Lead Designation", key: "leadDesignation", headerWidhth: 30 },
                        { headerName: "Lead Responsibility", key: "leadResponsibility", headerWidhth: 30 },
                        { headerName: "Created By", key: "createdBy", headerWidhth: 30 },
                        { headerName: "Created On", key: "createdDate", headerWidhth: 30 },
                        { headerName: "Description", key: "description", headerWidhth: 30 }
                    ]
                    const headerSizes = [20, 50, 30, 30, 20, 20, 20, 20, 30, 20, 20, 20, 20, 20];
                    response && response.forEach((element) => {
                        exportLeadList.push({
                            leadName: element.leadName,
                            email: element.email,
                            leadOwnerUserName: element.leadOwnerUserName,
                            leadOwnerUserEmail: element.leadOwnerUserEmail,
                            leadContact: element.leadContact,
                            phoneNo: element.phoneNo,
                            leadSource: element.leadSourceName,
                            followUpAction: element.followUpActionName,
                            leadStatus: element.leadStatus,
                            qualificationLevel: element.qualificationLevel,
                            categoryName: element.categoryName,
                            leadWebsite: element.leadWebsite,
                            leadDesignation: element.leadDesignation,
                            leadResponsibility: element.leadResponsibility,
                            createdDate: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD MMM YYYY') : "",
                            createdBy: element.createdBy,
                            description: element.description,
                        });
                    });
                    excelSheetExport(headerExcel, exportLeadList, "Lead-List");
                    // excelPrint(exportLeadList, "Lead-List", headers, headerSizes);
                }
            })
    }

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    //---------------------------IMPORT---------------------------------------------
    const [importCardOpen, setImportCardOpen] = useState(false);
    const [excelSheet, setExcelSheet] = useState();
    const [importError, setImportError] = useState();

    const handleImportCard = () => {
        setImportCardOpen(!importCardOpen)
    }

    const handleImport = () => {
        const dataToImport = [
            { headerName: "Lead Name", headerWidhth: 30 },
            { headerName: "Email", headerWidhth: 50 },
            { headerName: "Lead Owner Email", headerWidhth: 50 },
            { headerName: "Lead Contact", headerWidhth: 30 },
            { headerName: "Phone No", headerWidhth: 30 },
            { headerName: "Lead Source", headerWidhth: 30 },
            { headerName: "Qualification Level", headerWidhth: 30 },
            { headerName: "Category Name", headerWidhth: 30 },
            { headerName: "Follow Up Action", headerWidhth: 30 },
            { headerName: "Description", headerWidhth: 30 },
            { headerName: "Lead Website", headerWidhth: 30 },
            { headerName: "Lead Designation", headerWidhth: 30 },
            { headerName: "Lead Responsibility", headerWidhth: 30 }
        ]
        importExcelSheet(dataToImport, "Leads")
    }

    const selectFile = (e) => {
        const input = e.target;
        const value = input.files[0];
        setExcelSheet(value);
        setErrors({ ...errors, excelSheet: '' });
    }

    const handleSubmit = () => {
        const newErrors = {};
        if (!(excelSheet)) {
            newErrors.excelSheet = 'File is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {

            props.importLeads(excelSheet, filter)
                .then(() => {
                    console.log("It working")
                    setImportCardOpen(false);
                })
                .catch((err) => {
                    console.log("err: ", err);
                    setImportError(err);
                })
        }
    }

    console.log("excelSheet:", excelSheet);

    return (
        <div className="page-content">
            <Card>
                <CardTitle tag="h4"
                    className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                    <strong className='d-flex align-items-center'>LEADS  -&nbsp;<Badge color="danger" id={'leadCount'}>{isLeadsListPending ? 0 : (props && props.leadsListCount || 0)}</Badge>
                        <ElementToolTip id={'leadCount'} name={
                            'Leads Count'
                        } /></strong>
                    <div className="d-flex gap-2">
                        <Button
                            color="primary"
                            onClick={createLeadOpen}
                        >
                            CREATE
                        </Button>
                        {isAdmin &&
                            <Dropdown
                                isOpen={importCardOpen}
                                direction="bottom"
                                toggle={() => {
                                    handleImportCard();
                                    setImportError();
                                    setExcelSheet();
                                    setErrors({ ...errors, excelSheet: '' });
                                }}
                            >
                                <DropdownToggle
                                    style={{
                                        backgroundColor: "orange",
                                        borderColor: "orange"
                                    }}
                                >
                                    IMPORT
                                </DropdownToggle>
                                <DropdownMenu style={{ minWidth: "400px", minHeight: "150px" }}>
                                    <div className="p-3 pb-0">
                                        <div className="d-flex justify-content-between">
                                            <div className="fs-5 text-dark fw-bold mb-4">
                                                IMPORT LEADS
                                            </div>
                                            <Link onClick={handleImport}>
                                                <FontAwesomeIcon icon={faDownload} /> Template
                                            </Link>
                                        </div>
                                        {importError &&
                                            < VWMessage type="danger" defaultMessage={importError} baseClassName="alert" />
                                        }
                                        <FormGroup>
                                            <Label>Import File <span className="text-danger"> *</span></Label>
                                            <Input type="file" onChange={selectFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                invalid={errors && errors.excelSheet}
                                            />
                                            <FormText className="text-nowrap">
                                                Note: Use the template above to validate and import leads.
                                            </FormText>
                                            {<FormFeedback>{errors && errors.excelSheet}</FormFeedback>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Button
                                                className="me-3"
                                                color="success"
                                                onClick={handleSubmit}
                                                disabled={props.isImportLeadsPending}
                                            >
                                                {props.isImportLeadsPending ? <>
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                    &nbsp; Submitting...
                                                </>
                                                    : <>Submit</>}
                                            </Button>
                                            <Button
                                                color="danger"
                                                onClick={() => {
                                                    setImportCardOpen(false)
                                                    setExcelSheet()
                                                    setErrors({ ...errors, excelSheet: '' });
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </FormGroup>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        }
                        {isAdmin &&
                            <Button
                                className="me-4"
                                color="success"
                                onClick={handleExports}
                                disabled={leadsList && leadsList.length <= 0 || isLeadsExportListPending}
                            >
                                {isLeadsExportListPending ? 'EXPORTING...' : 'EXPORT'}
                            </Button>
                        }
                    </div>

                </CardTitle>
            </Card>
            <Card >
                <CardBody className="p-4">
                    {(LeadsListErrorMessage || leadsListExportErrorMessage) ? <div className='w-100'>
                        {(LeadsListErrorMessage || leadsListExportErrorMessage) && (
                            <VWMessage type="danger" defaultMessage={LeadsListErrorMessage || leadsListExportErrorMessage} baseClassName="alert" ></VWMessage>
                        )}
                    </div> : ""}
                    <div style={{ height: "60vh", overflowX: "auto" }}>
                        <Table size="sm" className="table table-striped table-hover table-nowrap w-100" style={style.table}>
                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                <tr>
                                    <th onClick={() => handleSort('LeadName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                                        Lead Name &nbsp;
                                        {sortOrder === 'LeadName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('LeadContact')} className='hand-cursor'>
                                        Lead Contact &nbsp;
                                        {sortOrder === 'LeadContact' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('LeadSourceName')} className='hand-cursor'>
                                        Lead Source &nbsp;
                                        {sortOrder === 'LeadSourceName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('FollowUpActionName')} className='hand-cursor'>
                                        Follow Up Action &nbsp;
                                        {sortOrder === 'FollowUpActionName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    {!isAdmin &&
                                        <th style={{ minWidth: '200px' }} onClick={() => handleSort('leadOwnerUserName')} className='hand-cursor'>
                                            Lead Owner &nbsp;
                                            {sortOrder === 'leadOwnerUserName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                    }
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('LeadStatus')} className='hand-cursor'>
                                        Lead Status &nbsp;
                                        {sortOrder === 'LeadStatus' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('QualificationLevel')} className='hand-cursor'>
                                        Qualification Level &nbsp;
                                        {sortOrder === 'QualificationLevel' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CategoryName')} className='hand-cursor'>
                                        Category Name &nbsp;
                                        {sortOrder === 'CategoryName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('LeadWebsite')} className='hand-cursor'>
                                        Lead Website &nbsp;
                                        {sortOrder === 'LeadWebsite' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('LeadDesignation')} className='hand-cursor'>
                                        Lead Designation &nbsp;
                                        {sortOrder === 'LeadDesignation' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('LeadResponsibility')} className='hand-cursor'>
                                        Lead Responsibility &nbsp;
                                        {sortOrder === 'LeadResponsibility' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                        Created By &nbsp;
                                        {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>View</th>
                                </tr>
                                <tr>
                                    <th>
                                        <Input
                                            type="text"
                                            name="email"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="leadContact"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="leadSourceId"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleLeadeSource}
                                            onChange={searchLeadSourceDD}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="followUpActionId"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleFollowAction}
                                            onChange={searchFollowUpActionDD}
                                        />
                                    </th>
                                    {!isAdmin &&
                                        <th>
                                            <Input
                                                type="text"
                                                name="leadOwnerUserEmail"
                                                placeholder="Search"
                                                onChange={searchInputValidation}
                                            />
                                        </th>
                                    }
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            type="select"
                                            isClearable
                                            name="LeadStatus"
                                            placeholder="Search"
                                            options={LEADSTATUSOPTIONS}
                                            onChange={searchLeadStatusDD}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            type="text"
                                            isClearable
                                            name="qualificationLevel"
                                            placeholder="Search"
                                            options={QUALIFICATIONLEVELOPTIONS}
                                            onChange={searchQualificationLevelDD}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="categoryId"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleCategory}
                                            onChange={searchLeadCategoryDD}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="LeadWebsite"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="LeadDesignation"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="LeadResponsibility"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <InputGroup style={{ width: "200px" }}>
                                            {filterSelectionOption === 'name' ?
                                                <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                    <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                    <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                style={{ width: "120px" }}
                                                type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                max={filterSelectionOption !== "name" && "9999-12-31"}
                                            />
                                        </InputGroup>
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {!isLeadsListPending && (leadsList && leadsList.length > 0) ?
                                    sortedLeadsList && sortedLeadsList.map((lead, index) => (
                                        <tr key={index} onDoubleClick={() => OnDoubleClickAction(lead.Id)}>
                                            <td className='ps-3'>{lead.LeadName || "N/A"}<br />
                                                <small>{lead.Email || "N/A"}</small>
                                            </td>
                                            <td className='ps-3'>{lead.LeadContact || "N/A"}<br />
                                                <small>{lead.PhoneNo || "N/A"}</small></td>
                                            <td className='ps-3'>{lead.LeadSourceName || "N/A"}</td>
                                            <td className='ps-3'>{lead.FollowUpActionName || "N/A"}</td>
                                            {!isAdmin &&
                                                <td className='ps-3'>{lead.LeadOwnerUserName || "N/A"}<br />
                                                    <small>{lead.LeadOwnerUserEmail || "N/A"}</small>
                                                </td>
                                            }
                                            <td className='ps-3'>{lead.LeadStatus || "N/A"}</td>
                                            <td className='ps-3'>{lead.QualificationLevel || "N/A"}</td>
                                            <td className='ps-3'>{lead.CategoryName || "N/A"}</td>
                                            <td className='ps-3'>{lead.LeadWebsite || "N/A"}</td>
                                            <td className='ps-3'>{lead.LeadDesignation || "N/A"}</td>
                                            <td className='ps-3'>{lead.LeadResponsibility || "N/A"}</td>
                                            <td>
                                                {lead.CreatedBy || "N/A"}<br />
                                                <small>{lead.CreatedTimeStamp ? moment(lead.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                            </td>
                                            <td className='sticky-column' style={{ backgroundColor: "white" }}>
                                                <Link className="btn btn-sm btn-light" to={`/crm/leads/details/${lead.Id}`}>
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                        </tr>)) :
                                    isLeadsListPending ?
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <VWSpinner />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <NoRecord />
                                                </div>
                                            </div>
                                        </div>}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <CardFooter>
                    {leadsList && leadsList.length > 0 ?
                        <VWPagination pager={Pager} setPage={setPage} totalRecords={leadsListCount} pageSize={PageSize} /> : null
                    }
                </CardFooter>
            </Card>
            <VWModel
                header="CREATE LEAD"
                modalSize="extra-large"
                isOpen={isCreateLeadsOpen}
                handleClose={handleClose}
            >
                <CreateLeads handleClose={handleClose} filteredData={filter} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentProfile: state.login.currentProfile,
    isCreateLeadsOpen: state.crmLeads.isCreateLeadsOpen,
    leadsList: state.crmLeads.leadsList,
    leadsListCount: state.crmLeads.leadsListCount,
    isLeadsListPending: state.crmLeads.isLeadsListPending,
    LeadsListErrorMessage: state.crmLeads.leadsListErrorMessage,
    leadsListExport: state.crmLeads.leadsListExport,
    isLeadsExportListPending: state.crmLeads.isLeadsExportListPending,
    leadsListExportErrorMessage: state.crmLeads.leadsListExportErrorMessage,
    isImportLeadsPending: state.crmLeads.isLeadsImportPending,
})

const mapDispatchToProps = (dispatch) => ({
    openLeadsCreate: () => dispatch(openLeadsCreate()),
    closeLeadsCreate: () => dispatch(closeLeadsCreate()),
    isImportLeadsSuccess: () => dispatch(isImportLeadsSuccess()),
    clearLeadsExportListError: () => dispatch(clearLeadsExportListError()),
    getLeadsList: (filter, page, PageSize, searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName,
        searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy) => dispatch(getLeadsList(filter, page, PageSize, searchLeadName, searchLeadContact, searchLeadSource,
            searchEmail, searchPhoneNo, searchLeadOwnerUserName, searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy)),
    getCrmUserAsyncSelect: (searchValue, callback) => dispatch(getCrmUserAsyncSelect(searchValue, callback)),
    getLeadCategoryAsyncSelect: (searchValue, callback) => dispatch(filterLeadCategoryAsyncSelect(searchValue, callback)),
    getLeadSourceAsyncSelect: (searchValue, callback) => dispatch(filterLeadSourceAsyncSelect(searchValue, callback)),
    getExportLeadList: (filter, searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName,
        searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy) => dispatch(getExportLeadList(filter, searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName,
            searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy)),
    getFollowActionAsyncSelect: (searchValue, callback) => dispatch(filterFollowActionAsyncSelect(searchValue, callback)),
    importLeads: (excelSheet, filter) => dispatch(importLeads(excelSheet, filter))
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmLeadsList)